<!-- hidden label to calculate if there is a label or not -->
<app-label-inflator #labelCalculator hidden [labelComponentInterface]="cardData"></app-label-inflator>
<div class="card-content" [style.gap.rem]="viewModel.sidebarAndContentGapInRem$ | async">
  <app-stiiizy-side-bar
    *ngIf="viewModel.showSidebar$ | async"
    [cardData]="cardData"
    [maxWidthInPx]="143"
    [maxHeightInPx]="12">
  </app-stiiizy-side-bar>
  <app-stiiizy-product-information [cardData]="cardData"></app-stiiizy-product-information>
</div>
