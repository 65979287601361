import { Pipe, PipeTransform } from '@angular/core';
import type { CardStack } from '../../../models/menu/section/card-stacks/card-stack';

@Pipe({
  name: 'cardPreviewWidthInPx'
})
export class CardPreviewWidthInPxPipe implements PipeTransform {

  transform(cardStack: CardStack | null): number {
    return cardStack?.cardPreviewWidthInPx() ?? 0;
  }

}
