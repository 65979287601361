import { SectionRowViewModel } from './SectionRowViewModel';
import { StringUtils } from '../../../../../../../../../utils/string-utils';

export class SectionRowViewModelLaCanapa extends SectionRowViewModel {

  override getCannabinoid(cannabinoid: string): string {
    return StringUtils.removeWhiteSpace(super.getCannabinoid(cannabinoid));
  }

}
