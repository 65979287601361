import { Observable, Subject } from 'rxjs';

// @dynamic
export class StringifyUtils {

  static mapDataType = 'Map';

  static replacer(k, val) {
    const originalObject = this[k];
    const ignore = k === 'srcUrl' || k === 'failedToDownload' || val instanceof Observable || val instanceof Subject;
    if (ignore) {
      return undefined;
    } else if (originalObject instanceof Map) {
      const obj = Object.create(null);
      for (const [key, value] of originalObject) {
        obj[key] = value;
      }
      return obj;
    } else {
      return val;
    }
  }

  static cachedBlobReplacer(key, value) {
    const originalObject = this[key];
    if (key === 'url' || key === 'safeUrl' || key === 'blob') {
      return undefined;
    } else if (originalObject instanceof Map) {
      const obj = Object.create(null);
      for (const [k, v] of originalObject) {
        obj[k] = v;
      }
      return obj;
    } else {
      return value;
    }
  }

}
