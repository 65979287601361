import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CardData } from '../../../../../../models/print-cards/card-data';
import { exists } from '../../../../../../functions/exists';
import { RenderContextComponent } from '../../../../../../models/base/render-context-component';

@Component({
  selector: 'app-print-card-background-image-with-color-overlay-and-opacity',
  templateUrl: './print-card-background-image-with-color-overlay-and-opacity.component.html',
  styleUrls: ['./print-card-background-image-with-color-overlay-and-opacity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintCardBackgroundImageWithColorOverlayAndOpacityComponent
  extends RenderContextComponent implements AfterViewInit {

  @Input() cardData: CardData;

  setupViews() {
  }

  setupBindings() {
  }

  imageRenderFailed() {
    // if failed, just say that it rendered properly, so the overall signal (isMenuReady) will fire
    this.rendered.next(true);
  }

  imageRenderSucceeded() {
    this.rendered.next(true);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.rendered.next(!exists(this.cardData?.menu?.backgroundImage));
  }

}
