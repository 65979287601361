/* tslint:disable:member-ordering */

import { Deserializable } from '../../protocols/deserializable';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { PromotionPeriod } from '../../enum/dto/promotion-period.enum';
import { LocalizedDateRangeUnixSeconds } from '../../shared/localized-date-range-unix-seconds';
import { PromotionCondition } from '../../enum/dto/promotion-condition.enum';
import { PromotionDiscount } from '../../enum/dto/promotion-discount.enum';
import { PriceUtils } from '../../../utils/price-utils';
import { exists } from '../../../functions/exists';
import { TimeDurationUnixSeconds } from '../../shared/time-duration-unix-seconds';

export class LocationPromotion implements Deserializable, Cachable {

  // DTO
  public locationId: number;
  public id: string;
  public discountId: string;
  // isCompanyPromo is used as a flag to determine if a promotion in a Cova account has been applied to the
  // entire company, hence inheriting to every location. This functionality is just a shortcut within the POS
  // to apply a promotion to all locations. Therefore, a promotion with "isCompanyPromo === true" is still applied
  // at the location level.
  public isCompanyPromo: boolean;
  public name: string;
  public period: PromotionPeriod;
  public recurringDuration: TimeDurationUnixSeconds;
  public effectiveDateRange: LocalizedDateRangeUnixSeconds;
  public dateRanges: LocalizedDateRangeUnixSeconds[];
  public condition: PromotionCondition;
  public discount: PromotionDiscount;
  public dollarDiscount: number;
  public percentageDiscount: number;
  public fixedPriceDiscount: number;
  // Cache
  public cachedTime: number;

  onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.recurringDuration = Deserialize?.instanceOf(TimeDurationUnixSeconds, this.recurringDuration);
    this.effectiveDateRange = Deserialize?.instanceOf(LocalizedDateRangeUnixSeconds, this.effectiveDateRange);
    this.dateRanges = Deserialize?.arrayOf(LocalizedDateRangeUnixSeconds, this.dateRanges);
  }

  static buildArrayCacheKey(locationId: number): string {
    return `Promotions-${locationId}`;
  }

  static buildCacheKey(id: string): string {
    return `LocationPromotion-${id}`;
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneHour();
  }

  cacheKey(): string {
    return LocationPromotion.buildCacheKey(this.id);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.nowInUnixSeconds() > expiresAt;
  }

  protected hasDateRanges(): boolean {
    return exists(this.dateRanges?.length);
  }

  protected isWithinDateRanges(now: number): boolean {
    return this.dateRanges?.some(dr => now >= (dr?.startDate ?? 0) && now <= (dr?.endDate ?? 0)) || false;
  }

  isActive(): boolean {
    const validDiscountAmount = [this.dollarDiscount, this.percentageDiscount, this.fixedPriceDiscount].some(exists);
    if (!validDiscountAmount) return false;
    if (this.period === PromotionPeriod.Definite) {
      return this.hasDateRanges()
        ? this.isWithinDateRanges(DateUtils.nowInUnixSeconds())
        : this.effectiveDateRange?.isWithinUnixDateTimeWindow(DateUtils.nowInUnixSeconds());
    } else {
      const validDate = this.effectiveDateRange?.isWithinUnixDateTimeWindow(DateUtils.nowInUnixSeconds());
      const validDuration = this.recurringDuration?.isLocationPromotionActive();
      return validDate && validDuration;
    }
  }

  /**
   * Returns the discount amount for the given price, else null
   */
  applyPromotionDiscountOrNull(priceBeforeDiscount: number): number | null {
    if (!this.isActive() || !priceBeforeDiscount) return null;
    const round = PriceUtils.roundPrice;
    switch (true) {
      case exists(this.dollarDiscount):     return round(Math.max(priceBeforeDiscount - this.dollarDiscount, 0));
      case exists(this.percentageDiscount): return round(priceBeforeDiscount * ((100 - this.percentageDiscount) / 100));
      case exists(this.fixedPriceDiscount): return round(Math.max(this.fixedPriceDiscount, 0));
      default:                              return null;
    }
  }

}

