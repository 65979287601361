@charset "UTF-8";

/* src/app/modules/shared/background-asset/background-asset.component.scss */
.background-asset-container {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  pointer-events: none;
}
.background-asset {
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  pointer-events: none;
}
.background-video {
  background: transparent;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  pointer-events: none;
}
/*# sourceMappingURL=background-asset.component.css.map */
