import { Pipe, PipeTransform } from '@angular/core';
import type { ProductMenu } from '../../../models/menu/product-menu';
import type { SectionWithProducts } from '../../../models/menu/section/section-with-products';

@Pipe({
  name: 'secondaryPriceColumnAlsoShowOriginalPriceIfOnSale'
})
export class SecondaryPriceColumnAlsoShowOriginalPriceIfOnSalePipe implements PipeTransform {

  transform(
    menu: ProductMenu,
    section: SectionWithProducts
  ): boolean {
    return menu?.secondaryPriceColumnAlsoShowOriginalPriceIfOnSale(section) || false;
  }

}
