<div class="color-strip" [style.width]="viewModel.colorStripWidth$ | async">
  <div
    class="upper-container"
    [style.background-color]="viewModel.colorStripColor$ | async"
    [style.height.px]="viewModel.colorStripTopSectionHeight$ | async"></div>
  <div class="lower-container" [style.background-color]="viewModel.colorStripColor$ | async"></div>
</div>
<div class="main-content" [style.background-color]="viewModel.cardColor$ | async">
  <app-print-card-background-image-with-color-overlay-and-opacity [cardData]="cardData">
  </app-print-card-background-image-with-color-overlay-and-opacity>
</div>
