import { Component, ComponentRef, EnvironmentInjector, EventEmitter, Injector, OnChanges, reflectComponentType, SimpleChanges, Type, ViewChild } from '@angular/core';
import { InflatorContainerDirective } from './inflator-container.directive';
import { BehaviorSubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { exists } from '../../functions/exists';
import { Subscribable } from '../../models/base/subscribable';

/**
 * Implement ngOnChanges within extended class to handle inflation
 * e.g.
 * ngOnChanges(changes: SimpleChanges): void {
 *   if (changes.menu) {
 *     this.connectToComponentType(this.getMenuType());
 *   } else {
 *     super.ngOnChanges(changes);
 *   }
 * }
 */
@Component({ selector: 'app-inflator', template: '' })
export abstract class InflatorComponent extends Subscribable implements OnChanges {

  protected constructor(
    protected injector: Injector,
    protected environmentInjector: EnvironmentInjector
  ) {
    super();
  }

  @ViewChild(InflatorContainerDirective, { static: true }) contentContainer: InflatorContainerDirective;
  protected compRef: ComponentRef<any>;
  private outputSubscriptions: Subscription[];
  private _componentType = new BehaviorSubject<Type<any>>(null);
  private listenToComponentType = this._componentType.pipe(takeUntil(this.onDestroy)).subscribe(t => this.inflate(t));

  connectToComponentType(type: Type<any>) {
    this._componentType.next(type);
  }

  inflate(type: Type<any> | null) {
    this.contentContainer?.viewContainerRef?.clear();
    this.compRef = null;
    if (exists(type)) {
      this.compRef = this.contentContainer
        ?.viewContainerRef
        ?.createComponent(type, { injector: this.injector, environmentInjector: this.environmentInjector });
      this.forwardInputs();
      this.forwardOutputs();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.forwardInputChanges(changes);
  }

  protected forwardInputs(): void {
    if (exists(this.compRef)) {
      reflectComponentType(this.compRef.componentType)
        ?.inputs
        ?.forEach(input => this.compRef.setInput(input.propName, this[input.propName]));
    }
  }

  protected forwardInputChanges(changes: SimpleChanges): void {
    if (exists(this.compRef)) {
      Object.keys(changes)?.forEach(propertyName => this.compRef.setInput(propertyName, this[propertyName]));
      this.compRef?.instance?.ngOnChanges(changes);
    }
  }

  private forwardOutputs(): void {
    this.outputSubscriptions?.forEach(sub => sub?.unsubscribe());
    this.outputSubscriptions = [];
    if (exists(this.compRef)) {
      reflectComponentType(this.compRef.componentType)?.outputs?.forEach(output => {
        const subscription = (this.compRef.instance[output.propName] as EventEmitter<any>)?.subscribeWhileAlive({
          owner: this,
          next: (event) => (this[output.propName] as EventEmitter<any>)?.emit(event)
        });
        if (exists(subscription)) this.outputSubscriptions.push(subscription);
      });
    }
  }

  override destroy() {
    super.destroy();
    this.outputSubscriptions?.forEach(sub => sub?.unsubscribe());
    this.compRef?.destroy();
  }

}
