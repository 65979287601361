import { Component, Input } from '@angular/core';
import { ProductMenuFooterComponent } from '../product-menu-footer.component';
import { VariantBadge } from '../../../../../../../../models/product/dto/variant-badge';

@Component({
  selector: 'app-plantlife-non-smokable-footer',
  templateUrl: './plantlife-non-smokable-footer.component.html',
  styleUrls: ['./plantlife-non-smokable-footer.component.scss']
})
export class PlantlifeNonSmokableFooterComponent extends ProductMenuFooterComponent {

  @Input() includeClassifications: boolean = false;
  @Input() includeBadges: boolean = true;
  @Input() hideSides: boolean = false;
  @Input() removeBottomPadding: boolean = false;
  public filterOutBadgeNamesWith86 = (badge: VariantBadge) => !badge?.name?.includes('86');

}
