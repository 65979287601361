import { Injectable } from '@angular/core';
import { PrintCardContentViewModel } from '../../print-card-content-view-model';
import { Observable, of } from 'rxjs';
import { SpecificPriceWithoutDiscounts } from '../../../../../../../models/enum/shared/specific-price-without-discounts.enum';

@Injectable()
export class FireAndFlowerMemberCardStackContentViewModel extends PrintCardContentViewModel {

  public readonly mainPriceSpecificPriceWithoutDiscounts$: Observable<SpecificPriceWithoutDiscounts[]> = of([
    SpecificPriceWithoutDiscounts.LocationSpecificSecondaryBasePrice,
    SpecificPriceWithoutDiscounts.CompanySpecificSecondaryBasePrice
  ]);

  public readonly bottomPriceSpecificPriceWithoutDiscounts$ = of([
    SpecificPriceWithoutDiscounts.LocationSpecificBasePrice,
    SpecificPriceWithoutDiscounts.CompanySpecificBasePrice,
    SpecificPriceWithoutDiscounts.BasePrice
  ]);

  public addSpaceAfterDollarSign = (price: string): string => price?.replace('$', '$ ');

}
