export type MediaType = ImageType | VideoType;

export enum ImageType {
  BMP = 'image/bmp',
  GIF = 'image/gif',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PNG = 'image/png',
  SVG = 'image/svg',
}

export enum VideoType {
  AVI = 'video/x-msvideo',
  MP4 = 'video/mp4',
  MPEG = 'video/mpeg',
  OGV = 'video/ogg',
  WEBM = 'video/webm',
}
