<div class="main-content">
  <div
    *ngIf="cardData | sectionRowViewModelBasicMethod : 'getBrand' as brand"
    class="product-card-brand"
    appScaleDownText
    [scaleDownLinkedKey]="viewModel.variantId$ | async"
    [transparentWhileScaling]="viewModel.viewingInLiveView$ | async"
    [style.color]="viewModel.bodyTextColor$ | async">
    {{ brand | trimSafely }}
  </div>
  <div
    *ngIf="cardData | sectionRowViewModelBasicMethod : 'getRowTitle' as title"
    class="product-card-name"
    appScaleDownText
    [scaleDownLinkedKey]="viewModel.variantId$ | async"
    [transparentWhileScaling]="viewModel.viewingInLiveView$ | async"
    [style.color]="viewModel.headerTextColor$ | async">
    {{ title | trimSafely }}
  </div>

  <div class="product-label-badge-size-unit-qty-container">
    <app-label-inflator
      class="print-card-label"
      [columnLabel]="false"
      [labelComponentInterface]="cardData"
      [reset]="false">
    </app-label-inflator>
    <div
      *ngIf="(cardData?.menu | printCardMenuBasicMethod : 'getNumberOfBadgesForVariant' : cardData) > 0"
      class="product-card-badge-container">
      <img
        *ngFor="let variantBadge of cardData | printCardBadges"
        appImage
        class="product-card-badge"
        [asset]="variantBadge?.image"
        alt="badge"
        src="" />
    </div>
    <div
      *ngIf="!(viewModel.isAccessory$ | async) && !(viewModel.isNonCannabinoidOtherVariant$ | async)"
      class="product-card-size"
      [style.color]="viewModel.bodyTextColor$ | async">
      {{ viewModel.quantityAndSizeText$ | async | trimSafely }}
    </div>
  </div>

  <div
    *ngIf="!(viewModel.isNonCannabinoidVariant$ | async)"
    class="product-card-cannabinoid-container"
    [style.color]="viewModel.cardAccentColor$ | async">
    <div *ngIf="cardData | sectionRowViewModelBasicMethod : 'getThc' as thc" class="property-container">
      <div class="title">THC</div>
      <div
        class="value"
        appScaleDownText
        [scaleDownLinkedKey]="viewModel.variantId$ | async"
        [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
        {{ thc }}
      </div>
    </div>
    <div *ngIf="cardData | sectionRowViewModelBasicMethod : 'getCbd' as cbd" class="property-container">
      <div class="title">CBD</div>
      <div
        class="value"
        appScaleDownText
        [scaleDownLinkedKey]="viewModel.variantId$ | async"
        [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
        {{ cbd }}
      </div>
    </div>
  </div>

  <div class="pricing-container" [style.color]="viewModel.bodyTextColor$ | async">
    <app-column-primary-price
      [menu]="viewModel.cardStackMenu$ | async"
      [section]="viewModel.cardStack$ | async"
      [colVm]="viewModel.spoofedColViewModel$ | async"
      [rowVm]="viewModel.cardData$ | async"
      [locConfig]="viewModel.locationConfig$ | async"
      [locationId]="viewModel.locationId$ | async"
      [productStylingVm]="viewModel">
    </app-column-primary-price>
  </div>
</div>
<div class="color-strip">
  <img
    *ngIf="cardData | getVariantAssetForCard | async as variantAsset"
    appImage
    class="product-card-image"
    [asset]="variantAsset"
    src=""
    alt="" />
</div>
