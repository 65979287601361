<!-- product-column-{{TYPE}} gets added inside .ts component -->
<div
  #columnWrapper
  *ngIf="
    !calculationMode &&
    colVm?.columnType !== Types.SectionColumnProductInfoType.ProductTitle &&
    (menu | shouldInflateColumnForRow: section : colVm : rowVm)
  "
  [hidden]="menu | shouldHideColumnContent: section : colVm : rowVm"
  [class.force-bold-style]="productStylingVm | forceColumnBoldStyling: colVm : locConfig | async"
  [class.force-italic-style]="productStylingVm | forceColumnItalicStyling: colVm : locConfig | async"
  [class.default-background-color]="!(productStylingVm | columnBackgroundColor: colVm | async)"
  class="product-column"
  [class]="additionalClasses"
  [style.background-color]="productStylingVm | columnBackgroundColor: colVm | async"
  [style.color]="productStylingVm | forcedColumnTextColor: colVm : locConfig | async"
  [style.text-decoration-color]="productStylingVm | forcedColumnTextColor: colVm : locConfig | async"
  [style.zoom]="productStylingVm | forcedColumnZoom: colVm | async"
  [style.width.%]="
    (colVm | hideLabelColumn: menu : currentLabelText)
      ? 0
      : (colVm | columnWidth: ignoreColConfigWidth : colWidthFudgeFactor)
  ">
  <ng-content></ng-content>
</div>
