/* src/app/modules/labels/components/flag-label/flag-label.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.product-label {
  padding: 0;
}
.triangles-for-flag {
  margin-right: -1px;
  position: relative;
}
.triangle-top {
  position: absolute;
  top: 0;
  right: 0;
}
.triangle-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
}
.flag-body {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.075em;
  text-align: center;
  line-height: 1.75;
  padding: 0 0.25rem;
}
/*# sourceMappingURL=flag-label.component.css.map */
