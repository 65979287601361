<div class="content-container">
  <app-label-inflator
    #labelCalculator
    hidden
    class="label-calculator label"
    [columnLabel]="false"
    [labelComponentInterface]="cardData"
    [reset]="false">
  </app-label-inflator>
  <div *ngIf="viewModel.showLeftSideLargeText$ | async" class="center-left-side-large-text">
    <div
      class="left-side-large-text"
      appScaleDownText
      [nPixelsPerStep]="8"
      [style.color]="viewModel.cardAccentColor$ | async"
      [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
      {{ viewModel.leftSideLargeText$ | async }}
    </div>
  </div>
  <div class="left-side-large-text-spacer"></div>
  <div class="main-content" [style.gap.rem]="!(viewModel.isLineItemMode$ | async) ? 0.675 : null">
    <img
      *ngIf="cardData | getVariantAssetForCard | async as variantAsset"
      appImage
      class="card-image"
      [asset]="variantAsset"
      src=""
      alt="" />
    <div>
      <div class="product-name" [style.color]="viewModel.headerTextColor$ | async">
        {{ viewModel.productName$ | async }}
      </div>
      <div
        *ngIf="viewModel.strainName$ | async as strainName"
        class="strain-name"
        [style.color]="viewModel.bodyTextColor$ | async">
        {{ strainName }}
      </div>
    </div>
    <div class="size-divider">
      <div class="left-line" [style.background-color]="viewModel.cardAccentColor$ | async"></div>
      <div
        *ngIf="
          (viewModel.productSizeExists$ | async) &&
          !(viewModel.isNonCannabinoidOtherVariant$ | async) &&
          (viewModel.isLineItemMode$ | async)
        "
        class="size"
        [style.color]="viewModel.bodyTextColor$ | async">
        {{ viewModel.productSize$ | async }}
      </div>
      <div class="right-line" [style.background-color]="viewModel.cardAccentColor$ | async"></div>
    </div>
    <div
      *ngIf="
        (!(viewModel.isNonCannabinoidVariant$ | async) && (cardData | sectionRowViewModelBasicMethod : 'getThc')) ||
        (!(viewModel.isNonCannabinoidVariant$ | async) && (cardData | sectionRowViewModelBasicMethod : 'getCbd')) ||
        (viewModel.badgeOrLabelVisible$ | async)
      "
      class="content-attributes">
      <div
        #thcContainer
        *ngIf="
          !(viewModel.isNonCannabinoidVariant$ | async) && (cardData | sectionRowViewModelBasicMethod : 'getThc') as thc
        "
        class="thc-container"
        [style.border-color]="viewModel.cardAccentColor$ | async">
        <div class="header" [style.background-color]="viewModel.cardAccentColor$ | async">THC</div>
        <div class="content" [style.color]="viewModel.bodyTextColor$ | async">{{ thc }}</div>
      </div>
      <div
        *ngIf="
          !(viewModel.isNonCannabinoidVariant$ | async) && (cardData | sectionRowViewModelBasicMethod : 'getCbd') as cbd
        "
        class="cbd-container"
        [style.border-color]="viewModel.cardAccentColor$ | async">
        <div class="header" [style.background-color]="viewModel.cardAccentColor$ | async">CBD</div>
        <div class="content" [style.color]="viewModel.bodyTextColor$ | async">{{ cbd }}</div>
      </div>
      <div
        *ngIf="viewModel.badgeOrLabelVisible$ | async"
        #labelAndBadges
        class="badge-and-label-container"
        [style.flex-direction]="labelAndBadgesFlexDirection$ | async">
        <app-label-inflator
          *ngIf="labelCalculator.currentLabelText$ | async"
          class="label"
          [style.align-self]="labelSelfAlignment$ | async"
          [columnLabel]="false"
          [labelComponentInterface]="cardData"
          [reset]="false">
        </app-label-inflator>
        <div
          *ngIf="(cardData?.menu | printCardMenuBasicMethod : 'getNumberOfBadgesForVariant' : cardData) > 0"
          class="badge-container">
          <img
            *ngFor="let variantBadge of cardData | printCardBadges"
            appImage
            class="badge"
            [asset]="variantBadge?.image"
            alt="badge"
            src="" />
        </div>
      </div>
    </div>
    <app-pops-cannabis-regular-price
      *ngIf="viewModel.isLineItemMode$ | async; else multiVariantPricing"
      [colVm]="viewModel.spoofedColViewModel$ | async">
    </app-pops-cannabis-regular-price>
    <ng-template #multiVariantPricing>
      <div class="grid-container">
        <div
          *ngFor="let spoofedGridColVM of viewModel.spoofedGridColViewModels$ | async; let i = index"
          class="size-container"
          [style.border-color]="viewModel.cardAccentColor$ | async">
          <div appLinkWidth class="header" [style.background-color]="viewModel.cardAccentColor$ | async" [index]="i">
            {{ viewModel.getGridSize$(spoofedGridColVM) | async }}
          </div>
          <div class="content">
            <app-pops-cannabis-regular-price [colVm]="spoofedGridColVM"> </app-pops-cannabis-regular-price>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
