import { Component, ElementRef, forwardRef, ViewChild } from '@angular/core';
import { ProductSectionHeaderComponent } from '../product-section-header/product-section-header.component';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { StrainType } from '../../../../../../../../models/enum/dto/strain-classification.enum';
import { SativaHybridIndicaCols } from '../../menu-section/product-section/sativa-hybrid-indica-split-product-section/sativa-hybrid-indica-cols';
import { HtmlUtils } from '../../../../../../../../utils/html-utils';
import { CompanyDomainModel } from '../../../../../../../../domain/company-domain-model';

@Component({
  selector: 'app-plantlife-section-header',
  templateUrl: './plantlife-section-header.component.html',
  providers: [
    { provide: ProductSectionHeaderComponent, useExisting: forwardRef(() => PlantlifeSectionHeaderComponent) }
  ]
})
export class PlantlifeSectionHeaderComponent extends ProductSectionHeaderComponent {

  constructor(
    companyDomainModel: CompanyDomainModel,
    sanitizer: DomSanitizer
  ) {
    super(companyDomainModel, sanitizer);
  }

  @ViewChild('collapsedHeight', { static: true }) collapsedContainer: ElementRef;

  public splitSectionRowViewModels$ = this.sectionRowViewModels$.pipe(
    map(vms => {
      const sativa = vms?.filter(vm => vm?.product?.classification === StrainType.Sativa) ?? [];
      const hybrid = vms?.filter(vm => vm?.product?.classification === StrainType.Hybrid) ?? [];
      const indica = vms?.filter(vm => vm?.product?.classification === StrainType.Indica) ?? [];
      return new SativaHybridIndicaCols(sativa, hybrid, indica);
    }),
  );
  public sativaSectionRowViewModels$ = this.splitSectionRowViewModels$.pipe(map(rows => rows?.sativa));
  public hybridSectionRowViewModels$ = this.splitSectionRowViewModels$.pipe(map(rows => rows?.hybrid));
  public indicaSectionRowViewModels$ = this.splitSectionRowViewModels$.pipe(map(rows => rows?.indica));

  getExpandedHeaderHeight(): number {
    return HtmlUtils.getElementTotalHeight(this?.headerContainer?.nativeElement);
  }

  getCollapsedHeaderHeight(): number {
    return HtmlUtils.getElementTotalHeight(this?.collapsedContainer?.nativeElement);
  }

}
