import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RenderPrintCardLiveViewViewModel } from './render-print-card-live-view-view-model';
import { RenderContextComponent } from '../../../../models/base/render-context-component';
import { RenderContextService } from '../../../services/render-context.service';

@Component({
  selector: 'app-render-print-card-live-view',
  templateUrl: './render-print-card-live-view.component.html',
  styleUrls: ['./render-print-card-live-view.component.scss'],
  providers: [RenderPrintCardLiveViewViewModel, RenderContextService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenderPrintCardLiveViewComponent extends RenderContextComponent implements OnChanges {

  constructor(
    public viewModel: RenderPrintCardLiveViewViewModel
  ) {
    super();
  }

  @Input() companyConfig: any;
  @Input() locationConfig: any;
  @Input() printCardMenu: any;
  @Input() variantIds: string[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyConfig) this.viewModel.connectToCompanyConfig(this.companyConfig);
    if (changes.locationConfig) this.viewModel.connectToLocationConfig(this.locationConfig);
    if (changes.printCardMenu) this.viewModel.connectToPrintCardMenu(this.printCardMenu);
    if (changes.variantIds) this.viewModel.connectToVariantIds(this.variantIds);
  }

  setupBindings() {
  }

  setupViews() {
  }

}
