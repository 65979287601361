import { CardStack } from './card-stack';
import { CardData } from '../../../print-cards/card-data';
import { Type } from '@angular/core';
import { PrintCardContentComponent } from '../../../../modules/print-cards/components/print-cards/card-content/print-card-content.component';
import { PrintCardBackgroundComponent } from '../../../../modules/print-cards/components/print-cards/backgrounds/print-card-background.component';
import { PaperSize } from '../../../shared/paper-size';
import { Orientation } from '../../../enum/dto/orientation.enum';

export class EmptyCardStack extends CardStack {

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return null;
  }

  getCardBackgroundComponent(cardData: CardData): Type<PrintCardBackgroundComponent> | null {
    return null;
  }

  getCardWidthInInches(): number {
    return 0;
  }

  getCardHeightInInches(): number {
    return 0;
  }

  nRows(): number {
    return 0;
  }

  nColumns(): number {
    return 0;
  }

  nCardsPerPage(paperSize: PaperSize, isOnPerforatedPaper: boolean): number {
    return 0;
  }

  cardOrientationOnPortraitPage(): Orientation {
    return null;
  }

}
