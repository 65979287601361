<app-label-inflator
  #labelCalculator
  hidden
  class="label-calculator"
  [calculateVirtualLabel]="true"
  [columnLabel]="false"
  [labelComponentInterface]="cardData"
  [reset]="false">
</app-label-inflator>
<div class="content-area">
  <div class="content">
    <div
      class="card-header"
      [style.background-color]="viewModel.topOfCardBackgroundColor$ | async"
      [style.color]="viewModel.topOfCardTextColor$ | async">
      <ng-container *ngIf="!(viewModel.isNonCannabinoidVariant$ | async)">
        {{ cardData | sectionRowViewModelBasicMethod: 'getReadableStrainType' }}
      </ng-container>
      <ng-container *ngIf="cardData | sectionRowViewModelBasicMethod: 'rowVariantType' | trimSafely as variantType">
        {{ variantType }}
      </ng-container>
    </div>
    <div class="card-information-area">
      <div class="background-content" [style.background-color]="viewModel.cardColor$ | async">
        <app-print-card-background-image-with-color-overlay-and-opacity [cardData]="cardData">
        </app-print-card-background-image-with-color-overlay-and-opacity>
      </div>
      <div class="top-half">
        <div class="split-logo">
          <div class="title-brand-label-badge">
            <div class="product-title" [style.color]="viewModel.headerTextColor$ | async">
              {{ cardData | sectionRowViewModelBasicMethod: 'getRowTitle' }}
            </div>
            <div class="product-brand" [style.color]="viewModel.headerTextColor$ | async">
              {{ cardData | sectionRowViewModelBasicMethod: 'getBrand' }}
            </div>
            <div *ngIf="(viewModel.hasBadges$ | async) || (viewModel.hasLabel$ | async)" class="badges-and-label">
              <ng-container *ngIf="viewModel.hasBadges$ | async">
                <img
                  *ngFor="let variantBadge of cardData | printCardBadges"
                  appImage
                  class="product-badge"
                  [asset]="variantBadge?.image"
                  alt="badge"
                  src="" />
              </ng-container>
              <app-label-inflator
                *ngIf="viewModel.hasLabel$ | async"
                class="print-card-label"
                [columnLabel]="false"
                [labelComponentInterface]="cardData"
                [reset]="false">
              </app-label-inflator>
            </div>
          </div>
          <img
            *ngIf="cardData | getVariantAssetForCard | async as variantAsset"
            appImage
            class="brand-logo"
            [class.include-border]="viewModel.hasBackgroundColorOrImage$ | async"
            [style.height]="(viewModel.hasBackgroundColorOrImage$ | async) ? null : 'fit-content'"
            [style.object-position]="(viewModel.hasBackgroundColorOrImage$ | async) ? null : 'right top'"
            [asset]="variantAsset"
            src=""
            alt="" />
        </div>
      </div>
      <div class="bottom-half">
        <div class="table-data" [style.color]="viewModel.bodyTextColor$ | async">
          <table *ngIf="viewModel.isCannabinoidVariant$ | async">
            <tr [style.background-color]="viewModel.tableHeaderBackgroundColor$ | async">
              <th>THC</th>
              <th>CBD</th>
              <th>QTY</th>
            </tr>
            <tr [style.color]="viewModel.bodyTextColor$ | async">
              <td *ngIf="cardData | sectionRowViewModelBasicMethod: 'getThc' as thc" class="property-container">
                <div
                  class="value"
                  appScaleDownTextAndAdjustLines
                  [maxFontSizeInPx]="20"
                  [scaleDownLinkedKey]="viewModel.variantId$ | async"
                  [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
                  {{ thc }}
                </div>
              </td>
              <td *ngIf="cardData | sectionRowViewModelBasicMethod: 'getCbd' as cbd" class="property-container">
                <div
                  class="value"
                  appScaleDownTextAndAdjustLines
                  [maxFontSizeInPx]="20"
                  [scaleDownLinkedKey]="viewModel.variantId$ | async"
                  [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
                  {{ cbd }}
                </div>
              </td>
              <td>
                <div
                  class="value"
                  appScaleDownTextAndAdjustLines
                  [maxFontSizeInPx]="20"
                  [scaleDownLinkedKey]="viewModel.variantId$ | async"
                  [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
                  {{
                    viewModel
                      | getExpandedGridSize: (viewModel.spoofedColViewModel$ | async) : true
                      | async
                      | lowercase
                      | trimSafely
                  }}
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="pricing-container" [style.color]="viewModel.bodyTextColor$ | async">
          <app-column-primary-price
            [menu]="viewModel.cardStackMenu$ | async"
            [section]="viewModel.cardStack$ | async"
            [colVm]="viewModel.spoofedColViewModel$ | async"
            [rowVm]="viewModel.cardData$ | async"
            [locConfig]="viewModel.locationConfig$ | async"
            [locationId]="viewModel.locationId$ | async"
            [productStylingVm]="viewModel">
          </app-column-primary-price>
        </div>
      </div>
    </div>
  </div>
</div>
