<div
  #headerContainer
  [ngClass]="menu | productMenuBasicMethod : 'getHeaderWrapperClass'"
  [ngStyle]="{
    color: menu?.hydratedTheme?.themeFeatures?.titleTextColor ? menu?.menuOptions?.titleTextColor : ''
  }">
  <div class="header-container">
    <div
      class="menu-title"
      [hidden]="menu?.menuOptions?.hideTitle || !menu?.configurationTitle"
      [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
      {{ menu?.configurationTitle }}
    </div>
  </div>
</div>
