<app-label-inflator
  #labelCalculator
  hidden
  class="print-card-label"
  [columnLabel]="false"
  [labelComponentInterface]="cardData"
  [reset]="false">
</app-label-inflator>

<div class="main-content">
  <div
    class="card-strain-type"
    [class.dark-text]="!(viewModel.cardBorderColor$ | async | isDarkColor)"
    [class.light-text]="viewModel.cardBorderColor$ | async | isDarkColor">
    <div
      *ngIf="
        !(cardData | sectionRowViewModelBasicMethod : 'isNonCannabinoidVariant') &&
        (cardData | sectionRowViewModelBasicMethod : 'getReadableStrainType') as strainType
      ">
      {{ strainType }}
    </div>
  </div>
  <div class="middle-content" [style.color]="viewModel.bodyTextColor$ | async">
    <div class="majority-of-content">
      <div class="company-logo-container">
        <img
          *ngIf="viewModel.showCompanyLogo$ | async"
          appImage
          class="company-logo"
          [asset]="viewModel.companyLogo$ | async"
          alt="company logo"
          src="" />
      </div>
      <div class="product-image-container">
        <img
          *ngIf="viewModel.cardAssetUrl$ | async as cardAssetUrl"
          class="product-image"
          [src]="cardAssetUrl"
          alt="product image" />
      </div>
      <div class="name-and-brand">
        <div class="product-name" [style.color]="viewModel.headerTextColor$ | async">
          {{ cardData | sectionRowViewModelBasicMethod : 'getRowTitle' }}
        </div>
        <div *ngIf="cardData | sectionRowViewModelBasicMethod : 'getBrand' as brand" class="product-brand">
          {{ brand }}
        </div>
      </div>
      <div class="cannabinoids-badges-label">
        <div
          *ngIf="
            !(cardData | sectionRowViewModelBasicMethod : 'isNonCannabinoidVariant') &&
            ((cardData | sectionRowViewModelBasicMethod : 'getThc') ||
              (cardData | sectionRowViewModelBasicMethod : 'getCbd'))
          "
          class="cannabinoids"
          [style.color]="(viewModel.cardAccentColor$ | async) || (viewModel.cardBorderColor$ | async)">
          <div *ngIf="cardData | sectionRowViewModelBasicMethod : 'getThc' as thc" class="cannabinoid-container">
            <div
              class="value"
              appScaleDownText
              [scaleDownLinkedKey]="viewModel.variantId$ | async"
              [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
              {{ thc }}
            </div>
            <div class="title">THC</div>
          </div>
          <div *ngIf="cardData | sectionRowViewModelBasicMethod : 'getCbd' as cbd" class="cannabinoid-container">
            <div
              class="value"
              appScaleDownText
              [scaleDownLinkedKey]="viewModel.variantId$ | async"
              [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
              {{ cbd }}
            </div>
            <div class="title">CBD</div>
          </div>
        </div>
        <div
          *ngIf="viewModel.strainName$ | async as strainName"
          class="strain-name"
          [style.color]="(viewModel.cardAccentColor$ | async) || (viewModel.cardBorderColor$ | async)">
          {{ strainName }}
        </div>
        <div *ngIf="viewModel.badgeOrLabelVisible$ | async" class="badges-and-label">
          <img
            *ngFor="let variantBadge of cardData | printCardBadges"
            appImage
            class="badge"
            [asset]="variantBadge?.image"
            alt="badge"
            src="" />
          <app-label-inflator
            *ngIf="viewModel.labelText$ | async"
            class="label"
            [columnLabel]="false"
            [labelComponentInterface]="cardData"
            [reset]="false">
          </app-label-inflator>
        </div>
      </div>
      <app-print-card-description-container
        [cardData]="cardData"
        [bodyTextColor]="viewModel.bodyTextColor$ | async"></app-print-card-description-container>
    </div>
    <div class="anchored-to-bottom">
      <div class="empty-space"></div>
      <div class="product-price">
        <app-column-primary-price
          [menu]="viewModel.cardStackMenu$ | async"
          [section]="viewModel.cardStack$ | async"
          [colVm]="viewModel.spoofedColViewModel$ | async"
          [rowVm]="viewModel.cardData$ | async"
          [locConfig]="viewModel.locationConfig$ | async"
          [locationId]="viewModel.locationId$ | async"
          [productStylingVm]="viewModel">
        </app-column-primary-price>
      </div>
      <div>
        <div class="product-quantity-and-size">
          <div
            *ngIf="!(viewModel.isAccessory$ | async) && !(viewModel.isNonCannabinoidOtherVariant$ | async)"
            class="product-card-size"
            [style.color]="viewModel.bodyTextColor$ | async">
            {{ viewModel.quantityAndSizeText$ | async | trimSafely }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="card-product-type"
    [class.dark-text]="!(viewModel.cardBorderColor$ | async | isDarkColor)"
    [class.light-text]="viewModel.cardBorderColor$ | async | isDarkColor">
    <div *ngIf="viewModel.bottomText$ | async as bottomText">
      {{ bottomText }}
    </div>
  </div>
</div>
