import { LabelData } from './label-data';
import { EmptyLabelStack } from '../menu/section/label-stacks/empty-label-stack';

export class EmptyLabelData extends LabelData {

  constructor() {
    super();
    this.section = new EmptyLabelStack();
  }

}
