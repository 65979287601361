import { PrintMenu } from '../print-menu';
import { Type } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { PrintHeaderLayoutType } from '../../enum/shared/print-header-layout-type.enum';
import { MenuSectionHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section-header/menu-section-header.component';
import { PrintFooterLayoutType } from '../../enum/shared/print-footer-layout-type.enum';
import { ProductMenuFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/product-menu-footer.component';
import { ColumnWidth } from '../../enum/shared/column-width.enum';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { ColWidth } from '../../shared/col-width';
import { SectionColumnCannabinoidType, SectionColumnProductInfoType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { ProductSectionHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section-header/product-section-header/product-section-header.component';
import { PlantlifeNonSmokableFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/plantlife-non-smokable-footer/plantlife-non-smokable-footer.component';
import { StrainType } from '../../enum/dto/strain-classification.enum';
import { MenuHeaderPlantlifeNonSmokableComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-plantlife/menu-header-plantlife-non-smokable/menu-header-plantlife-non-smokable.component';
import { MenuHeaderPlantlifeNonSmokableEmptyComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-plantlife/menu-header-platlife-non-smokable-empty/menu-header-plantlife-non-smokable-empty.component';
import { PlantlifeNonSmokableEmptyFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/plantlife-non-smokable-footer/plantlife-non-smokable-empty-footer/plantlife-non-smokable-empty-footer.component';
import { SectionColumnConfigProductInfoKey, SectionColumnConfigState } from '../section/section-column-config';
import { PlantlifeNonSmokableEmptySection } from '../section/plantlife-non-smokable-empty-section';
import { EmptySection } from '../section/empty-section';
import { Section } from '../section/section';
import { SortVariantUtils } from '../../../utils/sort-variant-utils';

export class PlantlifeNonSmokableMenu extends PrintMenu {

  protected override deserializeSections() {
    super.deserializeSections();
    this.fixProductBrands(this.sections);
  }

  protected override deserializeTemplateSections() {
    super.deserializeTemplateSections();
    this.fixProductBrands(this.templateSections);
  }

  override getEmptySectionType(): Type<EmptySection> {
    return PlantlifeNonSmokableEmptySection;
  }

  getPageTopMargin(): string {
    return '0.25in';
  }

  getPageBottomMargin(): string {
    return '0.25in';
  }

  getPageLeftMargin(): string {
    return '0.25in';
  }

  getPageRightMargin(): string {
    return '0.25in';
  }

  getTitleSectionTopMargin(): string {
    return '0';
  }

  getTitleSectionBottomMargin(): string {
    return '0';
  }

  getThemeClass(): string {
    return 'plantlife-non-smokable-theme';
  }

  getDisplayPadding(): string {
    return '0rem';
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  shutOffBackgroundAsset(): boolean {
    return true;
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderPlantlifeNonSmokableComponent;
  }

  getEmptyHeaderReplacement(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderPlantlifeNonSmokableEmptyComponent;
  }

  getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.AllPages;
  }

  getSectionsHeaderType(): Type<MenuSectionHeaderComponent> {
    return ProductSectionHeaderComponent;
  }

  getPrintFooterLayoutType(): PrintFooterLayoutType {
    return this.menuOptions?.printFooterLayout || PrintFooterLayoutType.AllPagesFixed;
  }

  getFooterType(): Type<ProductMenuFooterComponent> {
    return PlantlifeNonSmokableFooterComponent;
  }

  getEmptyFooterReplacement(): Type<ProductMenuFooterComponent> {
    return PlantlifeNonSmokableEmptyFooterComponent;
  }

  getTypeColumnTitle(col: SectionColumnViewModel): string {
    return '';
  }

  getSizeColumnTitle(col: SectionColumnViewModel): string {
    return '';
  }

  getPriceColumnTitle(col: SectionColumnViewModel): string {
    return '$';
  }

  getColWidths(sectionRowViewModel: SectionRowViewModel, stdPercentage: number = ColumnWidth.Default): ColWidth[] {
    const colWidths = super.getColWidths(sectionRowViewModel, stdPercentage);
    const strainClass = new ColWidth(SectionColumnProductInfoType.StrainType, 10);
    const cbdAndThc = new ColWidth(SectionColumnCannabinoidType.THCAndCBD, 20);
    const price = new ColWidth(SectionColumnProductInfoType.VariantPrice, 15);
    const size = new ColWidth(SectionColumnProductInfoType.Size, 13);
    colWidths.splice(colWidths.findIndex(it => it.type === SectionColumnProductInfoType.StrainType), 1, strainClass);
    colWidths.splice(colWidths.findIndex(it => it.type === SectionColumnCannabinoidType.THCAndCBD), 1, cbdAndThc);
    colWidths.splice(colWidths.findIndex(it => it.type === SectionColumnProductInfoType.VariantPrice), 1, price);
    colWidths.splice(colWidths.findIndex(it => it.type === SectionColumnProductInfoType.Size), 1, size);
    return colWidths;
  }

  override getThemeSpecifiedColumnViewModels(
    sectionRowViewModels: SectionRowViewModel[],
    rowViewModel: SectionRowViewModel,
    widths: ColWidth[] = this.getColWidths(rowViewModel)
  ): SectionColumnViewModel[] {
    const colVMs = [];
    const layoutType = rowViewModel?.getLayoutType();
    const colConfig = rowViewModel?.section?.columnConfig;
    const productTitle = new SectionColumnViewModel(layoutType, null);
    productTitle.columnType = SectionColumnProductInfoType.ProductTitle;
    const size = new SectionColumnViewModel(layoutType, null);
    size.columnType = SectionColumnProductInfoType.Size;
    size.widthPercentage = widths.find(it => it.type === SectionColumnProductInfoType.Size).widthPercentage;
    const strainType = new SectionColumnViewModel(layoutType, null);
    strainType.columnType = SectionColumnProductInfoType.StrainType;
    strainType.widthPercentage = widths.find(it => it.type === SectionColumnProductInfoType.StrainType).widthPercentage;
    const asset = new SectionColumnViewModel(layoutType, null);
    asset.columnConfig = colConfig.get(SectionColumnConfigProductInfoKey.Asset);
    asset.columnType = SectionColumnProductInfoType.Asset;
    asset.widthPercentage = widths.find(it => it.type === SectionColumnProductInfoType.Asset).widthPercentage;
    const thcAndCbd = new SectionColumnViewModel(layoutType, null);
    thcAndCbd.columnType = SectionColumnCannabinoidType.THCAndCBD;
    thcAndCbd.columnTitle = 'THC|CBD';
    thcAndCbd.widthPercentage = widths.find(it => it.type === SectionColumnCannabinoidType.THCAndCBD).widthPercentage;
    const priceCol = new SectionColumnViewModel(layoutType, null);
    priceCol.columnType = SectionColumnProductInfoType.VariantPrice;
    priceCol.widthPercentage = widths.find(it => it.type === SectionColumnProductInfoType.VariantPrice).widthPercentage;
    const assetConfig = rowViewModel?.section?.columnConfig?.get(SectionColumnConfigProductInfoKey.Asset);
    const areAssetsEnabled = assetConfig?.defaultState === SectionColumnConfigState.On;
    const assetClassColumns = [];
    areAssetsEnabled ? assetClassColumns.push(asset) : assetClassColumns.push(strainType);
    colVMs.push(...assetClassColumns, productTitle, size, thcAndCbd, priceCol);
    return colVMs;
  }

  getShowStrainTypes(): boolean {
    return false;
  }

  getUniqueClassificationList(): StrainType[] {
    // Plantlife has requested that we remove the Blend classification from non-smokable legend
    return super.getUniqueClassificationList()
      ?.filter(sc => sc !== StrainType.Blend)
      ?.sort(SortVariantUtils.plantlifeStrainTypeSort);
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return false;
  }

  getAllowedBadgeCount(): number {
    return 0; // zero means unlimited
  }

  getShowBadgesInline(): boolean {
    return true;
  }

  getShowBadgesInlineOnTitleLineElseSubtitle(): boolean {
    return false;
  }

  getShowBadgesUnderSubtitle(): boolean {
    return false;
  }

  getFontFaceList(): string[] {
    return ['bebas-neue-pro'];
  }

  getShowClassificationsInAssetColumn(): boolean {
    return true;
  }

  getSectionTitleUnderlineColor(section: Section): string {
    return '#222222';
  }

}
