import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { DougArBusinessCardStackBackgroundViewModel } from './doug-ar-business-card-stack-background-view-model';
import { PrintCardBackgroundComponent } from '../../print-card-background.component';

@Component({
  selector: 'lib-doug-ar-business-card-stack-business',
  templateUrl: './doug-ar-business-card-stack-background.component.html',
  styleUrls: ['./doug-ar-business-card-stack-background.component.scss'],
  providers: [DougArBusinessCardStackBackgroundViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DougArBusinessCardStackBackgroundComponent extends PrintCardBackgroundComponent {

  constructor(
    public viewModel: DougArBusinessCardStackBackgroundViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
    this.rendered.next(false);
  }

}
