import { Pipe, PipeTransform } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { Menu } from '../../../models/menu/menu';
import type { SectionRowViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { SectionColumnViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';

@Pipe({
  name: 'getGridVariantThc'
})
export class GetGridVariantThcPipe implements PipeTransform {

  transform(
    colVM: SectionColumnViewModel,
    menu$: Observable<Menu>,
    rowVM$: Observable<SectionRowViewModel>
  ): Observable<string> {
    return combineLatest([menu$, rowVM$]).pipe(
      map(([menu, rowVM]) => {
        const variant = rowVM?.getVariantMatch(colVM);
        return variant?.getCannabinoid(menu, rowVM?.companyConfig, 'THC');
      })
    );
  }

}
