import { Pipe, PipeTransform } from '@angular/core';
import { LabelComponentInterface } from '../components/label-component-interface';

@Pipe({
  name: 'getShutOffLabelForLabelComponent'
})
export class GetShutOffLabelForLabelComponentPipe implements PipeTransform {

  transform(labelComponentInterface: LabelComponentInterface): boolean {
    return labelComponentInterface?.getShutOffLabelForLabelComponent() || false;
  }

}
