import { Pipe, PipeTransform } from '@angular/core';
import { FikaIndexCardStackContentViewModel } from '../fika-index-card-stack-content-view-model';
import { Observable, of } from 'rxjs';
import { PrintCardColumnViewModel } from '../../../../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/print-card-column-view-model';

@Pipe({
  name: 'getFikaGridSize'
})
export class GetFikaGridSizePipe implements PipeTransform {

  transform(
    viewModel: FikaIndexCardStackContentViewModel,
    spoofedGridColVM: PrintCardColumnViewModel
  ): Observable<string|null> {
    return viewModel?.getFikaGridSize$(spoofedGridColVM) || of(null);
  }

}
