import { Subscribable } from './subscribable';
import { AfterViewInit, Directive, OnDestroy, OnInit } from '@angular/core';
import { Viewable } from '../protocols/viewable';
import { BehaviorSubject } from 'rxjs';
import { TypeUtils } from '../../utils/type-utils';

@Directive()
export abstract class BaseDirective extends Subscribable implements OnInit, AfterViewInit, OnDestroy, Viewable {

  public rendered = new BehaviorSubject<boolean>(false);
  Types = TypeUtils;
  abstract setupViews();
  abstract setupBindings();

  ngOnInit(): void {
    window.injector.isReadyService.globalSignalCreated(this.rendered);
    this.setupViews();
  }

  ngAfterViewInit(): void {
    this.rendered.next(true);
    this.setupBindings();
  }

  ngOnDestroy() {
    window.injector.isReadyService.globalSignalDestroyed(this.rendered);
    super.ngOnDestroy();
  }

}
