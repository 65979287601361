import { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { PrintCardBackgroundComponent } from '../../../../../modules/print-cards/components/print-cards/backgrounds/print-card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/print-card-content.component';
import { FireAndFlowerDefaultCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/print-cards/backgrounds/custom-cards-2x2/fire-and-flower-default-card-stack-background/fire-and-flower-default-card-stack-background.component';
import { FireAndFlowerDefaultCardStackContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/custom-cards-2x2/fire-and-flower-default-card-stack-content/fire-and-flower-default-card-stack-content.component';
import { FireAndFlowerOldStyleCardStack } from './fire-and-flower-old-style-card-stack';

export class FireAndFlowerDefaultCardStack extends FireAndFlowerOldStyleCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<PrintCardBackgroundComponent> | null {
    return FireAndFlowerDefaultCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return FireAndFlowerDefaultCardStackContentComponent;
  }

}
