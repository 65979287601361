import moment from 'moment-timezone';
import { exists } from '../functions/exists';

// @dynamic
export class DateUtils {

  static readonly DATETIME_LOCAL_SECONDS = 'YYYY-MM-DDTHH:mm:ss';

  // Comparisons

  static unixAfterDaysAgo(ts: number, days: number): boolean {
    return (DateUtils.nowInUnixSeconds() - days * DateUtils.unixOneDay()) < ts;
  }

  static unixAfterHoursAgo(ts: number, hours: number): boolean {
    return (DateUtils.nowInUnixSeconds() - hours * DateUtils.unixOneHour()) < ts;
  }

  static unixAfterMinutesAgo(ts: number, minutes: number): boolean {
    return (DateUtils.nowInUnixSeconds() - minutes * 60) < ts;
  }

  // Formatters

  static formatUnixToDateTime(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('lll');
  }

  static formatUnixToTime(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('LT');
  }

  static formatUnixToDate(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('ll');
  }

  static formatUnixToShorthandDate(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('MMM D');
  }

  static formatUnixForDateInput(d: number): string {
    const utcMoment = moment.unix(d);
    return moment(utcMoment).local().format('YYYY-MM-DD');
  }

  // Unix Helpers

  static nowInUnixMilliseconds(): number {
    return moment().valueOf();
  }

  static nowInUnixSeconds(): number {
    return moment().unix();
  }

  static unixOneMonth(): number {
    return 30 * 24 * 60 * 60;
  }

  static unixOneWeek(): number {
    return 7 * 24 * 60 * 60;
  }

  static unixOneDay(): number {
    return 24 * 60 * 60;
  }

  static unixOneHour(): number {
    return 60 * 60;
  }

  static currentWeekday(timezone?: string): number {
    const date = exists(timezone) ? moment().tz(timezone) : moment();
    return date?.day();
  }

  static unixOneMinute(): number {
    return 60;
  }

  // Time Now - Local Time

  static getSecondsSinceStartOfDay(date?: moment.Moment): number {
    const forThisDate = exists(date) ? date : moment();
    const hrsInSecs = forThisDate.hours() * 3600;
    const minsInSecs = forThisDate.minutes() * 60;
    const secs = forThisDate.seconds();
    return (hrsInSecs + minsInSecs + secs);
  }

  static getMinutesSinceStartOfDay() {
    const timeNow = new Date();
    return timeNow.getHours() * 60 + timeNow.getMinutes();
  }

  static getCurrentDayOfWeek(): number {
    const timeNow = new Date();
    return timeNow.getDay();
  }

}
