import { PrefetchMediaAsset } from './prefetch-media-asset';
import { AssetUrl } from './asset-url';
import { MediaSectionAssetUrl } from './media-section-asset-url';

export class MediaSectionAsset extends PrefetchMediaAsset {

  protected override deserializeUrls(filteredUrls: AssetUrl[]): void {
    this.urls = window?.injector?.Deserialize?.arrayOf(MediaSectionAssetUrl, filteredUrls);
  }

}
