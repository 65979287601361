import { Pipe, PipeTransform } from '@angular/core';
import type { PrintMenu } from '../../../models/menu/print-menu';

@Pipe({
  name: 'printMenuBasicMethod'
})
export class PrintMenuBasicMethodPipe implements PipeTransform {

  transform(
    printMenu: PrintMenu,
    methodKey: string,
    argument?: any
  ): any {
    return printMenu?.[methodKey]?.(argument);
  }

}
