import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/menu';

@Pipe({
  name: 'backgroundColorAssetBorderRadius'
})
export class BackgroundColorAssetBorderRadiusPipe implements PipeTransform {

  transform(menu: Menu): string|null {
    return menu?.getColorBackgroundAssetBorderRadius();
  }

}
