import { PostCardStack } from './post-card-stack';
import { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { PrintCardBackgroundComponent } from '../../../../../modules/print-cards/components/print-cards/backgrounds/print-card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/print-card-content.component';
import { SessionsPostCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/print-cards/backgrounds/post-cards-4x6/sessions-post-card-stack-background/sessions-post-card-stack-background.component';
import { SessionsPostCardStackContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/post-cards-4x6/sessions-post-card-stack-content/sessions-post-card-stack-content.component';

export class SessionsPostCardStack extends PostCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<PrintCardBackgroundComponent> | null {
    return SessionsPostCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return SessionsPostCardStackContentComponent;
  }

}
