import { BaseViewModel } from '../../../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { OrientationService } from '../../../../../../../services/orientation.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { Card } from '../../../../../../../models/menu/product-menu-cards/card';
import { map } from 'rxjs/operators';
import { MarketingMenu } from '../../../../../../../models/menu/marketing-menu';

@Injectable()
export class CardGridViewModel extends BaseViewModel {

  private menu = new BehaviorSubject<MarketingMenu | null>(null);
  private cards = new BehaviorSubject<Card[]>([]);
  public cardCount$ = this.cards.pipe(map(c => c.length));
  public portrait$ = this.orientationService.isPortrait$;
  private data$ = combineLatest([this.portrait$, this.cards]);
  public hasFooter$ = this.menu.pipe(map(m => m?.getShowFooter()));
  // Portrait
  public portrait1to2$ = this.data$.pipe(map(([p, cards]) => p && (cards?.length === 1 || cards?.length === 2)));
  public portrait3to4$ = this.data$.pipe(map(([p, cards]) => p && (cards?.length === 3 || cards?.length === 4)));
  public portrait5to6$ = this.data$.pipe(map(([p, cards]) => p && (cards?.length === 5 || cards?.length === 6)));
  public portrait7to9$ = this.data$.pipe(
    map(([p, cards]) => p && (cards?.length === 7 || cards?.length === 8 || cards?.length === 9))
  );
  public portrait10to12$ = this.data$.pipe(
    map(([p, cards]) => p && (cards?.length === 10 || cards?.length === 11 || cards?.length === 12))
  );
  // Landscape
  public landscape1to3$ = this.data$.pipe(
    map(([p, cards]) => !p && (cards?.length === 1 || cards?.length === 2 || cards?.length === 3))
  );
  public landscape4$ = this.data$.pipe(map(([p, cards]) => !p && (cards?.length === 4)));
  public landscape5to8$ = this.data$.pipe(
    map(([p, cards]) => {
      return !p && (cards?.length === 5 || cards?.length === 6 || cards?.length === 7 || cards?.length === 8);
    })
  );
  public landscape9to12$ = this.data$.pipe(
    map(([p, cards]) => {
      return !p && (cards?.length === 9 || cards?.length === 10 || cards?.length === 11 || cards?.length === 12);
    })
  );

  constructor(
    private orientationService: OrientationService
  ) {
    super();
  }

  connectToCards = (c: Card[]) => this.cards.next(c);
  connectToMenu = (m: MarketingMenu) => this.menu.next(m);

}
