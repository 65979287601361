import { Component } from '@angular/core';
import { ProductMenuFooterComponent } from '../product-menu-footer.component';

@Component({
  selector: 'app-centered-logo-menu-footer',
  templateUrl: './centered-logo-menu-footer.component.html',
  styleUrls: ['./centered-logo-menu-footer.component.scss']
})
export class CenteredLogoMenuFooterComponent extends ProductMenuFooterComponent {
}
