@charset "UTF-8";

/* src/app/modules/print-cards/components/print-cards/card-content/custom-cards-2x2/fire-and-flower-member-card-stack-content/fire-and-flower-member-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.card-content {
  font-size: 0.75rem;
  font-family: Arial, sans-serif;
  font-weight: 800;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.card-content .first-third {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.15rem;
}
.card-content .first-third .brand {
  font-size: 1.125rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding: 0 0.75rem;
  line-height: 1;
}
.card-content .first-third .title-size-cannabinoids {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  column-gap: 0.45rem;
  row-gap: 0rem;
  padding: 0 0.75rem;
  letter-spacing: -0.01rem;
}
.card-content .first-third .title-size-cannabinoids .title-size {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 0.45rem;
}
.card-content .first-third .title-size-cannabinoids .title-size .product-card-variant-title {
  overflow: clip;
  overflow-clip-margin: 0;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.card-content .first-third .title-size-cannabinoids .title-size .product-card-size {
  width: max-content;
  white-space: nowrap;
}
.card-content .first-third .title-size-cannabinoids .cannabinoids {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 0.45rem;
  row-gap: 0rem;
}
.card-content .first-third .title-size-cannabinoids .cannabinoids .product-cannabinoid {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: max-content;
  column-gap: 0.05rem;
}
.card-content .second-third {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  height: 3.75rem;
  min-height: 3.75rem;
}
.card-content .last-third {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  gap: 0.5rem;
  height: 4.275rem;
  min-height: 4.275rem;
}
.card-content .last-third .regular-price {
  font-size: 0.75rem;
}
.card-content ::ng-deep .product-column-general {
  overflow-x: visible !important;
}
.card-content ::ng-deep .force-bold-style * {
  font-family: Arial, sans-serif;
  font-weight: 800;
}
.card-content ::ng-deep .force-italic-style * {
  font-style: italic !important;
}
/*# sourceMappingURL=fire-and-flower-member-card-stack-content.component.css.map */
