import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ProductMenu } from '../../../../../../../models/menu/product-menu';
import { MenuHeaderComponent } from '../../../menu/building-blocks/menu-header/menu-header.component';
import { ResizeObserver } from '@juggle/resize-observer';
import { HtmlUtils } from '../../../../../../../utils/html-utils';

@Component({
  selector: 'app-product-menu-header',
  templateUrl: './product-menu-header.component.html',
  providers: [{ provide: MenuHeaderComponent, useExisting: ProductMenuHeaderComponent }]
})
export class ProductMenuHeaderComponent extends MenuHeaderComponent {

  @ViewChild('headerContainer') public headerContainer: ElementRef;
  @Input() menu: ProductMenu;
  @Output() headerHeightInPx: EventEmitter<number> = new EventEmitter<number>(true);
  public ro: ResizeObserver;

  setupBindings() {
    this.observeHeaderContainer();
  }

  observeHeaderContainer() {
    this.ro = new ResizeObserver((entries, _) => {
      HtmlUtils.getElementTotalHeightAsync(this.headerContainer.nativeElement)
        .then(height => this.headerHeightInPx.emit(height));
    });
    // Element for which to observe height and width
    if (!!this.headerContainer) {
      this.ro.observe(this.headerContainer.nativeElement);
    }
  }

  destroy() {
    super.destroy();
    this.ro?.disconnect();
  }

}
