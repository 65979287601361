import { Deserializable } from '../../protocols/deserializable';
import { MenuThemeId } from '../../enum/dto/theme.enum';
import { Asset } from '../../image/dto/asset';
import { ThemeFeatures } from './theme-features';
import { MenuType } from '../../enum/dto/menu-type.enum';
import { MenuColumnCountConfig } from './menu-column-count-config';
import { SectionColumnConfig, SectionColumnConfigKey } from '../section/section-column-config';
import { PrintConfig } from './print-config';

export class Theme implements Deserializable {

  public id: MenuThemeId;
  public name: string;
  public description: string;
  public previewImages: Asset[];
  public supportedMenuTypes: MenuType[];
  public themeFeatures: ThemeFeatures;
  public menuColumnCountConfig: MenuColumnCountConfig;
  public sectionColumnConfig: Map<SectionColumnConfigKey, SectionColumnConfig | null>;
  public menuSubType: string;
  public printConfig: PrintConfig;

  constructor(themeId?: MenuThemeId) {
    if (themeId) {
      this.id = themeId;
    }
  }

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.previewImages = Deserialize?.arrayOf(Asset, this.previewImages);
    this.themeFeatures = Deserialize?.instanceOf(ThemeFeatures, this.themeFeatures);
    this.menuColumnCountConfig = Deserialize?.instanceOf(MenuColumnCountConfig, this.menuColumnCountConfig);
    if (!this.sectionColumnConfig) {
      this.sectionColumnConfig = new Map<SectionColumnConfigKey, SectionColumnConfig>();
    }
    if (!(this.sectionColumnConfig instanceof Map)) {
      this.sectionColumnConfig = Deserialize?.mapOf(SectionColumnConfig, this.sectionColumnConfig);
    }
    this.printConfig = Deserialize?.instanceOf(PrintConfig, this.printConfig);
  }

}
