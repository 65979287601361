/* tslint:disable:member-ordering */
import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { VariantAssetService } from '../../../../../../../services/variant-asset-service';
import { ProductStylingViewModel } from '../../../../../../../../models/shared/product-styling-view-model';
import { SectionColumnCannabinoidType, SectionColumnType } from '../../menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { CompanyDomainModel } from '../../../../../../../../domain/company-domain-model';

@Injectable()
export class ProductSectionItemViewModel extends ProductStylingViewModel {

  constructor(
    protected companyDomainModel: CompanyDomainModel,
    variantAssetService: VariantAssetService
  ) {
    super(variantAssetService);
  }

  public readonly cannabinoidColumnTypes$ = this.companyDomainModel.enabledCannabinoids$;
  public readonly terpeneColumnTypes$ = this.companyDomainModel.enabledTerpenes$;

  public showInlineLabelOnTitleLine$ = combineLatest([
    this.menu$,
    this.rowViewModel$
  ]).pipe(
    debounceTime(1),
    map(([menu, rowViewModel]) => {
      return menu.getShowLabelsOnTitleLineElseSubtitle()
        && menu?.getShowInlineLabels()
        && !rowViewModel?.hideLabel;
    })
  );

  public showInlineLabelOnSubtitleLine$ = combineLatest([
    this.menu$,
    this.rowViewModel$
  ]).pipe(
    debounceTime(1),
    map(([menu, rowViewModel]) => {
      return !menu.getShowLabelsOnTitleLineElseSubtitle()
        && menu?.getShowInlineLabels()
        && !rowViewModel?.hideLabel;
    })
  );

  public showLabelInLabelColumn$ = this.rowViewModel$.pipe(
    debounceTime(1),
    map(rowViewModel => {
      return !rowViewModel?.hideLabel;
    })
  );

  public trackByCannabinoidColumnTypeId(index: number, item: SectionColumnCannabinoidType) {
    return item;
  }

  public trackByTerpeneColumnTypeId(index: number, item: SectionColumnType) {
    return item;
  }

}
