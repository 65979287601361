import { Pipe, PipeTransform } from '@angular/core';
import type { SectionWithProducts } from '../../../models/menu/section/section-with-products';

@Pipe({
  name: 'isInLineMode'
})
export class IsInLineModePipe implements PipeTransform {

  transform(section: SectionWithProducts): boolean {
    return section?.isInLineMode();
  }

}
