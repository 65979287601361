import { Injectable } from '@angular/core';
import { BaseService } from '../../models/base/base-service';

@Injectable()
export class RenderContextService extends BaseService {

  public contextId: string;

  setContextId(contextId: string): void {
    this.contextId = contextId;
  }

}
