import { PostCardStack } from './post-card-stack';
import { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { PrintCardBackgroundComponent } from '../../../../../modules/print-cards/components/print-cards/backgrounds/print-card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/print-card-content.component';
import { MissJonesCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/print-cards/backgrounds/post-cards-4x6/miss-jones-card-stack-background/miss-jones-card-stack-background.component';
import { MissJonesPostCardStackContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/post-cards-4x6/miss-jones-post-card-stack-content/miss-jones-post-card-stack-content.component';

export class MissJonesPostCardStack extends PostCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<PrintCardBackgroundComponent> | null {
    return MissJonesCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return MissJonesPostCardStackContentComponent;
  }

}
