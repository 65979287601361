import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Menu } from '../../../models/menu/menu';
import { IsReadyService } from '../../services/is-ready.service';
import { PrintCardDomainModel } from '../../../domain/print-card-domain-model';

@Injectable({ providedIn: 'root' })
export class IsPrintCardsReadyService extends IsReadyService {

  constructor(
    private printCardDomainModel: PrintCardDomainModel
  ) {
    super();
  }

  private variantMetadataLoaded$ = this.printCardDomainModel.variantMetadataLoaded$;

  getApiResponded$(): Observable<boolean> {
    return this.printCardDomainModel.apiRespondedWithMenu$;
  }

  getMenu$(): Observable<Menu> {
    return this.printCardDomainModel.printCardMenu$;
  }

  getIsReady$(): Observable<boolean> {
    return combineLatest([
      this.timeout$,
      this.variantMetadataLoaded$,
      this.rendered$,
      this.fontsLoaded$
    ]).pipe(
      map(([timedOut, variantImageDataFetched, rendered, fontsLoaded]) => {
        const loaded = variantImageDataFetched && rendered && fontsLoaded;
        return loaded || timedOut;
      })
    );
  }

}
