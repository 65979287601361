import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { PrintLabelBackgroundViewModel } from './print-label-background-view-model';
import { LabelData } from '../../../../../models/print-labels/label-data';
import { PrintCardBackgroundComponent } from '../../print-cards/backgrounds/print-card-background.component';

@Component({ template: '' })
export abstract class PrintLabelBackgroundComponent extends PrintCardBackgroundComponent {

  constructor(
    public override viewModel: PrintLabelBackgroundViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

  @Input() override cardData: LabelData;

}
