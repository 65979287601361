import { Pipe, PipeTransform } from '@angular/core';
import { SectionColumnViewModel } from '../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';

@Pipe({
  name: 'columnWidth'
})
export class ColumnWidthPipe implements PipeTransform {

  transform(
    colVm: SectionColumnViewModel,
    ignoreColConfigWidth: boolean,
    fudgeFactor: number = 1
  ): number|null {
    return ignoreColConfigWidth ? null : (Number.parseFloat(colVm.widthPercentage) * fudgeFactor);
  }

}
