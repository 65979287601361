import { UpgradedMarketingLoopingContentMenu } from '../upgraded-marketing-looping-content-menu';

export class MarketingSmartPlaylistMenu extends UpgradedMarketingLoopingContentMenu {

  override template?: MarketingSmartPlaylistMenu;

  calculateMarketingLoopDurationInSeconds(): number {
    // Summing the duration of each product on the menu
    let sum = 0;
    for (const [key, value] of (this?.options?.rotationInterval?.entries() || [])) {
      const section = this.sections?.find(it => it?.id === key);
      if (section?.isVisible(this)) sum += value;
    }
    return Number.isInteger(sum) ? sum : Number(sum.toFixed(2));
  }

  override isEmpty(): boolean {
    return !this.hasItemsEnabledInRotationInterval() || !this.hasVisibleSections();
  }

  hasEnabledContentButAllLinkedProductsAreOutOfStock(): boolean {
    return this.hasItemsEnabledInRotationInterval() && !this.hasVisibleSections();
  }

  hasVisibleSections(): boolean {
    return this.sections?.some(section => section?.isVisible(this));
  }

}
