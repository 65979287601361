/* src/app/modules/print-cards/components/print-cards/backgrounds/post-cards-4x6/sessions-post-card-stack-background/sessions-post-card-stack-background.component.scss */
:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}
:host .color-strip {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  background-color: #ffffff;
}
:host .color-strip .upper-container {
  background-color: #b9b9b9;
  height: 19.75rem;
}
:host .color-strip .lower-container {
  background-color: #b9b9b9;
  flex: 1;
}
:host .main-content {
  flex: 1;
}
:host .main-content .print-card-background-asset {
  height: 100%;
  width: 100%;
}
/*# sourceMappingURL=sessions-post-card-stack-background.component.css.map */
