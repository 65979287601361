import { ProductMenu } from '../product-menu';
import { SectionColumnProductInfoType, SectionColumnType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { Section } from '../section/section';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { exists } from '../../../functions/exists';
import { Type } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { MenuHeaderTitleComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title.component';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';

export class SuperLemonHazeMenu extends ProductMenu {

  getThemeClass(): string {
    return 'super-lemon-haze-theme';
  }

  getShowHeader(): boolean {
    return true;
  }

  getShowFooter(): boolean {
    return true;
  }

  getShowProductSubtitle(): boolean {
    return false;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleComponent;
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.Badge, 0],
      [SectionColumnProductInfoType.ProductTitle, 1],
      [SectionColumnProductInfoType.StrainType, 2],
      [SectionColumnProductInfoType.Brand, 3],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(4),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(5),
      [SectionColumnProductInfoType.QuantityAndSize, 6],
      [SectionColumnProductInfoType.Quantity, 7],
      [SectionColumnProductInfoType.Size, 8],
      [SectionColumnProductInfoType.VariantPrice, 9],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 10],
    ]);
  }

  getShouldInflateColumnHeaderForRow(
    s: Section,
    col: SectionColumnViewModel,
    rows: SectionRowViewModel[]
  ): boolean {
    const badge = col?.columnType === SectionColumnProductInfoType.Badge;
    return !badge || (badge && exists(col?.columnTitle));
  }

  getFontFaceList(): string[] {
    return ['open-sans'];
  }

  getTitleSectionBottomMargin(): string {
    return '0.75rem';
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
      return ColumnUtils.oneBigGrouping();
  }

}
