<div
  *ngIf="colVm?.columnType === Types.SectionColumnProductInfoType.Size"
  appScaleDownText
  [scaleEnabled]="!(menu | isPrintMenu)"
  [style.color]="
    ignoreUserSetTextColorAndAutoToLightOrDarkTextColor
      ? (section | textColorBasedOnSectionHeaderBackgroundColor)
      : (menu | productMenuBasicMethod: 'getSectionHeaderTextColor' : section)
  "
  [scaleDownLinkedKey]="
    section?.id +
    ((section | isInLineMode) ? Types.TextSizeLink.PricePreRollQuantitySize : Types.TextSizeLink.PreRollQuantitySize)
  "
  class="section-header-general section-header-size">
  {{ menu | productMenuBasicMethod: 'getSizeColumnTitle' : colVm }}
</div>
