<div
  class="footer-container"
  #footerContainer
  [ngClass]="menu | productMenuBasicMethod : 'getFooterWrapperClass'"
  [ngStyle]="{
    color: menu?.hydratedTheme?.themeFeatures?.footerTextColor ? menu?.menuOptions?.footerTextColor : ''
  }">
  <img
    appImage
    *ngIf="menu | productMenuBasicMethod : 'showCompanyLogo'"
    class="company-logo company-logo-padding"
    [style.zoom]="menu?.menuOptions?.titleZoom || 1.0"
    [asset]="menu?.companyLogo"
    [reset]="reset"
    alt="company logo"
    src="" />
</div>
