import { Pipe, PipeTransform } from '@angular/core';
import { CardStack } from '../../../models/menu/section/card-stacks/card-stack';

@Pipe({
  name: 'getNInternalGridCrosses'
})
export class GetNInternalGridCrossesPipe implements PipeTransform {

  transform(cardStack: CardStack, nCardsOnPage: number): number {
    const nCrossColumns = (cardStack?.getNumberOfVisibleColumnsOnPage(nCardsOnPage) ?? 1) - 1;
    const nCrossRows = (cardStack?.getNumberOfVisibleRowsOnPage(nCardsOnPage) ?? 1) - 1;
    return nCrossColumns * nCrossRows;
  }

}
