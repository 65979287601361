import { Pipe, PipeTransform } from '@angular/core';
import { ProductMenu } from '../../../models/menu/product-menu';
import type { Menu } from '../../../models/menu/menu';

@Pipe({
  name: 'isProductMenu'
})
export class IsProductMenuPipe implements PipeTransform {

  transform(value: Menu): boolean {
    return value instanceof ProductMenu;
  }

}
