<div
  class="card-header"
  [style.background-color]="viewModel.cardAccentColor$ | async"
  [style.justify-content]="(viewModel.isNonCannabinoidVariant$ | async) ? 'flex-end' : 'space-between'">
  <div
    class="header-content left-content"
    *ngIf="cardData | sectionRowViewModelBasicMethod: 'getReadableStrainType' as strainType">
    <div *ngIf="!(viewModel.isNonCannabinoidVariant$ | async)" class="strain-container">{{ strainType }}</div>
  </div>
  <div class="header-content right-content">
    <div class="product-size-and-type-container">
      <span
        *ngIf="
          viewModel.isLineItemMode$
            | cardHeaderUnitSize: viewModel.cardData$ : viewModel.spoofedGridColViewModels$ : true
            | async as unitSize
        ">
        {{ unitSize | trimSafely }}
      </span>
      {{ cardData | sectionRowViewModelBasicMethod: 'rowVariantType' | trimSafely }}
    </div>
  </div>
</div>

<div class="card-body">
  <div class="background-content" [style.background-color]="viewModel.cardColor$ | async">
    <app-print-card-background-image-with-color-overlay-and-opacity [cardData]="cardData">
    </app-print-card-background-image-with-color-overlay-and-opacity>
  </div>
  <div
    *ngIf="cardData | sectionRowViewModelBasicMethod: 'getRowTitle' as title"
    class="product-card-name"
    [style.color]="viewModel.headerTextColor$ | async">
    {{ title | trimSafely }}
  </div>
  <div class="bottom-body-content">
    <div class="card-body-left-column">
      <div class="brand-and-label">
        <div
          *ngIf="cardData | sectionRowViewModelBasicMethod: 'getBrand' as brand"
          class="product-card-brand"
          [style.max-width]="viewModel.titleMaxWidth$ | async"
          [style.color]="viewModel.bodyTextColor$ | async">
          {{ brand | trimSafely | uppercase }}
        </div>
        <app-label-inflator
          class="print-card-label"
          [class.print-card-line-mode]="viewModel.isLineItemMode$ | async"
          [class.print-card-label-3-columns]="viewModel.gridHas3Columns$ | async"
          [class.print-card-label-3-columns-ranged]="
            (viewModel.gridHas3Columns$ | async) && cardData.displayCannabinoidInRanges()
          "
          [class.default-label]="!(viewModel.labelIsFlagStyle$ | async)"
          [class.flag-label]="viewModel.labelIsFlagStyle$ | async"
          [columnLabel]="false"
          [labelComponentInterface]="cardData"
          [reset]="false">
        </app-label-inflator>
      </div>
      <div
        *ngIf="viewModel.hasBadges$ | async"
        class="badge-container"
        [style.max-width]="viewModel.badgeAndLabelMaxWidth$ | async"
        [style.align-self]="(priceIsVisible$ | async) ? 'center' : 'end'">
        <img
          *ngFor="let variantBadge of cardData | printCardBadges"
          appImage
          class="badge"
          [asset]="variantBadge?.image"
          alt="badge"
          src="" />
      </div>
      <div
        #linePrice
        *ngIf="viewModel.isLineItemMode$ | async"
        class="pricing-container"
        [style.color]="viewModel.bodyTextColor$ | async">
        <app-column-primary-price
          [menu]="viewModel.cardStackMenu$ | async"
          [section]="viewModel.cardStack$ | async"
          [colVm]="viewModel.spoofedColViewModel$ | async"
          [rowVm]="viewModel.cardData$ | async"
          [locConfig]="viewModel.locationConfig$ | async"
          [locationId]="viewModel.locationId$ | async"
          [productStylingVm]="viewModel">
        </app-column-primary-price>
      </div>
    </div>
    <div class="card-body-right-column">
      <div>
        <ng-container *ngIf="viewModel.isLineItemMode$ | async; else gridModeTable">
          <div
            *ngIf="
              !(viewModel.isNonCannabinoidVariant$ | async) && cardData
                | sectionRowViewModelBasicMethod: 'getThc' as thc
            "
            class="property-container"
            [style.color]="viewModel.bodyTextColor$ | async">
            <div class="title">THC</div>
            <div
              class="value"
              [style.width.rem]="
                (cardData | sectionRowViewModelBasicMethod: 'displayCannabinoidInRanges')
                  ? lineItemRangeCannabinoidInRem
                  : lineItemCannabinoidInRem
              "
              appScaleDownText
              [scaleDownLinkedKey]="viewModel.variantId$ | async"
              [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
              {{ thc }}
            </div>
          </div>
          <div
            *ngIf="
              !(viewModel.isNonCannabinoidVariant$ | async) && cardData
                | sectionRowViewModelBasicMethod: 'getCbd' as cbd
            "
            class="property-container"
            [style.color]="viewModel.bodyTextColor$ | async">
            <div class="title">CBD</div>
            <div
              class="value"
              [style.width.rem]="
                (cardData | sectionRowViewModelBasicMethod: 'displayCannabinoidInRanges')
                  ? lineItemRangeCannabinoidInRem
                  : lineItemCannabinoidInRem
              "
              appScaleDownText
              [scaleDownLinkedKey]="viewModel.variantId$ | async"
              [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
              {{ cbd }}
            </div>
          </div>
          <div
            *ngIf="
              !(viewModel.isNonCannabinoidVariant$ | async) && cardData
                | sectionRowViewModelBasicMethod: 'getListItemQty' as qty
            "
            class="property-container"
            [style.color]="viewModel.bodyTextColor$ | async">
            <div class="title">QTY</div>
            <div
              appScaleDownText
              class="value"
              [style.width.rem]="
                (cardData | sectionRowViewModelBasicMethod: 'displayCannabinoidInRanges')
                  ? lineItemRangeCannabinoidInRem
                  : lineItemCannabinoidInRem
              ">
              {{ qty }}
            </div>
          </div>
        </ng-container>

        <ng-template #gridModeTable>
          <div class="product-grid-header" [style.background-color]="viewModel.cardAccentColor$ | async">
            <ng-container *ngFor="let spoofedGridColVM of viewModel.spoofedGridColViewModels$ | async; let i = index">
              <div
                class="product-grid-column-header"
                [style.width.rem]="(cardData | sectionRowViewModelBasicMethod: 'displayCannabinoidInRanges') ? 5 : 3">
                {{ viewModel.getGridSize$(spoofedGridColVM) | async }}
              </div>
            </ng-container>
          </div>
          <div
            *ngIf="!(viewModel.isNonCannabinoidVariant$ | async)"
            class="property-container grid-mode"
            [style.color]="viewModel.bodyTextColor$ | async"
            [style.padding]="(viewModel.atLeastOneGridVariantOnSale$ | async) ? '0.25rem 0' : '0.5625rem 0.5rem'">
            <div class="title">THC</div>
            <div *ngFor="let spoofedGridColVM of viewModel.spoofedGridColViewModels$ | async; let i = index">
              <div
                class="value"
                [style.width.rem]="(cardData | sectionRowViewModelBasicMethod: 'displayCannabinoidInRanges') ? 5 : 3"
                appScaleDownText
                [transparentWhileScaling]="viewModel.viewingInLiveView$ | async"
                [scaleDownLinkedKey]="(viewModel.getGridVariantId$(spoofedGridColVM) | async) + 'cannabinoids'">
                {{ spoofedGridColVM | getGridVariantThc: viewModel.cardStackMenu$ : viewModel.cardData$ | async }}
              </div>
            </div>
          </div>
          <div
            *ngIf="!(viewModel.isNonCannabinoidVariant$ | async)"
            class="property-container grid-mode"
            [style.color]="viewModel.bodyTextColor$ | async"
            [style.padding]="(viewModel.atLeastOneGridVariantOnSale$ | async) ? '0.25rem 0' : '0.5625rem 0.5rem'">
            <div class="title">CBD</div>
            <div *ngFor="let spoofedGridColVM of viewModel.spoofedGridColViewModels$ | async; let i = index">
              <div
                class="value"
                [style.width.rem]="(cardData | sectionRowViewModelBasicMethod: 'displayCannabinoidInRanges') ? 5 : 3"
                appScaleDownText
                [transparentWhileScaling]="viewModel.viewingInLiveView$ | async"
                [scaleDownLinkedKey]="(viewModel.getGridVariantId$(spoofedGridColVM) | async) + 'cannabinoids'">
                {{ spoofedGridColVM | getGridVariantCbd: viewModel.cardStackMenu$ : viewModel.cardData$ | async }}
              </div>
            </div>
          </div>
          <div
            class="property-container grid-mode"
            [style.color]="viewModel.bodyTextColor$ | async"
            [style.padding]="(viewModel.atLeastOneGridVariantOnSale$ | async) ? '0.25rem 0' : '0.5625rem 0.5rem'">
            <div class="title">$</div>
            <div *ngFor="let spoofedGridColVM of viewModel.spoofedGridColViewModels$ | async; let i = index">
              <div
                class="value grid-regular-value"
                [style.width.rem]="(cardData | sectionRowViewModelBasicMethod: 'displayCannabinoidInRanges') ? 5 : 3">
                <app-column-primary-price
                  [menu]="viewModel.cardStackMenu$ | async"
                  [section]="viewModel.cardStack$ | async"
                  [colVm]="spoofedGridColVM"
                  [rowVm]="viewModel.cardData$ | async"
                  [locConfig]="viewModel.locationConfig$ | async"
                  [locationId]="viewModel.locationId$ | async"
                  [productStylingVm]="viewModel">
                </app-column-primary-price>
              </div>
            </div>
          </div>
          <div
            *ngIf="viewModel.atLeastOneGridVariantOnSale$ | async"
            class="property-container grid-mode"
            [style.color]="viewModel.bodyTextColor$ | async"
            [style.padding]="(viewModel.atLeastOneGridVariantOnSale$ | async) ? '0.25rem 0' : '0.5625rem 0.5rem'">
            <div class="title">SALE</div>
            <div *ngFor="let spoofedGridColVM of viewModel.spoofedGridColViewModels$ | async; let i = index">
              <div
                class="value grid-sale-value"
                [style.width.rem]="(cardData | sectionRowViewModelBasicMethod: 'displayCannabinoidInRanges') ? 5 : 3">
                <app-column-primary-price
                  [menu]="viewModel.cardStackMenu$ | async"
                  [section]="viewModel.cardStack$ | async"
                  [colVm]="spoofedGridColVM"
                  [rowVm]="viewModel.cardData$ | async"
                  [locConfig]="viewModel.locationConfig$ | async"
                  [locationId]="viewModel.locationId$ | async"
                  [productStylingVm]="viewModel"
                  [showNgContentContainerIfNoDiscount]="true">
                  --
                </app-column-primary-price>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
