import { StrainType } from '../models/enum/dto/strain-classification.enum';

// @dynamic
export class StrainTypeUtils {

  static getSymbol(strainType: StrainType): string {
    switch (strainType) {
      case StrainType.Indica:
      case StrainType.IndicaDominant:
        return 'I';
      case StrainType.Sativa:
      case StrainType.SativaDominant:
        return 'S';
      case StrainType.Hybrid:
        return 'H';
      case StrainType.Blend:
        return 'B';
      case StrainType.CBD:
        return 'C';
      default:
        return '';
    }
  }

  static getStandardizedName(
    strainType: StrainType,
    standardizeDominantStrainType: boolean,
  ): string {
    switch (strainType) {
      case StrainType.IndicaDominant:
        return standardizeDominantStrainType ? StrainType.Indica : 'Indica Dominant';
      case StrainType.SativaDominant:
        return standardizeDominantStrainType ? StrainType.Sativa : 'Sativa Dominant';
      case StrainType.NA:
        return StrainType.UNKNOWN;
      default:
        return strainType;
    }
  }

}
