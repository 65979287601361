import { CardData } from './card-data';
import { VariantTypeUtils } from '../../utils/variant-type-utils';
import { ProductType } from '../enum/dto/product-type.enum';
import { VariantType } from '../enum/dto/variant-type.enum';
import { exists } from '../../functions/exists';
import { Variant } from '../product/dto/variant';

export class PlainJaneCardData extends CardData {

  public override getSize(variant?: Variant): string {
    const v = variant || this.rowVariants?.firstOrNull();
    const formattedUnitSize = v?.getFormattedUnitSize();
    const hasSize = exists(formattedUnitSize)
      && (formattedUnitSize !== '0')
      && (formattedUnitSize !== '-')
      && (formattedUnitSize !== '--');
    switch (true) {
      case ProductType.Seed === v?.productType || VariantType.Seed === v?.variantType: {
        return null;
      }
      case VariantTypeUtils.isCapsuleType(v?.variantType): {
        const n = v?.packagedQuantity ?? 0;
        if (n < 1) return '-';
        return `${n} cap`
          .pluralizer()
          .addRule({ isPlural: n > 1, useApostrophe: false, word: 'cap' })
          .pluralize();
      }
      default: {
        switch (true) {
          case hasSize: return `${formattedUnitSize}`;
          default:      return '-';
        }
      }
    }
  }

  public showSize(): boolean {
    const v = this.rowVariants?.firstOrNull();
    return v?.productType !== ProductType.Edible || VariantTypeUtils.isReadyToDrinkBeverageType(v?.variantType);
  }

  public showQuantity(): boolean {
    const v = this.rowVariants?.firstOrNull();
    if (v?.variantType === VariantType.Capsule) return false;
    return v?.packagedQuantity > 1;
  }

  public getQuantityString(variant?: Variant): string {
    const showSize = this.showSize();
    const qtyString = super.getQuantityString(variant);
    return !showSize ? `${qtyString}pc` : qtyString;
  }

  public getSidebarText(): string {
    let strainTypesString = this.getReadableStrainType() ? this.getReadableStrainType() + ' ' : '';
    if (this.isNonCannabinoidOtherVariant()) {
      strainTypesString = '';
    }
    const variantTypeString = this.rowVariantType() ? this.rowVariantType() : '';
    return `${strainTypesString}${variantTypeString}`.trim();
  }

}
