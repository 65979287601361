import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProductSectionItemComponent } from '../product-section-item/product-section-item.component';
import { ProductSectionItemViewModel } from '../product-section-item/product-section-item-view-model';

@Component({
  selector: 'app-product-section-item-plantlife',
  templateUrl: './product-section-item-plantlife.component.html',
  styleUrl: './product-section-item-plantlife.component.scss',
  providers: [ProductSectionItemViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductSectionItemPlantlifeComponent extends ProductSectionItemComponent {

}
