<div
  class="background-asset-container"
  [style.top]="top"
  [style.right]="right"
  [style.bottom]="bottom"
  [style.left]="left">
  <img
    *ngIf="(menu | hasBackgroundAsset : loaded) && (menu?.backgroundImage | assetIsImage)"
    class="background-asset"
    [style.object-fit]="scaleFit ? 'contain' : 'cover'"
    (load)="renderSucceeded()"
    (error)="renderFailed()"
    [src]="imageBackgroundAsset$ | async"
    src=""
    alt="" />

  <video
    *ngIf="(menu | hasBackgroundAsset : loaded) && (menu?.backgroundImage | assetIsVideo)"
    #video
    class="background-video"
    [src]="videoBackgroundAsset$ | async"
    [autoplay]="true"
    (canplaythrough)="renderSucceeded()"
    (canplay)="videoCanPlay.next(true)"
    (error)="renderFailed()"
    (loadstart)="this.videoCanPlay.next(false)"
    (loadedmetadata)="duration.emit(video.duration)"
    [style.object-fit]="scaleFit ? 'contain' : 'cover'"
    [loop]="true"
    [muted]="!playAudio"></video>

  <img
    *ngIf="menu | shouldShowDefaultImage : defaultImageUrl"
    class="background-asset"
    (load)="renderSucceeded()"
    (error)="renderFailed()"
    [src]="distinctDefaultImageAsset$ | async"
    [style.object-fit]="scaleFit ? 'contain' : 'cover'"
    src=""
    alt="" />

  <video
    *ngIf="menu | shouldShowDefaultVideo : defaultVideoUrl"
    [src]="distinctDefaultVideoAsset$ | async"
    [autoplay]="true"
    (canplaythrough)="renderSucceeded()"
    (error)="renderFailed()"
    class="background-asset background-video"
    [loop]="true"
    [style.object-fit]="scaleFit ? 'contain' : 'cover'"
    [muted]="!playAudio"></video>

  <!-- blur overlay -->
  <div *ngIf="menu | shouldBlurBackground" class="background-asset" style="backdrop-filter: blur(10px)"></div>

  <!-- color overlay -->
  <div
    class="background-asset"
    [class]="menu | backgroundAssetWrapperClass"
    [style.background-color]="menu | backgroundBodyColor"
    [style.opacity]="menu | backgroundColorOpacity"
    [style.border-radius]="menu | backgroundColorAssetBorderRadius"></div>
</div>
