/* src/app/modules/display/components/menus/product-menu/building-blocks/menu-footer/plant-life-footer/plant-life-footer.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.footer-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 0.5rem;
  min-height: 2rem;
  background-color: #222222;
  padding: 0.1rem 1rem;
  color: white;
  text-transform: uppercase;
  font-size: 1rem;
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.footer-badge-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}
.footer-badge {
  height: 1.5rem;
  width: auto;
}
.footer-badge-title {
  line-height: 1;
  font-size: 1.25rem;
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
}
/*# sourceMappingURL=plant-life-footer.component.css.map */
