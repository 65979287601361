import { AssetUrl } from './asset-url';
import { MarketingAssetUrl } from './marketing-asset-url';
import { PrefetchMediaAsset } from './prefetch-media-asset';

export class MarketingAsset extends PrefetchMediaAsset {

  /**
   * Not returned from API - set by updated marketing menu infrastructure - UpgradedMarketingLoopingContentMenu.
   * The sectionId is used as the key within MarketingMenuAssetService.
   * It allows you to know which section the asset belongs to.
   */
  public sectionId: string;

  protected override deserializeUrls(filteredUrls: AssetUrl[]): void {
    this.urls = window?.injector?.Deserialize?.arrayOf(MarketingAssetUrl, filteredUrls);
  }

}
