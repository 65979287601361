import { ProductMenu } from '../product-menu';
import { Type } from '@angular/core';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { ColorUtils } from '../../../utils/color-utils';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { SectionWithProducts } from '../section/section-with-products';

export class ContemporaryMenu extends ProductMenu {

  getThemeClass(): string {
    return 'contemporary-theme';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getShowHeader(): boolean {
    return true;
  }

  getShowFooter(): boolean {
    return false;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getProductWrapperStyling(section: SectionWithProducts, sectionRowViewModel: SectionRowViewModel, odd: boolean) {
    const rowStyling = super.getProductWrapperStyling(section, sectionRowViewModel, odd);
    const [R, G, B] = ColorUtils.hexToRgb(this.menuOptions.bodyTextColor);
    rowStyling['border-top'] = `1px solid rgba(${R}, ${G}, ${B}, 0.5)`;
    return rowStyling;
  }

  getFontFaceList(): string[] {
    return ['apparat', 'pragmatica', 'Arial', 'pragmatica-extended'];
  }

}
