<div
  *ngIf="colVm?.columnType === cannabinoidColumnType"
  #cannabinoidHeader
  appScaleDownText
  [scaleEnabled]="!(menu | isPrintMenu)"
  [style.color]="
    ignoreUserSetTextColorAndAutoToLightOrDarkTextColor
      ? (section | textColorBasedOnSectionHeaderBackgroundColor)
      : (menu | productMenuBasicMethod: 'getSectionHeaderTextColor' : section)
  "
  [scaleDownLinkedKey]="section?.id + Types.TextSizeLink.TypeCannabinoid"
  [class.section-header-thc-and-cbd]="cannabinoidColumnType === Types.SectionColumnCannabinoidType.THCAndCBD"
  class="section-header-general section-header-cannabinoid">
  <!-- section-header-${cannabinoid} is added in component -->
  {{ (menu | productMenuBasicMethod: 'getCannabinoidColumnTitle' : colVm) + (headerCUOMString$ | async) }}
</div>
