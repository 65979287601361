import { Pipe, PipeTransform } from '@angular/core';
import type { SectionRowViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { VariantBadge } from '../../../models/product/dto/variant-badge';
import { Variant } from '../../../models/product/dto/variant';

@Pipe({
  name: 'getBadgesFor'
})
export class GetBadgesForPipe implements PipeTransform {

  transform(rowVm: SectionRowViewModel, variant: Variant): VariantBadge[] | null {
    return rowVm?.getBadgesFor(variant) || null;
  }

}
