import { PrintCardMenu } from './print-card-menu';
import { AssetLibraryType } from '../../enum/dto/asset-library-type.enum';

export class PlantlifePrintCardMenu extends PrintCardMenu {

  getFontFaceList(): string[] {
    return ['bebas-neue-pro'];
  }

  override shouldFetchVariantAssets(): boolean {
    return true;
  }

  /**
   * Brand asset should be displayed if available - no product fallback.
   */
  override variantAssetTypeSortOrder(): string[] {
    return [
      AssetLibraryType.PrimaryBrand,
      AssetLibraryType.AlternateBrand,
      AssetLibraryType.Brand,
    ];
  }

}
