@charset "UTF-8";

/* src/app/modules/print-cards/components/print-cards/card-content/post-cards-4x6/jane-doe-post-card-stack-content/jane-doe-post-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 0.5rem;
}
:host .main-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
:host .main-content .card-strain-type,
:host .main-content .card-product-type {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.875rem;
  width: 100%;
  font-size: 1.25rem;
  font-family: indivisible, sans-serif;
  font-weight: 900;
  font-style: normal;
}
:host .main-content .card-strain-type.dark-text,
:host .main-content .card-product-type.dark-text {
  color: #222222;
}
:host .main-content .card-strain-type.light-text,
:host .main-content .card-product-type.light-text {
  color: #ffffff;
}
:host .main-content .middle-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  box-sizing: border-box;
  padding: 1rem 0 0.5rem 0;
}
:host .main-content .middle-content .majority-of-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
:host .main-content .middle-content .majority-of-content .company-logo-container {
  height: 4rem;
  width: 100%;
  padding-bottom: 1rem;
  text-align: center;
}
:host .main-content .middle-content .majority-of-content .company-logo-container .company-logo {
  max-width: 80%;
  height: 4rem;
}
:host .main-content .middle-content .majority-of-content .product-image-container {
  height: 4rem;
  width: 100%;
  padding-bottom: 0.75rem;
  text-align: center;
}
:host .main-content .middle-content .majority-of-content .product-image-container .product-image {
  max-width: 80%;
  height: 4rem;
}
:host .main-content .middle-content .majority-of-content .name-and-brand {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  gap: 0.5rem;
  text-align: center;
  margin-bottom: 1rem;
}
:host .main-content .middle-content .majority-of-content .name-and-brand .product-name {
  font-size: 1.5rem;
  font-family: indivisible, sans-serif;
  font-weight: 700;
  font-style: normal;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  box-sizing: border-box;
  text-align: center;
}
:host .main-content .middle-content .majority-of-content .name-and-brand .product-brand {
  font-size: 0.875rem;
  font-family: indivisible, sans-serif;
  font-weight: 600;
  font-style: normal;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
:host .main-content .middle-content .majority-of-content .cannabinoids-badges-label {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  gap: 0.25rem;
  text-align: center;
  margin-bottom: 1rem;
}
:host .main-content .middle-content .majority-of-content .cannabinoids-badges-label .cannabinoids {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
  font-family: indivisible, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1rem;
}
:host .main-content .middle-content .majority-of-content .cannabinoids-badges-label .cannabinoids .cannabinoid-container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 0.25rem;
  align-items: center;
  box-sizing: border-box;
}
:host .main-content .middle-content .majority-of-content .cannabinoids-badges-label .cannabinoids .cannabinoid-container .value {
  overflow: hidden;
  white-space: nowrap;
  max-width: 9rem;
}
:host .main-content .middle-content .majority-of-content .cannabinoids-badges-label .strain-name {
  font-size: 0.875rem;
  font-family: indivisible, sans-serif;
  font-weight: 600;
  font-style: normal;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  box-sizing: border-box;
  text-align: center;
}
:host .main-content .middle-content .majority-of-content .cannabinoids-badges-label .badges-and-label {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
:host .main-content .middle-content .majority-of-content .cannabinoids-badges-label .badges-and-label .badge {
  height: 1.5rem;
}
:host .main-content .middle-content .majority-of-content .cannabinoids-badges-label .badges-and-label .label {
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .main-content .middle-content .majority-of-content .cannabinoids-badges-label .badges-and-label .label ::ng-deep .product-label {
  font-family: indivisible, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 0.8125rem;
  line-height: normal;
  margin: 0;
  width: fit-content;
}
:host .main-content .middle-content .majority-of-content ::ng-deep .description-container .description {
  font-family: indivisible, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.75rem;
  text-align: center;
}
:host .main-content .middle-content .majority-of-content ::ng-deep .description-container .plain-text {
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}
:host .main-content .middle-content .majority-of-content ::ng-deep .description-container .rich-text {
  max-height: 6.5rem;
  overflow-y: hidden;
}
:host .main-content .middle-content .anchored-to-bottom {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  width: 100%;
}
:host .main-content .middle-content .anchored-to-bottom .product-price ::ng-deep .product-variant-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.625rem;
  font-family: indivisible, sans-serif;
  font-weight: 900;
  font-style: normal;
  overflow: visible;
}
:host .main-content .middle-content .anchored-to-bottom .product-price ::ng-deep .product-variant-price .product-variant-previous-price {
  font-size: 1.25rem;
}
:host .main-content .middle-content .anchored-to-bottom .product-quantity-and-size {
  height: 100%;
  display: flex;
  align-items: center;
  font-family: indivisible, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1rem;
  padding-left: 0.75rem;
}
:host ::ng-deep .force-italic-style * {
  font-family: indivisible, sans-serif;
  font-weight: 900;
  font-style: italic;
}
/*# sourceMappingURL=jane-doe-post-card-stack-content.component.css.map */
