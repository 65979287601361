import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import type { LocationPriceStream } from '../../../../../models/enum/shared/location-price-stream';
import { ColumnComponent } from '../column.component';

@Component({
  selector: 'app-column-secondary-price',
  templateUrl: './column-secondary-price.component.html',
  styleUrls: ['./column-secondary-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ColumnSecondaryPriceComponent extends ColumnComponent {

  @Input() overridePriceStream: LocationPriceStream;

}
