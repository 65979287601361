import { Pipe, PipeTransform } from '@angular/core';
import type { ProductMenu } from '../../../models/menu/product-menu';
import type { SectionWithProducts } from '../../../models/menu/section/section-with-products';
import type { SectionRowViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { SectionColumnViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import type { LocationConfiguration } from '../../../models/company/dto/location-configuration';
import type { LocationPriceStream } from '../../../models/enum/shared/location-price-stream';

@Pipe({
  name: 'getSecondaryPriceDecimal'
})
export class GetSecondaryPriceDecimalPipe implements PipeTransform {

  transform(
    menu: ProductMenu,
    locationConfig: LocationConfiguration,
    locationId: number,
    section: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel,
    overridePriceStream?: LocationPriceStream
  ): string {
    const priceStream = overridePriceStream || locationConfig?.priceFormat;
    return menu?.getSecondaryPriceDecimal(priceStream, locationId, section, rowVM, colVm);
  }

}
