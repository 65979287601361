import { Pipe, PipeTransform } from '@angular/core';
import { SectionUtils } from '../../../utils/section-utils';
import type { Section } from '../../../models/menu/section/section';

@Pipe({
  name: 'lastProductSectionOnPage'
})
export class LastProductSectionOnPagePipe implements PipeTransform {

  transform(section: Section): boolean {
    return SectionUtils.isProductSection(section) && section?.lastProductSectionOnPage;
  }

}
