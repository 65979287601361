<div
  *ngIf="colVm?.columnType === Types.SectionColumnProductInfoType.VariantPrice"
  appScaleDownText
  [scaleEnabled]="!(menu | isPrintMenu)"
  [style.color]="
    ignoreUserSetTextColorAndAutoToLightOrDarkTextColor
      ? (section | textColorBasedOnSectionHeaderBackgroundColor)
      : (menu | productMenuBasicMethod: 'getSectionHeaderTextColor' : section)
  "
  [scaleDownLinkedKey]="
    section?.id +
    ((section | isInLineMode) ? Types.TextSizeLink.PricePreRollQuantitySize : Types.TextSizeLink.PriceLink)
  "
  class="section-header-general section-header-variant-price">
  {{ menu | productMenuBasicMethod: 'getPriceColumnTitle' : colVm }}
</div>
