import { StringUtils } from '../../../../../../../../../utils/string-utils';
import { SectionRowViewModel } from './SectionRowViewModel';

export class SectionRowViewModelTrueNorthDark extends SectionRowViewModel {

  override getCannabinoid(cannabinoid: string): string {
    return StringUtils.removeWhiteSpace(super.getCannabinoid(cannabinoid));
  }

}
