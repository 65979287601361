import { SortUtils } from './sort-utils';
import { CardData } from '../models/print-cards/card-data';
import { StrainType } from '../models/enum/dto/strain-classification.enum';

// @dynamic
export class SortPrintCards extends SortUtils {

  public static sortPrintCardsByStrainGroupings(cards: CardData[], strainGroupings: StrainType[][]): CardData[] {
    const sortByStrainGrouping = (a: CardData, b: CardData) => {
      // the order of the strain groupings is the order of the sort
      const aStrainIndex = strainGroupings?.findIndex(grouping => grouping.includes(a?.getStrainType()));
      const bStrainIndex = strainGroupings?.findIndex(grouping => grouping.includes(b?.getStrainType()));
      const aPriority = (aStrainIndex === -1) ? Infinity : aStrainIndex;
      const bPriority = (bStrainIndex === -1) ? Infinity : bStrainIndex;
      return SortPrintCards.numberAscending(aPriority, bPriority);
    };
    return cards?.sort(sortByStrainGrouping);
  }

}
