import { SativaHybridIndicaSectionRowViewModel } from './sativa-hybrid-indica-section-row-view-model';

export class SativaHybridIndicaCols {

  sativa: SativaHybridIndicaSectionRowViewModel[] = [];
  hybrid: SativaHybridIndicaSectionRowViewModel[] = [];
  indica: SativaHybridIndicaSectionRowViewModel[] = [];

  constructor(
    sativa: SativaHybridIndicaSectionRowViewModel[],
    hybrid: SativaHybridIndicaSectionRowViewModel[],
    indica: SativaHybridIndicaSectionRowViewModel[]
  ) {
    this.sativa = sativa;
    this.hybrid = hybrid;
    this.indica = indica;
  }

}
