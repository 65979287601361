import { PrintLabelMenu } from './print-label-menu';

export class BaseInventoryPrintLabelMenu extends PrintLabelMenu {

  getFontFaceList(): string[] {
    return ['poppins'];
  }

  shouldStandardizeDominantStrainType(): boolean {
    return false;
  }

}
