import { Pipe, PipeTransform } from '@angular/core';
import type { SectionWithProducts } from '../../../models/menu/section/section-with-products';
import type { SectionRowViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { SectionColumnViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import type { ProductMenu } from '../../../models/menu/product-menu';
import type { LocationConfiguration } from '../../../models/company/dto/location-configuration';
import type { LocationPriceStream } from '../../../models/enum/shared/location-price-stream';
import { SpecificPriceWithoutDiscounts } from '../../../models/enum/shared/specific-price-without-discounts.enum';

@Pipe({
  name: 'getOriginalVariantPricing'
})
export class GetOriginalVariantPricingPipe implements PipeTransform {

  transform(
    menu: ProductMenu,
    locationConfig: LocationConfiguration,
    locId: number,
    section: SectionWithProducts,
    rowViewModel: SectionRowViewModel,
    colVm: SectionColumnViewModel,
    specificPriceWithoutDiscounts: SpecificPriceWithoutDiscounts[],
    overridePriceStream: LocationPriceStream | null = null
  ): string {
    return menu?.getOriginalVariantPricing(
      overridePriceStream || locationConfig?.priceFormat,
      locId,
      section,
      rowViewModel,
      colVm,
      menu?.getDropDecimal(),
      menu?.getDropDollarSign(),
      specificPriceWithoutDiscounts
    );
  }

}
