/* src/app/modules/print-cards/components/cut-lines/print-card-right-cut-lines/print-card-right-cut-lines.component.scss */
:host {
  flex: 1;
  display: flex;
  z-index: 1;
}
.right-cut-lines {
  position: relative;
  width: 100%;
  max-width: 2rem;
  display: flex;
  flex-direction: column;
}
.right-cut-lines.extended {
  max-width: none;
}
.right-cut-lines * {
  box-sizing: border-box;
}
.right-cut-lines .top-sliver {
  position: relative;
  border-bottom: 1px solid black;
}
.right-cut-lines .dividing-sliver-container {
  position: relative;
}
.right-cut-lines .dividing-sliver-container .dividing-sliver {
  position: relative;
  height: 100%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.right-cut-lines .dividing-sliver-container .dividing-sliver-vertical-top-line {
  position: absolute;
  align-self: center;
  border-right: 1px solid black;
}
.right-cut-lines .dividing-sliver-container .dividing-sliver-vertical-middle-line {
  position: absolute;
  align-self: center;
  border-right: 1px solid black;
}
.right-cut-lines .dividing-sliver-container .dividing-sliver-vertical-bottom-line {
  position: absolute;
  align-self: center;
  border-right: 1px solid black;
}
.right-cut-lines .bottom-sliver {
  position: relative;
  border-top: 1px solid black;
}
/*# sourceMappingURL=print-card-right-cut-lines.component.css.map */
