// @dynamic
export class BlobUtils {

  static b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const replacementString = 'data:' + contentType + ';base64,';
    if (b64Data) {
      const base64Data = b64Data.replace(replacementString, '');
      const byteCharacters = atob(base64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      return new Blob(byteArrays, { type: contentType });
    } else {
      return null;
    }
  };

}
