import { VariantAndAsset } from './variant-and-asset';
import { Asset } from '../../../image/dto/asset';
import { Variant } from '../../../product/dto/variant';

export class OrderFeaturedVariants {

  position: number;
  variantAndAsset: VariantAndAsset;

  constructor(p: number, v: Variant, a: Asset) {
    this.position = p;
    this.variantAndAsset = new VariantAndAsset(v, a);
  }

}
