import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimSafely'
})
export class TrimSafelyPipe implements PipeTransform {

  transform(input?: string, chars: RegExp = /\s/) {
    return input?.replace(new RegExp(`^${chars}+|${chars}+$`, 'g'), '');
  }

}
