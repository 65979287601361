import { Pipe, PipeTransform } from '@angular/core';
import type { ProductMenu } from '../../../../../models/menu/product-menu';
import type { Section } from '../../../../../models/menu/section/section';
import type { SectionColumnViewModel } from '../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import type { SectionRowViewModel } from '../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';

@Pipe({
  name: 'shouldHideColumnContent'
})
export class ShouldHideColumnContentPipe implements PipeTransform {

  transform(menu: ProductMenu, section: Section, colVm: SectionColumnViewModel, rowVm: SectionRowViewModel): boolean {
    return menu?.getShouldHideColumnContent(section, colVm, rowVm);
  }

}
