@charset "UTF-8";

/* src/app/modules/display/components/menus/product-menu/building-blocks/menu-footer/swimlane-classification-legend-footer/swimlane-classification-and-badges-legend-footer.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.footer-container {
  font-size: 1rem;
  text-transform: uppercase;
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  padding: 0;
  margin-top: 1.45rem;
  flex-wrap: wrap;
}
.footer-container .footer-classification-board {
  font-size: 1.25rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.45rem;
  padding: 0.37rem 0.5rem 0.1rem;
}
.footer-container .footer-classification-board .classification-badge {
  font-size: 1.25rem;
  width: 1.8rem;
  color: white;
  text-align: center;
}
.footer-container .footer-classification-board .badge-in-legend {
  width: 1.8rem;
}
.footer-container .footer-classification-board .classification-name {
  font-size: 0.75rem;
  max-width: 8rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;
  min-width: 0;
}
.footer-container .footer-classification-board .classification-mapping {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.37rem;
}
/*# sourceMappingURL=swimlane-classification-and-badges-legend-footer.component.css.map */
