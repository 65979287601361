import { AfterViewInit, ChangeDetectionStrategy, Component, Input, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { ColumnComponent } from '../column.component';
import type { ProductSectionItemViewModel } from '../../../../display/components/menus/product-menu/building-blocks/menu-item/product-section-item/product-section-item-view-model';
import type { LabelInflatorComponent } from '../../../../labels/components/product-menu-label-inflator/label-inflator.component';
import { takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-column-label',
  templateUrl: './column-label.component.html',
  styleUrls: ['./column-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ColumnLabelComponent extends ColumnComponent implements AfterViewInit {

  @Input() calculationMode: boolean;
  @Input() productStylingVm: ProductSectionItemViewModel;
  @Input() checkForPriceChange: boolean;
  @ViewChildren('labelInflator') labelComponent: QueryList<LabelInflatorComponent>;
  private labelSub: Subscription;

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.listenToCurrentLabelText(this.labelComponent?.first);
    this.labelComponent.changes.subscribeWhileAlive({
        owner: this,
        next: labelComponents => this.listenToCurrentLabelText(labelComponents?.first)
    });
  }

  private listenToCurrentLabelText(labelComponent: LabelInflatorComponent): void {
    this.labelSub?.unsubscribe();
    if (!labelComponent) return;
    this.labelSub = labelComponent?.currentLabelText$
      ?.pipe(takeUntil(this.onDestroy))
      ?.subscribe(labelText => this.productStylingVm?.connectToLabelText(labelText));
  }

}

