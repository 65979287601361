import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { ResizeObserver, ResizeObserverEntry } from '@juggle/resize-observer';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { BaseDirective } from '../../models/base/base-directive';
import { ScaleDownService } from './scale-down.service';
import fastdom from 'fastdom';

@Directive({
  selector: '[appScaleDownText]'
})
export class ScaleDownTextDirective extends BaseDirective implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() scaleDownLinkedKey: string;
  @Input() scaleEnabled: boolean = true;
  @Input() transparentWhileScaling: boolean = false;
  @Input() nPixelsPerStep: number = 2;

  public ro: ResizeObserver;
  protected entry = new BehaviorSubject<ResizeObserverEntry>(null);
  protected enabled = new BehaviorSubject<boolean>(this.scaleEnabled);
  protected transparentSub: Subscription;
  protected readonly _makeTransparent = new BehaviorSubject<boolean>(false);
  protected readonly makeTransparent$ = this._makeTransparent as Observable<boolean>;

  constructor(
    protected renderer2: Renderer2,
    protected elementRef: ElementRef,
    protected service: ScaleDownService
  ) {
    super();
  }

  setupViews() {
    this.enabled.next(this.scaleEnabled);
  }

  setupBindings() {
    this.observeSectionsContainer();
    this.observeResize();
  }

  observeSectionsContainer() {
    this.ro = new ResizeObserver((entries, _) => {
      for (const entry of entries) {
        this.entry.next(entry);
      }
    });
    // Element for which to observe height and width
    this.ro.observe(this.elementRef.nativeElement);
  }

  observeResize() {
    const windowChange$ = this.service.windowChange$.pipe(tap(() => this.removeFontSizeAttribute()));
    const link$ = this.service.connectToScaleDownMap().pipe(
      map(m => m.get(this.scaleDownLinkedKey)),
      debounceTime(1),
      distinctUntilChanged()
    );
    const s = combineLatest([
      this.enabled,
      this.entry.notNull(),
      link$,
      windowChange$
    ]).pipe(debounceTime(1)).subscribe(([enabled, entry, linked, ]) => {
      if (enabled) {
        fastdom.measure(() => {
          const fontSize = (window.getComputedStyle(entry.target).fontSize);
          const stripUnits = fontSize.replace('px', '');
          let sizeInt = parseInt(stripUnits, 10);
          const cr = entry.contentRect;
          const sw = Math.floor(entry.target.scrollWidth);
          const w = Math.floor(cr.width);
          const swBuffer = 2;
          // If linked value is smaller than my value, then set myself to linked
          if (linked && (linked < sizeInt)) {
            this.temporarilyMakeTextTransparent();
            fastdom.mutate(() => {
              this.renderer2.setStyle(this.elementRef.nativeElement, 'font-size', `${linked}px`);
            });
          }
          if ((sw - swBuffer - w) > 0) {
            sizeInt -= this.nPixelsPerStep;
            if (!linked || (sizeInt < linked)) {
              this.temporarilyMakeTextTransparent();
              fastdom.mutate(() => {
                this.renderer2.setStyle(this.elementRef.nativeElement, 'font-size', `${sizeInt}px`);
              });
              if (!!this.scaleDownLinkedKey) {
                this.service.setValueInMap(this.scaleDownLinkedKey, sizeInt);
              }
            }
          }
        });
      } else {
        this.removeFontSizeAttribute();
      }
    });
    this.pushSub(s);
  }

  protected removeFontSizeAttribute() {
    fastdom.mutate(() => {
      this.renderer2.removeStyle(this.elementRef.nativeElement, 'font-size');
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scaleEnabled) this.enabled.next(this.scaleEnabled);
    if (changes.transparentWhileScaling) this.listenForTransparencyChanges();
  }

  protected listenForTransparencyChanges(): void {
    this.transparentSub?.unsubscribe();
    if (this.transparentWhileScaling) {
      this.transparentSub = this.makeTransparent$.pipe(
        tap(_ => fastdom.mutate(() => this.renderer2.setStyle(this.elementRef.nativeElement, 'opacity', 0))),
        debounceTime(400),
        tap(_ => fastdom.mutate(() => this.renderer2.removeStyle(this.elementRef.nativeElement, 'opacity'))),
      ).subscribeWhileAlive({ owner: this });
    }
  }

  protected temporarilyMakeTextTransparent(): void {
    this._makeTransparent.next(true);
  }

  ngOnDestroy(): void {
    super.destroy();
    this.ro?.disconnect();
  }

}
