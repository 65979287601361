<div
  *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.Asset"
  class="product-column-general product-image-column"
  [style.visibility]="section?.collapseContent || rowVm.collapseContent ? 'collapse' : ''"
  [style.margin-right]="menu | productMenuBasicMethod: 'getSectionBorderMarginSize'">
  <img
    *ngIf="!(productStylingVm.sortedVariantAsset$ | async)"
    class="product-image"
    [style.opacity]="rowVm | sectionRowViewModelBasicMethod: 'getImageOpacity'"
    [src]="productStylingVm.emptyAssetSrcUrl$ | async"
    [style.object-fit]="rowVm | sectionRowViewModelBasicMethod: 'getImageContainerFit'"
    alt="" />
  <img
    appImage
    *ngIf="productStylingVm.sortedVariantAsset$ | async"
    [asset]="productStylingVm.sortedVariantAsset$ | async"
    [reset]="reset"
    [style.object-fit]="rowVm | sectionRowViewModelBasicMethod: 'getImageContainerFit'"
    [style.opacity]="rowVm | sectionRowViewModelBasicMethod: 'getImageOpacity'"
    class="product-image"
    alt="product asset"
    src="" />
  <div
    *ngIf="menu | productMenuBasicMethod: 'getShowClassificationsInAssetColumn'"
    class="product-strain-type"
    [ngClass]="rowVm | sectionRowViewModelBasicMethod: 'getReadableStrainType' : colVm.strainTypeMode | lowercase">
    {{ rowVm | sectionRowViewModelBasicMethod: 'getReadableStrainType' : colVm.strainTypeMode }}
  </div>
</div>
