<div class="card-content">
  <div class="top-wrapper">
    <div class="product-brand-wrapper">
      <div class="product-brand-parent-for-scaling">
        <div
          class="print-card-product-brand"
          appPrintCardScaleDownText
          [maxHeightInPx]="120"
          [maxWidthInPx]="260"
          [transparentWhileScaling]="viewModel.viewingInLiveView$ | async"
          [style.color]="viewModel.cardAccentColor$ | async">
          {{ (cardData | sectionRowViewModelBasicMethod : 'getBrand') || 'fika' | trimSafely }}
        </div>
      </div>
    </div>
    <div class="product-name-wrapper">
      <div
        class="product-name"
        appPrintCardScaleDownText
        [maxHeightInPx]="66"
        [maxWidthInPx]="260"
        [transparentWhileScaling]="viewModel.viewingInLiveView$ | async"
        [style.color]="viewModel.headerTextColor$ | async">
        {{ cardData | sectionRowViewModelBasicMethod : 'getRowTitle' | trimSafely }}
      </div>
    </div>
    <div class="content-container">
      <div *ngIf="viewModel.hasBadges$ | async" class="product-badge-container">
        <img
          *ngFor="let variantBadge of cardData | printCardBadges"
          appImage
          class="product-badge"
          [asset]="variantBadge?.image"
          alt="badge"
          src="" />
      </div>
      <app-label-inflator
        class="print-card-label"
        [columnLabel]="false"
        [labelComponentInterface]="cardData"
        [reset]="false">
      </app-label-inflator>
    </div>
  </div>
  <div class="bottom-wrapper">
    <div class="product-desc-wrapper" [style.border-color]="viewModel.cardAccentColor$ | async">
      <ng-container
        *ngIf="
          cardData | sectionRowViewModelBasicMethod : 'getRichTextDescription' as richTextDescription;
          else descTemplate
        ">
        <div
          class="product-card-desc"
          [style.color]="viewModel.bodyTextColor$ | async"
          [innerHTML]="richTextDescription"></div>
      </ng-container>
      <ng-template #descTemplate>
        <ng-container *ngIf="cardData | sectionRowViewModelBasicMethod : 'getProductDesc' as productDesc">
          <div class="product-card-desc" [style.color]="viewModel.bodyTextColor$ | async">
            {{ productDesc }}
          </div>
        </ng-container>
      </ng-template>
    </div>
    <div class="price-type-wrapper" [style.color]="viewModel.bodyTextColor$ | async">
      <div class="product-price">
        <app-column-primary-price
          [menu]="viewModel.cardStackMenu$ | async"
          [section]="viewModel.cardStack$ | async"
          [colVm]="viewModel.spoofedColViewModel$ | async"
          [rowVm]="viewModel.cardData$ | async"
          [locConfig]="viewModel.locationConfig$ | async"
          [locationId]="viewModel.locationId$ | async"
          [productStylingVm]="viewModel">
        </app-column-primary-price>
      </div>
    </div>
  </div>
</div>
