import { DisplayableProductMenu } from '../protocols/displayable-product-menu';
import { Type } from '@angular/core';
import { ColWidth } from '../shared/col-width';
import { Variant } from '../product/dto/variant';
import { ProductMenuFooterComponent } from '../../modules/display/components/menus/product-menu/building-blocks/menu-footer/product-menu-footer.component';
import { MenuHeaderTitleImageComponent } from '../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { SectionColumnProductInfoType, SectionColumnType, SectionColumnViewModel } from '../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { Menu } from './menu';
import { ProductSectionHeaderComponent } from '../../modules/display/components/menus/product-menu/building-blocks/menu-section-header/product-section-header/product-section-header.component';
import { environment } from '../../../environments/environment';
import { MenuType } from '../enum/dto/menu-type.enum';
import { ColorUtils } from '../../utils/color-utils';
import { OverflowState } from '../enum/shared/overflow-transition-state.enum';
import { ColumnWidth } from '../enum/shared/column-width.enum';
import { MenuHeaderTitleComponent } from '../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title.component';
import { ColumnViewModelUtils } from '../../utils/column-view-model-utils';
import { SortUtils } from '../../utils/sort-utils';
import { ColumnUtils } from '../../utils/column-utils';
import { SectionColumnConfigDataValue, SectionColumnConfigProductInfoKey } from './section/section-column-config';
import { StrainType } from '../enum/dto/strain-classification.enum';
import { LocationPriceStream } from '../enum/shared/location-price-stream';
import { BandedRowMode } from '../enum/shared/banded-row-mode.enum';
import { BandedRowColorContrast } from '../enum/shared/banded-row-color-contrast.enum';
import { ProductSection } from './section/product-section';
import { Position } from '../enum/shared/position.enum';
import { EmptySection } from './section/empty-section';
import { AssetSection } from './section/asset-section';
import { SortVariantUtils } from '../../utils/sort-variant-utils';
import { exists } from '../../functions/exists';
import { SectionUtils } from '../../utils/section-utils';
import type { LocationConfiguration } from '../company/dto/location-configuration';
import type { Section } from './section/section';
import type { Product } from '../product/dto/product';
import type { SectionRowViewModel } from '../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { MenuSectionHeaderComponent } from '../../modules/display/components/menus/product-menu/building-blocks/menu-section-header/menu-section-header.component';
import type { ProductMenuHeaderComponent } from '../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import type { SectionWithProducts } from './section/section-with-products';
import type { VariantBadge } from '../product/dto/variant-badge';
import type { ColumnGrouping } from './section/column-grouping';
import { BadgeUtils } from '../../utils/badge-utils';
import { SpecificPriceWithoutDiscounts } from '../enum/shared/specific-price-without-discounts.enum';

type ContentLoopData = [number, number, number];

export abstract class ProductMenu extends Menu implements DisplayableProductMenu {

  public columnCount: number;
  public override template?: ProductMenu;

  onDeserialize() {
    super.onDeserialize();
    this.initializeProductSectionDurations();
    this.filterOutEmptyProductSections();
    this.filterOutEmptyMediaSections();
    this.roundCannabinoidsIfExplicitlySet();
  }

  private initializeProductSectionDurations() {
    const duration = Number.parseInt(this.metadata?.sectionOverflowDuration, 10);
    const productSections = (s: Section): s is ProductSection => s instanceof ProductSection;
    const initSectionDuration = (s: ProductSection) => s.initOverflowDuration(duration);
    this.sections?.filter(productSections)?.forEach(initSectionDuration);
  }

  private filterOutEmptyProductSections() {
    const priceStream = LocationPriceStream.Default;
    const hasVisibleProducts = (s: ProductSection) => {
      return exists(s?.getScopedVisibleLineItemCount(this, priceStream, this.isSectionLevelOverflow()));
    };
    const outEmptyProductSections = (s: ProductSection) => {
      return SectionUtils.isProductSection(s) ? hasVisibleProducts(s) : true;
    };
    this.sections = this.sections?.filter(outEmptyProductSections);
  }

  private filterOutEmptyMediaSections() {
    const hasVisibleMedia = (s: AssetSection) => s?.hasPrimaryAsset();
    const outEmptyMediaSections = (s: AssetSection) => SectionUtils.isAssetSection(s) ? hasVisibleMedia(s) : true;
    this.sections = this.sections?.filter(outEmptyMediaSections);
  }

  private roundCannabinoidsIfExplicitlySet() {
    if (!this.hideCannabinoidDecimal()) return;
    this.sections?.filter(SectionUtils.isProductSection)?.forEach(s => s?.roundCannabinoids(true));
  }

  /** abstract interface that all product menu descendants must implement */

  abstract getFontFaceList(): string[];

  /** ******************* Data for display menu *********************** */

  getMenuClass(): string {
    return 'product-menu';
  }

  getThemeClass(): string {
    return '';
  }

  getMenuWrapperClass(): string {
    return '';
  }

  getMenuScrollClass(): string {
    if (this.isLandscape() || this.isInVerticalPagingOverflowState()) {
      return 'horizontal-scroll';
    } else {
      return 'vertical-scroll';
    }
  }

  getHeaderWrapperClass(): string {
    return '';
  }

  getSectionsWrapperClass(): string {
    return '';
  }

  getSectionsBackgroundColor(): string {
    return '';
  }

  needsOverflowCalculatorInAbsolutePosition(): boolean {
    return !(this.isPortrait() && this.isInVerticalScrollingOverflowState() && this.moreThanOneSectionColumn());
  }

  getSectionWrapperClass(...args: any[]): string {
    return '';
  }

  getFooterWrapperClass(): string {
    return '';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    if (this.isLandscape()) {
      return MenuHeaderTitleImageComponent;
    } else {
      return MenuHeaderTitleComponent;
    }
  }

  getShowHeaderAsTitleSection(): Section {
    return undefined;
  }

  getSectionsHeaderType(): Type<MenuSectionHeaderComponent> {
    return ProductSectionHeaderComponent;
  }

  getFooterType(): Type<ProductMenuFooterComponent> {
    return ProductMenuFooterComponent;
  }

  getShowHeader(): boolean {
    return true;
  }

  getShowFooter(): boolean {
    return !this.isLandscape();
  }

  getShouldSectionsContainerFlexWrap(): boolean {
    return this.isInOverflowModeThatNeedsFlexWrap() || this.moreThanOneSectionColumn();
  }

  getSectionsContainerFlexDirection(): string {
    const isPortrait = this.isPortrait();
    const isManuallyScrolling = this.isInVerticalScrollingOverflowState();
    const moreThanOneColumn = this.moreThanOneSectionColumn();
    const rowDirection = isPortrait && isManuallyScrolling && moreThanOneColumn;
    return rowDirection ? 'row' : 'column';
  }

  getSectionsContainerAlignContent(): string {
    return (this.getSectionsContainerFlexDirection() === 'row') ? 'flex-start' : null;
  }

  getSectionWidthPercentage(): number {
    if (this.columnCount > 0) {
      return 100 / this.columnCount;
    } else {
      return this.isLandscape() ? 50 : 100;
    }
  }

  getNumberOfColumns(): number {
    return this.columnCount || (this.isLandscape() ? 2 : 1);
  }

  getShouldOverflowHorizontallyElseVertically(): boolean {
    const multipleColumn = this?.getSectionWidthPercentage() < 100;
    return multipleColumn && !this.isInVerticalScrollingOverflowState();
  }

  getShowSectionHeaderColorAsIcon(): boolean {
    return false;
  }

  getSectionHeaderUnderlined(): boolean {
    return true;
  }

  getSectionTitleUnderlineColor(section: Section): string {
    if (this.menuOptions?.showAltLogo) {
      return '#FFFFFF';
    } else {
      return '#222222';
    }
  }

  getSectionTitleBorderColor(section: Section): string {
    return '';
  }

  getColWidths(sectionRowViewModel: SectionRowViewModel, stdPercentage: number = ColumnWidth.Default): ColWidth[] {
    const sectionColumnConfigMap = sectionRowViewModel?.section?.columnConfig;
    return [
      ColumnUtils.getProducerColWidth(stdPercentage),
      ColumnUtils.getBadgeColWidth(stdPercentage, sectionColumnConfigMap),
      ColumnUtils.getBrandColWidth(sectionColumnConfigMap),
      ColumnUtils.getStrainClassColWidth(stdPercentage, sectionColumnConfigMap),
      ColumnUtils.getAssetColWidth(stdPercentage, sectionColumnConfigMap),
      ...ColumnUtils.getPrimaryCannabinoidColWidths(sectionColumnConfigMap),
      ...ColumnUtils.getSecondaryCannabinoidColWidths(sectionColumnConfigMap),
      ...ColumnUtils.getTerpeneColWidths(sectionColumnConfigMap),
      ColumnUtils.getSpacerColWidth(this),
      ColumnUtils.getTinySpacerColWidth(),
      ColumnUtils.getQuantityInStockColWidth(stdPercentage, sectionColumnConfigMap),
      ColumnUtils.getQuantityAndSizeColWidth(stdPercentage, sectionColumnConfigMap),
      ColumnUtils.getQuantityColWidth(stdPercentage, sectionColumnConfigMap),
      ColumnUtils.getSizeColWidth(stdPercentage, sectionColumnConfigMap),
      ColumnUtils.getPriceColWidth(stdPercentage, sectionColumnConfigMap),
      ColumnUtils.getSecondaryPriceColWidth(stdPercentage, sectionColumnConfigMap),
    ];
  }

  getColumnGroupingSpacerWidthPercentage(): number {
    return 4;
  }

  getThemeSpecifiedColumnViewModels(
    sectionRowViewModels: SectionRowViewModel[],
    rowViewModel: SectionRowViewModel,
    widths: ColWidth[] = this.getColWidths(rowViewModel)
  ): SectionColumnViewModel[] {
    const standardizedColumns = ColumnViewModelUtils.standardizedDigitalColumnViewModels;
    const columns = standardizedColumns(this, sectionRowViewModels, rowViewModel, widths);
    const sortedColumnViewModels = columns?.sort(SortUtils.columnViewModelByOrdering);
    let columnGroupings: ColumnGrouping[];
    if (rowViewModel?.variantLineItemMode) {
      columnGroupings = rowViewModel?.menu?.getLineModeColumnGroupings(sortedColumnViewModels);
    } else {
      columnGroupings = rowViewModel?.menu?.getGridModeColumnGroupings(sortedColumnViewModels);
    }
    const spacerWidth = widths.find(it => it.type === SectionColumnProductInfoType.Spacer).widthPercentage ?? '5';
    return ColumnViewModelUtils.addSpacersBetweenGroupings(spacerWidth, columnGroupings, sortedColumnViewModels);
  }

  /**
   * Theme limited badge count per line item.
   * 0 means don't limit the number of badges per line item.
   * This is counterintuitive, but how it was designed/implemented at the time.
   */
  getAllowedBadgeCount(): number {
    return this.hydratedTheme?.themeFeatures?.badgeCount ?? 0;
  }

  getNumberOfBadgesForVariant(row: SectionRowViewModel): number {
    return row?.getAllVariantBadges()?.length || 0;
  }

  getShouldHideHeaderContent(s: Section, col: SectionColumnViewModel) {
    return false;
  }

  getShouldHideColumnContent(s: Section, col: SectionColumnViewModel, row: SectionRowViewModel): boolean {
    return false;
  }

  getShouldInflateColumnForRow(s: Section, col: SectionColumnViewModel, row: SectionRowViewModel): boolean {
    return true;
  }

  hideProductLabelColumnWhenEmpty(): boolean {
    return false;
  }

  ignoreLabelColumnWidth(): boolean {
    return false;
  }

  getShouldInflateColumnHeaderForRow(s: Section, col: SectionColumnViewModel, rows: SectionRowViewModel[]): boolean {
    return true;
  }

  getProductSubtitle(
    section: Section,
    product: Product,
    rowVm: SectionRowViewModel
  ): string {
    return product.getBrand(rowVm.rowVariants.map(v => v.id));
  }

  getProductTertiaryTitle(
    section: Section,
    product: Product,
    rowVm: SectionRowViewModel
  ): string {
    return null;
  }

  getDropDecimal(): boolean {
    return this.menuOptions?.hidePriceDecimal;
  }

  getDropDollarSign(): boolean {
    return false;
  }

  hideCannabinoidDecimal(): boolean {
    return this.menuOptions?.hideCannabinoidDecimal;
  }

  getPriceInteger(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel
  ): string {
    return this.getVariantPricing(priceStream, locationId, s, rowVM, colVm, true, this.getDropDollarSign());
  }

  getPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel,
  ): string {
    let decimalString = '';
    let price;
    if (!this.getDropDecimal()) {
      price = this.getVariantPricing(priceStream, locationId, s, rowVM, colVm, this.getDropDecimal(), true);
      const dec = price.split('.')[1] || '';
      // decimals and formatting are included. ie: $1000.00
      if (price === '-' || price.length > 7 || dec === '') {
        decimalString = '';
      } else {
        decimalString = `.${dec}`;
      }
    }
    // Append /ea or /uom to pricing tier grid prices if price is set
    if (s?.isPricingTierGridMode() && price !== '-') {
      const variantMatch = rowVM?.getVariantMatch(colVm);
      const pricingTierModifier = variantMatch?.shouldUseWeightForPricingTierGridColumn()
        ? (variantMatch?.unitOfMeasure)
        : 'ea';
      return `${decimalString}/${pricingTierModifier}`;
    }
    return decimalString;
  }

  getSecondaryPriceInteger(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel
  ): string {
    const dropDollarSign = this.getDropDollarSign();
    return this.getVariantSecondaryPricing(priceStream, locationId, s, rowVM, colVm, true, dropDollarSign);
  }

  getSecondaryPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel,
  ): string {
    const dropDec = this.getDropDecimal();
    if (!dropDec) {
      const price = this.getVariantSecondaryPricing(priceStream, locationId, s, rowVM, colVm, dropDec, true);
      const dec = price.split('.')[1] || '';
      // decimals and formatting are included. ie: $1000.00
      if (price === '-' || price.length > 7 || dec === '') {
        return '';
      } else {
        return `.${dec}`;
      }
    } else {
      return '';
    }
  }

  getVariantPricing(
    priceStream: LocationPriceStream,
    locId: number,
    s: SectionWithProducts,
    sectionRowVM: SectionRowViewModel,
    columnVM: SectionColumnViewModel,
    dropDec: boolean,
    dropDollarSign: boolean
  ): string {
    const variantMatch = sectionRowVM?.getVariantMatch(columnVM);
    const hidePrice = !!sectionRowVM?.hidePriceOnVariantIds?.find(id => id === variantMatch?.id);
    if (!variantMatch || hidePrice) {
      // out of stock
      return '-';
    } else {
      const pricingTierName = s.isPricingTierGridMode() ? columnVM.columnTitle : null;
      const vPrice = this.getVariantPrice(priceStream, locId, variantMatch, dropDec, dropDollarSign, pricingTierName);
      const isZero = /^\$?0*\.?0*$/.exec(vPrice)?.length > 0;
      const isGarbageStagingData = !environment.production && vPrice?.includes('1000');
      return ((isZero && !dropDec) || isGarbageStagingData) ? '-' : vPrice;
    }
  }

  getVariantSecondaryPricing(
    priceStream: LocationPriceStream,
    locId: number,
    s: SectionWithProducts,
    sectionRowVM: SectionRowViewModel,
    columnVM: SectionColumnViewModel,
    dropDecimal: boolean,
    dropDollarSign: boolean
  ): string {
    const match = sectionRowVM?.getVariantFromGridColumn(columnVM);
    const hidePrice = !!sectionRowVM?.hidePriceOnVariantIds?.find(id => id === match?.id);
    if (!match || hidePrice) {
      // out of stock
      return '-';
    } else {
      const mode = columnVM?.secondaryPriceMode;
      const vPrice = this.getVariantSecondaryPrice(priceStream, locId, match, dropDecimal, dropDollarSign, mode);
      const isZero = /^\$?0*\.?0*$/.exec(vPrice)?.length > 0;
      return isZero ? '-' : vPrice;
    }
  }

  getOriginalVariantPricing(
    priceStream: LocationPriceStream,
    locationId: number,
    sectionWithProducts: SectionWithProducts,
    sectionRowVM: SectionRowViewModel,
    columnVM: SectionColumnViewModel,
    dropDecimal: boolean,
    dropDollarSign: boolean,
    specificPriceWithoutDiscounts: SpecificPriceWithoutDiscounts[]
  ): string {
    const variantMatch = sectionRowVM?.getVariantMatch(columnVM);
    const hidePrice = !!sectionRowVM?.hidePriceOnVariantIds?.find(id => id === variantMatch?.id);
    if (!variantMatch || hidePrice) {
      return '';
    } else {
      const vPrice = this.getOriginalVariantPrice(
        priceStream,
        locationId,
        variantMatch,
        dropDecimal,
        dropDollarSign,
        specificPriceWithoutDiscounts
      );
      const isZero = /^\$?0*\.?0*$/.exec(vPrice)?.length > 0;
      const isGarbageStagingData = !environment.production && vPrice?.includes('1000');
      return (isZero || isGarbageStagingData) ? '' : vPrice;
    }
  }

  getOriginalPriceInteger(
    priceStream: LocationPriceStream,
    locationId: number,
    sectionWithProducts: SectionWithProducts,
    sectionRowViewModel: SectionRowViewModel,
    sectionColumnViewModel: SectionColumnViewModel,
    specificPriceWithoutDiscounts: SpecificPriceWithoutDiscounts[]
  ): string {
    return this.getOriginalVariantPricing(
      priceStream,
      locationId,
      sectionWithProducts,
      sectionRowViewModel,
      sectionColumnViewModel,
      true,
      this.getDropDollarSign(),
      specificPriceWithoutDiscounts
    );
  }

  getOriginalPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    section: SectionWithProducts,
    rowViewModel: SectionRowViewModel,
    columnViewModel: SectionColumnViewModel,
    specificPriceWithoutDiscounts: SpecificPriceWithoutDiscounts[]
  ): string {
    if (!this.getDropDecimal()) {
      const price = this.getOriginalVariantPricing(
        priceStream,
        locationId,
        section,
        rowViewModel,
        columnViewModel,
        this.getDropDecimal(),
        true,
        specificPriceWithoutDiscounts
      );
      const dec = price.split('.')?.[1] || '';
      // decimals and formatting are included. ie: $1000.00
      if (price === '-' || price.length > 7 || dec === '') {
        return '';
      } else {
        return `.${dec}`;
      }
    } else {
      return '';
    }
  }

  isVariantPriceDiscounted(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    sectionRowVM: SectionRowViewModel,
    columnVM: SectionColumnViewModel
  ): boolean {
    const variantMatch = sectionRowVM?.getVariantMatch(columnVM);
    const hidePrice = !!sectionRowVM?.hidePriceOnVariantIds?.find(id => id === variantMatch?.id);
    const hideSale = sectionRowVM?.getHideSale();
    if (!variantMatch || hidePrice || hideSale) {
      // out of stock
      return false;
    }
    return variantMatch?.hasDiscount(this.theme, locationId, this.companyId, priceStream);
  }

  getVariantPrice(
    priceStream: LocationPriceStream,
    locId: number,
    variant: Variant,
    dropDecimal: boolean,
    dropDollarSign: boolean,
    pricingTierGridName: string = null
  ): string {
    const hideSale = this.menuOptions?.hideSale;
    const tId = this.theme;
    const lowestPrice = variant.getVisiblePrice(tId, locId, this.companyId, priceStream, hideSale, pricingTierGridName);
    return this.getFormattedPrice(priceStream, lowestPrice, dropDecimal, dropDollarSign);
  }

  getVariantSecondaryPrice(
    priceStream: LocationPriceStream,
    locId: number,
    variant: Variant,
    dropDecimal: boolean,
    dropDollarSign: boolean,
    mode: SectionColumnConfigDataValue
  ): string {
    const tId = this.theme;
    let secondaryPrice: number;
    switch (mode) {
      case SectionColumnConfigDataValue.PricePerUOM:
        secondaryPrice = variant.getPricePerUOM(tId, locId, this.companyId, priceStream, this.menuOptions?.hideSale);
        break;
      case SectionColumnConfigDataValue.OriginalPrice:
        secondaryPrice = variant.getPriceWithoutDiscounts(tId, locId, this.companyId, priceStream);
        break;
      case SectionColumnConfigDataValue.SaleOriginalPrice:
        const salePrice = variant.getDiscountedPriceOrNull(tId, locId, this.companyId, priceStream);
        if (!!salePrice) {
          secondaryPrice = variant.getPriceWithoutDiscounts(tId, locId, this.companyId, priceStream);
        }
        break;
      case SectionColumnConfigDataValue.OriginalAndSalePrice:
        secondaryPrice = variant.getVisiblePrice(tId, locId, this.companyId, priceStream, false);
        break;
      case SectionColumnConfigDataValue.TaxesInPrice:
        secondaryPrice = variant.getTaxesInPrice(tId, locId, this.companyId);
        break;
      case SectionColumnConfigDataValue.TaxesInRoundedPrice:
        secondaryPrice = variant.getTaxesInRoundedPrice(tId, locId, this.companyId);
        break;
      case SectionColumnConfigDataValue.PreTaxPrice:
        secondaryPrice = variant.getPreTaxPrice(tId, locId, this.companyId);
        break;
      default:
        secondaryPrice = variant.getSecondaryPrice(tId, this.companyId, locId, priceStream);
        break;
    }
    return this.getFormattedPrice(priceStream, secondaryPrice, dropDecimal, dropDollarSign);
  }

  /**
   * specificPriceWithoutDiscounts: order matters, the first non-null price will be used.
   */
  getOriginalVariantPrice(
    priceStream: LocationPriceStream,
    locationId: number,
    variant: Variant,
    dropDecimal: boolean,
    dropDollarSign: boolean,
    specificPriceWithoutDiscounts: SpecificPriceWithoutDiscounts[]
  ): string {
    const getPrice = (specificPrice: SpecificPriceWithoutDiscounts | null = null): number | null => {
      switch (specificPrice) {
        case SpecificPriceWithoutDiscounts.BasePrice:
          return variant.getBasePriceOrNull(this.theme, this.companyId, locationId, priceStream);
        case SpecificPriceWithoutDiscounts.CompanySpecificBasePrice:
          return variant.getCompanyPriceWithoutDiscountsOrNull(this.theme, this.companyId, locationId, priceStream);
        case SpecificPriceWithoutDiscounts.CompanySpecificSecondaryBasePrice:
          return variant.getCompanySecondaryPriceOrNull();
        case SpecificPriceWithoutDiscounts.LocationSpecificBasePrice:
          return variant.getLocationPriceWithoutDiscountsOrNull(this.theme, this.companyId, locationId, priceStream);
        case SpecificPriceWithoutDiscounts.LocationSpecificSecondaryBasePrice:
          return variant.getLocationSecondaryPriceOrNull(locationId);
        default:
          return variant.getPriceWithoutDiscounts(this.theme, locationId, this.companyId, priceStream);
      }
    };
    const price = specificPriceWithoutDiscounts?.length > 0
      ? specificPriceWithoutDiscounts.reduce<number|null>((acc, specificPrice) => acc ?? getPrice(specificPrice), null)
      : getPrice();
    return this.getFormattedPrice(priceStream, price, dropDecimal, dropDollarSign);
  }

  getFormattedPrice(
    priceStream: LocationPriceStream,
    price: number,
    dropDecimal: boolean,
    dropDollarSign: boolean
  ): string {
    let priceString: string;
    if (dropDecimal) {
      if (price === 0) {
        priceString = '-';
      } else {
        const truncated = (price === null || !isFinite(price)) ? '0' : String(Math.trunc(price));
        priceString = (dropDollarSign ? '' : '$') + truncated;
      }
    } else {
      if (price === 0) {
        priceString = '-';
      } else {
        const full = (price === null || !isFinite(price)) ? '0.00' : price.toFixed(2);
        priceString = (dropDollarSign ? '' : '$') + full;
      }
    }
    // Remove spaces
    return priceString?.replace(/\s/g, '') || '';
  }

  getFormattedPricingTierPrice(
    price: number,
    dropDecimal: boolean,
    dropDollarSign: boolean,
    variant: Variant
  ): string {
    const priceString = this.getFormattedPrice(
      LocationPriceStream.Default,
      price,
      dropDecimal,
      dropDollarSign
    );
    const pricingTierModifier = variant?.shouldUseWeightForPricingTierGridColumn()
      ? variant?.unitOfMeasure
      : 'ea';
    return `${priceString}/${pricingTierModifier}`;
  }

  primaryPriceColumnAlsoShowOriginalPriceIfOnSale(section: SectionWithProducts): boolean {
    const priceConfig = section?.columnConfig?.get(SectionColumnConfigProductInfoKey.Price);
    return priceConfig?.dataValue === SectionColumnConfigDataValue.OriginalAndSalePrice;
  }

  primaryPriceColumnAlsoShowOriginalPriceIfOnSalePosition(): Position {
    return Position.Top;
  }

  secondaryPriceColumnAlsoShowOriginalPriceIfOnSale(section: SectionWithProducts): boolean {
    const priceConfig = section?.columnConfig?.get(SectionColumnConfigProductInfoKey.SecondaryPrice);
    return priceConfig?.dataValue === SectionColumnConfigDataValue.OriginalAndSalePrice;
  }

  secondaryPriceColumnAlsoShowOriginalPriceIfOnSalePosition(): Position {
    return Position.Top;
  }

  // this does not affect the badge column, only inline badges within title or subtitle
  getShowBadgesInline(): boolean {
    return false;
  }

  getShowBadgesInlineOnTitleLineElseSubtitle(): boolean {
    return false;
  }

  getShowBadgesUnderSubtitle(): boolean {
    return false;
  }

  getShowInlineLabels(): boolean {
    return true;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return false;
  }

  getShowStrainTypes(): boolean {
    return false;
  }

  getShowStrainTypesOnTitleLineElseSubtitle(): boolean {
    return false;
  }

  getShowClassificationsUnderSubtitle(): boolean {
    return false;
  }

  getShowClassificationsInAssetColumn(): boolean {
    return false;
  }

  getProductWrapperClass(odd: boolean) {
    return '';
  }

  getProductWrapperStyling(section: SectionWithProducts, sectionRowViewModel: SectionRowViewModel, odd: boolean) {
    return {};
  }

  getLowAmountStyling(section: SectionWithProducts, rowVM: SectionRowViewModel, colVM: SectionColumnViewModel): any {
    const forcedRowFontColor = section.forcedRowTextColor(this, rowVM);
    const forcedColumnFontColor = section.forcedColumnTextColor(this, rowVM, colVM);
    const forcedFontColor = forcedRowFontColor || forcedColumnFontColor;
    const sectionOrMenuLevelFontColor = this.getSectionBodyTextColor(section) || '#000000';
    const fontColor = forcedFontColor || sectionOrMenuLevelFontColor;
    // If the user has selected a color at the column config or product level,
    // then force it to be more visible?
    // This was not well documented, so I don't exactly know why
    const opacity = !!forcedFontColor ? '0.8' : '0.5';
    const [R, G, B] = ColorUtils.hexToRgb(fontColor);
    const textColor = `rgba(${R}, ${G}, ${B}, ${opacity})`;
    return {
      color: textColor,
      'text-decoration-color': textColor
    };
  }

  getShowProductSubtitle(): boolean {
    return true;
  }

  getShowProductTertiaryTitle(): boolean {
    return false;
  }

  protected getLineModeProductTitle(variants: Variant[]): string {
    return variants?.firstOrNull()?.getVariantTitle();
  }

  protected getGridModeProductTitle(
    section: SectionWithProducts,
    product: Product,
    variants: Variant[],
    locationPriceStream: LocationPriceStream
  ): string {
    const sectionOverflow = this.isSectionLevelOverflow();
    const scopedVariants = section?.getScopedVisibleVariants(this, locationPriceStream, sectionOverflow, variants);
    if (scopedVariants?.length === 1) {
      const singleVariant = scopedVariants?.firstOrNull();
      if (exists(singleVariant)) {
        return singleVariant?.getVariantTitle();
      }
    }
    return product.getProductTitle();
  }

  getChildVariantListModeProductTitle(
    section: SectionWithProducts,
    product: Product,
    variants: Variant[],
    locationPriceStream: LocationPriceStream
  ): string {
    return this.getGridModeProductTitle(section, product, variants, locationPriceStream);
  }

  getProductTitle(
    section: SectionWithProducts,
    product: Product,
    variants: Variant[],
    locationPriceStream: LocationPriceStream
  ): string {
    switch (true) {
      case section.isInLineMode() && variants?.length > 0:
        return this.getLineModeProductTitle(variants);
      case section.isGridMode() || section.isPricingTierGridMode():
        return this.getGridModeProductTitle(section, product, variants, locationPriceStream);
      case section.isChildVariantListMode():
        return this.getChildVariantListModeProductTitle(section, product, variants, locationPriceStream);
      default:
        return product.getProductTitle();
    }
  }

  brandInlineWithProductName(): boolean {
    return false;
  }

  getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.Label, 0],
      [SectionColumnProductInfoType.ProductTitle, 1],
      [SectionColumnProductInfoType.Badge, 2],
      [SectionColumnProductInfoType.StrainType, 3],
      [SectionColumnProductInfoType.Brand, 4],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(5),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(6),
      [SectionColumnProductInfoType.Stock, 7],
      [SectionColumnProductInfoType.QuantityAndSize, 8],
      [SectionColumnProductInfoType.Quantity, 9],
      [SectionColumnProductInfoType.Size, 10],
      [SectionColumnProductInfoType.VariantPrice, 11],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 12],
    ]);
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    const outBadges = (sectionColumnViewModel: SectionColumnViewModel) => {
      return sectionColumnViewModel.columnType !== SectionColumnProductInfoType.Badge;
    };
    if (sectionColumnViewModels?.filter(outBadges)?.length >= 6) {
      return ColumnUtils.oneBigGrouping();
    }
    return ColumnUtils.standardizedGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    const outBadges = (sectionColumnViewModel: SectionColumnViewModel) => {
      return sectionColumnViewModel.columnType !== SectionColumnProductInfoType.Badge;
    };
    if (sectionColumnViewModels?.filter(outBadges)?.length >= 7) {
      return ColumnUtils.oneBigGrouping();
    }
    return ColumnUtils.standardizedGrouping();
  }

  protected getThemeStandardizedColumnViewModels(
    sectionRowViewModels: SectionRowViewModel[],
    rowViewModel: SectionRowViewModel,
    widths: ColWidth[]
  ): SectionColumnViewModel[] {
    return ColumnViewModelUtils.standardizedDigitalColumnViewModels(
      this,
      sectionRowViewModels,
      rowViewModel,
      widths
    );
  }

  getSectionTitle(s: Section): string {
    return s.title;
  }

  getOnlyShowFirstSectionHeader(): boolean {
    return false;
  }

  getMakeHeaderSectionImageFillEntireSection(): boolean {
    return false;
  }

  getTitleSectionTopMargin(): string {
    return '2.5rem';
  }

  getTitleSectionBottomMargin(): string {
    return '2.5rem';
  }

  getSectionBorderMarginSize(): string {
    return null;
  }

  // Overflow

  getOverflowState(): OverflowState {
    return this.overflowState;
  }

  hideOverflow(): boolean {
    return (this.getOverflowState() === OverflowState.NONE)
      && (this.type === MenuType.DisplayMenu);
  }

  isSectionLevelOverflow(): boolean {
    const overflowState = this.getOverflowState();
    return overflowState === OverflowState.SECTION_SCROLL
      || overflowState === OverflowState.SECTION_PAGING;
  }

  getSnapSectionFramesPerSecond(): number {
    return this.getNumberOfColumns() > 2 ? 24 : 40;
  }

  /**
   * When a product menu uses section level overflow, the rotation interval is replaced with a looping count,
   * instead of a direct time interval in seconds. This looping count is multiplied by the longest content loop.
   *
   * A "content loop" is the time required to display all content within a section.
   */
  calculateSectionLevelOverflowLongestContentLoopInSeconds(): number {
    const productSections = (s: Section): s is ProductSection => s instanceof ProductSection;
    const calculationData = (s: ProductSection) => s.getScopedVisibleLineItemDisplayDurationCalculationData(this);
    const lengthRowCountDurations = this.sections?.filter(productSections)?.map(calculationData) || [[0, 0, 0]];
    const loopDurationCalculator = {
      [OverflowState.SECTION_PAGING]: this.longestContentLoopInSecondsForSectionOverflowPaging.bind(this),
      [OverflowState.SECTION_SCROLL]: this.longestContentLoopInSecondsForSectionOverflowScroll.bind(this)
    };
    return loopDurationCalculator[this.overflowState]?.(lengthRowCountDurations) || 0;
  }

  /**
   * The longest content loop is the time required to display all content within a section when using
   * section level scrolling/snapping overflow.
   *
   * This number is calculated by multiplying the number of rows in the section by the Section Overflow Duration.
   */
  private longestContentLoopInSecondsForSectionOverflowScroll(lengthRowCountDurations: ContentLoopData[]): number {
    // lineItemLimit is a number specified by the user that limits the number of line items displayed at once
    const contentLoopDurations = ([numberOfLineItems, lineItemLimit, secs]: ContentLoopData) => {
      // If the lineItemLimit is not set, then the section will not page through content.
      // We only want to calculate content loop durations for sections that page through content.
      const nItems = (lineItemLimit < 1) ? 0 : numberOfLineItems;
      return nItems * secs;
    };
    const durations = lengthRowCountDurations?.map(contentLoopDurations) || [];
    const largestContentLoop = Math.max(...durations);
    return Number.isFinite(largestContentLoop) ? largestContentLoop : 0;
  }

  /**
   * The longest content loop is the time required to display all content within a section when using
   * section level paging overflow.
   *
   * This number is calculated by multiplying the number of pages in the section by the Section Overflow Duration.
   */
  private longestContentLoopInSecondsForSectionOverflowPaging(lengthRowCountDurations: ContentLoopData[]): number {
    // lineItemLimit is a number specified by the user that limits the number of line items displayed at once
    const sectionsWithLimitedLineItems = ([_, lineItemLimit]: ContentLoopData) => lineItemLimit > 0;
    const limitedLineItemSections = lengthRowCountDurations?.filter(sectionsWithLimitedLineItems);
    const calculateDurations = ([nLineItems, lineItemLimit, secs]) => Math.ceil(nLineItems / lineItemLimit) * secs;
    const durations = limitedLineItemSections?.map(calculateDurations) || [];
    const largestDuration = Math.max(...durations);
    return Number.isFinite(largestDuration) ? largestDuration : 0;
  }

  isMenuLevelOverflow(): boolean {
    return !this.isSectionLevelOverflow();
  }

  hasOverflowState(): boolean {
    return this.getOverflowState() !== OverflowState.NONE;
  }

  isInHorizontalScrollOverflowState(): boolean {
    return (this.isLandscape())
      && (this.getOverflowState() === OverflowState.SCROLL);
  }

  isInVerticalScrollOverflowState(): boolean {
    return (this.isPortrait())
      && (this.getOverflowState() === OverflowState.SCROLL);
  }

  isInHorizontalScrollingOverflowState(): boolean {
    return (this.isLandscape())
      && (this.getOverflowState() === OverflowState.STEADY_SCROLL);
  }

  isInVerticalScrollingOverflowState(): boolean {
    return (this.isPortrait())
      && (this.getOverflowState() === OverflowState.STEADY_SCROLL);
  }

  isInPagingOverflowState(): boolean {
    const scrollPaging = this.isInHorizontalScrollOverflowState() || this.isInVerticalScrollOverflowState();
    const paging = this.isInHorizontalPagingOverflowState() || this.isInVerticalPagingOverflowState();
    return scrollPaging || paging;
  }

  isInScrollPageOverflowState(): boolean {
    return this.isInHorizontalScrollOverflowState() || this.isInVerticalScrollOverflowState();
  }

  isInHorizontalPagingOverflowState(): boolean {
    return (this.isLandscape())
      && (this.getOverflowState() === OverflowState.PAGING);
  }

  isInVerticalPagingOverflowState(): boolean {
    return (this.isPortrait())
      && (this.getOverflowState() === OverflowState.PAGING);
  }

  isInOverflowModeThatNeedsFlexWrap(): boolean {
    const verticalNeedsFlexWrap = this.isInVerticalPagingOverflowState()
      || this.isInVerticalScrollOverflowState();
    const horizontalThatNeedsFlexWrap = this.isInHorizontalPagingOverflowState()
      || this.isInHorizontalScrollOverflowState()
      || this.isInHorizontalScrollingOverflowState();
    return (verticalNeedsFlexWrap || horizontalThatNeedsFlexWrap);
  }

  // Column Titles

  getPriceColumnTitle(col: SectionColumnViewModel): string {
    return col.getPriceLongFormatColumnTitle();
  }

  /**
   * don't delete even if webstorm says it's unused. It's being used via this?.['getQuantityInStockColumnTitle']
   */
  getQuantityInStockColumnTitle(col: SectionColumnViewModel): string {
    return col?.columnTitle ?? '';
  }

  /**
   * don't delete even if webstorm says it's unused. It's being used via this?.['getQuantityAndSizeColumnTitle']
   */
  getQuantityAndSizeColumnTitle(col: SectionColumnViewModel): string {
    return col?.columnTitle ?? '';
  }

  /**
   * don't delete even if webstorm says it's unused. It's being used via this?.['getQuantityColumnTitle']
   */
  getQuantityColumnTitle(col: SectionColumnViewModel): string {
    return col?.columnTitle ?? '';
  }

  /**
   * don't delete even if webstorm says it's unused. It's being used via this?.['getSizeColumnTitle']
   */
  getSizeColumnTitle(col: SectionColumnViewModel): string {
    return col?.columnTitle ?? '';
  }

  /**
   * don't delete even if webstorm says it's unused. It's being used via this?.['getTypeColumnTitle']
   */
  getTypeColumnTitle(col: SectionColumnViewModel): string {
    return col?.columnTitle ?? '';
  }

  /**
   * don't delete even if webstorm says it's unused. It's being used via this?.['getAssetColumnTitle']
   */
  getAssetColumnTitle(col: SectionColumnViewModel): string {
    // We are not allowing asset column titles on initial release
    return '';
  }

  /**
   * don't delete even if webstorm says it's unused. It's being used via this?.['getCannabinoidColumnTitle']
   */
  getCannabinoidColumnTitle(col: SectionColumnViewModel): string {
    return col?.columnTitle ?? '';
  }

  /**
   * don't delete even if webstorm says it's unused. It's being used via this?.['getTerpeneColumnTitle']
   */
  getTerpeneColumnTitle(col: SectionColumnViewModel): string {
    return col?.columnTitle ?? '';
  }

  getBadgeColumnTitle(section: Section, col: SectionColumnViewModel): string {
    return col?.columnTitle ?? '';
  }

  getUniqueBadgeList(locationConfig: LocationConfiguration, sections: Section[] = null): VariantBadge[] {
    const sectionsToParse = sections ?? this.sections;
    return sectionsToParse
      ?.map(section => {
        if (SectionUtils.isSectionWithProducts(section)) {
          const getLineItems = section?.getScopedVisibleVariantsWithoutFlattening.bind(section);
          const lineItems: any[] = getLineItems(this, locationConfig?.priceFormat, this.isSectionLevelOverflow());
          return this.getVariantBadgesForLineItems(section, lineItems);
        } else {
          return [] as VariantBadge[];
        }
      })
      ?.flatten<VariantBadge[]>()
      ?.filterNulls()
      ?.uniqueByProperty('id')
      ?.sort(SortUtils.sortBadgesByName);
  }

  private getVariantBadgesForLineItems(
    section: SectionWithProducts,
    lineItems: Variant[] | Variant[][]
  ): VariantBadge[] {
    // typescript can't figure out what to do if this is left as Variant[] | Variant[][] so I have to cast it to any[]
    return (lineItems || [] as any[])
      ?.map(lineItem => {
        if (lineItem instanceof Array) {
          return BadgeUtils.getVariantVisibleBadges(this, section, lineItem)?.sort(SortUtils.sortBadgesByName);
        } else if (lineItem instanceof Variant) {
          return BadgeUtils.getVariantVisibleBadges(this, section, [lineItem]);
        } else {
          return [];
        }
      })
      ?.flatten<VariantBadge[]>();
  }

  getUniqueClassificationList(sections: Section[] = null): StrainType[] {
    const sectionsToParse = sections ?? this.sections;
    return sectionsToParse
      ?.map(section => {
        if (SectionUtils.isSectionWithProducts(section)) {
          return section?.originalVariants
            ?.map(variant => {
              if (this.shouldStandardizeDominantStrainType()) {
                switch (variant?.classification) {
                  case StrainType.IndicaDominant:
                    return StrainType.Indica;
                  case StrainType.SativaDominant:
                    return StrainType.Sativa;
                }
              }
              return variant?.classification;
            })
            ?.flatten<StrainType[]>();
        } else {
          return [] as StrainType[];
        }
      })
      ?.flatten<StrainType[]>()
      ?.filterNulls()
      ?.unique()
      ?.sort(SortVariantUtils.strainTypeSortAsc);
  }

  protected translateVariantClassificationsIntoHybridIndicaSativa(sections: Section[]): void {
    sections?.filter(SectionUtils.isSectionWithProducts)?.forEach(sectionWithProducts => {
      sectionWithProducts?.products?.forEach(product => {
        product?.variants?.forEach(variant => {
          if (variant?.classification === StrainType.Blend) {
            variant.classification = StrainType.Hybrid;
          } else if (variant?.classification === StrainType.IndicaDominant) {
            variant.classification = StrainType.Indica;
          } else if (variant?.classification === StrainType.SativaDominant) {
            variant.classification = StrainType.Sativa;
          }
        });
      });
    });
  }

  /* ***************************************************************** */

  protected moreThanOneSectionColumn(): boolean {
    return this.getSectionWidthPercentage() <= 50;
  }

  protected shouldSplitSectionsInHalf(menu: Menu): boolean {
    if (menu instanceof ProductMenu) {
      const portrait = menu.isPortrait();
      const scrolling = menu.isInVerticalScrollingOverflowState();
      const moreThanOneColumn = menu.getSectionWidthPercentage() < 100;
      return portrait && scrolling && moreThanOneColumn;
    }
    return false;
  }

  checkForMenuColumnCountDiscrepancies(): void {
    if (this.isPortrait()) {
      const supportedColumnCount = this.hydratedTheme?.menuColumnCountConfig?.supportedMenuColumnCountPortrait;
      const supported = supportedColumnCount?.contains(this.columnCount);
      if (!supported) {
        this.columnCount = this.hydratedTheme?.menuColumnCountConfig?.defaultMenuColumnCountPortrait;
      }
    } else {
      const supportedColumnCount = this.hydratedTheme?.menuColumnCountConfig?.supportedMenuColumnCountLandscape;
      const supported = supportedColumnCount?.contains(this.columnCount);
      if (!supported) {
        this.columnCount = this.hydratedTheme?.menuColumnCountConfig?.defaultMenuColumnCountLandscape;
      }
    }
  }

  getDefaultTitleSectionImageSrc(): string {
    return null;
  }

  private supportsSectionHeaderTextColor(): boolean {
    return this.hydratedTheme?.themeFeatures?.sectionHeaderTextColor;
  }

  private supportsSectionBodyTextColor(): boolean {
    return this.hydratedTheme?.themeFeatures?.sectionBodyTextColor;
  }

  private supportsBodyTextColor(): boolean {
    return this.hydratedTheme?.themeFeatures?.bodyTextColor;
  }

  private getBodyTextColor(): string {
    return this.menuOptions.bodyTextColor;
  }

  getSectionHeaderTextColor(section: SectionWithProducts): string {
    if (this.supportsSectionHeaderTextColor() && !!section?.getSectionHeaderTitleColor()) {
      return section?.getSectionHeaderTitleColor();
    } else if (this.supportsBodyTextColor() && !!this.getBodyTextColor()) {
      return this.getBodyTextColor();
    } else {
      return null;
    }
  }

  getProductSectionBackgroundColor(section: Section): string {
    return null;
  }

  getEmptySectionType(): Type<EmptySection> {
    return EmptySection;
  }

  getSectionBodyTextColor(section: Section): string {
    if (this.supportsSectionBodyTextColor() && !!section?.getSectionBodyTextColor()) {
      return section?.getSectionBodyTextColor();
    } else if (this.supportsBodyTextColor() && !!this.getBodyTextColor()) {
      return this.getBodyTextColor();
    } else {
      return null;
    }
  }

  interceptLineItemRowBackgroundColor(color: string, odd: boolean, rowViewModel: SectionRowViewModel): string {
    return color;
  }

  interceptLineItemColumnBackgroundColor(color: string, bandingEnabled: boolean): string {
    return color;
  }

  getRowColorFromStrainType(odd: boolean, rowViewModel: SectionRowViewModel): string {
    return null;
  }

  bandedRowMode(): BandedRowMode {
    return BandedRowMode.Off;
  }

  bandedRowsDefaultEnabled(): boolean {
    return false;
  }

  getDefaultBandingHexString(): [number, number, number] {
    const invertedHex = ColorUtils.invertColor('#FFFFFF', false);
    return ColorUtils.hexToRgb(invertedHex);
  }

  getDefaultBandingOpacity(): number {
    return 0.15;
  }

  bandedRowColor(rowViewModel: SectionRowViewModel, odd: boolean, opacityModifier: number = 0.65): string {
    if (this.bandedRowMode() === BandedRowMode.Off || !this.currentRowIsBanded(odd)) {
      // Banding is disabled, or current row is not banded
      return null;
    } else {
      // Handle default banding case for all menus that support banding
      let bgColor;
      if (!!rowViewModel?.section?.metadata?.productsContainerBackgroundColor) {
        bgColor = rowViewModel?.section?.metadata?.productsContainerBackgroundColor;
      } else if (!!this.menuOptions.bodyBackgroundColor && this.menuOptions.backgroundOpacity > 0) {
        bgColor = this.menuOptions.bodyBackgroundColor;
      }
      const isLighten = this.bandedRowColorContrast() === BandedRowColorContrast.Lighten;
      if (!!bgColor) {
        if (isLighten) {
          return `rgba(255, 255, 255, ${opacityModifier})`;
        } else {
          return `rgba(0, 0, 0, ${opacityModifier})`;
        }
      } else if (this.bandedRowsDefaultEnabled()) {
        const [R, G, B] = this.getDefaultBandingHexString();
        const opacity = this.getDefaultBandingOpacity();
        return `rgba(${R}, ${G}, ${B}, ${opacity})`;
      } else {
        return null;
      }
    }
  }

  currentRowIsBanded(odd: boolean): boolean {
    const bandedRowMode = this.bandedRowMode();
    const evenBand = bandedRowMode === BandedRowMode.Even && !odd;
    const oddBand = bandedRowMode === BandedRowMode.Odd && odd;
    return evenBand || oddBand;
  }

  bandedRowColorContrast(): BandedRowColorContrast {
    return BandedRowColorContrast.Lighten;
  }

  bandedRowColorContrastAmount(): number {
    return this.bandedRowColorContrast() === BandedRowColorContrast.Darken ? 20 : 40;
  }

  /**
   * Makes is so that brands don't appear empty.
   */
  protected fixProductBrands(sections: Section[]): void {
    for (const section of sections ?? []) {
      if (SectionUtils.isSectionWithProducts(section)) {
        section?.products?.forEach(product => {
          if (!product?.getBrand(product?.variants?.map(v => v.id))) {
            // None of the variants have a brand, so set them all to --
            product?.variants?.forEach(v => v.brand = '--');
          }
        });
      }
    }
  }

  shouldStandardizeDominantStrainType(): boolean {
    return true;
  }

  allStrainTypeColumnsShutOff(): boolean {
    return this.sections
      ?.filter((section: Section): section is SectionWithProducts => SectionUtils.isProductSection(section))
      ?.every(section => section?.strainTypeColumnTurnedOff());
  }

}
