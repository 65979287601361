import { Injectable } from '@angular/core';
import { PrintCardBackgroundViewModel } from '../../print-card-background-view-model';
import { combineLatest, Observable } from 'rxjs';
import { SessionsCardData } from '../../../../../../../models/print-cards/sessions-card-data';
import { map } from 'rxjs/operators';
import { CardStack } from '../../../../../../../models/menu/section/card-stacks/card-stack';

@Injectable()
export class SessionsPostCardStackBackgroundViewModel extends PrintCardBackgroundViewModel {

  private static COLOR_STRIP_WIDTH_IN_PERCENT = 18.75;
  private static DIVIDER_TOP_MARGIN_IN_REM = 19.75;
  private static REM_TO_PX_CONVERSION_VALUE = 16;

  public readonly cardData$: Observable<SessionsCardData>;

  public colorStripColor$ = this.cardData$.pipe(
    map(cardData => cardData?.getColorStripColor())
  );

  public colorStripWidth$ = combineLatest([
    this.cardStack$,
    this.preview$
  ]).pipe(
    map(([cardStack, preview]) => {
      return this.getDimensionAdjustment(
        cardStack,
        SessionsPostCardStackBackgroundViewModel.COLOR_STRIP_WIDTH_IN_PERCENT,
        preview
      );
    })
  );

  private getDimensionAdjustment(cardStack: CardStack, colorStripWidth: number, preview: boolean): string {
    const bleed = (cardStack?.nonPerforatedBleedBorderInInches() ?? 0) * CardStack.PIXELS_PER_INCH;
    const additionalWidth = cardStack?.isOnRegularPaper() && !preview ? bleed : 0;
    return `calc(${colorStripWidth}% + ${additionalWidth + 1}px)`;
  }

  public colorStripTopSectionHeight$ = combineLatest([
    this.cardStack$,
    this.preview$
  ]).pipe(
    map(([cardStack, preview]) => {
      return this.getHeightAdjustment(
        cardStack,
        SessionsPostCardStackBackgroundViewModel.DIVIDER_TOP_MARGIN_IN_REM,
        preview
      );
    })
  );

  private getHeightAdjustment(cardStack: CardStack, dividerTopMargin: number, preview: boolean): number {
    const bleed = (cardStack?.nonPerforatedBleedBorderInInches() ?? 0) * CardStack.PIXELS_PER_INCH;
    const additionalWidth = cardStack?.isOnRegularPaper() && !preview ? bleed : 0;
    return dividerTopMargin * SessionsPostCardStackBackgroundViewModel.REM_TO_PX_CONVERSION_VALUE + additionalWidth;
  }

}
