import { PrintCardContentViewModel } from '../../print-card-content-view-model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { JaneDoeCardData } from '../../../../../../../models/print-cards/jane-doe-card-data';

@Injectable()
export class JaneDoeAddressCardStackContentViewModel extends PrintCardContentViewModel {

  public readonly cardData$: Observable<JaneDoeCardData>;

  public readonly quantityAndSizeText$ = this.cardData$.pipe(
    map(cardData => cardData?.getQuantityAndSizeString())
  );

}
