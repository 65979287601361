import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ProductMenuFooterComponent } from '../product-menu-footer.component';
import { PrintMenu } from '../../../../../../../../models/menu/print-menu';

@Component({
  selector: 'app-swimlane-classification-and-badges-legend-footer',
  templateUrl: './swimlane-classification-and-badges-legend-footer.component.html',
  styleUrls: ['./swimlane-classification-and-badges-legend-footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SwimlaneClassificationAndBadgesLegendFooterComponent extends ProductMenuFooterComponent {

  @Input() override menu: PrintMenu;

}
