import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PrintCardGridViewModel } from './print-card-grid-view-model.service';
import { RenderContextComponent } from '../../../../models/base/render-context-component';
import { CardData } from '../../../../models/print-cards/card-data';

@Component({
  selector: 'app-print-card-grid',
  templateUrl: './print-card-grid.component.html',
  styleUrls: ['./print-card-grid.component.scss'],
  providers: [PrintCardGridViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintCardGridComponent extends RenderContextComponent {

  constructor(
    public viewModel: PrintCardGridViewModel
  ) {
    super();
  }

  @Input() cards: CardData[];

  setupBindings() {
  }

  setupViews() {
  }

}
