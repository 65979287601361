<!--
  Notice the reduced amount of column types and components used within this template.
  This is because a lot of line items get rendered within this menu, and the more components and
  column types used, the more memory and processing power is required to render the menu.
-->

<div
  #sectionItem
  class="product-container"
  id="product-container-m{{ menu.id }}-s{{ sectionIndex }}-i{{ itemIndex }}"
  [ngClass]="[
    rowViewModel | sectionRowViewModelBasicMethod: 'getReadableStrainType' | lowercase,
    menu | productMenuBasicMethod: 'getProductWrapperClass' : odd
  ]"
  [class.odd]="odd"
  [class.even]="!odd"
  [class.first]="first"
  [class.last]="last"
  [class.force-bold-style]="viewModel.forcedRowBoldStyling$ | async"
  [class.force-italic-style]="viewModel.forcedRowItalicStyling$ | async"
  [class.has-background-color]="viewModel.rowBackgroundColor$ | async | getArrayElementAtIndex: 1"
  [ngStyle]="viewModel.productWrappingStyle$ | async"
  [style.background-color]="viewModel.rowBackgroundColor$ | async | getArrayElementAtIndex: 1"
  [style.color]="viewModel.forcedRowTextColorToDisplay$ | async"
  [style.text-decoration-color]="viewModel.forcedRowTextColor$ | async"
  [style.zoom]="viewModel.forcedRowZoom$ | async"
  [style.border-color]="viewModel.sectionBorderColor$ | async">
  <ng-container
    *rxFor="let colVm of viewModel.columnViewModels$; trackBy: trackByColViewModelId; strategy: 'immediate'">
    <!--    Lay out product title column    -->
    <app-column-name
      *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.ProductTitle"
      [calculationMode]="calculationMode"
      [menu]="menu"
      [section]="section"
      [colVm]="colVm"
      [rowVm]="rowViewModel"
      [locConfig]="locationConfig"
      [locationId]="locationId"
      [productStylingVm]="viewModel"
      [reset]="reset">
    </app-column-name>
    <!--    Lay out non-title columns    -->
    <div
      *ngIf="
        !calculationMode &&
        colVm?.columnType !== Types.SectionColumnProductInfoType.ProductTitle &&
        (menu | shouldInflateColumnForRow: section : colVm : rowViewModel)
      "
      [hidden]="menu | shouldHideColumnContent: section : colVm : rowViewModel"
      [class.force-bold-style]="viewModel | forceColumnBoldStyling: colVm : locationConfig | async"
      [class.force-italic-style]="viewModel | forceColumnItalicStyling: colVm : locationConfig | async"
      [class.default-background-color]="!(viewModel | columnBackgroundColor: colVm | async)"
      [class.product-column-VariantPrice]="colVm.columnType === Types.SectionColumnProductInfoType.VariantPrice"
      [class.product-column-Size]="colVm.columnType === Types.SectionColumnProductInfoType.Size"
      [class.product-column-StrainType]="colVm.columnType === Types.SectionColumnProductInfoType.StrainType"
      [class.product-column-THCAndCBD]="colVm.columnType === Types.SectionColumnCannabinoidType.THCAndCBD"
      class="product-column"
      [style.background-color]="viewModel | columnBackgroundColor: colVm | async"
      [style.color]="viewModel | forcedColumnTextColor: colVm : locationConfig | async"
      [style.text-decoration-color]="viewModel | forcedColumnTextColor: colVm : locationConfig | async"
      [style.zoom]="viewModel | forcedColumnZoom: colVm | async"
      [style.width.%]="colVm | columnWidth: false : 1">
      <div
        *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.Size"
        class="product-column-general product-size"
        [style.visibility]="section?.collapseContent || rowViewModel.collapseContent ? 'collapse' : ''"
        [style.text-decoration]="viewModel | forcedColumnTextDecoration: colVm : locationConfig | async"
        [style.margin-right]="menu | productMenuBasicMethod: 'getSectionBorderMarginSize'">
        {{ rowViewModel | sectionRowViewModelBasicMethod: 'getSize' }}
      </div>
      <div
        *ngIf="colVm.columnType === Types.SectionColumnCannabinoidType.THCAndCBD"
        [style.visibility]="section?.collapseContent || rowViewModel.collapseContent ? 'collapse' : ''"
        [style.text-decoration]="viewModel | forcedColumnTextDecoration: colVm : locationConfig | async"
        [style.margin-right]="menu | productMenuBasicMethod: 'getSectionBorderMarginSize'"
        [style.white-space]="'pre-wrap'"
        class="product-column-general product-cannabinoid product-thc-and-cbd">
        {{ rowViewModel | sectionRowViewModelBasicMethod: 'getThcAndCbd' }}
      </div>
      <app-column-primary-price
        *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.VariantPrice"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [rowVm]="rowViewModel"
        [locConfig]="locationConfig"
        [locationId]="locationId"
        [productStylingVm]="viewModel">
      </app-column-primary-price>
    </div>
  </ng-container>
</div>
