import { Component } from '@angular/core';
import { ProductMenuFooterComponent } from '../product-menu-footer.component';

@Component({
  selector: 'app-double-dutch-footer',
  templateUrl: './double-dutch-footer.component.html',
  styleUrls: ['./double-dutch-footer.component.scss']
})
export class DoubleDutchFooterComponent extends ProductMenuFooterComponent {
}
