<div
  *ngIf="
    (columnLabel || !(labelComponentInterface | getShutOffLabelForLabelComponent)) &&
    (viewModel?.labelText$ | async) !== ''
  "
  class="product-label product-flag-label"
  [style.display]="'flex'"
  [style.flex-direction]="'row'"
  [style.white-space]="'nowrap'">
  <!-- colors included within style signals -->
  <div
    class="triangles-for-flag"
    fxLayout="column"
    [style.height.px]="viewModel.flagHeight$ | async"
    [style.width.px]="viewModel.triangleWidth$ | async">
    <div
      class="triangle-top"
      [style.border-top]="viewModel.flagTriangleHeight$ | async"
      [style.border-left]="viewModel.flagTriangleWidth$ | async"></div>
    <div
      class="triangle-bottom"
      [style.border-bottom]="viewModel.flagTriangleHeight$ | async"
      [style.border-left]="viewModel.flagTriangleWidth$ | async"></div>
  </div>

  <div
    #flagBody
    class="flag-body"
    [style.background-color]="viewModel?.labelBackgroundColor$ | async"
    [style.color]="viewModel?.labelTextColor$ | async"
    appFitText
    [parentContainer]="fitTextParentContainer"
    [percentageOfParent]="fitTextPercentageOfParent"
    [fitTextDisabled]="fitTextDisabled"
    [lineHeightMultiplier]="1.4">
    {{ viewModel?.labelText$ | async }}
  </div>
</div>
