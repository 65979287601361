<div class="pricing-container" [style.color]="viewModel.bodyTextColor$ | async">
  <div class="pre-tax">
    <div class="pre-tax-text-container">
      <div>PRE</div>
      <div>TAX</div>
    </div>
    <app-column-primary-price
      [menu]="viewModel.cardStackMenu$ | async"
      [section]="viewModel.cardStack$ | async"
      [colVm]="colVm"
      [rowVm]="viewModel.cardData$ | async"
      [locConfig]="viewModel.locationConfig$ | async"
      [locationId]="viewModel.locationId$ | async"
      [productStylingVm]="viewModel"
      [overridePriceStream]="Types.LocationPriceStream.Default">
    </app-column-primary-price>
  </div>
  <div class="tax-in">
    <div class="tax-in-text-container">
      <div>TAX</div>
      <div>IN</div>
    </div>
    <app-column-primary-price
      [menu]="viewModel.cardStackMenu$ | async"
      [section]="viewModel.cardStack$ | async"
      [colVm]="colVm"
      [rowVm]="viewModel.cardData$ | async"
      [locConfig]="viewModel.locationConfig$ | async"
      [locationId]="viewModel.locationId$ | async"
      [productStylingVm]="viewModel"
      [overridePriceStream]="viewModel.priceStreamOverride$ | async">
    </app-column-primary-price>
  </div>
</div>
