import { BehaviorSubject } from 'rxjs';
import { LoadingSpinnerSize } from './loading-spinner-size.enum';

export class LoadingOptions {

  public isLoading: boolean = false;
  public fullscreen: boolean = false;
  public determinateLoading: boolean = false;
  public showLoadingText: boolean = false;
  public progress = 0;
  public loadingText: string;
  public backgroundColor: string;
  public spinnerColor: string;
  public color: string;
  public topMarginRem: number = 0;
  public cornerRadiusRem: number = 0;
  public spinnerSize: LoadingSpinnerSize = LoadingSpinnerSize.Default;
  public zIndex = 99;

  private awaitingRequests: BehaviorSubject<string[]> = new BehaviorSubject([]);

  static default(determinate: boolean = false, fullscreen: boolean = false): LoadingOptions {
    const opt = new LoadingOptions();
    opt.backgroundColor = 'rgba(222, 222, 222, 0.85)';
    opt.spinnerColor = '#2C4058';
    opt.color = '#2C4058';
    opt.determinateLoading = determinate;
    opt.showLoadingText = true;
    opt.fullscreen = fullscreen;
    opt.initialize();
    return opt;
  }

  static defaultInButton(): LoadingOptions {
    const opt = new LoadingOptions();
    opt.backgroundColor = 'transparent';
    opt.spinnerColor = '#FFF';
    opt.color = '#FFF';
    opt.spinnerSize = LoadingSpinnerSize.Small;
    opt.initialize();
    return opt;
  }

  initialize() {
    this.awaitingRequests.subscribe((reqs) => {
      this.isLoading = reqs.length > 0;
      if (reqs.length > 0) {
        this.loadingText = reqs[reqs.length - 1];
      } else {
        this.loadingText = '';
      }
    });
  }

  containsRequest(mess: string): boolean {
    const currReqs = this.awaitingRequests.getValue();
    const existingIndex = currReqs.indexOf(mess);
    return existingIndex > -1;
  }

  addRequest(mess: string) {
    const currReqs = this.awaitingRequests.getValue();
    const existingIndex = currReqs.indexOf(mess);
    if (existingIndex === -1) {
      currReqs.push(mess);
      this.awaitingRequests.next(currReqs);
    }
  }

  removeRequest(mess: string) {
    const currReqs = this.awaitingRequests.getValue();
    const removeIndex = currReqs.indexOf(mess);
    if (removeIndex > -1) {
      currReqs.splice(removeIndex, 1);
    }
    this.awaitingRequests.next(currReqs);
  }

  connectToRequestQueue(): BehaviorSubject<string[]> {
    return this.awaitingRequests;
  }

}
