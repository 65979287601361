/* src/app/modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-plain-jane/menu-header-plain-jane.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.special {
  border-spacing: 0;
  border-collapse: collapse;
}
.special .cell {
  font-family: poppins, sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  border: 2px solid black;
  padding: 2px 2rem;
}
html.print-menu .special .cell {
  font-size: 1rem !important;
}
/*# sourceMappingURL=menu-header-plain-jane.component.css.map */
