import { Deserializable } from './deserializable';
import { SystemLabelKey } from '../enum/dto/system-label-key.enum';
import { LowStockSystemLabel } from '../menu/labels/low-stock-system-label';
import { RestockSystemLabel } from '../menu/labels/restock-system-label';
import { SaleSystemLabel } from '../menu/labels/sale-system-label';
import { NewSystemLabel } from '../menu/labels/new-system-label';
import { Label } from '../menu/labels/label';
import { FeaturedSystemLabel } from '../menu/labels/featured-system-label';
import { Type } from '@angular/core';

export class DeserializeLabelHelper {

  static getPolymorphicLabelObject<T extends Deserializable>(label: any): T {
    const createPolymorphicObject = (ObjectType: Type<any>): T => {
      return Object.assign(Object.create(ObjectType.prototype), label);
    };
    switch (label?.id) {
      case SystemLabelKey.Featured:  return createPolymorphicObject(FeaturedSystemLabel);
      case SystemLabelKey.LowStock:  return createPolymorphicObject(LowStockSystemLabel);
      case SystemLabelKey.Restocked: return createPolymorphicObject(RestockSystemLabel);
      case SystemLabelKey.Sale:      return createPolymorphicObject(SaleSystemLabel);
      case SystemLabelKey.New:       return createPolymorphicObject(NewSystemLabel);
      default:                       return createPolymorphicObject(Label);
    }
  }

}
