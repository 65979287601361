import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { HeaderColumnComponent } from '../header-column.component';

@Component({
  selector: 'app-header-column-strain-type',
  templateUrl: './header-column-strain-type.component.html',
  styleUrls: ['./header-column-strain-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class HeaderColumnStrainTypeComponent extends HeaderColumnComponent {
}
