import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RenderContextComponent } from '../../../../../models/base/render-context-component';
import { RenderPrintCardBackgroundViewModel } from './render-print-card-background-view-model';
import { CardData } from '../../../../../models/print-cards/card-data';

@Component({
  selector: 'app-render-print-card-background',
  templateUrl: './render-print-card-background.component.html',
  styleUrls: ['./render-print-card-background.component.scss'],
  providers: [RenderPrintCardBackgroundViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenderPrintCardBackgroundComponent extends RenderContextComponent implements OnChanges {

  constructor(
    public viewModel: RenderPrintCardBackgroundViewModel
  ) {
    super();
  }

  @Input() preview: boolean;
  @Input() parentHeight: number;
  @Input() parentWidth: number;
  @Input() cardData: CardData;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.parentHeight) this.viewModel.connectToParentHeight(this.parentHeight);
    if (changes.parentWidth) this.viewModel.connectToParentWidth(this.parentWidth);
    if (changes.preview) this.viewModel.connectToPreview(this.preview);
    if (changes.cardData) this.viewModel.connectToCardData(this.cardData);
  }

  setupBindings() {
  }

  setupViews() {
  }

}
