import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RenderContextComponent } from '../../../../../../../models/base/render-context-component';
import { Menu } from '../../../../../../../models/menu/menu';

@Component({ selector: 'app-menu-footer', template: '' })
export abstract class MenuFooterComponent extends RenderContextComponent implements OnChanges {

  @Input() menu: Menu;
  @Input() reset: boolean;

  setupBindings() {
  }

  setupViews() {
  }

  /* We need this here, so that in the inflator we can pass down any changes  */
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(changes: SimpleChanges) {
  }

}
