<div
  #headerContainer
  class="section-header-container"
  [ngStyle]="{ 'background-image': headerBackgroundAsset$ | async }"
  [style.background-color]="
    (menu | productMenuBasicMethod: 'getShowSectionHeaderColorAsIcon')
      ? null
      : section?.metadata?.productsHeaderBackgroundColor
  "
  [style.background-size]="'cover'"
  [style.border-bottom-color]="menu | productMenuBasicMethod: 'getSectionTitleUnderlineColor' : section"
  [style.border-color]="menu | productMenuBasicMethod: 'getSectionTitleBorderColor' : section"
  [style.zoom]="menu?.menuOptions?.productZoom || 1.0"
  [class.section-header-bottom-border]="menu | productMenuBasicMethod: 'getSectionHeaderUnderlined'">
  <ng-container *ngFor="let colVm of columnVMs; trackBy: trackByColumnViewModelId">
    <app-header-column-wrapper
      [calculationMode]="calculationMode"
      [menu]="menu"
      [section]="section"
      [colVm]="colVm"
      [sectionRowViewModels]="sectionRowViewModels">
      <app-header-column-product-title
        *ngIf="colVm?.columnType === Types.SectionColumnProductInfoType.ProductTitle"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm">
      </app-header-column-product-title>
      <app-header-column-variant-price
        *ngIf="colVm?.columnType === Types.SectionColumnProductInfoType.VariantPrice"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm">
      </app-header-column-variant-price>
      <app-header-column-variant-secondary-price
        *ngIf="colVm?.columnType === Types.SectionColumnProductInfoType.VariantSecondaryPrice"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm">
      </app-header-column-variant-secondary-price>
      <app-header-column-quantity-in-stock
        *ngIf="colVm?.columnType === Types.SectionColumnProductInfoType.Stock"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm">
      </app-header-column-quantity-in-stock>
      <app-header-column-quantity-and-size
        *ngIf="colVm?.columnType === Types.SectionColumnProductInfoType.QuantityAndSize"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm">
      </app-header-column-quantity-and-size>
      <app-header-column-cannabinoid
        *ngIf="colVm?.columnType | isCannabinoidColumn as cannabinoidColumnType"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [sectionRowViewModels]="sectionRowViewModels"
        [cannabinoidColumnType]="cannabinoidColumnType">
      </app-header-column-cannabinoid>
      <app-header-column-terpene
        *ngIf="colVm?.columnType | isTerpeneColumn as terpeneColumnType"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [sectionRowViewModels]="sectionRowViewModels"
        [terpeneColumnType]="terpeneColumnType">
      </app-header-column-terpene>
      <app-header-column-quantity
        *ngIf="colVm?.columnType === Types.SectionColumnProductInfoType.Quantity"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm">
      </app-header-column-quantity>
      <app-header-column-size
        *ngIf="colVm?.columnType === Types.SectionColumnProductInfoType.Size"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm">
      </app-header-column-size>
      <app-header-column-strain-type
        *ngIf="colVm?.columnType === Types.SectionColumnProductInfoType.StrainType"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm">
      </app-header-column-strain-type>
      <app-header-column-asset
        *ngIf="colVm?.columnType === Types.SectionColumnProductInfoType.Asset"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm">
      </app-header-column-asset>
      <app-header-column-producer
        *ngIf="colVm?.columnType === Types.SectionColumnProductInfoType.Producer"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm">
      </app-header-column-producer>
      <app-header-column-badge
        *ngIf="colVm?.columnType === Types.SectionColumnProductInfoType.Badge"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm">
      </app-header-column-badge>
      <app-header-column-brand
        *ngIf="colVm?.columnType === Types.SectionColumnProductInfoType.Brand"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm">
      </app-header-column-brand>
      <app-header-column-label
        *ngIf="colVm?.columnType === Types.SectionColumnProductInfoType.Label"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm">
      </app-header-column-label>
    </app-header-column-wrapper>
  </ng-container>
</div>
