<div
  class="print-card-background-container"
  [style.height.px]="viewModel.contentHeight$ | async"
  [style.width.px]="viewModel.contentWidth$ | async"
  [style.transform]="viewModel.transform$ | async"
  [style.transform-origin]="viewModel.transformOrigin$ | async">
  <app-print-card-background-inflator [preview]="preview" [cardData]="cardData"></app-print-card-background-inflator>

  <!-- blur overlay -->
  <div
    *ngIf="
      !!this.cardData?.menu?.hydratedTheme?.themeFeatures?.blurredBackground &&
      !!this.cardData?.menu?.menuOptions?.blurredBackground
    "
    class="print-card-background-blur"
    style="backdrop-filter: blur(10px)"></div>

  <!-- color overlay -->
  <!-- this is here in case for whatever reason, the menu signals are used instead of the section signals -->
  <!-- if card implements print card background or   -->
  <div
    class="print-card-background-color-overlay"
    [class]="this.cardData?.menu | printCardMenuBasicMethod : 'getBackgroundAssetWrapperClass'"
    [style.background-color]="
      !!this.cardData?.menu?.hydratedTheme?.themeFeatures?.bodyBackgroundColor
        ? this.cardData?.menu?.menuOptions?.bodyBackgroundColor
        : ''
    "
    [style.opacity]="
      (cardData?.menu | printCardMenuBasicMethod : 'getOpacityEnabled')
        ? cardData?.menu?.menuOptions?.backgroundOpacity || '0'
        : '0'
    "
    [style.border-radius]="cardData?.menu | printCardMenuBasicMethod : 'getColorBackgroundAssetBorderRadius'"></div>
</div>
