/* src/app/modules/print-cards/components/print-cards/backgrounds/business-cards-2x3/plain-jane-business-card-stack-background/plain-jane-business-card-stack-background.component.scss */
:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  padding: 0.625rem;
  box-sizing: border-box;
}
:host .main-content {
  flex: 1;
}
:host .main-content .print-card-background-asset {
  height: 100%;
  width: 100%;
}
/*# sourceMappingURL=plain-jane-business-card-stack-background.component.css.map */
