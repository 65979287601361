import { Pipe, PipeTransform } from '@angular/core';
import type { LocationPriceStream } from '../../../models/enum/shared/location-price-stream';
import type { SectionWithProducts } from '../../../models/menu/section/section-with-products';
import type { SectionRowViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { SectionColumnViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import type { ProductMenu } from '../../../models/menu/product-menu';

@Pipe({
  name: 'getPriceInteger'
})
export class GetPriceIntegerPipe implements PipeTransform {

  transform(
    menu: ProductMenu,
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel,
    checkForPricingChanges: boolean
  ): string {
    return menu?.getPriceInteger(priceStream, locationId, s, rowVM, colVm);
  }

}
