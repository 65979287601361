import { TimeDuration } from './time-duration';
import { LocalizedDateRangeUnixSeconds } from './localized-date-range-unix-seconds';
import moment from 'moment-timezone';
import { exists } from '../../functions/exists';

/**
 * Time windows are in unix seconds.
 */
export class TimeDurationUnixSeconds extends TimeDuration {

  public dateTimeWindows: LocalizedDateRangeUnixSeconds[];

  deserializeLocalizedDateRange() {
    this.dateTimeWindows = window.injector.Deserialize
      .arrayOf(LocalizedDateRangeUnixSeconds, this.dateTimeWindows || []);
  }

  protected getCurrentDateTimeZoned(timezone?: string): moment.Moment {
    if (exists(this.testTimestampAt)) {
      return exists(this.testingTimezone)
        ? moment.utc(this.testTimestampAt * 1000).tz(this.testingTimezone)
        : moment.unix(this.testTimestampAt);
    }
    return exists(timezone)
      ? moment.utc().tz(timezone) // ignore local time and use provided timezone
      : moment();
  }

}
