<div
  #headerContainer
  [ngClass]="menu | productMenuBasicMethod : 'getHeaderWrapperClass'"
  [ngStyle]="{
    color: menu?.hydratedTheme?.themeFeatures?.titleTextColor ? menu?.menuOptions?.titleTextColor : ''
  }">
  <div class="header-container">
    <div class="menu-title" [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
      {{ menu?.configurationTitle }}
    </div>
    <div
      class="flex-container-row flex-content-end flex-grow menu-date"
      [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
      Available in Store | {{ date$ | async }}
    </div>
  </div>
</div>
