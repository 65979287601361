import { AssetLibraryType } from '../../enum/dto/asset-library-type.enum';
import { PrintCardMenu } from './print-card-menu';

export class NycdPrintCardMenu extends PrintCardMenu {

  getFontFaceList(): string[] {
    return ['inter', 'poppins'];
  }

  shouldFetchVariantAssets(): boolean {
    return true;
  }

  override variantAssetTypeSortOrder(): string[] {
    return [
      AssetLibraryType.PrimaryBrand,
      AssetLibraryType.AlternateBrand,
      AssetLibraryType.Brand
    ];
  }

}
