import { ProductSection } from '../product-section';
import { PrintCardSize } from '../../../enum/dto/print-card-size.enum';
import { PaperSize } from '../../../shared/paper-size';
import { DefaultPaperSize } from '../../../enum/shared/default-paper-size.enum';
import type { PrintCardBackgroundComponent } from '../../../../modules/print-cards/components/print-cards/backgrounds/print-card-background.component';
import type { PrintCardContentComponent } from '../../../../modules/print-cards/components/print-cards/card-content/print-card-content.component';
import { Type } from '@angular/core';
import { CutLineMode } from '../../../enum/shared/cut-line-mode.enum';
import { Orientation } from '../../../enum/dto/orientation.enum';
import { SectionColumnConfigDataValue } from '../section-column-config';
import { CardData } from '../../../print-cards/card-data';

export abstract class CardStack extends ProductSection {

  static readonly PIXELS_PER_INCH = 96;
  public templateSection?: CardStack;
  public singleCardPerPage: boolean; // not from API - set by menu on deserialize
  public paperSize: DefaultPaperSize; // not from API - set by menu on deserialize
  public cardSize: PrintCardSize; // not from API - set by menu on deserialize
  public cutLineMode: CutLineMode; // not from API - set within onDeserialize
  public orientation: Orientation; // not from API - set by menu on deserialize
  public themeId: string; // not from API - set by menu on deserialize

  abstract getCardBackgroundComponent(cardData: CardData): Type<PrintCardBackgroundComponent> | null;
  abstract getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null;
  /** IMPORTANT: It was decided that card width is always specified as if the card is in the landscape orientation. */
  abstract getCardWidthInInches(): number;
  /** IMPORTANT: It was decided that card height is always specified as if the card is in the landscape orientation. */
  abstract getCardHeightInInches(): number;
  abstract nCardsPerPage(paperSize: PaperSize, isOnPerforatedPaper: boolean): number;
  abstract nColumns(): number;
  abstract nRows(): number;
  abstract cardOrientationOnPortraitPage(): Orientation;

  onDeserialize() {
    super.onDeserialize();
    this.cutLineMode = CutLineMode.Extended;
  }

  public customTopPaperMarginInInches(): number | null {
    return null;
  }

  public getCardWidthInPixels(): number {
    const widthInInches = this.cardOrientationOnPortraitPage() === Orientation.Portrait
      ? this.getCardHeightInInches()
      : this.getCardWidthInInches();
    return widthInInches * CardStack.PIXELS_PER_INCH;
  }

  public getCardHeightInPixels(): number {
    const heightInInches = this.cardOrientationOnPortraitPage() === Orientation.Portrait
      ? this.getCardWidthInInches()
      : this.getCardHeightInInches();
    return heightInInches * CardStack.PIXELS_PER_INCH;
  }

  public isOnRegularPaper(): boolean {
    return this.paperSize?.toLowerCase()?.includes('custom');
  }

  public isOnPerforatedPaper(): boolean {
    return this.paperSize?.toLowerCase()?.includes('perforated');
  }

  public isOnLaserLabelPaper(): boolean {
    return this.paperSize?.toLowerCase()?.includes('label');
  }

  public nonPerforatedBleedBorderInInches(): number {
    return 1 / 16;
  }

  public getNumberOfVisibleRowsOnPage(nCardsOnPage: number): number {
    return this.singleCardPerPage ? 1 : this.nRows();
  }

  public getNumberOfVisibleColumnsOnPage(nCardsOnPage: number): number {
    return this.singleCardPerPage ? 1 : this.nColumns();
  }

  public priceType(): SectionColumnConfigDataValue {
    return SectionColumnConfigDataValue.OriginalAndSalePrice;
  }

  public getLeftCutLineText(cardData: CardData): string | null {
    return null;
  }

  public extendCutLineIntoContentAreaByNPx(): number {
    return 32;
  }

  public cardPreviewHeightInPx(): number {
    const height = this.getCardHeightInPixels() || 0;
    const width = this.getCardWidthInPixels() || 0;
    return this.orientation === Orientation.Portrait
      ? Math.max(height, width)
      : Math.min(height, width);
  }

  public cardPreviewWidthInPx(): number {
    const height = this.getCardHeightInPixels() || 0;
    const width = this.getCardWidthInPixels() || 0;
    return this.orientation === Orientation.Portrait
      ? Math.min(height, width)
      : Math.max(height, width);
  }

  withContentBleed(preview: boolean, multiplier: number = 1): number {
    if (preview) return 0;
    if (this.isOnRegularPaper()) {
      const bleedInInches = this.nonPerforatedBleedBorderInInches();
      return bleedInInches * CardStack.PIXELS_PER_INCH * multiplier;
    }
    return 0;
  }

}
