import { Type } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { ColumnWidth } from '../../enum/shared/column-width.enum';
import { ColWidth } from '../../shared/col-width';
import { SectionColumnCannabinoidType, SectionColumnProductInfoType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { Section } from '../section/section';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { MenuSectionHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section-header/menu-section-header.component';
import { PlantlifeSectionHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section-header/plantlife-section-header/plantlife-section-header.component';
import { PrintMenu } from '../print-menu';
import { PrintHeaderLayoutType } from '../../enum/shared/print-header-layout-type.enum';
import { ProductMenuFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/product-menu-footer.component';
import { PlantLifeFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/plant-life-footer/plant-life-footer.component';
import { PrintFooterLayoutType } from '../../enum/shared/print-footer-layout-type.enum';
import { EmptySection } from '../section/empty-section';
import { PlantlifeEmptySection } from '../section/plantlife-empty-section';
import { MenuHeaderPlantlifeComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-plantlife/menu-header-plantlife/menu-header-plantlife.component';
import { SectionType } from '../../enum/dto/section-type.enum';

export class PlantlifeMenu extends PrintMenu {

  protected override deserializeSections() {
    this.sections?.forEach(section => {
      const isProductSection = section?.sectionType === SectionType.Product;
      if (isProductSection) section.sectionType = SectionType.PlantlifeProduct;
    });
    this.sections = window?.injector?.Deserialize?.arrayOf(Section, this.sections) ?? [];
    this.translateVariantClassificationsIntoHybridIndicaSativa(this.sections);
  }

  protected override deserializeTemplateSections() {
    this.templateSections?.forEach(templateSection => {
      const isProductSection = templateSection?.sectionType === SectionType.Product;
      if (isProductSection) templateSection.sectionType = SectionType.PlantlifeProduct;
    });
    this.templateSections = window?.injector?.Deserialize?.arrayOf(Section, this.templateSections) ?? [];
    this.translateVariantClassificationsIntoHybridIndicaSativa(this.templateSections);
  }

  override getEmptySectionType(): Type<EmptySection> {
    return PlantlifeEmptySection;
  }

  getPageTopMargin(): string {
    return '0.25in';
  }

  getPageBottomMargin(): string {
    return '0.25in';
  }

  getPageLeftMargin(): string {
    return '0.25in';
  }

  getPageRightMargin(): string {
    return '0.25in';
  }

  getTitleSectionTopMargin(): string {
    return '0';
  }

  getTitleSectionBottomMargin(): string {
    return '0';
  }

  getThemeClass(): string {
    return 'plantlife-theme';
  }

  getDisplayPadding(): string {
    return '0rem';
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  shutOffBackgroundAsset(): boolean {
    return true;
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderPlantlifeComponent;
  }

  getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.AllPages;
  }

  getSectionsHeaderType(): Type<MenuSectionHeaderComponent> {
    return PlantlifeSectionHeaderComponent;
  }

  getPrintFooterLayoutType(): PrintFooterLayoutType {
    return this.menuOptions?.printFooterLayout || PrintFooterLayoutType.AllPagesFixed;
  }

  getFooterType(): Type<ProductMenuFooterComponent> {
    return PlantLifeFooterComponent;
  }

  getColWidths(sectionRowViewModel: SectionRowViewModel, stdPercentage: number = ColumnWidth.Default): ColWidth[] {
    const colWidths = super.getColWidths(sectionRowViewModel, stdPercentage);
    const strainClass = new ColWidth(SectionColumnProductInfoType.StrainType, -1);
    const cbdAndThc = new ColWidth(SectionColumnCannabinoidType.THCAndCBD, 20);
    const price = new ColWidth(SectionColumnProductInfoType.VariantPrice, 15);
    const size = new ColWidth(SectionColumnProductInfoType.Size, 13);
    colWidths.splice(colWidths.findIndex(it => it.type === SectionColumnProductInfoType.StrainType), 1, strainClass);
    colWidths.splice(colWidths.findIndex(it => it.type === SectionColumnCannabinoidType.THCAndCBD), 1, cbdAndThc);
    colWidths.splice(colWidths.findIndex(it => it.type === SectionColumnProductInfoType.VariantPrice), 1, price);
    colWidths.splice(colWidths.findIndex(it => it.type === SectionColumnProductInfoType.Size), 1, size);
    return colWidths;
  }

  override getThemeSpecifiedColumnViewModels(
    sectionRowViewModels: SectionRowViewModel[],
    rowViewModel: SectionRowViewModel,
    widths: ColWidth[] = this.getColWidths(rowViewModel)
  ): SectionColumnViewModel[] {
    const colVMs = [];
    const layoutType = rowViewModel?.getLayoutType();
    const productTitle = new SectionColumnViewModel(layoutType, null);
    productTitle.columnType = SectionColumnProductInfoType.ProductTitle;
    const size = new SectionColumnViewModel(layoutType, null);
    size.columnType = SectionColumnProductInfoType.Size;
    size.widthPercentage = widths.find(it => it.type === SectionColumnProductInfoType.Size).widthPercentage;
    const strainType = new SectionColumnViewModel(layoutType, null);
    strainType.columnType = SectionColumnProductInfoType.StrainType;
    strainType.widthPercentage = widths.find(it => it.type === SectionColumnProductInfoType.StrainType).widthPercentage;
    const thcAndCbd = new SectionColumnViewModel(layoutType, null);
    thcAndCbd.columnType = SectionColumnCannabinoidType.THCAndCBD;
    thcAndCbd.widthPercentage = widths.find(it => it.type === SectionColumnCannabinoidType.THCAndCBD).widthPercentage;
    const priceCol = new SectionColumnViewModel(layoutType, null);
    priceCol.columnType = SectionColumnProductInfoType.VariantPrice;
    priceCol.widthPercentage = widths.find(it => it.type === SectionColumnProductInfoType.VariantPrice).widthPercentage;
    colVMs.push(productTitle, strainType, size, thcAndCbd, priceCol);
    return colVMs;
  }

  getShowStrainTypes(): boolean {
    return false;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return false;
  }

  getAllowedBadgeCount(): number {
    return 2;
  }

  getShowBadgesInline(): boolean {
    return true;
  }

  getShowBadgesInlineOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getShouldInflateColumnHeaderForRow(s: Section, col: SectionColumnViewModel, rows: SectionRowViewModel[]): boolean {
    return (col.columnType !== SectionColumnProductInfoType.ProductTitle)
        && (col.columnType !== SectionColumnProductInfoType.Size);
  }

  getShouldInflateColumnForRow(s: Section, col: SectionColumnViewModel, row: SectionRowViewModel): boolean {
    return col.columnType !== SectionColumnProductInfoType.StrainType;
  }

  getFontFaceList(): string[] {
    return ['bebas-neue-pro'];
  }

}
