import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/menu';
import { exists } from '../../../functions/exists';

@Pipe({
  name: 'shouldShowDefaultImage'
})
export class ShouldShowDefaultImagePipe implements PipeTransform {

  transform(menu: Menu, defaultImageUrl: string): boolean {
    const shutOffBackgroundAsset = menu?.shutOffBackgroundAsset();
    const changeBackgroundEnabled = exists(menu?.hydratedTheme?.themeFeatures?.backgroundMedia);
    const menuHasBackgroundImage = exists(menu?.backgroundImage);
    const hasDefaultImageBackground = exists(defaultImageUrl);
    return changeBackgroundEnabled
      ? !shutOffBackgroundAsset && !menuHasBackgroundImage && hasDefaultImageBackground
      : !shutOffBackgroundAsset && hasDefaultImageBackground;
  }

}
