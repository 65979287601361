import { environment } from '../../environments/environment';
import { Menu } from '../models/menu/menu';

export class Endpoints {

  // Session

  static encodeParam(s: string): string {
    return encodeURIComponent(s);
  }

  // Template and Collections

  static GetMenuTemplate(locationId: number, menuTemplateId: string): string {
    return environment.templateServiceUrl
      + `/get-template`
      + `?LocationId=${locationId}`
      + `&TemplateId=${menuTemplateId}`;
  }

  static GetPartialMenuTemplate(locationId: number, menuTemplateId: string, variantIds: string[]): string {
    return environment.templateServiceUrl
      + `/get-partial-template`
      + `?LocationId=${locationId}`
      + `&TemplateId=${menuTemplateId}`
      + `&VariantIds=${variantIds.sort().join(',')}`;
  }

  static GetTemplateCollection(locationId: number, templateId: string): string {
    return environment.templateServiceUrl
      + `/get-template-collection`
      + `?LocationId=${locationId}`
      + `&Id=${templateId}`
      + `&IncludeTemplatedMenus=true`;
  }

  // Menu

  static GetMenuSection(menuId, sectionId: string): string {
    return environment.menuServiceUrl
      + `/get-configuration-section`
      + `?MenuId=${menuId}`
      + `&SectionId=${sectionId}`;
  }

  static GetMenu(locationId: number, menuId: string): string {
    return environment.menuServiceUrl
      + `/get-configuration`
      + `?LocationId=${locationId}`
      + `&MenuId=${menuId}`;
  }

  static GetPartialMenu(locationId: number, menuId: string, variantIds: string[]): string {
    return environment.menuServiceUrl
      + `/get-partial-menu`
      + `?LocationId=${locationId}`
      + `&MenuId=${menuId}`
      + `&VariantIds=${variantIds.sort().join(',')}`;
  }

  static GetMenuAssets(menu: Menu): string {
    const id = menu?.getOriginalMenuId();
    const paramName = menu?.isTemplate() ? 'TemplateId' : 'MenuId';
    return environment.menuServiceUrl
      + `/get-configuration-assets`
      + `?${paramName}=${id}`;
  }

  static GetMenuThemes(): string {
    return environment.menuServiceUrl + `/get-configuration-themes`;
  }

  static GetLabels(locationId: number): string {
    return environment.mainServiceUrl
      + `/get-labels`
      + `?LocationId=${locationId}`;
  }

  // Display Attributes

  static GetDisplayAttributes(): string {
    return environment.menuServiceUrl + `/get-display-attributes`;
  }

  // Company

  static GetCompany(): string {
    return environment.mainServiceUrl + `/get-company`;
  }

  static GetCompanyConfiguration(companyId: number) {
    return environment.mainServiceUrl
      + `/get-company-configuration`
      + `?CompanyId=${companyId}`;
  }

  // Display

  static GetDisplay(
    displayId: string,
    includeProducts: boolean,
    ignoreLastSession: boolean,
    lastModified?: number
  ): string {
    let apiCall = environment.mainServiceUrl
      + `/get-display`
      + `?DisplayId=${displayId}`
      + `&IncludeProducts=${includeProducts}`
      + `&IgnoreLastSession=${ignoreLastSession}`;
    if (lastModified > 0) apiCall += `&LastModified=${lastModified}`;
    return apiCall;
  }

  // Image

  static GetAsset(id, md5Hash: string): string {
    return environment.imageServiceUrl
      + `/get-asset`
      + `?Id=${id}`
      + `&MD5Hash=${md5Hash}`;
  }

  /**
   * itemTypeSortOrder can be used to tell the backend which AssetLibraryTypes to grab and in what order.
   */
  static GetVariantAssets(ids: string[], itemCount: number, assetTypeSortOrder?: string[]): string {
    let url = environment.imageServiceUrl + `/get-variant-assets?ItemCount=${itemCount}`;
    if (assetTypeSortOrder?.length) url += `&AssetTypeSortOrder=${assetTypeSortOrder.join(',')}`;
    if (ids?.length) url += `&VariantIds=${ids.join(',')}`;
    return url;
  }

  // Location

  static GetLocationConfigurations(locationId: number): string {
    return environment.mainServiceUrl
      + `/get-location-configuration`
      + `?LocationId=${locationId}`;
  }

}

