import { Component, Input } from '@angular/core';
import type { SpotlightMenu } from '../../../../../../../models/menu/spotlight-menu';
import type { SpotlightSectionRowViewModel } from '../menu-section/spotlight-product-section/spotlight-section-row-view-model';
import type { SpotlightSection } from '../../../../../../../models/menu/section/spotlight-section';
import { ProductSectionItemComponent } from '../../../product-menu/building-blocks/menu-item/product-section-item/product-section-item.component';
import { ProductSectionItemViewModel } from '../../../product-menu/building-blocks/menu-item/product-section-item/product-section-item-view-model';

@Component({
  selector: 'app-spotlight-menu-item',
  templateUrl: './spotlight-menu-item.component.html',
  providers: [ProductSectionItemViewModel],
})
export class SpotlightMenuItemComponent extends ProductSectionItemComponent {

  constructor(
    public viewModel: ProductSectionItemViewModel
  ) {
    super(viewModel);
  }

  @Input() menu: SpotlightMenu;
  @Input() section: SpotlightSection;
  @Input() rowViewModel: SpotlightSectionRowViewModel;

}
