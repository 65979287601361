import { Section } from './section';
import { MediaSectionAsset } from '../../image/dto/media-section-asset';
import { exists } from '../../../functions/exists';

export class AssetSection extends Section {

  public override image: MediaSectionAsset;
  public override secondaryImage: MediaSectionAsset;

  protected override deserializeAssets(): void {
    this.image = window.injector.Deserialize.instanceOf(MediaSectionAsset, this.image);
    this.secondaryImage = window.injector.Deserialize?.instanceOf(MediaSectionAsset, this.secondaryImage);
  }

  protected override mergeAdvancedDataPropertiesFromTemplateSection(sectionTemplate: AssetSection) {
    super.mergeAdvancedDataPropertiesFromTemplateSection(sectionTemplate);
    this.mergeImageFromTemplateSection(sectionTemplate);
  }

  protected mergeImageFromTemplateSection(sectionTemplate: AssetSection) {
    if (!this.image && exists(sectionTemplate?.image)) {
      this.image = sectionTemplate.image;
    }
  }

  public hasPrimaryAsset(): boolean {
    return exists(this.image);
  }

  public hasSecondaryAsset(): boolean {
    return exists(this.secondaryImage);
  }

}
