import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'containsDashes'
})
export class ContainsDashesPipe implements PipeTransform {

  transform(value: string): boolean {
    return /-/.test(value);
  }

}
