import { PrintCardMenu } from './print-card-menu';

export class GenericPrintCardMenu extends PrintCardMenu {

  getFontFaceList(): string[] {
    return ['poppins'];
  }

  shouldFetchVariantAssets(): boolean {
    return true;
  }

  shouldStandardizeDominantStrainType(): boolean {
    return false;
  }

}
