import { ProductMenu } from '../product-menu';
import { SectionColumnProductInfoType, SectionColumnType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { Section } from '../section/section';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { exists } from '../../../functions/exists';
import { Type } from '@angular/core';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';

export class NorthernLights extends ProductMenu {

  getThemeClass(): string {
    return 'northern-lights';
  }

  getMenuWrapperClass(): string {
    if (this.isLandscape()) {
      return 'landscape-menu';
    } else {
      return 'vertical-menu';
    }
  }

  getFontFaceList(): string[] {
    return ['poppins', 'Roboto', 'shelby'];
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getHeaderType(): Type<MenuHeaderTitleImageComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getTitleSectionTopMargin(): string {
    return '0';
  }

  getTitleSectionBottomMargin(): string {
    return '0';
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getShowFooter(): boolean {
    return false;
  }

  getShouldInflateColumnHeaderForRow(
    s: Section,
    col: SectionColumnViewModel,
    rows: SectionRowViewModel[]
  ): boolean {
    const badge = col?.columnType === SectionColumnProductInfoType.Badge;
    return !badge || (badge && exists(col?.columnTitle));
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.Badge, 0],
      [SectionColumnProductInfoType.ProductTitle, 1],
      [SectionColumnProductInfoType.Label, 2],
      [SectionColumnProductInfoType.StrainType, 3],
      [SectionColumnProductInfoType.Brand, 4],
      [SectionColumnProductInfoType.QuantityAndSize, 5],
      [SectionColumnProductInfoType.Quantity, 6],
      [SectionColumnProductInfoType.Size, 7],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(8),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(9),
      [SectionColumnProductInfoType.VariantPrice, 10],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 11],
    ]);
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

}
