<div class="card-content">
  <div class="sidebar-spacer"></div>
  <div class="sidebar-section" [style.background-color]="viewModel.cardAccentColor$ | async">
    <div
      *ngIf="cardData | sectionRowViewModelBasicMethod : 'getSidebarText' as text"
      class="sidebar-text"
      appScaleDownText
      [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
      {{ text }}
    </div>
  </div>
  <div class="main-section">
    <div class="card-top">
      <div class="title-brand-label-and-description-container">
        <div class="title-brand-and-label-container">
          <div
            *ngIf="cardData | sectionRowViewModelBasicMethod : 'getRowTitle' as title"
            [style.color]="viewModel.headerTextColor$ | async"
            class="product-card-name">
            {{ title | trimSafely }}
          </div>
          <div class="brand-and-label">
            <div
              *ngIf="cardData | sectionRowViewModelBasicMethod : 'getBrand' as brand"
              class="product-card-brand"
              [style.color]="viewModel.bodyTextColor$ | async">
              {{ brand | trimSafely }}
            </div>
            <app-label-inflator
              class="print-card-label"
              [columnLabel]="false"
              [labelComponentInterface]="cardData"
              [reset]="false">
            </app-label-inflator>
          </div>
        </div>
      </div>
      <div
        *ngIf="(cardData?.menu | printCardMenuBasicMethod : 'getNumberOfBadgesForVariant' : cardData) > 0"
        class="product-card-badge-container">
        <img
          *ngFor="let variantBadge of cardData | printCardBadges"
          appImage
          class="product-card-badge"
          [asset]="variantBadge?.image"
          alt="badge"
          src="" />
      </div>
    </div>

    <div class="card-bottom">
      <div class="properties-and-price-container">
        <div class="properties" [style.color]="viewModel.bodyTextColor$ | async">
          <ng-container *ngIf="!(viewModel.isNonCannabinoidVariant$ | async)">
            <div *ngIf="cardData | sectionRowViewModelBasicMethod : 'getThc' as thc" class="property-container">
              <div class="title">THC</div>
              <div
                class="value"
                appScaleDownTextAndAdjustLines
                [maxFontSizeInPx]="11"
                [scaleDownLinkedKey]="viewModel.variantId$ | async"
                [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
                {{ thc }}
              </div>
            </div>
            <div *ngIf="cardData | sectionRowViewModelBasicMethod : 'getCbd' as cbd" class="property-container">
              <div class="title">CBD</div>
              <div
                class="value"
                appScaleDownTextAndAdjustLines
                [maxFontSizeInPx]="11"
                [scaleDownLinkedKey]="viewModel.variantId$ | async"
                [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
                {{ cbd }}
              </div>
            </div>
            <div
              *ngIf="
                (viewModel.showQuantity$ | async) &&
                (cardData | sectionRowViewModelBasicMethod : 'getQuantityString') as quantity
              "
              class="property-container">
              <div class="title">QTY</div>
              <div
                class="value"
                appScaleDownTextAndAdjustLines
                [maxFontSizeInPx]="11"
                [scaleDownLinkedKey]="viewModel.variantId$ | async"
                [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
                {{ quantity }}
              </div>
            </div>
            <div
              *ngIf="(viewModel.showSize$ | async) && (cardData | sectionRowViewModelBasicMethod : 'getSize') as size"
              class="property-container">
              <div class="title">SIZE</div>
              <div
                class="value"
                appScaleDownTextAndAdjustLines
                [maxFontSizeInPx]="11"
                [scaleDownLinkedKey]="viewModel.variantId$ | async"
                [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
                {{ size }}
              </div>
            </div>
          </ng-container>
        </div>
        <div class="product-card-price" [style.color]="viewModel.bodyTextColor$ | async">
          <app-column-primary-price
            [menu]="viewModel.cardStackMenu$ | async"
            [section]="viewModel.cardStack$ | async"
            [colVm]="viewModel.spoofedColViewModel$ | async"
            [rowVm]="viewModel.cardData$ | async"
            [locConfig]="viewModel.locationConfig$ | async"
            [locationId]="viewModel.locationId$ | async"
            [productStylingVm]="viewModel">
          </app-column-primary-price>
        </div>
      </div>
    </div>
  </div>
</div>
