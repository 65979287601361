import { Asset } from '../../image/dto/asset';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { MediaType } from '../../enum/dto/media-type.enum';
import { BadgeAsset } from '../../image/dto/badge-asset';

export class VariantBadge implements Cachable, UniquelyIdentifiable {

  // Do not change property names, we don't have a smart JSON library, so it won't
  // deserialize properly
  public companyId: number;
  public id: string;
  public name: string;
  public image: BadgeAsset;
  public cachedTime: number;

  constructor(name?: string, url?: string, mediaType?: MediaType) {
    this.name = name;
    const image = new Asset();
    image.setUrlsFromLocalFile(url, mediaType);
    this.image = image;
  }

  static buildCacheKey(id: string): string {
    return `VariantBadge-${id}`;
  }

  public onDeserialize() {
    this.image = window?.injector?.Deserialize?.instanceOf(BadgeAsset, this.image);
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneDay();
  }

  cacheKey(): string {
    return VariantBadge.buildCacheKey(this.id);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.nowInUnixSeconds() > expiresAt;
  }

  getUniqueIdentifier(): string {
    return `${this.companyId}
    -${this.id}
    -${this.name}
    -${this.image?.getUniqueIdentifier()}
    -${this.cachedTime}`;
  }

}
