import { Deserializable } from '../../protocols/deserializable';
import type { SectionType } from '../../enum/dto/section-type.enum';
import { DateUtils } from '../../../utils/date-utils';
import { SectionMetadata } from '../dto/section-metadata';
import { Asset } from '../../image/dto/asset';
import type { Menu } from '../menu';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import type { CompanyConfiguration } from '../../company/dto/company-configuration';
import type { LocationConfiguration } from '../../company/dto/location-configuration';
import { PolymorphicDeserializationKey } from '../../enum/shared/polymorphic-deserialization-key.enum';
import { exists } from '../../../functions/exists';

export class Section implements Deserializable, UniquelyIdentifiable {

  public configurationId: string;
  public id: string;
  public first: boolean;
  public title: string;
  public subTitle: string;
  public priority: number;
  public sectionType: SectionType;
  public masterSectionId: string;
  public metadata: SectionMetadata;
  public customLabelMap: Map<string, string>;
  public autoUpdateGridColumns: boolean;
  // Template
  public templateSectionId?: string;
  public templateSection?: Section; // deleted when combineSectionTemplatesWithSections runs on menu object
  // Cache
  public cachedTime: number;
  // Unique Identifier
  public uniqueIdentifier: string = '';
  // Hydrated
  public image: Asset;
  public secondaryImage: Asset;
  // not returned from server - data added by section overflow calculator
  public pageIndex: number = 0;
  public firstOnPage: boolean = false;
  public lastOnPage: boolean = false;
  public lastSection: boolean = false;
  public beforeTitleSection: boolean = false;
  public beforeAssetSection: boolean = false;
  public afterAssetSection: boolean = false;

  static buildCacheKey(id: string): string {
    return `Section-${id}`;
  }

  /**
   * Don't use methods or types in here. The pointers are added before the data has been completely deserialized,
   * so the data is in a raw JavaScript object format.
   *
   * Section.TemplateSection is scrubbed away by the server to prevent sending the template data structure
   * multiple times within a single data structure tree. This method adds the template section pointer back
   * upon deserialization.
   */
  static setTemplatePointers(menu: any): void {
    menu?.sections?.forEach(section => {
      const templateSec = menu?.template?.templateSections?.find(ts => ts?.id === section?.templateSectionId);
      if (exists(templateSec)) {
        section.templateSection = templateSec;
      }
    });
  }

  public getPolymorphicDeserializationKey(): PolymorphicDeserializationKey {
    return PolymorphicDeserializationKey.Section;
  }

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.metadata = Deserialize?.instanceOf(SectionMetadata, this.metadata);
    this.deserializeTemplateSection();
    this.deserializeAssets();
    this.removePriorityIfNegativeOne();
    this.deserializeCustomLabelMap();
    this.initializeSectionOverflowMetaDataOnDeserialize();
  }

  protected deserializeTemplateSection(): void {
    this.templateSection = window.injector.Deserialize.instanceOf(Section, this.templateSection);
  }

  protected deserializeAssets(): void {
    this.image = window.injector.Deserialize.instanceOf(Asset, this.image);
    this.secondaryImage = window.injector.Deserialize?.instanceOf(Asset, this.secondaryImage);
  }

  protected removePriorityIfNegativeOne(): void {
    if (this.priority === -1) this.priority = undefined;
  }

  private deserializeCustomLabelMap(): void {
    if (this.customLabelMap === null) {
      this.customLabelMap = new Map<string, string>();
    } else {
      this.customLabelMap = window?.injector?.Deserialize?.genericMap(this.customLabelMap);
    }
  }

  private initializeSectionOverflowMetaDataOnDeserialize(): void {
    if (!this.pageIndex) this.pageIndex = 0;
    if (!this.firstOnPage) this.firstOnPage = false;
    if (!this.lastOnPage) this.lastOnPage = false;
    if (!this.lastSection) this.lastSection = false;
    if (!this.beforeTitleSection) this.beforeTitleSection = false;
    if (!this.beforeAssetSection) this.beforeAssetSection = false;
    if (!this.afterAssetSection) this.afterAssetSection = false;
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneDay();
  }

  cacheKey(): string {
    return Section.buildCacheKey(this.id);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.nowInUnixSeconds() > expiresAt;
  }

  resetOverflowMetaData(): void {
    this.pageIndex = 0;
    this.firstOnPage = false;
    this.lastOnPage = false;
    this.lastSection = false;
    this.beforeTitleSection = false;
    this.beforeAssetSection = false;
    this.afterAssetSection = false;
  }

  public setUniqueIdentifier(
    menu: Menu,
    companyConfig: CompanyConfiguration,
    locationConfig: LocationConfiguration
  ): string {
    // We need to immediately set the unique identifier after decoding data from API
    // Once data is laid out, the sections are reorded for overflow, which will always have a different id
    const id = this.id;
    const titleId = this.title ?? '';
    const priorityId = this.priority?.toString() ?? '0';
    const sectionTypeId = this.sectionType ?? '';
    const masterSectionId = this.masterSectionId ?? '';
    const metadataId = this.metadata?.getUniqueIdentifier() ?? '';
    const imageId = this.image?.getUniqueIdentifier() ?? '';
    const secondaryImageId = this.secondaryImage?.getUniqueIdentifier() ?? '';
    const customLabels = [];
    for (const [key, value] of (this.customLabelMap?.entries() || [])) {
      customLabels.push(`${key}=${value}`);
    }
    this.uniqueIdentifier = `
      -${id}
      -${titleId}
      -${priorityId}
      -${sectionTypeId}
      -${masterSectionId}
      -${metadataId}
      -${imageId}
      -${secondaryImageId}
      -${customLabels?.join(',')}
    `;
    return this.uniqueIdentifier;
  }

  combineSectionWithTemplateData(sectionTemplate: Section): void {
    if (!sectionTemplate) return;
    const shortCircuitedProperties = this.getCombineWithTemplateDataShortCircuitedProperties();
    shortCircuitedProperties?.forEach(key => this[key] = this[key] || sectionTemplate?.[key]);
    this.mergeAdvancedDataPropertiesFromTemplateSection(sectionTemplate);
  }

  protected combineCustomLabelMapWithTemplate(sectionTemplate: Section): void {
    const updatedMap = window.injector.Deserialize.genericMap(sectionTemplate?.customLabelMap) ?? new Map();
    this.customLabelMap?.forEach((customLabel, key) => updatedMap?.set(key, customLabel));
    this.customLabelMap = updatedMap;
  }

  protected combineSectionMetadataWithTemplate(sectionTemplate: Section): void {
    const templateDataTakesPrecedenceFor = [
      'width', 'alignment', 'imageAlignment',
      'repeat', 'objectFit',
      'hidePrices', 'cardOpacity'
    ];
    if (!this.autoUpdateGridColumns) {
      // If section uses auto-update grid columns, then use the value from the templated menu (not the template)
      templateDataTakesPrecedenceFor.push('gridColumnNames');
    }
    this.metadata = this.metadata || {} as SectionMetadata;
    Object.keys(sectionTemplate?.metadata || {})?.forEach(property => {
      templateDataTakesPrecedenceFor?.includes(property)
        ? this.metadata[property] = sectionTemplate?.metadata[property] || this.metadata[property]
        : this.metadata[property] = this.metadata[property] || sectionTemplate?.metadata[property];
    });
  }

  protected getCombineWithTemplateDataShortCircuitedProperties(): string[] {
    const ignore = [
      'templatedSectionId', 'templatedSection',
      'uniqueIdentifier', 'cachedTime',
      'pageIndex', 'firstOnPage', 'lastOnPage', 'lastSection',
      'beforeTitleSection', 'beforeAssetSection', 'afterAssetSection',
      'customLabelMap', 'metadata'
    ];
    return Object.keys(this)?.filter(key => !ignore.includes(key)) || [];
  }

  protected mergeAdvancedDataPropertiesFromTemplateSection(sectionTemplate: Section): void {
    // base implementation is section template data, then override with any section data
    this.combineCustomLabelMapWithTemplate(sectionTemplate);
    this.combineSectionMetadataWithTemplate(sectionTemplate);
  }

  getUniqueIdentifier(): string {
    return this.uniqueIdentifier;
  }

  // In Section rather than SectionWithProducts because Title/Image sections
  // may still need reference to header/body text colors

  getSectionHeaderTitleColor(): string {
    return this.metadata?.sectionHeaderTextColor;
  }

  getSectionBodyTextColor(): string {
    return this.metadata?.sectionBodyTextColor;
  }

}
