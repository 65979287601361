import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProductSectionItemComponent } from '../product-section-item/product-section-item.component';
import { ReportNewProductSectionItemViewModel } from './report-new-product-section-item-view-model';

@Component({
  selector: 'app-report-new-product-section-item',
  templateUrl: './report-new-product-section-item.component.html',
  styleUrls: ['./report-new-product-section-item.component.scss'],
  providers: [ReportNewProductSectionItemViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportNewProductSectionItemComponent extends ProductSectionItemComponent {

  constructor(
    public viewModel: ReportNewProductSectionItemViewModel
  ) {
    super(viewModel);
  }

}
