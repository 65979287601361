import { Type } from '@angular/core';
import { CardData } from 'src/app/models/print-cards/card-data';
import { PrintCardBackgroundComponent } from 'src/app/modules/print-cards/components/print-cards/backgrounds/print-card-background.component';
import { PrintCardContentComponent } from 'src/app/modules/print-cards/components/print-cards/card-content/print-card-content.component';
import { TwoByTwoCardStack } from './two-by-two-card-stack';
import { FireAndFlowerCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/print-cards/backgrounds/custom-cards-2x2/fire-and-flower-card-stack-background/fire-and-flower-card-stack-background.component';
import { FireAndFlowerCardStackContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/custom-cards-2x2/fire-and-flower-card-stack-content/fire-and-flower-card-stack-content.component';

export class FireAndFlowerCardStack extends TwoByTwoCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<PrintCardBackgroundComponent> | null {
    return FireAndFlowerCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return FireAndFlowerCardStackContentComponent;
  }

  getCardHeightInInches(): number {
    return 2.125;
  }

  getCardWidthInInches(): number {
    return 2.125;
  }

}
