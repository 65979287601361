import { PrintCardMenu } from './print-card-menu';

export class JaneDoePrintCardMenu extends PrintCardMenu {

  getFontFaceList(): string[] {
    return ['indivisible'];
  }

  shouldStandardizeDominantStrainType(): boolean {
    return false;
  }

}
