import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { SystemLabelKey } from '../../enum/dto/system-label-key.enum';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { PolymorphicDeserializationKey } from '../../enum/shared/polymorphic-deserialization-key.enum';

export class Label implements UniquelyIdentifiable, Cachable {

  public locationId: number;
  // id can be GUID for custom label, systemLabelKey for system Label, or id from 3rd party (like POS)
  public id: string | SystemLabelKey;
  public color: string;
  public text: string;
  public textColor: string;
  public priority: number;
  // Label has an equivalent company version with the same id
  public isCompanyManaged: boolean;
  // Label is inheriting style from equivalent company version
  public isCompanyLinked: boolean;
  public isPOSManaged: boolean;
  public timeThreshold: number;
  public numericThreshold: number;
  public usedByLocationIds: string[];
  public isSystemLabel: boolean;
  // not from api
  cachedTime: number;

  public getPolymorphicDeserializationKey(): PolymorphicDeserializationKey {
    return PolymorphicDeserializationKey.Label;
  }

  onDeserialize() {
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneDay();
  }

  cacheKey(): string {
    return `${this.locationId}-${this.id}`;
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.nowInUnixSeconds() > expiresAt;
  }

  getUniqueIdentifier(): string {
    return `
      -${this.locationId}
      -${this.id}
      -${this.color}
      -${this.text}
      -${this.textColor}
      -${this.priority}
      -${this.isCompanyManaged}
      -${this.isCompanyLinked}
      -${this.isPOSManaged}
      -${this.timeThreshold}
      -${this.numericThreshold}
      -${this.isSystemLabel}
    `;
  }

}
