import { Type } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { SectionColumnProductInfoType, SectionColumnType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { MenuHeaderCalyxTrichomesComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-calyx-trichomes/menu-header-calyx-trichomes.component';
import { PrintMenu } from '../print-menu';
import { PrintHeaderLayoutType } from '../../enum/shared/print-header-layout-type.enum';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';

export class CalyxTrichomesMenu extends PrintMenu {

  getPageTopMargin(): string {
    return '0.5in';
  }

  getPageBottomMargin(): string {
    return '0.5in';
  }

  getPageLeftMargin(): string {
    return '0.5in';
  }

  getPageRightMargin(): string {
    return '0.5in';
  }

  getThemeClass(): string {
    return 'calyx-trichomes-theme';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderCalyxTrichomesComponent;
  }

  getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.FirstPage;
  }

  getShowProductSubtitle(): boolean {
    return false;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getShowSectionHeaderColorAsIcon(): boolean {
    return true;
  }

  getFontFaceList(): string[] {
    return ['Banks-Miles', 'Facile-Sans'];
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.Brand, 0],
      [SectionColumnProductInfoType.ProductTitle, 1],
      [SectionColumnProductInfoType.Badge, 2],
      [SectionColumnProductInfoType.StrainType, 3],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(4),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(5),
      [SectionColumnProductInfoType.QuantityAndSize, 6],
      [SectionColumnProductInfoType.Size, 7],
      [SectionColumnProductInfoType.Quantity, 8],
      [SectionColumnProductInfoType.VariantPrice, 9],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 10],
    ]);
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

}
