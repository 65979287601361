import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ProductMenu } from '../../../../../../../models/menu/product-menu';
import { ResizeObserver } from '@juggle/resize-observer';
import { MenuFooterComponent } from '../../../menu/building-blocks/menu-footer/menu-footer.component';
import { HtmlUtils } from '../../../../../../../utils/html-utils';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { DistinctUtils } from '../../../../../../../utils/distinct.utils';
import { Section } from '../../../../../../../models/menu/section/section';
import { VariantBadge } from '../../../../../../../models/product/dto/variant-badge';
import { ProductSection } from '../../../../../../../models/menu/section/product-section';
import { LocationConfiguration } from '../../../../../../../models/company/dto/location-configuration';
import { StrainType } from '../../../../../../../models/enum/dto/strain-classification.enum';

@Component({
  selector: 'app-product-menu-footer',
  templateUrl: './product-menu-footer.component.html'
})
export class ProductMenuFooterComponent extends MenuFooterComponent implements OnChanges {

  @ViewChild('footerContainer') public footerContainer: ElementRef;
  @Input() menu: ProductMenu;
  @Input() reset: boolean;
  @Input() lastProductSection: ProductSection;
  @Input() locationConfig: LocationConfiguration;
  @Input() sectionsOnPage: Section[];
  @Output() footerHeightInPx: EventEmitter<number> = new EventEmitter<number>(true);

  public ro: ResizeObserver;

  protected _menu = new BehaviorSubject<ProductMenu | null>(null);
  public menu$ = this._menu.pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable));

  protected _locationConfig = new BehaviorSubject<LocationConfiguration | null>(null);
  public locationConfig$ = this._locationConfig.pipe(distinctUntilChanged(DistinctUtils.distinctUniquelyIdentifiable));

  protected _sectionsOnPage = new BehaviorSubject<Section[]>(null);
  public sectionsOnPage$ = this._sectionsOnPage as Observable<Section[]>;

  public menuBadges$: Observable<VariantBadge[]> = combineLatest([
    this.menu$,
    this.locationConfig$
  ]).pipe(
    map(([menu, locationConfig]) => menu?.getUniqueBadgeList(locationConfig))
  );
  public noBadges$ = this.menuBadges$.pipe(map(badges => !(badges?.length >= 1)));

  public menuClassifications$ = this.menu$.pipe(map(menu => menu?.getUniqueClassificationList()));
  public uniqueClassifications$ = this.menu$.pipe(map(menu => menu?.getUniqueClassificationList()));

  public hasClassifications$ = combineLatest([
    this.menu$,
    this.uniqueClassifications$
  ]).pipe(
    map(([menu, classifications]) => {
      return !menu?.allStrainTypeColumnsShutOff() && classifications?.length > 0;
    })
  );

  public noBadgesAndClassifications$ = combineLatest([
    this.noBadges$,
    this.hasClassifications$
  ]).pipe(
    map(([noBadges, hasClassifications]) => noBadges && !hasClassifications)
  );

  trackByBadgeUniqueIdentifier = (index: number, badge: VariantBadge): string => {
    return badge?.getUniqueIdentifier();
  };

  trackByClassification = (index: number, classification: StrainType): string => {
    return classification?.toString();
  };

  setupViews() {
    this._menu.next(this.menu);
    this._sectionsOnPage.next(this.sectionsOnPage);
  }

  setupBindings() {
    this.observeFooterContainer();
  }

  observeFooterContainer() {
    this.ro = new ResizeObserver((entries, _) => {
      HtmlUtils.getElementTotalHeightAsync(this.footerContainer.nativeElement)
        .then(height => this.footerHeightInPx.emit(height));
    });
    // Element for which to observe height and width
    if (!!this.footerContainer) {
      this.ro.observe(this.footerContainer.nativeElement);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.menu) this._menu.next(this.menu);
    if (changes.sectionsOnPage) this._sectionsOnPage.next(this.sectionsOnPage);
    if (changes.locationConfig) this._locationConfig.next(this.locationConfig);
  }

  destroy() {
    super.destroy();
    this.ro?.disconnect();
  }

}
