import { Component, Input } from '@angular/core';
import { CardStack } from '../../../../models/menu/section/card-stacks/card-stack';
import { BaseComponent } from '../../../../models/base/base-component';

@Component({ selector: 'app-cut-lines', template: '' })
export abstract class CutLinesComponent extends BaseComponent {

  @Input() cardStack: CardStack;
  @Input() nCardsOnPage: number;

  setupBindings() {
  }

  setupViews() {
  }

}
