import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import type { MenuSectionInflatorComponent } from '../../modules/display/components/menus/menu/inflators/menu-section-inflator-component';

export class PageFragment implements UniquelyIdentifiable {

  public startOfPage: boolean;
  public position: number;
  public scrollableItemComponent: MenuSectionInflatorComponent;

  constructor(startOfPage: boolean, position: number, start: MenuSectionInflatorComponent) {
    this.startOfPage = startOfPage;
    this.position = position;
    this.scrollableItemComponent = start;
  }

  static arePageFragmentsEqual(a: PageFragment[], b: PageFragment[]) {
    if (a === b) {
      return true;
    }
    if (a === null || b === null) {
      return false;
    }
    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; ++i) {
      if (a[i].getUniqueIdentifier() !== b[i].getUniqueIdentifier()) {
        return false;
      }
    }
    return true;
  }

  getUniqueIdentifier(): string {
    return this.scrollableItemComponent?.getUniqueIdentifier() + `-${this.startOfPage}-${this.position}`;
  }

}
