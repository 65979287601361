@charset "UTF-8";

/* src/app/modules/print-cards/components/print-cards/card-content/index-cards-3x5/fika-edibles-index-card-stack-content/fika-edibles-index-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  overflow: hidden;
}
:host .main-content {
  flex: 1;
  text-align: center;
  color: #0c2340;
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 400;
  font-style: normal;
}
:host .main-content .product-card-variant-type {
  position: absolute;
  top: 1rem;
  left: 1.5rem;
  right: 1.5rem;
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
  text-transform: uppercase;
}
:host .main-content .product-card-brand {
  position: absolute;
  bottom: 24.25rem;
  left: 1.5rem;
  right: 1.5rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}
:host .main-content .product-card-product-name {
  position: absolute;
  top: 7.25rem;
  left: 1.5rem;
  right: 1.5rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.25rem;
  line-height: 118%;
  letter-spacing: 0.0475rem;
  padding: 0 0.5rem;
}
:host .main-content .product-card-flavours {
  position: absolute;
  top: 11rem;
  left: 1.5rem;
  right: 1.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
:host .main-content .product-card-flavours .product-card-number-of-flavours {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
:host .main-content .product-card-flavours .product-card-number-of-flavours .product-card-flavor-text {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 0.75rem;
  text-transform: uppercase;
}
:host .main-content .product-card-flavours .product-card-number-of-flavours .separator {
  width: 100%;
  height: 2px;
  background-color: #0c2340;
  border: 0;
  margin: 0;
}
:host .main-content .product-card-flavours .product-card-individual-product-info {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  gap: 0.05rem;
}
:host .main-content .product-card-flavours .product-card-individual-product-info .product-card-variant-name {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 0.75rem;
  line-height: 100%;
}
:host .main-content .product-card-flavours .product-card-individual-product-info .product-card-cannabinoids {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  gap: 0.15rem;
  font-size: 0.75rem;
}
:host .main-content .product-card-flavours .product-card-individual-product-info .product-card-cannabinoids .cannabinoid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}
:host .main-content .product-card-flavours .product-card-individual-product-info .product-card-cannabinoids .cannabinoid-separator {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 400;
  font-style: normal;
}
:host .main-content .product-card-flavours .product-card-individual-product-info .product-card-cannabinoids .cannabinoid-title {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 500;
  font-style: normal;
}
:host .main-content .product-card-flavours .product-card-individual-product-info .product-card-cannabinoids .cannabinoid-value {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 400;
  font-style: normal;
}
:host .main-content .product-card-flavours .product-card-individual-product-info .product-card-strain-type-and-size {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 0.75rem;
  text-transform: uppercase;
}
:host .main-content .product-card-flavours .product-card-individual-product-info .product-card-strain-type-and-size .strain-and-size-separator {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 400;
  font-style: normal;
}
:host .main-content .product-card-flavours .product-card-individual-product-info .product-card-strain-type-and-size .badge-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: calc(100% + 0.4rem);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.25rem;
}
:host .main-content .product-card-flavours .product-card-individual-product-info .product-card-strain-type-and-size .badge-container .print-card-badge {
  height: 0.75rem;
  width: auto;
}
:host .main-content .product-card-flavours .product-card-individual-product-info .product-card-strain-type-and-size .label-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translate(calc(100% + 0.4rem), 0);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
:host .main-content .product-card-flavours .product-card-individual-product-info .product-card-strain-type-and-size .label-container .print-card-label {
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .main-content .product-card-flavours .product-card-individual-product-info .product-card-strain-type-and-size .label-container .print-card-label ::ng-deep .product-label {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 0.5625rem;
  padding-top: 0.125rem;
  padding-bottom: 0.1rem;
  padding-left: 0.175rem;
  padding-right: 0.175rem;
  line-height: 100%;
  border-radius: 0.125rem;
  margin: 0;
  width: fit-content;
}
:host .main-content .product-card-flavours .plus-n-more-flavours {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 0.75rem;
  line-height: 100%;
  text-transform: uppercase;
  margin-top: 0.5rem;
}
:host .main-content .product-card-price {
  position: absolute;
  top: 27rem;
  left: 1.5rem;
  right: 1.5rem;
}
:host .main-content ::ng-deep .force-bold-style * {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 600;
  font-style: normal;
}
:host .main-content ::ng-deep .force-italic-style * {
  font-family: "NeueHaasGroteskDisplay", sans-serif;
  font-weight: 700;
  font-style: italic;
}
/*# sourceMappingURL=fika-edibles-index-card-stack-content.component.css.map */
