import { PrintCardMenu } from './print-card-menu';
import type { LocationPriceStream } from '../../enum/shared/location-price-stream';
import type { SectionWithProducts } from '../section/section-with-products';
import type { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { SpecificPriceWithoutDiscounts } from '../../enum/shared/specific-price-without-discounts.enum';

export class DougPrintCardMenu extends PrintCardMenu {

  getFontFaceList(): string[] {
    return ['source-sans-pro', 'poppins'];
  }

  getPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel
  ): string {
    const priceDecimal = super.getPriceDecimal(priceStream, locationId, s, rowVM, colVm);
    if (!s?.isGridMode()) {
      return priceDecimal.replace('.', '');
    }
    return priceDecimal;
  }

  getOriginalPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    sectionWithProducts: SectionWithProducts,
    sectionRowViewModel: SectionRowViewModel,
    sectionColumnViewModel: SectionColumnViewModel,
    specificPriceWithoutDiscounts: SpecificPriceWithoutDiscounts[]
  ): string {
    const originalPriceDecimal = super.getOriginalPriceDecimal(
      priceStream,
      locationId,
      sectionWithProducts,
      sectionRowViewModel,
      sectionColumnViewModel,
      specificPriceWithoutDiscounts
    );
    if (!sectionWithProducts?.isGridMode()) {
      return originalPriceDecimal.replace('.', '');
    }
    return originalPriceDecimal;
  }

  shouldStandardizeDominantStrainType(): boolean {
    return false;
  }

}
