/* src/app/modules/print-cards/components/card-grid/print-card-grid.component.scss */
:host {
  position: relative;
}
.print-card-paper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.print-card-paper .print-card-top-cut-lines {
  flex: 1;
}
.print-card-paper .print-card-middle-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.print-card-paper .print-card-middle-content .print-card-grid {
  place-content: center;
}
.print-card-paper .print-card-middle-content .print-card-left-cut-lines {
  flex: 1;
}
.print-card-paper .print-card-middle-content .print-card-right-cut-lines {
  flex: 1;
}
.print-card-paper .print-card-bottom-cut-lines {
  flex: 1;
}
.print-card-paper .print-card-page-break {
  page-break-after: always;
}
/*# sourceMappingURL=print-card-grid.component.css.map */
