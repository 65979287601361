import { SectionRowViewModel } from './SectionRowViewModel';
import { SectionColumnConfigDataValue } from '../../../../../../../../../models/menu/section/section-column-config';

export class SectionRowViewModelBlended extends SectionRowViewModel {

  getStrainTypeColor(): string {
    return '#222222';
  }

  getReadableStrainType(strainTypeMode: SectionColumnConfigDataValue): string {
    // Blended is hardcoded to always return symbol - the column is disabled is admin dashboard
    return super.getReadableStrainType(SectionColumnConfigDataValue.StrainTypeSymbol);
  }

}
