import { SectionLayoutType } from '../models/enum/dto/section-layout-type.enum';
import { exists } from '../functions/exists';
import { SortUtils } from './sort-utils';
import type { ProductMenu } from '../models/menu/product-menu';
import type { SectionWithProducts } from '../models/menu/section/section-with-products';
import type { VariantBadge } from '../models/product/dto/variant-badge';
import type { Variant } from '../models/product/dto/variant';

export class BadgeUtils {

  public static getVariantVisibleBadges(
    menu: ProductMenu,
    section: SectionWithProducts,
    scopedVisibleVariants: Variant[]
  ): VariantBadge[] {
    const sectionType = section?.layoutType;
    const limit = menu?.getAllowedBadgeCount();
    const variantBadgeMap = section?.variantBadgeMap;
    return BadgeUtils.getBadges(sectionType, limit, variantBadgeMap, scopedVisibleVariants);
  }

  /**
   * Hierarchy: override badges > location display attribute badges > company display attribute badges
   *
   * Hierarchy pools do not mix together.
   *
   * - Example: Theme supports 3 badges. There is 1 override badge, 2 location display attribute badges,
   *   and 3 company display attribute badges. Only the single override badge will be shown.
   * - Example: Theme supports 3 badges. There is 2 location display attribute badges and 3 company display
   *   attribute badges. Only the 2 location display attribute badges will be shown.
   * 
   * If the line item has more badges than the theme limit, then the badges are sorted by name and the first
   * n badges are shown where n is the theme limit.
   */
  public static getBadges(
    sectionType: SectionLayoutType,
    limit: number = 0, // 0 means no limit, -1 means not supported
    variantBadgeMap: Map<string, VariantBadge[]>,
    scopedVisibleVariants: Variant[]
  ): VariantBadge[] {
    let selectedBadges: VariantBadge[] = [];
    let overrideBadges: VariantBadge[] = [];
    let daBadges: VariantBadge[] = [];
    let inheritedDABadges: VariantBadge[] = [];

    scopedVisibleVariants?.forEach((v) => {
      const badges: VariantBadge[] = [];
      if (exists(variantBadgeMap?.get(v.id))) {
        variantBadgeMap.get(v.id)?.forEach((badge) => {
          if (!badges.find(b => b.id === badge.id)) {
            badges.push(badge);
          }
        });
      }
      const displayAttributeBadges = v?.displayAttributes?.badges ? v.displayAttributes.badges : [];
      const inheritedDisplayAttributeBadges = v?.displayAttributes?.inheritedDisplayAttribute?.badges ?? [];
      if (sectionType === SectionLayoutType.Grid) {
        // Only handle badges if Grid mode (PricingTierGrid is still technically in-line mode)
        if (badges.length > 0) {
          overrideBadges.push(...badges);
        }
        if (displayAttributeBadges.length > 0) {
          daBadges.push(...displayAttributeBadges);
        }
        if (inheritedDisplayAttributeBadges.length > 0) {
          inheritedDABadges.push(...inheritedDisplayAttributeBadges);
        }
      } else {
        if (badges.length > 0) {
          selectedBadges.push(...badges);
        } else if (displayAttributeBadges.length > 0) {
          selectedBadges.push(...displayAttributeBadges);
        } else if (inheritedDisplayAttributeBadges.length > 0) {
          selectedBadges.push(...inheritedDisplayAttributeBadges);
        }
      }
    });

    overrideBadges = overrideBadges.uniqueByProperty('id').sort(SortUtils.sortBadgesByName);
    daBadges = daBadges.uniqueByProperty('id').sort(SortUtils.sortBadgesByName);
    inheritedDABadges = inheritedDABadges.uniqueByProperty('id').sort(SortUtils.sortBadgesByName);

    if (overrideBadges.length > 0) {
      selectedBadges = overrideBadges;
    } else if (daBadges.length > 0) {
      selectedBadges = daBadges;
    } else if (inheritedDABadges.length > 0) {
      selectedBadges = inheritedDABadges;
    } else {
      selectedBadges = selectedBadges.uniqueByProperty('id').sort(SortUtils.sortBadgesByName);
    }

    if (limit > 0 && limit < selectedBadges.length) {
      return selectedBadges.slice(0, limit);
    } else if (limit < 0) { // Not supported
      return [];
    } else {
      return selectedBadges;
    }
  }

}
