import { Menu } from '../../menu/menu';

export class FetchVariantAssetReq {

  public companyId: number;
  public batchVariantIds: string[]; // Max batch size of 50
  public assetTypeSortOrder: string[];
  public itemCount: number = 1;

  constructor(
    companyId: number,
    menu: Menu,
    variantIds: string[],
  ) {
    this.companyId = companyId;
    this.batchVariantIds = variantIds;
    this.assetTypeSortOrder = menu?.variantAssetTypeSortOrder();
    this.itemCount = menu?.variantAssetItemCount();
  }

}
