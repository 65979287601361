import { ProductSection } from '../product-section';

export class ReportRestockedProductsSection extends ProductSection {

  onDeserialize() {
    super.onDeserialize();
    this.metadata.hideNewLabels = 'true';
    this.metadata.hideRestockedLabels = 'true';
  }

}
