import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appScrollableItem]'
})
export class ScrollableItemDirective {

  @Input('appScrollableItem')
  public key: string;

  constructor(private el: ElementRef<HTMLElement>) {
  }

  public scrollIntoView() {
    this.el.nativeElement.scrollIntoView({ behavior: 'smooth', inline: 'start' });
  }

}
