import { Pipe, PipeTransform } from '@angular/core';
import { CardStack } from '../../../models/menu/section/card-stacks/card-stack';

@Pipe({
  name: 'getInternalGridCutLineCrossTopOffset'
})
export class GetInternalGridCutLineCrossTopOffsetPipe implements PipeTransform {

  /**
   * Indices of Crosses
   *   One Column              Two Column                      Three Column
   * |------------| |-----------------------------| |--------------------------------|
   * |     0      | |       0            1        | |    0          1          2     |
   * |     +      | |       +            +        | |    +          +          +     |
   * |            | |                             | |                                |
   * |     1      | |       2            3        | |    3          4          5     |
   * |     +      | |       +            +        | |    +          +          +     |
   * |            | |                             | |                                |
   * |     2      | |       4            5        | |    6          7          8     |
   * |     +      | |       +            +        | |    +          +          +     |
   * | -----------| | ----------------------------| | -------------------------------|
   */
  transform(cardStack: CardStack, nCardsOnPage: number, index: number): number {
    const nCrossColumns = (cardStack?.getNumberOfVisibleColumnsOnPage(nCardsOnPage) ?? 1) - 1;
    const nThRow = Math.floor(index / nCrossColumns) + 1;
    const bleed = cardStack?.withContentBleed(false, 2) ?? 0;
    const cardHeight = cardStack?.getCardHeightInPixels() ?? 0;
    return (cardHeight + bleed) * nThRow;
  }

}
