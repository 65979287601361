import { Deserializable } from '../../protocols/deserializable';
import { Asset } from '../../image/dto/asset';
import { Location } from './location';
import { CompanyConfiguration } from './company-configuration';
import { DateUtils } from '../../../utils/date-utils';
import { Cachable } from '../../protocols/cachable';
import { CompanyFeatures } from './company-features';

export class Company implements Deserializable, Cachable {

  public id: number;
  public name: string;
  public logo: Asset;
  public altLogo: Asset;
  public locations: Location[];
  public configuration: CompanyConfiguration;
  public features: CompanyFeatures;
  // Cache
  public cachedTime: number;

  static buildCacheKey(id: string): string {
    return `Company-${id}`;
  }

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    this.logo = Deserialize?.instanceOf(Asset, this.logo);
    this.altLogo = Deserialize?.instanceOf(Asset, this.altLogo);
    this.locations = Deserialize?.arrayOf(Location, this.locations);
    this.configuration = Deserialize?.instanceOf(CompanyConfiguration, this.configuration);
    this.features = Deserialize?.instanceOf(CompanyFeatures, this.features);
  }

  cacheKey(): string {
    return Company.buildCacheKey(this.id.toString());
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneDay();
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.nowInUnixSeconds() > expiresAt;
  }

}
