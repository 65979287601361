import { Type } from '@angular/core';
import { ProductMenu } from '../product-menu';
import { ProductMenuFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/product-menu-footer.component';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { OpacityLocation } from '../../shared/opacity-location.enum';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import type { SectionWithProducts } from '../section/section-with-products';
import { LocationPriceStream } from '../../enum/shared/location-price-stream';

export class FramedMenu extends ProductMenu {

  getThemeClass(): string {
    return 'framed-theme';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getSectionsWrapperClass(): string {
    return 'framed-sections-container';
  }

  getSectionsBackgroundColor(): string {
    return this.getBackgroundColorWithOpacity();
  }

  getFooterType(): Type<ProductMenuFooterComponent> {
    return ProductMenuFooterComponent;
  }

  getShowFooter(): boolean {
    return false;
  }

  getSectionWrapperClass(...args): string {
    if (this.isPortrait()) {
      return 'framed-section-container-portrait';
    } else {
      return 'framed-section-container';
    }
  }

  getTitleSectionBottomMargin(): string {
    return '0.375rem';
  }

  getOpacityLocation(): OpacityLocation {
    return OpacityLocation.SECTION_CONTAINER;
  }

  getBackgroundColorWithOpacity(defaultBackgroundColor: number[] = [255, 255, 255]): string {
    return super.getBackgroundColorWithOpacity(defaultBackgroundColor, 0);
  }

  getPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel,
  ): string {
    if (!this.getDropDecimal()) {
      const price = this.getVariantPricing(priceStream, locationId, s, rowVM, colVm, false, true);
      const dec = price.split('.')?.[1];
      // decimals and formatting are included. ie: $1000.00
      if (price === '-' || price.length > 7 || dec === '') {
        return '';
      } else {
        return '.' + dec;
      }
    } else {
      return '';
    }
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return false;
  }

  getFontFaceList(): string[] {
    return ['realist', 'poppins'];
  }

}
