<div class="sidebar-section" [style.background-color]="viewModel.cardAccentColor$ | async">
  <div
    *ngIf="cardData | sectionRowViewModelBasicMethod: 'getSidebarText' as text"
    class="sidebar-text"
    [style.color]="
      viewModel.cardStack$ | async | textColorBasedOnSectionHeaderBackgroundColor: (viewModel.cardAccentColor$ | async)
    "
    appPrintCardScaleDownText
    [maxHeightInPx]="24"
    [maxWidthInPx]="154"
    [startScalingAfterNMillisecondsAfterViewInit]="500"
    [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
    {{ text }}
  </div>
</div>

<div class="main-section">
  <div class="top-half-of-card">
    <div>
      <img
        *ngIf="cardData | getVariantAssetForCard | async as variantAsset"
        appImage
        class="product-card-image"
        [asset]="variantAsset"
        src=""
        alt="" />
    </div>

    <app-nycd-business-price></app-nycd-business-price>
  </div>
  <div class="bottom-half-of-card">
    <div
      *ngIf="cardData | sectionRowViewModelBasicMethod: 'getRowTitle' as title"
      class="product-card-name"
      [style.color]="viewModel.headerTextColor$ | async">
      {{ title | trimSafely }}
    </div>

    <div class="info-row" *ngIf="!(viewModel.isNonCannabinoidVariant$ | async)" [style.gap.rem]="0.5">
      <div
        *ngIf="cardData | sectionRowViewModelBasicMethod: 'getThc' as thc"
        class="property-container"
        [style.color]="viewModel.bodyTextColor$ | async">
        <div class="title">THC</div>
        <div class="value">
          {{ thc }}
        </div>
      </div>
      <div
        *ngIf="cardData | sectionRowViewModelBasicMethod: 'getCbd' as cbd"
        class="property-container"
        [style.color]="viewModel.bodyTextColor$ | async">
        <div class="title">CBD</div>
        <div class="value">
          {{ cbd }}
        </div>
      </div>
    </div>

    <div class="info-row" [style.gap.rem]="0.375">
      <div
        *ngIf="viewModel | getExpandedGridSize: (viewModel.spoofedColViewModel$ | async) : true | async as size"
        class="product-card-size"
        [style.color]="viewModel.bodyTextColor$ | async">
        {{ size }}
      </div>

      <app-label-inflator
        class="print-card-label"
        [columnLabel]="false"
        [labelComponentInterface]="cardData"
        [reset]="false">
      </app-label-inflator>

      <img
        *ngFor="let variantBadge of cardData | printCardBadges"
        appImage
        class="product-badge"
        [asset]="variantBadge?.image"
        alt="badge"
        src="" />
    </div>
  </div>
</div>
