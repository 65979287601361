import { PrintCardMenu } from './print-card-menu';
import { Position } from '../../enum/shared/position.enum';

export class PlainJanePrintCardMenu extends PrintCardMenu {

  getFontFaceList() {
    return ['poppins', 'realist'];
  }

  shouldStandardizeDominantStrainType() {
    return false;
  }

  primaryPriceColumnAlsoShowOriginalPriceIfOnSalePosition(): Position {
    return Position.Top;
  }

}
