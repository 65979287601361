import { Component, ViewEncapsulation } from '@angular/core';
import { LabelComponent } from '../label-component';
import { LabelViewModel } from '../label-view-model';

@Component({
  selector: 'app-basic-round-border-label',
  templateUrl: './basic-round-border-label.component.html',
  styleUrls: ['./basic-round-border-label.component.scss'],
  providers: [LabelViewModel],
  encapsulation: ViewEncapsulation.None
})
export class BasicRoundBorderLabelComponent extends LabelComponent {

  constructor(
    public viewModel: LabelViewModel
  ) {
    super(viewModel);
  }

  setupViews() {
    super.setupViews();
  }

  setupBindings() {
  }

}
