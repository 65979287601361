import { ChangeDetectionStrategy, Component, ElementRef, Input, QueryList, ViewChildren } from '@angular/core';
import { BaseComponent } from '../../../../../../../../models/base/base-component';
import { StiiizyCardStackContentViewModel } from '../stiiizy-card-stack-content-view-model';
import { StiiizyCardData } from '../../../../../../../../models/print-cards/stiiizy-card-data';

@Component({
  selector: 'app-stiiizy-product-information',
  templateUrl: './stiiizy-product-information.component.html',
  styleUrls: ['./stiiizy-product-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StiiizyProductInformationComponent extends BaseComponent {

  constructor(
    public viewModel: StiiizyCardStackContentViewModel, // provided by parent component
  ) {
    super();
  }

  @Input() cardData: StiiizyCardData;
  @ViewChildren('cannabinoids') cannabinoids: QueryList<ElementRef<HTMLDivElement>>;
  private resizeObserver: ResizeObserver;

  setupViews() {}

  setupBindings() {
    this.observeCannabinoidsHeight(this.cannabinoids?.first?.nativeElement);
    this.cannabinoids.changes.subscribeWhileAlive({
      owner: this,
      next: (cannabinoids) => this.observeCannabinoidsHeight(cannabinoids?.firstOrNull()?.nativeElement)
    });
  }

  private observeCannabinoidsHeight(cannabinoidsDiv: HTMLDivElement): void {
    this.resizeObserver?.disconnect();
    if (!cannabinoidsDiv) return;
    this.resizeObserver = new ResizeObserver(() => {
      this.viewModel.connectToCannabinoidsHeight(cannabinoidsDiv?.clientHeight ?? 0);
    });
    this.resizeObserver.observe(cannabinoidsDiv);
  }

  destroy() {
    super.destroy();
    this.resizeObserver?.disconnect();
  }

}
