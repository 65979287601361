<div #footerContainer class="footer-container double-dutch-empty-footer">
  <div fxLayout="row" fxFlex>
    <div fxFlex class="side-of-classification-board left" fxLayout="column" fxLayoutAlign="stretch">
      <div
        [style.border-color]="lastProductSection?.metadata.productsHeaderBackgroundColor"
        [style.background-color]="lastProductSection?.metadata.productsContainerBackgroundColor"
        fxFlex="60"
        class="top-of-side left">
        <div
          class="gap-filler"
          [style.background-color]="lastProductSection?.metadata.productsContainerBackgroundColor"></div>
      </div>
      <div fxFlex="40" class="bottom-of-side"></div>
    </div>
    <div fxFlex class="side-of-classification-board right" fxLayout="column" fxLayoutAlign="stretch">
      <div
        [style.border-color]="lastProductSection?.metadata.productsHeaderBackgroundColor"
        [style.background-color]="lastProductSection?.metadata.productsContainerBackgroundColor"
        fxFlex="60"
        class="top-of-side right">
        <div
          class="gap-filler"
          [style.background-color]="lastProductSection?.metadata.productsContainerBackgroundColor"></div>
      </div>
      <div fxFlex="40" class="bottom-of-side"></div>
    </div>
  </div>
</div>
