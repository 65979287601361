import { Viewable } from '../protocols/viewable';
import { Subscribable } from './subscribable';
import { TypeUtils } from '../../utils/type-utils';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({ selector: 'app-base', template: '' })
export abstract class BaseComponent extends Subscribable implements OnInit, AfterViewInit, OnDestroy, Viewable {

  public rendered = new BehaviorSubject<boolean>(false);
  Types = TypeUtils;

  abstract setupViews();
  abstract setupBindings();

  ngOnInit(): void {
    window.injector.isReadyService.globalSignalCreated(this.rendered);
    this.setupViews();
  }

  ngAfterViewInit(): void {
    this.rendered.next(true);
    this.setupBindings();
  }

  ngOnDestroy() {
    window.injector.isReadyService.globalSignalDestroyed(this.rendered);
    super.ngOnDestroy();
  }

}
