/* src/app/modules/display/components/menus/product-menu/building-blocks/menu-footer/classification-footer/classification-footer.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.classification-footer-container {
  font-size: 1rem;
  color: white;
  text-transform: uppercase;
  display: flex;
  align-content: center;
  padding: 0;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  flex-wrap: wrap;
  height: 4rem;
}
.classification-footer-container.no-classifications {
  height: 0;
}
.classification-footer-container .footer-classification-board {
  font-size: 1.25rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 0.1rem 0.5rem;
}
.classification-footer-container .footer-classification-board .classification-mapping {
  display: flex;
  flex-direction: row;
  gap: 0.35rem;
}
/*# sourceMappingURL=classification-footer.component.css.map */
