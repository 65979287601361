import { SectionRowViewModel } from './SectionRowViewModel';
import { VariantTypeUtils } from '../../../../../../../../../utils/variant-type-utils';

export class SectionRowViewModelBanded extends SectionRowViewModel {

  public getQuantityString(): string {
    if (this.variantLineItemMode) {
      const isPreRoll = VariantTypeUtils.isPreRollType(this.rowVariants?.firstOrNull()?.variantType);
      if (isPreRoll) return `${this.getQuantity()} pk`;
    }
    return this.getQuantity().toString();
  }

}
