import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ColumnComponent } from '../column.component';

@Component({
  selector: 'app-column-strain-type',
  templateUrl: './column-strain-type.component.html',
  styleUrls: ['./column-strain-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ColumnStrainTypeComponent extends ColumnComponent {

  @Input() useNgContent: boolean;

}
