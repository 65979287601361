import { VariantFeature } from '../../product/dto/variant-feature';
import { DisplayOptions } from '../../shared/display-options';
import { MarketingAsset } from '../../image/dto/marketing-asset';
import { OrderFeaturedVariants } from './FeaturedProduct/order-featured-variants';
import { DeprecatedMarketingMenu } from '../deprecated-marketing-menu';

export abstract class MarketingLoopingContentMenu extends DeprecatedMarketingMenu {

  public abstract calculateMarketingLoopDurationInSeconds(): number;

  getOrderFeaturedVariants(
    featured: VariantFeature,
    order: DisplayOptions,
    media: MarketingAsset[]
  ): OrderFeaturedVariants[] {
    return media?.map(m => {
      const position = order?.rotationOrder?.get(m.fileName);
      const interval = order?.rotationInterval?.get(m.fileName);
      if (position >= 0 && interval > 0) {
        return new OrderFeaturedVariants(position, null, m);
      }
    }) ?? [];
  }

}
