import { Injectable } from '@angular/core';
import { PrintCardBackgroundViewModel } from '../../print-card-background-view-model';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { JaneDoeCardData } from '../../../../../../../models/print-cards/jane-doe-card-data';
import { CardStack } from '../../../../../../../models/menu/section/card-stacks/card-stack';

@Injectable()
export class JaneDoeAddressCardStackBackgroundViewModel extends PrintCardBackgroundViewModel {

  static readonly ColorBorderThicknessInPx = 8;

  public readonly cardData$: Observable<JaneDoeCardData>;

  public borderAdjustment$ = combineLatest([
    this.cardStack$,
    this.preview$
  ]).pipe(
    map(([cardStack, preview]) => {
      const bleed = (cardStack?.nonPerforatedBleedBorderInInches() ?? 0) * CardStack.PIXELS_PER_INCH;
      const additionalHeight = cardStack?.isOnRegularPaper() && !preview ? bleed : 0;
      const borderThickness = JaneDoeAddressCardStackBackgroundViewModel.ColorBorderThicknessInPx;
      return `calc(100% - ${additionalHeight * 2}px - ${borderThickness * 2}px)`;
    })
  );

  public borderColor$ = this.cardData$.pipe(
    map(cardData => cardData?.getCardBorderColor())
  );

}
