import { ApiClient } from './api-client';
import { LoggingService } from '../services/logging-service';
import { Injectable } from '@angular/core';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { catchError } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { LocationConfiguration } from '../models/company/dto/location-configuration';

@Injectable({ providedIn: 'root' })
export class LocationAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  // Variables

  public serviceName = 'Location';

  public getLocationConfiguration(companyId: number, locationId: number): Observable<LocationConfiguration> {
    const url = Endpoints.GetLocationConfigurations(locationId);
    // noinspection SpellCheckingInspection
    const additionalHeaders = { companyid: `${companyId}` };
    return this.apiClient.getObj<LocationConfiguration>(LocationConfiguration, url, additionalHeaders).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetLocationConfiguration', err));
        return throwError(err);
      })
    );
  }

}
