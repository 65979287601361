// @dynamic
export class StyleUtils {

  static getConsolidatedStyles(styles: any[]): any {
    const styleRes = {};
    styles?.forEach(s => {
      if (!!s) {
        for (const [k, v] of Object.entries(s)) {
          if (!!v) {
            styleRes[k] = v;
          }
        }
      }
    });
    return styleRes;
  }

}

