<div
  #headerContainer
  [ngClass]="menu | productMenuBasicMethod : 'getHeaderWrapperClass'"
  [ngStyle]="{
    color: menu?.hydratedTheme?.themeFeatures?.titleTextColor ? menu?.menuOptions?.titleTextColor : ''
  }">
  <div class="header-container" fxLayoutAlign="center center">
    <div
      class="main-header"
      [hidden]="menu?.menuOptions?.hideTitle || !menu?.configurationTitle"
      [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
      {{ menu?.configurationTitle }}
    </div>

    <img
      appImage
      *ngIf="menu | productMenuBasicMethod : 'showCompanyLogo'"
      class="company-logo flex-grow"
      [style.zoom]="menu?.menuOptions?.titleZoom || 1.0"
      [style.margin]="menu | productMenuBasicMethod : 'getHeaderLogoMargin'"
      [asset]="menu?.companyLogo"
      [reset]="reset"
      alt="company logo"
      src="" />
  </div>
</div>
