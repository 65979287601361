@charset "UTF-8";

/* src/app/modules/print-cards/components/print-cards/card-content/post-cards-4x6/miss-jones-post-card-stack-content/miss-jones-post-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
  height: 100%;
  display: flex;
  font-family: oswald, sans-serif;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  overflow: hidden;
}
:host .header {
  color: #222222;
  display: flex;
  padding: 0.75rem 0;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  font-size: calc(1.25rem * 1);
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
}
:host .main-content {
  flex: 1;
  padding: calc(1rem * 1) calc(1.5rem * 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: calc(0.5rem * 1);
}
:host .main-content .top-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
:host .main-content .top-content .miss-jane-brand {
  font-family: futura-pt, sans-serif;
  font-size: calc(0.75rem * 1);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12rem;
  text-transform: uppercase;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
:host .main-content .top-content .miss-jane-logo {
  width: calc(1.5rem * 1);
}
:host .main-content .center-content .product-name {
  font-family: rama-gothic-m, sans-serif;
  font-size: calc(3.5rem * 1);
  font-style: normal;
  font-weight: 600;
  line-height: 90%;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
:host .main-content .center-content .strain-label-badge-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  column-gap: calc(0.5rem * 1);
  row-gap: calc(0.5rem * 1);
  margin-top: 0.5rem;
}
:host .main-content .center-content .strain-label-badge-container .strain-type {
  color: #222222;
  font-size: calc(1.5rem * 1);
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
}
:host .main-content .center-content .strain-label-badge-container .badge-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: calc(0.5rem * 1);
  justify-content: center;
  align-items: center;
}
:host .main-content .center-content .strain-label-badge-container .badge-container .badge {
  height: 1.75rem;
}
:host .main-content .center-content .label {
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .main-content .center-content .label ::ng-deep .product-label {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-size: calc(0.75rem * 1);
  line-height: normal;
  border-radius: calc(0.125rem * 1);
  margin: 0;
  width: fit-content;
}
:host .main-content .center-content ::ng-deep .description-container .description {
  font-family: futura-pt, sans-serif;
  font-size: calc(0.9375rem * 1);
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-top: 1rem;
}
:host .main-content .center-content ::ng-deep .description-container .plain-text {
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}
:host .main-content .center-content ::ng-deep .description-container .rich-text {
  max-height: 7.3125rem;
  overflow-y: hidden;
}
:host .main-content .bottom-content .cannabinoids-and-price {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
:host .main-content .bottom-content .property-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: calc(0.125rem * 1);
  box-sizing: border-box;
  padding: calc(0.3rem * 1) 0;
}
:host .main-content .bottom-content .property-container .title {
  font-size: calc(0.75rem * 1);
  line-height: 120%;
}
:host .main-content .bottom-content .property-container .value {
  font-size: calc(1.5rem * 1);
  line-height: 120%;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
:host .main-content .bottom-content .product-card-price ::ng-deep .product-variant-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(2.5rem * 1);
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
  overflow: visible;
}
:host .main-content .bottom-content .product-card-price ::ng-deep .product-variant-price .product-variant-previous-price {
  opacity: 0.5;
  font-size: calc(1.75rem * 1);
}
:host .main-content .sizing {
  font-family: futura-pt, sans-serif;
  display: flex;
  justify-content: flex-end;
  font-size: calc(1rem * 1);
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
/*# sourceMappingURL=miss-jones-post-card-stack-content.component.css.map */
