<div class="card-content">
  <app-label-inflator
    #labelCalculator
    hidden
    class="label-calculator label"
    [columnLabel]="false"
    [labelComponentInterface]="cardData"
    [reset]="false">
  </app-label-inflator>
  <div class="header-section" [style.background-color]="viewModel.cardAccentColor$ | async">
    <div class="header-text">
      {{
        viewModel.isLineItemMode$
          | cardHeaderUnitSize: viewModel.cardData$ : viewModel.spoofedGridColViewModels$ : false
          | async
          | trimSafely
      }}
      <ng-container *ngIf="viewModel.showVariantType$ | async">
        {{ cardData | sectionRowViewModelBasicMethod: 'rowVariantType' }}
      </ng-container>
    </div>
  </div>
  <div class="main-section" [style.color]="viewModel.bodyTextColor$ | async">
    <div class="background-content" [style.background-color]="viewModel.cardColor$ | async">
      <app-print-card-background-image-with-color-overlay-and-opacity [cardData]="cardData">
      </app-print-card-background-image-with-color-overlay-and-opacity>
    </div>
    <div class="left-side" [class.full-width]="viewModel.hideRightSideOfCard$ | async">
      <div class="title-brand-container">
        <div
          *ngIf="cardData | sectionRowViewModelBasicMethod: 'getRowTitle' as title"
          appScaleDownText
          class="title-container"
          [style.color]="viewModel.headerTextColor$ | async">
          {{ title | trim }}
        </div>
        <div *ngIf="cardData | sectionRowViewModelBasicMethod: 'getBrand' as brand" class="product-card-brand">
          {{ brand | trim }}
        </div>
      </div>
      <div class="product-card-price">
        <app-column-primary-price
          [menu]="viewModel.cardStackMenu$ | async"
          [section]="viewModel.cardStack$ | async"
          [colVm]="viewModel.spoofedColViewModel$ | async"
          [rowVm]="viewModel.cardData$ | async"
          [locConfig]="viewModel.locationConfig$ | async"
          [locationId]="viewModel.locationId$ | async"
          [productStylingVm]="viewModel">
        </app-column-primary-price>
      </div>
    </div>
    <div *ngIf="!(viewModel.hideRightSideOfCard$ | async)" class="right-side">
      <div *ngIf="viewModel.badgeOrLabelVisible$ | async" class="badge-and-label-container">
        <div *ngIf="viewModel.hasBadges$ | async" class="product-card-badge-container">
          <img
            *ngFor="let variantBadge of cardData | printCardBadges"
            appImage
            class="product-card-badge"
            [asset]="variantBadge?.image"
            alt="badge"
            src="" />
        </div>
        <div *ngIf="viewModel.labelText$ | async" class="product-card-label-container">
          <app-label-inflator
            class="print-card-label"
            [columnLabel]="false"
            [labelComponentInterface]="cardData"
            [reset]="false">
          </app-label-inflator>
        </div>
      </div>
      <ng-container *ngIf="!(viewModel.isNonCannabinoidVariant$ | async)">
        <div *ngIf="viewModel.strainText$ | async as strainType" class="strain-type-container">
          <div class="strain-type" appScaleDownText [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
            {{ strainType }}
          </div>
        </div>
        <div class="property-row">
          <div class="title">THC</div>
          <div class="value">
            <div
              class="value-text"
              appScaleDownText
              [scaleDownLinkedKey]="viewModel.variantId$ | async"
              [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
              {{ cardData | sectionRowViewModelBasicMethod: 'getThc' }}
            </div>
          </div>
        </div>
        <div class="property-row">
          <div class="title">CBD</div>
          <div class="value">
            <div
              class="value-text"
              appScaleDownText
              [scaleDownLinkedKey]="viewModel.variantId$ | async"
              [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
              {{ cardData | sectionRowViewModelBasicMethod: 'getCbd' }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
