import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { CutLinesComponent } from '../cut-lines.component';
import { CutLineMode } from '../../../../../models/enum/shared/cut-line-mode.enum';

@Component({
  selector: 'app-print-card-right-cut-lines',
  templateUrl: './print-card-right-cut-lines.component.html',
  styleUrls: ['./print-card-right-cut-lines.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintCardRightCutLinesComponent extends CutLinesComponent {

  @HostBinding('style.justify-content') get alignItems() {
    switch (this.cardStack?.cutLineMode) {
      case CutLineMode.HugPerimeter: return 'flex-end';
      case CutLineMode.HugContent:   return 'flex-start';
      case CutLineMode.Extended:     return 'stretch';
    }
    return null;
  }

}
