import { Menu } from './menu';
import { DisplayableMarketingMenu } from '../protocols/displayable-marketing-menu';
import { DisplayableHeader } from '../protocols/displayable-header';
import { Type } from '@angular/core';
import { MarketingHeaderComponent } from '../../modules/display/components/menus/marketing-menu/building-blocks/menu-header/marketing-header.component';
import { MarketingFooterComponent } from '../../modules/display/components/menus/marketing-menu/building-blocks/menu-footer/marketing-footer.component';
import { DisplayableFooter } from '../protocols/displayable-footer';

export abstract class MarketingMenu extends Menu
  implements DisplayableFooter, DisplayableHeader, DisplayableMarketingMenu {

  public override template?: MarketingMenu;

  getMenuClass(): string {
    return 'marketing-menu';
  }

  isEmpty(): boolean {
    const empty = this.options?.rotationInterval?.size < 1;
    return empty || !this.hasItemsEnabledInRotationInterval();
  }

  hasItemsEnabledInRotationInterval(): boolean {
    const greaterThanZero = (val) => val > 0;
    return Array.from(this.options.rotationInterval || [])
      ?.map(([_, val]) => val)
      ?.filterNulls()
      ?.some(greaterThanZero) || false;
  }

  // Header interface

  getHeaderType(): Type<MarketingHeaderComponent> {
    return MarketingHeaderComponent;
  }

  getHeaderWrapperClass(): string {
    return '';
  }

  getShowHeader(): boolean {
    return false;
  }

  // Footer interface

  getFooterType(): Type<MarketingFooterComponent> {
    return MarketingFooterComponent;
  }

  getFooterWrapperClass(): string {
    return '';
  }

  getShowFooter(): boolean {
    return false;
  }

}
