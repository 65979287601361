import { MarketingAsset } from '../../../image/dto/marketing-asset';
import { SectionWithProducts } from '../section-with-products';
import { SectionType } from '../../../enum/dto/section-type.enum';
import { Menu } from '../../menu';
import { exists } from '../../../../functions/exists';
import { LocationPriceStream } from '../../../enum/shared/location-price-stream';

export class LoopingContentSection extends SectionWithProducts {

  public hideSection: boolean;
  public override image: MarketingAsset;
  public override secondaryImage: MarketingAsset;
  public override templateSection?: LoopingContentSection;

  protected override deserializeTemplateSection(): void {
    if (this.templateSection) this.templateSection.sectionType = SectionType.LoopingContentSection;
    this.templateSection = window.injector.Deserialize.instanceOf(LoopingContentSection, this.templateSection);
  }

  protected override deserializeAssets(): void {
    this.image = window.injector.Deserialize.instanceOf(MarketingAsset, this.image);
    this.secondaryImage = window.injector.Deserialize?.instanceOf(MarketingAsset, this.secondaryImage);
  }

  protected override getCombineWithTemplateDataShortCircuitedProperties(): string[] {
    const list = super.getCombineWithTemplateDataShortCircuitedProperties();
    const ignore = ['image'];
    return list?.filter(key => !ignore.includes(key)) || [];
  }

  protected override mergeAdvancedDataPropertiesFromTemplateSection(sectionTemplate: LoopingContentSection) {
    super.mergeAdvancedDataPropertiesFromTemplateSection(sectionTemplate);
    this.mergeImageFromTemplateSection(sectionTemplate);
  }

  protected mergeImageFromTemplateSection(sectionTemplate: LoopingContentSection) {
    if (!this.image && sectionTemplate.image) {
      this.image = sectionTemplate.image;
      this.image.sectionId = this.id;
    }
  }

  public isVisible(menu: Menu): boolean {
    // price stream doesn't matter for looping content sections, so use default
    const stream = LocationPriceStream.Default;
    const showContent = (this.showZeroStockItems || exists(this.getScopedVisibleVariants(menu, stream, false)?.length));
    return !this.hideSection && this.image?.isValid() && showContent;
  }

}
