import { Injectable } from '@angular/core';
import { BaseService } from '../../models/base/base-service';
import { BehaviorSubject, combineLatest, defer, iif, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { delay, distinctUntilChanged, filter, map, scan, shareReplay, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { IsMenuReadyServiceUtils } from '../../utils/is-menu-ready-service-utils';
import { DistinctUtils } from '../../utils/distinct.utils';
import { Menu } from '../../models/menu/menu';
import { ProductMenu } from '../../models/menu/product-menu';
import { exists } from '../../functions/exists';

@Injectable({ providedIn: 'root' })
export abstract class IsReadyService extends BaseService {

  static fontTimeOutTime: number = 10000; // 10 seconds in milliseconds
  static isMenuReadyTimeOut: number = 80000; // 80 seconds in milliseconds

  protected constructor() {
    super();
  }

  public abstract getApiResponded$(): Observable<boolean>;
  public abstract getMenu$(): Observable<Menu>;
  public abstract getIsReady$(): Observable<boolean>;

  protected started: boolean = false;
  protected isReadySub: Subscription;

  /**
   * The client will automatically fire isMenuReady after 80 seconds.
   * The API will time out the chrome instance after 90 seconds.
   */
  public timeout$ = timer(0, IsReadyService.isMenuReadyTimeOut).pipe(
    map(it => it > 0),
    distinctUntilChanged()
  );

  protected focusedContextId = new BehaviorSubject<string>(null);
  protected globalRenderedSignals = new BehaviorSubject<BehaviorSubject<boolean>[]>([]);
  protected contextRenderedSignals = new BehaviorSubject<Map<string, BehaviorSubject<boolean>[]>>(new Map());
  protected renderSignals$ = combineLatest([
    this.focusedContextId,
    this.globalRenderedSignals
  ]).pipe(
    switchMap(([contextId, renderedSignals]) => {
      const hasContextId$ = this.contextRenderedSignals.pipe(
        map(contextRenderedSignals => {
          const contextSignals = contextRenderedSignals?.get(contextId) || [];
          return [...renderedSignals, ...contextSignals];
        }),
      );
      const noContextId$ = this.contextRenderedSignals.pipe(
        map(contextRenderedSignals => {
          const allContextSignals = [...(contextRenderedSignals.values() || [])].flatMap(x => x) || [];
          return [...renderedSignals, ...allContextSignals];
        }),
      );
      return iif(() => exists(contextId), hasContextId$, noContextId$);
    }),
  );

  protected rendered$ = defer(() => this.getApiResponded$()).pipe(
    filter(apiResponded => apiResponded),
    switchMap(_ => this.renderSignals$),
    switchMap(renderSignalBundle => {
      return of(renderSignalBundle).pipe(
        // the rendered signals list is going to be spammed at the start, so wait until it plateaus before continuing
        delay(IsMenuReadyServiceUtils.renderSignalBundlerDebounceTime),
        switchMap(renderedSignals => combineLatest(renderedSignals)),
        map(renderedSignals => renderedSignals?.every(isRendered => isRendered)),
        // always fire off false down the pipeline as the signal bundle changes
        startWith(false),
      );
    }),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  protected calculatingOverflow = new BehaviorSubject<boolean>(false);
  protected calculatingOverflow$ = this.calculatingOverflow.pipe(distinctUntilChanged());

  protected lowerCase = (fontFaces: string[]) => fontFaces?.map(fontFace => fontFace?.toLowerCase());

  protected loadedFontFaces = new BehaviorSubject<string[]>([]);
  protected loadedFontFaces$ = this.loadedFontFaces.pipe(
    scan((acc, curr) => [...acc, ...curr], []),
    map(fontFaces => this.lowerCase(fontFaces)),
    distinctUntilChanged(DistinctUtils.distinctStrings)
  );

  protected menu$ = defer(() => this.getMenu$());

  public killIsMenuReadyService = new Subject<boolean>();

  protected neededFontFaces$ = this.menu$.pipe(
    map(m => {
      if (m instanceof ProductMenu) {
        // Remove default fonts for Chromium
        const defaultChromiumFonts = ['Times New Roman', 'Arial', 'Consolas'];
        return m.getFontFaceList().filter(ff => {
          return !defaultChromiumFonts.map(x => x.toLowerCase()).contains(ff.toLowerCase());
        });
      } else if (m instanceof Menu) {
        return [];
      } else {
        return null;
      }
    }),
    map(fontFaces => this.lowerCase(fontFaces)),
    distinctUntilChanged(DistinctUtils.distinctStrings)
  );

  protected fontsLoaded$ = combineLatest([
    timer(0, IsReadyService.fontTimeOutTime).pipe(map(it => it > 0)),
    this.loadedFontFaces$,
    this.neededFontFaces$
  ]).pipe(
    map(([timedOut, fonts, neededFonts]) => {
      if (timedOut) {
        return true;
      } else if (neededFonts) {
        return neededFonts?.every(needed => fonts?.includes(needed));
      } else {
        return false;
      }
    }),
    startWith(false),
    distinctUntilChanged()
  );

  protected _showSplashScreen = new BehaviorSubject<boolean>(true);
  public showSplashScreen$ = this._showSplashScreen.pipe(distinctUntilChanged());

  /**
   * Call from constructor of extending class
   */
  public startService(): void {
    if (this.started) return;
    window.isMenuReady = false;
    (document as any).fonts.onloadingdone = this.fontsLoaded.bind(this);
    // Listen for is menu ready
    this.isReadySub = defer(() => this.getIsReady$()).pipe(
      distinctUntilChanged(),
      tap(isMenuReady => {
        if (isMenuReady) this._showSplashScreen.next(false);
      }),
      delay(1000),
      takeUntil(this.killIsMenuReadyService),
    ).subscribe(isMenuReady => {
      if (isMenuReady) {
        window.isMenuReady = true;
        this.killIsMenuReadyService.next(true);
      }
    });
    this.started = true;
  }

  public killService(): void {
    if (this.started) {
      this.isReadySub?.unsubscribe();
      this.started = false;
    }
  }

  protected fontsLoaded(e): void {
    this.loadedFontFaces.next(e?.fontfaces?.map(font => font?.family).unique());
  }

  overflow(calculating: boolean) {
    this.calculatingOverflow.next(calculating);
  }

  globalSignalCreated(signal: BehaviorSubject<boolean>) {
    const renderedSignals = this.globalRenderedSignals.getValue();
    const alreadyHere = renderedSignals?.find(sig => sig === signal);
    if (!alreadyHere) {
      renderedSignals.push(signal);
      this.globalRenderedSignals.next(renderedSignals);
    }
  }

  signalWithInjectionContextCreated(signal: BehaviorSubject<boolean>, contextId: string) {
    const contextSignals = this.contextRenderedSignals.getValue();
    const contextRenderedSignals = contextSignals?.get(contextId) || [];
    const alreadyHere = contextRenderedSignals?.find(sig => sig === signal);
    if (!alreadyHere) {
      contextRenderedSignals.push(signal);
      contextSignals.set(contextId, contextRenderedSignals);
      this.contextRenderedSignals.next(contextSignals);
    }
  }

  globalSignalDestroyed(signal: BehaviorSubject<boolean>) {
    const renderedSignals = this.globalRenderedSignals.getValue();
    const renderSignalIndex = renderedSignals?.findIndex(sig => sig === signal);
    if (renderSignalIndex > -1) {
      renderedSignals.splice(renderSignalIndex, 1);
      this.globalRenderedSignals.next(renderedSignals);
    }
  }

  signalWithInjectionContextDestroyed(signal: BehaviorSubject<boolean>, contextId: string) {
    const contextSignals = this.contextRenderedSignals.getValue();
    const contextRenderedSignals = contextSignals?.get(contextId) || [];
    const renderSignalIndex = contextRenderedSignals?.findIndex(sig => sig === signal);
    if (renderSignalIndex > -1) {
      contextRenderedSignals.splice(renderSignalIndex, 1);
      contextSignals.set(contextId, contextRenderedSignals);
      this.contextRenderedSignals.next(contextSignals);
    }
  }

}
