<div
  #headerContainer
  [ngStyle]="{
    color: menu?.hydratedTheme?.themeFeatures?.titleTextColor ? menu?.menuOptions?.titleTextColor : ''
  }"
  [ngClass]="menu | deprecatedMarketingMenuBasicMethod : 'getHeaderWrapperClass'"
  [class.has-footer]="viewModel.hasFooter$ | async"
  [class.portrait-1-to-2]="viewModel.portrait1to2$ | async"
  [class.portrait-3-to-4]="viewModel.portrait3to4$ | async"
  [class.portrait-5-to-6]="viewModel.portrait5to6$ | async"
  [class.portrait-7-to-9]="viewModel.portrait7to9$ | async"
  [class.portrait-10-to-12]="viewModel.portrait10to12$ | async"
  [class.landscape-1-to-3]="viewModel.landscape1to3$ | async"
  [class.landscape-4]="viewModel.landscape4$ | async"
  [class.landscape-5-to-8]="viewModel.landscape5to8$ | async"
  [class.landscape-9-to-12]="viewModel.landscape9to12$ | async">
  <div class="header-container">
    <div class="main-header">
      <div
        class="main-title"
        *ngIf="!menu?.menuOptions?.hideTitle && !!menu?.configurationTitle"
        [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
        {{ menu?.configurationTitle }}
      </div>
      <div
        class="main-subtitle"
        *ngIf="!menu?.menuOptions?.hideTitle && !!menu?.subTitle"
        [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
        {{ menu?.subTitle }}
      </div>
    </div>
    <div class="total-price">
      <span>{{ menu | deprecatedMarketingMenuBasicMethod : 'getComboPriceInteger' }}</span>
      <span *ngIf="!menu?.menuOptions?.hidePriceDecimal">{{
        menu | deprecatedMarketingMenuBasicMethod : 'getComboPriceDecimal'
      }}</span>
    </div>
  </div>
</div>
