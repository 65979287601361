import { Asset } from './asset';
import { CachePolicy } from '../../enum/shared/cachable-image-policy.enum';
import { MediaUtils } from '../../../utils/media-utils';

export class BadgeAsset extends Asset {

  onDeserialize() {
    super.onDeserialize();
    // Always download badges as they load into the app
    this.getAsset(CachePolicy.Service, MediaUtils.DefaultImageSize, MediaUtils.DefaultCacheTimeInSeconds);
  }

}
