// @dynamic
export class ColorUtils {

  static readonly COLOR_WHITE = '#FFFFFF';
  static readonly COLOR_OFF_WHITE = '#FEFEFE';
  static readonly COLOR_BLACK = '#222222';
  static readonly DEFAULT_CARD_SECTION_NUMBER_BACKGROUND_COLOR = '#222222';

  static validateHexColor(hex: string): string {
    if (hex?.length > 0) {
      const firstChar = hex.substring(0, 1);
      if (firstChar !== '#') {
        hex = '#' + hex;
      }
    }
    hex = hex?.toLocaleUpperCase();
    const regexp = new RegExp(`^#([0-9A-F]{3}){1,2}$`);
    if (regexp.test(hex)) {
      return hex;
    } else {
      return '';
    }
  }

  static isDarkColor(hexColor: string): boolean {
    hexColor = ColorUtils.validateHexColor(hexColor);
    if (hexColor) {
      if (hexColor.length === 4) {
        // if 3 character hex code, convert to 6 character
        hexColor = hexColor.replace('#([0-9a-fA-F])([0-9a-fA-F])([0-9a-fA-F])', '#$1$1$2$2$3$3');
      }
      const color = hexColor.substring(1); // strip #
      const rgb = parseInt(color, 16);   // convert rrggbb to decimal
      // tslint:disable-next-line:no-bitwise
      const r = (rgb >> 16) & 0xff;  // extract red
      // tslint:disable-next-line:no-bitwise
      const g = (rgb >> 8) & 0xff;  // extract green
      // tslint:disable-next-line:no-bitwise
      const b = (rgb >> 0) & 0xff;  // extract blue
      const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
      return (luma < 128);
    } else {
      return false;
    }
  }

  static isWhite(hexColor: string): boolean {
    return hexColor === '#FFF' || hexColor === '#FFFFFF' || hexColor === '#ffffff' || hexColor === '#fff';
  }

  static isBlack(hexColor: string): boolean {
    return hexColor === '#000000' || hexColor === '#000';
  }

  static hexToRgb(hex: string): [number, number, number] {
    hex = ColorUtils.validateHexColor(hex);
    if (!hex) {
      return [0, 0, 0];
    }
    hex = hex.replace('#', '');
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    return [r, g, b];
  }

  static invertColor(hex: string, bwDefault: boolean = false): string {
    const [R, G, B] = ColorUtils.hexToRgb(hex);
    if (bwDefault) {
      // http://stackoverflow.com/a/3943023/112731
      return (R * 0.299 + G * 0.587 + B * 0.114) > 186 ? '#000000' : '#FFFFFF';
    }
    // invert color components
    const rr = (255 - R).toString(16);
    const gg = (255 - G).toString(16);
    const bb = (255 - B).toString(16);
    // pad each with zeros and return
    return '#' + ColorUtils.padZero(rr) + ColorUtils.padZero(gg) + ColorUtils.padZero(bb);
  }

  static padZero(str: string) {
    const zeros = new Array(2).join('0');
    return (zeros + str).slice(-2);
  }

  static hexToRGBAString(hex: string): string {
    const [R, G, B] = ColorUtils.hexToRgb(hex);
    return `rgba(${R}, ${G}, ${B})`;
  }

  static rgbaGetAlphaOrNull(rgba: string): number | null {
    const isHex = /^([0-9A-F]{6})$/.test(rgba?.replace('#', ''));
    if (isHex) rgba = ColorUtils.hexToRGBAString(rgba);
    const alpha = Number.parseFloat(/rgba\([\s\d]*,[\s\d]*,[\s\d]*,([\s\d.]*)\)/.exec(rgba)?.[1]);
    return Number.isFinite(alpha) ? alpha : null;
  }

  static replaceAlphaInRGBA(rgba: string, newAlpha: number): string {
    if (!Number.isFinite(newAlpha)) return rgba;
    const isHex = /^([0-9A-F]{6})$/.test(rgba?.replace('#', ''));
    if (isHex) rgba = ColorUtils.hexToRGBAString(rgba);
    const rgbaRegex = /rgba\(([\s\d]*),([\s\d]*),([\s\d]*).*\)/;
    return rgba?.replace(rgbaRegex, `rgba($1,$2,$3,${newAlpha})`)?.replace(/\s/g, '');
  }

  static darken(rgba: string, amount: number): string {
    const [R, G, B, A] = rgba?.match(/(\d|\.)+/g)?.map(Number) || [null, null, null, null];
    if ((R >= 0) && (G >= 0) && (B >= 0)) {
      const newR = Math.max(0, R - amount);
      const newG = Math.max(0, G - amount);
      const newB = Math.max(0, B - amount);
      return `rgba(${newR},${newG},${newB}` + (A >= 0 ? `,${A}` : '') + ')';
    }
  }

  static lighten(rgba: string, amount: number): string {
    const [R, G, B, A] = rgba?.match(/(\d|\.)+/g)?.map(Number) || [null, null, null, null];
    if ((R >= 0) && (G >= 0) && (B >= 0)) {
      const newR = Math.min(255, R + amount);
      const newG = Math.min(255, G + amount);
      const newB = Math.min(255, B + amount);
      return `rgba(${newR},${newG},${newB}` + (A >= 0 ? `,${A}` : '') + ')';
    }
  }

}
