<div
  class="footer-container"
  #footerContainer
  [ngClass]="menu | productMenuBasicMethod : 'getFooterWrapperClass'"
  [style.display]="(noBadges$ | async) ? 'block' : null"
  [style.color]="menu | productMenuBasicMethod : 'getSectionHeaderTextColor' : lastProductSection">
  <div fxLayout="row" fxFlex>
    <div fxFlex class="side-of-classification-board" fxLayout="column" fxLayoutAlign="stretch">
      <div
        [style.border-color]="lastProductSection?.metadata.productsHeaderBackgroundColor"
        [style.background-color]="lastProductSection?.metadata.productsContainerBackgroundColor"
        fxFlex="60"
        class="top-of-side left">
        <div
          class="gap-filler"
          [style.background-color]="lastProductSection?.metadata.productsContainerBackgroundColor"></div>
      </div>
      <div fxFlex="40" class="bottom-of-side"></div>
    </div>
    <div
      *ngIf="(menu | productMenuBasicMethod : 'getUniqueClassificationList')?.length > 0"
      [style.background-color]="lastProductSection?.metadata?.productsHeaderBackgroundColor"
      class="footer-classification-board">
      <div
        *ngFor="
          let classification of menu | productMenuBasicMethod : 'getUniqueClassificationList';
          trackBy: trackByClassification
        "
        class="classification-mapping">
        <div [ngClass]="classification | lowercase">
          {{ classification | getClassificationSymbol }}
        </div>
        <div>=</div>
        <div class="classification-name">
          {{ classification | uppercase }}
        </div>
      </div>
    </div>
    <div fxFlex class="side-of-classification-board" fxLayout="column" fxLayoutAlign="stretch">
      <div
        [style.border-color]="lastProductSection?.metadata.productsHeaderBackgroundColor"
        [style.background-color]="lastProductSection?.metadata.productsContainerBackgroundColor"
        fxFlex="60"
        class="top-of-side right">
        <div
          class="gap-filler"
          [style.background-color]="lastProductSection?.metadata.productsContainerBackgroundColor"></div>
      </div>
      <div fxFlex="40" class="bottom-of-side"></div>
    </div>
  </div>
</div>
