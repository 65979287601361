import { Component } from '@angular/core';
import { ProductMenuHeaderComponent } from '../product-menu-header.component';

@Component({
  selector: 'app-menu-header-plain-jane',
  templateUrl: './menu-header-plain-jane.component.html',
  styleUrls: ['./menu-header-plain-jane.component.scss'],
  providers: [{ provide: ProductMenuHeaderComponent, useExisting: MenuHeaderPlainJaneComponent }]
})
export class MenuHeaderPlainJaneComponent extends ProductMenuHeaderComponent {
}
