import { PostCardStack } from './post-card-stack';
import { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { PrintCardBackgroundComponent } from '../../../../../modules/print-cards/components/print-cards/backgrounds/print-card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/print-card-content.component';
import { AgentOrangePostCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/print-cards/backgrounds/post-cards-4x6/agent-orange-post-card-stack-background/agent-orange-post-card-stack-background.component';
import { AgentOrangePostCardStackContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/post-cards-4x6/agent-orange-post-card-stack-content/agent-orange-post-card-stack-content.component';

export class AgentOrangePostCardStack extends PostCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<PrintCardBackgroundComponent> | null {
    return AgentOrangePostCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return AgentOrangePostCardStackContentComponent;
  }

}
