import { PrintLabelContentViewModel } from '../../print-label-content-view-model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AssetUtils } from '../../../../../../../utils/asset-utils';
import { StrainTypeUtils } from '../../../../../../../utils/strain-type-utils';
import { StrainType } from '../../../../../../../models/enum/dto/strain-classification.enum';

@Injectable()
export class BaseInventoryLabelStackContentViewModel extends PrintLabelContentViewModel {

  public readonly labelAssetUrl$ = this.labelData$.pipe(
    map(labelData => AssetUtils.BaseInventoryLabelProductTypeUrls(labelData))
  );

  public readonly strainTypeColor$ = this.labelData$.pipe(
    map(labelData => this.getInventoryLabelStrainTypeColor(labelData?.getStrainType()))
  );

  public readonly strainTypeSymbol$ = this.labelData$.pipe(
    map(labelData => StrainTypeUtils.getSymbol(labelData?.getStrainType()))
  );

  private getInventoryLabelStrainTypeColor(strainType: StrainType): string {
    switch (strainType) {
      case StrainType.Indica:
      case StrainType.IndicaDominant: return '#9E42FB';
      case StrainType.Sativa:
      case StrainType.SativaDominant: return '#DF7902';
      case StrainType.Hybrid:         return '#05A76D';
      case StrainType.Blend:          return '#057A9F';
      case StrainType.CBD:            return '#AC1F49';
      default:                        return '#000000';
    }
  }

}
