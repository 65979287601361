import { Pipe, PipeTransform } from '@angular/core';
import { exists } from '../../../../../functions/exists';

@Pipe({
  name: 'modifyFinalPriceString'
})
export class ModifyFinalPriceStringPipe implements PipeTransform {

  transform(price: string, modifier: (p: string) => string): string {
    return exists(modifier) ? modifier(price) : price;
  }

}
