import { CachePolicy } from '../../enum/shared/cachable-image-policy.enum';
import { BsError } from '../../shared/bs-error';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { AssetUrl } from './asset-url';

export class PrefetchMediaUrl extends AssetUrl {

  protected override downloadAndCacheBlob(
    cachePolicy: CachePolicy = CachePolicy.Service,
    cacheForNSeconds: number,
    retryCount: number = 0,
    maxRetryCount: number = 3
  ) {
    this.loading.next(true);
    if (this.handleSafariWebmUrl()) return;
    window?.injector?.imageApi?.GetBlobFromUrl(this, true)?.subscribe({
      next: (blob) => this.readResponseFromBlobDownload(blob),
      error: (err: BsError) => this.downloadFailed(cachePolicy, cacheForNSeconds, retryCount, maxRetryCount)
    });
  }

  readResponseFromBlobDownload(event: HttpEvent<Blob>): void {
    if (event.type === HttpEventType.DownloadProgress) {
      const e = event as { type: HttpEventType.DownloadProgress; loaded: number; total: number };
      window?.injector?.prefetchMediaService?.connectToDownloadedBytes(e);
    } else if (event instanceof HttpResponse) {
      this.readBlobDataIntoUrl(event, CachePolicy.Service);
    }
  }

}
