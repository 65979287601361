<div>
  <div class="price-container" [style.margin-bottom.rem]="0.25">
    <div
      class="price-label"
      [style.background-color]="viewModel.cardAccentColor$ | async"
      [style.color]="
        viewModel.cardStack$
          | async
          | textColorBasedOnSectionHeaderBackgroundColor: (viewModel.cardAccentColor$ | async)
      ">
      PRE TAX
    </div>
    <app-column-primary-price
      [menu]="viewModel.cardStackMenu$ | async"
      [section]="viewModel.cardStack$ | async"
      [colVm]="viewModel.spoofedColViewModel$ | async"
      [rowVm]="viewModel.cardData$ | async"
      [locConfig]="viewModel.locationConfig$ | async"
      [locationId]="viewModel.locationId$ | async"
      [productStylingVm]="viewModel"
      [overridePriceStream]="Types.LocationPriceStream.Default">
    </app-column-primary-price>
  </div>
  <div class="price-container">
    <div
      class="price-label"
      [style.background-color]="viewModel.cardAccentColor$ | async"
      [style.color]="
        viewModel.cardStack$
          | async
          | textColorBasedOnSectionHeaderBackgroundColor: (viewModel.cardAccentColor$ | async)
      ">
      TAX IN
    </div>
    <app-column-primary-price
      [menu]="viewModel.cardStackMenu$ | async"
      [section]="viewModel.cardStack$ | async"
      [colVm]="viewModel.spoofedColViewModel$ | async"
      [rowVm]="viewModel.cardData$ | async"
      [locConfig]="viewModel.locationConfig$ | async"
      [locationId]="viewModel.locationId$ | async"
      [productStylingVm]="viewModel"
      [overridePriceStream]="viewModel.priceStreamOverride$ | async">
    </app-column-primary-price>
  </div>
</div>
