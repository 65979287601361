import { Variant } from '../../../product/dto/variant';
import { Asset } from '../../../image/dto/asset';
import { UniquelyIdentifiable } from '../../../protocols/uniquely-identifiable';

export class VariantAndAsset implements UniquelyIdentifiable {

  public variant: Variant;
  public asset: Asset;

  constructor(v: Variant, a: Asset) {
    this.variant = v;
    this.asset = a;
  }

  getMediaIdentifier(): string {
    return (this.variant) ? this.variant.id : this.asset.fileName;
  }

  getUniqueIdentifier(): string {
    if (this.variant) {
      return `${this.variant?.uniqueIdentifier}-${this.asset?.getUniqueIdentifier()}`;
    } else {
      return this.asset?.getUniqueIdentifier();
    }
  }

}
