import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class PrintCardJigViewModel {

  private readonly _height = new BehaviorSubject<number>(0);
  public readonly height$ = this._height.pipe(distinctUntilChanged());
  connectToHeight = (height: number) => this._height.next(height);

  private readonly _width = new BehaviorSubject<number>(0);
  public readonly width$ = this._width.pipe(distinctUntilChanged());
  connectToWidth = (width: number) => this._width.next(width);

}
