<div
  *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.Label"
  class="product-column-general product-label"
  [style.visibility]="section?.collapseContent || rowVm.collapseContent ? 'collapse' : ''"
  [style.text-decoration]="productStylingVm | forcedColumnTextDecoration: colVm : locConfig | async"
  [style.margin-right]="menu | productMenuBasicMethod: 'getSectionBorderMarginSize'">
  <app-label-inflator
    #labelInflator
    *ngIf="!calculationMode && productStylingVm.showLabelInLabelColumn$ | async"
    class="product-label-container"
    [columnLabel]="true"
    [labelComponentInterface]="rowVm"
    [checkForPriceChange]="checkForPriceChange">
  </app-label-inflator>
</div>
