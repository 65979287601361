import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ColumnComponent } from '../column.component';

@Component({
  selector: 'app-column-size',
  templateUrl: './column-size.component.html',
  styleUrls: ['./column-size.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ColumnSizeComponent extends ColumnComponent {
}
