import { Component } from '@angular/core';
import { ProductMenuFooterComponent } from '../../product-menu-footer.component';

@Component({
  selector: 'app-plantlife-non-smokable-empty-footer',
  templateUrl: './plantlife-non-smokable-empty-footer.component.html',
  styleUrls: ['./plantlife-non-smokable-empty-footer.component.scss'],
})
export class PlantlifeNonSmokableEmptyFooterComponent extends ProductMenuFooterComponent {
}
