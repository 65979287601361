<div
  *ngIf="colVm?.columnType === terpeneColumnType"
  #terpeneHeader
  appScaleDownText
  [scaleEnabled]="!(menu | isPrintMenu)"
  [style.color]="
    ignoreUserSetTextColorAndAutoToLightOrDarkTextColor
      ? (section | textColorBasedOnSectionHeaderBackgroundColor)
      : (menu | productMenuBasicMethod: 'getSectionHeaderTextColor' : section)
  "
  [scaleDownLinkedKey]="section?.id + Types.TextSizeLink.TypeTerpene"
  class="section-header-general section-header-terpene">
  <!-- section-header-${terpene-kebab-cased} is added in component -->
  {{ (menu | productMenuBasicMethod: 'getTerpeneColumnTitle' : colVm) + (headerTUOMString$ | async) }}
</div>
