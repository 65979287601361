<div class="bottom-cut-lines" [class.extended]="cardStack | isExtendedCutLine">
  <div
    class="left-sliver"
    [style.width.px]="cardStack | withContentBleed : false"
    [style.height]="cardStack | calculateExtendedCutLineLengthString : (cardStack | withContentBleed : false)"
    [style.bottom.px]="cardStack | cutLineExtendedLengthInPx : (cardStack | withContentBleed : false)"></div>
  <ng-template ngFor [ngForOf]="cardStack | iterableCardStackColumns : nCardsOnPage" let-last="last">
    <div class="spacer" [style.width.px]="cardStack | cardContentWidth"></div>
    <div *ngIf="!last" class="dividing-sliver-container" [style.width.px]="cardStack | withContentBleed : false : 2">
      <div
        class="dividing-sliver"
        [style.height]="cardStack | calculateExtendedCutLineLengthString : (cardStack | withContentBleed : false)"
        [style.bottom.px]="cardStack | cutLineExtendedLengthInPx : (cardStack | withContentBleed : false)"
        [style.border-right]="(cardStack | withContentBleed : false) === 0 ? 'unset' : null"></div>
      <div
        class="dividing-sliver-horizontal-left-line"
        [style.width.px]="cardStack | cutLineExtendedLengthInPx"
        [style.left.px]="-(cardStack | cutLineExtendedLengthInPx)"
        [style.top.px]="-(cardStack | withContentBleed : false)"></div>
      <div
        class="dividing-sliver-horizontal-middle-line"
        [style.width.px]="cardStack | withContentBleed : false : 2"
        [style.left.px]="0"
        [style.top.px]="-(cardStack | withContentBleed : false)"></div>
      <div
        class="dividing-sliver-horizontal-right-line"
        [style.width.px]="cardStack | cutLineExtendedLengthInPx"
        [style.left.px]="cardStack | withContentBleed : false : 2"
        [style.top.px]="-(cardStack | withContentBleed : false)"></div>
    </div>
  </ng-template>
  <div
    class="right-sliver"
    [style.width.px]="cardStack | withContentBleed : false"
    [style.height]="cardStack | calculateExtendedCutLineLengthString : (cardStack | withContentBleed : false)"
    [style.bottom.px]="cardStack | cutLineExtendedLengthInPx : (cardStack | withContentBleed : false)"></div>
</div>
