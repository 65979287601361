<div
  class="footer-container"
  #footerContainer
  [ngClass]="menu | productMenuBasicMethod : 'getFooterWrapperClass'"
  [style.display]="(noBadgesAndClassifications$ | async) ? 'block' : null"
  [style.color]="menu?.hydratedTheme?.themeFeatures?.titleTextColor ? menu?.menuOptions?.titleTextColor : ''">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div *ngIf="(menuBadges$ | async)?.length > 0" class="footer-classification-board">
      <div
        *ngFor="let badge of menuBadges$ | async; trackBy: trackByBadgeUniqueIdentifier"
        class="classification-mapping">
        <img
          appImage
          class="badge-in-legend"
          [asset]="badge?.image"
          [reset]="reset"
          [style.object-fit]="'contain'"
          alt="badge image"
          src="" />
        <div class="classification-name">
          {{ badge?.name }}
        </div>
      </div>
    </div>
  </div>
</div>
