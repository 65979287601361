<div
  *ngIf="colVm?.columnType === Types.SectionColumnProductInfoType.Producer"
  appScaleDownText
  [scaleEnabled]="!(menu | isPrintMenu)"
  [style.color]="
    ignoreUserSetTextColorAndAutoToLightOrDarkTextColor
      ? (section | textColorBasedOnSectionHeaderBackgroundColor)
      : (menu | productMenuBasicMethod: 'getSectionHeaderTextColor' : section)
  "
  class="section-header-general section-header-producer">
  Producer
</div>
