import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(input: string, target: string, replacement: string): string {
    return input?.replace(new RegExp(target, 'g'), replacement);
  }

}
