import { Pipe, PipeTransform } from '@angular/core';
import type { Asset } from '../../../models/image/dto/asset';

@Pipe({
  name: 'assetIsVideo'
})
export class AssetIsVideoPipe implements PipeTransform {

  transform(asset: Asset): Asset | null {
    return asset?.isVideo() ? asset : null;
  }

}
