import { LabelViewModel } from '../label-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FlagLabelViewModel extends LabelViewModel {

  private _flagHeight = new BehaviorSubject<number>(0);
  public flagHeight$ = this._flagHeight.asObservable();
  public connectToFlagHeight = (height: number) => this._flagHeight.next(height);

  public triangleHeight$ = this.flagHeight$.pipe(
    map(height => Math.ceil(height / 2) + 1)
  );

  public flagTriangleHeight$ = combineLatest([
    this.triangleHeight$,
    this.labelBackgroundColor$
  ]).pipe(
    map(([triangleHeight, color]) => `${triangleHeight}px solid ${color}`)
  );

  public triangleWidth$ = this.flagHeight$.pipe(
    map(height => 0.35 * height)
  );
  public flagTriangleWidth$ = this.triangleWidth$.pipe(
    map(width => `${width}px solid transparent`),
  );

}
