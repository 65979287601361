import { ApiClient } from './api-client';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { Display } from '../models/display/dto/display';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { LoggingService } from '../services/logging-service';
import { catchError } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DisplayAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  // Variables

  public serviceName = 'Display';

  // Display

  public GetDisplay(
    id: string,
    includeProducts: boolean,
    ignoreLastSession: boolean,
    lastModified?: number
  ): Observable<Display> {
    const url = Endpoints.GetDisplay(id, includeProducts, ignoreLastSession, lastModified);
    return this.apiClient.recursiveGetObject(Display, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetDisplay', err));
        return throwError(err);
      })
    );
  }

}
