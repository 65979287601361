import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { HeaderColumnComponent } from '../header-column.component';

@Component({
  selector: 'app-header-column-product-title',
  templateUrl: './header-column-product-title.component.html',
  styleUrls: ['./header-column-product-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class HeaderColumnProductTitleComponent extends HeaderColumnComponent {
}
