import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RenderCardStackComponent } from './components/render-card-stack/render-card-stack.component';
import { RenderPrintCardPreviewComponent } from './components/render-print-card-preview/render-print-card-preview.component';
import { RenderPrintCardLiveViewComponent } from './components/render-print-card-live-view/render-print-card-live-view.component';

const routes: Routes = [
  {
    path: '',
    component: RenderCardStackComponent,
  },
  {
    path: ':locationId/:printCardMenuId',
    component: RenderCardStackComponent,
    data: {
      title: 'Print Cards'
    }
  },
  {
    path: 'live-view',
    component: RenderPrintCardLiveViewComponent,
    data: {
      title: 'Live View of Print Card'
    }
  },
  {
    path: 'preview/:locationId/:printCardMenuId',
    component: RenderPrintCardPreviewComponent,
    data: {
      title: 'Preview Print Cards'
    }
  },
  {
    path: 'template',
    children: [
      {
        path: ':locationId/:printCardMenuTemplateId',
        component: RenderCardStackComponent,
        data: {
          title: 'Template for Print Cards'
        }
      },
      {
        path: 'preview/:locationId/:printCardMenuTemplateId',
        component: RenderPrintCardPreviewComponent,
        data: {
          title: 'Preview Print Card Template'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrintCardsRoutingModule {
}
