import { CopyableProductSection } from '../copyable-product-section';
import { Variant } from '../../../product/dto/variant';
import { CardType } from '../../../../modules/display/components/menus/marketing-menu/building-blocks/menu-cards/card-type.enum';
import { Menu } from '../../menu';
import { CompanyConfiguration } from '../../../company/dto/company-configuration';
import { LocationConfiguration } from '../../../company/dto/location-configuration';

export class FeaturedCategorySection extends CopyableProductSection {

  public cardType: CardType;

  public copyWithout(
    menu: Menu,
    companyConfig: CompanyConfiguration,
    locationConfig: LocationConfiguration,
    variants: Variant[]
  ): CopyableProductSection {
    return this.copyWithoutHelper(FeaturedCategorySection, menu, companyConfig, locationConfig, variants);
  }

}
