import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/menu';
import { exists } from '../../../functions/exists';

@Pipe({
  name: 'backgroundBodyColor'
})
export class BackgroundBodyColorPipe implements PipeTransform {

  transform(menu: Menu): string|null {
    return exists(menu?.hydratedTheme?.themeFeatures?.bodyBackgroundColor)
      ? menu?.menuOptions?.bodyBackgroundColor
      : null;
  }

}
