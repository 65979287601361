import { Asset } from './asset';
import { AssetLibraryType } from '../../enum/dto/asset-library-type.enum';
import { CachePolicy } from '../../enum/shared/cachable-image-policy.enum';
import { MediaUtils } from '../../../utils/media-utils';

export class VariantAsset extends Asset {

  public assetType: AssetLibraryType;
  public isClientAsset: boolean;
  public isDisabled: boolean;

  public onDeserialize() {
    super.onDeserialize();
    // Pre-fetch the medium asset so it's ready when the card appears in the UI
    this.preFetchAssets();
  }

  public preFetchAssets() {
    this.getAsset(CachePolicy.Service, MediaUtils.DefaultImageSize, MediaUtils.DefaultCacheTimeInSeconds);
  }

}
