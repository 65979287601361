import { Component } from '@angular/core';
import { SpotlightMenuItemComponent } from '../spotlight-menu-item.component';
import { ProductSectionItemViewModel } from '../../../../product-menu/building-blocks/menu-item/product-section-item/product-section-item-view-model';

@Component({
  selector: 'app-spotlight-product-menu-item',
  templateUrl: './spotlight-product-menu-item.component.html',
  styleUrls: ['./spotlight-product-menu-item.component.scss'],
  providers: [ProductSectionItemViewModel]
})
export class SpotlightProductMenuItemComponent extends SpotlightMenuItemComponent {

  constructor(
    public viewModel: ProductSectionItemViewModel
  ) {
    super(viewModel);
  }

}
