import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appInflatorContainer]'
})
export class InflatorContainerDirective {

  constructor(public viewContainerRef: ViewContainerRef) {
  }

}
