<div #headerContainer [ngClass]="menu | productMenuBasicMethod : 'getHeaderWrapperClass'">
  <div
    class="header-container"
    [ngStyle]="{
      'background-size': 'cover',
      'background-image': (image$ | async)
    }">
    <img
      appImage
      *ngIf="menu | productMenuBasicMethod : 'showCompanyLogo'"
      class="company-logo"
      [style.height.rem]="6.25"
      [style.zoom]="menu?.menuOptions?.titleZoom || 1.0"
      [style.margin]="menu | productMenuBasicMethod : 'getHeaderLogoMargin'"
      [asset]="menu?.companyLogo"
      [reset]="reset"
      alt="company logo"
      src="" />
    <div
      class="main-header"
      [hidden]="menu?.menuOptions?.hideTitle || !menu?.configurationTitle"
      [style.color]="menu?.hydratedTheme?.themeFeatures?.titleTextColor ? menu?.menuOptions?.titleTextColor : ''"
      [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
      {{ menu?.configurationTitle }}
    </div>
    <app-plantlife-non-smokable-footer
      [style.display]="'block'"
      [style.width.%]="100"
      [hideSides]="true"
      [removeBottomPadding]="true"
      [menu]="menu"
      [reset]="reset"
      [includeClassifications]="true"
      [includeBadges]="false">
    </app-plantlife-non-smokable-footer>
  </div>
</div>
