@charset "UTF-8";

/* src/app/modules/display/components/menus/product-menu/building-blocks/menu-footer/double-dutch-footer/double-dutch-empty-footer/double-dutch-empty-footer.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.print-menu.double-dutch-theme {
  page: double-dutch-theme-pages;
}
@page double-dutch-theme-pages {
  margin: 0.25in;
}
.double-dutch-theme .background-asset {
  border-radius: 0.7275rem !important;
}
.double-dutch-theme .header-container {
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 !important;
  gap: 0.5rem;
}
.double-dutch-theme .header-container .main-header {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-size: 2.5rem;
  text-align: center;
  max-width: 100%;
}
.double-dutch-theme .header-container .company-logo {
  height: 8.18rem !important;
  max-height: 8.18rem;
}
.double-dutch-theme .section-column-title {
  color: #000000;
}
.double-dutch-theme .products-container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 0.7275rem;
  color: #ebdcaf;
  overflow: hidden;
  border-left: 3.5px solid;
  border-right: 3.5px solid;
  border-top: 3.5px solid;
}
.double-dutch-theme .sections-container {
  margin-left: 0;
  margin-right: 0;
}
.double-dutch-theme .title-section {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-size: 3.75rem;
}
.double-dutch-theme .section-column {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-size: 1.36rem;
}
.double-dutch-theme .section-column.section-column-classification {
  flex: 1;
}
.double-dutch-theme .section-container {
  margin-left: 2rem;
  margin-right: 2rem;
  flex: 1;
  overflow: unset;
  margin-top: 1.25rem;
  border-radius: 0.7275rem 0.7275rem 0 0;
}
.double-dutch-theme .section-header-title {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  font-size: 1.5rem;
  letter-spacing: 0.0625rem;
  color: #000000;
  text-align: center;
  padding: 0.25rem;
}
.double-dutch-theme .section-header-container {
  flex-direction: row;
  height: 2.36rem;
  background-color: #ebdcaf;
  padding: 0.75rem 0;
  color: #000000;
}
.double-dutch-theme .split-product-header-inflator.odd .section-header-container {
  border-radius: 0.7275rem 0 0 0;
}
.double-dutch-theme .split-product-header-inflator .section-header-container {
  border-radius: 0 0.7275rem 0 0;
  position: relative;
}
.double-dutch-theme .split-product-header-inflator .gap-filler {
  position: absolute;
  margin-top: 2rem;
  margin-left: 0;
  background-color: #ebdcaf;
  height: 2rem;
  left: 0;
  right: 0;
}
.double-dutch-theme .section-title {
  display: block;
  text-transform: uppercase;
  max-width: 12rem;
  margin-left: 0.35rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;
  min-width: 0;
}
.double-dutch-theme .section-header-general {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-size: 1.25rem;
  color: #000000;
}
.double-dutch-theme .product-column-general {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}
.double-dutch-theme .product-container {
  align-items: center;
  height: 4.72rem;
  color: #000000;
}
.double-dutch-theme .product-container.odd {
  border-right: 3.5px solid;
}
.double-dutch-theme .product-container.last {
  border-bottom: unset;
}
.double-dutch-theme .product-strain-type {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  font-size: 1rem;
}
.double-dutch-theme .product-strain-type.s,
.double-dutch-theme .product-strain-type.sativa {
  color: #b52a23;
}
.double-dutch-theme .product-strain-type.h,
.double-dutch-theme .product-strain-type.hybrid {
  color: #9594d2;
}
.double-dutch-theme .product-strain-type.i,
.double-dutch-theme .product-strain-type.indica {
  color: #006838;
}
.double-dutch-theme .product-strain-type.b,
.double-dutch-theme .product-strain-type.blend {
  color: #edae44;
}
.double-dutch-theme .product-strain-type.c,
.double-dutch-theme .product-strain-type.cbd {
  color: #0075c9;
}
.double-dutch-theme .product-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.18rem;
  height: 100%;
  padding-left: 0.25rem;
}
.double-dutch-theme .product-title {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-size: 1rem;
}
.double-dutch-theme .product-badge {
  display: flex;
  gap: 0.5rem;
}
.double-dutch-theme .badge {
  height: 1.2rem;
}
.double-dutch-theme .product-label {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-size: 0.75rem;
  margin-left: 0;
  border-radius: 0.2rem;
  padding: 0.125rem 0.4rem;
  align-self: end;
}
.double-dutch-theme .subtitle-flex-container {
  gap: 0.75rem;
}
.double-dutch-theme .product-subtitle {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  font-size: 1rem;
}
.double-dutch-theme .product-low-amount {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}
.double-dutch-theme .product-size {
  font-size: 1rem;
}
.double-dutch-theme .product-column {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-size: 1rem;
}
.double-dutch-theme .base-product-strain-type {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-size: 1rem;
}
.double-dutch-theme .product-image-column {
  display: flex;
  justify-content: flex-end;
  overflow-x: visible;
  align-items: center;
  max-height: 100%;
}
.double-dutch-theme .product-image {
  height: 90%;
  width: inherit;
}
.double-dutch-theme .image-section {
  margin-top: 0;
}
.double-dutch-theme .classification-mapping {
  height: 1.82rem;
  align-items: center;
}
.double-dutch-theme .empty-container {
  margin-left: 2rem;
  margin-right: 2rem;
  background-color: #ffffff;
  color: #ebdcaf;
  position: relative;
  border-right: 3.5px solid;
  border-left: 3.5px solid;
}
.double-dutch-theme .empty-container .sativa-products {
  flex: 1 1 100%;
  max-width: 50%;
  border-right: 3.5px solid;
}
.double-dutch-theme .empty-container .hybrid-products {
  display: none;
}
.double-dutch-theme .empty-container .indica-products {
  flex: 1 1 100%;
  max-width: 50%;
  border-right: 3.5px solid rgba(255, 255, 255, 0);
}
.double-dutch-theme .empty-container .gap-filler {
  height: 1rem;
  position: absolute;
  z-index: -1;
  margin-top: -0.25rem;
  left: 0;
  right: 0;
}
.double-dutch-theme .force-bold-style * {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
}
.footer-container {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-size: 1rem;
  text-transform: uppercase;
  display: flex;
  align-content: flex-start;
  padding: 0;
  margin-left: 2rem;
  margin-right: 2rem;
  flex-wrap: wrap;
  height: 2.5rem;
  margin-top: -1px;
}
.footer-container .side-of-classification-board {
  color: #ebdcaf;
}
.footer-container .side-of-classification-board .top-of-side {
  border-bottom: 3.5px solid;
  background-color: #ffffff;
  position: relative;
}
.footer-container .side-of-classification-board .top-of-side.left {
  border-left: 3.5px solid;
  border-radius: 0 0 0 0.7275rem;
}
.footer-container .side-of-classification-board .top-of-side.left .gap-filler {
  height: 1rem;
  width: 1rem;
  position: absolute;
  margin-right: -1rem;
  right: 0;
  z-index: -1;
  background-color: #ffffff;
}
.footer-container .side-of-classification-board .top-of-side.right {
  border-right: 3.5px solid;
  border-radius: 0 0 0.7275rem 0;
}
.footer-container .side-of-classification-board .top-of-side.right .gap-filler {
  height: 1rem;
  width: 1rem;
  position: absolute;
  margin-left: -1rem;
  z-index: -1;
  background-color: #ffffff;
}
.footer-container .classification-name {
  align-content: center;
}
.footer-container .footer-classification-board {
  font-family: oswald, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-size: 1.25rem;
  display: flex;
  flex-direction: row;
  background-color: #ebdcaf;
  gap: 0.8rem;
  padding: 0.1rem 0.5rem;
  border-radius: 0.7275rem;
}
.footer-container .footer-classification-board .indica {
  color: #006838;
}
.footer-container .footer-classification-board .sativa {
  color: #b52a23;
}
.footer-container .footer-classification-board .hybrid {
  color: #9594d2;
}
.footer-container .footer-classification-board .blend {
  color: #edae44;
}
.footer-container .footer-classification-board .cbd {
  color: #0075c9;
}
.footer-container .footer-classification-board .classification-mapping {
  display: flex;
  flex-direction: row;
  gap: 0.35rem;
}
.double-dutch-empty-footer .side-of-classification-board {
  height: 2.5rem;
}
.double-dutch-empty-footer .top-of-side.left {
  border-right: 3.5px solid;
}
/*# sourceMappingURL=double-dutch-empty-footer.component.css.map */
