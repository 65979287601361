import { CardData } from './card-data';
import { EmptyCardStack } from '../menu/section/card-stacks/empty-card-stack';

export class EmptyCardData extends CardData {

  constructor() {
    super();
    this.section = new EmptyCardStack();
  }

}
