<ng-container *ngIf="viewModel.isEdiblesCard$ | async; else regularCard">
  <app-label-inflator
    #labelCalculator
    hidden
    class="label-calculator"
    [calculateVirtualLabel]="true"
    [columnLabel]="false"
    [labelComponentInterface]="cardData"
    [reset]="false">
  </app-label-inflator>
  <div class="main-content">
    <div
      class="product-card-variant-type"
      appPrintCardScaleDownText
      [maxHeightInPx]="32"
      [maxWidthInPx]="248"
      [transparentWhileScaling]="viewModel.viewingInLiveView$ | async"
      [style.color]="viewModel.headerTextColor$ | async">
      {{ viewModel.topOfCardVariantType$ | async }}
    </div>
    <div
      *ngIf="cardData | sectionRowViewModelBasicMethod: 'getBrand' as brand"
      appPrintCardScaleDownText
      [maxHeightInPx]="32"
      [maxWidthInPx]="248"
      class="product-card-brand"
      [style.color]="viewModel.bodyTextColor$ | async">
      {{ brand }}
    </div>
    <div class="product-card-product-name">
      {{ cardData | sectionRowViewModelBasicMethod: 'getRowTitle' | trimSafely }}
    </div>
    <div class="product-card-flavours">
      <div class="product-card-number-of-flavours" [style.color]="viewModel.bodyTextColor$ | async">
        <div class="product-card-flavor-text">{{ viewModel.numberOfFlavours$ | async }} Flavours</div>
        <hr class="separator" />
      </div>
      <div
        *ngFor="let variant of viewModel.visibleVariantFlavours$ | async"
        class="product-card-individual-product-info">
        <div
          class="product-card-variant-name"
          appPrintCardScaleDownText
          [maxHeightInPx]="14"
          [maxWidthInPx]="248"
          [transparentWhileScaling]="viewModel.viewingInLiveView$ | async"
          [style.color]="viewModel.bodyTextColor$ | async">
          {{ cardData | sectionRowViewModelBasicMethod: 'getRowTitleFor' : variant | trimSafely }}
        </div>
        <div
          *ngIf="
            !(cardData | sectionRowViewModelBasicMethod: 'isVariantAnAccessory' : variant) &&
            !(cardData | sectionRowViewModelBasicMethod: 'isVariantNonCannabinoidOtherVariant' : variant)
          "
          class="product-card-cannabinoids"
          [style.color]="viewModel.bodyTextColor$ | async">
          <div
            *ngIf="cardData | sectionRowViewModelBasicMethod: 'getCannabinoid' : 'THC' : variant as thcValue"
            class="cannabinoid">
            <div class="cannabinoid-title">THC</div>
            <div class="cannabinoid-value">{{ thcValue | trimSafely }}</div>
          </div>
          <div
            *ngIf="
              (cardData | sectionRowViewModelBasicMethod: 'getCannabinoid' : 'THC' : variant) &&
              (cardData | sectionRowViewModelBasicMethod: 'getCannabinoid' : 'CBD' : variant)
            "
            class="cannabinoid-separator">
            |
          </div>
          <div
            *ngIf="cardData | sectionRowViewModelBasicMethod: 'getCannabinoid' : 'CBD' : variant as cbdValue"
            class="cannabinoid">
            <div class="cannabinoid-title">CBD</div>
            <div class="cannabinoid-value">{{ cbdValue | trimSafely }}</div>
          </div>
        </div>
        <div class="product-card-strain-type-and-size" [style.color]="viewModel.bodyTextColor$ | async">
          <div class="badge-container">
            <img
              *ngFor="let variantBadge of cardData | getBadgesFor: variant"
              appImage
              class="print-card-badge"
              [asset]="variantBadge?.image"
              alt="badge"
              src="" />
          </div>
          <div
            *ngIf="
              !(viewModel.isAccessory$ | async) &&
              !(viewModel.isNonCannabinoidOtherVariant$ | async) &&
              (cardData
                | sectionRowViewModelBasicMethod: 'getReadableStrainTypeFor' : variant
                | trimSafely) as strainType
            "
            class="product-card-strain">
            {{ strainType }}
          </div>
          <div
            *ngIf="
              !(viewModel.isAccessory$ | async) &&
              !(viewModel.isNonCannabinoidOtherVariant$ | async) &&
              (cardData | sectionRowViewModelBasicMethod: 'getReadableStrainTypeFor' : variant | trimSafely) &&
              (cardData | sectionRowViewModelBasicMethod: 'getSize' : variant | trimSafely)
            "
            class="strain-and-size-separator">
            |
          </div>
          <div
            *ngIf="
              !(viewModel.isAccessory$ | async) &&
              !(viewModel.isNonCannabinoidOtherVariant$ | async) &&
              (cardData | sectionRowViewModelBasicMethod: 'getSize' : variant | trimSafely | replace: ' ' : '') as size
            "
            class="product-card-size">
            {{ size }}
          </div>
          <div class="label-container">
            <app-label-inflator
              class="print-card-label"
              [columnLabel]="false"
              [labelComponentInterface]="cardData | sectionRowViewModelBasicMethod: 'labelInterfaceFor' : variant"
              [reset]="false">
            </app-label-inflator>
          </div>
        </div>
      </div>
      <div *ngIf="viewModel.nMoreFlavours$ | async as nFlavours" class="plus-n-more-flavours">
        +{{ nFlavours }} More
      </div>
    </div>
    <div class="product-card-price">
      <app-fika-size-and-price
        [includeSize]="viewModel.includeSizeInPrice$ | async"
        [singlePrice]="true"
        [rowVm]="viewModel.cardData$ | async"
        [colVms]="viewModel.spoofedGridColViewModels$ | async"
        [locationConfig]="viewModel.locationConfig$ | async"
        [saleText]="viewModel.virtualLabelText$ | async">
      </app-fika-size-and-price>
    </div>
  </div>
</ng-container>

<ng-template #regularCard>
  <app-fika-index-card-content [cardData]="cardData"></app-fika-index-card-content>
</ng-template>
