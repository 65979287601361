import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ColumnComponent } from '../column.component';

@Component({
  selector: 'app-column-quantity-in-stock',
  templateUrl: './column-quantity-in-stock.component.html',
  styleUrls: ['./column-quantity-in-stock.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnQuantityInStockComponent extends ColumnComponent {
}
