import { CardData } from './card-data';
import { Variant } from '../product/dto/variant';
import { StringUtils } from '../../utils/string-utils';

export class FikaBeverageCardData extends CardData {

  public override getSize(variant?: Variant): string {
    const v = variant || this.rowVariants?.firstOrNull();
    const formattedUnitSize = v?.getFormattedUnitSize();
    const packagedQuantity = v?.packagedQuantity;
    const packagedQuantityIsSet = formattedUnitSize !== '-' && formattedUnitSize !== '0';
    if (packagedQuantity > 1 && packagedQuantityIsSet) {
      return `${packagedQuantity} x ${formattedUnitSize?.toUpperCase()}`;
    } else if (packagedQuantity < 1 && packagedQuantityIsSet) {
      return `${formattedUnitSize?.toUpperCase()}`;
    } else if (!packagedQuantityIsSet) {
      return '-';
    }
    return super.getSize(variant)?.toUpperCase();
  }

  getOverrideBaseSaleFormatText(): string {
    const promoNames = this.rowVariants?.map(v => {
      return StringUtils.removeWhiteSpace(v?.locationPromotion?.name?.toLowerCase());
    });
    switch (true) {
      case promoNames?.some(name => name?.includes('lastchance')):
        return 'LAST CHANCE';
      case promoNames?.some(name => name?.includes('bigdeal')):
        return 'BIG DEAL';
    }
    return null;
  }

  getOverrideDollarOffSaleFormatText(): string {
    return this.getOverrideBaseSaleFormatText();
  }

  getOverridePercentOffSaleFormatText(): string {
    return this.getOverrideBaseSaleFormatText();
  }

}
