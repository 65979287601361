import { LocationPriceStream } from '../models/enum/shared/location-price-stream';
import { PriceUtils } from './price-utils';
import { PrintThemeId, ProductThemeId } from '../models/enum/dto/theme.enum';

export {};
declare global {
  export interface Number {
    applyPriceRounding(
      themeId: string,
      companyId: number,
      locationId: number,
      stream: LocationPriceStream
    ): number | null;
  }
}

Number.prototype.applyPriceRounding = function(
  themeId: string,
  companyId: number,
  locationId: number,
  stream: LocationPriceStream
): number | null {
  switch (true) {
    case themeId === ProductThemeId.IngramAndSons:
    case themeId === PrintThemeId.IngramAndSonsSingleFold:
      return PriceUtils.ingramAndSonsEighthPriceRounding(this);
    case stream === LocationPriceStream.TaxesInRounded:
      return PriceUtils.roundToNearest5CentsOrNull(this);
    default:
      return this;
  }
};
