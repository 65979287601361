<div
  class="footer-container"
  #footerContainer
  [ngClass]="menu | productMenuBasicMethod : 'getFooterWrapperClass'"
  [style.display]="(noBadges$ | async) ? 'block' : null"
  [style.color]="menu?.hydratedTheme?.themeFeatures?.footerTextColor ? menu?.menuOptions?.footerTextColor : ''">
  <div fxLayout="row" fxFlex [style.padding-bottom.rem]="removeBottomPadding ? 0 : 1">
    <div fxFlex class="side-of-classification-board" fxLayout="column" fxLayoutAlign=" stretch">
      <div
        *ngIf="!hideSides"
        fxFlex="40"
        class="top-of-side left"
        [style.background-color]="lastProductSection?.metadata.productsContainerBackgroundColor"></div>
      <div fxFlex="60" class="bottom-of-side"></div>
    </div>
    <div
      *ngIf="
        (includeClassifications && (menu | productMenuBasicMethod : 'getUniqueClassificationList')?.length > 0) ||
        (includeBadges && (menuBadges$ | async | filterList : filterOutBadgeNamesWith86)?.length > 0)
      "
      class="footer-classification-board"
      [style.background-color]="lastProductSection?.metadata.productsHeaderBackgroundColor">
      <ng-container
        *ngIf="includeClassifications && (menu | productMenuBasicMethod : 'getUniqueClassificationList')?.length > 0">
        <div
          *ngFor="let classification of menu | productMenuBasicMethod : 'getUniqueClassificationList'"
          class="classification-mapping">
          <div [ngClass]="classification | lowercase">
            {{ classification | getClassificationSymbol }}
          </div>
          <div>=</div>
          <div class="classification-name">
            {{ classification | uppercase }}
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="includeBadges">
        <div
          *ngFor="let badge of menuBadges$ | async | filterList : filterOutBadgeNamesWith86 as badges"
          class="classification-mapping">
          <img
            appImage
            class="badge-in-legend"
            [asset]="badge?.image"
            [reset]="reset"
            [style.object-fit]="'contain'"
            alt="badge image"
            src="" />
          <div>=</div>
          <div
            class="classification-name"
            [style.color]="menu | productMenuBasicMethod : 'getSectionHeaderTextColor' : lastProductSection">
            {{ badge?.name | uppercase }}
          </div>
        </div>
      </ng-container>
    </div>
    <div fxFlex class="side-of-classification-board" fxLayout="column" fxLayoutAlign=" stretch">
      <div
        *ngIf="!hideSides"
        fxFlex="40"
        class="top-of-side right"
        [style.background-color]="lastProductSection?.metadata.productsContainerBackgroundColor"></div>
      <div fxFlex="60" class="bottom-of-side"></div>
    </div>
  </div>
</div>
