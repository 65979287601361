import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { UnitOfMeasure } from '../../enum/dto/unit-of-measure.enum';
import { NumberUtils } from '../../../utils/number.utils';

export const PRICING_TIER_UPPER_LIMIT = 9999;

export class VariantPricingTier implements Deserializable, UniquelyIdentifiable {

  public name: string;
  public startWeight: number;   // Lower bounded weight for pricing tier
  public startQuantity: number; // Quantity of BaseVariant (StartWeight/VariantDTO.UnitSize)
  public endWeight: number;     // Upper bounded weight for pricing tier
  public endQuantity: number;   // Quantity of BaseVariant (StartWeight/VariantDTO.UnitSize)
  public price: number;         // Indicates price per unit/uom
  public isDiscountable: boolean;

  onDeserialize() {
  }

  /**
   * This functionality directly matches what is implemented on the API.
   * The selected values are validated on the API, so it is important that
   * this is only changed in conjunction with the API
   */
  getGridColumnName(useWeight: boolean, uom: UnitOfMeasure): string {
    if (useWeight && (this.startWeight > 0 || this.endWeight > 0)) {
      // Return column name using Weight
      if (this.endWeight === PRICING_TIER_UPPER_LIMIT) {
        return `${NumberUtils.formatToSigFigDecimals(this.startWeight)}${uom}+`;
      } else {
        const startWeight = NumberUtils.formatToSigFigDecimals(this.startWeight);
        const endWeight = NumberUtils.formatToSigFigDecimals(this.endWeight);
        return `${startWeight}-${endWeight}${uom}`;
      }
    } else {
      // Default to using quantity
      if (this.endQuantity === PRICING_TIER_UPPER_LIMIT) {
        return `${NumberUtils.formatToSigFigDecimals(this.startQuantity)}+`;
      } else {
        const startQty = NumberUtils.formatToSigFigDecimals(this.startQuantity);
        const endQty = NumberUtils.formatToSigFigDecimals(this.endQuantity);
        return `${startQty}-${endQty}`;
      }
    }
  }

  getUniqueIdentifier(): string {
    return `${this.name}
      -${this.startWeight}
      -${this.startQuantity}
      -${this.endWeight}
      -${this.endQuantity}
      -${this.price}
      -${this.isDiscountable}`;
  }

}
