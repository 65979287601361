import { Pipe, PipeTransform } from '@angular/core';
import { CardStack } from '../../../models/menu/section/card-stacks/card-stack';

@Pipe({
  name: 'iterableCardStackColumns'
})
export class IterableCardStackColumnsPipe implements PipeTransform {

  transform(cardStack: CardStack, nCardsOnPage: number): any[] {
    return Array(cardStack?.getNumberOfVisibleColumnsOnPage(nCardsOnPage) || 1);
  }

}
