import { CardData } from './card-data';
import { ProductType } from '../enum/dto/product-type.enum';
import { StringUtils } from '../../utils/string-utils';
import { CannabisUnitOfMeasure } from '../enum/dto/cannabis-unit-of-measure.enum';
import { VariantType } from '../enum/dto/variant-type.enum';
import { VariantTypeUtils } from '../../utils/variant-type-utils';
import { exists } from '../../functions/exists';
import { Variant } from '../product/dto/variant';

export class FikaCardData extends CardData {

  public fikaVariantType(): string {
    const rowVariantType = this.rowVariantType();
    switch (true) {
      case rowVariantType?.toLowerCase()?.includes('capsule'):
        return 'capsules';
      case rowVariantType?.toLowerCase()?.includes('diamond'):
        return 'diamonds';
      default:
        return rowVariantType;
    }
  }

  public override getSize(variant?: Variant): string {
    const v = variant || this.rowVariants?.firstOrNull();
    switch (true) {
      case ProductType.Seed === v?.productType || VariantType.Seed === v?.variantType: {
        return null;
      }
      case VariantTypeUtils.isPreRollType(v?.variantType): {
        const quantity = v?.packagedQuantity;
        const formattedUnitSize = v?.getFormattedUnitSize();
        const hasQty = Number.isFinite(quantity);
        const hasSize = exists(formattedUnitSize) && (formattedUnitSize !== '-');
        switch (true) {
          case hasQty && hasSize:  return `${quantity} x ${formattedUnitSize}`;
          case hasQty && !hasSize: return `${quantity}`;
          default:                 return '-';
        }
      }
      case VariantTypeUtils.isCapsuleType(v?.variantType): {
        const n = v?.packagedQuantity ?? 0;
        return `${n} cap`
          .pluralizer()
          .addRule({ isPlural: n > 1, useApostrophe: false, word: 'cap' })
          .pluralize();
      }
      case (v?.productType === ProductType.Beverage && VariantTypeUtils.isReadyToDrinkBeverageType(v?.variantType)): {
        const quantity = v?.packagedQuantity;
        const formattedUnitSize = v?.getFormattedUnitSize();
        switch (true) {
          case !quantity:
          case quantity === 1:
            return formattedUnitSize;
          default:
            return `${quantity} x ${formattedUnitSize}`;
        }
      }
      case (v?.productType === ProductType.Edible && !VariantTypeUtils.isReadyToDrinkBeverageType(v?.variantType)): {
        const quantity = v?.packagedQuantity;
        switch (true) {
          case !quantity:
            return v?.getFormattedUnitSize();
          default:
            return `${v?.packagedQuantity} pc`;
        }
      }
      default: {
        return v?.getFormattedUnitSize();
      }
    }
  }

  override getCannabinoid(cannabinoid: string, variant?: Variant): string {
    const variants = variant ? [variant] : this.getScopedVisibleVariantsElseRowVariants();
    const includesAccessories = variants?.some(v => v?.productType === ProductType.Accessories);
    if (includesAccessories) return null;
    if (!variants?.length) return '';
    const unitOfMeasureString = variants?.map(v => v?.getCannabinoidUnitOfMeasureString(this.menu))?.mode();
    if (unitOfMeasureString === CannabisUnitOfMeasure.NA) return '--';
    const value = (this.displayCannabinoidInRanges() || variants?.length > 1)
      ? this.getCannabinoidRowRangeAsString(cannabinoid, unitOfMeasureString, null, variant)
      : this.getCannabinoidAsString(cannabinoid, unitOfMeasureString, null, variant);
    return StringUtils.removeWhiteSpace(value);
  }

  override getThc(variant?: Variant): string {
    const variants = variant ? [variant] : this.getScopedVisibleVariantsElseRowVariants();
    const includesAccessories = variants?.some(v => v?.productType === ProductType.Accessories);
    if (includesAccessories) return null;
    if (!variants?.length) return '';
    const unitOfMeasureString = variants?.map(v => v?.getCannabinoidUnitOfMeasureString(this.menu))?.mode();
    if (unitOfMeasureString === CannabisUnitOfMeasure.NA) return '--';
    const thc = (this.displayCannabinoidInRanges() || variants?.length > 1)
      ? this.getCannabinoidRowRangeAsString('THC', unitOfMeasureString, null, variant)
      : this.getCannabinoidAsString('THC', unitOfMeasureString, null, variant);
    return StringUtils.removeWhiteSpace(thc);
  }

  override getCbd(variant?: Variant): string {
    const variants = variant ? [variant] : this.getScopedVisibleVariantsElseRowVariants();
    const includesAccessories = variants?.some(v => v?.productType === ProductType.Accessories);
    if (includesAccessories) return null;
    if (!variants?.length) return '';
    const unitOfMeasureString = variants?.map(v => v?.getCannabinoidUnitOfMeasureString(this.menu))?.mode();
    if (unitOfMeasureString === CannabisUnitOfMeasure.NA) return '--';
    const cbd = (this.displayCannabinoidInRanges() || variants?.length > 1)
      ? this.getCannabinoidRowRangeAsString('CBD', unitOfMeasureString, null, variant)
      : this.getCannabinoidAsString('CBD', unitOfMeasureString, null, variant);
    return cbd?.includes('<') ? null : StringUtils.removeWhiteSpace(cbd);
  }

  getOverrideBaseSaleFormatText(): string {
    const promoNames = this.rowVariants?.map(v => {
      return StringUtils.removeWhiteSpace(v?.locationPromotion?.name?.toLowerCase());
    });
    switch (true) {
      case promoNames?.some(name => name?.includes('lastchance')):
        return 'LAST CHANCE';
      case promoNames?.some(name => name?.includes('bigdeal')):
        return 'BIG DEAL';
    }
    return null;
  }

  getOverrideDollarOffSaleFormatText(): string {
    return this.getOverrideBaseSaleFormatText();
  }

  getOverridePercentOffSaleFormatText(): string {
    return this.getOverrideBaseSaleFormatText();
  }

}
