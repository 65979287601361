import { SectionColumnType } from '../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';

export class ColWidth {

  public type: SectionColumnType;
  public widthPercentage: string;

  constructor(type: SectionColumnType, widthPercentage: number) {
    this.type = type;
    this.widthPercentage = `${widthPercentage}`;
  }

}
