// @dynamic
export class StringUtils {

  static getStringMode(items: string[]): string {
    const modeCounts = {};
    items?.forEach(item => modeCounts[item] = (modeCounts[item] || 0) + 1);
    const sortedModeCounts = Object.keys(modeCounts)?.sort((a, b) => modeCounts[b] - modeCounts[a]);
    return sortedModeCounts?.firstOrNull();
  }

  static removeWhiteSpace(s: string): string {
    return s?.replace(/\s/g, '');
  }

  static gridColumnComparisonString(unitString: string): string {
    return StringUtils.removeWhiteSpace(unitString?.toLowerCase());
  }

  static capitalize(s: string): string {
    return (s.length > 1) ? s.charAt(0).toUpperCase() + s.slice(1) : s;
  }

  static sentenceCase(s: string): string {
    return StringUtils.capitalize(s?.toLowerCase());
  }

  static pascalCaseToKebabCase(s: string): string {
    return s?.split(/(?=[A-Z])/)?.join('-')?.toLowerCase();
  }

  static pascalCaseToCamelCase(s: string): string {
    return s?.charAt(0)?.toLowerCase() + s?.slice(1);
  }

  static camelize(str): string {
    return str
      ?.replace(/^\w|[A-Z]|\b\w/g, (word, index) => index === 0 ? word.toLowerCase() : word.toUpperCase())
      ?.replace(/\s+/g, '');
  }

  /**
   * Pascal case, or PascalCase, is a variable naming convention in programming in which the first letter of each word
   * is uppercase and the remaining letters are lowercase, with no spaces or other separators between words.
   */
  static toPascalCase(s: string, separator: string = ' ') {
    return s?.split(separator)?.map(word => StringUtils.capitalize(word))?.join('');
  }

}
