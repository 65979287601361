import { Pipe, PipeTransform } from '@angular/core';
import { ProductMenu } from '../../../models/menu/product-menu';
import { SpotlightMenu } from '../../../models/menu/spotlight-menu';
import type { Menu } from '../../../models/menu/menu';

@Pipe({
  name: 'isProductMenuAndNotSpotlight'
})
export class IsProductMenuAndNotSpotlightPipe implements PipeTransform {

  transform(value: Menu): boolean {
    return (value instanceof ProductMenu) && !(value instanceof SpotlightMenu);
  }

}
