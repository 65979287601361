import { IndexCardStack } from './index-card-stack';
import { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { PrintCardBackgroundComponent } from '../../../../../modules/print-cards/components/print-cards/backgrounds/print-card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/print-card-content.component';
import { OGKushIndexCardStackContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/index-cards-3x5/og-kush-index-card-stack-content/og-kush-index-card-stack-content.component';

export class OGKushIndexCardStack extends IndexCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<PrintCardBackgroundComponent> | null {
    return null;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return OGKushIndexCardStackContentComponent;
  }

  nonPerforatedBleedBorderInInches(): number {
    return 0;
  }

}
