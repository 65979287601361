import { ProductMenu } from '../../product-menu';
import { Type } from '@angular/core';
import type { CenteredLogoMenuFooterComponent } from '../../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/centered-logo-menu-footer/centered-logo-menu-footer.component';
import { ProductMenuHeaderComponent } from '../../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { MenuHeaderTitleSubtitleComponent } from '../../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-subtitle/menu-header-title-subtitle.component';
import { ProductMenuFooterComponent } from '../../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/product-menu-footer.component';
import { FeaturedCategoryHeaderComponent } from '../../../../modules/display/components/menus/marketing-menu/building-blocks/menu-header/featured-category-header/featured-category-header.component';
import { LocationPriceStream } from '../../../enum/shared/location-price-stream';
import { SectionUtils } from '../../../../utils/section-utils';

export class MarketingFeaturedCategoryStaticGridMenu extends ProductMenu {

  getThemeClass(): string {
    return 'marketing-featured-category-static-grid-theme';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    if (this.isPortrait()) {
      return MenuHeaderTitleSubtitleComponent;
    } else {
      return FeaturedCategoryHeaderComponent;
    }
  }

  getShowHeader(): boolean {
    return true;
  }

  getFontFaceList(): string[] {
    return ['poppins', 'source-sans-pro', 'archivo-black'];
  }

  getShouldSectionsContainerFlexWrap(): boolean {
    return false;
  }

  getShowFooter(): boolean {
    return this.isPortrait();
  }

  getFooterType(): Type<CenteredLogoMenuFooterComponent> {
    return ProductMenuFooterComponent;
  }

  getCarouselDurationInSeconds(): number {
    const showProductForXSeconds = Number.parseInt(this?.metadata?.carouselDuration || '10', 10);
    const isNumber = Number.isFinite(showProductForXSeconds);
    return isNumber ? showProductForXSeconds : 10;
  }

  calculateLongestSingleLoopDurationInSeconds(): number {
    const displayEachProductForXSeconds = this.getCarouselDurationInSeconds();
    const variantCountsPerSection = this.sections
      ?.filter(SectionUtils.isSectionWithProducts)
      ?.map(sectionWithProducts => {
        // price stream doesn't matter for this calculation, so use default
        const priceStream = LocationPriceStream.Default;
        return sectionWithProducts?.getScopedVisibleLineItemCount(this, priceStream, this.isSectionLevelOverflow());
      })
      || [0];
    return Math.max(...variantCountsPerSection) * displayEachProductForXSeconds;
  }

  shouldFetchVariantAssets(): boolean {
    return true;
  }

}
