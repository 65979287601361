import { Component, Input } from '@angular/core';
import { MarketingMenu } from '../../../../../../../models/menu/marketing-menu';
import { MenuFooterComponent } from '../../../menu/building-blocks/menu-footer/menu-footer.component';

@Component({
  selector: 'app-marketing-footer',
  templateUrl: './marketing-footer.component.html'
})
export class MarketingFooterComponent extends MenuFooterComponent {

  @Input() menu: MarketingMenu;

}
