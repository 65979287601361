import { Deserializable } from '../../protocols/deserializable';

export class CompanyFeatures implements Deserializable {

  public companyId: number;
  public supportsLocationLabels: boolean;

  onDeserialize() {
  }

}
