export enum SystemLabelKey {
  Featured = 'Featured',
  LowStock = 'LowStock',
  Restocked = 'Restocked',
  Sale = 'Sale',
  New = 'New',
  Custom1 = 'Custom1',
  Custom2 = 'Custom2',
  Custom3 = 'Custom3',
  Custom4 = 'Custom4',
  Custom5 = 'Custom5',
}
