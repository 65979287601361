import { Component } from '@angular/core';
import { ProductMenuHeaderComponent } from '../product-menu-header.component';

@Component({
  selector: 'app-menu-header-calyx-trichomes',
  templateUrl: './menu-header-calyx-trichomes.component.html',
  styleUrls: ['./menu-header-calyx-trichomes.component.scss'],
  providers: [{ provide: ProductMenuHeaderComponent, useExisting: MenuHeaderCalyxTrichomesComponent }]
})
export class MenuHeaderCalyxTrichomesComponent extends ProductMenuHeaderComponent {
}
