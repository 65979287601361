import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TrueNorthLandscapeCardData } from '../../../../../../../../models/print-cards/true-north-landscape-card-data';
import { TrueNorthCardStackContentViewModel } from '../true-north-card-stack-content-view-model';

@Injectable()
export class TrueNorthLandscapeCardStackContentViewModel extends TrueNorthCardStackContentViewModel {

  public readonly cardData$: Observable<TrueNorthLandscapeCardData>;

}
