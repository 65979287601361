import { Pipe, PipeTransform } from '@angular/core';
import { CardStack } from '../../../models/menu/section/card-stacks/card-stack';

@Pipe({
  name: 'withContentBleed'
})
export class WithContentBleedPipe implements PipeTransform {

  transform(cardStack: CardStack|null, preview: boolean, multiplier: number = 1): number {
    return cardStack?.withContentBleed(preview, multiplier) || 0;
  }

}
