import { LabelStack } from '../label-stack';
import { PaperSize } from '../../../../shared/paper-size';
import { Orientation } from '../../../../enum/dto/orientation.enum';

export abstract class TwoByFourLabelStack extends LabelStack {

  getCardHeightInInches(): number {
    return 2;
  }

  getCardWidthInInches(): number {
    return 4;
  }

  nCardsPerPage(paperSize: PaperSize, isOnPerforatedPaper: boolean): number {
    return 10;
  }

  nColumns(): number {
    return 2;
  }

  nRows(): number {
    return 5;
  }

  cardOrientationOnPortraitPage(): Orientation {
    return Orientation.Landscape;
  }

}
