import { Deserializable } from '../../protocols/deserializable';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { CannabinoidDisplayType } from '../../enum/shared/cannabinoid-display-type.enum';
import { SaleLabelFormat } from '../../enum/shared/sale-label-format.enum';
import { LabelStyle } from '../../enum/shared/label-style.enum';
import { TerpeneDisplayType } from '../../enum/shared/terpene-display-type';
import { Cannabinoid } from '../../enum/shared/cannabinoid';
import { Terpene } from '../../enum/shared/terpene';

export const DEFAULT_LABEL_ORDER = 12;

export class CompanyConfiguration implements Deserializable, Cachable, UniquelyIdentifiable {

  public companyId: number;
  public lowStockThreshold: number;
  public restockThresholdHours: number;
  public cannabinoidDisplayType: CannabinoidDisplayType = CannabinoidDisplayType.Exact;
  public terpeneDisplayType: TerpeneDisplayType = TerpeneDisplayType.Exact;
  public saleLabelFormat: SaleLabelFormat;
  public labelStyle: LabelStyle;
  public enabledCannabinoids: Cannabinoid[];
  public enabledTerpenes: Terpene[];
  // Cache
  cachedTime: number;

  static buildCacheKey(id: string): string {
    return `CompanyConfiguration-${id}`;
  }

  public onDeserialize() {
    if (!this.cannabinoidDisplayType) {
      this.cannabinoidDisplayType = CannabinoidDisplayType.Exact;
    }
    this.enabledCannabinoids = Array.from(this.enabledCannabinoids || []);
    this.enabledTerpenes = Array.from(this.enabledTerpenes || []);
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneDay();
  }

  cacheKey(): string {
    return CompanyConfiguration.buildCacheKey(this.companyId.toString());
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.nowInUnixSeconds() > expiresAt;
  }

  getUniqueIdentifier(): string {
    return `
      -${this.companyId}
      -${this.restockThresholdHours}
      -${this.cannabinoidDisplayType}
      -${this.terpeneDisplayType}
      -${this.saleLabelFormat}
    `;
  }

}

