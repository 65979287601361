import { Component } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../product-menu-header.component';

@Component({
  selector: 'app-menu-header-plantlife-non-smokable-empty',
  templateUrl: './menu-header-plantlife-non-smokable-empty.component.html',
  providers: [{ provide: ProductMenuHeaderComponent, useExisting: MenuHeaderPlantlifeNonSmokableEmptyComponent }]
})
export class MenuHeaderPlantlifeNonSmokableEmptyComponent extends ProductMenuHeaderComponent {
}
