<div
  #headerContainer
  [ngClass]="menu | productMenuBasicMethod : 'getHeaderWrapperClass'"
  [ngStyle]="{
    color: menu?.hydratedTheme?.themeFeatures?.titleTextColor ? menu?.menuOptions?.titleTextColor : ''
  }">
  <div class="header-container">
    <div class="main-header">
      <div
        class="main-title"
        *ngIf="!menu?.menuOptions?.hideTitle && !!menu?.configurationTitle"
        [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
        {{ menu?.configurationTitle }}
      </div>
      <div
        class="main-subtitle"
        *ngIf="!menu?.menuOptions?.hideTitle && !!menu?.subTitle"
        [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
        {{ menu?.subTitle }}
      </div>
    </div>
  </div>
</div>
