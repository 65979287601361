import { Component } from '@angular/core';
import { ProductMenuHeaderComponent } from '../product-menu-header.component';

@Component({
  selector: 'app-menu-header-pre-roll',
  templateUrl: './menu-header-pre-roll.component.html',
  styleUrls: ['./menu-header-pre-roll.component.scss'],
  providers: [{ provide: ProductMenuHeaderComponent, useExisting: MenuHeaderPreRollComponent }]
})
export class MenuHeaderPreRollComponent extends ProductMenuHeaderComponent {
}
