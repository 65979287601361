import { Component, OnChanges, SecurityContext, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { Menu } from '../../../../../../../../models/menu/menu';
import { debounceTime, map } from 'rxjs/operators';
import { exists } from '../../../../../../../../functions/exists';
import { AssetSize } from '../../../../../../../../models/enum/dto/asset-size.enum';
import { DateUtils } from '../../../../../../../../utils/date-utils';
import { CachePolicy } from '../../../../../../../../models/enum/shared/cachable-image-policy.enum';
import { ProductMenuHeaderComponent } from '../product-menu-header.component';

@Component({ selector: 'app-menu-header-background-asset', template: '' })
export abstract class MenuHeaderBackgroundAssetComponent extends ProductMenuHeaderComponent implements OnChanges {

  constructor(
    private sanitizer: DomSanitizer
  ) {
    super();
  }

  private _menu = new BehaviorSubject<Menu>(null);
  private _bgImg: ReplaySubject<string | SafeResourceUrl> = new ReplaySubject<string | SafeResourceUrl>(1);
  public image$ = combineLatest([
    this._menu,
    this._bgImg
  ]).pipe(
    map(([menu, url]) => menu.backgroundImage?.isImage() ? url : null),
    debounceTime(1)
  );

  setupViews() {
    super.setupViews();
  }

  setupBindings() {
    super.setupBindings();
    this.listenToImg();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.menu) this._menu.next(this.menu);
  }

  listenToImg() {
    const imgKey = 'backgroundKey';
    this.destroyImageSub(imgKey);
    if (!exists(this.menu?.backgroundImage?.sizePriorityUrl$)) {
      this._bgImg.next(null);
      return;
    }
    this.fetchAsset();
    const asset = this.menu?.backgroundImage;
    const bindTo$ = this.menu?.backgroundImage?.sizePriorityUrl$.pipe(
      map((url) => {
        return exists(url) && asset?.isImage()
          ? `url(${this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, url)})`
          : null;
      })
    );
    this.pushImageSub(imgKey, this._bgImg.bind(bindTo$));
  }

  private fetchAsset() {
    const asset = this.menu?.backgroundImage;
    const size = AssetSize.Original;
    const cacheForNSeconds = (DateUtils.unixOneHour() * 12);
    const validCacheTime = cacheForNSeconds > -1 && Number.isFinite(cacheForNSeconds);
    if (exists(asset) && exists(size) && validCacheTime) {
      const policy = CachePolicy.Service;
      this.menu?.backgroundImage?.getAsset(policy, size, cacheForNSeconds);
    }
  }

}
