<div
  #headerContainer
  class="section-header-container"
  [ngStyle]="{ 'background-image': headerBackgroundAsset$ | async }"
  [style.background-color]="
    (menu | productMenuBasicMethod: 'getShowSectionHeaderColorAsIcon')
      ? null
      : section?.metadata?.productsHeaderBackgroundColor
  "
  [style.background-size]="'cover'"
  [style.border-bottom-color]="menu | productMenuBasicMethod: 'getSectionTitleUnderlineColor' : section"
  [style.zoom]="menu?.menuOptions?.productZoom || 1.0"
  [class.section-header-bottom-border]="menu | productMenuBasicMethod: 'getSectionHeaderUnderlined'">
  <ng-container *ngFor="let colVm of columnVMs">
    <app-header-column-wrapper
      [calculationMode]="calculationMode"
      [menu]="menu"
      [section]="section"
      [colVm]="colVm"
      [sectionRowViewModels]="sectionRowViewModels">
      <app-header-column-product-title [menu]="menu" [section]="section" [colVm]="colVm">
      </app-header-column-product-title>
      <app-header-column-variant-price
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [ignoreUserSetTextColorAndAutoToLightOrDarkTextColor]="true">
      </app-header-column-variant-price>
      <app-header-column-variant-secondary-price
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [ignoreUserSetTextColorAndAutoToLightOrDarkTextColor]="true">
      </app-header-column-variant-secondary-price>
      <app-header-column-quantity-in-stock
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [ignoreUserSetTextColorAndAutoToLightOrDarkTextColor]="true">
      </app-header-column-quantity-in-stock>
      <app-header-column-quantity-and-size
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [ignoreUserSetTextColorAndAutoToLightOrDarkTextColor]="true">
      </app-header-column-quantity-and-size>
      <app-header-column-cannabinoid
        *ngIf="colVm?.columnType | isCannabinoidColumn as cannabinoidColumnType"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [sectionRowViewModels]="sectionRowViewModels"
        [cannabinoidColumnType]="cannabinoidColumnType"
        [ignoreUserSetTextColorAndAutoToLightOrDarkTextColor]="true">
      </app-header-column-cannabinoid>
      <app-header-column-terpene
        *ngIf="colVm?.columnType | isTerpeneColumn as terpeneColumnType"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [sectionRowViewModels]="sectionRowViewModels"
        [terpeneColumnType]="terpeneColumnType">
      </app-header-column-terpene>
      <app-header-column-quantity
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [ignoreUserSetTextColorAndAutoToLightOrDarkTextColor]="true">
      </app-header-column-quantity>
      <app-header-column-size
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [ignoreUserSetTextColorAndAutoToLightOrDarkTextColor]="true">
      </app-header-column-size>
      <app-header-column-strain-type
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [ignoreUserSetTextColorAndAutoToLightOrDarkTextColor]="true">
      </app-header-column-strain-type>
      <app-header-column-asset
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [ignoreUserSetTextColorAndAutoToLightOrDarkTextColor]="true">
      </app-header-column-asset>
      <app-header-column-producer
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [ignoreUserSetTextColorAndAutoToLightOrDarkTextColor]="true">
      </app-header-column-producer>
      <app-header-column-badge
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [ignoreUserSetTextColorAndAutoToLightOrDarkTextColor]="true">
      </app-header-column-badge>
      <app-header-column-brand
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [ignoreUserSetTextColorAndAutoToLightOrDarkTextColor]="true">
      </app-header-column-brand>
      <app-header-column-label
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [ignoreUserSetTextColorAndAutoToLightOrDarkTextColor]="true">
      </app-header-column-label>
    </app-header-column-wrapper>
  </ng-container>
</div>
