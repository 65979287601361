import { ApiClient } from './api-client';
import { Observable, of, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { LoggingService } from '../services/logging-service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { Injectable } from '@angular/core';
import { AssetUrl } from '../models/image/dto/asset-url';
import { Asset } from '../models/image/dto/asset';
import { VariantAsset } from '../models/image/dto/variant-asset';
import { HttpEvent } from '@angular/common/http';
import { FetchVariantAssetReq } from '../models/image/shared/fetch-variant-asset-req';

@Injectable({ providedIn: 'root' })
export class ImageAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  // Variables

  public serviceName = 'Image';

  // Image

  public GetAsset(id, md5Hash: string): Observable<Asset> {
    const url = Endpoints.GetAsset(id, md5Hash);
    return this.apiClient.getObj<Asset>(Asset, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetAsset', err));
        return throwError(err);
      })
    );
  }

  public GetBlobFromUrl(
    assetUrl: AssetUrl,
    includeProgressEvents: boolean = false
  ): Observable<HttpEvent<Blob>> {
    if (!assetUrl.url || assetUrl.urlExpired()) {
      return this.GetAsset(assetUrl.assetId, assetUrl.md5Hash).pipe(
        map(fetchedAsset => fetchedAsset.urls?.find(url => url.size === assetUrl.size)),
        map(updatedAssetUrl => assetUrl.updateDataFrom(updatedAssetUrl)),
        switchMap(updatedAssetUrl => {
          return !!updatedAssetUrl?.url
            ? this.fetchBlobFromApi(updatedAssetUrl.url, includeProgressEvents)
            : of<HttpEvent<Blob>>();
        })
      );
    } else {
      return this.fetchBlobFromApi(assetUrl.url, includeProgressEvents);
    }
  }

  private fetchBlobFromApi(
    url: string,
    includeProgressEvents: boolean = false
  ): Observable<HttpEvent<Blob>> {
    return this.apiClient.getBlob(url, includeProgressEvents).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetBlobFromUrl', err));
        return throwError(err);
      })
    );
  }

  /**
   * itemTypeSortOrder can be used to tell the backend which AssetLibraryTypes to grab and in what order.
   * if itemTypeSortOrder is defined, any libraryType not included in that list will not be returned from API
   */
  public GetVariantAssets(
    req: FetchVariantAssetReq,
  ): Observable<Map<string, VariantAsset[]>> {
    if (req?.batchVariantIds?.length) {
      const url = Endpoints.GetVariantAssets(req.batchVariantIds, req.itemCount, req.assetTypeSortOrder);
      const additionalHeaders = { companyid: `${req.companyId}` };
      return this.apiClient.getMapArr<VariantAsset>(VariantAsset, url, additionalHeaders).pipe(
        catchError(e => {
          const err = new BsError(e, this.serviceName);
          this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetVariantAssets', err));
          return throwError(err);
        })
      );
    } else {
      return of(new Map<string, VariantAsset[]>());
    }
  }

}
