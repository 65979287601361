@charset "UTF-8";

/* src/app/modules/print-cards/components/print-cards/card-content/post-cards-4x6/sessions-post-card-stack-content/sessions-post-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}
:host .color-strip {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  height: 24rem;
}
:host .color-strip .upper-container {
  padding-top: 1.25rem;
  gap: 0.375rem;
}
:host .color-strip .upper-container .product-image {
  width: 4.875rem;
  height: 4.875rem;
}
:host .color-strip .upper-container .card-strain-type {
  font-family: poppins, sans-serif;
  font-weight: 500;
  font-size: 1.4375rem;
  color: #ffffff;
  max-width: 97%;
}
:host .color-strip .upper-container .qty-and-size-container {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 1.625rem;
  color: #ffffff;
  padding-top: 0.625rem;
  max-width: 97%;
  white-space: nowrap;
}
:host .color-strip .lower-container {
  width: 100%;
  padding-bottom: 1.875rem;
}
:host .color-strip .lower-container .effects-container {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  color: #ffffff;
  text-transform: uppercase;
}
:host .color-strip .lower-container .company-name {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  color: #ffffff;
  text-transform: lowercase;
  padding-top: 0.8125rem;
}
:host .main-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.125rem 1rem;
  box-sizing: border-box;
}
:host .main-content .product-info .product-card-name {
  font-family: nunito, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 1.75rem;
  letter-spacing: -0.05em;
  color: #000000;
}
:host .main-content .product-info .product-card-brand {
  font-family: nunito-sans, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 1.75rem;
  padding-top: 0.5rem;
  text-transform: uppercase;
  color: #222222;
}
:host .main-content .product-info .product-card-cannabinoids-container {
  display: flex;
  flex-direction: row;
  gap: 2.25rem;
  padding: 0.5rem 0;
  line-height: 1.75rem;
}
:host .main-content .product-info .product-card-cannabinoids-container .property-container {
  font-family: nunito-sans, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.25rem;
  display: flex;
  flex-direction: row;
}
:host .main-content .product-info .badge-and-label-container {
  display: flex;
  flex-direction: row;
}
:host .main-content .product-info .badge-and-label-container .badge {
  height: 1.25rem;
}
:host .main-content .product-info .badge-and-label-container .print-card-label {
  align-self: start;
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .main-content .product-info .badge-and-label-container .print-card-label ::ng-deep .product-label {
  font-family: nunito-sans, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 0.75rem;
  display: block;
  margin-left: 0;
  margin-right: 0.5rem;
  width: fit-content;
}
:host .main-content .product-info .badge-and-label-container .print-card-label ::ng-deep .flag-body {
  display: block;
  justify-content: inherit;
}
:host .main-content .product-info ::ng-deep .description-container .description {
  font-family: nunito-sans, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
:host .main-content .product-info ::ng-deep .description-container .plain-text {
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
:host .main-content .product-info ::ng-deep .description-container .rich-text {
  max-height: 8.75rem;
  overflow-y: hidden;
}
/*# sourceMappingURL=sessions-post-card-stack-content.component.css.map */
