import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PriceUtils } from '../../../utils/price-utils';
import type { SectionColumnViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import type { LocationConfiguration } from '../../../models/company/dto/location-configuration';
import type { ProductStylingViewModel } from '../../../models/shared/product-styling-view-model';
import type { LocationPriceStream } from '../../../models/enum/shared/location-price-stream';

@Pipe({
  name: 'forcedPriceTextColorIncludingSaleConfiguration'
})
export class ForcedPriceTextColorIncludingSaleConfigurationPipe implements PipeTransform {

  transform(
    productStylingViewModel: ProductStylingViewModel,
    columnViewModel: SectionColumnViewModel,
    locationConfig: LocationConfiguration,
    secondaryPrice: boolean = false,
    overridePriceStream?: LocationPriceStream
  ): Observable<string> {
    const priceType = columnViewModel?.columnConfig?.dataValue;
    if (!secondaryPrice || PriceUtils.secondaryPriceRequiresSaleStyling(priceType)) {
      const color = productStylingViewModel?.forcedPriceTextColorIncludingSaleConfiguration;
      return color?.(columnViewModel, locationConfig, overridePriceStream) || of(null);
    }
    return of(null);
  }

}
