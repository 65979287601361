import { Type } from '@angular/core';
import { ComboHeaderComponent } from '../../../modules/display/components/menus/marketing-menu/building-blocks/menu-header/combo-header/combo-header.component';
import { MarketingHeaderComponent } from '../../../modules/display/components/menus/marketing-menu/building-blocks/menu-header/marketing-header.component';
import { Themeable } from '../../protocols/themeable';
import { PriceUtils } from '../../../utils/price-utils';
import { ComboFooterComponent } from '../../../modules/display/components/menus/marketing-menu/building-blocks/menu-footer/combo-footer/combo-footer.component';
import { MarketingFooterComponent } from '../../../modules/display/components/menus/marketing-menu/building-blocks/menu-footer/marketing-footer.component';
import { Asset } from '../../image/dto/asset';
import { VariantFeature } from '../../product/dto/variant-feature';
import { DisplayOptions } from '../../shared/display-options';
import { MarketingAsset } from '../../image/dto/marketing-asset';
import { OrderFeaturedVariants } from './FeaturedProduct/order-featured-variants';
import { DeprecatedMarketingMenu } from '../deprecated-marketing-menu';

export class MarketingComboMenu extends DeprecatedMarketingMenu implements Themeable {

  getComboPriceInteger(): string {
    return this.getComboTotalPrice()?.split('.')?.firstOrNull();
  }

  getComboPriceDecimal(): string | null {
    const price = this.getComboTotalPrice()?.split('.');
    if (price?.length > 1) {
      return `.${price.last()}`;
    }
  }

  getComboTotalPrice(): string {
    return PriceUtils.formatPriceAndRemoveTrailingZeros(parseFloat(this?.metadata?.price));
  }

  getThemeClass(): string {
    return 'original-combo';
  }

  getHeaderWrapperClass(): string {
    return 'wrap-header-in-flex-container';
  }

  getHeaderType(): Type<MarketingHeaderComponent> {
    return ComboHeaderComponent;
  }

  getShowHeader(): boolean {
    return true;
  }

  getFooterType(): Type<MarketingFooterComponent> {
    return ComboFooterComponent;
  }

  getShowFooter(): boolean {
    return this.showCompanyLogo();
  }

  isAssetEnabled(asset: Asset): boolean {
    const variantId = this.getVariantIdFromAsset(asset);
    const position = this.options?.rotationOrder?.get(variantId);
    const interval = this.options?.rotationInterval?.get(variantId);
    return (position >= 0) && (interval > 0);
  }

  getAssetPosition(asset: Asset): number {
    const variantId = this.getVariantIdFromAsset(asset);
    return this.options?.rotationOrder?.get(variantId);
  }

  getOrderFeaturedVariants(
    featured: VariantFeature,
    order: DisplayOptions,
    media: MarketingAsset[]
  ): OrderFeaturedVariants[] {
    return featured?.variantIds
      ?.map(id => {
        const position = order?.rotationOrder?.get(id);
        const interval = order?.rotationInterval?.get(id);
        const variant = featured?.variants?.find(v => v.id === id);
        const mediaName = featured?.assetNameMap?.get(id);
        const asset = media?.find(m => m?.fileName === mediaName);
        if (position >= 0 && interval > 0) {
          return new OrderFeaturedVariants(position, variant, asset);
        }
      })
      ?.filterNulls();
  }

}
