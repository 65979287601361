import { MenuType } from '../../enum/dto/menu-type.enum';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { ProductMenu } from '../product-menu';
import type { SectionWithProducts } from '../section/section-with-products';
import { LocationPriceStream } from '../../enum/shared/location-price-stream';

export class WeedStoreMenu extends ProductMenu {

  getThemeClass(): string {
    return 'weed-store-theme' + (this.type === MenuType.WebMenu ? ' web-menu' : '');
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getDefaultBackgroundImageUrl(): string {
    if (this.isLandscape()) {
      return 'assets/images/weed-store-background-landscape.jpg';
    } else {
      return 'assets/images/weed-store-background-portrait.jpg';
    }
  }

  getPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel,
  ): string {
    if (!this.getDropDecimal()) {
      const price = this.getVariantPricing(priceStream, locationId, s, rowVM, colVm, false, true);
      const dec = price.split('.')?.[1];
      // decimals and formatting are included. ie: $1000.00
      if (price === '-' || price.length > 7 || dec === '') {
        return '';
      } else {
        return dec;
      }
    } else {
      return '';
    }
  }

  getColumnGroupingSpacerWidthPercentage(): number {
    return 0;
  }

  getFontFaceList(): string[] {
    return ['open-sans', 'poppins'];
  }

  brandInlineWithProductName(): boolean {
    return true;
  }

  getShowProductSubtitle(): boolean {
    return false;
  }

}
