import { AfterViewInit, Component, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { ProductSectionHeaderComponent } from '../product-section-header/product-section-header.component';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { StringUtils } from '../../../../../../../../utils/string-utils';
import { ColorUtils } from '../../../../../../../../utils/color-utils';
import { HeaderColumnWrapperComponent } from '../../../../../../../shared/components/header-column/header-column-wrapper/header-column-wrapper.component';
import { CompanyDomainModel } from '../../../../../../../../domain/company-domain-model';

@Component({
  selector: 'app-order-review-section-header',
  templateUrl: './order-review-section-header.component.html',
  providers: [{ provide: ProductSectionHeaderComponent, useExisting: OrderReviewSectionHeaderComponent }]
})
export class OrderReviewSectionHeaderComponent extends ProductSectionHeaderComponent
  implements AfterViewInit, OnDestroy {

  constructor(
    companyDomainModel: CompanyDomainModel,
    sanitizer: DomSanitizer
  ) {
    super(companyDomainModel, sanitizer);
  }

  @ViewChildren(HeaderColumnWrapperComponent) sectionColumns: QueryList<HeaderColumnWrapperComponent>;
  private headerContainerStyleMutationObserver: MutationObserver;
  private borderRadius = '0.25rem';
  private white = '#ffffff';
  private black = '#222222';

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.listenToHeaderContainerStyleMutations();
    this.initializeSectionColumns();
  }

  private deleteHeaderContainerBackgroundColor = () => {
    const headerContainer = this.headerContainer?.nativeElement as HTMLDivElement;
    headerContainer.style.backgroundColor = null;
  };

  private listenToHeaderContainerStyleMutations() {
    const headerContainerMutationConfig = { attributes: true, childList: false, subtree: true };
    const headerContainer = this.headerContainer?.nativeElement as HTMLDivElement;
    if (!!headerContainer) {
      this.headerContainerStyleMutationObserver = new MutationObserver(this.deleteHeaderContainerBackgroundColor);
      this.headerContainerStyleMutationObserver.observe(headerContainer, headerContainerMutationConfig);
    }
  }

  private initializeSectionColumns() {
    const sectionColumns = this.sectionColumns?.toArray()?.map(column => {
      return (column?.elementRef?.nativeElement as HTMLElement)?.firstElementChild as HTMLDivElement;
    });
    this.deleteSectionColumnHeadersForColorBar(sectionColumns);
    this.sectionColumns.changes.pipe(takeUntil(this.onDestroy)).subscribe(changes => {
      const updatedSectionColumns = changes?.toArray()?.map(column => {
        return (column?.elementRef?.nativeElement as HTMLElement)?.firstElementChild as HTMLDivElement;
      });
      this.deleteSectionColumnHeadersForColorBar(updatedSectionColumns as HTMLDivElement[]);
    });
  }

  private deleteSectionColumnHeadersForColorBar(sectionColumns: HTMLDivElement[]) {
    const indexOfNameColumn = sectionColumns?.findIndex(column => column.classList.contains('section-column-title'));
    if (indexOfNameColumn > -1) {
      const columnsBeforeNameColumn = (sectionColumns.slice(0, indexOfNameColumn) || []).reverse();
      const columnsAfterNameColumn = sectionColumns.slice(indexOfNameColumn + 1) || [];
      const removeColumns = (column: HTMLDivElement) => {
        const innerDiv = column?.getElementsByClassName('section-header-general')?.item(0);
        const content = !!StringUtils.removeWhiteSpace(innerDiv?.innerHTML);
        if (!content) column?.remove();
        return !content;
      };
      // every works the same as forEach, but will stop iterating if the callback returns false
      columnsBeforeNameColumn.every(removeColumns);
      columnsAfterNameColumn.every(removeColumns);
      sectionColumns[indexOfNameColumn].insertAdjacentHTML('afterend', this.getBarDiv());
      this.setLastColumnBorderRadius(sectionColumns);
      this.addBackgroundColorToHeader(columnsAfterNameColumn);
      this.adjustColorBarTextColor(columnsAfterNameColumn);
    }
  }

  private setLastColumnBorderRadius(sectionColumns: HTMLDivElement[]) {
    const element = sectionColumns?.last() as HTMLDivElement;
    if (element?.style) {
      element.style.borderTopRightRadius = this.borderRadius;
      element.style.borderBottomRightRadius = this.borderRadius;
    }
  }

  private addBackgroundColorToHeader(sectionColumns: HTMLDivElement[]) {
    const color = this.section?.metadata?.productsHeaderBackgroundColor || this.black;
    sectionColumns?.forEach(column => {
      if (column?.style) column.style.backgroundColor = color;
    });
  }

  private adjustColorBarTextColor(sectionColumns: HTMLDivElement[]) {
    const backgroundColor = this.section?.metadata?.productsHeaderBackgroundColor || this.black;
    const textColor = ColorUtils.isDarkColor(backgroundColor) ? this.white : this.black;
    sectionColumns?.forEach(column => {
      if (column?.style) column.style.color = textColor;
    });
  }

  private getBarDiv(): string {
    return `
    <div style="flex: 1; display: flex; justify-content: center; align-items: center;">
       <div style="
         flex: 1;
         height: 100%;
         margin-left: 1.625rem;
         border-top-left-radius: ${this.borderRadius};
         border-bottom-left-radius: ${this.borderRadius};
         background-color: ${this.section?.metadata?.productsHeaderBackgroundColor || this.black};
       ">
       </div>
    </div>
    `;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.headerContainerStyleMutationObserver?.disconnect();
  }

}
