import { Pipe, PipeTransform } from '@angular/core';
import { PrintCardMenu } from '../../../models/menu/print-card/print-card-menu';

@Pipe({
  name: 'printCardMenuBasicMethod'
})
export class PrintCardMenuBasicMethodPipe implements PipeTransform {

  transform(
    printCardMenu: PrintCardMenu,
    methodKey: string,
    argument?: any
  ): any {
    return printCardMenu?.[methodKey]?.(argument);
  }

}
