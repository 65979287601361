<app-label-inflator
  #labelCalculator
  hidden
  class="label-calculator"
  [calculateVirtualLabel]="true"
  [columnLabel]="false"
  [labelComponentInterface]="cardData"
  [reset]="false">
</app-label-inflator>
<div class="main-content">
  <div class="top-container">
    <div class="product-cannabinoids-container" [style.color]="viewModel.bodyTextColor$ | async">
      <div *ngIf="viewModel.productTHC$ | async as thcValue" class="cannabinoid">
        <div class="cannabinoid-title">THC</div>
        <div class="cannabinoid-value">{{ thcValue | trimSafely }}</div>
      </div>
      <div *ngIf="(viewModel.productTHC$ | async) && (viewModel.productCBD$ | async)" class="cannabinoid-separator">
        |
      </div>
      <div *ngIf="viewModel.productCBD$ | async as cbdValue" class="cannabinoid">
        <div class="cannabinoid-title">CBD</div>
        <div class="cannabinoid-value">{{ cbdValue | trimSafely }}</div>
      </div>
    </div>
  </div>

  <div class="bottom-container">
    <div class="product-brand-and-product">
      <div
        *ngIf="cardData | sectionRowViewModelBasicMethod : 'getBrand' as brand"
        class="product-card-brand"
        [style.color]="viewModel.headerTextColor$ | async">
        {{ brand }}
      </div>
      <div #productCardName class="product-card-name" [style.color]="viewModel.headerTextColor$ | async">
        {{ cardData | sectionRowViewModelBasicMethod : 'getRowTitle' | trimSafely }}
      </div>
    </div>

    <hr
      class="card-separator"
      [style.background-color]="(viewModel.cardAccentColor$ | async) || (viewModel.bodyTextColor$ | async)" />

    <div class="product-card-info">
      <div class="left-side">
        <div class="product-card-badge-and-label-container">
          <img
            *ngFor="let variantBadge of cardData | printCardBadges"
            appImage
            class="product-card-badge"
            [asset]="variantBadge?.image"
            alt="badge"
            src="" />
          <app-label-inflator
            class="print-card-label"
            [columnLabel]="false"
            [labelComponentInterface]="cardData"
            [reset]="false">
          </app-label-inflator>
        </div>

        <div class="strain-and-quantity-container" [style.color]="viewModel.bodyTextColor$ | async">
          <div *ngIf="viewModel.strainText$ | async as strainType" class="product-card-strain-type">
            {{ strainType }}
          </div>
          <div
            *ngIf="(viewModel.strainText$ | async) && (viewModel.productSizeExists$ | async)"
            class="product-card-strain-quantity-separator">
            |
          </div>
          <div *ngIf="viewModel.productSize$ | async as size" class="product-card-quantity">
            {{ size }}
          </div>
        </div>
      </div>

      <div class="product-card-price-container" [style.color]="viewModel.bodyTextColor$ | async">
        <app-column-primary-price
          [menu]="viewModel.cardStackMenu$ | async"
          [section]="viewModel.cardStack$ | async"
          [colVm]="viewModel.spoofedColViewModel$ | async"
          [rowVm]="viewModel.cardData$ | async"
          [locConfig]="viewModel.locationConfig$ | async"
          [locationId]="viewModel.locationId$ | async"
          [productStylingVm]="viewModel"
          [leadingTextOriginalPriceWithSale]="'WAS '"
          [leadingTextSalePrice]="viewModel.virtualLabelText$ | async"
          [includeLineThroughOnLeadingTextOriginalPriceWithSale]="false"
          [ignoreStylingForSaleText]="false">
        </app-column-primary-price>
      </div>
    </div>
  </div>
</div>
