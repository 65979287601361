/* src/app/modules/print-cards/components/print-cards/backgrounds/post-cards-4x6/miss-jones-card-stack-background/miss-jones-card-stack-background.component.scss */
:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
}
:host .color-strip {
  width: 100%;
  background-color: #dab0a8;
}
:host .main-content {
  flex: 1;
}
:host .main-content .print-card-background-asset {
  height: 100%;
  width: 100%;
}
/*# sourceMappingURL=miss-jones-card-stack-background.component.css.map */
