import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { PrintCardBackgroundComponent } from '../../print-card-background.component';
import { MissJonesCardStackBackgroundViewModel } from './miss-jones-card-stack-background-view-model';

@Component({
  selector: 'app-miss-jones-card-stack-background',
  templateUrl: './miss-jones-card-stack-background.component.html',
  styleUrls: ['./miss-jones-card-stack-background.component.scss'],
  providers: [MissJonesCardStackBackgroundViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissJonesCardStackBackgroundComponent extends PrintCardBackgroundComponent {

  constructor(
    public viewModel: MissJonesCardStackBackgroundViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
    this.rendered.next(false);
  }

}
