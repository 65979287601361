<div class="stiiizy-product-information" [style.color]="viewModel.bodyTextColor$ | async">
  <div class="background-content" [style.background-color]="viewModel.stiiizyCardBackgroundColor$ | async">
    <app-print-card-background-image-with-color-overlay-and-opacity [cardData]="cardData">
    </app-print-card-background-image-with-color-overlay-and-opacity>
  </div>
  <div class="top-content">
    <img
      *ngIf="cardData | getVariantAssetForCard | async as variantAsset"
      appImage
      class="brand-logo"
      [class.shrink-brand-logo]="viewModel.shrinkBrandAsset$ | async"
      [asset]="variantAsset"
      src=""
      alt="" />
  </div>
  <div #middleContent class="middle-content">
    <div
      *ngIf="cardData | sectionRowViewModelBasicMethod: 'getRowTitle' as title"
      #title
      class="product-card-variant-title"
      [class.shrink]="viewModel.shrinkCardTitle$ | async"
      [style.color]="viewModel.headerTextColor$ | async"
      appPrintCardScaleDownText
      [maxHeightInPx]="20"
      [maxWidthInPx]="middleContent.offsetWidth"
      [lineHeightScaleVsFontSize]="1"
      [startScalingAfterNMillisecondsAfterViewInit]="500"
      [transparentWhileScalingDebounceTimeInMilliseconds]="501"
      [transparentWhileScaling]="viewModel.viewingInLiveView$ | async">
      {{ title }}
    </div>
    <div *ngIf="viewModel.showCannabinoids$ | async" #cannabinoids class="cannabinoids">
      <div
        *ngIf="cardData | sectionRowViewModelBasicMethod: 'getCannabinoid' : 'THC' as thc"
        class="product-cannabinoid product-card-thc">
        <span>THC</span> {{ thc }}
      </div>
      <div
        *ngIf="cardData | sectionRowViewModelBasicMethod: 'getCannabinoid' : 'CBD' as cbd"
        class="product-cannabinoid product-card-cbd">
        <span>CBD</span> {{ cbd }}
      </div>
    </div>
    <div class="size-and-label">
      <div
        *ngIf="viewModel | getExpandedGridSize: (viewModel.spoofedColViewModel$ | async) : true : false | async as size"
        class="product-card-size">
        {{ size }}
      </div>
      <app-label-inflator
        class="print-card-label"
        [columnLabel]="false"
        [labelComponentInterface]="cardData"
        [reset]="false">
      </app-label-inflator>
    </div>
  </div>
  <div class="bottom-content">
    <div class="product-card-price" [style.color]="viewModel.bodyTextColor$ | async">
      <div
        *ngIf="(cardData?.menu | printCardMenuBasicMethod: 'getNumberOfBadgesForVariant' : cardData) > 0"
        class="badge-container">
        <img
          *ngFor="let variantBadge of cardData | printCardBadges"
          appImage
          class="badge"
          [asset]="variantBadge?.image"
          alt="badge"
          src="" />
      </div>
      <app-column-primary-price
        [menu]="viewModel.cardStackMenu$ | async"
        [section]="viewModel.cardStack$ | async"
        [colVm]="viewModel.spoofedColViewModel$ | async"
        [rowVm]="viewModel.cardData$ | async"
        [locConfig]="viewModel.locationConfig$ | async"
        [locationId]="viewModel.locationId$ | async"
        [productStylingVm]="viewModel">
      </app-column-primary-price>
    </div>
  </div>
</div>
