import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RenderContextComponent } from '../../../../../../../../models/base/render-context-component';
import { NycdIndexCardStackContentViewModel } from '../nycd-index-card-stack-content-view-model';

@Component({
  selector: 'app-nycd-index-price',
  templateUrl: './nycd-index-price.component.html',
  styleUrls: ['./nycd-index-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NycdIndexPriceComponent extends RenderContextComponent {

  constructor(
    public viewModel: NycdIndexCardStackContentViewModel
  ) {
    super();
  }

  setupBindings() {
  }

  setupViews() {
  }

}
