import { Pipe, PipeTransform } from '@angular/core';
import { CardStack } from '../../../models/menu/section/card-stacks/card-stack';

@Pipe({
  name: 'isOnLaserLabelPaper'
})
export class IsOnLaserLabelPaperPipe implements PipeTransform {

  transform(value: CardStack): boolean {
    return value?.isOnLaserLabelPaper() || false;
  }

}
