import { Injectable } from '@angular/core';
import { PrintCardContentViewModel } from '../../print-card-content-view-model';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Injectable()
export class DougIndexCardStackContentViewModel extends PrintCardContentViewModel {

  public titleMaxWidth$ = combineLatest([
    this.cardData$,
    this.isLineItemMode$,
  ]).pipe(
    map(([cardData, isLineItemMode]) => {
      const numOfCols = isLineItemMode ? 1 : cardData?.rowVariants?.length;
      switch (numOfCols) {
        case 2:
          return '11rem';
        case 3:
          return '8rem';
        default:
          return '13rem';
      }
    })
  );

  public badgeAndLabelMaxWidth$ = combineLatest([
    this.cardData$,
    this.isLineItemMode$,
  ]).pipe(
    map(([cardData, isLineItemMode]) => {
      const numOfCols = isLineItemMode ? 1 : cardData?.rowVariants?.length;
      switch (numOfCols) {
        case 2:
          return '8rem';
        case 3:
          return '6rem';
        default:
          return '10rem';
      }
    })
  );

  public gridHas3Columns$ = this.cardData$.pipe(
    map(cardData => cardData?.rowVariants?.length === 3)
  );

}
