import { Deserializable } from '../../protocols/deserializable';

export class MenuColumnCountConfig implements Deserializable {

  defaultMenuColumnCountPortrait: number;
  defaultMenuColumnCountLandscape: number;
  supportedMenuColumnCountPortrait: number[];
  supportedMenuColumnCountLandscape: number[];
  onDeserialize() {
    this.supportedMenuColumnCountPortrait = Array.from(this.supportedMenuColumnCountPortrait || []);
    this.supportedMenuColumnCountLandscape = Array.from(this.supportedMenuColumnCountLandscape || []);
  }

}
