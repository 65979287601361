import { Subscribable } from './subscribable';
import { AfterViewInit, Directive, inject, OnDestroy, OnInit } from '@angular/core';
import { Viewable } from '../protocols/viewable';
import { BehaviorSubject } from 'rxjs';
import { TypeUtils } from '../../utils/type-utils';
import { RenderContextService } from '../../modules/services/render-context.service';

@Directive()
export abstract class RenderContextDirective extends Subscribable
  implements OnInit, AfterViewInit, OnDestroy, Viewable {

  constructor() {
    super();
    this.renderContext = inject(RenderContextService);
  }

  protected renderContext: RenderContextService;

  public rendered = new BehaviorSubject<boolean>(false);
  Types = TypeUtils;

  abstract setupViews();
  abstract setupBindings();

  ngOnInit(): void {
    const renderContextId = this.renderContext.contextId;
    window.injector.isReadyService.signalWithInjectionContextCreated(this.rendered, renderContextId);
    this.setupViews();
  }

  ngAfterViewInit(): void {
    this.rendered.next(true);
    this.setupBindings();
  }

  ngOnDestroy() {
    const renderContextId = this.renderContext.contextId;
    window.injector.isReadyService.signalWithInjectionContextDestroyed(this.rendered, renderContextId);
    super.ngOnDestroy();
  }

}
