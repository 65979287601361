import { NumberUtils } from '../../../../../../../../../utils/number.utils';
import { SectionRowViewModel } from './SectionRowViewModel';
import { UnitOfMeasure } from '../../../../../../../../../models/enum/dto/unit-of-measure.enum';
import { Variant } from '../../../../../../../../../models/product/dto/variant';
import { StringUtils } from '../../../../../../../../../utils/string-utils';

export class SectionRowViewModelSoulBud extends SectionRowViewModel {

  public getSize(): string {
    const variant = this.rowVariants?.firstOrNull();
    if (variant?.unitOfMeasure === UnitOfMeasure.Gram && this.variantLineItemMode) {
      return this.getFormattedUnitSize(variant);
    }
    return super.getSize();
  }

  private getFormattedUnitSize(variant: Variant): string {
    return NumberUtils.formatToMinSigFigDecimals(variant?.unitSize, 1) + variant?.unitOfMeasure;
  }

  override getCannabinoid(cannabinoid: string): string {
    return StringUtils.removeWhiteSpace(super.getCannabinoid(cannabinoid));
  }

}
