import { VariantType } from '../models/enum/dto/variant-type.enum';

// @dynamic
export class VariantTypeUtils {

  static isFlowerByGramType(vt: VariantType): boolean {
    return vt === VariantType.Flower
      || vt === VariantType.BulkFlower
      || vt === VariantType.MilledFlower
      || vt === VariantType.InfusedFlower
      || vt === VariantType.FreeRoll
      || vt === VariantType.Shake;
  }

  static isPreRollType(vt: VariantType): boolean {
    return vt === VariantType.PreRoll
      || vt === VariantType.InfusedPreRoll
      || vt === VariantType.InfusedBlunt
      || vt === VariantType.Blunt;
  }

  static isCapsuleType(vt: VariantType): boolean {
    return vt === VariantType.Capsule;
  }

  static isReadyToDrinkBeverageType(vt: VariantType): boolean {
    return vt === VariantType.Beverage;
  }

  static isOtherTypeWithCannabinoids(vt: VariantType): boolean {
    return vt === VariantType.Bundle
      || vt === VariantType.Pet
      || vt === VariantType.Sample;
  }

}
