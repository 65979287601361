import { Injectable } from '@angular/core';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { LocationConfiguration } from '../models/company/dto/location-configuration';
import { catchError, tap } from 'rxjs/operators';
import { LocationAPI } from '../api/location-api';
import { LoggingService } from '../services/logging-service';
import { CacheService } from '../modules/services/cache-service';
import { CompanyAPI } from '../api/company-api';

@Injectable({ providedIn: 'root' })
export class LocationDomainModel extends BaseDomainModel {

  constructor(
    private cacheService: CacheService,
    private locationAPI: LocationAPI,
    private companyAPI: CompanyAPI,
    private logging: LoggingService,
  ) {
    super();
  }

  private readonly _locationConfig = new BehaviorSubject<LocationConfiguration|null>(null);
  public readonly locationConfig$ = this._locationConfig as Observable<LocationConfiguration|null>;

  public readonly locationTimeZone$: Observable<string> = this.locationConfig$.pipe(
    map(locationConfig => locationConfig?.timezone),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public getLocationConfiguration(companyId: number, locationId: number): Observable<LocationConfiguration> {
    return this.locationAPI.getLocationConfiguration(companyId, locationId).pipe(
      tap(_ => this.logging.loadedLocationConfig(locationId)),
      tap(locationConfig => this._locationConfig.next(locationConfig)),
      tap(locationConfig => this.cacheService.cacheObject(locationConfig.cacheKey(), locationConfig, true)),
      catchError(err => {
        console.error('getLocationConfiguration api error');
        return throwError(err);
      })
    );
  }

  public locationConfigSentFromDashboardApp(locationConfig: LocationConfiguration): void {
    this._locationConfig.next(locationConfig);
  }

}
