import { Deserializable } from '../protocols/deserializable';
import { SizeUnit } from '../enum/dto/size-unit.enum';
import { Orientation } from '../enum/dto/orientation.enum';
import { ObjectHasSelectableWrapper } from '../protocols/object-has-selectable-wrapper';
import { DefaultDisplaySize } from '../enum/shared/default-display-size.enum';

export class Size implements Deserializable, ObjectHasSelectableWrapper {

  public name: string;
  public unit: SizeUnit;
  public orientation: Orientation;
  public height: number;
  public width: number;
  // Display Name
  public displayName: string;

  static empty(): Size {
    const empty = new Size();
    Object.keys(empty)?.forEach(key => empty[key] = undefined);
    return empty;
  }

  public onDeserialize() {
    this.setDisplayName();
  }

  getSelectableUniqueIdentifier(): string {
    return this.name;
  }

  private setDisplayName() {
    switch (this.name) {
      case DefaultDisplaySize.Digital720p:
        this.displayName = '720p';
        break;
      case DefaultDisplaySize.Digital1080p:
        this.displayName = '1080p';
        break;
      case DefaultDisplaySize.Digital4k:
        this.displayName = '4k';
        break;
      case DefaultDisplaySize.Digital8k:
        this.displayName = '8k';
        break;
    }
  }

  public hasChanges(u: Size): boolean {
    return this.orientation !== u.orientation || this.height !== u.height || this.width !== u.width;
  }

  static getDigitalSizeFromPaper(s: Size): Size {
    const defaultDotsPerInch: number = 150; // default DPI is 150 - could be as low as 72 though
    let digitalHeight;
    let digitalWidth: number;
    if (s.unit === SizeUnit.Metric) {
      // convert mm to pixel - 1 inch = 25.4mm
      const imperialHeight = s.height / 25.4;
      const imperialWidth = s.width / 25.4;
      digitalHeight = imperialHeight * defaultDotsPerInch;
      digitalWidth = imperialWidth * defaultDotsPerInch;
    } else if (s.unit === SizeUnit.Imperial) {
      // convert inch to pixel
      digitalHeight = s.height * defaultDotsPerInch;
      digitalWidth = s.width * defaultDotsPerInch;
    } else {
      return s;
    }
    const newSize = new Size();
    newSize.height = Math.round(digitalHeight);
    newSize.width = Math.round(digitalWidth);
    newSize.orientation = s.orientation;
    newSize.name = s.name;
    newSize.displayName = s.displayName;
    return newSize;
  }

}
