import { BaseDomainModel } from '../models/base/base-domain-model';
import { MenuAPI } from '../api/menu-api';
import { TemplateAPI } from '../api/template-api';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { concatMap, distinctUntilChanged, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { exists } from '../functions/exists';
import { PrintCardMenu } from '../models/menu/print-card/print-card-menu';
import { VariantAssetService } from '../modules/services/variant-asset-service';
import { LabelDomainModel } from './label-domain-model';
import { LocationDomainModel } from './location-domain-model';
import { CompanyDomainModel } from './company-domain-model';

type VariantCardCountMap = { [key: string]: number };

@Injectable({ providedIn: 'root' })
export class PrintCardDomainModel extends BaseDomainModel {

  constructor(
    private companyDomainModel: CompanyDomainModel,
    private labelDomainModel: LabelDomainModel,
    private locationDomainModel: LocationDomainModel,
    private menuAPI: MenuAPI,
    private templateAPI: TemplateAPI,
    private variantAssetService: VariantAssetService
  ) {
    super();
  }

  public readonly variantMetadataLoaded$ = this.variantAssetService.variantMetadataLoaded$;

  private readonly _apiRespondedWithMenu = new BehaviorSubject<boolean>(false);
  public readonly apiRespondedWithMenu$ = this._apiRespondedWithMenu as Observable<boolean>;

  private readonly _companyId = new BehaviorSubject<number|null>(null);
  public readonly companyId$ = this._companyId.pipe(distinctUntilChanged());
  public readonly companyConfig$ = this.companyDomainModel.companyConfig$;

  private readonly _locationId = new BehaviorSubject<number|null>(null);
  public readonly locationId$ = this._locationId.pipe(distinctUntilChanged());
  connectToLocationId = (id: number) => this._locationId.next(id);

  public readonly locationConfig$ = this.locationDomainModel.locationConfig$;

  private readonly _printCardMenuId = new BehaviorSubject<string|null>(null);
  public readonly printCardMenuId$ = this._printCardMenuId as Observable<string|null>;
  connectToPrintCardMenuId = (id: string) => this._printCardMenuId.next(id);

  private readonly _partialPrintCardMenuId = new BehaviorSubject<string|null>(null);
  public readonly partialPrintCardMenuId$ = this._partialPrintCardMenuId as Observable<string|null>;
  connectToPartialPrintCardMenuId = (id: string) => this._partialPrintCardMenuId.next(id);

  private readonly _printCardMenuTemplateId = new BehaviorSubject<string|null>(null);
  public readonly printCardMenuTemplateId$ = this._printCardMenuTemplateId as Observable<string|null>;
  connectToPrintCardMenuTemplateId = (id: string) => this._printCardMenuTemplateId.next(id);

  private readonly _partialPrintCardMenuTemplateId = new BehaviorSubject<string|null>(null);
  public readonly partialPrintCardMenuTemplateId$ = this._partialPrintCardMenuTemplateId as Observable<string|null>;
  connectToPartialPrintCardMenuTemplateId = (id: string) => this._partialPrintCardMenuTemplateId.next(id);

  private readonly _overrideVariantIds = new BehaviorSubject<string[]|null>(null);
  public readonly overrideVariantIds$ = this._overrideVariantIds as Observable<string[]|null>;
  connectToOverrideVariantIds = (ids: string[]) => {
    this._overrideVariantIds.next(ids);
    // Only base variantIds need to be passed into the variantAssetService, since siblingVariantIds are specified for
    // stacks that display multiple variants per card, and we don't need assets from the sibling variants
    this.variantAssetService.connectToOverrideVariantIds(ids);
  };

  /**
   * The name overrideSiblingVariantIds is a bit deceptive, override sibling variant ids contain sibling ids for
   * ALL relevant overrideVariantIds.
   *
   * Sibling variant ids are only needed for rendering smart prints, because smart prints narrow down the list of
   * variants to variants that have data changes. We need to show all siblings alongside the changed variant
   * for multi-variant cards.
   */
  private readonly _overrideSiblingVariantIds = new BehaviorSubject<string[]|null>(null);
  public readonly overrideSiblingVariantIds$ = this._overrideSiblingVariantIds as Observable<string[]|null>;
  connectToOverrideSiblingVariantIds = (ids: string[]) => this._overrideSiblingVariantIds.next(ids);

  private readonly _variantCardCountMap = new BehaviorSubject<VariantCardCountMap|null>(null);
  public readonly variantCardCountMap$ = this._variantCardCountMap as Observable<VariantCardCountMap|null>;
  connectToVariantCardCountMap = (x: VariantCardCountMap|null) => this._variantCardCountMap.next(x);

  private _printCardMenuFromAPI = new BehaviorSubject<PrintCardMenu|null>(null);
  private readonly printCardMenuFromAPI$ = this._printCardMenuFromAPI.pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly printCardMenu$: Observable<PrintCardMenu|null> = combineLatest([
    this.printCardMenuFromAPI$,
    this.companyConfig$,
    this.locationConfig$
  ]).pipe(
    map(([menu, companyConfig, locationConfig]) => {
      if (exists(menu) && exists(companyConfig) && exists(locationConfig)) {
        menu.setImplicitProperties(companyConfig, locationConfig);
        return menu;
      }
      return null;
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly cardStack$ = this.printCardMenu$.pipe(
    map(cardStackMenu => cardStackMenu?.sections?.firstOrNull() ?? null),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly cardStackId$ = this.cardStack$.pipe(
    map(cardStack => cardStack?.id ?? null)
  );

  /* *************************** Local Threads of Execution *************************** */

  private fetchCompanyConfig = this.companyId$.notNull().pipe(
    switchMap(companyId => this.companyDomainModel.getCompanyConfig(companyId)),
  ).subscribeWhileAlive({ owner: this });

  private fetchLocationConfig = combineLatest([
    this.companyId$.notNull(),
    this.locationId$.notNull(),
  ]).pipe(
    switchMap(([companyId, locationId]) => this.locationDomainModel.getLocationConfiguration(companyId, locationId)),
  ).subscribeWhileAlive({ owner: this });

  private fetchPrintCardMenuFromAPI = combineLatest([
    this.locationId$.notNull(),
    this.printCardMenuId$.notNull(),
  ]).pipe(
    switchMap(([locationId, printCardMenuId]) => this.getPrintCardMenu(locationId, printCardMenuId)),
  ).subscribeWhileAlive({ owner: this });

  private fetchPartialPrintCardMenuFromAPI = combineLatest([
    this.locationId$.notNull(),
    this.partialPrintCardMenuId$.notNull(),
    this.overrideVariantIds$.notNull()
  ]).pipe(
    switchMap(([locationId, printCardMenuId, variantIds]) => {
      return this.getPartialPrintCardMenu(locationId, printCardMenuId, variantIds);
    }),
  ).subscribeWhileAlive({ owner: this });

  private fetchPrintCardMenuTemplateFromAPI = combineLatest([
    this.locationId$.notNull(),
    this.printCardMenuTemplateId$.notNull()
  ]).pipe(
    switchMap(([locationId, menuTemplateId]) => this.getPrintCardMenuTemplate(locationId, menuTemplateId)),
  ).subscribeWhileAlive({ owner: this });

  private fetchPartialPrintCardMenuTemplateFromAPI = combineLatest([
    this.locationId$.notNull(),
    this.partialPrintCardMenuTemplateId$.notNull(),
    this.overrideVariantIds$.notNull()
  ]).pipe(
    switchMap(([locationId, menuTemplateId, variantIds]) => {
      return this.getPrintCardPartialMenuTemplate(locationId, menuTemplateId, variantIds);
    }),
  ).subscribeWhileAlive({ owner: this });

  /* ********************************************************************************** */

  public getPrintCardMenu(locationId: number, printCardMenuId: string): Observable<PrintCardMenu> {
    return this.menuAPI.GetMenu(locationId, printCardMenuId).pipe(
      concatMap(menu => this.labelDomainModel.getLabels(locationId, menu?.companyId).pipe(map(() => menu))),
      tap((menu: PrintCardMenu) => this.menuWasReturnedFromApi(menu))
    );
  }

  public getPartialPrintCardMenu(
    locationId: number,
    printCardMenuId: string,
    variantIds: string[],
  ): Observable<PrintCardMenu> {
    return this.menuAPI.GetPartialMenu(locationId, printCardMenuId, variantIds).pipe(
      concatMap(menu => this.labelDomainModel.getLabels(locationId, menu?.companyId).pipe(map(() => menu))),
      tap((menu: PrintCardMenu) => this.menuWasReturnedFromApi(menu))
    );
  }

  public getPrintCardMenuTemplate(locationId: number, printCardMenuTemplateId: string): Observable<PrintCardMenu> {
    return this.templateAPI.GetMenuTemplate(locationId, printCardMenuTemplateId).pipe(
      concatMap(menu => this.labelDomainModel.getLabels(locationId, menu?.companyId).pipe(map(() => menu))),
      tap((menu: PrintCardMenu) => this.menuWasReturnedFromApi(menu))
    );
  }

  public getPrintCardPartialMenuTemplate(
    locationId: number,
    printCardMenuTemplateId: string,
    variantIds: string[],
  ): Observable<PrintCardMenu> {
    return this.templateAPI.GetPartialMenuTemplate(locationId, printCardMenuTemplateId, variantIds).pipe(
      concatMap(menu => this.labelDomainModel.getLabels(locationId, menu?.companyId).pipe(map(() => menu))),
      tap((menu: PrintCardMenu) => this.menuWasReturnedFromApi(menu))
    );
  }

  private menuWasReturnedFromApi(menu: PrintCardMenu): void {
    this._companyId.next(menu?.companyId ?? null);
    this.variantAssetService.fetchVariantAssetsForMenusAssignedToDisplay([menu]);
    this._printCardMenuFromAPI.next(menu);
    this._apiRespondedWithMenu.next(true);
  }

}
