import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { PrintCardBackgroundComponent } from '../../print-card-background.component';
import { FikaEdiblesIndexCardStackBackgroundViewModel } from './fika-edibles-index-card-stack-background-view-model';

@Component({
  selector: 'app-fika-edibles-index-card-stack-background',
  templateUrl: './fika-edibles-index-card-stack-background.component.html',
  styleUrls: ['./fika-edibles-index-card-stack-background.component.scss'],
  providers: [FikaEdiblesIndexCardStackBackgroundViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FikaEdiblesIndexCardStackBackgroundComponent extends PrintCardBackgroundComponent {

  constructor(
    public viewModel: FikaEdiblesIndexCardStackBackgroundViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

}
