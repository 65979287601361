import { Component, forwardRef } from '@angular/core';
import { ProductSectionHeaderComponent } from '../product-section-header/product-section-header.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CompanyDomainModel } from '../../../../../../../../domain/company-domain-model';

@Component({
  selector: 'app-double-dutch-section-header',
  templateUrl: './double-dutch-section-header.component.html',
  providers: [
    { provide: ProductSectionHeaderComponent, useExisting: forwardRef(() => DoubleDutchSectionHeaderComponent) }
  ]
})
export class DoubleDutchSectionHeaderComponent extends ProductSectionHeaderComponent {

  constructor(
    companyDomainModel: CompanyDomainModel,
    sanitizer: DomSanitizer
  ) {
    super(companyDomainModel, sanitizer);
  }

}
