import { Component, ChangeDetectionStrategy, ElementRef, Renderer2 } from '@angular/core';
import { PrintCardContentComponent } from '../../print-card-content.component';
import { PlainJanePostCardStackContentViewModel } from './plain-jane-post-card-stack-content-view-model';

@Component({
  selector: 'app-plain-jane-post-card-stack-content',
  templateUrl: './plain-jane-post-card-stack-content.component.html',
  styleUrls: ['./plain-jane-post-card-stack-content.component.scss'],
  providers: [PlainJanePostCardStackContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlainJanePostCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public override viewModel: PlainJanePostCardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

}
