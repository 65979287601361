<div
  #sectionItem
  class="product-container"
  id="product-container-m{{ menu.id }}-s{{ sectionIndex }}-i{{ itemIndex }}"
  [ngClass]="[
    rowViewModel?.getReadableStrainType(Types.SectionConfigDataValue.StrainTypeWord) | lowercase,
    menu.getProductWrapperClass(odd)
  ]"
  [class.odd]="odd"
  [class.even]="!odd"
  [class.first]="first"
  [class.last]="last"
  [class.force-bold-style]="viewModel.forcedRowBoldStyling$ | async"
  [class.force-italic-style]="viewModel.forcedRowItalicStyling$ | async"
  [class.has-background-color]="viewModel.rowBackgroundColor$ | async | getArrayElementAtIndex: 1"
  [ngStyle]="viewModel.productWrappingStyle$ | async"
  [style.background-color]="viewModel.rowBackgroundColor$ | async | getArrayElementAtIndex: 1"
  [style.color]="viewModel.forcedRowTextColorToDisplay$ | async"
  [style.text-decoration-color]="viewModel.forcedRowTextColor$ | async"
  [style.zoom]="viewModel.forcedRowZoom$ | async"
  [style.border-color]="viewModel.sectionBorderColor$ | async">
  <ng-container *ngFor="let colVm of viewModel.columnViewModels$ | async">
    <!--    Lay out product title column    -->
    <div
      *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.ProductTitle"
      [class.default-background-color]="!(viewModel | columnBackgroundColor: colVm | async)"
      class="product-title-container">
      <div class="flex-container product-title-flex-container">
        <div
          *ngIf="menu.getShowStrainTypes() && menu.getShowStrainTypesOnTitleLineElseSubtitle()"
          [ngStyle]="{ 'background-color': rowViewModel.getStrainTypeColor() }"
          class="base-product-strain-type">
          {{ rowViewModel.getReadableStrainType(colVm.strainTypeMode) }}
        </div>
        <div class="product-title">{{ rowViewModel.getRowTitle() }}</div>
        <app-label-inflator
          *ngIf="menu.getShowLabelsOnTitleLineElseSubtitle() && !rowViewModel.hideLabel"
          class="product-label-container"
          [labelComponentInterface]="rowViewModel">
        </app-label-inflator>
      </div>
      <div class="flex-container subtitle-flex-container">
        <div
          *ngIf="menu.getShowStrainTypes() && !menu.getShowStrainTypesOnTitleLineElseSubtitle()"
          [ngStyle]="{ 'background-color': rowViewModel.getStrainTypeColor() }"
          class="base-product-strain-type">
          {{ rowViewModel.getReadableStrainType(colVm.strainTypeMode) }}
        </div>
        <div
          *ngIf="
            (rowViewModel | sectionRowViewModelBasicMethod: 'getProductSubtitle') &&
            (menu | productMenuBasicMethod: 'getShowProductSubtitle')
          "
          class="product-subtitle">
          {{ rowViewModel | sectionRowViewModelBasicMethod: 'getProductSubtitle' }}
        </div>
        <app-label-inflator
          *ngIf="!menu.getShowLabelsOnTitleLineElseSubtitle() && !rowViewModel.hideLabel"
          class="product-label-container"
          [labelComponentInterface]="rowViewModel">
        </app-label-inflator>
      </div>
    </div>

    <app-column-wrapper
      [menu]="menu"
      [section]="section"
      [colVm]="colVm"
      [rowVm]="rowViewModel"
      [locConfig]="locationConfig"
      [locationId]="locationId"
      [productStylingVm]="viewModel">
      <app-column-primary-price
        *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.VariantPrice"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [rowVm]="rowViewModel"
        [locConfig]="locationConfig"
        [locationId]="locationId"
        [productStylingVm]="viewModel">
      </app-column-primary-price>
      <app-column-secondary-price
        *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.VariantSecondaryPrice"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [rowVm]="rowViewModel"
        [locConfig]="locationConfig"
        [locationId]="locationId"
        [productStylingVm]="viewModel">
      </app-column-secondary-price>
      <app-column-quantity-and-size
        *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.QuantityAndSize"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [rowVm]="rowViewModel"
        [locConfig]="locationConfig"
        [locationId]="locationId"
        [productStylingVm]="viewModel">
      </app-column-quantity-and-size>
      <app-column-quantity-and-size
        *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.QuantityAndSize"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [rowVm]="rowViewModel"
        [locConfig]="locationConfig"
        [locationId]="locationId"
        [productStylingVm]="viewModel"
        [useNgContent]="true">
        {{ rowViewModel | sectionRowViewModelBasicMethod: 'getQuantityAndSizeString' }}
        <div class="product-quantity-and-size-secondary">{{ colVm.columnTitle }}</div>
      </app-column-quantity-and-size>
      <app-column-quantity
        *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.Quantity"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [rowVm]="rowViewModel"
        [locConfig]="locationConfig"
        [locationId]="locationId"
        [productStylingVm]="viewModel"
        [useNgContent]="true">
        <div>{{ rowViewModel | sectionRowViewModelBasicMethod: 'getQuantityString' }}</div>
        <div class="product-quantity-secondary">{{ colVm.columnTitle }}</div>
      </app-column-quantity>
      <app-column-strain-type
        *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.StrainType"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [rowVm]="rowViewModel"
        [locConfig]="locationConfig"
        [locationId]="locationId"
        [productStylingVm]="viewModel"
        [useNgContent]="true">
        {{ rowViewModel | sectionRowViewModelBasicMethod: 'getReadableStrainType' : colVm.strainTypeMode }}
        <div class="product-strain-type">{{ colVm.columnTitle }}</div>
      </app-column-strain-type>
      <app-column-cannabinoid
        *ngIf="colVm.columnType | isCannabinoidColumn as cannabinoidColumnType"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [rowVm]="rowViewModel"
        [locConfig]="locationConfig"
        [locationId]="locationId"
        [productStylingVm]="viewModel"
        [cannabinoidColumnType]="cannabinoidColumnType"
        [useNgContent]="true">
        <div>{{ rowViewModel | sectionRowViewModelBasicMethod: 'getCannabinoid' : cannabinoidColumnType }}</div>
        <div class="product-cannabinoid-secondary">{{ colVm.columnTitle }}</div>
      </app-column-cannabinoid>
      <app-column-terpene
        *ngIf="colVm.columnType | isTerpeneColumn as terpeneColumnType"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [rowVm]="rowViewModel"
        [locConfig]="locationConfig"
        [locationId]="locationId"
        [productStylingVm]="viewModel"
        [terpeneColumnType]="terpeneColumnType"
        [useNgContent]="true">
        <div>{{ rowViewModel | sectionRowViewModelBasicMethod: 'getTerpene' : terpeneColumnType }}</div>
        <div class="product-terpene-secondary">{{ colVm.columnTitle }}</div>
      </app-column-terpene>
      <app-column-producer
        *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.Producer"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [rowVm]="rowViewModel"
        [locConfig]="locationConfig"
        [locationId]="locationId"
        [productStylingVm]="viewModel"
        [useNgContent]="true">
        <div>{{ (rowViewModel | sectionRowViewModelBasicMethod: 'getBrand') || '&nbsp;' }}</div>
        <div class="product-producer-secondary">{{ colVm.columnTitle }}</div>
      </app-column-producer>
      <app-column-brand
        *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.Brand"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [rowVm]="rowViewModel"
        [locConfig]="locationConfig"
        [locationId]="locationId"
        [productStylingVm]="viewModel"
        [useNgContent]="true">
        <div>{{ rowViewModel | sectionRowViewModelBasicMethod: 'getBrand' }}</div>
        <div class="product-brand-secondary">{{ colVm.columnTitle }}</div>
      </app-column-brand>
      <app-column-spacer
        *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.Spacer"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [rowVm]="rowViewModel"
        [locConfig]="locationConfig"
        [locationId]="locationId"
        [productStylingVm]="viewModel">
      </app-column-spacer>
      <app-column-tiny-spacer
        *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.TinySpacer"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [rowVm]="rowViewModel"
        [locConfig]="locationConfig"
        [locationId]="locationId"
        [productStylingVm]="viewModel">
      </app-column-tiny-spacer>
      <app-column-badge
        *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.Badge"
        [menu]="menu"
        [section]="section"
        [colVm]="colVm"
        [rowVm]="rowViewModel"
        [locConfig]="locationConfig"
        [locationId]="locationId"
        [productStylingVm]="viewModel">
      </app-column-badge>
    </app-column-wrapper>
  </ng-container>
</div>
