import { Menu } from './menu';

export class MenuToDisplay {

  public menu: Menu;
  public displayMeForXSeconds: number;

  constructor(m: Menu, displayTimeInSeconds: number) {
    this.menu = m;
    this.displayMeForXSeconds = displayTimeInSeconds;
  }

}
