import { Pipe, PipeTransform } from '@angular/core';
import { CardStack } from '../../../models/menu/section/card-stacks/card-stack';

@Pipe({
  name: 'isOnPerforatedPaper'
})
export class IsOnPerforatedPaperPipe implements PipeTransform {

  transform(value: CardStack): boolean {
    return value?.isOnPerforatedPaper() || false;
  }

}
