<div class="main-content">
  <div
    *ngIf="cardData | sectionRowViewModelBasicMethod : 'getRowTitle' as title"
    class="product-card-name"
    [style.color]="viewModel.headerTextColor$ | async">
    {{ title | trimSafely }}
  </div>
  <div class="product-card-info">
    <div class="product-card-badge-container">
      <img
        *ngFor="let variantBadge of cardData | printCardBadges"
        appImage
        class="product-card-badge"
        [asset]="variantBadge?.image"
        alt="badge"
        src="" />
    </div>
    <div class="pricing-container" [style.color]="viewModel.bodyTextColor$ | async">
      <app-column-primary-price
        [menu]="viewModel.cardStackMenu$ | async"
        [section]="viewModel.cardStack$ | async"
        [colVm]="viewModel.spoofedColViewModel$ | async"
        [rowVm]="viewModel.cardData$ | async"
        [locConfig]="viewModel.locationConfig$ | async"
        [locationId]="viewModel.locationId$ | async"
        [productStylingVm]="viewModel">
      </app-column-primary-price>
    </div>
    <div
      *ngIf="!(viewModel.isAccessory$ | async) && !(viewModel.isNonCannabinoidOtherVariant$ | async)"
      class="product-card-size"
      [style.color]="viewModel.bodyTextColor$ | async">
      {{ viewModel.quantityAndSizeText$ | async | trimSafely }}
    </div>
  </div>
</div>
