/* src/app/modules/display/components/menus/product-menu/building-blocks/menu-footer/badge-legend-footer/badge-legend-footer.component.scss */
.footer-classification-board {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 1.5rem;
  column-gap: 1rem;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 0.5rem;
  padding: 0.75rem;
}
.footer-classification-board .badge-in-legend {
  width: 2rem;
}
.classification-mapping {
  width: 5.455rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.classification-name {
  padding-top: 0.5rem;
  font-size: 0.75rem;
  text-align: center;
}
.footer-container {
  padding-inline: 0;
}
/*# sourceMappingURL=badge-legend-footer.component.css.map */
