import { Deserializable } from '../protocols/deserializable';
import { DateUtils } from '../../utils/date-utils';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import { exists } from '../../functions/exists';
import moment from 'moment-timezone';
import type { LocalizedDateRange } from './localized-date-range';
import type { WeekDay } from '@angular/common';

export abstract class TimeDuration implements Deserializable, UniquelyIdentifiable {

  constructor(
    testTimestampAt?: number,
    testingTimezone?: string,
    startTime?: string,
    endTime?: string,
    days?: WeekDay[]
  ) {
    this.testTimestampAt = testTimestampAt || undefined;
    this.testingTimezone = testingTimezone || undefined;
    this.startTime = startTime || '';
    this.endTime = endTime || '';
    this.days = days || [];
  }

  protected readonly testTimestampAt: number; // for testing purposes only
  protected readonly testingTimezone: string; // for testing purposes only
  public startTime: string; // hours:minutes:seconds
  public endTime: string; // hours:minutes:seconds
  public days: WeekDay[];
  public abstract dateTimeWindows: LocalizedDateRange[];
  public abstract deserializeLocalizedDateRange(): void;
  protected abstract getCurrentDateTimeZoned(timezone?: string): moment.Moment;

  public onDeserialize() {
    this.days = Array.from(this.days || []);
    this.deserializeLocalizedDateRange();
  }

  public hasIntervalOrTimeWindow(): boolean {
    const hasRecurringDetails = this.days?.length > 0 && exists(this.startTime) && exists(this.endTime);
    const hasTimeWindows = this.dateTimeWindows?.length > 0;
    return hasRecurringDetails || hasTimeWindows;
  }

  public isActive(timezone?: string): boolean {
    const hasRecurringInterval = this.days?.length > 0 && exists(this.startTime) && exists(this.endTime);
    const hasDateTimeWindows = this.dateTimeWindows?.length > 0;
    switch (true) {
      case hasRecurringInterval && hasDateTimeWindows:
        return this.doesTodayFallWithinRecurringInterval(timezone) && this.doesTodayFallWithinDateTimeWindow(timezone);
      case hasRecurringInterval:
        return this.doesTodayFallWithinRecurringInterval(timezone);
      case hasDateTimeWindows:
        return this.doesTodayFallWithinDateTimeWindow(timezone);
      default:
        return false;
    }
  }

  private doesTodayFallWithinDateTimeWindow(timezone?: string): boolean {
    return this.dateTimeWindows?.some(dtw => dtw?.isRightNowWithinDateTimeWindow(timezone));
  }

  /**
   * secondsStart and secondsEnd are time zone independent. Only the local time considers a time zone conversion.
   */
  private doesTodayFallWithinRecurringInterval(timezone?: string): boolean {
    if (this.doesTodayFallWithinDaysInterval(timezone)) {
      const secondsNow = DateUtils.getSecondsSinceStartOfDay(this.getCurrentDateTimeZoned(timezone));
      const secondsStart = this.getStartTimeSecondsEncodedSinceStartOfDay();
      const secondsEnd = this.getEndTimeSecondsEncodedSinceStartOfDay();
      if (secondsStart >= 0 && secondsEnd >= 0) {
        const isAfterStartTime = (secondsNow >= secondsStart);
        const isBeforeEndTime = (secondsNow <= secondsEnd);
        return isAfterStartTime && isBeforeEndTime;
      }
    } else {
      return false;
    }
  }

  private doesTodayFallWithinDaysInterval(timezone?: string): boolean {
    const date = this.getCurrentDateTimeZoned(timezone);
    const day = date.day();
    return this.days?.contains(day);
  }

  /**
   * Returns seconds value of encoded time string since start-of-day (0 in military time);
   * -1 if error
   *
   * @param str encoded time as hours:minutes:seconds
   */
  private getSecondsSinceStartOfDay(str: string): number {
    if (!!str) {
      const time = str.split(':');
      try {
        const hrsInSecs = (Number.parseInt(time[0], 10) * 3600);
        const minsInSecs = (Number.parseInt(time[1], 10) * 60);
        const secs = ((Number.parseInt(time[2], 10)) || 0);
        return (hrsInSecs + minsInSecs + secs);
      } catch (e) {
        return -1;
      }
    } else {
      return -1;
    }
  }

  /**
   * 12:30 pm would be encoded into (43200+1800+0) = 450000 seconds
   * -1 if error
   */
  private getStartTimeSecondsEncodedSinceStartOfDay(): number {
    return this.getSecondsSinceStartOfDay(this.startTime);
  }

  /**
   * 12:30 pm would be encoded into (43200+1800+0) = 450000 seconds
   * -1 if error
   */
  private getEndTimeSecondsEncodedSinceStartOfDay(): number {
    return this.getSecondsSinceStartOfDay(this.endTime);
  }

  setDateTimeWindows(dateTimeWindows: LocalizedDateRange[]): void {
    this.dateTimeWindows = dateTimeWindows || [];
  }

  /**
   * legacy code for checking active location promotion
   */
  public isLocationPromotionActive(): boolean {
    const fallsOnRecurringDay = this.days.contains(DateUtils.currentWeekday());
    const secondsNow = DateUtils.getSecondsSinceStartOfDay();
    const secondsStart = this.getSecondsSinceStartOfDay(this.startTime);
    const secondsEnd = this.getSecondsSinceStartOfDay(this.endTime);
    const withinRecurringTimeWindow = secondsStart >= 0 && secondsEnd >= 0
      ? (secondsNow >= secondsStart) && (secondsNow <= secondsEnd)
      : false;
    return fallsOnRecurringDay && withinRecurringTimeWindow;
  }

  getUniqueIdentifier(): string {
    const dateTimeWindowsId = this.dateTimeWindows?.map(dtw => dtw?.getUniqueIdentifier())?.sort();
    return `
      -${this.startTime}
      -${this.endTime}
      -${this.days?.sort()?.toStringArray() ?? ''}
      -${dateTimeWindowsId?.toStringArray() ?? ''}
    `;
  }

}
