<div #headerContainer>
  <div
    *ngIf="!!(menu | productMenuBasicMethod: 'getSectionTitle' : section)"
    class="section-title"
    [style.background-color]="section?.metadata?.productsHeaderBackgroundColor"
    [style.color]="section?.metadata?.sectionHeaderTextColor"
    [style.background-size]="'cover'"
    [style.zoom]="menu?.menuOptions?.productZoom || 1.0">
    <div class="section-title-text">{{ menu | productMenuBasicMethod: 'getSectionTitle' : section }}</div>
  </div>
  <div
    class="section-header-container"
    [style.background-color]="section?.metadata?.productsHeaderBackgroundColor"
    [style.zoom]="menu?.menuOptions?.productZoom || 1.0"
    [style.border-bottom-color]="menu | productMenuBasicMethod: 'getSectionTitleUnderlineColor' : section">
    <ng-container *ngFor="let col of columnVMs">
      <div
        *ngIf="menu | getShouldInflateColumnHeaderForRow: section : col : sectionRowViewModels"
        class="section-column"
        [ngClass]="{ 'section-column-title': col.columnType === Types.SectionColumnProductInfoType.ProductTitle }"
        [style.width.%]="col.widthPercentage"
        [style.color]="menu | productMenuBasicMethod: 'getSectionHeaderTextColor' : section"
        [hidden]="menu | getShouldHideHeaderContent: section : col">
        <app-header-column-variant-price
          *ngIf="col.columnType === Types.SectionColumnProductInfoType.VariantPrice"
          [menu]="menu"
          [section]="section"
          [colVm]="col">
        </app-header-column-variant-price>
        <app-header-column-variant-secondary-price
          *ngIf="col.columnType === Types.SectionColumnProductInfoType.VariantSecondaryPrice"
          [menu]="menu"
          [section]="section"
          [colVm]="col">
        </app-header-column-variant-secondary-price>
        <app-header-column-quantity-in-stock
          *ngIf="col.columnType === Types.SectionColumnProductInfoType.Stock"
          [menu]="menu"
          [section]="section"
          [colVm]="col">
        </app-header-column-quantity-in-stock>
        <app-header-column-quantity
          *ngIf="col.columnType === Types.SectionColumnProductInfoType.Quantity"
          [menu]="menu"
          [section]="section"
          [colVm]="col">
        </app-header-column-quantity>
        <div
          *ngIf="col.columnType === Types.SectionColumnProductInfoType.ProductTitle"
          appScaleDownText
          [style.color]="menu | productMenuBasicMethod: 'getSectionHeaderTextColor' : section"
          [scaleEnabled]="!(menu | isPrintMenu)"
          class="section-header-general section-header-product-title">
          Product Name
        </div>
        <app-header-column-size
          *ngIf="col.columnType === Types.SectionColumnProductInfoType.Size"
          [menu]="menu"
          [section]="section"
          [colVm]="col">
        </app-header-column-size>
        <app-header-column-strain-type
          *ngIf="col.columnType === Types.SectionColumnProductInfoType.StrainType"
          [menu]="menu"
          [section]="section"
          [colVm]="col">
        </app-header-column-strain-type>
        <app-header-column-cannabinoid
          *ngIf="col?.columnType | isCannabinoidColumn as cannabinoidColumnType"
          [menu]="menu"
          [section]="section"
          [colVm]="col"
          [sectionRowViewModels]="sectionRowViewModels"
          [cannabinoidColumnType]="cannabinoidColumnType">
        </app-header-column-cannabinoid>
        <app-header-column-terpene
          *ngIf="col?.columnType | isTerpeneColumn as terpeneColumnType"
          [menu]="menu"
          [section]="section"
          [colVm]="col"
          [sectionRowViewModels]="sectionRowViewModels"
          [terpeneColumnType]="terpeneColumnType">
        </app-header-column-terpene>
        <app-header-column-producer
          *ngIf="col.columnType === Types.SectionColumnProductInfoType.Producer"
          [menu]="menu"
          [section]="section"
          [colVm]="col">
        </app-header-column-producer>
        <app-header-column-badge
          *ngIf="col.columnType === Types.SectionColumnProductInfoType.Badge"
          [menu]="menu"
          [section]="section"
          [colVm]="col">
        </app-header-column-badge>
        <app-header-column-brand
          *ngIf="col.columnType === Types.SectionColumnProductInfoType.Brand"
          [menu]="menu"
          [section]="section"
          [colVm]="col">
        </app-header-column-brand>
      </div>
    </ng-container>
  </div>
</div>
