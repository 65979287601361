import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { PrintLabelBackgroundComponent } from '../../print-label-background.component';
import { BaseInventoryLabelStackBackgroundViewModel } from './base-inventory-label-stack-background-view-model';

@Component({
  selector: 'app-base-inventory-label-stack-background',
  templateUrl: './base-inventory-label-stack-background.component.html',
  styleUrls: ['./base-inventory-label-stack-background.component.scss'],
  providers: [BaseInventoryLabelStackBackgroundViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseInventoryLabelStackBackgroundComponent extends PrintLabelBackgroundComponent {

  constructor(
    public viewModel: BaseInventoryLabelStackBackgroundViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

}
