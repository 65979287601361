import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import type { SectionColumnViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import type { LocationConfiguration } from '../../../models/company/dto/location-configuration';
import type { ProductStylingViewModel } from '../../../models/shared/product-styling-view-model';

@Pipe({
  name: 'forcedColumnTextColor'
})
export class ForcedColumnTextColorPipe implements PipeTransform {

  transform(
    productStylingViewModel: ProductStylingViewModel,
    columnViewModel: SectionColumnViewModel,
    locationConfig: LocationConfiguration
  ): Observable<string> {
    return productStylingViewModel?.forcedColumnTextColor(columnViewModel, locationConfig) || of(null);
  }

}
