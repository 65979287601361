import { PrintCardMenu } from './print-card-menu';
import { AssetLibraryType } from '../../enum/dto/asset-library-type.enum';

export class TrueNorthPortraitPrintCardMenu extends PrintCardMenu {

  getFontFaceList(): string[] {
    return ['gilroy', 'montserrat'];
  }

  shouldFetchVariantAssets(): boolean {
    return true;
  }

  variantAssetTypeSortOrder(): string[] {
    return [
      AssetLibraryType.PrimaryBrand,
      AssetLibraryType.AlternateBrand,
      AssetLibraryType.Brand,
    ];
  }

  shouldStandardizeDominantStrainType(): boolean {
    return false;
  }

}
