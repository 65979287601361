<div
  *ngIf="colVm?.columnType === Types.SectionColumnProductInfoType.Stock"
  appScaleDownText
  [scaleEnabled]="!(menu | isPrintMenu)"
  [style.color]="
    ignoreUserSetTextColorAndAutoToLightOrDarkTextColor
      ? (section | textColorBasedOnSectionHeaderBackgroundColor)
      : (menu | productMenuBasicMethod: 'getSectionHeaderTextColor' : section)
  "
  [scaleDownLinkedKey]="section?.id + ((section | isInLineMode) ? Types.TextSizeLink.PricePreRollQuantitySize : null)"
  class="section-header-general section-header-quantity-in-stock">
  {{ menu | productMenuBasicMethod: 'getQuantityInStockColumnTitle' : colVm }}
</div>
