import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PopsCannabis5By5CardStackContentViewModel } from '../pops-cannabis-5-by-5-card-stack-content-view-model';
import { RenderContextComponent } from '../../../../../../../../models/base/render-context-component';
import { SectionColumnViewModel } from '../../../../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';

@Component({
  selector: 'app-pops-cannabis-regular-price',
  templateUrl: './pops-cannabis-price.component.html',
  styleUrls: ['./pops-cannabis-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopsCannabisPriceComponent extends RenderContextComponent {

  constructor(
    public viewModel: PopsCannabis5By5CardStackContentViewModel
  ) {
    super();
  }

  @Input() colVm: SectionColumnViewModel;

  setupViews() {}
  setupBindings() {}

}
