<div
  #headerContainer
  class="section-header-container"
  [ngStyle]="{
    'background-color':
      (menu | productMenuBasicMethod: 'getShowSectionHeaderColorAsIcon')
        ? null
        : section?.metadata?.productsHeaderBackgroundColor,
    'background-size': 'cover',
    'background-image': headerBackgroundAsset$ | async,
    zoom: menu?.menuOptions?.productZoom || 1.0
  }">
  <ng-container *ngFor="let col of columnVMs">
    <div
      *ngIf="menu | getShouldInflateColumnHeaderForRow: section : col : sectionRowViewModels"
      class="section-column"
      [ngClass]="{ 'section-column-title': col.columnType === Types.SectionColumnProductInfoType.ProductTitle }"
      [style.width.%]="col.widthPercentage"
      [hidden]="menu | getShouldHideHeaderContent: section : col">
      <div
        *ngIf="col.columnType === Types.SectionColumnProductInfoType.ProductTitle"
        class="section-title"
        appScaleDownText
        [style.color]="menu | productMenuBasicMethod: 'getSectionHeaderTextColor' : section"
        [scaleEnabled]="!(menu | isPrintMenu)">
        {{ menu | productMenuBasicMethod: 'getSectionTitle' : section }}
        <div
          *ngIf="
            (menu | productMenuBasicMethod: 'getShowSectionHeaderColorAsIcon') &&
            !!section?.metadata?.productsHeaderBackgroundColor
          "
          class="section-title-icon"
          [style.background-color]="section?.metadata?.productsHeaderBackgroundColor"></div>
      </div>
      <!-- see [1] before changing formatting -->
      <app-header-column-variant-price
        *ngIf="col.columnType === Types.SectionColumnProductInfoType.VariantPrice"
        [menu]="menu"
        [section]="section"
        [colVm]="col">
      </app-header-column-variant-price>
      <app-header-column-variant-secondary-price
        *ngIf="col.columnType === Types.SectionColumnProductInfoType.VariantSecondaryPrice"
        [menu]="menu"
        [section]="section"
        [colVm]="col">
      </app-header-column-variant-secondary-price>
      <app-header-column-quantity-and-size
        *ngIf="col.columnType === Types.SectionColumnProductInfoType.QuantityAndSize"
        [menu]="menu"
        [section]="section"
        [colVm]="col">
      </app-header-column-quantity-and-size>
      <app-header-column-quantity
        *ngIf="col.columnType === Types.SectionColumnProductInfoType.Quantity"
        [menu]="menu"
        [section]="section"
        [colVm]="col">
      </app-header-column-quantity>
      <app-header-column-size
        *ngIf="col.columnType === Types.SectionColumnProductInfoType.Size"
        [menu]="menu"
        [section]="section"
        [colVm]="col">
      </app-header-column-size>
      <app-header-column-asset
        *ngIf="col.columnType === Types.SectionColumnProductInfoType.Asset"
        [menu]="menu"
        [section]="section"
        [colVm]="col">
      </app-header-column-asset>
      <app-header-column-strain-type
        *ngIf="col.columnType === Types.SectionColumnProductInfoType.StrainType"
        [menu]="menu"
        [section]="section"
        [colVm]="col">
      </app-header-column-strain-type>
      <app-header-column-cannabinoid
        *ngIf="col?.columnType | isCannabinoidColumn as cannabinoidColumnType"
        [menu]="menu"
        [section]="section"
        [colVm]="col"
        [sectionRowViewModels]="sectionRowViewModels"
        [cannabinoidColumnType]="cannabinoidColumnType">
      </app-header-column-cannabinoid>
      <app-header-column-terpene
        *ngIf="col?.columnType | isTerpeneColumn as terpeneColumnType"
        [menu]="menu"
        [section]="section"
        [colVm]="col"
        [sectionRowViewModels]="sectionRowViewModels"
        [terpeneColumnType]="terpeneColumnType">
      </app-header-column-terpene>
      <app-header-column-producer
        *ngIf="col.columnType === Types.SectionColumnProductInfoType.Producer"
        [menu]="menu"
        [section]="section">
      </app-header-column-producer>
      <app-header-column-badge
        *ngIf="col.columnType === Types.SectionColumnProductInfoType.Badge"
        [menu]="menu"
        [section]="section"
        [colVm]="col">
      </app-header-column-badge>
      <app-header-column-brand
        *ngIf="col.columnType === Types.SectionColumnProductInfoType.Brand"
        [menu]="menu"
        [section]="section"
        [colVm]="col">
      </app-header-column-brand>
    </div>
  </ng-container>
  <div [style.background-color]="section?.metadata?.productsHeaderBackgroundColor" class="gap-filler"></div>
</div>
