<div
  *ngIf="colVm?.columnType === Types.SectionColumnProductInfoType.ProductTitle"
  class="section-title"
  [style.color]="
    ignoreUserSetTextColorAndAutoToLightOrDarkTextColor
      ? (section | textColorBasedOnSectionHeaderBackgroundColor)
      : (menu | productMenuBasicMethod: 'getSectionHeaderTextColor' : section)
  ">
  <div class="section-title-text">
    {{ menu | productMenuBasicMethod: 'getSectionTitle' : section }}
  </div>
  <div
    *ngIf="
      (menu | productMenuBasicMethod: 'getShowSectionHeaderColorAsIcon') &&
      !!section?.metadata?.productsHeaderBackgroundColor
    "
    class="section-title-icon"
    [style.background-color]="section?.metadata?.productsHeaderBackgroundColor"></div>
</div>
