import { Deserializable } from '../../protocols/deserializable';
import { Variant } from './variant';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';

export class VariantFeature implements Deserializable, UniquelyIdentifiable {

  public title: string;
  public variantIds: string[];
  public variants: Variant[] = [];
  public descriptionOverrideMap: Map<string, string>;
  public assetNameMap: Map<string, string>;
  public priceOverrideMap: Map<string, number>;
  public colorMap: Map<string, string>; // variantId, color

  public onDeserialize() {
    this.variantIds = Array.from(this.variantIds || []);
    this.variants = window?.injector?.Deserialize?.arrayOf(Variant, this.variants);
    if (!(this.descriptionOverrideMap instanceof Map)) {
      this.descriptionOverrideMap = window?.injector?.Deserialize?.genericMap(this.descriptionOverrideMap);
    }
    if (!(this.assetNameMap instanceof Map)) {
      this.assetNameMap = window?.injector?.Deserialize?.genericMap(this.assetNameMap);
    }
    if (!(this.priceOverrideMap instanceof Map)) {
      this.priceOverrideMap = window?.injector?.Deserialize?.genericMap(this.priceOverrideMap);
    }
    if (!(this.colorMap instanceof Map)) {
      this.colorMap = window?.injector?.Deserialize?.genericMap(this.colorMap);
    }
  }

  getUniqueIdentifier(): string {
    const titleId = this.title ?? '';
    const variantId = this.variantIds.sort().join(',') ?? '';
    const descIds: string[] = [];
    this.descriptionOverrideMap?.forEach((val, key) => {
      descIds.push(`${key}-${val}`);
    });
    const descId = descIds.sort().join(',') ?? '';
    const assetIds: string[] = [];
    this.assetNameMap?.forEach((val, key) => {
      descIds.push(`${key}-${val}`);
    });
    const assetId = assetIds.sort().join(',') ?? '';
    const priceIds: string[] = [];
    this.priceOverrideMap?.forEach((val, key) => {
      priceIds.push(`${key}-${val}`);
    });
    const priceId = priceIds.sort().join(',') ?? '';
    return `${titleId}-${variantId}-${descId}-${assetId}-${priceId}`;
  }

  getVariantColor(vId: string): string {
    return this.colorMap?.get(vId);
  }

  isFeaturedVariantInStock(id: string): boolean {
    return this.variants?.find(v => v.id === id)?.inStock() ?? false;
  }

}
