import { CardData } from '../print-cards/card-data';
import { PrintLabelMenu } from '../menu/print-label/print-label-menu';
import { LabelStack } from '../menu/section/label-stacks/label-stack';

export class LabelData extends CardData {

  override menu: PrintLabelMenu;
  override section: LabelStack;

  getBarCode(): string {
    return this.getScopedVisibleVariants()?.firstOrNull()?.barcode;
  }

  getSKU(): string {
    return this.getScopedVisibleVariants()?.firstOrNull()?.catalogSKU;
  }

}
