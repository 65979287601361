import { Type } from '@angular/core';
import { LabelData } from 'src/app/models/print-labels/label-data';
import { PrintLabelBackgroundComponent } from 'src/app/modules/print-cards/components/print-labels/backgrounds/print-label-background.component';
import { PrintLabelContentComponent } from 'src/app/modules/print-cards/components/print-labels/label-content/print-label-content.component';
import { BaseInventoryLabelStackBackgroundComponent } from '../../../../../modules/print-cards/components/print-labels/backgrounds/custom-labels-2x4/base-inventory-label-stack-background/base-inventory-label-stack-background.component';
import { BaseInventoryLabelStackContentComponent } from '../../../../../modules/print-cards/components/print-labels/label-content/custom-labels-2x4/base-inventory-label-stack-content/base-inventory-label-stack-content.component';
import { TwoByFourLabelStack } from './two-by-four-label-stack';

export class BaseInventoryLabelStack extends TwoByFourLabelStack {

  getCardBackgroundComponent(labelData: LabelData): Type<PrintLabelBackgroundComponent> {
    return BaseInventoryLabelStackBackgroundComponent;
  }

  getCardContentComponent(labelData: LabelData): Type<PrintLabelContentComponent> {
    return BaseInventoryLabelStackContentComponent;
  }

}
