import { BaseViewModel } from '../../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CardData } from '../../../../../models/print-cards/card-data';
import { map } from 'rxjs/operators';

@Injectable()
export abstract class PrintCardBackgroundViewModel extends BaseViewModel {

  private _cardData = new BehaviorSubject<CardData|null>(null);
  public readonly cardData$ = this._cardData as Observable<CardData|null>;
  connectToCardData = (cardData: CardData) => this._cardData.next(cardData);

  private readonly _preview = new BehaviorSubject<boolean>(false);
  public readonly preview$ = this._preview as Observable<boolean>;
  connectToPreview = (preview: boolean) => this._preview.next(preview);

  public readonly cardStack$ = this.cardData$.pipe(map(cardData => cardData?.section));
  public headerTextColor$ = this.cardData$.pipe(map(cardData => cardData?.getHeaderTextColor() ?? null));
  public bodyTextColor$ = this.cardData$.pipe(map(cardData => cardData?.getBodyTextColor() ?? null));
  public cardAccentColor$ = this.cardData$.pipe(map(cardData => cardData?.getAccentColor() ?? null));
  public cardColor$ = this.cardData$.pipe(map(cardData => cardData?.getCardColor() ?? null));

}
