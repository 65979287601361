import { CardType } from '../modules/display/components/menus/marketing-menu/building-blocks/menu-cards/card-type.enum';

// @dynamic
export class FeaturedCategoryUtils {

  static cardTypeIsForStaticGridMenu(cardType: CardType): boolean {
    return cardType === CardType.HeaderImageCarousel
      || cardType === CardType.ProductDealCarousel;
  }

}
