import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FikaIndexCardStackContentViewModel } from '../fika-index-card-stack-content-view-model';
import { ResizeObserver } from '@juggle/resize-observer';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../../../../../../models/base/base-component';
import { LabelInflatorComponent } from '../../../../../../../labels/components/product-menu-label-inflator/label-inflator.component';
import { FikaCardData } from '../../../../../../../../models/print-cards/fika-card-data';

@Component({
  selector: 'app-fika-index-card-content',
  templateUrl: './fika-index-card-content.component.html',
  styleUrls: ['./fika-index-card-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FikaIndexCardContentComponent extends BaseComponent {

  constructor(
    public viewModel: FikaIndexCardStackContentViewModel,
  ) {
    super();
  }

  @Input() cardData: FikaCardData;
  @ViewChild('labelCalculator') labelCalculator?: LabelInflatorComponent;
  @ViewChild('productCardName', { static: true }) productCardName: ElementRef<HTMLDivElement>;
  private observer: ResizeObserver;

  setupViews() {}

  setupBindings() {
    this.listenForCurrentLabelText();
    this.listenToVirtualLabelText();
    this.listenToProductCardNameHeight();
  }

  private listenForCurrentLabelText(): void {
    this.labelCalculator?.currentLabelText$
      ?.pipe(takeUntil(this.onDestroy))
      ?.subscribe(labelText => this.viewModel.connectToLabelText(labelText));
  }

  private listenToVirtualLabelText(): void {
    this.labelCalculator?.virtualLabelText$
      ?.pipe(takeUntil(this.onDestroy))
      ?.subscribe(labelText => this.viewModel.connectToVirtualLabelText(labelText));
  }

  private listenToProductCardNameHeight(): void {
    this.observer = new ResizeObserver(() => {
      this.viewModel.connectToNameHeight(this.productCardName.nativeElement.offsetHeight);
    });
    this.observer.observe(this.productCardName.nativeElement);
  }

  destroy() {
    super.destroy();
    this.observer?.disconnect();
  }

}
