import { SpotlightProduct } from '../../product/dto/spotlight-product';
import { Variant } from '../../product/dto/variant';
import { CopyableProductSection } from './copyable-product-section';
import { Menu } from '../menu';
import { CompanyConfiguration } from '../../company/dto/company-configuration';
import { LocationConfiguration } from '../../company/dto/location-configuration';
import { SpotlightProductMenuItemComponent } from '../../../modules/display/components/menus/spotlight-menu/building-blocks/menu-item/spotlight-product-menu-item/spotlight-product-menu-item.component';
import { Type } from '@angular/core';
import type { SpotlightMenuItemComponent } from '../../../modules/display/components/menus/spotlight-menu/building-blocks/menu-item/spotlight-menu-item.component';

export class SpotlightSection extends CopyableProductSection {

  onDeserialize() {
    super.onDeserialize();
    this.products = window?.injector?.Deserialize?.arrayOf(SpotlightProduct, this.products);
  }

  getMenuItemType(): Type<SpotlightMenuItemComponent> {
    return SpotlightProductMenuItemComponent;
  }

  public copyWithout(
    menu: Menu,
    companyConfig: CompanyConfiguration,
    locationConfig: LocationConfiguration,
    variants: Variant[]
  ): CopyableProductSection {
    return this.copyWithoutHelper(SpotlightSection, menu, companyConfig, locationConfig, variants);
  }

}
