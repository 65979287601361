export enum DefaultDisplaySize {
  // NORTH AMERICAN PAPER SIZES
  PaperJuniorLegal = 'DefaultSize_JuniorLegal',
  PaperGovernmentLetter = 'DefaultSize_GovernmentLetter',
  PaperLetter = 'DefaultSize_Letter',
  PaperLegal = 'DefaultSize_Legal',
  PaperTabloid = 'DefaultSize_Tabloid',
  PaperLedger = 'DefaultSize_Ledger',
  // INTERNATIONAL PAPER SIZES
  PaperA0 = 'DefaultSize_A0',
  PaperA1 = 'DefaultSize_A1',
  PaperA2 = 'DefaultSize_A2',
  PaperA3 = 'DefaultSize_A3',
  PaperA4 = 'DefaultSize_A4',
  PaperA5 = 'DefaultSize_A5',
  PaperA6 = 'DefaultSize_A6',
  // COMMON SCREEN SIZES
  Digital720p = 'DefaultSize_720p',
  Digital1080p = 'DefaultSize_1080p',
  Digital4k = 'DefaultSize_4k',
  Digital8k = 'DefaultSize_8k',
}
