import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { BaseDirective } from '../../../models/base/base-directive';
import { CardStack } from '../../../models/menu/section/card-stacks/card-stack';
import { exists } from '../../../functions/exists';
import { LabelStack } from '../../../models/menu/section/label-stacks/label-stack';

@Directive({
  selector: '[appPrintCardGridManager]'
})
export class PrintCardGridManagerDirective extends BaseDirective implements OnChanges {

  constructor(
    private elementRef: ElementRef,
    private renderer2: Renderer2
  ) {
    super();
  }

  @Input() cardStack: CardStack = null;
  @Input() nCardsOnPage: number = 1;

  private _cardStack = new BehaviorSubject<CardStack|null>(this.cardStack);
  private cardStack$ = this._cardStack as Observable<CardStack|null>;

  private _nCardsOnPage = new BehaviorSubject<number>(this.nCardsOnPage);
  private nCardsOnPage$ = this._nCardsOnPage as Observable<number>;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cardStack) this._cardStack.next(this.cardStack);
    if (changes.nCardsOnPage) this._nCardsOnPage.next(this.nCardsOnPage);
  }

  setupViews() {
  }

  setupBindings() {
    combineLatest([
      this.cardStack$,
      this.nCardsOnPage$
    ]).subscribeWhileAlive({
      owner: this,
      next: ([cardStack, nCardsOnPage]) => this.setLetterPaperGrid(cardStack, nCardsOnPage)
    });
  }

  /**
   * The paper in 2d space is assumed to ALWAYS be in portrait orientation.
   */
  private setLetterPaperGrid(cardStack: CardStack|null, nCardsOnPage: number): void {
    const native = this.elementRef.nativeElement;
    const buildGrid = (nRows: number, nColumns: number) => {
      let heightInPx = cardStack?.getCardHeightInPixels() ?? 0;
      let widthInPx = cardStack?.getCardWidthInPixels() ?? 0;
      if (cardStack?.isOnRegularPaper()) {
        const bleed = (cardStack?.nonPerforatedBleedBorderInInches() ?? 0) * CardStack.PIXELS_PER_INCH * 2;
        heightInPx += bleed;
        widthInPx += bleed;
      }
      const repeat = (n: number, val: number) => `repeat(${n}, ${val}px)`;
      if (cardStack?.singleCardPerPage && cardStack?.isOnRegularPaper()) {
        this.renderer2.setStyle(native, 'grid-template-rows', repeat(1, heightInPx));
        this.renderer2.setStyle(native, 'grid-template-columns', repeat(1, widthInPx));
      } else {
        this.renderer2.setStyle(native, 'grid-template-rows', repeat(nRows, heightInPx));
        this.renderer2.setStyle(native, 'grid-template-columns', repeat(nColumns, widthInPx));
      }
      if (cardStack?.isOnLaserLabelPaper() && cardStack instanceof LabelStack) {
        const rowGap = CardStack.PIXELS_PER_INCH * cardStack?.getLaserLabelPaperRowGapInInches();
        const columnGap = CardStack.PIXELS_PER_INCH * cardStack?.getLaserLabelPaperColumnGapInInches();
        Number.isFinite(rowGap)
          ? this.renderer2.setStyle(native, 'row-gap', `${rowGap}px`)
          : this.renderer2.removeStyle(native, 'row-gap');
        Number.isFinite(columnGap)
          ? this.renderer2.setStyle(native, 'column-gap', `${columnGap}px`)
          : this.renderer2.removeStyle(native, 'column-gap');
      } else {
        this.renderer2.removeStyle(native, 'row-gap');
        this.renderer2.removeStyle(native, 'column-gap');
      }
    };
    this.renderer2.setStyle(native, 'display', 'grid');
    if (exists(cardStack)) {
      buildGrid(
        cardStack.getNumberOfVisibleRowsOnPage(nCardsOnPage),
        cardStack.getNumberOfVisibleColumnsOnPage(nCardsOnPage)
      );
    }
  }

}
