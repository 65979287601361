import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RenderContextComponent } from '../../../../../../../../models/base/render-context-component';
import { NycdBusinessCardStackContentViewModel } from '../nycd-business-card-stack-content-view-model';

@Component({
  selector: 'app-nycd-business-price',
  templateUrl: './nycd-business-price.component.html',
  styleUrls: ['./nycd-business-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NycdBusinessPriceComponent extends RenderContextComponent {

  constructor(
    public viewModel: NycdBusinessCardStackContentViewModel
  ) {
    super();
  }

  setupBindings() {
  }

  setupViews() {
  }

}
