/* src/app/modules/print-cards/components/cut-lines/print-card-internal-grid-cut-lines/internal-grid-cut-line-cross/internal-grid-cut-line-cross.component.scss */
:host {
  position: absolute;
  display: block;
  transform: translateX(-50%) translateY(-50%);
}
.internal-grid-cross {
  position: relative;
}
.internal-grid-cross * {
  box-sizing: border-box;
}
.internal-grid-cross .vertical,
.internal-grid-cross .horizontal,
.internal-grid-cross .center-square {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.internal-grid-cross .vertical .dividing-sliver,
.internal-grid-cross .horizontal .dividing-sliver,
.internal-grid-cross .center-square .dividing-sliver {
  height: 100%;
  width: 100%;
  border-color: black;
  border-style: solid;
}
.internal-grid-cross .vertical .white,
.internal-grid-cross .horizontal .white,
.internal-grid-cross .center-square .white {
  background-color: white;
}
/*# sourceMappingURL=internal-grid-cut-line-cross.component.css.map */
