import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RenderContextComponent } from '../../../models/base/render-context-component';
import { LabelViewModel } from './label-view-model';
import { LabelComponentInterface } from './label-component-interface';
import { takeUntil } from 'rxjs/operators';

/**
 * All components that extent this class must implement the appFitText directive internally.
 * This is because the drive-thru menu (combo menu), uses this to scale the text of the label.
 */
@Component({ selector: 'app-label', template: '' })
export abstract class LabelComponent extends RenderContextComponent implements AfterViewInit, OnChanges {

  protected constructor(
    public viewModel: LabelViewModel
  ) {
    super();
  }

  @Input() columnLabel: boolean = false;
  @Input() labelComponentInterface: LabelComponentInterface;
  @Input() reset: boolean;
  // appFitText interface. Only needed for drive-thru cards
  @Input() fitTextParentContainer: HTMLElement;
  @Input() fitTextPercentageOfParent: number = 1;
  @Input() fitTextDisabled: boolean = true;
  @Input() checkForPriceChange: boolean = false;

  public currentLabelText$ = this.viewModel.labelText$;
  public virtualLabelText$ = this.viewModel.virtualLabelText$;

  setupViews() {
    this.viewModel.connectToLabelComponentInterface(this.labelComponentInterface);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes?.labelComponentInterface) {
      this.viewModel.connectToLabelComponentInterface(this.labelComponentInterface);
    }
    if (!!changes?.checkForPriceChange) {
      this.viewModel.connectToCheckForPriceChange(this.checkForPriceChange);
    }
  }

  ngAfterViewInit() {
    // don't call super.ngAfterViewInit() because we don't want to call this.rendered.next(true);
    this.setupBindings();
    this.viewModel.label$.pipe(takeUntil(this.onDestroy)).subscribe(label => {
      if (label !== undefined) this.rendered.next(true);
    });
  }

}
