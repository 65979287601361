import { SativaHybridIndicaSectionRowViewModel } from './sativa-hybrid-indica-section-row-view-model';
import { StrainType } from '../../../../../../../../../models/enum/dto/strain-classification.enum';
import { SectionColumnConfigDataValue } from '../../../../../../../../../models/menu/section/section-column-config';

/**
 * "Collapsed" content refers to the css property "visibility: collapsed",
 * which means that the content for this view model is invisible when displayed.
 * The outline of the cell is not invisible.
 * This allows for the outline of the cell to be displayed without its internal content.
 */
export class CollapsedSativaHybridIndicaSectionViewModel extends SativaHybridIndicaSectionRowViewModel {

  public overrideStrainType: StrainType;

  constructor() {
    super();
  }

  static shallowCopy(
    vm: SativaHybridIndicaSectionRowViewModel,
    strainType: StrainType
  ): SativaHybridIndicaSectionRowViewModel {
    const copy = new CollapsedSativaHybridIndicaSectionViewModel();
    copy.menu = vm?.menu;
    copy.section = vm?.section;
    copy.subHeader = vm?.subHeader;
    copy.product = vm?.product;
    copy.rowVariants = vm?.rowVariants;
    copy.variantLineItemMode = vm?.variantLineItemMode;
    copy.collapseContent = vm?.collapseContent;
    copy.hideLabel = vm?.hideLabel;
    copy.configTheme = vm?.configTheme;
    copy.style = vm?.style;
    copy.hidePriceOnVariantIds = vm?.hidePriceOnVariantIds;
    copy.companyConfig = vm?.companyConfig;
    copy.locationConfig = vm?.locationConfig;
    copy.variantBadgeMap = vm?.variantBadgeMap;
    copy.itemOverflowStatus = vm?.itemOverflowStatus;
    copy.overrideStrainType = strainType;
    return copy;
  }

  getReadableStrainType(strainTypeMode: SectionColumnConfigDataValue): string {
    // S/I/H columns depend on full word, so always return
    return this.overrideStrainType;
  }

}
