import { Pipe, PipeTransform } from '@angular/core';
import { CardStack } from '../../../models/menu/section/card-stacks/card-stack';

@Pipe({
  name: 'iterableCardStackRows'
})
export class IterableCardStackRowsPipe implements PipeTransform {

  transform(cardStack: CardStack, nCardsOnPage: number): any[] {
    return Array(cardStack?.getNumberOfVisibleRowsOnPage(nCardsOnPage) || 1);
  }

}
