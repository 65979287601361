<div
  *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.Producer"
  class="product-column-general product-producer"
  [style.visibility]="section?.collapseContent || rowVm.collapseContent ? 'collapse' : ''"
  [style.text-decoration]="productStylingVm | forcedColumnTextDecoration: colVm : locConfig | async"
  [style.margin-right]="menu | productMenuBasicMethod: 'getSectionBorderMarginSize'">
  <ng-container *ngIf="useNgContent"><ng-content></ng-content></ng-container>
  <ng-container *ngIf="!useNgContent">{{
    (rowVm | sectionRowViewModelBasicMethod: 'getBrand') || '&nbsp;'
  }}</ng-container>
</div>
