<div
  class="footer-container wrapping-swimlane-section-container"
  #footerContainer
  [ngClass]="menu | productMenuBasicMethod : 'getFooterWrapperClass'"
  [style.display]="(noBadgesAndClassifications$ | async) ? 'block' : null"
  [style.color]="menu?.hydratedTheme?.themeFeatures?.footerTextColor ? menu?.menuOptions?.footerTextColor : ''">
  <ng-container *ngFor="let i of menu | printMenuBasicMethod : 'getNWrappingOverflowSwimlanes' | range">
    <div class="wrapping-swimlane" fxLayout="row" fxLayoutAlign="center center">
      <div
        *ngIf="(menuClassifications$ | async)?.length > 0 || (menuBadges$ | async)?.length > 0"
        class="footer-classification-board">
        <div *ngFor="let classification of menuClassifications$ | async" class="classification-mapping">
          <div class="classification-badge" [ngClass]="classification | lowercase">
            {{ classification | getClassificationSymbol }}
          </div>
          <div class="classification-name">
            {{ classification | uppercase }}
          </div>
        </div>
        <div *ngFor="let badge of menuBadges$ | async" class="classification-mapping">
          <img
            appImage
            class="badge-in-legend"
            [asset]="badge?.image"
            [reset]="reset"
            [style.object-fit]="'contain'"
            alt="badge image"
            src="" />
          <div class="classification-name">
            {{ badge?.name | uppercase }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
