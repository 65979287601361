<div class="sidebar-section">
  <div
    *ngIf="cardData | sectionRowViewModelBasicMethod: 'getSidebarText' as text"
    class="sidebar-text"
    appPrintCardScaleDownText
    [maxHeightInPx]="maxHeightInPx"
    [maxWidthInPx]="maxWidthInPx"
    [transparentWhileScaling]="viewModel.viewingInLiveView$ | async"
    [adjustLineHeight]="false"
    [style.height.px]="maxHeightInPx"
    [style.width.px]="maxWidthInPx">
    {{ text }}
  </div>
</div>
