import { ProductMenu } from './product-menu';
import { PrintHeaderLayoutType } from '../enum/shared/print-header-layout-type.enum';
import { PrintFooterLayoutType } from '../enum/shared/print-footer-layout-type.enum';
import { DisplayablePrintMenu } from '../protocols/displayable-print-menu';
import { SizeUnit } from '../enum/dto/size-unit.enum';
import { SectionColumnProductInfoType, SectionColumnViewModel } from '../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { ColumnViewModelUtils } from '../../utils/column-view-model-utils';
import { WrappingSwimLaneOverflow } from '../enum/shared/swim-lane-overflow.enum';
import { SortUtils } from '../../utils/sort-utils';
import { Type } from '@angular/core';
import type { ColWidth } from '../shared/col-width';
import type { Section } from './section/section';
import type { SectionRowViewModel } from '../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { ProductMenuHeaderComponent } from '../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import type { ProductMenuFooterComponent } from '../../modules/display/components/menus/product-menu/building-blocks/menu-footer/product-menu-footer.component';

export abstract class PrintMenu extends ProductMenu implements DisplayablePrintMenu {

  public static readonly CHROME_DEFAULT_DPI: number = 96;

  static convertImperialToPixels(inches: number): number {
    const defaultDotsPerInch = PrintMenu.CHROME_DEFAULT_DPI;
    return inches * defaultDotsPerInch;
  }

  static convertMetricToPixels(milimeters: number): number {
    // There are 25.4 mm in an inch
    const imperialHeight = milimeters / 25.4;
    const defaultDotsPerInch = PrintMenu.CHROME_DEFAULT_DPI;
    return imperialHeight * defaultDotsPerInch;
  }

  static getPrintDistanceInPixels(distance: string): number {
    let unit: SizeUnit = SizeUnit.Digital;
    const inches = /in/g;
    const mm = /mm/g;
    const px = /p/g;
    const height = parseFloat(distance ?? '0');
    if (inches.test(distance)) {
      unit = SizeUnit.Imperial;
    } else if (mm.test(distance)) {
      unit = SizeUnit.Metric;
    } else if (px.test(distance)) {
      unit = SizeUnit.Digital;
    }
    switch (unit) {
      case SizeUnit.Imperial:
        return PrintMenu.convertImperialToPixels(height);
      case SizeUnit.Metric:
        return PrintMenu.convertMetricToPixels(height);
      default:
        return height;
    }
  }

  /**
   * Can be in px, inches, or mm.
   */
  abstract getPageTopMargin(): string;
  abstract getPageBottomMargin(): string;
  abstract getPageLeftMargin(): string;
  abstract getPageRightMargin(): string;

  getMenuClass(): string {
    return `print-menu ${this.getThemeClass()}`;
  }

  getShowHeader(): boolean {
    return true;
  }

  getShowFooter(): boolean {
    return true;
  }

  getShouldOverflowHorizontallyElseVertically(): boolean {
    return false;
  }

  getSectionWidthPercentage(): number {
    return 100;
  }

  getShouldSectionsContainerFlexWrap(): boolean {
    return false;
  }

  getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.None;
  }

  getPrintFooterLayoutType(): PrintFooterLayoutType {
    return this.menuOptions?.printFooterLayout || PrintFooterLayoutType.None;
  }

  pushFooterToBottomOfPage(): boolean {
    return this.getPrintFooterLayoutType() === PrintFooterLayoutType.AllPagesFixed;
  }

  public getPageTopMarginInPx(): number {
    return PrintMenu.getPrintDistanceInPixels(this.getPageTopMargin());
  }

  public getPageBottomMarginInPx(): number {
    return PrintMenu.getPrintDistanceInPixels(this.getPageBottomMargin());
  }

  public shouldInflateHeader(s: Section): boolean {
    switch (this.getPrintHeaderLayoutType()) {
      case PrintHeaderLayoutType.FirstPage:
        return s?.pageIndex === 0 && s?.firstOnPage;
      case PrintHeaderLayoutType.AllPages:
        return s?.firstOnPage;
      default:
        return false;
    }
  }

  public shouldInflateEmptyHeaderReplacement(s: Section): boolean {
    switch (this.getPrintHeaderLayoutType()) {
      case PrintHeaderLayoutType.FirstPage:
        return s?.pageIndex !== 0 && s?.firstOnPage;
      case PrintHeaderLayoutType.AllPages:
        return false;
      default:
        return true;
    }
  }

  public shouldInflateFooter(s: Section): boolean {
    switch (this.getPrintFooterLayoutType()) {
      case PrintFooterLayoutType.AllPagesHugging:
        return s?.lastOnPage || s?.lastSection;
      case PrintFooterLayoutType.AllPagesFixed:
        return s?.lastOnPage || s?.lastSection;
      default:
        return false;
    }
  }

  public shouldInflateEmptyFooterReplacement(s: Section): boolean {
    switch (this.getPrintFooterLayoutType()) {
      case PrintFooterLayoutType.AllPagesHugging:
        return false;
      case PrintFooterLayoutType.AllPagesFixed:
        return false;
      default:
        return s?.lastOnPage || s?.lastSection;
    }
  }

  getMenuScrollClass(): string {
    return 'vertical-scroll';
  }

  protected getThemeStandardizedColumnViewModels(
    sectionRowViewModels: SectionRowViewModel[],
    rowViewModel: SectionRowViewModel,
    widths: ColWidth[]
  ): SectionColumnViewModel[] {
    return ColumnViewModelUtils.standardizedPrintColumnViewModels(
      this,
      sectionRowViewModels,
      rowViewModel,
      widths
    );
  }

  override getThemeSpecifiedColumnViewModels(
    sectionRowViewModels: SectionRowViewModel[],
    rowViewModel: SectionRowViewModel,
    widths: ColWidth[] = this.getColWidths(rowViewModel)
  ): SectionColumnViewModel[] {
    const getStandardizedPrintColumnViewModels = ColumnViewModelUtils.standardizedPrintColumnViewModels;
    const columns = getStandardizedPrintColumnViewModels(this, sectionRowViewModels, rowViewModel, widths);
    const sortedColumnViewModels = columns?.sort(SortUtils.columnViewModelByOrdering);
    const columnGroupings = rowViewModel?.variantLineItemMode
      ? rowViewModel?.menu?.getLineModeColumnGroupings(sortedColumnViewModels)
      : rowViewModel?.menu?.getGridModeColumnGroupings(sortedColumnViewModels);
    const spacerWidth = widths.find(it => it.type === SectionColumnProductInfoType.Spacer).widthPercentage ?? '5';
    return ColumnViewModelUtils.addSpacersBetweenGroupings(spacerWidth, columnGroupings, sortedColumnViewModels);
  }

  getTitleSectionTopMargin(): string {
    return this.wrappingOverflowSwimlanesEnabled() ? null : '0';
  }

  getTitleSectionBottomMargin(): string {
    return this.wrappingOverflowSwimlanesEnabled() ? null : '0';
  }

  public getNWrappingOverflowSwimlanes(): number {
    switch (this.wrappingSwimLaneOverflow()) {
      case WrappingSwimLaneOverflow.Off:
        return 1;
      case WrappingSwimLaneOverflow.TwoLanes:
        return 2;
      case WrappingSwimLaneOverflow.ThreeLanes:
        return 3;
      case WrappingSwimLaneOverflow.FourLanes:
        return 4;
    }
  }

  public wrappingOverflowSwimlanesEnabled(): boolean {
    return this.getNWrappingOverflowSwimlanes() > 1;
  }

  public wrappingSwimLaneOverflow(): WrappingSwimLaneOverflow {
    return WrappingSwimLaneOverflow.Off;
  }

  public getEmptyHeaderReplacement(): Type<ProductMenuHeaderComponent> {
    return null;
  }

  public getEmptyFooterReplacement(): Type<ProductMenuFooterComponent> {
    return null;
  }

  public pageContentAreaHeightInPx(): number {
    return PrintMenu.convertImperialToPixels(this.displaySize?.height);
  }

  public pageContentAreaWidthInPx(): number {
    return PrintMenu.convertImperialToPixels(this.displaySize?.width);
  }

}
