import { PrintCardBackgroundViewModel } from '../../print-card-background-view-model';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { JaneDoeCardData } from '../../../../../../../models/print-cards/jane-doe-card-data';
import { map } from 'rxjs/operators';
import { CardStack } from '../../../../../../../models/menu/section/card-stacks/card-stack';

@Injectable()
export class JaneDoePostCardStackBackgroundViewModel extends PrintCardBackgroundViewModel {

  private readonly ColorBorderLeftAndRightThicknessInPx = 8;
  private readonly ColorBorderTopAndBottomThicknessInPx = 30;

  public readonly cardData$: Observable<JaneDoeCardData>;

  public borderColor$ = this.cardData$.pipe(
    map(cardData => cardData?.getCardBorderColor())
  );

  public borderLeftAndRightAdjustment$ = combineLatest([
    this.cardStack$,
    this.preview$
  ]).pipe(
    map(([cardStack, preview]) => {
      return this.getDimensionAdjustment(cardStack, this.ColorBorderLeftAndRightThicknessInPx, preview);
    })
  );

  public borderTopAndBottomAdjustment$ = combineLatest([
    this.cardStack$,
    this.preview$
  ]).pipe(
    map(([cardStack, preview]) => {
      return this.getDimensionAdjustment(cardStack, this.ColorBorderTopAndBottomThicknessInPx, preview);
    })
  );

  private getDimensionAdjustment(cardStack: CardStack, borderThickness: number, preview: boolean): string {
    const bleed = (cardStack?.nonPerforatedBleedBorderInInches() ?? 0) * CardStack.PIXELS_PER_INCH;
    const additionalHeight = cardStack?.isOnRegularPaper() && !preview ? bleed : 0;
    return `calc(100% - ${additionalHeight * 2}px - ${borderThickness * 2}px)`;
  }

}
