<div
  class="loading-container"
  [ngStyle]="{
    top: options.topMarginRem + 'rem',
    'border-radius': options.cornerRadiusRem + 'rem',
    'background-color': options.backgroundColor,
    'z-index': options.zIndex
  }">
  <div *ngIf="!options.determinateLoading" class="indeterminate-spinner">
    <ngx-spinner
      [bdColor]="options.fullscreen ? options.backgroundColor : 'rgba(0, 0, 0, 0)'"
      [color]="options.spinnerColor"
      [size]="options.spinnerSize"
      [fullScreen]="options.fullscreen"
      size="medium"
      type="ball-clip-rotate">
      <p
        *ngIf="options.showLoadingText && options.loadingText && options.loadingText !== ''"
        [style]="{ color: options.color }"
        class="loading-text">
        {{ options.loadingText }}
      </p>
    </ngx-spinner>
  </div>

  <div
    *ngIf="options.determinateLoading"
    [ngClass]="{ fullscreen: options.fullscreen }"
    [ngStyle]="{ 'background-color': options.backgroundColor, color: options.color }"
    class="determinate-spinner">
    <div class="progress-bar-container">
      <app-progress-bar [barColor]="options.spinnerColor" [progress]="options.progress"> </app-progress-bar>
      <span
        *ngIf="options.showLoadingText && options.loadingText && options.loadingText !== ''"
        class="determinate-spinner-loading-text">
        {{ options.loadingText }}
      </span>
    </div>
  </div>
</div>
