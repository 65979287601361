import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintCardDomainModel } from '../../../../domain/print-card-domain-model';
import { RenderContentViewModel } from '../../base/render-content-view-model';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { CardData } from '../../../../models/print-cards/card-data';
import { SectionRowViewModelUtils } from '../../../../utils/section-row-view-model-utils';

@Injectable()
export class RenderPrintCardPreviewViewModel extends RenderContentViewModel {

  constructor(
    activatedRoute: ActivatedRoute,
    printCardDomainModel: PrintCardDomainModel
  ) {
    super(activatedRoute, printCardDomainModel);
  }

  /**
   * Note: overrideSiblingVariantIds are only needed for rendering smart prints.
   */
  public readonly cardGrouping$: Observable<CardData> = combineLatest([
    this.printCardMenu$,
    this.cardStack$,
    this.overrideVariantIds$,
    this.companyConfig$,
    this.locationConfig$
  ]).pipe(
    map(([menu, cardStack, variantIds, companyConfig, locationConfig]) => {
      const generateCardData = SectionRowViewModelUtils.generateRowViewModels;
      return generateCardData(cardStack, menu, companyConfig, locationConfig, cardStack?.products, [], variantIds)
        ?.filter((card): card is CardData => card instanceof CardData)
        ?.filter(cardData => cardData?.rowVariants?.map(v => v?.id)?.intersection(variantIds)?.length > 0)
        ?.firstOrNull();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

}
