import { Pipe, PipeTransform } from '@angular/core';
import type { ProductMenu } from '../../../models/menu/product-menu';
import type { SectionWithProducts } from '../../../models/menu/section/section-with-products';
import type { SectionRowViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { SectionColumnViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import type { LocationConfiguration } from '../../../models/company/dto/location-configuration';
import type { LocationPriceStream } from '../../../models/enum/shared/location-price-stream';
import { SpecificPriceWithoutDiscounts } from '../../../models/enum/shared/specific-price-without-discounts.enum';

@Pipe({
  name: 'getOriginalPriceDecimal'
})
export class GetOriginalPriceDecimalPipe implements PipeTransform {

  transform(
    menu: ProductMenu,
    locationConfig: LocationConfiguration,
    locationId: number,
    section: SectionWithProducts,
    sectionRowViewModel: SectionRowViewModel,
    sectionColumnViewModel: SectionColumnViewModel,
    specificPriceWithoutDiscounts: SpecificPriceWithoutDiscounts[],
    overridePriceStream?: LocationPriceStream
  ): string {
    const priceStream = overridePriceStream || locationConfig?.priceFormat;
    return menu?.getOriginalPriceDecimal(
      priceStream,
      locationId,
      section,
      sectionRowViewModel,
      sectionColumnViewModel,
      specificPriceWithoutDiscounts
    );
  }

}
