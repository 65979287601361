import { Pipe, PipeTransform } from '@angular/core';
import { CardStack } from '../../../models/menu/section/card-stacks/card-stack';

@Pipe({
  name: 'verticalCardStackJustification'
})
export class VerticalCardStackJustificationPipe implements PipeTransform {

  transform(cardStack: CardStack): string | null {
    const topPaperMarginInInches = cardStack?.customTopPaperMarginInInches() ?? null;
    if (Number.isFinite(topPaperMarginInInches)) {
      return null;
    }
    return 'center';
  }

}
