/* src/app/modules/display/components/menus/product-menu/building-blocks/menu-footer/ingram-and-sons-print-footer/ingram-and-sons-print-footer.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: anton, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  padding: 0;
}
/*# sourceMappingURL=ingram-and-sons-print-footer.component.css.map */
