<div
  #headerContainer
  [style.color]="menu?.hydratedTheme?.themeFeatures?.titleTextColor ? menu?.menuOptions?.titleTextColor : ''"
  [ngClass]="menu | productMenuBasicMethod : 'getHeaderWrapperClass'">
  <div class="header-container">
    <div class="main-header">
      <div
        class="main-title"
        *ngIf="!menu?.menuOptions?.hideTitle && !!menu?.configurationTitle"
        [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
        {{ menu?.configurationTitle }}
      </div>
      <div
        class="main-subtitle"
        *ngIf="!menu?.menuOptions?.hideTitle && !!menu?.subTitle"
        [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
        {{ menu?.subTitle }}
      </div>
    </div>
    <img
      appImage
      *ngIf="menu | productMenuBasicMethod : 'showCompanyLogo'"
      class="company-logo"
      [style.height.rem]="6.25"
      [style.zoom]="menu?.menuOptions?.titleZoom || 1.0"
      [asset]="menu?.companyLogo"
      [reset]="reset"
      alt="company logo"
      src="" />
  </div>
</div>
