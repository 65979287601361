import { MarketingMenu } from '../marketing-menu';
import { exists } from '../../../functions/exists';

export class MarketingUrlPlaylistMenu extends MarketingMenu {

  public linkedURL: string;

  override isEmpty(): boolean {
    return !this.linkedURL;
  }

  hasURL(): boolean {
    return exists(this.linkedURL);
  }

}
