/* src/app/modules/shared/progress-bar/progress-bar.component.scss */
.progress-cont {
  height: 2px;
  width: 100%;
  border-radius: 1px;
  background-color: #f8f8f8;
  position: relative;
  margin-top: 4px;
}
.progress-cont .progress {
  width: 0;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  border-radius: 1px;
  transition: 0.5s all;
}
.progress-cont .progress.default {
  background-color: #2c4058;
}
.progress-cont.error .progress.default {
  background-color: #fa5555;
}
.progress-cont.success .progress.default {
  background-color: #63d58f;
}
/*# sourceMappingURL=progress-bar.component.css.map */
