<div
  *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.QuantityAndSize"
  class="product-column-general product-quantity-and-size"
  [style.visibility]="section?.collapseContent || rowVm?.collapseContent ? 'collapse' : ''"
  [style.text-decoration]="productStylingVm | forcedColumnTextDecoration: colVm : locConfig | async"
  [style.margin-right]="menu | productMenuBasicMethod: 'getSectionBorderMarginSize'">
  <ng-container *ngIf="useNgContent"><ng-content></ng-content></ng-container>
  <ng-container *ngIf="!useNgContent">{{
    rowVm | sectionRowViewModelBasicMethod: 'getQuantityAndSizeString'
  }}</ng-container>
</div>
