import { BusinessCardStack } from './business-card-stack';
import { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { PrintCardBackgroundComponent } from '../../../../../modules/print-cards/components/print-cards/backgrounds/print-card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/print-card-content.component';
import { PlantlifeBusinessCardStackContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/business-cards-2x3/plantlife-business-card-stack-content/plantlife-business-card-stack-content.component';

export class PlantlifeBusinessCardStack extends BusinessCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<PrintCardBackgroundComponent> | null {
    // card has mandatory white border around it, so background lives within content component
    return null;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return PlantlifeBusinessCardStackContentComponent;
  }

}
