<div
  *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.StrainType"
  class="product-column-general product-strain-type"
  [ngClass]="rowVm | sectionRowViewModelBasicMethod: 'getReadableStrainType' : colVm.strainTypeMode | lowercase"
  [style.visibility]="section?.collapseContent || rowVm.collapseContent ? 'collapse' : ''"
  [style.text-decoration]="productStylingVm | forcedColumnTextDecoration: colVm : locConfig | async"
  [style.margin-right]="menu | productMenuBasicMethod: 'getSectionBorderMarginSize'">
  <ng-container *ngIf="useNgContent"><ng-content></ng-content></ng-container>
  <ng-container *ngIf="!useNgContent">{{
    rowVm | sectionRowViewModelBasicMethod: 'getReadableStrainType' : colVm.strainTypeMode
  }}</ng-container>
</div>
