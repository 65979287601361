@charset "UTF-8";

/* src/app/modules/print-cards/components/print-cards/card-content/business-cards-2x3/og-kush-business-card-stack-content/og-kush-business-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0.75rem;
}
:host .card-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
:host .header-section {
  padding: 0.25rem 0 0.25rem 0.313rem;
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-size: 0.5rem;
}
:host .main-section {
  position: relative;
  display: flex;
  gap: 0.5rem;
  padding: 0.563rem 0.75rem 0.563rem 0.688rem;
  border-top: 0.25rem solid #222222;
  border-bottom: 0.25rem solid #222222;
  height: 100%;
}
:host .background-content {
  position: absolute;
  inset: 0;
  z-index: -1;
}
:host .background-content .print-card-background-asset {
  height: 100%;
  width: 100%;
}
:host .left-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 64%;
}
:host .left-side.full-width {
  max-width: 100%;
}
:host .title-brand-container {
  display: flex;
  flex-direction: column;
}
:host .title-container {
  line-height: 1.25;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
}
:host .product-card-brand {
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-size: 0.5625rem;
}
:host .product-card-price {
  margin-top: auto;
}
:host .product-card-price ::ng-deep .product-variant-price {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: flex-end;
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 100%;
  overflow: visible;
}
:host .product-card-price ::ng-deep .product-variant-price .product-variant-previous-price {
  opacity: 0.5;
  font-family: poppins, sans-serif;
  font-weight: 600;
  color: #222222;
  font-size: 0.875rem;
}
:host .right-side {
  position: absolute;
  right: 0.25rem;
  display: flex;
  flex-direction: column;
  width: 32%;
  height: 5.688rem;
  margin-top: 0.188rem;
  border-left: 0.063rem solid black;
}
:host .right-side > :not(:last-child) {
  border-bottom: 0.063rem solid black;
}
:host .badge-and-label-container {
  display: flex;
  flex-direction: column;
  gap: 0.313rem;
  padding-bottom: 0.313rem;
}
:host .product-card-badge-container {
  display: flex;
  justify-content: center;
  gap: 0.125rem;
}
:host .product-card-badge {
  width: 0.875rem;
}
:host .product-card-label-container {
  display: flex;
  justify-content: center;
}
:host .print-card-label {
  height: fit-content;
  margin: 0;
  padding: 0;
  max-width: 90%;
}
:host .print-card-label ::ng-deep .product-label {
  font-family: poppins, sans-serif;
  font-weight: 500;
  font-size: 0.438rem;
  line-height: 120%;
  border-radius: 0.25rem;
  margin: 0.1rem 0 0 0;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
:host .print-card-label ::ng-deep .product-flag-label {
  overflow: visible;
}
:host .print-card-label ::ng-deep .product-flag-label .flag-body {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
:host .strain-type-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.156rem;
  white-space: nowrap;
  font-size: 0.4375rem;
  font-family: poppins, sans-serif;
  font-weight: 700;
}
:host .strain-type {
  max-width: 100%;
}
:host .property-row {
  display: flex;
  flex-direction: row;
  font-size: 0.5rem;
  font-family: poppins, sans-serif;
  font-weight: 400;
}
:host .property-row .title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 1rem;
  text-align: center;
  letter-spacing: -0.009rem;
  border-right: 0.063rem solid black;
  box-sizing: border-box;
  font-size: 0.4375rem;
  font-family: poppins, sans-serif;
  font-weight: 700;
}
:host .property-row .value {
  display: flex;
  width: 50%;
  height: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 0.063rem;
  letter-spacing: -0.01rem;
  box-sizing: border-box;
  white-space: nowrap;
}
:host .property-row .value .value-text {
  max-width: 100%;
}
:host ::ng-deep .force-bold-style * {
  font-family: poppins, sans-serif;
  font-weight: 700;
}
:host ::ng-deep .force-italic-style * {
  font-family: poppins, sans-serif;
  font-style: italic;
  font-weight: 600;
}
/*# sourceMappingURL=og-kush-business-card-stack-content.component.css.map */
