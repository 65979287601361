import { PrintCardBackgroundViewModel } from '../../print-card-background-view-model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { CardStack } from '../../../../../../../models/menu/section/card-stacks/card-stack';

@Injectable()
export class MissJonesCardStackBackgroundViewModel extends PrintCardBackgroundViewModel {

  public readonly colorStripHeight$ = this.cardStack$.pipe(
    map(cardStack => {
      const bleed = (cardStack?.nonPerforatedBleedBorderInInches() ?? 0) * CardStack.PIXELS_PER_INCH;
      const additionalHeight = cardStack?.isOnRegularPaper() ? bleed : 0;
      return `calc(7.3% + ${additionalHeight}px)`;
    })
  );

}
