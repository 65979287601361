import { Type } from '@angular/core';
import { ProductMenu } from '../product-menu';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { Section } from '../section/section';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { Product } from '../../product/dto/product';

export class AmsterdamMenu extends ProductMenu {

  getThemeClass(): string {
    return 'amsterdam-theme';
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getNumberOfBadgesForVariant(row: SectionRowViewModel): number {
    return row?.getAllVariantBadges()?.length || 0;
  }

  getShowBadgesInline(): boolean {
    return true;
  }

  getShowBadgesInlineOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getProductSubtitle(
    section: Section,
    product: Product,
    rowVm: SectionRowViewModel
  ): string {
    const thc = rowVm.getCannabinoid('THC');
    const cbd = rowVm.getCannabinoid('CBD');
    if (thc !== '-' && cbd !== '-') {
      return `THC ${thc} \xa0\xa0 CBD ${cbd}`;
    } else {
      return '-';
    }
  }

  getShowStrainTypes(): boolean {
    return false;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getShowFooter(): boolean {
    return false;
  }

  getFontFaceList(): string[] {
    return ['P22-Mackinac-Pro', 'soleil'];
  }

}

