import { Pipe, PipeTransform } from '@angular/core';
import type { ProductMenu } from '../../../models/menu/product-menu';
import type { Section } from '../../../models/menu/section/section';
import type { SectionColumnViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';

@Pipe({
  name: 'getBadgeColumnTitle'
})
export class GetBadgeColumnTitlePipe implements PipeTransform {

  transform(menu: ProductMenu, section: Section, colVm: SectionColumnViewModel): string|null {
    return menu?.getBadgeColumnTitle(section, colVm) || null;
  }

}
