import { SectionSortOption, SectionSortProductInfo, SectionSortSecondaryCannabinoids, SectionSortTerpenes } from '../models/enum/dto/section-sort-option.enum';
import { Move } from './sort-utils';
import { LocationPriceStream } from '../models/enum/shared/location-price-stream';
import type { SectionWithProducts } from '../models/menu/section/section-with-products';
import { Menu } from '../models/menu/menu';
import { VariantGroup } from '../models/product/dto/variant-group';
import { SectionColumnConfigDataValue, SectionColumnConfigProductInfoKey } from '../models/menu/section/section-column-config';
import { SortVariantsInSectionUtils } from './sort-variants-in-section-utils';

// @dynamic
export class SortVariantGroupUtils extends SortVariantsInSectionUtils {

  /* *******************************************************************************************
   *                             Variant Groups - Sort Options                                 *
   * *******************************************************************************************/

  static variantGroupsBrandAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.nonNullStringAscending(a?.getGroupBrand(), b?.getGroupBrand());
  };
  static variantGroupsBrandDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.nonNullStringDescending(a?.getGroupBrand(), b?.getGroupBrand());
  };

  static variantGroupsClassificationAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.strainTypeSortAsc(a?.getGroupClassification(), b?.getGroupClassification());
  };
  static variantGroupsClassificationDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.strainTypeSortDesc(a?.getGroupClassification(), b?.getGroupClassification());
  };

  static variantGroupsManufacturerAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.nonNullStringAscending(a?.getGroupManufacturer(), b?.getGroupManufacturer());
  };
  static variantGroupsManufacturerDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.nonNullStringDescending(a?.getGroupManufacturer(), b?.getGroupManufacturer());
  };

  static variantGroupsPackagedQuantityAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.numberAscending(a?.getMinPackageQuantity(), b?.getMinPackageQuantity());
  };
  static variantGroupsPackagedQuantityDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.numberDescending(a?.getMaxPackageQuantity(), b?.getMaxPackageQuantity());
  };

  static variantGroupsPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [themeId, locationId, companyId]: [string, number, number],
    [locationPriceStream, hideSale]: [LocationPriceStream, boolean]
  ): Move => {
    const aGroupPrice = a?.getMinGroupPrice(themeId, locationId, companyId, locationPriceStream, hideSale);
    const bGroupPrice = b?.getMinGroupPrice(themeId, locationId, companyId, locationPriceStream, hideSale);
    return SortVariantGroupUtils.numberAscending(aGroupPrice, bGroupPrice);
  };
  static variantGroupsPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [themeId, locationId, companyId]: [string, number, number],
    [locationPriceStream, hideSale]: [LocationPriceStream, boolean]
  ): Move => {
    const aGroupPrice = a?.getMinGroupPrice(themeId, locationId, companyId, locationPriceStream, hideSale);
    const bGroupPrice = b?.getMinGroupPrice(themeId, locationId, companyId, locationPriceStream, hideSale);
    return SortVariantGroupUtils.numberDescending(aGroupPrice, bGroupPrice);
  };

  static variantGroupsPricePerUOMAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [tId, locationId, companyId]: [string, number, number],
    [locationPriceStream, hideSale]: [LocationPriceStream, boolean]
  ): Move => {
    const aMinGroupPricePerUOM = a?.getMinGroupPricePerUOM(tId, locationId, companyId, locationPriceStream, hideSale);
    const bMinGroupPricePerUOM = b?.getMinGroupPricePerUOM(tId, locationId, companyId, locationPriceStream, hideSale);
    return SortVariantGroupUtils.numberAscending(aMinGroupPricePerUOM, bMinGroupPricePerUOM);
  };
  static variantGroupsPricePerUOMDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [tId, locationId, companyId]: [string, number, number],
    [locationPriceStream, hideSale]: [LocationPriceStream, boolean]
  ): Move => {
    const bMaxGroupPricePerUOM = b?.getMaxGroupPricePerUOM(tId, locationId, companyId, locationPriceStream, hideSale);
    const aMaxGroupPricePerUOM = a?.getMaxGroupPricePerUOM(tId, locationId, companyId, locationPriceStream, hideSale);
    return SortVariantGroupUtils.numberDescending(aMaxGroupPricePerUOM, bMaxGroupPricePerUOM);
  };

  static variantGroupsOriginalPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [themeId, locationId, companyId]: [string, number, number],
    [locationPriceStream]: [LocationPriceStream]
  ): Move => {
    const aMinOriginalPrice = a?.getMinOriginalPrice(themeId, locationId, companyId, locationPriceStream);
    const bMinOriginalPrice = b?.getMinOriginalPrice(themeId, locationId, companyId, locationPriceStream);
    return SortVariantGroupUtils.numberAscending(aMinOriginalPrice, bMinOriginalPrice);
  };
  static variantGroupsOriginalPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [themeId, locationId, companyId]: [string, number, number],
    [locationPriceStream]: [LocationPriceStream]
  ): Move => {
    const aMinOriginalPrice = a?.getMaxOriginalPrice(themeId, locationId, companyId, locationPriceStream);
    const bMinOriginalPrice = b?.getMaxOriginalPrice(themeId, locationId, companyId, locationPriceStream);
    return SortVariantGroupUtils.numberDescending(aMinOriginalPrice, bMinOriginalPrice);
  };

  static variantGroupsSaleOriginalPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [tId, locationId, companyId]: [string, number, number],
    [locationPriceStream]: [LocationPriceStream]
  ): Move => {
    const aMinSaleOriginalPrice = a?.getMinSaleOriginalPrice(tId, locationId, companyId, locationPriceStream);
    const bMinSaleOriginalPrice = b?.getMinSaleOriginalPrice(tId, locationId, companyId, locationPriceStream);
    return SortVariantGroupUtils.numberAscending(aMinSaleOriginalPrice, bMinSaleOriginalPrice);
  };
  static variantGroupsSaleOriginalPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [tId, locationId, companyId]: [string, number, number],
    [locationPriceStream]: [LocationPriceStream]
  ): Move => {
    const aMinSaleOriginalPrice = a?.getMaxSaleOriginalPrice(tId, locationId, companyId, locationPriceStream);
    const bMinSaleOriginalPrice = b?.getMaxSaleOriginalPrice(tId, locationId, companyId, locationPriceStream);
    return SortVariantGroupUtils.numberDescending(aMinSaleOriginalPrice, bMinSaleOriginalPrice);
  };

  static variantGroupsOriginalAndSalePriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [themeId, locationId, companyId]: [string, number, number],
    [locationPriceStream]: [LocationPriceStream]
  ): Move => {
    const aMinOriginalAndSalePrice = a?.getMinGroupPrice(themeId, locationId, companyId, locationPriceStream, false);
    const bMinOriginalAndSalePrice = b?.getMinGroupPrice(themeId, locationId, companyId, locationPriceStream, false);
    return SortVariantGroupUtils.numberAscending(aMinOriginalAndSalePrice, bMinOriginalAndSalePrice);
  };
  static variantGroupsOriginalAndSalePriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [themeId, locationId, companyId]: [string, number, number],
    [locationPriceStream]: [LocationPriceStream]
  ): Move => {
    const aMinOriginalAndSalePrice = a?.getMaxGroupPrice(themeId, locationId, companyId, locationPriceStream, false);
    const bMinOriginalAndSalePrice = b?.getMaxGroupPrice(themeId, locationId, companyId, locationPriceStream, false);
    return SortVariantGroupUtils.numberDescending(aMinOriginalAndSalePrice, bMinOriginalAndSalePrice);
  };

  static variantGroupsTaxesInPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [themeId, locationId, companyId]: [string, number, number],
  ): Move => {
    const aMinTaxesInPrice = a?.getMinTaxesInPrice(themeId, locationId, companyId);
    const bMinTaxesInPrice = b?.getMinTaxesInPrice(themeId, locationId, companyId);
    return SortVariantGroupUtils.numberAscending(aMinTaxesInPrice, bMinTaxesInPrice);
  };
  static variantGroupsTaxesInPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [themeId, locationId, companyId]: [string, number, number],
  ): Move => {
    const aMinTaxesInPrice = a?.getMaxTaxesInPrice(themeId, locationId, companyId);
    const bMinTaxesInPrice = b?.getMaxTaxesInPrice(themeId, locationId, companyId);
    return SortVariantGroupUtils.numberDescending(aMinTaxesInPrice, bMinTaxesInPrice);
  };

  static variantGroupsTaxesInRoundedPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [themeId, locationId, companyId]: [string, number, number],
  ): Move => {
    const aMinTaxesInPrice = a?.getMinTaxesInPrice(themeId, locationId, companyId);
    const bMinTaxesInPrice = b?.getMinTaxesInPrice(themeId, locationId, companyId);
    return SortVariantGroupUtils.numberAscending(aMinTaxesInPrice, bMinTaxesInPrice);
  };
  static variantGroupsTaxesInRoundedPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [themeId, locationId, companyId]: [string, number, number],
  ): Move => {
    const aMinTaxesInPrice = a?.getMaxTaxesInRoundedPrice(themeId, locationId, companyId);
    const bMinTaxesInPrice = b?.getMaxTaxesInRoundedPrice(themeId, locationId, companyId);
    return SortVariantGroupUtils.numberDescending(aMinTaxesInPrice, bMinTaxesInPrice);
  };

  static variantGroupsPreTaxPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [themeId, locationId, companyId]: [string, number, number],
  ): Move => {
    const aMinPreTaxPrice = a?.getMinPreTaxPrice(themeId, locationId, companyId);
    const bMinPreTaxPrice = b?.getMinPreTaxPrice(themeId, locationId, companyId);
    return SortVariantGroupUtils.numberAscending(aMinPreTaxPrice, bMinPreTaxPrice);
  };
  static variantGroupsPreTaxPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [themeId, locationId, companyId]: [string, number, number],
  ): Move => {
    const aMinPreTaxPrice = a?.getMaxPreTaxPrice(themeId, locationId, companyId);
    const bMinPreTaxPrice = b?.getMaxPreTaxPrice(themeId, locationId, companyId);
    return SortVariantGroupUtils.numberDescending(aMinPreTaxPrice, bMinPreTaxPrice);
  };

  static variantGroupsMinSecondaryPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [themeId, locationId, companyId]: [string, number, number],
    [locationPriceStream]: [LocationPriceStream]
  ): Move => {
    const aMinGroupSecondaryPrice = a?.getMinGroupSecondaryPrice(themeId, companyId, locationId, locationPriceStream);
    const bMinGroupSecondaryPrice = b?.getMinGroupSecondaryPrice(themeId, companyId, locationId, locationPriceStream);
    return SortVariantGroupUtils.numberAscending(aMinGroupSecondaryPrice, bMinGroupSecondaryPrice);
  };
  static variantGroupsMinSecondaryPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [themeId, locationId, companyId]: [string, number, number],
    [locationPriceStream]: [LocationPriceStream]
  ): Move => {
    const aMinGroupSecondaryPrice = a?.getMinGroupSecondaryPrice(themeId, companyId, locationId, locationPriceStream);
    const bMinGroupSecondaryPrice = b?.getMinGroupSecondaryPrice(themeId, companyId, locationId, locationPriceStream);
    return SortVariantGroupUtils.numberDescending(aMinGroupSecondaryPrice, bMinGroupSecondaryPrice);
  };

  static variantGroupsMaxSecondaryPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [themeId, locationId, companyId]: [string, number, number],
    [locationPriceStream]: [LocationPriceStream]
  ): Move => {
    const aMaxGroupSecondaryPrice = a?.getMaxGroupSecondaryPrice(themeId, companyId, locationId, locationPriceStream);
    const bMaxGroupSecondaryPrice = b?.getMaxGroupSecondaryPrice(themeId, companyId, locationId, locationPriceStream);
    return SortVariantGroupUtils.numberAscending(aMaxGroupSecondaryPrice, bMaxGroupSecondaryPrice);
  };
  static variantGroupsMaxSecondaryPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [themeId, locationId, companyId]: [string, number, number],
    [locationPriceStream]: [LocationPriceStream]
  ): Move => {
    const aMaxGroupSecondaryPrice = a?.getMaxGroupSecondaryPrice(themeId, companyId, locationId, locationPriceStream);
    const bMaxGroupSecondaryPrice = b?.getMaxGroupSecondaryPrice(themeId, companyId, locationId, locationPriceStream);
    return SortVariantGroupUtils.numberDescending(aMaxGroupSecondaryPrice, bMaxGroupSecondaryPrice);
  };

  static variantGroupsProductTypeAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.nonNullStringAscending(a?.getGroupProductType(), b?.getGroupProductType());
  };
  static variantGroupsProductTypeDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.nonNullStringDescending(a?.getGroupProductType(), b?.getGroupProductType());
  };

  static variantGroupsSecondaryPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [tId, locId, compId]: [string, number, number],
    [section, priceStream, hideSale]: [SectionWithProducts, LocationPriceStream, boolean]
  ): Move => {
    const secondaryPriceState = section?.columnConfig?.get(SectionColumnConfigProductInfoKey.SecondaryPrice)?.dataValue;
    switch (secondaryPriceState) {
      case SectionColumnConfigDataValue.PricePerUOM:
        return SortVariantGroupUtils.variantGroupsPricePerUOMAsc([a, b], [tId, locId, compId], [priceStream, hideSale]);
      case SectionColumnConfigDataValue.OriginalPrice:
        return SortVariantGroupUtils.variantGroupsOriginalPriceAsc([a, b], [tId, locId, compId], [priceStream]);
      case SectionColumnConfigDataValue.SaleOriginalPrice:
        return SortVariantGroupUtils.variantGroupsSaleOriginalPriceAsc([a, b], [tId, locId, compId], [priceStream]);
      case SectionColumnConfigDataValue.OriginalAndSalePrice:
        return SortVariantGroupUtils.variantGroupsOriginalAndSalePriceAsc([a, b], [tId, locId, compId], [priceStream]);
      case SectionColumnConfigDataValue.TaxesInPrice:
        return SortVariantGroupUtils.variantGroupsTaxesInPriceAsc([a, b], [tId, locId, compId]);
      case SectionColumnConfigDataValue.TaxesInRoundedPrice:
        return SortVariantGroupUtils.variantGroupsTaxesInRoundedPriceAsc([a, b], [tId, locId, compId]);
      case SectionColumnConfigDataValue.PreTaxPrice:
        return SortVariantGroupUtils.variantGroupsPreTaxPriceAsc([a, b], [tId, locId, compId]);
      default:
        return SortVariantGroupUtils.variantGroupsMinSecondaryPriceAsc([a, b], [tId, locId, compId], [priceStream]);
    }
  };
  static variantGroupsSecondaryPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [tId, locId, cId]: [string, number, number],
    [section, priceStream, hideSale]: [SectionWithProducts, LocationPriceStream, boolean]
  ): Move => {
    const secondaryPriceState = section?.columnConfig?.get(SectionColumnConfigProductInfoKey.SecondaryPrice)?.dataValue;
    switch (secondaryPriceState) {
      case SectionColumnConfigDataValue.PricePerUOM:
        return SortVariantGroupUtils.variantGroupsPricePerUOMDesc([a, b], [tId, locId, cId], [priceStream, hideSale]);
      case SectionColumnConfigDataValue.OriginalPrice:
        return SortVariantGroupUtils.variantGroupsOriginalPriceDesc([a, b], [tId, locId, cId], [priceStream]);
      case SectionColumnConfigDataValue.SaleOriginalPrice:
        return SortVariantGroupUtils.variantGroupsSaleOriginalPriceDesc([a, b], [tId, locId, cId], [priceStream]);
      case SectionColumnConfigDataValue.OriginalAndSalePrice:
        return SortVariantGroupUtils.variantGroupsOriginalAndSalePriceDesc([a, b], [tId, locId, cId], [priceStream]);
      case SectionColumnConfigDataValue.TaxesInPrice:
        return SortVariantGroupUtils.variantGroupsTaxesInPriceDesc([a, b], [tId, locId, cId]);
      case SectionColumnConfigDataValue.TaxesInRoundedPrice:
        return SortVariantGroupUtils.variantGroupsTaxesInRoundedPriceDesc([a, b], [tId, locId, cId]);
      case SectionColumnConfigDataValue.PreTaxPrice:
        return SortVariantGroupUtils.variantGroupsPreTaxPriceDesc([a, b], [tId, locId, cId]);
      default:
        return SortVariantGroupUtils.variantGroupsMaxSecondaryPriceDesc([a, b], [tId, locId, cId], [priceStream]);
    }
  };

  static variantGroupsStockAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.numberAscending(a?.getMaxVariantStock(), b?.getMaxVariantStock());
  };
  static variantGroupsStockDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.numberDescending(a?.getMaxVariantStock(), b?.getMaxVariantStock());
  };

  static variantGroupsTitleAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.nonNullStringAscending(a?.getGroupTitle(), b?.getGroupTitle());
  };
  static variantGroupsTitleDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.nonNullStringDescending(a?.getGroupTitle(), b?.getGroupTitle());
  };

  static variantGroupsUnitSizeAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.numberAscending(a?.getMinNumericUnitSize(), b?.getMinNumericUnitSize());
  };
  static variantGroupsUnitSizeDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.numberDescending(a?.getMaxNumericUnitSize(), b?.getMaxNumericUnitSize());
  };

  static variantGroupCannabinoidAsc = (a: VariantGroup, b: VariantGroup, cannabinoid: string): Move => {
    return SortVariantGroupUtils.numberAscending(
      a?.getMinNumericCannabinoid(cannabinoid),
      b?.getMinNumericCannabinoid(cannabinoid)
    );
  };
  static variantGroupCannabinoidDesc = (a: VariantGroup, b: VariantGroup, cannabinoid: string): Move => {
    return SortVariantGroupUtils.numberAscending(
      a?.getMaxNumericCannabinoid(cannabinoid),
      b?.getMaxNumericCannabinoid(cannabinoid)
    );
  };

  static variantGroupTerpeneAsc = (a: VariantGroup, b: VariantGroup, terpeneCamelCased: string): Move => {
    return SortVariantGroupUtils.numberAscending(
      a?.getMinNumericTerpene(terpeneCamelCased),
      b?.getMinNumericTerpene(terpeneCamelCased)
    );
  };
  static variantGroupTerpeneDesc = (a: VariantGroup, b: VariantGroup, terpeneCamelCased: string): Move => {
    return SortVariantGroupUtils.numberAscending(
      a?.getMaxNumericTerpene(terpeneCamelCased),
      b?.getMaxNumericTerpene(terpeneCamelCased)
    );
  };

  static variantGroupTopTerpeneAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.nonNullStringAscending(a?.getVariantGroupTopTerpene(), b?.getVariantGroupTopTerpene());
  };
  static variantGroupTopTerpeneDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.nonNullStringDescending(
      a?.getVariantGroupTopTerpene(),
      b?.getVariantGroupTopTerpene()
    );
  };

  /* *******************************************************************************************
   *                          Section Variant Groups - Sort Options                            *
   * *******************************************************************************************/

  static sectionVariantGroupsBrandAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.variantGroupsBrandAsc(a, b);
  };
  static sectionVariantGroupsBrandDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.variantGroupsBrandDesc(a, b);
  };

  static sectionVariantGroupsClassificationAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.variantGroupsClassificationAsc(a, b);
  };
  static sectionVariantGroupsClassificationDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.variantGroupsClassificationDesc(a, b);
  };

  static sectionVariantGroupsManufacturerAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.variantGroupsManufacturerAsc(a, b);
  };
  static sectionVariantGroupsManufacturerDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.variantGroupsManufacturerDesc(a, b);
  };

  static sectionVariantGroupsPackagedQuantityAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.variantGroupsPackagedQuantityAsc(a, b);
  };
  static sectionVariantGroupsPackagedQuantityDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.variantGroupsPackagedQuantityDesc(a, b);
  };

  static sectionVariantGroupsPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [tId, locId, compId]: [string, number, number],
    [locationPriceStream, hideSale]: [LocationPriceStream, boolean]
  ): Move => {
    return SortVariantGroupUtils.variantGroupsPriceAsc([a, b], [tId, locId, compId], [locationPriceStream, hideSale]);
  };
  static sectionVariantGroupsPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [tId, locId, compId]: [string, number, number],
    [locationPriceStream, hideSale]: [LocationPriceStream, boolean]
  ): Move => {
    return SortVariantGroupUtils.variantGroupsPriceDesc([a, b], [tId, locId, compId], [locationPriceStream, hideSale]);
  };

  static sectionVariantGroupsProductTypeAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.variantGroupsProductTypeAsc(a, b);
  };
  static sectionVariantGroupsProductTypeDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.variantGroupsProductTypeDesc(a, b);
  };

  static sectionVariantGroupsSecondaryPriceAsc = (
    [a, b]: [VariantGroup, VariantGroup],
    [themId, locationId, companyId]: [string, number, number],
    [section, locationPriceStream, hideSale]: [SectionWithProducts, LocationPriceStream, boolean]
  ): Move => {
    return SortVariantGroupUtils.variantGroupsSecondaryPriceAsc(
      [a, b],
      [themId, locationId, companyId],
      [section, locationPriceStream, hideSale]
    );
  };
  static sectionVariantGroupsSecondaryPriceDesc = (
    [a, b]: [VariantGroup, VariantGroup],
    [themId, locationId, companyId]: [string, number, number],
    [section, locationPriceStream, hideSale]: [SectionWithProducts, LocationPriceStream, boolean]
  ): Move => {
    return SortVariantGroupUtils.variantGroupsSecondaryPriceDesc(
      [a, b],
      [themId, locationId, companyId],
      [section, locationPriceStream, hideSale]
    );
  };

  static sectionVariantGroupsStockAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.variantGroupsStockAsc(a, b);
  };
  static sectionVariantGroupsStockDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.variantGroupsStockDesc(a, b);
  };

  static sectionVariantGroupsTitleAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.variantGroupsTitleAsc(a, b);
  };
  static sectionVariantGroupsTitleDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.variantGroupsTitleDesc(a, b);
  };

  static sectionVariantGroupsUnitSizeAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.variantGroupsUnitSizeAsc(a, b);
  };
  static sectionVariantGroupsUnitSizeDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.variantGroupsUnitSizeDesc(a, b);
  };

  static sectionVariantGroupsCannabinoidAsc = (
    a: VariantGroup,
    b: VariantGroup,
    cannabinoid: string
  ): Move => {
    return SortVariantGroupUtils.variantGroupCannabinoidAsc(a, b, cannabinoid);
  };
  static sectionVariantGroupsCannabinoidDesc = (
    a: VariantGroup,
    b: VariantGroup,
    cannabinoid: string
  ): Move => {
    return SortVariantGroupUtils.variantGroupCannabinoidDesc(a, b, cannabinoid);
  };

  static sectionVariantGroupsTerpeneAsc = (
    a: VariantGroup,
    b: VariantGroup,
    terpeneCamelCased: string
  ): Move => {
    return SortVariantGroupUtils.variantGroupTerpeneAsc(a, b, terpeneCamelCased);
  };
  static sectionVariantGroupsTerpeneDesc = (
    a: VariantGroup,
    b: VariantGroup,
    terpeneCamelCased: string
  ): Move => {
    return SortVariantGroupUtils.variantGroupTerpeneDesc(a, b, terpeneCamelCased);
  };

  static sectionVariantGroupsTopTerpeneAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.variantGroupTopTerpeneAsc(a, b);
  };
  static sectionVariantGroupsTopTerpeneDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.variantGroupTopTerpeneDesc(a, b);
  };

  static sectionVariantGroupsVariantTypeAsc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.nonNullStringAscending(a?.getVariantType(), b?.getVariantType());
  };
  static sectionVariantGroupsVariantTypeDesc = (a: VariantGroup, b: VariantGroup): Move => {
    return SortVariantGroupUtils.nonNullStringDescending(a?.getVariantType(), b?.getVariantType());
  };

  private static sectionVariantGroupSorter(
    sortOption: SectionSortOption,
    [a, b]: [VariantGroup, VariantGroup],
    [themeId, locationId, companyId]: [string, number, number],
    [section, locationPriceStream, hideSale]: [SectionWithProducts, LocationPriceStream, boolean]
  ): Move {
    const productSortType = () => {
      switch (sortOption) {
        case SectionSortProductInfo.BrandAsc:
          return SortVariantGroupUtils.sectionVariantGroupsBrandAsc(a, b);
        case SectionSortProductInfo.BrandDesc:
          return SortVariantGroupUtils.sectionVariantGroupsBrandDesc(a, b);
        // CBDAsc uses min CBD
        case SectionSortProductInfo.CBDAsc:
          return SortVariantGroupUtils.sectionVariantGroupsCannabinoidAsc(a, b, 'CBD');
        // CBDDesc uses max CBD
        case SectionSortProductInfo.CBDDesc:
          return SortVariantGroupUtils.sectionVariantGroupsCannabinoidDesc(a, b, 'CBD');
        case SectionSortProductInfo.ClassificationAsc:
          return SortVariantGroupUtils.sectionVariantGroupsClassificationAsc(a, b);
        case SectionSortProductInfo.ClassificationDesc:
          return SortVariantGroupUtils.sectionVariantGroupsClassificationDesc(a, b);
        case SectionSortProductInfo.ManufacturerAsc:
          return SortVariantGroupUtils.sectionVariantGroupsManufacturerAsc(a, b);
        case SectionSortProductInfo.ManufacturerDesc:
          return SortVariantGroupUtils.sectionVariantGroupsManufacturerDesc(a, b);
        // PackagedQuantityAsc uses min packaged quantity
        case SectionSortProductInfo.PackagedQuantityAsc:
          return SortVariantGroupUtils.sectionVariantGroupsPackagedQuantityAsc(a, b);
        // PackagedQuantityDesc uses max packaged quantity
        case SectionSortProductInfo.PackagedQuantityDesc:
          return SortVariantGroupUtils.sectionVariantGroupsPackagedQuantityDesc(a, b);
        // PriceAsc uses min price
        case SectionSortProductInfo.PriceAsc:
          return SortVariantGroupUtils.sectionVariantGroupsPriceAsc(
            [a, b],
            [themeId, locationId, companyId],
            [locationPriceStream, hideSale]
          );
        // PriceDesc uses max price
        case SectionSortProductInfo.PriceDesc:
          return SortVariantGroupUtils.sectionVariantGroupsPriceDesc(
            [a, b],
            [themeId, locationId, companyId],
            [locationPriceStream, hideSale]
          );
        case SectionSortProductInfo.ProductTypeAsc:
          return SortVariantGroupUtils.sectionVariantGroupsProductTypeAsc(a, b);
        case SectionSortProductInfo.ProductTypeDesc:
          return SortVariantGroupUtils.sectionVariantGroupsProductTypeDesc(a, b);
        // PriceAsc uses min secondary price
        case SectionSortProductInfo.SecondaryPriceAsc:
          return SortVariantGroupUtils.sectionVariantGroupsSecondaryPriceAsc(
            [a, b],
            [themeId, locationId, companyId],
            [section, locationPriceStream, hideSale]
          );
        // PriceDesc uses max secondary price
        case SectionSortProductInfo.SecondaryPriceDesc:
          return SortVariantGroupUtils.sectionVariantGroupsSecondaryPriceDesc(
            [a, b],
            [themeId, locationId, companyId],
            [section, locationPriceStream, hideSale]
          );
        case SectionSortProductInfo.StockAsc:
          return SortVariantGroupUtils.sectionVariantGroupsStockAsc(a, b);
        case SectionSortProductInfo.StockDesc:
          return SortVariantGroupUtils.sectionVariantGroupsStockDesc(a, b);
        case SectionSortProductInfo.TitleAsc:
          return SortVariantGroupUtils.sectionVariantGroupsTitleAsc(a, b);
        case SectionSortProductInfo.TitleDesc:
          return SortVariantGroupUtils.sectionVariantGroupsTitleDesc(a, b);
        // THCAsc uses min THC
        case SectionSortProductInfo.THCAsc:
          return SortVariantGroupUtils.sectionVariantGroupsCannabinoidAsc(a, b, 'THC');
        // THCDesc uses max THC
        case SectionSortProductInfo.THCDesc:
          return SortVariantGroupUtils.sectionVariantGroupsCannabinoidDesc(a, b, 'THC');
        case SectionSortProductInfo.TopTerpeneAsc:
          return SortVariantGroupUtils.sectionVariantGroupsTopTerpeneAsc(a, b);
        case SectionSortProductInfo.TopTerpeneDesc:
          return SortVariantGroupUtils.sectionVariantGroupsTopTerpeneDesc(a, b);
        case SectionSortProductInfo.TotalTerpeneAsc:
          return SortVariantGroupUtils.sectionVariantGroupsTerpeneAsc(a, b, 'totalTerpene');
        case SectionSortProductInfo.TotalTerpeneDesc:
          return SortVariantGroupUtils.sectionVariantGroupsTerpeneDesc(a, b, 'totalTerpene');
        // UnitSizeAsc uses min unit size
        case SectionSortProductInfo.UnitSizeAsc:
          return SortVariantGroupUtils.sectionVariantGroupsUnitSizeAsc(a, b);
        // UnitSizeDesc uses max unit size
        case SectionSortProductInfo.UnitSizeDesc:
          return SortVariantGroupUtils.sectionVariantGroupsUnitSizeDesc(a, b);
        case SectionSortProductInfo.VariantTypeAsc:
          return SortVariantGroupUtils.sectionVariantGroupsVariantTypeAsc(a, b);
        case SectionSortProductInfo.VariantTypeDesc:
          return SortVariantGroupUtils.sectionVariantGroupsVariantTypeDesc(a, b);
        default:
          return SortVariantGroupUtils.variantGroupsTitleAsc(a, b);
      }
    };
  const getSecondaryCannabinoid = (): Move =>  {
    const { cannabinoid, order } = SortVariantGroupUtils.getCannabinoidAccessor(sortOption);
    const isAscending = order === 'ASC';
    return isAscending
      ? SortVariantGroupUtils.sectionVariantGroupsCannabinoidAsc(a, b, cannabinoid)
      : SortVariantGroupUtils.sectionVariantGroupsCannabinoidDesc(a, b, cannabinoid);
  };
    const getTerpene = (): Move => {
      const { terpeneCamelCased, order } = SortVariantGroupUtils.getTerpeneAccessor(sortOption);
      const isAscending = order === 'ASC';
      return isAscending
        ? SortVariantGroupUtils.sectionVariantGroupsTerpeneAsc(a, b, terpeneCamelCased)
        : SortVariantGroupUtils.sectionVariantGroupsTerpeneDesc(a, b, terpeneCamelCased);
    };
    switch (true) {
      case Object.values(SectionSortProductInfo).includes(sortOption as SectionSortProductInfo):
        return productSortType();
      case Object.values(SectionSortSecondaryCannabinoids).includes(sortOption as SectionSortSecondaryCannabinoids):
        return getSecondaryCannabinoid();
      case Object.values(SectionSortTerpenes).includes(sortOption as SectionSortTerpenes):
        return getTerpene();
    }
  }

  static variantGroupsBySortOptions = (
    variantGroups: VariantGroup[],
    menu: Menu,
    sec: SectionWithProducts,
    priceStream: LocationPriceStream
  ): VariantGroup[] => {
    const sorter = SortVariantGroupUtils.sectionVariantGroupSorter;
    const sortedGroups = SortVariantGroupUtils.sortVariantsInSection(sorter, variantGroups, menu, sec, priceStream);
    const sortVariantsInGrouping = (variantGroup: VariantGroup) => {
      variantGroup.sortVariantsBy(SortVariantGroupUtils.sortVariantsBySectionSortOptions, menu, sec, priceStream);
    };
    sortedGroups.forEach(sortVariantsInGrouping);
    return sortedGroups;
  };

}
