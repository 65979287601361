import { Injectable } from '@angular/core';
import { PrintCardBackgroundViewModel } from '../../print-card-background-view-model';
import { Observable } from 'rxjs';
import { NycdCardData } from '../../../../../../../models/print-cards/nycd-card-data';

@Injectable()
export class NycdIndexCardStackBackgroundViewModel extends PrintCardBackgroundViewModel {

  public override cardData$: Observable<NycdCardData>;

}
