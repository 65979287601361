<div
  #headerContainer
  [ngClass]="menu | productMenuBasicMethod : 'getHeaderWrapperClass'"
  [ngStyle]="{
    color: menu?.hydratedTheme?.themeFeatures?.titleTextColor ? menu?.menuOptions?.titleTextColor : ''
  }">
  <div class="header-container">
    <div class="company-logo-container" *ngIf="menu | productMenuBasicMethod : 'showCompanyLogo'">
      <img
        appImage
        class="company-logo flex-grow"
        [style.zoom]="menu?.menuOptions?.titleZoom || 1.0"
        [style.margin]="menu | productMenuBasicMethod : 'getHeaderLogoMargin'"
        [asset]="menu?.companyLogo"
        [reset]="reset"
        alt="company logo"
        src="" />
    </div>
    <div
      class="main-header"
      [hidden]="menu?.menuOptions?.hideTitle || !menu?.configurationTitle"
      [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
      {{ menu?.configurationTitle }}
    </div>
    <div class="flex-container-row flex-content-end flex-grow" [style.zoom]="menu?.menuOptions?.titleZoom || 1.0"></div>
  </div>
</div>
