import { MarketingMenu } from './marketing-menu';
import { Asset } from '../image/dto/asset';
import { OrderFeaturedVariants } from './marketing/FeaturedProduct/order-featured-variants';
import { NumberUtils } from '../../utils/number.utils';
import { MarketingAsset } from '../image/dto/marketing-asset';
import { DisplayOptions } from '../shared/display-options';
import { VariantFeature } from '../product/dto/variant-feature';
import { CompanyConfiguration } from '../company/dto/company-configuration';
import { LocationConfiguration } from '../company/dto/location-configuration';

/**
 * @deprecated
 * This class is used to represent marketing menus that are not using the new marketing menu infrastructure.
 * They use a property called "hydratedVariantFeature" to store variant information relevant to the menu.
 */
export abstract class DeprecatedMarketingMenu extends MarketingMenu {

  public hydratedVariantFeature: VariantFeature = new VariantFeature();
  public marketingAssetHashes: string[];
  public override template?: DeprecatedMarketingMenu;

  public abstract isAssetEnabled(asset: Asset): boolean;

  public abstract getAssetPosition(asset: Asset): number;

  public abstract getOrderFeaturedVariants(
    featured: VariantFeature,
    order: DisplayOptions,
    media: MarketingAsset[]
  ): OrderFeaturedVariants[];

  protected override createLocalObjectReferences(): void {
    super.createLocalObjectReferences();
    const Deserialize = window?.injector?.Deserialize;
    this.hydratedVariantFeature = Deserialize?.instanceOf(VariantFeature, this.hydratedVariantFeature);
    this.marketingAssetHashes = Array.from(this.marketingAssetHashes || []);
  }

  protected override mergeAdvancedDataPropertiesFromMenuTemplate(menuTemplate: DeprecatedMarketingMenu): void {
    super.mergeAdvancedDataPropertiesFromMenuTemplate(menuTemplate);
    this.combineMenuMarketingDataWithTemplate(menuTemplate);
  }

  /**
   * Always use template data.
   */
  protected combineMenuMarketingDataWithTemplate(menuTemplate: DeprecatedMarketingMenu): void {
    this.hydratedVariantFeature = this.template?.hydratedVariantFeature;
    this.marketingAssetHashes = this.template?.marketingAssetHashes;
  }

  getInStockVariantIds(): string[] {
    // Get out of stock variants
    const inventory = this.hydratedVariantFeature?.variants?.map(v => v?.inventory);
    const inStock = [];
    for (const i of inventory) {
      if (NumberUtils.floatFirstGreaterThanSecond(i?.quantityInStock, 0)) {
        inStock.push(i.variantId);
      }
    }
    return inStock;
  }

  protected getVariantIdFromAsset(asset: Asset): string {
    let variantId;
    for (const [key, value] of (this.hydratedVariantFeature?.assetNameMap?.entries() || [])) {
      if (value === asset?.fileName) {
        variantId = key;
        break;
      }
    }
    return variantId;
  }

  override setUniqueIdentifier(
    companyConfig: CompanyConfiguration,
    locationConfig: LocationConfiguration,
  ): string {
    const superId = super.setUniqueIdentifier(companyConfig, locationConfig);
    const hydratedVariantFeatureId = this.hydratedVariantFeature?.getUniqueIdentifier() ?? '';
    const marketingAssetsIds = this.marketingAssetHashes?.sort().join(',') ?? '';
    this.uniqueIdentifier = `
      -${superId}
      -${hydratedVariantFeatureId}
      -${marketingAssetsIds}
    `;
    return this.uniqueIdentifier;
  }

}
