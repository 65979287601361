import { Deserializable } from '../../protocols/deserializable';
import { Selectable } from '../../protocols/selectable';

export class Location implements Deserializable, Selectable {

  public id: number;
  public name: string;
  public address: string;
  public city: string;
  public state: string;
  public stateCode: string;
  public country: string;
  public countryCode: string;
  public lastModified: number;
  public timezone: string;

  public onDeserialize() {

  }

  getSelectionTitle(): any {
    return this.name;
  }

  getSelectionValue(): any {
    return this.id;
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getCityProv(): string {
    return `${this.city}, ${this.stateCode}`;
  }

}
