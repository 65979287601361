import { Pipe, PipeTransform } from '@angular/core';
import { SpotlightMenu } from '../../../models/menu/spotlight-menu';
import type { Menu } from '../../../models/menu/menu';

@Pipe({
  name: 'isSpotlightMenu'
})
export class IsSpotlightMenuPipe implements PipeTransform {

  transform(value: Menu): boolean {
    return value instanceof SpotlightMenu;
  }

}
