import { CardData } from './card-data';
import { ProductType } from '../enum/dto/product-type.enum';
import { VariantType } from '../enum/dto/variant-type.enum';
import { VariantTypeUtils } from '../../utils/variant-type-utils';
import { exists } from '../../functions/exists';

export class TrueNorthPortraitCardData extends CardData {

  public override getSize(): string {
    const v = this.rowVariants?.firstOrNull();
    switch (true) {
      case VariantTypeUtils.isPreRollType(v?.variantType): {
        const quantity = v?.packagedQuantity;
        const formattedUnitSize = v?.getFormattedUnitSize();
        const hasQty = Number.isFinite(quantity);
        const hasSize = exists(formattedUnitSize) && (formattedUnitSize !== '-');
        switch (true) {
          case hasQty && hasSize:  return `${quantity} x ${formattedUnitSize}`;
          case hasQty && !hasSize: return `${quantity}`;
          default:                 return '-';
        }
      }
      case VariantTypeUtils.isCapsuleType(v?.variantType): {
        const n = v?.packagedQuantity ?? 0;
        return `${n} Capsule`
          .pluralizer()
          .addRule({ isPlural: n > 1, useApostrophe: false, word: 'Capsule' })
          .pluralize();
      }
      case (v?.productType === ProductType.Beverage && VariantTypeUtils.isReadyToDrinkBeverageType(v?.variantType)): {
        const quantity = v?.packagedQuantity;
        const formattedUnitSize = v?.getFormattedUnitSize();
        return quantity === 1 ? `${formattedUnitSize}` : `${quantity} x ${formattedUnitSize}`;
      }
      case (v?.productType === ProductType.Edible && !VariantTypeUtils.isReadyToDrinkBeverageType(v?.variantType)): {
        return `${v?.packagedQuantity} pc`;
      }
      case ProductType.Seed === v?.productType || VariantType.Seed === v?.variantType: {
        return `${v?.packagedQuantity} Seed`
          .pluralizer()
          .addRule({ isPlural: v?.packagedQuantity > 1, useApostrophe: false, word: 'Seed' })
          .pluralize();
      }
      default: {
        return v?.getFormattedUnitSize();
      }
    }
  }

}
