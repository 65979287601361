import { PrintCardMenu } from './print-card-menu';

export class MissJonesPrintCardMenu extends PrintCardMenu {

  getFontFaceList(): string[] {
    return ['oswald', 'futura-pt', 'rama-gothic-m'];
  }

  shouldStandardizeDominantStrainType(): boolean {
    return false;
  }

}
