import { Injectable } from '@angular/core';
import { BaseService } from '../../models/base/base-service';
import { BehaviorSubject, Observable } from 'rxjs';
import type { Section } from '../../models/menu/section/section';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CachedOverflowService extends BaseService {

  constructor() {
    super();
  }

  private readonly _cachedOverflowedSections = new BehaviorSubject<Map<string, Section[]>>(new Map());
  public readonly cachedOverflowedSections$ = this._cachedOverflowedSections as Observable<Map<string, Section[]>>;

  public connectToOverflowedSections(menuId: string, overflowed: Section[]) {
    const current = this._cachedOverflowedSections.value?.shallowCopy() || new Map();
    current.set(menuId, overflowed);
    this._cachedOverflowedSections.next(current);
  }

  public clearOverflowedSectionsFor(menuId: string) {
    const current = this._cachedOverflowedSections.value?.shallowCopy() || new Map();
    current.delete(menuId);
    this._cachedOverflowedSections.next(current);
  }

  public getCachedOverflowedSectionsFor(menuId: string): Observable<Section[]> {
    return this.cachedOverflowedSections$.pipe(map(cache => cache?.get(menuId)));
  }

}
