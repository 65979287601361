import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { GanjikaBeverageAddressCardStackContentViewModel } from './ganjika-beverage-address-card-stack-content-view-model';
import { PrintCardContentComponent } from '../../print-card-content.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-ganjika-beverage-address-card-stack-content',
  templateUrl: './ganjika-beverage-address-card-stack-content.component.html',
  styleUrls: ['./ganjika-beverage-address-card-stack-content.component.scss'],
  providers: [GanjikaBeverageAddressCardStackContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GanjikaBeverageAddressCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public viewModel: GanjikaBeverageAddressCardStackContentViewModel, // override
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

  setupBindings() {
    super.setupBindings();
    this.listenToVirtualLabelText();
  }

  private listenToVirtualLabelText(): void {
    this.labelCalculator?.virtualLabelText$
      ?.pipe(takeUntil(this.onDestroy))
      ?.subscribe(labelText => this.viewModel.connectToVirtualLabelText(labelText));
  }

}
