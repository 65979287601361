import { Injectable } from '@angular/core';
import { BaseService } from '../../models/base/base-service';
import { AssetUrl } from '../../models/image/dto/asset-url';
import { SafeResourceUrl } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class DuplicateAssetService extends BaseService {

  private downloading: AssetUrl[] = [];
  private duplicateQueue: AssetUrl[] = [];

  constructor() {
    super();
  }

  isDownloading(assetUrl: AssetUrl) {
    if (assetUrl?.isSafariAndWebm()) return false;
    const isDownloading = this.downloading.find(url => url.duplicateEquals(assetUrl));
    if (!isDownloading) {
      this.downloading.push(assetUrl);
    }
    return isDownloading;
  }

  addToDuplicateQueue(assetUrl: AssetUrl) {
    const alreadyInQ = this.duplicateQueue.find(url => url === assetUrl);
    if (!alreadyInQ) {
      this.duplicateQueue.push(assetUrl);
    }
  }

  public finishedDownloading(assetUrl: AssetUrl, url: string | SafeResourceUrl) {
    const duplicates = this.duplicateQueue.filter(qItem => qItem.duplicateEquals(assetUrl));
    duplicates.forEach(dup => {
      dup.setUrlSubjectFromDuplicate(url);
      const dupIndex = this.duplicateQueue.findIndex(qUrl => qUrl === dup);
      if (dupIndex > -1) {
        this.duplicateQueue.splice(dupIndex, 1);
      }
    });
    const downloadedIndex = this.downloading.findIndex(dUrl => dUrl === assetUrl);
    if (downloadedIndex > -1) {
      this.downloading.splice(downloadedIndex, 1);
    }
  }

}
