import { PrintCardMenu } from '../print-card/print-card-menu';
import { PaperSize } from '../../shared/paper-size';
import { Section } from '../section/section';
import { SectionType } from '../../enum/dto/section-type.enum';
import { LabelStack } from '../section/label-stacks/label-stack';
import { LabelData } from '../../print-labels/label-data';
import { EmptyLabelData } from '../../print-labels/empty-label-data';

export abstract class PrintLabelMenu extends PrintCardMenu {

  public override template: PrintLabelMenu;
  public override sections: LabelStack[];
  public override templateSections: LabelStack[];
  public override displaySize: PaperSize;

  protected override deserializeDisplaySize(): void {
    this.displaySize = window?.injector?.Deserialize?.instanceOf(PaperSize, this.displaySize);
  }

  protected override deserializeSections(): void {
    this.sections?.forEach(section => this.setDataNotFromAPI(section));
    const sections = window?.injector?.Deserialize?.arrayOf(Section, this.sections) ?? [];
    this.sections = sections?.filter((s: Section): s is LabelStack => s instanceof LabelStack);
  }

  protected override deserializeTemplateSections(): void {
    this.templateSections?.forEach(templateSection => this.setDataNotFromAPI(templateSection));
    const templateSections = window?.injector?.Deserialize?.arrayOf(Section, this.templateSections) ?? [];
    this.templateSections = templateSections?.filter((s: Section): s is LabelStack => s instanceof LabelStack);
  }

  protected override setDataNotFromAPI(section: LabelStack): void {
    super.setDataNotFromAPI(section);
    section.sectionType = SectionType.LabelStack;
  }

  getLabelStack(): LabelStack | null {
    return this.sections?.firstOrNull();
  }

  protected override getEmptyCards(
    currentPage: LabelData[],
    nCardsPerPage: number,
    nColumns: number
  ): EmptyLabelData[] {
    const nMoreCardsCanFitOnPage = nCardsPerPage - currentPage?.length;
    const nEmptyCardsToAdd = nMoreCardsCanFitOnPage < nColumns ? nMoreCardsCanFitOnPage : nColumns;
    return [...Array(nEmptyCardsToAdd)].map(() => new EmptyLabelData());
  }

}
