import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InflatorContainerDirective } from './inflator-container.directive';

@NgModule({
  declarations: [
    InflatorContainerDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    InflatorContainerDirective
  ]
})
export class InflatorModule { }
