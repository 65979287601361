import { LocalizedDateRange } from './localized-date-range';
import moment from 'moment-timezone';
import { exists } from '../../functions/exists';
import { DateUtils } from '../../utils/date-utils';

/**
 * startDate and endDate are in unix seconds.
 */
export class LocalizedDateRangeUnixSeconds extends LocalizedDateRange {

  public isRightNowWithinDateTimeWindow(timezone?: string): boolean {
    if (exists(this.testTimestampAt)) {
      return this.isTestDateWithinTimeWindow(this.testTimestampAt, this.testingTimezone);
    }
    return this.isWithinDateTimeWindow(moment().unix(), timezone); // unix() returns Unix Seconds
  }

  public isWithinDateTimeWindow(dateTimeInSeconds: number, timezone?: string): boolean {
    const startDate = this.getUtcTimeInLocalizedTimeZone(this.startDate, timezone);
    const endDate = this.getUtcTimeInLocalizedTimeZone(this.endDate, timezone);
    const comparisonDate = this.getCurrentLocalTimeInLocalizedTimeZone(dateTimeInSeconds, timezone);
    return comparisonDate.isSameOrAfter(startDate) && comparisonDate.isSameOrBefore(endDate);
  }

  protected getUtcTimeInLocalizedTimeZone(utcTimeStampInSeconds: number, timezone?: string): moment.Moment {
    const window = moment.utc(utcTimeStampInSeconds * 1000).format(DateUtils.DATETIME_LOCAL_SECONDS);
    return this.convertUtcTimeWindowStringToLocalDate(window, timezone);
  }

  protected getCurrentLocalTimeInLocalizedTimeZone(timeStampInSeconds: number, timezone?: string): moment.Moment {
    const window = exists(this.testTimestampAt) // test date is localized to different timezone
      ? moment.tz(timeStampInSeconds * 1000, this.testingTimezone).format(DateUtils.DATETIME_LOCAL_SECONDS)
      : moment.unix(timeStampInSeconds).format(DateUtils.DATETIME_LOCAL_SECONDS);
    return this.convertTimeWindowStringToLocalDate(window, timezone);
  }

}
