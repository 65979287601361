@charset "UTF-8";

/* src/app/modules/print-cards/components/print-cards/card-content/business-cards-2x3/plain-jane-business-card-stack-content/plain-jane-business-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0.625rem;
}
:host .card-content {
  position: relative;
  display: flex;
  height: 100%;
}
:host .sidebar-spacer {
  box-sizing: border-box;
  width: 1.375rem;
}
:host .sidebar-section {
  box-sizing: border-box;
  position: absolute;
  padding: 0.25rem;
  transform: rotate(90deg);
  transform-origin: top left;
  left: 1.375rem;
  text-transform: uppercase;
  border-color: black;
  border-style: solid;
  border-width: 0 0.188rem 0.188rem 0.188rem;
  width: 8.813rem;
  height: 1.375rem;
}
:host .sidebar-text {
  white-space: nowrap;
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 0.5625rem;
}
:host .main-section {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.625rem 0.75rem 0.625rem 0.75rem;
  border: 0.188rem solid black;
  border-left: 0.126rem solid black;
}
:host .card-top {
  display: flex;
  gap: 0.25rem;
}
:host .title-brand-label-and-description-container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 0.25rem;
}
:host .title-brand-and-label-container {
  display: flex;
  flex-direction: column;
}
:host .product-card-name {
  line-height: 1.1;
  word-break: break-word;
  font-family: realistnarrow, sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
:host .brand-and-label {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}
:host .product-card-brand {
  font-size: 0.875rem;
  font-family: realistwide, sans-serif;
  font-style: italic;
  font-weight: 800;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
:host .print-card-label {
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .print-card-label ::ng-deep .product-label {
  font-family: poppins, sans-serif;
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 120%;
  border-radius: 0.25rem;
  margin: 0.1rem 0 0 0;
  width: fit-content;
}
:host .product-card-badge-container {
  display: flex;
  flex-direction: column;
  width: 1.125rem;
  gap: 0.25rem;
}
:host .product-card-badge-container .product-card-badge {
  width: 1.125rem;
}
:host .card-bottom {
  display: flex;
  flex: 1 1 0;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0.25rem;
  border-top: 0.063rem solid black;
}
:host .properties-and-price-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0.25rem;
}
:host .properties {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  font-family: realistnarrow, sans-serif;
  font-weight: 400;
}
:host .property-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
:host .property-container .title {
  font-size: 0.625rem;
  width: 100%;
  padding-bottom: 0.25rem;
  border-bottom: 0.063rem solid black;
}
:host .property-container .value {
  padding-top: 0.25rem;
  font-size: 0.6875rem;
  max-width: 90%;
  white-space: pre;
}
:host .product-card-price {
  max-width: 6rem;
}
:host .product-card-price ::ng-deep .product-variant-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: poppins, sans-serif;
  font-weight: 800;
  font-size: 1.3125rem;
  line-height: 100%;
  overflow: visible;
}
:host .product-card-price ::ng-deep .product-variant-price .product-variant-previous-price {
  opacity: 1;
  font-family: poppins, sans-serif;
  font-weight: 800;
  color: #9fa2ad;
  font-size: 0.875rem;
}
:host ::ng-deep .grid-value.grid-regular-price .product-variant-current-price.and-has-previous-price {
  display: none;
}
:host ::ng-deep .grid-value.grid-sale-price .product-variant-previous-price {
  display: none;
}
:host ::ng-deep .product-variant-sale-price .product-variant-current-price {
  color: #de0000;
}
:host ::ng-deep .force-grid-bold-style {
  font-family: realistnarrow, sans-serif;
  font-weight: 700;
}
:host ::ng-deep .force-grid-italic-style {
  font-family: realistnarrow, sans-serif;
  font-style: italic;
  font-weight: 700;
}
:host ::ng-deep .force-italic-style * {
  font-family: poppins, sans-serif;
  font-style: italic;
  font-weight: 700;
}
/*# sourceMappingURL=plain-jane-business-card-stack-content.component.css.map */
