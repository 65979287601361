import { PrintCardMenu } from './print-card-menu';

export class GanjikaBeveragePrintCardMenu extends PrintCardMenu {

  getFontFaceList(): string[] {
    return ['NeueHaasGroteskDisplay'];
  }

  shouldStandardizeDominantStrainType(): boolean {
    return false;
  }

}
