import { Deserializable } from '../../protocols/deserializable';
import { CardType } from '../../../modules/display/components/menus/marketing-menu/building-blocks/menu-cards/card-type.enum';
import { PrintCardSize } from '../../enum/dto/print-card-size.enum';

export const DefaultCardCount = '4';
export const DefaultCardStartAt = '1';

export class MenuMetadata implements Deserializable {

  public backgroundOpacity: string = '';
  public cardCount: string;
  public cardType: CardType;
  public printCardSize: PrintCardSize;
  public price: string;
  public cardStartAt: string = DefaultCardStartAt;
  public carouselDuration: string;
  public hideCardNumber: string;
  // Section Overflow
  static defaultSectionOverflowDuration: string = '20';
  public sectionOverflowDuration: string = MenuMetadata.defaultSectionOverflowDuration;

  static empty(): MenuMetadata {
    const empty = new MenuMetadata();
    Object.keys(empty)?.forEach(key => empty[key] = undefined);
    return empty;
  }

  onDeserialize() {
    this.backgroundOpacity = this.backgroundOpacity || '1';
    this.cardStartAt = this.cardStartAt || DefaultCardStartAt;
    this.sectionOverflowDuration = this.sectionOverflowDuration || MenuMetadata.defaultSectionOverflowDuration;
  }

}
