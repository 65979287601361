import { Section } from './section';
import { SectionType } from '../../enum/dto/section-type.enum';

export class PageBreakSection extends Section {

  public heightInPx: number;

  constructor(pageIndex?: number, heightInPx?: number) {
    super();
    this.pageIndex = pageIndex;
    this.heightInPx = heightInPx;
    this.sectionType = SectionType.PageBreak;
  }

}
