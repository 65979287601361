// @dynamic
export class ArrayUtils {

  // laces two lists together - ie, interlace([0,2,4,6,8,10], [1,3,5,7,9]) = [0,1,2,3,4,5,6,7,8,9]
  static interlace = (zs, ys) => {
    if (zs.length === 0) return ys;              // base case, are there any Z's to lace? no? then return Y's
    const [x, ...xs] = zs;                       // break Z's into first item and everything else
    return [x, ...ArrayUtils.interlace(ys, xs)]; // interlace arrays
  };

  /**
   * Takes an array of items and a predicate function and returns a two dimensional array, where the first array
   * contains all items that match the predicate and the second array contains all items that do not.
   *
   * @returns [[matchingItems], [nonMatchingItems]]
   */
  static partition = function<T>(arr: T[], predicate: (value: T) => boolean): [T[], T[]] {
    if (!arr) return [[], []];
    return arr.reduce(
      (result: [T[], T[]], item: T) => {
        if (predicate(item)) {
          result[0].push(item);
        } else {
          result[1].push(item);
        }
        return result;
      },
      [[], []]
    );
  };

}
