<div
  *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.Badge"
  class="product-column-general product-badge"
  [style.visibility]="section?.collapseContent || rowVm?.collapseContent ? 'collapse' : ''"
  [style.text-decoration]="productStylingVm | forcedColumnTextDecoration: colVm : locConfig | async"
  [style.margin-right]="menu | productMenuBasicMethod: 'getSectionBorderMarginSize'">
  <div *ngFor="let b of rowVm | allVariantBadges; trackBy: trackByBadgeUniqueIdentifier" class="badge">
    <img appImage [style.height]="'100%'" [style.width]="'100%'" [asset]="b.image" [reset]="reset" alt="badge" src="" />
  </div>
</div>
