import { Type } from '@angular/core';
import { PaperSize } from '../../../shared/paper-size';
import { Orientation } from '../../../enum/dto/orientation.enum';
import { LabelStack } from './label-stack';
import { LabelData } from '../../../print-labels/label-data';
import { PrintLabelContentComponent } from '../../../../modules/print-cards/components/print-labels/label-content/print-label-content.component';
import { PrintLabelBackgroundComponent } from '../../../../modules/print-cards/components/print-labels/backgrounds/print-label-background.component';

export class EmptyLabelStack extends LabelStack {

  getCardContentComponent(labelData: LabelData): Type<PrintLabelContentComponent> | null {
    return null;
  }

  getCardBackgroundComponent(labelData: LabelData): Type<PrintLabelBackgroundComponent> | null {
    return null;
  }

  getCardWidthInInches(): number {
    return 0;
  }

  getCardHeightInInches(): number {
    return 0;
  }

  nRows(): number {
    return 0;
  }

  nColumns(): number {
    return 0;
  }

  nCardsPerPage(paperSize: PaperSize, isOnPerforatedPaper: boolean): number {
    return 0;
  }

  cardOrientationOnPortraitPage(): Orientation {
    return null;
  }

}
