export enum AssetLibraryType {
  Product = 'Product',
  Package = 'Package',
  PrimaryBrand = 'PrimaryBrand',
  AlternateBrand = 'AlternateBrand',
  Brand = 'Brand',
  Marketing = 'Marketing',
  BitBud = 'BitBud',
  Custom1 = 'Custom1',
  Custom2 = 'Custom2',
  Custom3 = 'Custom3'
}
