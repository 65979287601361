import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { PrintCardContentComponent } from '../../print-card-content.component';
import { FikaAccessoriesIndexCardStackContentViewModel } from './fika-accessories-index-card-stack-content-view-model';

@Component({
  selector: 'app-fika-accessories-index-card-stack-content',
  templateUrl: './fika-accessories-index-card-stack-content.component.html',
  styleUrls: ['./fika-accessories-index-card-stack-content.component.scss'],
  providers: [FikaAccessoriesIndexCardStackContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FikaAccessoriesIndexCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public override viewModel: FikaAccessoriesIndexCardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

}
