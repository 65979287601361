import { Injectable } from '@angular/core';
import { FirebaseApp } from 'firebase/app';
import { Analytics, initializeAnalytics, logEvent } from 'firebase/analytics';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { OrientationError } from '../models/enum/dto/orientation-error.enum';
import { MenuType } from '../models/enum/dto/menu-type.enum';

@Injectable({ providedIn: 'root' })
export class LoggingService {

  public firebaseApp: FirebaseApp;
  public analytics: Analytics;

  setFirebaseAppInstance(i: FirebaseApp): void {
    this.firebaseApp = i;
    this.analytics = initializeAnalytics(this.firebaseApp);
  }

  LogAPIError(errLog: ApiErrorLog): void {
    logEvent(this.analytics, 'API_Error', errLog.logParams());
  }

  logMetrics(eventName: string, params: { [key: string]: any }): void {
    logEvent(this.analytics, eventName, params);
  }

  logVersionAndLocation(version: string, locationId: number) {
    const params = new Map<string, any>();
    params.set('version', version);
    params.set('locationId', locationId);
    this.logMetrics('Version', params);
  }

  logScreenOrientationError(e: OrientationError) {
    if (e === OrientationError.NoError) return;
    const params = new Map<string, any>();
    params.set('error', 'Orientation Error');
    if (e === OrientationError.DisplayInLandscapeModeButScreenIsPortrait) {
      params.set('errorMessage', 'Display object: Landscape, but physical screen: Portrait');
    } else if (e === OrientationError.DisplayInPortraitModeButScreenIsLandscape) {
      params.set('errorMessage', 'Display object: Portrait, but physical screen: Landscape');
    }
    this.logMetrics('Display_App_Screen_Orientation_Error', params);
  }

  logInternetConnectionError() {
    const params = new Map<string, any>();
    params.set('error', 'Internet Connection Error');
    params.set('errorMessage', 'Browser window could not connect to the internet.');
    this.logMetrics('Display_App_Internet_Connection_Error', params);
  }

  loadedMenuType(type: MenuType) {
    const params = new Map<string, any>();
    params.set('menuType', type);
    this.logMetrics('Display_App_Loaded_Menu_Type', params);
  }

  loadedCompanyConfig(id: number) {
    const params = new Map<string, any>();
    params.set('companyId', id);
    this.logMetrics('Display_App_Loaded_Company_Configuration', params);
  }

  loadedLocationConfig(locationId: number) {
    const params = new Map<string, any>();
    params.set('locationId', locationId);
    this.logMetrics('Display_App_Loaded_Location_Configuration', params);
  }

  loadedLabels(locationId: number) {
    const params = new Map<string, any>();
    params.set('locationId', locationId);
    this.logMetrics('Display_App_Loaded_Labels', params);
  }

  refreshedTemplateCollectionData(locationId: number, collectionId: string) {
    const params = new Map<string, any>();
    params.set('locationId', locationId);
    params.set('collectionId', collectionId);
    this.logMetrics('Display_App_Refreshed_Template_Collection_Data', params);
  }

  refreshedDisplayData(dId: string) {
    const params = new Map<string, any>();
    params.set('displayId', dId);
    this.logMetrics('Display_App_Refreshed_Display_Data', params);
  }

  refreshedMenuTemplateData(locationId: number, menuTemplateId: string) {
    const params = new Map<string, any>();
    params.set('locationId', locationId);
    params.set('menuTemplateId', menuTemplateId);
    this.logMetrics('Display_App_Refreshed_Menu_Template_Data', params);
  }

  refreshedMenuData(locationId: number, menuId: string) {
    const params = new Map<string, any>();
    params.set('locationId', locationId);
    params.set('menuId', menuId);
    this.logMetrics('Display_App_Refreshed_Menu_Data', params);
  }

  sessionExpired(dId: string) {
    const now = Date.now();
    const params = new Map<string, any>();
    params.set('displayId', dId);
    params.set('timestamp', now);
    this.logMetrics('Display_App_Session_Expired', params);
  }

}
