import { Pipe, PipeTransform } from '@angular/core';
import { CardStack } from '../../../models/menu/section/card-stacks/card-stack';

@Pipe({
  name: 'getInternalCrossDimension'
})
export class GetInternalCrossDimensionPipe implements PipeTransform {

  transform(cardStack: CardStack, crossDimension: string): string {
    const bleed = !cardStack ? 0 : cardStack.withContentBleed(false, 2);
    return `calc(${crossDimension} + ${bleed}px)`;
  }

}
