import { NgModule } from '@angular/core';
import { ScaleDownTextAndAdjustLinesDirective } from './scale-down-text-and-adjust-lines.directive';

@NgModule({
  declarations: [
    ScaleDownTextAndAdjustLinesDirective
  ],
  exports: [
    ScaleDownTextAndAdjustLinesDirective
  ],
})
export class ScaleDownTextAndAdjustLinesModule {
}
