import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { FikaEdiblesCardData } from '../../../../../../../models/print-cards/fika-edibles-card-data';
import { FikaIndexCardStackContentViewModel } from '../fika-index-card-stack-content/fika-index-card-stack-content-view-model';
import { map } from 'rxjs/operators';

@Injectable()
export class FikaEdiblesIndexCardStackContentViewModel extends FikaIndexCardStackContentViewModel {

  public readonly cardData$: Observable<FikaEdiblesCardData>;
  public override readonly includeSizeInPrice$ = of(false);
  public readonly isEdiblesCard$ = this.cardData$.mapTo(cardData => cardData?.isEdiblesCard());
  public readonly topOfCardVariantType$ = this.cardData$.mapTo(cardData => cardData?.topOfCardVariantType());
  public readonly numberOfFlavours$ = this.cardData$.mapTo(cardData => cardData?.rowVariants?.length);
  public readonly visibleVariantFlavours$ = this.cardData$.mapTo(cardData => cardData?.rowVariants?.take(4));
  public readonly nMoreFlavours$ = this.cardData$.pipe(
    map(cardData => {
      const nMoreFlavours = cardData?.rowVariants?.length - 4;
      return nMoreFlavours > 0 ? nMoreFlavours : 0;
    })
  );

}
