import { PrintCardContentViewModel } from '../../print-card-content-view-model';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { FireAndFlowerCardData } from '../../../../../../../models/print-cards/fire-and-flower/fire-and-flower-card-data';
import { map } from 'rxjs/operators';
import { FireAndFlowerRegularPriceCardData } from '../../../../../../../models/print-cards/fire-and-flower/fire-and-flower-regular-price-card-data';
import { FireAndFlowerMemberPriceCardData } from '../../../../../../../models/print-cards/fire-and-flower/fire-and-flower-member-price-card-data';
import { FireAndFlowerSalePriceCardData } from '../../../../../../../models/print-cards/fire-and-flower/fire-and-flower-sale-price-card-data';
import { SpecificPriceWithoutDiscounts } from '../../../../../../../models/enum/shared/specific-price-without-discounts.enum';

@Injectable()
export class FireAndFlowerCardStackContentViewModel extends PrintCardContentViewModel {

  public readonly cardData$: Observable<FireAndFlowerCardData>;

  public readonly isRegularPriceCard$: Observable<boolean> = this.cardData$.pipe(
    map(cardData => cardData instanceof FireAndFlowerRegularPriceCardData)
  );

  public readonly isMemberPriceCard$: Observable<boolean> = this.cardData$.pipe(
    map(cardData => cardData instanceof FireAndFlowerMemberPriceCardData)
  );

  public readonly isSalePriceCard$: Observable<boolean> = this.cardData$.pipe(
    map(cardData => cardData instanceof FireAndFlowerSalePriceCardData)
  );

  public readonly mainPriceSpecificPriceWithoutDiscounts$ = combineLatest([
    this.isRegularPriceCard$,
    this.isMemberPriceCard$
  ]).pipe(
    map(([isRegularPricingCard, isMemberPriceCard]) => {
      if (isMemberPriceCard) {
        return [
          SpecificPriceWithoutDiscounts.LocationSpecificSecondaryBasePrice,
          SpecificPriceWithoutDiscounts.CompanySpecificSecondaryBasePrice,
        ];
      }
      if (isRegularPricingCard) {
        return [
          SpecificPriceWithoutDiscounts.LocationSpecificBasePrice,
          SpecificPriceWithoutDiscounts.CompanySpecificBasePrice,
          SpecificPriceWithoutDiscounts.BasePrice
        ];
      }
      return null;
    })
  );

  public readonly bottomPriceSpecificPriceWithoutDiscounts$ = of([
    SpecificPriceWithoutDiscounts.LocationSpecificBasePrice,
    SpecificPriceWithoutDiscounts.CompanySpecificBasePrice,
    SpecificPriceWithoutDiscounts.BasePrice
  ]);

  public readonly type$: Observable<'regular' | 'member' | 'sale'> = combineLatest([
    this.isRegularPriceCard$,
    this.isMemberPriceCard$,
    this.isSalePriceCard$
  ]).pipe(
    map(([isRegularPriceCard, isMemberPriceCard, isSalePriceCard]) => {
      if (isRegularPriceCard) return 'regular';
      if (isMemberPriceCard) return 'member';
      if (isSalePriceCard) return 'sale';
    })
  );

  public readonly priceBannerBackgroundColor$ = this.type$.pipe(
    map(type => {
      switch (type) {
        case 'regular': return '#F6F6F6';
        case 'member':  return '#F0F0F0';
        case 'sale':    return '#E8E8E8';
      }
    })
  );

  public readonly priceBannerBorderColor$ = this.type$.pipe(
    map(type => {
      switch (type) {
        case 'regular': return '#E1E1E1';
        case 'member':  return '#CCC';
        case 'sale':    return '#A2A2A2';
      }
    })
  );

  public readonly showPill$ = this.type$.pipe(
    map(type => type !== 'regular')
  );

  public readonly pillText$ = this.type$.pipe(
    map(type => {
      switch (type) {
        case 'regular': return '';
        case 'member':  return 'Member Price';
        case 'sale':    return 'Sale Price';
      }
    })
  );

  public readonly pillTextColor$ = this.type$.pipe(
    map(type => {
      switch (type) {
        case 'regular': return '';
        case 'member':  return 'black';
        case 'sale':    return 'white';
      }
    })
  );

  public readonly pillWhiteLogo$ = this.type$.pipe(
    map(type => type === 'sale'),
  );

  public readonly showRegularPriceAtBottom$ = this.cardData$.pipe(
    map(cardData => cardData?.showRegularPriceAtBottom)
  );

  public addSpaceAfterDollarSign = (price: string): string => price?.replace('$', '$ ');

}
