import { Type } from '@angular/core';
import type { SectionsCanOverflow } from '../../../protocols/sections-can-overflow';
import { Themeable } from '../../../protocols/themeable';
import { FeaturedCategoryHeaderComponent } from '../../../../modules/display/components/menus/marketing-menu/building-blocks/menu-header/featured-category-header/featured-category-header.component';
import { ProductMenu } from '../../product-menu';
import type { ProductMenuHeaderComponent } from '../../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import type { SectionRowViewModel } from '../../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { ColWidth } from '../../../shared/col-width';
import { ColumnWidth } from '../../../enum/shared/column-width.enum';
import { SectionColumnProductInfoType, SectionColumnViewModel } from '../../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import type { Section } from '../../section/section';
import type { Product } from '../../../product/dto/product';
import type { Variant } from '../../../product/dto/variant';
import { ProductType } from '../../../enum/dto/product-type.enum';
import { Position } from '../../../enum/shared/position.enum';
import type { SectionWithProducts } from '../../section/section-with-products';
import { VariantTypeUtils } from '../../../../utils/variant-type-utils';

export class MarketingFeaturedCategoryMenu extends ProductMenu implements Themeable, SectionsCanOverflow {

  getThemeClass(): string {
    return 'marketing-featured-category-theme';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return FeaturedCategoryHeaderComponent;
  }

  getShowHeader(): boolean {
    return true;
  }

  getShowFooter(): boolean {
    return false;
  }

  getFontFaceList(): string[] {
    return ['poppins', 'source-sans-pro'];
  }

  getSectionsBackgroundColor(): string {
    return '';
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getShowProductTertiaryTitle(): boolean {
    return true;
  }

  primaryPriceColumnAlsoShowOriginalPriceIfOnSale(section: SectionWithProducts): boolean {
    return true;
  }

  primaryPriceColumnAlsoShowOriginalPriceIfOnSalePosition(): Position {
    return Position.Bottom;
  }

  nCards(): number {
    return parseInt(this.metadata?.cardCount || '2', 10);
  }

  getColWidths(sectionRowViewModel: SectionRowViewModel, stdPercentage: number = ColumnWidth.Default): ColWidth[] {
    const colWidths = super.getColWidths(sectionRowViewModel, stdPercentage);
    const badgeWidth = this.nCards() === 2 ? 7 : 11;
    const badge = new ColWidth(SectionColumnProductInfoType.Badge, badgeWidth);
    const price = new ColWidth(SectionColumnProductInfoType.VariantPrice, -1);
    colWidths.splice(colWidths.findIndex(it => it.type === SectionColumnProductInfoType.Badge), 1, badge);
    colWidths.splice(colWidths.findIndex(it => it.type === SectionColumnProductInfoType.VariantPrice), 1, price);
    return colWidths;
  }

  override getThemeSpecifiedColumnViewModels(
    sectionRowViewModels: SectionRowViewModel[],
    rowViewModel: SectionRowViewModel,
    widths: ColWidth[] = this.getColWidths(rowViewModel)
  ): SectionColumnViewModel[] {
    return this.getThemeStandardizedColumnViewModels(sectionRowViewModels, rowViewModel, widths);
  }

  protected getThemeStandardizedColumnViewModels(
    sectionRowViewModels: SectionRowViewModel[],
    rowViewModel: SectionRowViewModel,
    widths: ColWidth[]
  ): SectionColumnViewModel[] {
    const colVMs = [];
    const layoutType = rowViewModel?.getLayoutType();
    const productTitleCol = new SectionColumnViewModel(layoutType, null);
    productTitleCol.columnType = SectionColumnProductInfoType.ProductTitle;
    const badgeCol = new SectionColumnViewModel(layoutType, null);
    badgeCol.columnType = SectionColumnProductInfoType.Badge;
    badgeCol.widthPercentage = widths.find(it => it.type === SectionColumnProductInfoType.Badge).widthPercentage;
    const spacerCol = new SectionColumnViewModel(layoutType, null);
    spacerCol.columnType = SectionColumnProductInfoType.Spacer;
    spacerCol.widthPercentage = widths.find(it => it.type === SectionColumnProductInfoType.Spacer).widthPercentage;
    const tinySpaceCol = new SectionColumnViewModel(layoutType, null);
    tinySpaceCol.columnType = SectionColumnProductInfoType.TinySpacer;
    tinySpaceCol.widthPercentage = widths
      .find(it => it.type === SectionColumnProductInfoType.TinySpacer)
      .widthPercentage;
    const priceCol = new SectionColumnViewModel(layoutType, null);
    priceCol.columnType = SectionColumnProductInfoType.VariantPrice;
    priceCol.widthPercentage = widths.find(it => it.type === SectionColumnProductInfoType.VariantPrice).widthPercentage;
    // Check for badges
    if (rowViewModel.section.hasBadges()) {
      colVMs.push(badgeCol);
      if (this.nCards() === 2) colVMs.push(tinySpaceCol);
      else colVMs.push(spacerCol);
    }
    // Build Columns
    colVMs.push(productTitleCol);
    colVMs.push(tinySpaceCol);
    colVMs.push(priceCol);
    return colVMs;
  }

  getProductSubtitle(
    section: Section,
    product: Product,
    rowVm: SectionRowViewModel
  ): string {
    // Brand 20g (x1)
    const brandString = rowVm.rowVariants.firstOrNull()?.brand || '--';
    const sizeString = this.getSizeString(rowVm.rowVariants.firstOrNull());
    const spaceBetween = '   ';
    return `${brandString}${spaceBetween}${sizeString}`;
  }

  getProductTertiaryTitle(
    section: Section,
    product: Product,
    rowVm: SectionRowViewModel
  ): string {
    // THC 10mg CBD <1mg
    const spaceBetween = '   ';
    return `THC ${rowVm.getCannabinoid('THC')}${spaceBetween}CBD ${rowVm.getCannabinoid('CBD')}`;
  }

  getSizeString(variant: Variant): string {
    if (!!variant) {
      // Ignore unitSize for accessories, edibles, capsules
      const accessoryProduct = variant.productType === ProductType.Accessories;
      const edibleProduct = variant.productType === ProductType.Edible;
      const capsuleVariant = VariantTypeUtils.isCapsuleType(variant?.variantType);
      const ignoreUnitSize = accessoryProduct || edibleProduct || capsuleVariant;
      if (variant.packagedQuantity > 1) {
        const withoutUnits = `(x${variant.packagedQuantity})`;
        const withUnits = `${variant.getFormattedUnitSize()} (x${variant.packagedQuantity})`;
        return ignoreUnitSize ? withoutUnits : withUnits;
      } else {
        return ignoreUnitSize ? null : variant.getFormattedUnitSize();
      }
    } else {
      return null;
    }
  }

  /*  *********************** Overflow interface overrides *********************** */

  getShouldSectionsContainerFlexWrap(): boolean {
    return false;
  }

  getMenuScrollClass(): string {
    if (this.isLandscape()) {
      return 'horizontal-scroll';
    } else {
      return 'vertical-scroll';
    }
  }

  /*  ****************************************************************** */

  shouldFetchVariantAssets(): boolean {
    return true;
  }

}
