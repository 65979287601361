import { Deserializable } from './deserializable';
import { Type } from '@angular/core';
import { Display } from '../display/dto/display';

export class DeserializeDisplayHelper {

  static getPolymorphicDisplayObject<T extends Deserializable>(display: any): T {
    const createPolymorphicObject = (ObjectType: Type<any>): T => {
      return Object.assign(Object.create(ObjectType.prototype), display);
    };
    return createPolymorphicObject(Display);
  }

}

