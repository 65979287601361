import { Deserializable } from '../../protocols/deserializable';
import { AssetUrl } from './asset-url';
import { MediaType } from '../../enum/dto/media-type.enum';
import { AssetSize } from '../../enum/dto/asset-size.enum';
import { Cachable } from '../../protocols/cachable';
import { DateUtils } from '../../../utils/date-utils';
import { MediaUtils } from '../../../utils/media-utils';
import { CachePolicy } from '../../enum/shared/cachable-image-policy.enum';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';
import { combineLatest, merge, Observable } from 'rxjs';
import { SafeResourceUrl } from '@angular/platform-browser';
import { map, startWith } from 'rxjs/operators';

export class Asset implements Deserializable, Cachable, UniquelyIdentifiable {

  public id: string;
  public md5Hash: string;
  public urls: AssetUrl[];
  public timestamp: number;
  public fileName: string;
  public mediaType: MediaType;
  public cachedTime: number;
  // Not From API
  // always emits url to largest fetched asset
  public sizePriorityUrl$: Observable<string | SafeResourceUrl>;
  // Empty void if the url fails to download
  public priorityUrlFailed$: Observable<void>;

  static buildCacheKey(id: string, hash: string): string {
    return `Image-${id}-${hash}`;
  }

  public isEmpty() {
    return this.id === '' || this.md5Hash === '';
  }

  public onDeserialize() {
    const filteredUrls = this.urls?.filter(u => {
      // No other size exist for videos so filer them out
      return MediaUtils.isVideo(this.mediaType) ? u.size === AssetSize.Original : true;
    });
    this.deserializeUrls(filteredUrls);
    // Set data in child objects from parent object
    this.urls?.forEach(url => {
      url.name = this.fileName;
      url.assetId = this.id;
      url.md5Hash = this.md5Hash;
      url.mediaType = this.mediaType;
    });
    this.priorityUrlFailed$ = merge(...this.urls.map(url => url.failedToDownload) || []);
    // Create size priority url observable that always emits url to largest fetched asset
    this.sizePriorityUrl$ = combineLatest(
      this.urls?.map(url => url.srcUrl.pipe(startWith([url.size, ''] as [AssetSize, Asset | SafeResourceUrl])))
    ).pipe(
      map((listData) => {
        const [, ogUrl] = listData?.find(([size, _]) => size === AssetSize.Original) || [undefined, ''];
        const [, largeUrl] = listData?.find(([size, _]) => size === AssetSize.Large) || [undefined, ''];
        const [, mediumUrl] = listData?.find(([size, _]) => size === AssetSize.Medium) || [undefined, ''];
        const [, smallUrl] = listData?.find(([size, _]) => size === AssetSize.Small) || [undefined, ''];
        const [, thumbUrl] = listData?.find(([size, _]) => size === AssetSize.Thumb) || [undefined, ''];
        if (!!ogUrl && !this.isPDF(AssetSize.Original)) return ogUrl;
        if (largeUrl) return largeUrl;
        if (mediumUrl) return mediumUrl;
        if (smallUrl) return smallUrl;
        if (thumbUrl) return thumbUrl;
        return '';
      })
    );
  }

  protected deserializeUrls(filteredUrls: AssetUrl[]): void {
    this.urls = window?.injector?.Deserialize?.arrayOf(AssetUrl, filteredUrls);
  }

  getAsset(cachePolicy: CachePolicy, size: AssetSize, cacheForNSeconds: number) {
    this.getAssetUrl(size)?.loadAssetIntoSrcUrlSubject(cachePolicy, cacheForNSeconds);
  }

  public isValid(): boolean {
    // Use this to handle cases where assets are deleted, leaving a broken Image object behind (ie: {urls: null})
    return !!this && !!this.id && !!this.md5Hash;
  }

  public isImage(): boolean {
    return this.mediaType.match(/image\/*/)?.length > 0;
  }

  public isVideo(): boolean {
    return this.mediaType.match(/video\/*/)?.length > 0;
  }

  public isPDFImage(size: AssetSize): boolean {
    return this.mediaType.match(/pdf\/*/)?.length > 0 && size !== AssetSize.Original;
  }

  public isPDF(size: AssetSize): boolean {
    return this.mediaType.match(/pdf\/*/)?.length > 0 && size === AssetSize.Original;
  }

  public getAssetUrl(size: AssetSize): AssetUrl | null | undefined {
    return this.urls?.find(u => u.size === size);
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneHour();
  }

  cacheKey(): string {
    return Asset.buildCacheKey(this.id, this.md5Hash);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.nowInUnixSeconds() > expiresAt;
  }

  getUniqueIdentifier(): string {
    return `${this.id}-${this.md5Hash}`;
  }

  setUrlsFromLocalFile(url: string, mediaType: MediaType): void {
    this.id = url;
    this.md5Hash = url;
    this.fileName = url;
    this.mediaType = mediaType;
    const small = new AssetUrl(AssetSize.Small, url);
    const medium = new AssetUrl(AssetSize.Medium, url);
    const large = new AssetUrl(AssetSize.Large, url);
    const thumb = new AssetUrl(AssetSize.Thumb, url);
    const original = new AssetUrl(AssetSize.Original, url);
    this.urls = [original, small, medium, large, thumb];
    this.cachedTime = DateUtils.unixOneMonth();
    this.onDeserialize();
  }

}
