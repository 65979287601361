<div class="component-container" [style.justify-content]="cardData?.section?.metadata?.imageAlignment">
  <img
    *ngIf="cardData | sectionRowViewModelBasicMethod: 'getBackgroundAsset' | assetIsImage as asset"
    appImage
    class="image-with-color-overlay-and-opacity"
    [asset]="asset"
    [style.object-fit]="cardData?.section?.metadata?.objectFit"
    [style.width.%]="cardData?.section?.metadata?.width"
    (load)="imageRenderSucceeded()"
    (error)="imageRenderFailed()"
    src=""
    alt="" />
  <!-- color overlay -->
  <div
    class="image-with-color-overlay-and-opacity"
    [class]="cardData?.menu | productMenuBasicMethod: 'getBackgroundAssetWrapperClass'"
    [style.background-color]="cardData | sectionRowViewModelBasicMethod: 'getCardColor'"
    [style.opacity]="cardData | sectionRowViewModelBasicMethod: 'getBackgroundOpacity'"
    [style.border-radius]="cardData?.menu | productMenuBasicMethod: 'getColorBackgroundAssetBorderRadius'"></div>
</div>
