import { ProductSection } from './product-section';
import type { Type } from '@angular/core';
import type { MenuItemComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-item/menu-item.component';
import { ProductSectionItemPlantlifeComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-item/product-section-item-plantlife/product-section-item-plantlife.component';

export class PlantlifeProductSection extends ProductSection {

  override getMenuItemType(): Type<MenuItemComponent> {
    return ProductSectionItemPlantlifeComponent;
  }

}
