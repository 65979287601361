import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/menu';
import { exists } from '../../../functions/exists';

@Pipe({
  name: 'shouldBlurBackground'
})
export class ShouldBlurBackgroundPipe implements PipeTransform {

  transform(menu: Menu): boolean {
    return exists(menu?.hydratedTheme?.themeFeatures?.blurredBackground)
        && exists(menu?.menuOptions?.blurredBackground);
  }

}
