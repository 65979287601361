@charset "UTF-8";

/* src/app/modules/print-cards/components/print-cards/card-content/post-cards-4x6/true-north-post-card-stack-content/true-north-portrait-card-stack-content/true-north-portrait-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  padding: 1rem;
  box-sizing: border-box;
}
:host .card-header {
  height: 5.7%;
  width: 100%;
  position: relative;
  display: block;
  color: #ffffff;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
}
:host .card-header .strain-container {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 1.1875rem;
  vertical-align: middle;
  letter-spacing: 0.1em;
}
:host .card-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 1.375rem 0 1.375rem 0;
  width: 100%;
  height: 100%;
}
:host .card-body .company-logo {
  max-width: 80%;
  max-height: 5rem;
}
:host .card-body .main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  padding: 0 0.75rem;
}
:host .card-body .main-content .brand-logo {
  max-width: 80%;
  max-height: 7rem;
}
:host .card-body .main-content .product-card-name {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 1.9375rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  box-sizing: border-box;
  width: 100%;
}
:host .card-body .main-content .badge-and-label-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
}
:host .card-body .main-content .badge-and-label-container .badge {
  height: 2.25rem;
}
:host .card-body .main-content .badge-and-label-container .print-card-label {
  align-self: center;
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .card-body .main-content .badge-and-label-container .print-card-label ::ng-deep .product-label {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  width: fit-content;
  display: block;
  max-width: 18rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
:host .card-body .main-content .badge-and-label-container .print-card-label ::ng-deep .product-flag-label {
  border-radius: unset;
}
:host .card-body .main-content .badge-and-label-container .print-card-label ::ng-deep .flag-body {
  display: block;
  max-width: 16rem;
  justify-content: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
:host .card-body .main-content .cannabinoids-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 1rem;
  padding: 0.625rem;
  gap: 1rem;
}
:host .card-body .main-content .cannabinoids-container .property-container {
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  align-items: center;
}
:host .card-body .main-content .cannabinoids-container .property-container .title {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 0.9375rem;
  opacity: 0.5;
}
:host .card-body .main-content .cannabinoids-container .property-container .value {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 1.3125rem;
  max-width: 6.5rem;
}
:host .card-body .main-content .product-size-and-type-container {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 1rem;
  opacity: 0.5;
  line-height: 1.125rem;
}
:host .card-body .pricing-container {
  display: flex;
  flex-direction: column;
}
:host .card-body .pricing-container ::ng-deep .product-variant-price {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
:host .card-body .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price {
  font-size: 1.125rem;
}
:host .card-body .pricing-container ::ng-deep .product-variant-price .product-variant-current-price {
  font-size: 2.25rem;
}
:host ::ng-deep .force-italic-style * {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-style: italic;
}
:host ::ng-deep .force-bold-style * {
  font-weight: 900 !important;
}
/*# sourceMappingURL=true-north-portrait-card-stack-content.component.css.map */
