import { Pipe, PipeTransform } from '@angular/core';
import type { SectionRowViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { VariantBadge } from '../../../models/product/dto/variant-badge';

@Pipe({
  name: 'allVariantBadges'
})
export class AllVariantBadgesPipe implements PipeTransform {

  transform(rowVm: SectionRowViewModel): VariantBadge[] | null {
    return rowVm?.getAllVariantBadges() || null;
  }

}
