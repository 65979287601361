import { Component, ViewEncapsulation } from '@angular/core';
import { ProductMenuFooterComponent } from '../product-menu-footer.component';

@Component({
  selector: 'app-classification-footer',
  templateUrl: './classification-footer.component.html',
  styleUrls: ['./classification-footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClassificationFooterComponent extends ProductMenuFooterComponent {
}
