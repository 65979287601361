import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ProductMenuFooterComponent } from '../product-menu-footer.component';
import { ProductMenu } from '../../../../../../../../models/menu/product-menu';

@Component({
  selector: 'app-badge-legend-footer',
  templateUrl: './badge-legend-footer.component.html',
  styleUrls: ['./badge-legend-footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BadgeLegendFooterComponent extends ProductMenuFooterComponent {

  @Input() menu: ProductMenu;

}
