<div class="right-cut-lines" [class.extended]="cardStack | isExtendedCutLine">
  <div
    class="top-sliver"
    [style.height.px]="cardStack | withContentBleed : false"
    [style.width]="cardStack | calculateExtendedCutLineLengthString : (cardStack | withContentBleed : false)"
    [style.right.px]="cardStack | cutLineExtendedLengthInPx : (cardStack | withContentBleed : false)"></div>
  <ng-template ngFor [ngForOf]="cardStack | iterableCardStackRows : nCardsOnPage" let-last="last">
    <div class="spacer" [style.height.px]="cardStack | cardContentHeight"></div>
    <div *ngIf="!last" class="dividing-sliver-container" [style.height.px]="cardStack | withContentBleed : false : 2">
      <div
        class="dividing-sliver"
        [style.width]="cardStack | calculateExtendedCutLineLengthString : (cardStack | withContentBleed : false)"
        [style.right.px]="cardStack | cutLineExtendedLengthInPx : (cardStack | withContentBleed : false)"
        [style.border-bottom]="(cardStack | withContentBleed : false) === 0 ? 'unset' : null"></div>
      <div
        class="dividing-sliver-vertical-top-line"
        [style.height.px]="cardStack | cutLineExtendedLengthInPx"
        [style.top.px]="-(cardStack | cutLineExtendedLengthInPx)"
        [style.left.px]="-(cardStack | withContentBleed : false)"></div>
      <div
        class="dividing-sliver-vertical-middle-line"
        [style.height.px]="cardStack | withContentBleed : false : 2"
        [style.top.px]="0"
        [style.left.px]="-(cardStack | withContentBleed : false)"></div>
      <div
        class="dividing-sliver-vertical-bottom-line"
        [style.height.px]="cardStack | cutLineExtendedLengthInPx"
        [style.top.px]="cardStack | withContentBleed : false : 2"
        [style.left.px]="-(cardStack | withContentBleed : false)"></div>
    </div>
  </ng-template>
  <div
    class="bottom-sliver"
    [style.height.px]="cardStack | withContentBleed : false"
    [style.width]="cardStack | calculateExtendedCutLineLengthString : (cardStack | withContentBleed : false)"
    [style.right.px]="cardStack | cutLineExtendedLengthInPx : (cardStack | withContentBleed : false)"></div>
</div>
