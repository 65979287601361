import { Deserializable } from '../../protocols/deserializable';

export class ThemeFeatures implements Deserializable {

  // Menu Defaults
  public featuredProducts: boolean = false;
  public titleTextColor: boolean = false;
  public bodyTextColor: boolean = false;
  public badgeCount: number = 0;
  public footerTextColor: boolean = false; // Not returned from API, just future proofing ourselves
  // Menu Background
  public backgroundMedia: boolean = false;
  public backgroundOpacity: boolean = false;
  public blurredBackground: boolean = false;
  public bodyBackgroundColor: boolean = false;
  // Product Section
  public sectionColor: boolean = false;
  public sectionImage: boolean = false;
  public sectionProductMaxCount: number = 0;
  public sectionHeaderBackgroundColor: boolean = false;
  public sectionBodyBackgroundColor: boolean = false;
  public sectionHeaderTextColor: boolean = false;
  public sectionBodyTextColor: boolean = false;
  // Title Section
  public titleSectionBackgroundImage: boolean = false;
  public titleSectionSubtitle: boolean = false;

  onDeserialize() {
    this.sectionColor = this.sectionColor ?? false;
    this.sectionImage = this.sectionImage ?? false;
    this.featuredProducts = this.featuredProducts ?? false;
    this.backgroundMedia = this.backgroundMedia ?? false;
    this.backgroundOpacity = this.backgroundOpacity ?? false;
    this.titleTextColor = this.titleTextColor ?? false;
    this.bodyTextColor = this.bodyTextColor ?? false;
    this.bodyBackgroundColor = this.bodyBackgroundColor ?? false;
    this.blurredBackground = this.blurredBackground ?? false;
    this.sectionHeaderBackgroundColor = this.sectionHeaderBackgroundColor ?? false;
    this.sectionBodyBackgroundColor = this.sectionBodyBackgroundColor ?? false;
    this.sectionHeaderTextColor = this.sectionHeaderTextColor ?? false;
    this.sectionBodyTextColor = this.sectionBodyTextColor ?? false;
    this.badgeCount = this.badgeCount ?? 0;
    this.sectionProductMaxCount = this.sectionProductMaxCount ?? 0;
    this.titleSectionBackgroundImage = this.titleSectionBackgroundImage ?? false;
    this.titleSectionSubtitle = this.titleSectionSubtitle ?? false;
  }

}
