// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.staging.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environmentName: 'dev',
  mainServiceUrl: 'https://1atnrjljz3.execute-api.us-west-2.amazonaws.com/dev',
  accountServiceUrl: 'https://os24szc8tc.execute-api.us-west-2.amazonaws.com/dev',
  imageServiceUrl: 'https://i6tg826c0a.execute-api.us-west-2.amazonaws.com/dev',
  productServiceUrl: 'https://abjr2ew223.execute-api.us-west-2.amazonaws.com/dev',
  menuServiceUrl: 'https://qko0xxzck8.execute-api.us-west-2.amazonaws.com/dev',
  templateServiceUrl: 'https://pj2w8xiiue.execute-api.us-west-2.amazonaws.com/dev',
  deviceServiceUrl: 'https://2dv3i6a9u3.execute-api.us-west-2.amazonaws.com/dev',
  firebaseConfig: {
    apiKey: 'AIzaSyDjwZQn64i4YkBO77AP9oEqhPwhOMH5GNI',
    authDomain: 'budsense-display-staging.firebaseapp.com',
    projectId: 'budsense-display-staging',
    storageBucket: 'budsense-display-staging.appspot.com',
    messagingSenderId: '152874063248',
    appId: '1:152874063248:web:745fb6eb6df60747918f15',
    measurementId: 'G-JQL0RL3YEY'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
