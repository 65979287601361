import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { HoldOffMenuRotation } from '../models/shared/hold-off-menu-rotation';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CachedOverflowService } from '../modules/services/cached-overflow.service';
import { exists } from '../functions/exists';

@Injectable({ providedIn: 'root' })
export class DisplayMenuCoupling {

  constructor(
    private cachedOverflowService: CachedOverflowService
  ) {
  }

  public holdDisplayRotation = new BehaviorSubject<HoldOffMenuRotation>(null);
  public holdLoopingMenuRotation = new BehaviorSubject<boolean>(false);
  public currentVisibleMenuId = new BehaviorSubject<string>(null);
  public menuToRotateCount = new BehaviorSubject<number>(null);
  public menuIdsToRotate = new BehaviorSubject<string[]>([]);
  public screenshotMode = new BehaviorSubject<boolean>(false);

  private _hideDownloadToast = new BehaviorSubject<boolean>(false);
  public hideDownloadToast$ = this._hideDownloadToast as Observable<boolean>;
  public connectToHideDownloadToast = (hide: boolean) => this._hideDownloadToast.next(hide);

  private _noSteadyScrollContentMenuRotation = new Subject<void>();
  public noSteadyScrollContentMenuRotation$ = this._noSteadyScrollContentMenuRotation as Observable<void>;
  public connectToNoSteadyScrollContentMenuRotation = () => this._noSteadyScrollContentMenuRotation.next();

  private _steadyScrollFinishedRotateMenu = new Subject<void>();
  public steadyScrollFinishedRotateMenu$ = this._steadyScrollFinishedRotateMenu as Observable<void>;
  public connectToSteadyScrollFinishedRotateMenu = () => this._steadyScrollFinishedRotateMenu.next();

  private readonly _waitForOverflowCalculation = new BehaviorSubject<Map<string, boolean>>(new Map());
  public readonly waitForOverflowCalculation$ = this._waitForOverflowCalculation as Observable<Map<string, boolean>>;
  public connectToWaitForOverflowCalculation = (menuId: string, wait: boolean) => {
    const updated = this._waitForOverflowCalculation.value?.shallowCopy() || new Map();
    updated.set(menuId, wait);
    this._waitForOverflowCalculation.next(updated);
  };
  public waitForThisMenusOverflow$ = (menuId: string): Observable<boolean> => {
    return this.waitForOverflowCalculation$.pipe(
      map(waitMap => waitMap?.get(menuId) || false),
      distinctUntilChanged()
    );
  };

  /**
   * returns [isCurrentVisibleMenu, cacheSize, menuCanCalculateOverflow]
   */
  public menuCanCalculateOverflow = (menuId: string): Observable<[boolean, number, boolean]> => {
    const distinctOutput = (a: [boolean, number, boolean], b: [boolean, number, boolean]) => a[2] === b[2];
    return combineLatest([
      this.currentVisibleMenuId,
      this.menuIdsToRotate,
      this.cachedOverflowService.cachedOverflowedSections$,
    ]).pipe(
      map(([currentVisibleMenuId, ids, cache]) => {
        const lastInList = ids?.last() === menuId;
        const idBeforeMe = lastInList
          ? ids?.firstOrNull()
          : ids?.slice(0, ids?.indexOf(menuId))?.last();
        const isCurrentVisibleMenu = currentVisibleMenuId === menuId;
        const menuBeforeMeIsCached = exists(cache?.get(idBeforeMe));
        const menuCanCalculateOverflow = isCurrentVisibleMenu || menuBeforeMeIsCached;
        return [isCurrentVisibleMenu, cache?.size, menuCanCalculateOverflow] as [boolean, number, boolean];
      }),
      distinctUntilChanged(distinctOutput)
    );
  };

}
