import { Move } from './sort-utils';
import { SectionRowViewModel } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { SectionSortOption, SectionSortProductInfo, SectionSortSecondaryCannabinoids, SectionSortTerpenes } from '../models/enum/dto/section-sort-option.enum';
import type { SectionWithProducts } from '../models/menu/section/section-with-products';
import { SectionLayoutType } from '../models/enum/dto/section-layout-type.enum';
import { SortVariantUtils } from './sort-variant-utils';
import { CollapsedSativaHybridIndicaSectionViewModel } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/sativa-hybrid-indica-split-product-section/collapsed-sativa-hybrid-indica-section-view-model';

// @dynamic
export class SortSectionRowViewModelUtils extends SortVariantUtils {

  /* *******************************************************************************************
   *                        Section Row View Model - Sort Options                              *
   * *******************************************************************************************/

  static sectionRowViewModelBrandAsc(a: SectionRowViewModel, b: SectionRowViewModel): Move {
    const aCompare = a?.product?.getBrand(a?.rowVariants?.map(v => v.id));
    const bCompare = b?.product?.getBrand(b?.rowVariants?.map(v => v.id));
    return SortSectionRowViewModelUtils.nonNullStringAscending(aCompare, bCompare);
  }

  static sectionRowViewModelBrandDesc(a: SectionRowViewModel, b: SectionRowViewModel): Move {
    const aCompare = a?.product?.getBrand(a?.rowVariants?.map(v => v.id));
    const bCompare = b?.product?.getBrand(b?.rowVariants?.map(v => v.id));
    return SortSectionRowViewModelUtils.nonNullStringDescending(aCompare, bCompare);
  }

  static sectionRowViewModelProductClassificationAsc(a: SectionRowViewModel, b: SectionRowViewModel): Move {
    return SortSectionRowViewModelUtils.nonNullStringAscending(a?.product?.classification, b?.product?.classification);
  }

  static sectionRowViewModelProductsClassificationDesc(a: SectionRowViewModel, b: SectionRowViewModel): Move {
    return SortSectionRowViewModelUtils.nonNullStringDescending(a?.product?.classification, b?.product?.classification);
  }

  static sectionRowViewModelVariantClassificationAsc(a: SectionRowViewModel, b: SectionRowViewModel): Move {
    return SortSectionRowViewModelUtils.strainTypeSortAsc(
      a?.rowVariants?.firstOrNull()?.classification,
      b?.rowVariants?.firstOrNull()?.classification
    );
  }

  static sectionRowViewModelVariantClassificationDesc(a: SectionRowViewModel, b: SectionRowViewModel): Move {
    return SortSectionRowViewModelUtils.strainTypeSortDesc(
      a?.rowVariants?.firstOrNull()?.classification,
      b?.rowVariants?.firstOrNull()?.classification
    );
  }

  static sectionRowViewModelManufacturerAsc(a: SectionRowViewModel, b: SectionRowViewModel): Move {
    const aManufacturer = a?.product?.getManufacturer();
    const bManufacturer = b?.product?.getManufacturer();
    return SortSectionRowViewModelUtils.nonNullStringAscending(aManufacturer, bManufacturer);
  }

  static sectionRowViewModelManufacturerDesc(a: SectionRowViewModel, b: SectionRowViewModel): Move {
    const aManufacturer = a?.product?.getManufacturer();
    const bManufacturer = b?.product?.getManufacturer();
    return SortSectionRowViewModelUtils.nonNullStringDescending(aManufacturer, bManufacturer);
  }

  static sectionRowViewModelPackagedQuantityAsc = (a: SectionRowViewModel, b: SectionRowViewModel): Move => {
    return SortSectionRowViewModelUtils.numberAscending(a?.getMinPackageQuantity(), b?.getMinPackageQuantity());
  };
  static sectionRowViewModelPackagedQuantityDesc = (a: SectionRowViewModel, b: SectionRowViewModel): Move => {
    return SortSectionRowViewModelUtils.numberDescending(a?.getMaxPackageQuantity(), b?.getMaxPackageQuantity());
  };

  static sectionRowViewModelPriceAsc(a: SectionRowViewModel, b: SectionRowViewModel): Move {
    return SortSectionRowViewModelUtils.numberAscending(a?.getMinRowPrice(), b?.getMinRowPrice());
  }

  static sectionRowViewModelPriceDesc(a: SectionRowViewModel, b: SectionRowViewModel): Move {
    return SortSectionRowViewModelUtils.numberDescending(a?.getMaxRowPrice(), b?.getMaxRowPrice());
  }

  static sectionRowViewModelProductTypeAsc(a: SectionRowViewModel, b: SectionRowViewModel): Move {
    const aProductType = a?.rowVariants?.firstOrNull()?.productType;
    const bProductType = b?.rowVariants?.firstOrNull()?.productType;
    return SortSectionRowViewModelUtils.nonNullStringAscending(aProductType, bProductType);
  }

  static sectionRowViewModelProductTypeDesc(a: SectionRowViewModel, b: SectionRowViewModel): Move {
    const aProductType = a?.rowVariants?.firstOrNull()?.productType;
    const bProductType = b?.rowVariants?.firstOrNull()?.productType;
    return SortSectionRowViewModelUtils.nonNullStringDescending(aProductType, bProductType);
  }

  static sectionRowViewModelSecondaryPriceAsc(a: SectionRowViewModel, b: SectionRowViewModel): Move {
    return SortSectionRowViewModelUtils.numberAscending(a?.getMinRowSecondaryPrice(), b?.getMinRowSecondaryPrice());
  }

  static sectionRowViewModelSecondaryPriceDesc(a: SectionRowViewModel, b: SectionRowViewModel): Move {
    return SortSectionRowViewModelUtils.numberDescending(a?.getMaxRowSecondaryPrice(), b?.getMaxRowSecondaryPrice());
  }

  static sectionRowViewModelStockAsc(a: SectionRowViewModel, b: SectionRowViewModel): Move {
    return SortSectionRowViewModelUtils.numberAscending(a.getMaxRowStock(), b.getMaxRowStock());
  }

  static sectionRowViewModelStockDesc(a: SectionRowViewModel, b: SectionRowViewModel): Move {
    return SortSectionRowViewModelUtils.numberDescending(a?.getMaxRowStock(), b?.getMaxRowStock());
  }

  static sectionRowViewModelTitleAsc(a: SectionRowViewModel, b: SectionRowViewModel): Move {
    return SortSectionRowViewModelUtils.nonNullStringAscending(a?.getRowTitle(), b?.getRowTitle());
  }

  static sectionRowViewModelTitleDesc(a: SectionRowViewModel, b: SectionRowViewModel): Move {
    return SortSectionRowViewModelUtils.nonNullStringDescending(a?.getRowTitle(), b?.getRowTitle());
  }

  static sectionRowViewModelCannabinoidOrTerpeneAsc(
    a: SectionRowViewModel,
    b: SectionRowViewModel,
    cannabinoidOrTerpeneCamelCased: string
  ): Move {
    return SortSectionRowViewModelUtils.numberAscending(
      a?.getMinRowCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased),
      b?.getMinRowCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased)
    );
  }

  static sectionRowViewModelCannabinoidOrTerpeneDesc(
    a: SectionRowViewModel,
    b: SectionRowViewModel,
    cannabinoidOrTerpeneCamelCased: string
  ): Move {
    return SortSectionRowViewModelUtils.numberDescending(
      a?.getMaxRowCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased),
      b?.getMaxRowCannabinoidOrTerpene(cannabinoidOrTerpeneCamelCased)
    );
  }

  static sectionRowViewModelTopTerpeneAsc(a: SectionRowViewModel, b: SectionRowViewModel): Move {
    return SortSectionRowViewModelUtils.nonNullStringAscending(a?.getTopTerpene(), b?.getTopTerpene());
  }
  static sectionRowViewModelTopTerpeneDesc(a: SectionRowViewModel, b: SectionRowViewModel): Move {
    return SortSectionRowViewModelUtils.nonNullStringDescending(a?.getTopTerpene(), b?.getTopTerpene());
  }

  static sectionRowViewModelUnitSizeAsc = (a: SectionRowViewModel, b: SectionRowViewModel): Move => {
    return SortSectionRowViewModelUtils.numberAscending(a?.getMinNumericUnitSize(), b?.getMinNumericUnitSize());
  };
  static sectionRowViewModelUnitSizeDesc = (a: SectionRowViewModel, b: SectionRowViewModel): Move => {
    return SortSectionRowViewModelUtils.numberDescending(a?.getMaxNumericUnitSize(), b?.getMaxNumericUnitSize());
  };

  static sectionRowViewModelVariantTypeAsc = (a: SectionRowViewModel, b: SectionRowViewModel): Move => {
    const aVariantType = a?.rowVariants?.firstOrNull()?.variantType;
    const bVariantType = b?.rowVariants?.firstOrNull()?.variantType;
    return SortSectionRowViewModelUtils.nonNullStringAscending(aVariantType, bVariantType);
  };
  static sectionRowViewModelVariantTypeDesc = (a: SectionRowViewModel, b: SectionRowViewModel): Move => {
    const aVariantType = a?.rowVariants?.firstOrNull()?.variantType;
    const bVariantType = b?.rowVariants?.firstOrNull()?.variantType;
    return SortSectionRowViewModelUtils.nonNullStringDescending(aVariantType, bVariantType);
  };

  static getSectionRowSortLambda(
    sortType: SectionSortOption,
    s: SectionWithProducts
  ): (a: SectionRowViewModel, b: SectionRowViewModel) => Move {
    const productSortType = (): ((a: SectionRowViewModel, b: SectionRowViewModel) => Move) => {
    switch (sortType) {
      case SectionSortProductInfo.BrandAsc:
        return SortSectionRowViewModelUtils.sectionRowViewModelBrandAsc;
      case SectionSortProductInfo.BrandDesc:
        return SortSectionRowViewModelUtils.sectionRowViewModelBrandDesc;
      case SectionSortProductInfo.CBDAsc:
        return (a: SectionRowViewModel, b: SectionRowViewModel) => {
          return SortSectionRowViewModelUtils.sectionRowViewModelCannabinoidOrTerpeneAsc(a, b, 'CBD');
        };
      case SectionSortProductInfo.CBDDesc:
        return (a: SectionRowViewModel, b: SectionRowViewModel) => {
          return SortSectionRowViewModelUtils.sectionRowViewModelCannabinoidOrTerpeneDesc(a, b, 'CBD');
        };
      case SectionSortProductInfo.ClassificationAsc: {
        if (s.layoutType === SectionLayoutType.List) {
          return SortSectionRowViewModelUtils.sectionRowViewModelVariantClassificationAsc;
        }
        return SortSectionRowViewModelUtils.sectionRowViewModelProductClassificationAsc;
      }
      case SectionSortProductInfo.ClassificationDesc: {
        if (s.layoutType === SectionLayoutType.List) {
          return SortSectionRowViewModelUtils.sectionRowViewModelVariantClassificationDesc;
        }
        return SortSectionRowViewModelUtils.sectionRowViewModelProductsClassificationDesc;
      }
      case SectionSortProductInfo.ManufacturerAsc:
        return SortSectionRowViewModelUtils.sectionRowViewModelManufacturerAsc;
      case SectionSortProductInfo.ManufacturerDesc:
        return SortSectionRowViewModelUtils.sectionRowViewModelManufacturerDesc;
      case SectionSortProductInfo.PackagedQuantityAsc:
        return SortSectionRowViewModelUtils.sectionRowViewModelPackagedQuantityAsc;
      case SectionSortProductInfo.PackagedQuantityDesc:
        return SortSectionRowViewModelUtils.sectionRowViewModelPackagedQuantityDesc;
      case SectionSortProductInfo.PriceAsc:
        return SortSectionRowViewModelUtils.sectionRowViewModelPriceAsc;
      case SectionSortProductInfo.PriceDesc:
        return SortSectionRowViewModelUtils.sectionRowViewModelPriceDesc;
      case SectionSortProductInfo.ProductTypeAsc:
        return SortSectionRowViewModelUtils.sectionRowViewModelProductTypeAsc;
      case SectionSortProductInfo.ProductTypeDesc:
        return SortSectionRowViewModelUtils.sectionRowViewModelProductTypeDesc;
      case SectionSortProductInfo.SecondaryPriceAsc:
        return SortSectionRowViewModelUtils.sectionRowViewModelSecondaryPriceAsc;
      case SectionSortProductInfo.SecondaryPriceDesc:
        return SortSectionRowViewModelUtils.sectionRowViewModelSecondaryPriceDesc;
      case SectionSortProductInfo.StockAsc:
        return SortSectionRowViewModelUtils.sectionRowViewModelStockAsc;
      case SectionSortProductInfo.StockDesc:
        return SortSectionRowViewModelUtils.sectionRowViewModelStockDesc;
      case SectionSortProductInfo.TitleAsc:
        return SortSectionRowViewModelUtils.sectionRowViewModelTitleAsc;
      case SectionSortProductInfo.TitleDesc:
        return SortSectionRowViewModelUtils.sectionRowViewModelTitleDesc;
      case SectionSortProductInfo.THCAsc:
        return (a: SectionRowViewModel, b: SectionRowViewModel) => {
          return SortSectionRowViewModelUtils.sectionRowViewModelCannabinoidOrTerpeneAsc(a, b, 'THC');
        };
      case SectionSortProductInfo.THCDesc:
        return (a: SectionRowViewModel, b: SectionRowViewModel) => {
          return SortSectionRowViewModelUtils.sectionRowViewModelCannabinoidOrTerpeneDesc(a, b, 'THC');
        };
      case SectionSortProductInfo.TopTerpeneAsc:
        return SortSectionRowViewModelUtils.sectionRowViewModelTopTerpeneAsc;
      case SectionSortProductInfo.TopTerpeneDesc:
        return SortSectionRowViewModelUtils.sectionRowViewModelTopTerpeneDesc;
      case SectionSortProductInfo.TotalTerpeneAsc:
        return (a: SectionRowViewModel, b: SectionRowViewModel) => {
          return SortSectionRowViewModelUtils.sectionRowViewModelCannabinoidOrTerpeneAsc(a, b, 'totalTerpene');
        };
      case SectionSortProductInfo.TotalTerpeneDesc:
        return (a: SectionRowViewModel, b: SectionRowViewModel) => {
          return SortSectionRowViewModelUtils.sectionRowViewModelCannabinoidOrTerpeneDesc(a, b, 'totalTerpene');
        };
      case SectionSortProductInfo.UnitSizeAsc:
        return SortSectionRowViewModelUtils.sectionRowViewModelUnitSizeAsc;
      case SectionSortProductInfo.UnitSizeDesc:
        return SortSectionRowViewModelUtils.sectionRowViewModelUnitSizeDesc;
      case SectionSortProductInfo.VariantTypeAsc:
        return SortSectionRowViewModelUtils.sectionRowViewModelVariantTypeAsc;
      case SectionSortProductInfo.VariantTypeDesc:
        return SortSectionRowViewModelUtils.sectionRowViewModelVariantTypeDesc;
      default:
        return SortSectionRowViewModelUtils.sectionRowViewModelTitleAsc;
    }
    };
    const getSecondaryCannabinoid = (): (a: SectionRowViewModel, b: SectionRowViewModel) => Move => {
      const { cannabinoid, order } = SortSectionRowViewModelUtils.getCannabinoidAccessor(sortType);
      const isAscending = order === 'ASC';
      const asc = SortSectionRowViewModelUtils.sectionRowViewModelCannabinoidOrTerpeneAsc;
      const desc = SortSectionRowViewModelUtils.sectionRowViewModelCannabinoidOrTerpeneDesc;
      return isAscending
        ? (a: SectionRowViewModel, b: SectionRowViewModel): Move => asc(a, b, cannabinoid)
        : (a: SectionRowViewModel, b: SectionRowViewModel): Move => desc(a, b, cannabinoid);
    };
    const getTerpene = (): (a: SectionRowViewModel, b: SectionRowViewModel) => Move => {
      const { terpeneCamelCased, order } = SortSectionRowViewModelUtils.getTerpeneAccessor(sortType);
      const isAscending = order === 'ASC';
      const asc = SortSectionRowViewModelUtils.sectionRowViewModelCannabinoidOrTerpeneAsc;
      const desc = SortSectionRowViewModelUtils.sectionRowViewModelCannabinoidOrTerpeneDesc;
      return isAscending
        ? (a: SectionRowViewModel, b: SectionRowViewModel) => asc(a, b, terpeneCamelCased)
        : (a: SectionRowViewModel, b: SectionRowViewModel) => desc(a, b, terpeneCamelCased);
    };
    switch (true) {
      case Object.values(SectionSortProductInfo).includes(sortType as SectionSortProductInfo):
        return productSortType();
      case Object.values(SectionSortSecondaryCannabinoids).includes(sortType as SectionSortSecondaryCannabinoids):
        return getSecondaryCannabinoid();
      case Object.values(SectionSortTerpenes).includes(sortType as SectionSortTerpenes):
        return getTerpene();
    }

  }

  static sortSectionRowViewModelsInSection(
    section: SectionWithProducts
  ): (a: SectionRowViewModel, b: SectionRowViewModel) => Move {
    const primary = section?.sorting;
    const secondary = section?.secondarySorting;
    const tertiary = SortSectionRowViewModelUtils.DEFAULT_SECTION_TERTIARY_SORT;
    const secondarySortId = SortSectionRowViewModelUtils.sharedSortId(secondary);
    const tertiarySortId = SortSectionRowViewModelUtils.sharedSortId(tertiary);
    const sortByTertiary = secondarySortId !== tertiarySortId;
    const sorter = SortSectionRowViewModelUtils.getSectionRowSortLambda;
    return (a: SectionRowViewModel, b: SectionRowViewModel): Move => {
      // short-circuit calculation
      return sorter(primary, section)(a, b)
          || (!!secondary ? sorter(secondary, section)(a, b) : Move.Nothing)
          || (sortByTertiary ? sorter(tertiary, section)(a, b) : Move.Nothing);
    };
  }

  static plantlifeSectionRowSortCompare(
    s: SectionWithProducts,
    a: SectionRowViewModel,
    b: SectionRowViewModel
  ): Move {
    const invisibleToMiddle = (c: SectionRowViewModel, d: SectionRowViewModel) => {
      const cIsInvisible = c instanceof CollapsedSativaHybridIndicaSectionViewModel;
      const dIsInvisible = d instanceof CollapsedSativaHybridIndicaSectionViewModel;
      if (cIsInvisible && !dIsInvisible) return Move.ARight;
      else return Move.Nothing;
    };
    const pushVariantsMovedToCenterColumnToEnd = (c: SectionRowViewModel, d: SectionRowViewModel) => {
      const cMovedToCenter = c?.rowVariants?.some(v => v.moveToCenterColumn);
      const dMovedToCenter = d?.rowVariants?.some(v => v.moveToCenterColumn);
      if (cMovedToCenter && !dMovedToCenter) return Move.ARight;
      else if (dMovedToCenter && !cMovedToCenter) return Move.BRight;
      else return Move.Nothing;
    };
    const regularSorting = SortSectionRowViewModelUtils.sortSectionRowViewModelsInSection(s);
    return pushVariantsMovedToCenterColumnToEnd(a, b) || invisibleToMiddle(a, b) || regularSorting(a, b);
  }

}
