import { BehaviorSubject, Observable, Subscription } from 'rxjs';

declare module 'rxjs/internal/BehaviorSubject' {
  interface BehaviorSubject<T> {
    bind(obvs$: Observable<any>): Subscription;
    consumeFlag(consume: (n: any) => void): Subscription;
  }
}

/**
 * bindMe.bind(bindTo)
 */
BehaviorSubject.prototype.bind = function(obvs$: Observable<any>): Subscription {
  return obvs$.subscribe((n) => {
    this.next(n);
  });
};

BehaviorSubject.prototype.consumeFlag = function(consume: (n: any) => void): Subscription {
  return this.subscribe((n) => {
    if (n) {
      consume(n);
      this.next(null);
    }
  });
};
