import { Pipe, PipeTransform } from '@angular/core';
import { CardStack } from '../../../models/menu/section/card-stacks/card-stack';

@Pipe({
  name: 'cutLineExtendedLengthInPx'
})
export class CutLineExtendedLengthInPxPipe implements PipeTransform {

  transform(cardStack: CardStack, addBleed: number = 0): number | null {
    return ((cardStack?.extendCutLineIntoContentAreaByNPx() ?? 0) + addBleed) || null;
  }

}
