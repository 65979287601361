/* src/app/modules/shared/shimmer-loading/shimmer-loading.component.scss */
:host {
  display: block;
  line-height: 1.75;
}
.shimmer {
  display: inline-block;
  width: 100%;
  height: 12px;
  background-color: #f6f7f8;
  background-image:
    linear-gradient(
      to right,
      #f6f7f8 0,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 1000px 1000px;
  animation: shimmerEffect 1s linear infinite forwards;
}
.shimmer.rtl {
  animation: shimmerEffectRTL 1s linear infinite forwards;
}
@keyframes shimmerEffect {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
@keyframes shimmerEffectRTL {
  0% {
    background-position: 1000px 0;
  }
  100% {
    background-position: -1000px 0;
  }
}
/*# sourceMappingURL=shimmer-loading.component.css.map */
