import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ProductMenuFooterComponent } from '../product-menu-footer.component';

@Component({
  selector: 'app-ingram-and-sons-print-footer',
  templateUrl: './ingram-and-sons-print-footer.component.html',
  styleUrls: ['./ingram-and-sons-print-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IngramAndSonsPrintFooterComponent extends ProductMenuFooterComponent {
}
