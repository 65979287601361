import { Component } from '@angular/core';
import { ProductMenuFooterComponent } from '../product-menu-footer.component';
import { VariantBadge } from '../../../../../../../../models/product/dto/variant-badge';

@Component({
  selector: 'app-plant-life-footer',
  templateUrl: './plant-life-footer.component.html',
  styleUrls: ['./plant-life-footer.component.scss']
})
export class PlantLifeFooterComponent extends ProductMenuFooterComponent {

  public filterOutBadgeNamesWith86 = (badge: VariantBadge) => !badge?.name?.includes('86');

}
