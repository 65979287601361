import { AfterViewInit, Component, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { ProductSectionHeaderComponent } from '../product-section-header/product-section-header.component';
import { DomSanitizer } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { StringUtils } from '../../../../../../../../utils/string-utils';
import { HeaderColumnWrapperComponent } from '../../../../../../../shared/components/header-column/header-column-wrapper/header-column-wrapper.component';
import { CompanyDomainModel } from '../../../../../../../../domain/company-domain-model';

@Component({
  selector: 'app-runtz-section-header',
  // uses the product section headers HTML
  templateUrl: '../product-section-header/product-section-header.component.html',
  providers: [{ provide: ProductSectionHeaderComponent, useExisting: RuntzSectionHeaderComponent }]
})
export class RuntzSectionHeaderComponent extends ProductSectionHeaderComponent implements AfterViewInit, OnDestroy {

  constructor(
    companyDomainModel: CompanyDomainModel,
    sanitizer: DomSanitizer
  ) {
    super(companyDomainModel, sanitizer);
  }

  @ViewChildren(HeaderColumnWrapperComponent) sectionColumns: QueryList<HeaderColumnWrapperComponent>;
  private headerContainerStyleMutationObserver: MutationObserver;

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.listenToHeaderContainerStyleMutations();
    this.initializeSectionColumns();
  }

  private deleteHeaderContainerBackgroundColor = () => {
    const headerContainer = this.headerContainer?.nativeElement as HTMLDivElement;
    headerContainer.style.backgroundColor = null;
  };

  private listenToHeaderContainerStyleMutations() {
    const headerContainerMutationConfig = { attributes: true, childList: false, subtree: true };
    const headerContainer = this.headerContainer?.nativeElement as HTMLDivElement;
    if (!!headerContainer) {
      this.headerContainerStyleMutationObserver = new MutationObserver(this.deleteHeaderContainerBackgroundColor);
      this.headerContainerStyleMutationObserver.observe(headerContainer, headerContainerMutationConfig);
    }
  }

  private initializeSectionColumns() {
    const sectionColumns = this.sectionColumns?.toArray()?.map(column => {
      return (column?.elementRef?.nativeElement as HTMLElement)?.firstElementChild as HTMLDivElement;
    });
    this.deleteSectionColumnHeadersForColorBar(sectionColumns);
    this.sectionColumns.changes.pipe(takeUntil(this.onDestroy)).subscribe(changes => {
      const updatedSectionColumns = changes?.toArray()?.map(column => {
        return (column?.elementRef?.nativeElement as HTMLElement)?.firstElementChild as HTMLDivElement;
      });
      this.deleteSectionColumnHeadersForColorBar(updatedSectionColumns as HTMLDivElement[]);
    });
  }

  private deleteSectionColumnHeadersForColorBar(sectionColumns: HTMLDivElement[]) {
    const indexOfNameColumn = sectionColumns?.findIndex(column => column.classList.contains('section-column-title'));
    if (indexOfNameColumn > -1) {
      const columnsBeforeNameColumn = (sectionColumns.slice(0, indexOfNameColumn) || []).reverse();
      const columnsAfterNameColumn = sectionColumns.slice(indexOfNameColumn + 1) || [];
      const removeColumns = (column: HTMLDivElement) => {
        const innerDiv = column?.getElementsByClassName('section-header-general')?.item(0);
        const content = !!StringUtils.removeWhiteSpace(innerDiv?.innerHTML);
        if (!content) column?.remove();
        return !content;
      };
      // every works the same as forEach, but will stop iterating if the callback returns false
      columnsBeforeNameColumn.every(removeColumns);
      columnsAfterNameColumn.every(removeColumns);
      sectionColumns[indexOfNameColumn].insertAdjacentHTML('beforeend', this.getBarDiv());
    }
  }

  private getBarDiv(): string {
    return `
    <div style="flex: 1; display: flex; justify-content: center; align-items: center;">
       <div style="flex: 1; height: 0.5rem; margin-left: 1.625rem; border-radius: 0.5rem;
                   background-color: ${this.section?.metadata?.productsHeaderBackgroundColor}">
       </div>
    </div>
    `;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.headerContainerStyleMutationObserver?.disconnect();
  }

}
