import { Directive, ElementRef, Injectable, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { BaseDirective } from '../../../../../../../models/base/base-directive';

@Injectable({ providedIn: 'root' })
export class LinkSizeWidthViewModel {

  private readonly _linkedSizes = new BehaviorSubject<Map<number, number>>(new Map());
  public readonly linkedSize$ = this._linkedSizes.pipe(
    debounceTime(100),
    map(linkedSizes => Math.max(...Array.from(linkedSizes.values()), 0)),
    distinctUntilChanged(),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  connectToLinkedSizes(id: number, value: number): void {
    this._linkedSizes.once(linkedSizes => {
      const updated = linkedSizes.shallowCopy();
      updated.set(id, value);
      this._linkedSizes.next(updated);
    });
  }

  removeFromLinkedSizes(id: number): void {
    this._linkedSizes.once(linkedSizes => {
      const updated = linkedSizes.shallowCopy();
      updated.delete(id);
      this._linkedSizes.next(updated);
    });
  }

}

@Directive({ selector: '[appLinkWidth]' })
export class LinkWidthDirective extends BaseDirective implements OnChanges {

  constructor(
    private viewModel: LinkSizeWidthViewModel,
    private elementRef: ElementRef<HTMLDivElement>,
    private renderer: Renderer2
  ) {
    super();
  }

  @Input() index: number;

  private readonly _id = new BehaviorSubject<number|null>(null);
  private readonly _width = new BehaviorSubject<number|null>(null);

  ngOnChanges(changes: SimpleChanges) {
    if (changes.index) this._id.next(this.index);
  }

  setupViews() {
    combineLatest([this._id, this._width]).subscribeWhileAlive({
      owner: this,
      next: ([id, linkedWidth]) => {
        if (Number.isFinite(id) && Number.isFinite(linkedWidth)) {
          this.viewModel.connectToLinkedSizes(id, linkedWidth);
        }
      }
    });
  }

  setupBindings() {
    this._width.next(this.elementRef?.nativeElement?.scrollWidth ?? 0);
    this.viewModel.linkedSize$.pipe(filter(size => size > 0)).subscribeWhileAlive({
      owner: this,
      next: linkedSize => this.renderer.setStyle(this.elementRef.nativeElement, 'width', `${linkedSize}px`)
    });
  }

  destroy() {
    super.destroy();
    this._id.once(id => this.viewModel.removeFromLinkedSizes(id));
  }

}
