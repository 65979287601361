import { Pipe, PipeTransform } from '@angular/core';
import { MenuUtils } from '../../../utils/menu-utils';
import type { Menu } from '../../../models/menu/menu';
import type { PrintMenu } from '../../../models/menu/print-menu';

@Pipe({
  name: 'isPrintMenu'
})
export class IsPrintMenuPipe implements PipeTransform {

  transform(value: Menu): PrintMenu | null {
    return MenuUtils.isPrintMenu(value) ? value : null;
  }

}
