import { Pipe, PipeTransform } from '@angular/core';
import { CardStack } from '../../../models/menu/section/card-stacks/card-stack';

@Pipe({
  name: 'customTopPaperMarginInPx'
})
export class CustomTopPaperMarginInPxPipe implements PipeTransform {

  transform(cardStack: CardStack): number | null {
    const topPaperMarginInInches = cardStack?.customTopPaperMarginInInches() ?? null;
    if (Number.isFinite(topPaperMarginInInches)) {
      return topPaperMarginInInches * CardStack.PIXELS_PER_INCH;
    }
    return null;
  }

}
