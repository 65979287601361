import { PrintCardMenu } from './print-card-menu';
import { AssetLibraryType } from '../../enum/dto/asset-library-type.enum';

export class StiiizyPrintCardMenu extends PrintCardMenu {

  getFontFaceList(): string[] {
    return ['Archivo'];
  }

  shouldStandardizeDominantStrainType(): boolean {
    return false;
  }

  /**
   * Brand asset should be displayed if available - no product fallback.
   * Used in conjunction with variantAssetTypeSortOrder within StiiizyCardData and GetVariantAssetForCardPipe.
   */
  override variantAssetTypeSortOrder(): string[] {
    return [
      AssetLibraryType.PrimaryBrand,
      AssetLibraryType.AlternateBrand,
      AssetLibraryType.Brand
    ];
  }

  /**
   * Stiiizy cards ask for 2 assets to be returned instead of 1, because there is an inverse version of the card, where
   * the background is black and the brand asset needs to be white, so in this scenario the alternative brand asset
   * is also needed.
   */
  variantAssetItemCount(): number {
    return 2;
  }

  shouldFetchVariantAssets(): boolean {
    return true;
  }

}
