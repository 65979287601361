<ng-container *ngIf="viewModel.cardStack$ | async as cardStack">
  <div
    *ngFor="let cards of viewModel.cardGroupingsPerPage$ | async"
    class="print-card-paper"
    [style.justify-content]="cardStack | verticalCardStackJustification">
    <div
      *ngIf="cardStack | customTopPaperMarginInPx as spacing"
      [style.height.px]="spacing"
      [style.width.%]="100"></div>
    <app-print-card-top-cut-lines
      *ngIf="cardStack | isOnRegularPaper"
      [cardStack]="cardStack"
      [nCardsOnPage]="cards?.length">
    </app-print-card-top-cut-lines>
    <div class="print-card-middle-content">
      <app-print-card-left-cut-lines
        *ngIf="cardStack | isOnRegularPaper"
        [cards]="cards"
        [cardStack]="cardStack"
        [nCardsOnPage]="cards?.length">
      </app-print-card-left-cut-lines>
      <app-print-card-grid [cards]="cards"></app-print-card-grid>
      <app-print-card-right-cut-lines
        *ngIf="cardStack | isOnRegularPaper"
        [cardStack]="cardStack"
        [nCardsOnPage]="cards?.length">
      </app-print-card-right-cut-lines>
    </div>
    <app-print-card-bottom-cut-lines
      *ngIf="cardStack | isOnRegularPaper"
      [cardStack]="cardStack"
      [nCardsOnPage]="cards?.length">
    </app-print-card-bottom-cut-lines>
  </div>
  <div class="print-card-page-break"></div>
</ng-container>
