<div
  #headerContainer
  class="section-header-container"
  [ngStyle]="{
    'background-color':
      (menu | productMenuBasicMethod: 'getShowSectionHeaderColorAsIcon')
        ? null
        : section?.metadata?.productsHeaderBackgroundColor,
    'background-size': 'cover',
    'background-image': headerBackgroundAsset$ | async,
    zoom: menu?.menuOptions?.productZoom || 1.0,
    'border-bottom-color': menu | productMenuBasicMethod: 'getSectionTitleUnderlineColor' : section
  }"
  [ngClass]="{
    'section-header-bottom-border': menu | productMenuBasicMethod: 'getSectionHeaderUnderlined'
  }">
  <div *ngIf="calculationMode ? true : section?.firstOnPage" class="sativa-hybrid-indica-container">
    <div class="sativa-section-header">
      <ng-container *ngFor="let col of columnVMs">
        <div
          *ngIf="menu | getShouldInflateColumnHeaderForRow: section : col : (sativaSectionRowViewModels$ | async)"
          class="section-column"
          [class.section-column-title]="col.columnType === Types.SectionColumnProductInfoType.ProductTitle"
          [class.section-column-classification]="col.columnType === Types.SectionColumnProductInfoType.StrainType"
          [class.section-column-thc-and-cbd]="col.columnType === Types.SectionColumnCannabinoidType.THCAndCBD"
          [class.section-column-price]="col.columnType === Types.SectionColumnProductInfoType.VariantPrice"
          [style.width.%]="col.widthPercentage"
          [hidden]="menu | getShouldHideHeaderContent: section : col">
          <div
            *ngIf="col.columnType === Types.SectionColumnProductInfoType.StrainType"
            class="section-header-general section-header-classification section-header-sativa-classification">
            {{ Types.StrainType.Sativa | uppercase }}
          </div>
          <div
            *ngIf="col.columnType === Types.SectionColumnCannabinoidType.THCAndCBD"
            class="section-header-general section-header-thc">
            THC|CBD
          </div>
          <div
            *ngIf="col.columnType === Types.SectionColumnProductInfoType.VariantPrice"
            class="section-header-general section-header-variant-price">
            $
          </div>
        </div>
      </ng-container>
    </div>
    <div class="hybrid-section-header">
      <ng-container *ngFor="let col of columnVMs">
        <div
          *ngIf="menu | getShouldInflateColumnHeaderForRow: section : col : (hybridSectionRowViewModels$ | async)"
          class="section-column"
          [class.section-column-title]="col.columnType === Types.SectionColumnProductInfoType.ProductTitle"
          [class.section-column-classification]="col.columnType === Types.SectionColumnProductInfoType.StrainType"
          [class.section-column-thc-and-cbd]="col.columnType === Types.SectionColumnCannabinoidType.THCAndCBD"
          [class.section-column-price]="col.columnType === Types.SectionColumnProductInfoType.VariantPrice"
          [style.width.%]="col.widthPercentage"
          [hidden]="menu | getShouldHideHeaderContent: section : col">
          <div
            *ngIf="col.columnType === Types.SectionColumnProductInfoType.StrainType"
            class="section-header-general section-header-classification section-header-sativa-classification">
            {{ Types.StrainType.Hybrid | uppercase }}
          </div>
          <div
            *ngIf="col.columnType === Types.SectionColumnCannabinoidType.THCAndCBD"
            class="section-header-general section-header-thc">
            THC|CBD
          </div>
          <div
            *ngIf="col.columnType === Types.SectionColumnProductInfoType.VariantPrice"
            class="section-header-general section-header-variant-price">
            $
          </div>
        </div>
      </ng-container>
    </div>
    <div class="indica-section-header">
      <ng-container *ngFor="let col of columnVMs">
        <div
          *ngIf="menu | getShouldInflateColumnHeaderForRow: section : col : (indicaSectionRowViewModels$ | async)"
          class="section-column"
          [class.section-column-title]="col.columnType === Types.SectionColumnProductInfoType.ProductTitle"
          [class.section-column-classification]="col.columnType === Types.SectionColumnProductInfoType.StrainType"
          [class.section-column-thc-and-cbd]="col.columnType === Types.SectionColumnCannabinoidType.THCAndCBD"
          [class.section-column-price]="col.columnType === Types.SectionColumnProductInfoType.VariantPrice"
          [style.width.%]="col.widthPercentage"
          [hidden]="menu | getShouldHideHeaderContent: section : col">
          <div
            *ngIf="col.columnType === Types.SectionColumnProductInfoType.StrainType"
            class="section-header-general section-header-classification section-header-sativa-classification">
            {{ Types.StrainType.Indica | uppercase }}
          </div>
          <div
            *ngIf="col.columnType === Types.SectionColumnCannabinoidType.THCAndCBD"
            class="section-header-general section-header-thc">
            THC|CBD
          </div>
          <div
            *ngIf="col.columnType === Types.SectionColumnProductInfoType.VariantPrice"
            class="section-header-general section-header-variant-price">
            $
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div
    #collapsedHeight
    [style.color]="menu | productMenuBasicMethod: 'getSectionBodyTextColor' : section"
    class="section-header-title">
    {{ section?.title }}
  </div>
</div>
