import { Component, forwardRef, OnChanges } from '@angular/core';
import { ProductSectionHeaderComponent } from '../product-section-header/product-section-header.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CompanyDomainModel } from '../../../../../../../../domain/company-domain-model';

@Component({
  selector: 'app-space-monkey-print-header',
  templateUrl: './space-monkey-print-header.component.html',
  providers: [
    { provide: ProductSectionHeaderComponent, useExisting: forwardRef(() => SpaceMonkeyPrintHeaderComponent) }
  ]
})
export class SpaceMonkeyPrintHeaderComponent extends ProductSectionHeaderComponent implements OnChanges {

  constructor(
    companyDomainModel: CompanyDomainModel,
    sanitizer: DomSanitizer
  ) {
    super(companyDomainModel, sanitizer);
  }

}
