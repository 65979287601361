import { PrintCardContentViewModel } from '../../print-card-content-view-model';
import { Observable } from 'rxjs';
import { JaneDoeCardData } from '../../../../../../../models/print-cards/jane-doe-card-data';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AssetUtils } from '../../../../../../../utils/asset-utils';

@Injectable()
export class JaneDoePostCardStackContentViewModel extends PrintCardContentViewModel {

  public readonly cardData$: Observable<JaneDoeCardData>;
  public readonly companyLogo$ = this.menu$.pipe(map(menu => menu?.companyLogo));
  public readonly showCompanyLogo$ = this.menu$.pipe(map(menu => menu?.showCompanyLogo()));
  public readonly bottomText$ = this.cardData$.pipe(map(cardData => cardData?.getBottomText()));
  public readonly cardAssetUrl$ = this.cardData$.pipe(map(cardData => AssetUtils.janeDoeProductTypeUrls(cardData)));
  public readonly quantityAndSizeText$ = this.cardData$.pipe(map(cardData => cardData?.getQuantityAndSizeString()));
  public readonly cardBorderColor$ = this.cardData$.pipe(map(cardData => cardData?.getCardBorderColor()));

}
