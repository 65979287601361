@charset "UTF-8";

/* src/app/modules/print-cards/components/print-cards/card-content/index-cards-3x5/nycd-index-card-stack-content/nycd-index-price/nycd-index-price.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.price-container {
  display: flex;
  flex-direction: row;
}
.price-container .price-label {
  font-family: inter, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 0.625rem;
  padding: 0.1875rem;
  border-radius: 0.125rem 0 0 0.125rem;
  border-width: 0 0.21875rem 0 0;
  border-color: black;
  border-style: solid;
  text-align: right;
  margin-right: 0.125rem;
  width: 1.625rem;
}
.price-container ::ng-deep .product-variant-price {
  font-family: inter, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.125rem;
}
.price-container ::ng-deep .product-variant-price .product-variant-current-price,
.price-container ::ng-deep .product-variant-price .product-variant-previous-price {
  display: flex;
  flex-direction: row;
}
.price-container ::ng-deep .product-variant-price .product-variant-previous-price {
  font-family: inter, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.3125rem;
  text-decoration: unset;
  color: rgba(0, 0, 0, 0.5019607843);
}
.price-container ::ng-deep .product-variant-price .product-variant-previous-price .product-variant-price-decoration {
  font-size: 1.3125rem;
}
.price-container ::ng-deep .product-variant-price .product-variant-current-price {
  font-size: 1.75rem;
}
.price-container ::ng-deep .product-variant-price .product-variant-price-decoration {
  font-size: 1.75rem;
}
/*# sourceMappingURL=nycd-index-price.component.css.map */
