<ng-container *ngIf="viewModel.printCardMenu$ | async as printCardMenu">
  <ng-container *ngIf="viewModel.cardStack$ | async as cardStack">
    <div appPrintCardGridManager class="print-card-grid" [cardStack]="cardStack" [nCardsOnPage]="cards?.length">
      <app-print-card-jig *ngFor="let cardData of cards" [cardData]="cardData"> </app-print-card-jig>
    </div>
    <app-print-card-internal-grid-cut-lines
      *ngIf="cardStack | isOnRegularPaper"
      [cardStack]="cardStack"
      [nCardsOnPage]="cards?.length">
    </app-print-card-internal-grid-cut-lines>
  </ng-container>
</ng-container>
