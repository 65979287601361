import { SectionType } from '../../enum/dto/section-type.enum';
import { Section } from './section';

export class EmptySection extends Section {

  public heightInPx: number;

  constructor(pageIndex?: number, heightInPx?: number, firstOnPage?: boolean) {
    super();
    this.pageIndex = pageIndex;
    this.heightInPx = heightInPx;
    this.sectionType = SectionType.Empty;
    this.firstOnPage = firstOnPage;
  }

  /**
   * Needed in SectionUtils to remove circular imports
   */
  isEmptySection(): boolean {
    return true;
  }

  getUniqueIdentifier(): string {
    return `
      -${this.pageIndex}
      -${this.heightInPx}
      -${this.firstOnPage}
    `;
  }

}
