import { Injectable } from '@angular/core';
import { ActiveToast, IndividualConfig, ToastrService } from 'ngx-toastr';
import { BsError } from '../models/shared/bs-error';

@Injectable({ providedIn: 'root' })
export class ToastService {

  private errorMsg = 'Check Internet Connection.';
  private errorTitle = 'Connectivity Error';

  constructor(
    private toast: ToastrService
  ) {
  }

  publishError(err: BsError) {
    this.toast.error(err.message, err.title);
  }

  showConnectionError() {
    this.publishErrorMessage(this.errorMsg, this.errorTitle);
  }

  publishErrorMessage(mess, title: string) {
    this.toast.error(mess, title);
  }

  publishSuccessMessage(mess, title: string) {
    this.toast.success(mess, title);
  }

  publishInfoMessage(mess, title: string, override?: Partial<IndividualConfig>): ActiveToast<any> {
    return this.toast.info(mess, title, override);
  }

  publishWarningMessage(mess, title: string) {
    this.toast.warning(mess, title);
  }

}
