import { Pipe, PipeTransform } from '@angular/core';
import type { ProductMenu } from '../../../models/menu/product-menu';

@Pipe({
  name: 'productMenuBasicMethod'
})
export class ProductMenuBasicMethodPipe implements PipeTransform {

  transform(
    productMenu: ProductMenu,
    methodKey: string,
    argument?: any
  ): any {
    return productMenu?.[methodKey]?.(argument);
  }

}
