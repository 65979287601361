import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/menu';
import { OpacityLocation } from '../../../models/shared/opacity-location.enum';

@Pipe({
  name: 'backgroundColorOpacity'
})
export class BackgroundColorOpacityPipe implements PipeTransform {

  transform(menu: Menu): number|string {
    return menu?.getOpacityEnabled() && menu?.getOpacityLocation() === OpacityLocation.BACKGROUND_ASSET
      ? menu?.menuOptions?.backgroundOpacity || '0'
      : '0';
  }

}
