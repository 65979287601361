import { Deserializable } from '../../protocols/deserializable';
import { UniquelyIdentifiable } from '../../protocols/uniquely-identifiable';

export class SectionMetadata implements Deserializable, UniquelyIdentifiable {

  public width: string = '100';
  public alignment: string = 'left';
  public imageAlignment: string = 'center';
  public repeat: string = 'false';
  public objectFit: string = 'cover';
  public gridColumnNames: string = '';
  public hidePrices: string = 'false';
  public autoBalanceThreshold: string = '';
  public cardOpacity: string = '';
  public hideLowStockLabels: string = 'false'; // NOT RETURNED FROM API, USED LOCALLY
  public hideNewLabels: string = 'false'; // NOT RETURNED FROM API, USED LOCALLY
  public hideRestockedLabels: string = 'false'; // NOT RETURNED FROM API, USED LOCALLY
  // background color
  public titleSectionBackgroundColor: string = '';
  public productsHeaderBackgroundColor: string = '';
  public productsContainerBackgroundColor: string = '';
  public productsInfoBackgroundColor: string = '';
  // font color
  public sectionHeaderTextColor: string = '';
  public sectionBodyTextColor: string = '';

  onDeserialize() {
    this.width = this.width || '100';
    this.alignment = this.alignment || 'left';
    this.imageAlignment = this.imageAlignment || 'center';
    this.repeat = this.repeat || 'false';
    this.objectFit = this.objectFit || 'cover';
    this.gridColumnNames = this.gridColumnNames || '';
    this.hidePrices = this.hidePrices || 'false';
    this.hideLowStockLabels = this.hideLowStockLabels || 'false';
    this.hideNewLabels = this.hideNewLabels || 'false';
    this.hideRestockedLabels = this.hideRestockedLabels || 'false';
    this.titleSectionBackgroundColor = this.titleSectionBackgroundColor || '';
    this.productsHeaderBackgroundColor = this.productsHeaderBackgroundColor || '';
    this.productsContainerBackgroundColor = this.productsContainerBackgroundColor || '';
    this.productsInfoBackgroundColor = this.productsInfoBackgroundColor || '';
  }

  getUniqueIdentifier(): string {
    return `
      -${this.width}
      -${this.alignment}
      -${this.imageAlignment}
      -${this.repeat}
      -${this.gridColumnNames}
      -${this.hidePrices}
      -${this.hideRestockedLabels}
      -${this.hideNewLabels}
      -${this.hideRestockedLabels}
      -${this.titleSectionBackgroundColor}
      -${this.productsHeaderBackgroundColor}
      -${this.productsContainerBackgroundColor}
      -${this.productsInfoBackgroundColor}
    `;
  }

}
