<div
  class="footer-container"
  #footerContainer
  [ngClass]="menu | productMenuBasicMethod : 'getFooterWrapperClass'"
  [class.no-classifications]="!(hasClassifications$ | async)"
  [style.display]="!(hasClassifications$ | async) ? 'block' : null"
  [style.color]="menu?.hydratedTheme?.themeFeatures?.footerTextColor ? menu?.menuOptions?.footerTextColor : ''">
  <div *ngIf="hasClassifications$ | async" class="footer-classification-board">
    <div *ngFor="let classification of uniqueClassifications$ | async" class="classification-mapping">
      <div [ngClass]="classification | lowercase">{{ classification | getClassificationSymbol }} -</div>
      <div class="classification-name" [ngClass]="classification | lowercase">
        {{ classification | uppercase }}
      </div>
    </div>
  </div>
</div>
