import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { PrintCardBackgroundComponent } from '../../print-card-background.component';
import { NycdIndexCardStackBackgroundViewModel } from './nycd-index-card-stack-background-view-model';

@Component({
  selector: 'app-nycd-index-card-background',
  templateUrl: './nycd-index-card-stack-background.component.html',
  styleUrls: ['./nycd-index-card-stack-background.component.scss'],
  providers: [NycdIndexCardStackBackgroundViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NycdIndexCardStackBackgroundComponent extends PrintCardBackgroundComponent {

  constructor(
    public override viewModel: NycdIndexCardStackBackgroundViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
    this.rendered.next(false);
  }

}
