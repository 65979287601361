import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, combineLatest, merge, ReplaySubject, Subject } from 'rxjs';
import { AssetSize } from '../../../models/enum/dto/asset-size.enum';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import { Menu } from '../../../models/menu/menu';
import { CachePolicy } from '../../../models/enum/shared/cachable-image-policy.enum';
import { DateUtils } from '../../../utils/date-utils';
import { exists } from '../../../functions/exists';
import { RefreshAssetService } from '../../../services/refresh-asset.service';
import { BaseComponent } from '../../../models/base/base-component';

@Component({
  selector: 'app-background-asset',
  templateUrl: './background-asset.component.html',
  styleUrls: ['./background-asset.component.scss'],
})
export class BackgroundAssetComponent extends BaseComponent implements AfterViewInit, OnChanges {

  constructor(
    public sanitizer: DomSanitizer,
    private refreshAssetService: RefreshAssetService
  ) {
    super();
  }

  @ViewChild('video') private video: ElementRef<HTMLVideoElement>;
  @Input() menu: Menu;
  @Input() scaleFit: boolean = false;
  @Input() loaded: boolean = false;
  @Input() cachePolicy: CachePolicy = CachePolicy.Service;
  @Input() cacheForNSeconds: number = (DateUtils.unixOneHour() * 12);
  @Input() top: string;
  @Input() right: string;
  @Input() bottom: string;
  @Input() left: string;
  @Input() playAudio: boolean = false;
  @Input() defaultImageUrl: string; // One or the other. Not both
  @Input() defaultVideoUrl: string; // One or the other. Not both
  @Input() reset: boolean = false;
  @Output() duration: EventEmitter<number> = new EventEmitter<number>();

  public defaultImageSubject: ReplaySubject<string | SafeResourceUrl> = new ReplaySubject<string | SafeResourceUrl>(1);
  public distinctDefaultImageAsset$ = this.defaultImageSubject.pipe(distinctUntilChanged());

  private defaultVideoSubject: ReplaySubject<string | SafeResourceUrl> = new ReplaySubject<string | SafeResourceUrl>(1);
  public distinctDefaultVideoAsset$ = this.defaultVideoSubject.pipe(distinctUntilChanged());

  private _imageBackgroundAsset: ReplaySubject<string | SafeResourceUrl> = new ReplaySubject(1);
  public imageBackgroundAsset$ = this._imageBackgroundAsset.pipe(
    distinctUntilChanged(),
    map(url => url || '')
  );

  private _videoBackgroundAsset: ReplaySubject<string | SafeResourceUrl> = new ReplaySubject(1);
  public videoBackgroundAsset$ = this._videoBackgroundAsset.pipe(
    distinctUntilChanged(),
    map(url => url || '')
  );

  private resetSubject = new BehaviorSubject<boolean>(false);
  private resetSignal$ = merge(
    this.resetSubject.pipe(distinctUntilChanged()),
    this.refreshAssetService.globalRefreshSignal$
  ).pipe(debounceTime(1));
  private isVideo = new BehaviorSubject<boolean>(false);
  public videoCanPlay = new BehaviorSubject<boolean>(false);

  // Reset video
  private resetVideo = new Subject<boolean>();
  private listenToReset = this.resetVideo.pipe(
    shareReplay({ bufferSize: 1, refCount: true }),
    takeUntil(this.onDestroy)
  ).subscribe(resetVid => {
    this.video?.nativeElement?.load();
    this.video?.nativeElement?.pause();
  });

  // Play video
  private playVideo = new Subject<boolean>();
  private listenToPlay = this.playVideo.pipe(
    shareReplay({ bufferSize: 1, refCount: true }),
    takeUntil(this.onDestroy)
  ).subscribe(playVideo => {
    this.video?.nativeElement?.play().then().catch(console.error);
  });

  // Reset
  private resetMech = combineLatest([
    this.resetSignal$,
    this.isVideo.pipe(startWith(false), distinctUntilChanged()),
    this.duration.asObservable().pipe(startWith(0), distinctUntilChanged()),
    this.videoCanPlay.pipe(distinctUntilChanged())
  ]).pipe(debounceTime(100), takeUntil(this.onDestroy))
    .subscribe(([reset, isVid, duration, canPlay]) => {
      if (isVid) {
        const loaded = duration > 0;
        const startedPlaying = ((this.video?.nativeElement?.currentTime ?? 0) > 0);
        if (reset && loaded && startedPlaying) {
          this.resetVideo.next(true);
        } else if (!reset && canPlay) {
          this.playVideo.next(true);
        }
      } else if (reset) {
        this.fetchAsset();
      }
    });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menu || changes.theme) {
      const oldHash = changes?.menu?.previousValue?.backgroundImage?.md5Hash;
      const newHash = changes?.menu?.currentValue?.backgroundImage?.md5Hash;
      const md5Changed = (oldHash !== newHash);
      if (md5Changed) {
        this.setupBindings();
      }
    }
    if (changes.reset) {
      this.checkReset();
    }
    if (changes.defaultImageUrl) {
      this.setDefaultBackgroundImage();
    }
    if (changes.defaultVideoUrl) {
      this.setDefaultBackgroundVideo();
    }
  }

  setupViews() {
    this.setupImageBinding();
    this.checkReset();
  }

  setupBindings() {
    this.setupImageBinding();
  }

  private checkReset() {
    this.resetSubject.next(this.reset);
  }

  private setupImageBinding() {
    this.resetRenderedLogic();
    this.fetchAsset();
    const imgKey = 'assetKey';
    this.destroyImageSub(imgKey);
    this.setDefaultBackgroundImage();
    this.setDefaultBackgroundVideo();
    let blobSub;
    const asset = this.menu?.backgroundImage;
    const priorityUrl$ = asset?.sizePriorityUrl$;
    if (asset?.isImage() && exists(priorityUrl$)) {
      this.isVideo.next(false);
      blobSub = this._imageBackgroundAsset.bind(priorityUrl$);
    } else if (asset?.isVideo() && exists(priorityUrl$)) {
      this.isVideo.next(true);
      blobSub = this._videoBackgroundAsset.bind(priorityUrl$);
    }
    if (blobSub) {
      this.pushImageSub(imgKey, blobSub);
    }
  }

  setDefaultBackgroundImage() {
    if (this.defaultImageUrl) {
      this.defaultImageSubject.next(`${this.defaultImageUrl}`);
    } else {
      this.defaultImageSubject.next(null);
    }
  }

  setDefaultBackgroundVideo() {
    if (this.defaultVideoUrl) {
      this.defaultVideoSubject.next(this.defaultVideoUrl);
    } else {
      this.defaultVideoSubject.next(null);
    }
  }

  protected fetchAsset() {
    const asset = this.menu?.backgroundImage;
    const size = AssetSize.Original;
    const cacheForNSeconds = this.cacheForNSeconds;
    const validCacheTime = cacheForNSeconds > -1 && cacheForNSeconds !== undefined && cacheForNSeconds !== null;
    if (!!asset && !!size && validCacheTime) {
      const policy = this.cachePolicy ?? CachePolicy.Service;
      asset.getAsset(policy, size, cacheForNSeconds);
    }
  }

  resetRenderedLogic() {
    const hasAsset = !!this.menu?.backgroundImage && (this.menu?.backgroundImage.isValid());
    if (!hasAsset) {
      this.noAssetToRender();
    }
  }

  noAssetToRender() {
    // if no asset, just say that it rendered properly, so the overall signal (isMenuReady) will fire
    this.rendered.next(true);
  }

  renderFailed() {
    // if failed, just say that it rendered properly, so the overall signal (isMenuReady) will fire
    this.rendered.next(true);
  }

  renderSucceeded() {
    this.rendered.next(true);
  }

  ngAfterViewInit() {
    // Don't call super.ngAfterViewInit(), because we don't want to call rendered.next(true)
    this.setupBindings();
  }

}
