import { Injectable } from '@angular/core';
import { BaseService } from '../models/base/base-service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RefreshAssetService extends BaseService {

  private globalRefreshSignal = new Subject<boolean>();
  public globalRefreshSignal$ = this.globalRefreshSignal.pipe(debounceTime(100));

  constructor() {
    super();
  }

  sendSignal() {
    this.globalRefreshSignal.next(true);
  }

}
