import { Asset } from './asset';
import { AssetUrl } from './asset-url';
import { PrefetchMediaUrl } from './prefetch-media-url';

export class PrefetchMediaAsset extends Asset {

  protected override deserializeUrls(filteredUrls: AssetUrl[]): void {
    this.urls = window?.injector?.Deserialize?.arrayOf(PrefetchMediaUrl, filteredUrls);
  }

}
