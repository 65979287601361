import { Component, ChangeDetectionStrategy, ElementRef, Renderer2 } from '@angular/core';
import { PrintCardContentComponent } from '../../print-card-content.component';
import { PlainJaneBusinessCardStackContentViewModel } from './plain-jane-business-card-stack-content-view-model';

@Component({
  selector: 'app-plain-jane-business-card-stack-content',
  templateUrl: './plain-jane-business-card-stack-content.component.html',
  styleUrls: ['./plain-jane-business-card-stack-content.component.scss'],
  providers: [PlainJaneBusinessCardStackContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlainJaneBusinessCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public override viewModel: PlainJaneBusinessCardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

}
