import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { Orientation } from '../models/enum/dto/orientation.enum';
import { Size } from '../models/shared/size';
import { BaseService } from '../models/base/base-service';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OrientationService extends BaseService {

  private physicalOrientation = new BehaviorSubject<Orientation>(Orientation.NA);
  private virtualOrientation = new BehaviorSubject<Orientation>(Orientation.NA);
  public virtualOrientation$ = this.virtualOrientation.asObservable();
  private canRotateWebView = new BehaviorSubject<boolean>(false);
  // this means that canRotateWebView is toggled on, and the viewport is being rotated virtually
  public virtuallyRotated$ = this.canRotateWebView.asObservable();
  public orientation$ = combineLatest([
    this.physicalOrientation,
    this.virtualOrientation,
    this.canRotateWebView,
  ]).pipe(
    map(([physical, virtual, canRotateViewport]) => (canRotateViewport ? virtual : physical)),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public isPortrait$ = this.orientation$.pipe(
    map(o => o === Orientation.Portrait || o === Orientation.ReversePortrait)
  );

  constructor() {
    super();
  }

  setOrientation(screenRectangle: DOMRectReadOnly) {
    if (screenRectangle.height <= 0 && screenRectangle.width <= 0) {
      this.physicalOrientation.next(Orientation.NA);
    } else if (screenRectangle.height > screenRectangle.width) {
      this.physicalOrientation.next(Orientation.Portrait);
    } else {
      this.physicalOrientation.next(Orientation.Landscape);
    }
  }

  setVirtualOrientation(s: Size) {
    this.virtualOrientation.next(s.orientation);
  }

  setCanRotateWebView(can: boolean) {
    this.canRotateWebView.next(can);
  }

}
