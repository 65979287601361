import { Component, ElementRef, forwardRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MenuSectionHeaderComponent } from '../menu-section-header.component';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { HtmlUtils } from '../../../../../../../../utils/html-utils';
import type { SectionRowViewModel } from '../../menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { SectionWithProducts } from '../../../../../../../../models/menu/section/section-with-products';
import { CompanyDomainModel } from '../../../../../../../../domain/company-domain-model';

@Component({
  selector: 'app-product-section-header',
  templateUrl: './product-section-header.component.html',
  providers: [
    { provide: MenuSectionHeaderComponent, useExisting: forwardRef(() => ProductSectionHeaderComponent) }
  ]
})
export class ProductSectionHeaderComponent extends MenuSectionHeaderComponent implements OnChanges {

  constructor(
    protected companyDomainModel: CompanyDomainModel,
    sanitizer: DomSanitizer
  ) {
    super(sanitizer);
  }

  @Input() section: SectionWithProducts;
  @ViewChild('headerContainer') headerContainer: ElementRef;

  public readonly cannabinoidColumnTypes$ = this.companyDomainModel.enabledCannabinoids$;
  public readonly terpeneColumnTypes$ = this.companyDomainModel.enabledTerpenes$;

  protected _sectionRowVMsSubject = new BehaviorSubject<SectionRowViewModel[]>([]);
  public sectionRowViewModels$ = this._sectionRowVMsSubject as Observable<SectionRowViewModel[]>;
  protected showCUOMInHeader$ = this.sectionRowViewModels$.pipe(
    map(vms => {
      if (!!vms?.length && vms?.firstOrNull()?.menu?.menuOptions?.showCUOMInHeader) {
        return vms?.map(vm => vm.getRowUniqueCUOM())?.filter(cuom => !!cuom)?.uniqueInstance(true);
      }
      return null;
    }),
  );
  public headerCUOMString$ = this.showCUOMInHeader$.pipe(map(CUOM => (!!CUOM ? `(${CUOM})` : '')));
  public headerBackgroundAsset$ = combineLatest([
    this.assetIsForSectionHeader,
    this.asset
  ]).pipe(
    map(([assetIsForSectionHeader, asset]) => (assetIsForSectionHeader ? asset : null)),
    distinctUntilChanged()
  );

  getHeaderHeight(): number {
    return HtmlUtils.getElementTotalHeight(this?.headerContainer?.nativeElement);
  }

  setupViews() {
    super.setupViews();
    this._sectionRowVMsSubject.next(this.sectionRowViewModels);
  }

  setupBindings() {
    super.setupBindings();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.sectionRowViewModels) {
      this._sectionRowVMsSubject.next(this.sectionRowViewModels);
    }
  }

}
