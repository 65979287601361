import { ProductMenu } from '../product-menu';
import { Type } from '@angular/core';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { SectionColumnProductInfoType, SectionColumnType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { StrainType } from '../../enum/dto/strain-classification.enum';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { ProductMenuFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/product-menu-footer.component';
import { ClassificationFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/classification-footer/classification-footer.component';
import { Section } from '../section/section';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { SortVariantUtils } from '../../../utils/sort-variant-utils';

export class LuxLeafMenu extends ProductMenu {

  protected override deserializeSections() {
    super.deserializeSections();
    this.translateVariantClassificationsIntoHybridIndicaSativa(this.sections);
  }

  protected override deserializeTemplateSections() {
    super.deserializeTemplateSections();
    this.translateVariantClassificationsIntoHybridIndicaSativa(this.templateSections);
  }

  override getThemeClass(): string {
    return 'lux-leaf-theme';
  }

  override getHeaderType(): Type<MenuHeaderTitleImageComponent> {
    return MenuHeaderTitleImageComponent;
  }

  override getFooterType(): Type<ProductMenuFooterComponent> {
    return ClassificationFooterComponent;
  }

  override getShowFooter(): boolean {
    return true;
  }

  override getShouldInflateColumnHeaderForRow(
    s: Section,
    col: SectionColumnViewModel,
    rows: SectionRowViewModel[]
  ): boolean {
    return col?.columnType !== SectionColumnProductInfoType.StrainType;
  }

  override getSectionHeaderUnderlined(): boolean {
    return false;
  }

  override getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  override getShowBadgesInline(): boolean {
    return true;
  }

  override getShowBadgesInlineOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  override getShowProductSubtitle(): boolean {
    return false;
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.StrainType, 1],
      [SectionColumnProductInfoType.ProductTitle, 2],
      [SectionColumnProductInfoType.Badge, 3],
      [SectionColumnProductInfoType.Brand, 4],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(5),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(6),
      [SectionColumnProductInfoType.QuantityAndSize, 7],
      [SectionColumnProductInfoType.Quantity, 8],
      [SectionColumnProductInfoType.Size, 9],
      [SectionColumnProductInfoType.VariantPrice, 10],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 11]
    ]);
  }

  override getUniqueClassificationList(): StrainType[] {
    const through = (c: StrainType, allowed: StrainType[]) => allowed?.includes(c);
    return super
      .getUniqueClassificationList()
      ?.filter(c => through(c, [
        StrainType.Hybrid,
        StrainType.Sativa,
        StrainType.Indica,
        StrainType.CBD
      ]))
      ?.sort(SortVariantUtils.luxLeafStrainTypeSort);
  }

  override getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  override getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  override getFontFaceList(): string[] {
    return ['Karla'];
  }

}
