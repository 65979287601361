import { ApiClient } from './api-client';
import { Observable, throwError } from 'rxjs';
import { Endpoints } from './endpoints';
import { Company } from '../models/company/dto/company';
import { CompanyConfiguration } from '../models/company/dto/company-configuration';
import { LoggableAPI } from '../models/protocols/loggable-api';
import { LoggingService } from '../services/logging-service';
import { catchError } from 'rxjs/operators';
import { BsError } from '../models/shared/bs-error';
import { ApiErrorLog } from '../models/shared/api-error-log';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CompanyAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private loggingService: LoggingService,
  ) {
  }

  // Variables

  public serviceName = 'Company';

  // Company

  public GetCompany(): Observable<Company> {
    const url = Endpoints.GetCompany();
    return this.apiClient.getObj<Company>(Company, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetCompany', err));
        return throwError(err);
      })
    );
  }

  public GetCompanyConfiguration(companyId: number): Observable<CompanyConfiguration> {
    const url = Endpoints.GetCompanyConfiguration(companyId);
    return this.apiClient.getObj<CompanyConfiguration>(CompanyConfiguration, url).pipe(
      catchError(e => {
        const err = new BsError(e, this.serviceName);
        this.loggingService.LogAPIError(new ApiErrorLog(this.serviceName, 'GetCompanyConfiguration', err));
        return throwError(err);
      })
    );
  }

}
