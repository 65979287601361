export enum SectionType {
  Asset = 'SectionType_Image',
  CardStack = 'SectionType_CardStack',
  CategoryCard = 'SectionType_CategoryCard',
  DoubleDutchProduct = 'SectionType_DoubleDutchProduct', // Double Dutch Product doesn't exist on backend
  Empty = 'SectionType_EmptySection',
  LabelStack = 'SectionType_LabelStack',
  LoopingContentSection = 'SectionType_LoopingContentSection',
  PageBreak = 'SectionType_PageBreak',
  PlantlifeProduct = 'SectionType_PlantlifeProduct', // Plantlife Product doesn't exist on backend
  PrintReportNewProducts = 'SectionType_PrintReportNewProducts',
  Product = 'SectionType_Product',
  PrintReportRestockedProducts = 'SectionType_PrintReportRestockedProducts',
  Spotlight = 'SectionType_Spotlight',
  Title = 'SectionType_Title',
}
