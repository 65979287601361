import { ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { FikaIndexCardStackContentViewModel } from './fika-index-card-stack-content-view-model';
import { PrintCardContentComponent } from '../../print-card-content.component';
import { FikaCardData } from '../../../../../../../models/print-cards/fika-card-data';

@Component({
  selector: 'app-fika-index-card-stack-content',
  templateUrl: './fika-index-card-stack-content.component.html',
  styleUrls: ['./fika-index-card-stack-content.component.scss'],
  providers: [FikaIndexCardStackContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FikaIndexCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public override viewModel: FikaIndexCardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

  @Input() override cardData: FikaCardData;

}
