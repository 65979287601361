import { SectionRowViewModel } from '../../../../product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { SpotlightMenu } from '../../../../../../../../models/menu/spotlight-menu';
import type { SpotlightSection } from '../../../../../../../../models/menu/section/spotlight-section';
import { CannabisUnitOfMeasure } from '../../../../../../../../models/enum/dto/cannabis-unit-of-measure.enum';
import { ProductType } from '../../../../../../../../models/enum/dto/product-type.enum';
import { StringUtils } from '../../../../../../../../utils/string-utils';

export class SpotlightSectionRowViewModel extends SectionRowViewModel {

  menu: SpotlightMenu;
  section: SpotlightSection;

  public getThcStringWithoutUnits(): string {
    // Get THC value
    if (this.displayCannabinoidInRanges()) {
      const thcRange = this.getRowRangeCannabinoidOrTerpene('THC');
      if (thcRange === '') {
        return `${CannabisUnitOfMeasure.NA}`;
      } else {
        return `${thcRange}`.trim();
      }
    } else {
      const parsedThc = this.getMinRowCannabinoidOrTerpene('THC');
      if (parsedThc < 1) {
        return (`< 1`).trim();
      } else {
        return `${(Math.round((parsedThc + Number.EPSILON) * 100) / 100)}`.trim();
      }
    }
  }

  public getCbdStringWithoutUnits(): string {
    // Get THC value
    if (this.displayCannabinoidInRanges()) {
      const cbdRange = this.getRowRangeCannabinoidOrTerpene('CBD');
      if (cbdRange === '') {
        return `${CannabisUnitOfMeasure.NA}`;
      } else {
        return `${cbdRange}`.trim();
      }
    } else {
      const parsedCbd = this.getMinRowCannabinoidOrTerpene('CBD');
      if (parsedCbd < 1) {
        return (`<1`).trim();
      } else {
        return `${(Math.round((parsedCbd + Number.EPSILON) * 100) / 100)}`.trim();
      }
    }
  }

  public getSize(): string {
    const productType = this.rowVariants[0]?.productType;
    if (productType !== ProductType.Accessories && productType !== ProductType.Other) {
      if (productType === ProductType.Edible || productType === ProductType.Beverage) {
        // For edibles we dont want to show size as it would be 10.5g or 333mL etc.
        return 'Price';
      } else {
        return this.rowVariants[0].getFormattedUnitSize();
      }
    }
    return '';
  }

  override getCannabinoid(cannabinoid: string): string {
    return StringUtils.removeWhiteSpace(super.getCannabinoid(cannabinoid));
  }

}
