import { Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { RenderContextComponent } from '../../../../../models/base/render-context-component';
import { PrintCardInterface } from '../../../interface/print-card.interface';
import { CardData } from '../../../../../models/print-cards/card-data';
import { PrintCardBackgroundViewModel } from './print-card-background-view-model';

@Component({ selector: 'app-print-card-background', template: '' })
export abstract class PrintCardBackgroundComponent extends RenderContextComponent
  implements PrintCardInterface, OnChanges {

  constructor(
    public viewModel: PrintCardBackgroundViewModel,
    protected elementRef: ElementRef,
    protected renderer2: Renderer2
  ) {
    super();
  }

  @Input() cardData: CardData;
  @Input() preview: boolean;

  setupViews() {
    this.handleBleedClass();
    this.viewModel.connectToCardData(this.cardData);
    this.viewModel.connectToPreview(this.preview);
  }

  setupBindings() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cardData) {
      this.handleBleedClass();
      this.viewModel.connectToCardData(this.cardData);
    }
    if (changes.preview) {
      this.handleBleedClass();
      this.viewModel.connectToPreview(this.preview);
    }
  }

  private handleBleedClass(): void {
    (this.cardData?.section?.isOnRegularPaper() && !this.preview)
      ? this.renderer2.addClass(this.elementRef.nativeElement, 'bleeding')
      : this.renderer2.removeClass(this.elementRef.nativeElement, 'bleeding');
  }

}
