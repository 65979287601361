@charset "UTF-8";

/* src/app/modules/display/components/menus/product-menu/building-blocks/menu-footer/plantlife-non-smokable-footer/plantlife-non-smokable-footer.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.print-menu.plantlife-non-smokable-theme {
  page: plantlife-beverages-theme-pages;
}
@page plantlife-non-smokable-theme-pages {
  margin: 0.25in;
}
.plantlife-non-smokable-theme .background-asset {
  background-color: #222222;
}
.plantlife-non-smokable-theme .header-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  padding: 1rem 0 !important;
  gap: 0.5rem;
}
.plantlife-non-smokable-theme .header-container .main-header {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 3.27rem;
  text-align: center;
  max-width: 100%;
}
.plantlife-non-smokable-theme .header-container .company-logo {
  height: 8.18rem !important;
  max-height: 8.18rem;
}
.plantlife-non-smokable-theme .products-container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}
.plantlife-non-smokable-theme .sections-container {
  margin-left: 0;
  margin-right: 0;
}
.plantlife-non-smokable-theme .title-section {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 3.75rem;
}
.plantlife-non-smokable-theme .section-column {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.75rem;
}
.plantlife-non-smokable-theme .section-column.section-column-classification {
  flex: 1;
}
.plantlife-non-smokable-theme .section-container {
  border-left: 1px solid #222222;
  border-right: 1px solid #222222;
  flex: 1;
  padding-left: 0;
  padding-right: 0;
  overflow: unset;
}
.plantlife-non-smokable-theme .section-header-title {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.75rem;
  letter-spacing: 0.0625rem;
  color: #222222;
  text-transform: uppercase;
  text-align: center;
  padding: 0.25rem;
}
.plantlife-non-smokable-theme .section-header-container {
  border-top: 2px solid #222222;
  border-bottom: 2px solid #222222;
  flex-direction: row;
  height: 2.36rem;
  background-color: #ffffff;
  padding-top: 0 !important;
}
.plantlife-non-smokable-theme .section-title {
  text-transform: uppercase;
}
.plantlife-non-smokable-theme .section-header-general {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.75rem;
  color: #222222;
}
.plantlife-non-smokable-theme .product-column-general {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.plantlife-non-smokable-theme .product-container {
  align-items: center;
  height: 4.72rem;
}
.plantlife-non-smokable-theme .product-container.odd {
  border-right: 1px solid #222222;
}
.plantlife-non-smokable-theme .product-container.last {
  border-bottom: unset;
}
.plantlife-non-smokable-theme .product-strain-type {
  color: #222222;
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.375rem;
}
.plantlife-non-smokable-theme .product-strain-type.s {
  color: #222222;
}
.plantlife-non-smokable-theme .product-strain-type.h {
  color: #222222;
}
.plantlife-non-smokable-theme .product-strain-type.i {
  color: #222222;
}
.plantlife-non-smokable-theme .product-strain-type.b {
  color: #222222;
}
.plantlife-non-smokable-theme .product-strain-type.c {
  color: #222222;
}
.plantlife-non-smokable-theme .product-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.18rem;
  height: 100%;
  padding-left: 0.25rem;
}
.plantlife-non-smokable-theme .product-title {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.3125rem;
}
.plantlife-non-smokable-theme .product-badge {
  display: flex;
  gap: 0.5rem;
  order: 3;
}
.plantlife-non-smokable-theme .badge {
  height: 1.2rem;
}
.plantlife-non-smokable-theme .product-label {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 0.875rem;
  margin-left: 0;
  border-radius: 0.2rem;
  padding: 0.125rem 0.4rem;
  align-self: end;
}
.plantlife-non-smokable-theme .subtitle-flex-container {
  gap: 0.75rem;
}
.plantlife-non-smokable-theme .product-subtitle {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.25rem;
  font-size: 1.25rem;
}
.plantlife-non-smokable-theme .product-image-column {
  max-height: 80%;
  max-width: 80%;
  display: flex;
  overflow-x: visible;
}
.plantlife-non-smokable-theme .product-image-column .product-strain-type {
  margin-left: 0.25rem;
}
.plantlife-non-smokable-theme .product-image {
  height: 100%;
  width: inherit;
  filter: grayscale(1);
}
.plantlife-non-smokable-theme .product-low-amount {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}
.plantlife-non-smokable-theme .product-size {
  font-size: 1.25rem;
}
.plantlife-non-smokable-theme .product-column {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.25rem;
}
.plantlife-non-smokable-theme .base-product-strain-type {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.5rem;
}
.plantlife-non-smokable-theme .image-section {
  margin-top: 0;
}
.plantlife-non-smokable-theme .empty-container {
  background-color: #ffffff;
  border-right: 1px solid #222222;
}
.plantlife-non-smokable-theme .empty-container .lane-one {
  flex: 1 1 100%;
  max-width: 50%;
  border-left: 1px solid #222222;
  border-right: 1px solid #222222;
}
.plantlife-non-smokable-theme .empty-container .lane-two {
  flex: 1 1 100%;
  max-width: 50%;
  border-right: 1px solid rgba(255, 255, 255, 0);
}
.plantlife-non-smokable-theme .force-bold-style * {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
}
.footer-container {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  color: white;
  text-transform: uppercase;
  display: flex;
  align-content: flex-start;
  padding: 0;
  flex-wrap: wrap;
}
.footer-container .side-of-classification-board .top-of-side {
  background-color: #ffffff;
  border-bottom: 1px solid #222222;
}
.footer-container .side-of-classification-board .top-of-side.left {
  border-left: 1px solid #222222;
}
.footer-container .side-of-classification-board .top-of-side.right {
  border-right: 1px solid #222222;
}
.footer-container .badge-in-legend {
  width: 1rem;
  height: 1rem;
}
.footer-container .footer-classification-board {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.25rem;
  color: #222222;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 0.9rem;
  row-gap: 0.2rem;
  padding: 0.1rem 0.5rem;
  max-width: 80%;
  min-height: 1.82rem;
  border: 1px solid #222222;
  background-color: #ffffff;
}
.footer-container .footer-classification-board .indica {
  color: #222222;
}
.footer-container .footer-classification-board .sativa {
  color: #222222;
}
.footer-container .footer-classification-board .hybrid {
  color: #222222;
}
.footer-container .footer-classification-board .blend {
  color: #222222;
}
.footer-container .footer-classification-board .cbd {
  color: #222222;
}
.footer-container .footer-classification-board .classification-mapping {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.35rem;
}
/*# sourceMappingURL=plantlife-non-smokable-footer.component.css.map */
