import { Pipe, PipeTransform } from '@angular/core';
import { ProductStylingViewModel } from '../../../models/shared/product-styling-view-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'isLowAmountTerpene',
  standalone: true
})
export class IsLowAmountTerpenePipe implements PipeTransform {

  transform(productStylingViewModel: ProductStylingViewModel, terpenePascalCased: string): Observable<boolean> {
    return productStylingViewModel.rowViewModel$.pipe(
      map(sectionRowViewModel => sectionRowViewModel?.getTerpene(terpenePascalCased)?.indexOf('<') > -1)
    );
  }

}
