export enum StrainType {
  Indica = 'Indica',
  IndicaDominant = 'IndicaDominant',
  Sativa = 'Sativa',
  SativaDominant = 'SativaDominant',
  Hybrid = 'Hybrid',
  Blend = 'Blend',
  CBD = 'CBD',
  UNKNOWN = '',
  NA = 'N/A'
}
