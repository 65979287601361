export enum Fraction {
  Eighth = '⅛',
  Quarter = '¼',
  Third = '⅓',
  ThreeEighths = '⅜',
  Half = '½',
  FiveEighths = '⅝',
  TwoThirds = '⅔',
  ThreeQuarters = '¾',
  SevenEighths = '⅞',
}
