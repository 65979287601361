import { Injectable } from '@angular/core';
import { PrintCardContentViewModel } from '../../print-card-content-view-model';
import { Observable } from 'rxjs';
import { PlainJaneCardData } from '../../../../../../../models/print-cards/plain-jane-card-data';
import { map } from 'rxjs/operators';
import { PrintCardColumnViewModel } from '../../../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/print-card-column-view-model';
import { SectionLayoutType } from '../../../../../../../models/enum/dto/section-layout-type.enum';

@Injectable()
export class PlainJanePostCardStackContentViewModel extends PrintCardContentViewModel {

  public readonly cardData$: Observable<PlainJaneCardData>;

  public showSize$ = this.cardData$.pipe(
    map(cardData => cardData?.showSize())
  );

  public showQuantity$ = this.cardData$.pipe(
    map(cardData => cardData?.showQuantity())
  );

  public gridHeader$ = this.showSize$.pipe(
    map(showSize => showSize ? 'SIZE' : 'QTY')
  );

  public override getGridSize$(columnVM: PrintCardColumnViewModel): Observable<string> {
    return this.cardData$.pipe(
      map(cardData => {
        const variant = cardData?.getVariantFromGridColumn(columnVM);
        const isEdible = !cardData?.showSize();
        const locationId = cardData?.locationConfig?.locationId;
        const gridName = variant?.getGridNames(SectionLayoutType.Grid, locationId)?.firstOrNull()?.replace(/\s/g, '');
        return isEdible ? gridName?.replace('pk', 'pc') : gridName;
      })
    );
  }

}
