import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { ColumnComponent } from '../column.component';

@Component({
  selector: 'app-column-badge',
  templateUrl: './column-badge.component.html',
  styleUrls: ['./column-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ColumnBadgeComponent extends ColumnComponent {

  @Input() reset: boolean;

}
