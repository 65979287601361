import { Component } from '@angular/core';
import { RenderContextComponent } from '../../../../../../../../models/base/render-context-component';
import { SessionsPostCardStackContentViewModel } from '../sessions-post-card-stack-content-view-model';

@Component({
  selector: 'app-sessions-price',
  templateUrl: './sessions-price.component.html',
  styleUrls: ['./sessions-price.component.scss']
})
export class SessionsPriceComponent extends RenderContextComponent {

  constructor(
    public viewModel: SessionsPostCardStackContentViewModel
  ) {
    super();
  }

  setupBindings() {
  }

  setupViews() {
  }

}
