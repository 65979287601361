import { Pipe, PipeTransform } from '@angular/core';
import { CardStack } from '../../../models/menu/section/card-stacks/card-stack';

@Pipe({
  name: 'cardContentWidth'
})
export class CardContentWidthPipe implements PipeTransform {

  transform(cardStack: CardStack): number {
    return cardStack?.getCardWidthInPixels() || 0;
  }

}
