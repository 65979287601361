<div
  class="footer-container"
  #footerContainer
  [ngClass]="menu | productMenuBasicMethod : 'getFooterWrapperClass'"
  [style.display]="(noBadges$ | async) ? 'block' : null"
  [style.color]="menu?.hydratedTheme?.themeFeatures?.footerTextColor ? menu?.menuOptions?.footerTextColor : ''">
  <div
    *ngFor="
      let badge of menuBadges$ | async | filterList : filterOutBadgeNamesWith86;
      trackBy: trackByBadgeUniqueIdentifier
    "
    class="footer-badge-container">
    <img appImage class="footer-badge" [asset]="badge?.image" [reset]="reset" alt="badge" src="" />
    <div class="footer-badge-title">= {{ badge?.name }}</div>
  </div>
</div>
