import { ChangeDetectionStrategy, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { DougIndexCardStackContentViewModel } from './doug-index-card-stack-content-view-model';
import { PrintCardContentComponent } from '../../print-card-content.component';
import { ResizeObserver } from '@juggle/resize-observer';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'lib-doug-index-card-stack-content',
  templateUrl: './doug-index-card-stack-content.component.html',
  styleUrls: ['./doug-index-card-stack-content.component.scss'],
  providers: [DougIndexCardStackContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DougIndexCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public override viewModel: DougIndexCardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

  @ViewChild('linePrice') linePrice: ElementRef<HTMLDivElement>;

  public ro: ResizeObserver;
  lineItemRangeCannabinoidInRem = 7;
  lineItemCannabinoidInRem = 3.5;

  private _priceIsVisible = new BehaviorSubject<boolean>(false);
  public readonly priceIsVisible$ = this._priceIsVisible as Observable<boolean>;

  setupBindings() {
    super.setupBindings();
    this.observeProductCardContainer();
  }

  observeProductCardContainer() {
    this.ro = new ResizeObserver(() => {
      const productCardHeight = this.linePrice?.nativeElement?.clientHeight;
      this._priceIsVisible.next(productCardHeight > 0);
    });
    if (this.linePrice?.nativeElement) this.ro.observe(this.linePrice.nativeElement);
  }

  destroy() {
    super.destroy();
    this.ro?.disconnect();
  }

}
