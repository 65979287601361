/* src/app/modules/shared/asset/asset.component.scss */
.asset-container {
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.asset {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.shimmer-loading {
  z-index: 5;
}
img {
  vertical-align: auto;
}
.ease-me-in-from-top {
  animation-name: ease-me-in-from-top;
  animation-duration: 0.5s;
}
.sweep-from-middle-to-top {
  animation-name: sweep-from-middle-to-top;
  animation-duration: 0.5s;
}
.sweep-from-bottom-to-top {
  animation-name: sweep-from-bottom-to-top;
  animation-duration: 0.5s;
}
@keyframes ease-me-in-from-top {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes sweep-from-middle-to-top {
  from {
    object-position: center;
  }
  to {
    object-position: top;
  }
}
@keyframes sweep-from-bottom-to-top {
  from {
    object-position: bottom;
  }
  to {
    object-position: top;
  }
}
/*# sourceMappingURL=asset.component.css.map */
