import { Pipe, PipeTransform } from '@angular/core';
import type { CardStack } from '../../../models/menu/section/card-stacks/card-stack';

@Pipe({
  name: 'isOnRegularPaper'
})
export class IsOnRegularPaperPipe implements PipeTransform {

  transform(value: CardStack): boolean {
    return value?.isOnRegularPaper() || false;
  }

}
