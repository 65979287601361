import { RenderContextComponent } from '../../../../models/base/render-context-component';
import { Component, Input } from '@angular/core';
import type { ProductMenu } from '../../../../models/menu/product-menu';
import type { SectionWithProducts } from '../../../../models/menu/section/section-with-products';
import type { SectionColumnViewModel } from '../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import type { SectionRowViewModel } from '../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { ProductStylingViewModel } from '../../../../models/shared/product-styling-view-model';
import type { LocationConfiguration } from '../../../../models/company/dto/location-configuration';
import type { VariantBadge } from '../../../../models/product/dto/variant-badge';

@Component({ selector: 'app-column', template: '' })
export abstract class ColumnComponent extends RenderContextComponent {

  @Input() menu: ProductMenu;
  @Input() section: SectionWithProducts;
  @Input() colVm: SectionColumnViewModel;
  @Input() rowVm: SectionRowViewModel;
  @Input() productStylingVm: ProductStylingViewModel;
  @Input() locConfig: LocationConfiguration;
  @Input() locationId: number;

  setupViews() {}
  setupBindings() {}

  public trackByBadgeUniqueIdentifier = (index: number, badge: VariantBadge): string => {
    return badge?.getUniqueIdentifier();
  };

}
