import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { IsReadyService } from './is-ready.service';
import { DisplayDomainModel } from '../../domain/display-domain-model';
import { Menu } from '../../models/menu/menu';

@Injectable({ providedIn: 'root' })
export class IsMenuReadyService extends IsReadyService {

  constructor(
    private displayDomainModel: DisplayDomainModel
  ) {
    super();
  }

  private variantMetadataLoaded$ = this.displayDomainModel?.variantMetadataLoaded$;

  getApiResponded$(): Observable<boolean> {
    return this.displayDomainModel.apiResponded$;
  }

  getMenu$(): Observable<Menu> {
    return this.displayDomainModel.menuToDisplay.pipe(map(menuToDisplay => menuToDisplay?.menu));
  }

  getIsReady$(): Observable<boolean> {
    return combineLatest([
      this.timeout$,
      this.variantMetadataLoaded$,
      this.rendered$,
      this.fontsLoaded$,
      this.calculatingOverflow$
    ]).pipe(
      map(([timedOut, variantImageDataFetched, rendered, fontsLoaded, calculatingOverflow]) => {
        const loaded = variantImageDataFetched && rendered && fontsLoaded && !calculatingOverflow;
        return loaded || timedOut;
      }),
      // if signal bounces from false to true to false, prevent false loaded signal from passing through
      debounceTime(1000),
      distinctUntilChanged(),
      startWith(false)
    );
  }

  public waitUntilMenuRenders$(contextId: string): Observable<boolean> {
    return this.contextRenderedSignals.pipe(
      switchMap(renderSignalBundle => {
        return of(renderSignalBundle).pipe(
          // the rendered signals list is going to be spammed at the start, so wait until it plateaus before continuing
          delay(1000),
          map(contextRenderedSignals => contextRenderedSignals?.get(contextId) || []),
          switchMap(renderedSignals => combineLatest(renderedSignals)),
          map(renderedSignals => renderedSignals?.every(isRendered => isRendered)),
          // always fire off false down the pipeline as the signal bundle changes
          startWith(false),
        );
      }),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

}
