import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelInflatorComponent } from './components/product-menu-label-inflator/label-inflator.component';
import { FlagLabelComponent } from './components/flag-label/flag-label.component';
import { BasicRoundBorderLabelComponent } from './components/basic-round-border-label/basic-round-border-label.component';
import { FitTextModule } from '../scale-up-text/fit-text.module';
import { ScaleDownTextModule } from '../scale-down-text/scale-down-text.module';
import { GetShutOffLabelForLabelComponentPipe } from './pipes/get-shut-off-label-for-label-component.pipe';
import { InflatorModule } from '../inflator/inflator.module';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

@NgModule({
  declarations: [
    LabelInflatorComponent,
    FlagLabelComponent,
    BasicRoundBorderLabelComponent,
    GetShutOffLabelForLabelComponentPipe,
  ],
  imports: [
    CommonModule,
    FitTextModule,
    FlexLayoutModule,
    ScaleDownTextModule,
    InflatorModule
  ],
  exports: [
    LabelInflatorComponent,
    FlagLabelComponent,
    BasicRoundBorderLabelComponent,
  ]
})
export class LabelsModule { }
