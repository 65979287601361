import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CutLinesComponent } from '../cut-lines.component';

@Component({
  selector: 'app-print-card-internal-grid-cut-lines',
  templateUrl: './print-card-internal-grid-cut-lines.component.html',
  styleUrls: ['./print-card-internal-grid-cut-lines.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintCardInternalGridCutLinesComponent extends CutLinesComponent {

  readonly repeat = Array;

}
