import { ColumnGrouping } from '../models/menu/section/column-grouping';
import { SectionColumnCannabinoidType, SectionColumnProductInfoType, SectionColumnTerpeneType } from '../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { ColWidth } from '../models/shared/col-width';
import { type SectionColumnConfig, SectionColumnConfigCannabinoidKey, SectionColumnConfigKey, SectionColumnConfigProductInfoKey, SectionColumnConfigTerpeneKey } from '../models/menu/section/section-column-config';
import { ColumnWidth } from '../models/enum/shared/column-width.enum';
import type { ProductMenu } from '../models/menu/product-menu';

// @dynamic
export class ColumnUtils {

  /* ***************************** Column Widths ***************************** */

  static getProducerColWidth(stdPercentage: number): ColWidth {
    return new ColWidth(SectionColumnProductInfoType.Producer, stdPercentage);
  }

  static getBadgeColWidth(
    stdPercentage: number,
    sectionColumnConfigMap: Map<SectionColumnConfigKey, SectionColumnConfig | null>
  ): ColWidth {
    const badgeSectionWidth = sectionColumnConfigMap?.get(SectionColumnConfigProductInfoKey.Badges)?.columnWidth;
    const badgeWidth = badgeSectionWidth || stdPercentage;
    return new ColWidth(SectionColumnProductInfoType.Badge, badgeWidth);
  }

  static getBrandColWidth(sectionColumnConfigMap: Map<SectionColumnConfigKey, SectionColumnConfig | null>): ColWidth {
    const brandSectionWidth = sectionColumnConfigMap?.get(SectionColumnConfigProductInfoKey.Brand)?.columnWidth;
    const brandWidth = brandSectionWidth || ColumnWidth.BrandDefault;
    return new ColWidth(SectionColumnProductInfoType.Brand, brandWidth);
  }

  static getStrainClassColWidth(
    stdPercentage: number,
    sectionColumnConfigMap: Map<SectionColumnConfigKey, SectionColumnConfig | null>
  ): ColWidth {
    const sClassSectionWidth = sectionColumnConfigMap?.get(SectionColumnConfigProductInfoKey.StrainType)?.columnWidth;
    const sClassWidth = sClassSectionWidth || stdPercentage;
    const strainClass = SectionColumnProductInfoType.StrainType;
    return new ColWidth(strainClass, sClassWidth);
  }

  static getAssetColWidth(
    stdPercentage: number,
    sectionColumnConfigMap: Map<SectionColumnConfigKey, SectionColumnConfig | null>
  ): ColWidth {
    const assetSectionWidth = sectionColumnConfigMap?.get(SectionColumnConfigProductInfoKey.Asset)?.columnWidth;
    const assetWidth = assetSectionWidth || stdPercentage;
    return new ColWidth(SectionColumnProductInfoType.Asset, assetWidth || stdPercentage);
  }

  static getQuantityInStockColWidth(
    stdPercentage: number,
    sectionColumnConfigMap: Map<SectionColumnConfigKey, SectionColumnConfig | null>
  ): ColWidth {
    // Quantity In Stock
    const qtyInStockSectionWidth = sectionColumnConfigMap?.get(SectionColumnConfigProductInfoKey.Stock)?.columnWidth;
    const qtyInStockWidth = qtyInStockSectionWidth || stdPercentage;
    return new ColWidth(SectionColumnProductInfoType.Stock, qtyInStockWidth);
  }

  static getQuantityAndSizeColWidth(
    stdPercentage: number,
    sectionColumnConfigMap: Map<SectionColumnConfigKey, SectionColumnConfig | null>
  ): ColWidth {
    const qtyAndSizeSectionWidth = sectionColumnConfigMap
      ?.get(SectionColumnConfigProductInfoKey.QuantityAndSize)
      ?.columnWidth;
    const qtyAndSizeWidth = qtyAndSizeSectionWidth || stdPercentage;
    return new ColWidth(SectionColumnProductInfoType.QuantityAndSize, qtyAndSizeWidth);
  }

  static getQuantityColWidth(
    stdPercentage: number,
    sectionColumnConfigMap: Map<SectionColumnConfigKey, SectionColumnConfig | null>
  ): ColWidth {
    const quantitySectionWidth = sectionColumnConfigMap?.get(SectionColumnConfigProductInfoKey.Quantity)?.columnWidth;
    const quantityWidth = quantitySectionWidth || stdPercentage;
    return new ColWidth(SectionColumnProductInfoType.Quantity, quantityWidth || stdPercentage);
  }

  static getSizeColWidth(
    stdPercentage: number,
    sectionColumnConfigMap: Map<SectionColumnConfigKey, SectionColumnConfig>
  ): ColWidth {
    const sizeSectionWidth = sectionColumnConfigMap?.get(SectionColumnConfigProductInfoKey.Size)?.columnWidth;
    const sizeWidth = sizeSectionWidth || stdPercentage;
    return new ColWidth(SectionColumnProductInfoType.Size, sizeWidth);
  }

  static getPriceColWidth(
    stdPercentage: number,
    sectionColumnConfigMap: Map<SectionColumnConfigKey, SectionColumnConfig | null>
  ): ColWidth {
    const priceSectionWidth = sectionColumnConfigMap?.get(SectionColumnConfigProductInfoKey.Price)?.columnWidth;
    const priceWidth = priceSectionWidth || stdPercentage;
    return new ColWidth(SectionColumnProductInfoType.VariantPrice, priceWidth);
  }

  static getSecondaryPriceColWidth(
    stdPercentage: number,
    sectionColumnConfigMap: Map<SectionColumnConfigKey, SectionColumnConfig | null>
  ): ColWidth {
    const secondaryPriceSectionWidth = sectionColumnConfigMap
      ?.get(SectionColumnConfigProductInfoKey.SecondaryPrice)
      ?.columnWidth;
    const sPriceWidth = secondaryPriceSectionWidth || stdPercentage;
    const sPrice = SectionColumnProductInfoType.VariantSecondaryPrice;
    return new ColWidth(sPrice, sPriceWidth);
  }

  static getSpacerColWidth(productMenu: ProductMenu): ColWidth {
    return new ColWidth(SectionColumnProductInfoType.Spacer, productMenu?.getColumnGroupingSpacerWidthPercentage());
  }

  static getTinySpacerColWidth(): ColWidth {
    return new ColWidth(SectionColumnProductInfoType.TinySpacer, ColumnWidth.TinySpacerDefault);
  }

  static getPrimaryCannabinoidColWidths(
    sectionColumnConfigMap: Map<SectionColumnConfigKey, SectionColumnConfig | null>
  ): ColWidth[] {
    const thcSectionWidth = sectionColumnConfigMap?.get(SectionColumnConfigCannabinoidKey.THC)?.columnWidth;
    const cbdSectionWidth = sectionColumnConfigMap?.get(SectionColumnConfigCannabinoidKey.CBD)?.columnWidth;
    const thcWidth = thcSectionWidth || ColumnWidth.CannabinoidDefault;
    const cbdWidth = cbdSectionWidth || ColumnWidth.CannabinoidDefault;
    return [
      new ColWidth(SectionColumnCannabinoidType.THC, thcWidth),
      new ColWidth(SectionColumnCannabinoidType.CBD, cbdWidth),
      // THC and CBD - not returned from API. Used in some custom themes
      new ColWidth(SectionColumnCannabinoidType.THCAndCBD, ColumnWidth.ThcAndCbdDefault)
    ];
  }

  static getSecondaryCannabinoidColWidths(
    sectionColumnConfigMap: Map<SectionColumnConfigKey, SectionColumnConfig | null>
  ): ColWidth[] {
    return Object.values(SectionColumnConfigCannabinoidKey).map(cannabinoidKey => {
      const sectionDefinedWidth = sectionColumnConfigMap?.get(cannabinoidKey)?.columnWidth;
      const width = sectionDefinedWidth || ColumnWidth.CannabinoidDefault;
      const cannabinoidType = SectionColumnCannabinoidType[cannabinoidKey];
      return new ColWidth(cannabinoidType, width);
    });
  }

  static getTerpeneColWidths(
    sectionColumnConfigMap: Map<SectionColumnConfigKey, SectionColumnConfig | null>
  ): ColWidth[] {
    return Object.values(SectionColumnConfigTerpeneKey).map(terpeneKey => {
      const sectionDefinedWidth = sectionColumnConfigMap?.get(terpeneKey)?.columnWidth;
      const width = sectionDefinedWidth || ColumnWidth.TerpeneDefault;
      const terpeneType = SectionColumnTerpeneType[terpeneKey];
      return new ColWidth(terpeneType, width);
    });
  }

  /* ***************************** Column Groupings ***************************** */

  static oneBigGrouping(): ColumnGrouping[] {
    return [
      new ColumnGrouping([
        SectionColumnProductInfoType.StrainType, SectionColumnProductInfoType.Brand,
        SectionColumnProductInfoType.Stock, SectionColumnProductInfoType.Quantity, SectionColumnProductInfoType.Size,
        SectionColumnProductInfoType.VariantPrice, SectionColumnProductInfoType.VariantSecondaryPrice,
        ...Object.values(SectionColumnCannabinoidType),
        ...Object.values(SectionColumnTerpeneType)
      ]),
    ];
  }

  static standardizedGrouping(): ColumnGrouping[] {
    return [
      new ColumnGrouping([
        SectionColumnProductInfoType.StrainType,
        SectionColumnProductInfoType.Brand
      ]),
      new ColumnGrouping(Object.values(SectionColumnCannabinoidType)),
      new ColumnGrouping(Object.values(SectionColumnTerpeneType)),
      new ColumnGrouping([
        SectionColumnProductInfoType.Stock,
        SectionColumnProductInfoType.QuantityAndSize,
        SectionColumnProductInfoType.Quantity,
        SectionColumnProductInfoType.Size,
        SectionColumnProductInfoType.VariantPrice,
        SectionColumnProductInfoType.VariantSecondaryPrice
      ])
    ];
  }

}
