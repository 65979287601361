import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { DougArBusinessCardStackContentViewModel } from './doug-ar-business-card-stack-content-view-model';
import { PrintCardContentComponent } from '../../print-card-content.component';

@Component({
  selector: 'lib-doug-ar-business-card-stack-content',
  templateUrl: './doug-ar-business-card-stack-content.component.html',
  styleUrls: ['./doug-ar-business-card-stack-content.component.scss'],
  providers: [DougArBusinessCardStackContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DougArBusinessCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public override viewModel: DougArBusinessCardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

}
