import { Type } from '@angular/core';
import { MenuHeaderPreRollComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-pre-roll/menu-header-pre-roll.component';
import { ColWidth } from '../../shared/col-width';
import { SectionColumnProductInfoType, SectionColumnType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { PrintMenu } from '../print-menu';
import { PrintHeaderLayoutType } from '../../enum/shared/print-header-layout-type.enum';
import type { SectionWithProducts } from '../section/section-with-products';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { Section } from '../section/section';
import { ProductMenuFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/product-menu-footer.component';
import { BadgeLegendFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/badge-legend-footer/badge-legend-footer.component';
import { PrintFooterLayoutType } from '../../enum/shared/print-footer-layout-type.enum';

export class GridLinesMenu extends PrintMenu {

  private DEFAULT_BORDER_COLOR = '#222222';

  getPageBottomMargin(): string {
    return '0.5in';
  }

  getPageTopMargin(): string {
    return '0.5in';
  }

  getPageLeftMargin(): string {
    return '0.5in';
  }

  getPageRightMargin(): string {
    return '0.5in';
  }

  getThemeClass(): string {
    return 'grid-lines-theme';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderPreRollComponent;
  }

  getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.FirstPage;
  }

  getSectionWrapperClass(...args: any[]): string {
    return 'black-background';
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getMakeHeaderSectionImageFillEntireSection(): boolean {
    return true;
  }

  getShowProductSubtitle(): boolean {
    return false;
  }

  getShowStrainTypes(): boolean {
    return false;
  }

  getShowInlineLabels(): boolean {
    return true;
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getFontFaceList(): string[] {
    return ['arial'];
  }

  getProductWrapperStyling(section: SectionWithProducts, sectionRowViewModel: SectionRowViewModel, odd: boolean) {
    const sectionStyling = super.getProductWrapperStyling(section, sectionRowViewModel, odd);
    sectionStyling['margin-bottom'] = this.getSectionBorderMarginSize();
    return sectionStyling;
  }

  getSectionBorderMarginSize(): string {
    const productZoom = this.menuOptions?.productZoom || 1.0;
    const desiredMarginSizePx = 1.0;
    return `${desiredMarginSizePx / productZoom}px`;
  }

  override getThemeSpecifiedColumnViewModels(
    sectionRowViewModels: SectionRowViewModel[],
    rowViewModel: SectionRowViewModel,
    widths: ColWidth[] = this.getColWidths(rowViewModel)
  ): SectionColumnViewModel[] {
    const vms = super.getThemeSpecifiedColumnViewModels(sectionRowViewModels, rowViewModel, widths);
    return vms?.filter(vm => vm.columnType !== SectionColumnProductInfoType.TinySpacer);
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.ProductTitle, 0],
      [SectionColumnProductInfoType.Brand, 1],
      [SectionColumnProductInfoType.StrainType, 2],
      [SectionColumnProductInfoType.Badge, 3],
      [SectionColumnProductInfoType.QuantityAndSize, 4],
      [SectionColumnProductInfoType.Quantity, 5],
      [SectionColumnProductInfoType.Size, 6],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(7),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(8),
      [SectionColumnProductInfoType.VariantPrice, 9],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 10],
    ]);
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getProductSectionBackgroundColor(section: Section): string {
    return section?.metadata?.productsContainerBackgroundColor ?? this.DEFAULT_BORDER_COLOR;
  }

  getPrintFooterLayoutType(): PrintFooterLayoutType {
    if (this.companyId === 32800658) { // capitalCannabisCompanyId
      return this.menuOptions?.printFooterLayout || PrintFooterLayoutType.AllPagesFixed;
    }
    return super.getPrintFooterLayoutType();
  }

  getFooterType(): Type<ProductMenuFooterComponent> {
    if (this.companyId === 32800658) { // capitalCannabisCompanyId
      return BadgeLegendFooterComponent;
    }
    return super.getFooterType();
  }

}
