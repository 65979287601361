/* src/app/modules/print-cards/components/print-cards/backgrounds/index-cards-3x5/nycd-index-card-stack-background/nycd-index-card-stack-background.component.scss */
:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  padding: 0.25rem;
  box-sizing: border-box;
}
:host .main-content {
  flex: 1;
  border-radius: 0.25rem 0 0 0.25rem;
}
/*# sourceMappingURL=nycd-index-card-stack-background.component.css.map */
