import { Injectable } from '@angular/core';
import { PrintCardContentViewModel } from '../../print-card-content-view-model';
import { map } from 'rxjs/operators';
import { StringUtils } from '../../../../../../../utils/string-utils';
import { Observable } from 'rxjs';
import { FikaBeverageCardData } from '../../../../../../../models/print-cards/fika-beverage-card-data';

@Injectable()
export class FikaBeverageAddressCardStackContentViewModel extends PrintCardContentViewModel {

  public readonly cardData$: Observable<FikaBeverageCardData>;

  public override virtualLabelText$ = this._virtualLabelText.pipe(
    map(virtualLabelText => {
      const compare = StringUtils.removeWhiteSpace(virtualLabelText?.toLowerCase());
      if (compare?.includes('bigdeal') || compare?.includes('lastchance')) return virtualLabelText;
      return 'NOW';
    })
  );

}
