import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import type { SectionColumnViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import type { SectionRowViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';

@Pipe({
  name: 'getGridVariantQuantity'
})
export class GetGridVariantQuantityPipe implements PipeTransform {

  transform(
    colVM: SectionColumnViewModel,
    rowVM$: Observable<SectionRowViewModel>
  ): Observable<string> {
    return rowVM$.pipe(
      map(rowVM => {
        const variant = rowVM?.getVariantMatch(colVM);
        const quantity = variant?.packagedQuantity ?? 1;
        return quantity.toString();
      })
    );
  }

}
