import { PrintCardMenu } from './print-card-menu';

export class OGKushPrintCardMenu extends PrintCardMenu {

  getFontFaceList(): string[] {
    return ['poppins'];
  }

  shouldStandardizeDominantStrainType() {
    return false;
  }

}
