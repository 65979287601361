import { Injectable } from '@angular/core';
import { PrintCardContentViewModel } from '../../print-card-content-view-model';
import { map } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Injectable()
export class DougPostCardStackContentViewModel extends PrintCardContentViewModel {

  public cardBodyHeight$ = this.isLineItemMode$.pipe(
    map(isLineItemMode => isLineItemMode ? '19.375rem' : '15rem')
  );

  public gridHas3Columns$ = this.cardData$.pipe(
    map(cardData => cardData?.rowVariants?.length === 3)
  );

  public readonly rightSideLineItemWidthInRem$ = combineLatest([
    this.isLineItemMode$,
    this.cardData$
  ]).pipe(
    map(([isLineItemMode, cardData]) => {
      if (!isLineItemMode) return null;
      return cardData?.displayCannabinoidInRanges() ? 11.625 : 8.625;
    })
  );

  public readonly leftSideLineItemWidthInRem$ = this.rightSideLineItemWidthInRem$.pipe(
    map(rightSideLineItemWidthInRem => {
      const totalContentAreaWidthInRem = 34;
      const spaceBetweenLeftAndRightContentInRem = 1;
      return Number.isFinite(rightSideLineItemWidthInRem)
        ? (totalContentAreaWidthInRem - spaceBetweenLeftAndRightContentInRem - rightSideLineItemWidthInRem)
        : null;
    })
  );

}
