import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Label } from '../models/menu/labels/label';
import { map, shareReplay, tap } from 'rxjs/operators';
import { SortUtils } from '../utils/sort-utils';
import { MenuAPI } from '../api/menu-api';
import { BaseDomainModel } from '../models/base/base-domain-model';
import { LoggingService } from '../services/logging-service';
import { Injectable } from '@angular/core';
import { SystemLabel } from '../models/menu/labels/system-label';
import { FeaturedSystemLabel } from '../models/menu/labels/featured-system-label';
import { LowStockSystemLabel } from '../models/menu/labels/low-stock-system-label';
import { RestockSystemLabel } from '../models/menu/labels/restock-system-label';
import { SaleSystemLabel } from '../models/menu/labels/sale-system-label';
import { NewSystemLabel } from '../models/menu/labels/new-system-label';
import { LocationDomainModel } from './location-domain-model';

@Injectable({ providedIn: 'root' })
export class LabelDomainModel extends BaseDomainModel {

  constructor(
    public locationDomainModel: LocationDomainModel,
    private logging: LoggingService,
    private menuAPI: MenuAPI
  ) {
    super();
  }

  private readonly locationConfig$ = this.locationDomainModel.locationConfig$;

  private readonly _labels = new BehaviorSubject<Label[]|null>(null);
  public readonly labels$ = this._labels.pipe(
    map(labels => labels?.sort(SortUtils.sortLabelsByPriority)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly locationLabels$ = combineLatest([
    this.labels$,
    this.locationConfig$.pipe(map(locationConfig => locationConfig?.locationId))
  ]).pipe(
    map(([labels, locationId]) => labels?.filter(label => label?.locationId === locationId)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly posLabels$ = this.labels$.pipe(
    map(labels => labels?.filter(label => label?.isPOSManaged)),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly systemLabels$ = this.labels$.pipe(
    map(labels => {
      const isSaleSystemLabel = (l: SystemLabel): l is SystemLabel => l instanceof SystemLabel;
      return labels?.filter(isSaleSystemLabel);
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  private readonly dataForFindingSystemLabels$ = combineLatest([
    this.systemLabels$,
    this.locationConfig$.pipe(map(lc => lc?.locationId))
  ]).pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly featuredSystemLabel$ = this.dataForFindingSystemLabels$.pipe(
    map(([systemLabels, locationId]) => {
      const isFeatured = (l: Label): l is FeaturedSystemLabel => l instanceof FeaturedSystemLabel;
      const featuredLabels = systemLabels?.filter(isFeatured);
      return featuredLabels?.find(l => l?.locationId === locationId) || featuredLabels?.firstOrNull();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly lowStockSystemLabel$ = this.dataForFindingSystemLabels$.pipe(
    map(([systemLabels, locationId]) => {
      const isLowStockSystemLabel = (l: Label): l is LowStockSystemLabel => l instanceof LowStockSystemLabel;
      const lowStockSystemLabels = systemLabels?.filter(isLowStockSystemLabel);
      return lowStockSystemLabels?.find(l => l?.locationId === locationId) || lowStockSystemLabels?.firstOrNull();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly restockSystemLabel$ = this.dataForFindingSystemLabels$.pipe(
    map(([systemLabels, locationId]) => {
      const isRestockSystemLabel = (l: Label): l is RestockSystemLabel => l instanceof RestockSystemLabel;
      const restockSystemLabels = systemLabels?.filter(isRestockSystemLabel);
      return restockSystemLabels?.find(l => l?.locationId === locationId) || restockSystemLabels?.firstOrNull();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly saleSystemLabel$ = this.dataForFindingSystemLabels$.pipe(
    map(([systemLabels, locationId]) => {
      const isSaleSystemLabel = (l: Label): l is SaleSystemLabel => l instanceof SaleSystemLabel;
      const saleLabels = systemLabels?.filter(isSaleSystemLabel);
      return saleLabels?.find(l => l?.locationId === locationId) || saleLabels?.firstOrNull();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public readonly newSystemLabel$ = this.dataForFindingSystemLabels$.pipe(
    map(([systemLabels, locationId]) => {
      const isNewSystemLabel = (l: Label): l is NewSystemLabel => l instanceof NewSystemLabel;
      const newSystemLabels = systemLabels?.filter(isNewSystemLabel);
      return newSystemLabels?.find(l => l?.locationId === locationId) || newSystemLabels?.firstOrNull();
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public getLabels(locationId: number, companyId: number): Observable<Label[]> {
    return this.menuAPI.GetLabels(locationId, companyId).pipe(
      tap(labels => {
        this.logging.loadedLabels(locationId);
        this._labels.next(labels);
      })
    );
  }

  public labelDataSentFromDashboardApp(labels: Label[]): void {
    this._labels.next(labels);
  }

}
