import { Injectable } from '@angular/core';
import { BaseViewModel } from '../../../models/base/base-view-model';
import { exists } from '../../../functions/exists';
import { combineLatest } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PrintCardDomainModel } from '../../../domain/print-card-domain-model';

@Injectable()
export abstract class RenderContentViewModel extends BaseViewModel {

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected printCardDomainModel: PrintCardDomainModel
  ) {
    super();
  }

  public readonly companyConfig$ = this.printCardDomainModel.companyConfig$;
  public readonly locationId$ = this.printCardDomainModel.locationId$;
  public readonly locationConfig$ = this.printCardDomainModel.locationConfig$;
  public readonly printCardMenu$ = this.printCardDomainModel.printCardMenu$;
  public readonly cardStack$ = this.printCardDomainModel.cardStack$;
  public readonly overrideVariantIds$ = this.printCardDomainModel.overrideVariantIds$;
  public readonly overrideSiblingVariantIds$ = this.printCardDomainModel.overrideSiblingVariantIds$;
  public readonly variantCardCountMap$ = this.printCardDomainModel.variantCardCountMap$;

  /* *************************** Local Threads of Execution *************************** */

  private sendUrlParamsToDomainModel = combineLatest([
    this.activatedRoute.url,
    this.activatedRoute.params,
    this.activatedRoute.queryParams
  ]).subscribeWhileAlive({
    owner: this,
    next: ([urlSegments, params, queryParams]) => {
      if (exists(params?.locationId)) {
        this.printCardDomainModel.connectToLocationId(params.locationId);
      }
      const isPreview = urlSegments?.some(seg => seg.path === 'preview');
      if (exists(params?.printCardMenuId)) {
        if (isPreview) {
          this.printCardDomainModel.connectToPartialPrintCardMenuId(params.printCardMenuId);
        } else {
          this.printCardDomainModel.connectToPrintCardMenuId(params.printCardMenuId);
        }
      }
      if (exists(params?.printCardMenuTemplateId)) {
        if (isPreview) {
          this.printCardDomainModel.connectToPartialPrintCardMenuTemplateId(params.printCardMenuTemplateId);
        } else {
          this.printCardDomainModel.connectToPrintCardMenuTemplateId(params.printCardMenuTemplateId);
        }
      }
      if (exists(queryParams?.variantIds)) {
        // VariantIds will always be specified for building a card-stack PDF
        const variantIds = queryParams?.variantIds?.split(',') ?? [];
        this.printCardDomainModel.connectToOverrideVariantIds(variantIds);
      }
      if (exists(queryParams?.siblingVariantIds)) {
        const siblingVariantIds = queryParams?.siblingVariantIds?.split(',') ?? [];
        this.printCardDomainModel.connectToOverrideSiblingVariantIds(siblingVariantIds);
      }
      if (exists(queryParams?.variantCardCountMap)) {
        try {
          const variantCardCountMap = JSON.parse(queryParams?.variantCardCountMap);
          this.printCardDomainModel.connectToVariantCardCountMap(variantCardCountMap);
        } catch (e) {
          console.error('Failed to parse variantCardCountMap', e);
        }
      }
    }
  });

  /* ********************************************************************************** */

}
