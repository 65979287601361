import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { FireAndFlowerCardStackContentViewModel } from './fire-and-flower-card-stack-content-view-model';
import { PrintCardContentComponent } from '../../print-card-content.component';

@Component({
  selector: 'app-fire-and-flower-card-stack-content',
  templateUrl: './fire-and-flower-card-stack-content.component.html',
  styleUrls: ['./fire-and-flower-card-stack-content.component.scss'],
  providers: [FireAndFlowerCardStackContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireAndFlowerCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public override viewModel: FireAndFlowerCardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

}
