import { Component } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../product-menu-header.component';

@Component({
  selector: 'app-menu-header-centered-logo-right-desc-and-image',
  templateUrl: './menu-header-centered-logo-right-desc-and-image.component.html',
  styleUrls: ['./menu-header-centered-logo-right-desc-and-image.component.scss'],
  providers: [{ provide: ProductMenuHeaderComponent, useExisting: MenuHeaderCenteredLogoRightDescAndImageComponent }]
})
export class MenuHeaderCenteredLogoRightDescAndImageComponent extends ProductMenuHeaderComponent {
}
