<ng-container
  *ngIf="{
    isVariantPriceDiscounted:
      menu
      | isVariantPriceDiscounted
        : locConfig
        : locationId
        : section
        : rowVm
        : colVm
        : overridePriceStream
        : checkForPriceChange
  } as saleHelper">
  <ng-container
    *ngIf="
      !showNgContentContainerIfNoDiscount ||
        (showNgContentContainerIfNoDiscount && saleHelper?.isVariantPriceDiscounted);
      else contents
    ">
    <div
      *ngIf="
        colVm.columnType === Types.SectionColumnProductInfoType.VariantPrice &&
        section?.metadata?.hidePrices === 'false'
      "
      class="product-column-general product-variant-price"
      [class.product-variant-sale-price]="saleHelper?.isVariantPriceDiscounted"
      [style.visibility]="section?.collapseContent || rowVm?.collapseContent ? 'collapse' : ''"
      [style.text-decoration]="productStylingVm | forcedColumnTextDecoration: colVm : locConfig | async"
      [style.margin-right]="menu | productMenuBasicMethod: 'getSectionBorderMarginSize'">
      <ng-template #stackedOriginalPriceTemplate>
        <div
          *ngIf="
            !hidePreviousPrice &&
            saleHelper?.isVariantPriceDiscounted &&
            !!(
              menu
              | getOriginalVariantPricing: locConfig : locationId : section : rowVm : colVm : null : overridePriceStream
            )
          "
          class="product-variant-previous-price">
          <span
            *ngIf="leadingTextOriginalPriceWithSale"
            class="leading-text-original-price-with-sale"
            [style.text-decoration]="includeLineThroughOnLeadingTextOriginalPriceWithSale ? 'line-through' : null"
            [innerHTML]="leadingTextOriginalPriceWithSale">
          </span>
          <span class="product-variant-price-integer" [style.text-decoration]="'line-through'">{{
            menu
              | getOriginalPriceInteger: locConfig : locationId : section : rowVm : colVm : null : overridePriceStream
              | modifyFinalPriceString: finalPriceStringModifier
          }}</span>
          <span class="product-variant-price-decoration" [style.text-decoration]="'line-through'">{{
            menu
              | getOriginalPriceDecimal: locConfig : locationId : section : rowVm : colVm : null : overridePriceStream
          }}</span>
        </div>
      </ng-template>
      <ng-container
        *ngIf="
          !specificPriceWithoutDiscounts &&
            !salePriceOnly &&
            (menu | primaryPriceColumnAlsoShowOriginalPriceIfOnSale: section) &&
            (menu | productMenuBasicMethod: 'primaryPriceColumnAlsoShowOriginalPriceIfOnSalePosition') ===
              Types.Position.Top;
          then stackedOriginalPriceTemplate
        ">
      </ng-container>
      <div
        *ngIf="!specificPriceWithoutDiscounts && !salePriceOnly"
        class="product-variant-current-price"
        [class.and-has-previous-price]="saleHelper?.isVariantPriceDiscounted"
        [style.color]="
          ignoreStylingForSaleText && saleHelper?.isVariantPriceDiscounted
            ? null
            : (productStylingVm
              | forcedPriceTextColorIncludingSaleConfiguration: colVm : locConfig : false : overridePriceStream
              | async)
        "
        [style.text-decoration-color]="
          ignoreStylingForSaleText && saleHelper?.isVariantPriceDiscounted
            ? null
            : (productStylingVm
              | forcedPriceTextColorIncludingSaleConfiguration: colVm : locConfig : false : overridePriceStream
              | async)
        "
        [style.text-decoration]="
          ignoreStylingForSaleText && saleHelper?.isVariantPriceDiscounted
            ? null
            : (productStylingVm
              | forcedPriceColumnTextDecorationIncludingSaleConfiguration
                : colVm
                : locConfig
                : false
                : overridePriceStream
              | async)
        "
        [class.force-bold-style]="
          ignoreStylingForSaleText && saleHelper?.isVariantPriceDiscounted
            ? null
            : (productStylingVm
              | forceColumnBoldStylingIncludingSalePriceConfiguration: colVm : locConfig : false : overridePriceStream
              | async)
        "
        [class.force-italic-style]="
          ignoreStylingForSaleText && saleHelper?.isVariantPriceDiscounted
            ? null
            : (productStylingVm
              | forceColumnItalicStylingIncludingSalePriceConfiguration: colVm : locConfig : false : overridePriceStream
              | async)
        ">
        <span
          *ngIf="leadingTextOriginalPriceWithoutSale && !saleHelper?.isVariantPriceDiscounted"
          class="leading-text-current-price without-sale">
          {{ leadingTextOriginalPriceWithoutSale }}
        </span>
        <span
          *ngIf="leadingTextSalePrice && saleHelper?.isVariantPriceDiscounted"
          class="leading-text-current-price with-sale">
          {{ leadingTextSalePrice }}
        </span>
        <span class="product-variant-price-integer">{{
          menu
            | getPriceInteger
              : overridePriceStream || locConfig?.priceFormat
              : locationId
              : section
              : rowVm
              : colVm
              : checkForPriceChange
            | modifyFinalPriceString: finalPriceStringModifier
        }}</span>
        <span class="product-variant-price-decoration">{{
          menu
            | getPriceDecimal
              : overridePriceStream || locConfig?.priceFormat
              : locationId
              : section
              : rowVm
              : colVm
              : checkForPriceChange
        }}</span>
      </div>
      <ng-container
        *ngIf="
          !specificPriceWithoutDiscounts &&
            !salePriceOnly &&
            (menu | primaryPriceColumnAlsoShowOriginalPriceIfOnSale: section) &&
            (menu | productMenuBasicMethod: 'primaryPriceColumnAlsoShowOriginalPriceIfOnSalePosition') ===
              Types.Position.Bottom;
          then stackedOriginalPriceTemplate
        ">
      </ng-container>
      <ng-container *ngIf="specificPriceWithoutDiscounts">
        <div class="product-variant-current-price">
          <span
            *ngIf="leadingTextOriginalPriceWithSale"
            class="leading-text-original-price-with-sale"
            [innerHTML]="leadingTextOriginalPriceWithSale">
          </span>
          <span class="product-variant-price-integer">{{
            menu
              | getOriginalPriceInteger
                : locConfig
                : locationId
                : section
                : rowVm
                : colVm
                : specificPriceWithoutDiscounts
                : overridePriceStream
              | modifyFinalPriceString: finalPriceStringModifier
          }}</span>
          <span class="product-variant-price-decoration">{{
            menu
              | getOriginalPriceDecimal
                : locConfig
                : locationId
                : section
                : rowVm
                : colVm
                : specificPriceWithoutDiscounts
                : overridePriceStream
          }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="salePriceOnly && saleHelper?.isVariantPriceDiscounted">
        <div
          class="product-variant-current-price"
          [style.color]="
            ignoreStylingForSaleText
              ? null
              : (productStylingVm
                | forcedPriceTextColorIncludingSaleConfiguration: colVm : locConfig : false : overridePriceStream
                | async)
          "
          [style.text-decoration-color]="
            ignoreStylingForSaleText
              ? null
              : (productStylingVm
                | forcedPriceTextColorIncludingSaleConfiguration: colVm : locConfig : false : overridePriceStream
                | async)
          "
          [style.text-decoration]="
            ignoreStylingForSaleText
              ? null
              : (productStylingVm
                | forcedPriceColumnTextDecorationIncludingSaleConfiguration
                  : colVm
                  : locConfig
                  : false
                  : overridePriceStream
                | async)
          "
          [class.force-bold-style]="
            ignoreStylingForSaleText
              ? null
              : (productStylingVm
                | forceColumnBoldStylingIncludingSalePriceConfiguration: colVm : locConfig : false : overridePriceStream
                | async)
          "
          [class.force-italic-style]="
            ignoreStylingForSaleText
              ? null
              : (productStylingVm
                | forceColumnItalicStylingIncludingSalePriceConfiguration
                  : colVm
                  : locConfig
                  : false
                  : overridePriceStream
                | async)
          ">
          <span
            *ngIf="leadingTextSalePrice"
            class="leading-text-current-price with-sale"
            [innerHTML]="leadingTextSalePrice">
          </span>
          <span class="product-variant-price-integer">{{
            menu
              | getPriceInteger
                : overridePriceStream || locConfig?.priceFormat
                : locationId
                : section
                : rowVm
                : colVm
                : checkForPriceChange
              | modifyFinalPriceString: finalPriceStringModifier
          }}</span>
          <span class="product-variant-price-decoration">{{
            menu
              | getPriceDecimal
                : overridePriceStream || locConfig?.priceFormat
                : locationId
                : section
                : rowVm
                : colVm
                : checkForPriceChange
          }}</span>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #contents><ng-content></ng-content></ng-template>
</ng-container>
