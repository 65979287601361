import { SectionRowViewModel } from './SectionRowViewModel';

export class SectionRowViewModelCalyxTrichomes extends SectionRowViewModel {

  protected getThcRowRangeAsString(unitOfMeasureString: string, fixedPoint: number = 2): string {
    return super.getThcRowRangeAsString(unitOfMeasureString, fixedPoint);
  }

  protected getThcAsString(units: string, fixedPoint: number = 2): string {
    return super.getThcAsString(units, fixedPoint);
  }

  protected getCbdRowRangeAsString(unitOfMeasureString: string, fixedPoint: number = 2): string {
    return super.getCbdRowRangeAsString(unitOfMeasureString, fixedPoint);
  }

  protected getCbdAsString(units: string, fixedPoint: number = 2): string {
    return super.getCbdAsString(units, fixedPoint);
  }

}
