import { Deserializable } from '../protocols/deserializable';
import { Cachable } from '../protocols/cachable';
import { Asset } from '../image/dto/asset';
import { MenuType } from '../enum/dto/menu-type.enum';
import { MarketingThemeId, MenuThemeId } from '../enum/dto/theme.enum';
import { MenuStyle } from './dto/menu-style';
import { Size } from '../shared/size';
import { DisplayOptions } from '../shared/display-options';
import { DateUtils } from '../../utils/date-utils';
import { Section } from './section/section';
import { DisplayableMenu } from '../protocols/displayable-menu';
import { MenuOptions } from '../shared/menu-options';
import { Orientation } from '../enum/dto/orientation.enum';
import { OverflowState } from '../enum/shared/overflow-transition-state.enum';
import { Theme } from './dto/theme';
import { ColorUtils } from '../../utils/color-utils';
import { OpacityLocation } from '../shared/opacity-location.enum';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import { MenuMetadata } from './dto/menu-metadata';
import { PolymorphicDeserializationKey } from '../enum/shared/polymorphic-deserialization-key.enum';
import { SortUtils } from '../../utils/sort-utils';
import { exists } from '../../functions/exists';
import { SectionUtils } from '../../utils/section-utils';
import type { CompanyConfiguration } from '../company/dto/company-configuration';
import type { LocationConfiguration } from '../company/dto/location-configuration';
import type { TemplateStatus } from './template-status.enum';
import type { TimeDurationUnixSeconds } from '../shared/time-duration-unix-seconds';

/**
 * As new menus are extended by menu, add them to DeserializeMenuHelper.
 */
export class Menu implements DisplayableMenu, Deserializable, Cachable, UniquelyIdentifiable {

  static defaultRotationInterval = 20;

  public id: string;
  public name: string = '';
  public companyId: number;
  public locationId: number;
  public companyLogo: Asset;
  public backgroundImage: Asset;
  public type: MenuType = MenuType.DisplayMenu;
  public theme: MenuThemeId = MarketingThemeId.MarketingLoop;
  public sections: Section[] = [];
  public styling: MenuStyle[];
  public displaySize: Size;
  public configurationTitle: string;
  public subTitle: string;
  public options: DisplayOptions = new DisplayOptions();
  public menuOptions: MenuOptions;
  public overflowState: OverflowState = OverflowState.NONE;
  public hydratedTheme: Theme;
  public metadata: MenuMetadata;
  public pagingKey: string;

  // Templated Menu
  public templateId?: string;
  public template?: Menu;

  // If Menu Template
  public activeLocationIds: number[];
  public requiredLocationIds: number[];
  public status: TemplateStatus;
  public templateSections: Section[];

  // Not explicitly returned from api, but set on deserialization
  public intervalDecoded: boolean = false;
  /** this is the interval set by the user, but the actual rotation interval can be larger depending on overflow. */
  public originalRotationInterval: number = Menu.defaultRotationInterval;
  public rotationInterval: number = Menu.defaultRotationInterval;
  public overrideTime: TimeDurationUnixSeconds;
  public position: number = 0;
  // Unique Identifier
  public uniqueIdentifier: string = '';
  // Cache
  public cachedTime: number;

  static buildArrayCacheKey(locationId: number): string {
    return `Menus-${locationId}`;
  }

  static buildCacheKey(locationId: number, id: string): string {
    return `Menu-${locationId}-${id}`;
  }

  public getPolymorphicDeserializationKey(): PolymorphicDeserializationKey {
    return PolymorphicDeserializationKey.Menu;
  }

  /**
   * Add template pointers before deserializing, so data is available upon deserialization.
   */
  public onDeserialize() {
    if (!!this.templateId && !!this.template) this.addTemplatePointersToTemplatedSection();
    this.createLocalObjectReferences();
    this.reshapeIncomingData();
  }

  protected createLocalObjectReferences(): void {
    const Deserialize = window?.injector?.Deserialize;
    this.companyLogo = Deserialize?.instanceOf(Asset, this.companyLogo);
    this.backgroundImage = Deserialize?.instanceOf(Asset, this.backgroundImage);
    this.styling = Deserialize?.arrayOf(MenuStyle, this.styling);
    this.deserializeDisplaySize();
    this.options = Deserialize?.instanceOf(DisplayOptions, this.options);
    this.menuOptions = Deserialize?.instanceOf(MenuOptions, this.menuOptions);
    this.hydratedTheme = Deserialize?.instanceOf(Theme, this.hydratedTheme);
    this.originalRotationInterval = Menu.defaultRotationInterval;
    this.rotationInterval = Menu.defaultRotationInterval;
    this.template = Deserialize?.instanceOf(Menu, this.template);
    this.deserializeSections();
    this.deserializeTemplateSections();
  }

  protected deserializeDisplaySize(): void {
    this.displaySize = window?.injector?.Deserialize?.instanceOf(Size, this.displaySize);
  }

  protected deserializeSections(): void {
    this.sections = window?.injector?.Deserialize?.arrayOf(Section, this.sections) ?? [];
  }

  protected deserializeTemplateSections(): void {
    this.templateSections = window?.injector?.Deserialize?.arrayOf(Section, this.templateSections);
  }

  protected reshapeIncomingData(): void {
    if (this.isTemplate()) this.makeTemplateViewable();
    if (this.isTemplatedMenu() && exists(this.template)) this.combineWithTemplateData(this.template);
    this.sortMenuSectionsByPriority();
    this.mergeThemeColumnConfigIntoSectionConfigs();
  }

  public addTemplatePointersToTemplatedSection() {
    // Section.TemplateSection is de-duped from responses that also return the Template object
    Section.setTemplatePointers(this);
  }

  protected sortMenuSectionsByPriority(): void {
    this.sections = this.sections?.sort(SortUtils.sortSectionsByPriority);
    this.templateSections = this.templateSections?.sort(SortUtils.sortSectionsByPriority);
  }

  protected mergeThemeColumnConfigIntoSectionConfigs(): void {
    this.sections?.forEach(section => {
      if (SectionUtils.isSectionWithProducts(section)) {
        section?.mergeColumnConfigWithThemeColumnConfig(this.hydratedTheme?.sectionColumnConfig);
      }
    });
  }

  protected makeTemplateViewable(): void {
    this.templateSections?.forEach(template => {
      const existingSection = this.sections?.find(regular => regular?.templateSectionId === template?.id);
      existingSection
        ? existingSection.combineSectionWithTemplateData(template)
        : this.sections.push(template);
    });
  }

  /**
   * Menu styling takes precedence over template styling.
   */
  protected combineProductStylingWithTemplateStyling(menuTemplate: Menu): void {
    const templateStyling = window.injector.Deserialize.arrayOf(MenuStyle, menuTemplate?.styling) ?? [];
    this.styling = this.styling ?? [];
    templateStyling?.forEach(templateStyle => {
      const overrideExists = this.styling?.find(menuStyle => {
        const projectedSectionId = templateStyle?.templateStyleTargetsWhichTemplatedSectionId(this.sections);
        const sameSection = menuStyle?.sectionId === projectedSectionId;
        const sameObjectId = menuStyle?.objectId === templateStyle?.objectId;
        return sameSection && sameObjectId;
      });
      if (!overrideExists) {
        templateStyle.configurationId = this.id;
        templateStyle.sectionId = templateStyle?.templateStyleTargetsWhichTemplatedSectionId(this.sections);
        this.styling?.push(templateStyle);
      }
    });
  }

  protected combineBackgroundImageWithTemplate(menuTemplate: Menu): void {
    if (!!menuTemplate?.backgroundImage) this.backgroundImage = menuTemplate?.backgroundImage;
  }

  protected combineCompanyLogoWithTemplate(menuTemplate: Menu): void {
    if (!!menuTemplate.companyLogo) this.companyLogo = menuTemplate.companyLogo;
  }

  /**
   * The concept of template sections disappears after deserialization when reshapeIncomingData is called.
   * The data gets merged into the regular section object, so the template section property can safely be deleted.
   */
  protected combineSectionTemplatesWithSections(): void {
    this.sections?.forEach(section => {
      section?.combineSectionWithTemplateData(section?.templateSection);
      delete section?.templateSection;
    });
  }

  protected combineMenuMetadataWithTemplate(menuTemplate: Menu): void {
    this.metadata = this.metadata || MenuMetadata.empty();
    Object.keys(menuTemplate?.metadata || {})?.forEach(key => {
      this.metadata[key] = this.metadata[key] || menuTemplate?.metadata[key];
    });
  }

  protected combineMenuOptionsWithTemplate(menuTemplate: Menu): void {
    this.menuOptions = this.menuOptions || MenuOptions.empty();
    Object.keys(menuTemplate?.menuOptions || {})?.forEach(key => {
      this.menuOptions[key] = this.menuOptions[key] || menuTemplate?.menuOptions[key];
    });
  }

  protected combineMenuDisplayOptionsWithTemplate(menuTemplate: Menu): void {
    this.options = this.options || DisplayOptions.empty();
    Object.keys(menuTemplate?.options || {})?.forEach(key => {
      const templateDataField = menuTemplate?.options[key];
      if (templateDataField instanceof Map) {
        this.options[key] = this.options[key] || new Map();
        templateDataField?.forEach((value, subKey) => {
          if (this.options[key].has(subKey)) return;
          this.options[key].set(subKey, value);
        });
      } else {
        this.options[key] = this.options[key] || menuTemplate?.options[key];
      }
    });
  }

  protected combineMenuDisplaySizeWithTemplate(menuTemplate: Menu): void {
    this.displaySize = this.displaySize || Size.empty();
    Object.keys(menuTemplate?.displaySize || {})?.forEach(key => {
      this.displaySize[key] = this.displaySize[key] || menuTemplate?.displaySize[key];
    });
  }

  public combineWithTemplateData(menuTemplate: Menu): void {
    if (!menuTemplate) return;
    const shortCircuitedProperties = this.getCombineWithTemplateDataShortCircuitedProperties();
    shortCircuitedProperties?.forEach(key => this[key] = this[key] || menuTemplate?.[key]);
    this.mergeAdvancedDataPropertiesFromMenuTemplate(menuTemplate);
  }

  protected getCombineWithTemplateDataShortCircuitedProperties(): string[] {
    const ignore = [
      'backgroundImage', 'companyLogo',
      'styling',
      'sections', 'templateSections',
      'templateId', 'template', 'status',
      'activeLocationIds', 'requiredLocationIds',
      'originalRotationInterval', 'rotationInterval', 'overrideTime', 'position',
      'uniqueIdentifier', 'cachedTime',
      'metadata', 'menuOptions', 'options', 'displaySize',
    ];
    return Object.keys(this)?.filter(key => !ignore.includes(key));
  }

  protected mergeAdvancedDataPropertiesFromMenuTemplate(menuTemplate: Menu): void {
    this.combineBackgroundImageWithTemplate(menuTemplate);
    this.combineCompanyLogoWithTemplate(menuTemplate);
    this.combineSectionTemplatesWithSections();
    this.combineProductStylingWithTemplateStyling(menuTemplate);
    this.combineMenuMetadataWithTemplate(menuTemplate);
    this.combineMenuOptionsWithTemplate(menuTemplate);
    this.combineMenuDisplayOptionsWithTemplate(menuTemplate);
    this.combineMenuDisplaySizeWithTemplate(menuTemplate);
  }

  isTemplateActiveAtLocation(locationId: number): boolean {
    return this.activeLocationIds?.includes(locationId) || this.template?.activeLocationIds?.includes(locationId);
  }

  /**
   * If there are collections added to a display, then the menu will have its id transformed
   * to include the collection id.
   * menuId: "collectionId:XXXX-XXXX-XXXX-XXXX menuId:YYYY-YYYY-YYYY-YYYY"
   *
   * @returns the original menuId YYYY-YYYY-YYYY-YYYY
   */
  getOriginalMenuId(): string {
    const regex = /[mM]enuId:(\S*)/;
    const matches = this.id.match(regex);
    return matches?.[1] ?? this.id;
  }

  /**
   * If there are collections added to a display, then the menu will have its id transformed
   * to include the collection id.
   * menuId: "collectionId:XXXX-XXXX-XXXX-XXXX menuId:YYYY-YYYY-YYYY-YYYY"
   *
   * @returns the collectionId XXXX-XXXX-XXXX-XXXX if it exists, else null
   */
  getCollectionId(): string {
    const regex = /[cC]ollectionId:(\S*)/;
    const matches = this.id.match(regex);
    return matches?.[1] ?? null;
  }

  setMenuIntervalFromDisplay(n: number): void {
    this.originalRotationInterval = n;
    this.rotationInterval = n;
  }

  isLandscape(): boolean {
    return this.displaySize.orientation === Orientation.Landscape;
  }

  isPortrait(): boolean {
    return (this.displaySize.orientation === Orientation.Portrait)
      || (this.displaySize.orientation === Orientation.ReversePortrait);
  }

  getStyles(): MenuStyle[] {
    return this.styling || [];
  }

  isActiveIntervalMenu(timezone?: string): boolean {
    return this.overrideTime?.isActive(timezone) || false;
  }

  updateRotationIntervalInSeconds(n: number) {
    this.rotationInterval = n;
  }

  hasBackgroundAsset(): boolean {
    const hasImage = !!this.backgroundImage;
    const changeBackgroundEnabled = !!this.hydratedTheme?.themeFeatures?.backgroundMedia;
    return hasImage && changeBackgroundEnabled;
  }

  isTemplate(): boolean {
    return !!this.status;
  }

  isTemplatedMenu(): boolean {
    return !!this.templateId;
  }

  /** ******************* Data for displaying menu *********************** */

  getOpacityEnabled(): boolean {
    return this.hydratedTheme?.themeFeatures?.backgroundOpacity;
  }

  getOpacityLocation(): OpacityLocation {
    return OpacityLocation.BACKGROUND_ASSET;
  }

  getBackgroundAssetWrapperClass(): string {
    return '';
  }

  shutOffBackgroundAsset(): boolean {
    return false;
  }

  getDefaultBackgroundImageUrl(): string {
    return null;
  }

  getDefaultBackgroundVideoUrl(): string {
    return null;
  }

  getMenuClass(): string {
    return '';
  }

  getDisplayPadding(): string {
    return '';
  }

  showCompanyLogo(): boolean {
    return this.companyLogo && !this.menuOptions?.hideLogo;
  }

  getHeaderLogoMargin(): string {
    return null;
  }

  centerMenuTitleWhenLogoIsHidden(): boolean {
    return false;
  }

  shouldFetchVariantAssets(): boolean {
    return false;
  }

  /**
   * By default, do not specify. The API will return all types and use fallback default sorting.
   * By not specifying, this will allow for untyped assets to be returned. If values are provided,
   * any untyped assets will be excluded.
   */
  variantAssetTypeSortOrder(): string[] {
    return null;
  }

  variantAssetItemCount(): number {
    return 1;
  }

  /** ***************************************************************** */

  getBackgroundColorWithOpacity(defaultBackgroundColor = [255, 255, 255], defaultOpacity = 1): string {
    if (!this.menuOptions.bodyBackgroundColor) {
      const [defaultR, defaultG, defaultB] = defaultBackgroundColor;
      return `rgba(${defaultR}, ${defaultG}, ${defaultB}, ${defaultOpacity})`;
    }
    const [R, G, B] = ColorUtils.hexToRgb(this.menuOptions.bodyBackgroundColor);
    const opacity = this.menuOptions.backgroundOpacity;
    return `rgba(${R}, ${G}, ${B}, ${opacity})`;
  }

  getColorBackgroundAssetBorderRadius(): string {
    return null;
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneDay();
  }

  cacheKey(): string {
    return Menu.buildCacheKey(this.companyId, this.id);
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.nowInUnixSeconds() > expiresAt;
  }

  setImplicitProperties(companyConfig: CompanyConfiguration, locationConfig: LocationConfiguration): void {
    this.sections?.forEach(section => {
      if (SectionUtils.isSectionWithProducts(section)) {
        section?.products?.forEach(product => {
          product.setImplicitProperties(section?.enabledVariantIds, locationConfig?.secondaryPriceGroupId);
        });
      }
    });
    this.setUniqueIdentifier(companyConfig, locationConfig);
  }

  // Returns a unique string that identifies all menu properties that can be modified

  getUniqueIdentifier(): string {
    return this.uniqueIdentifier;
  }

  setUniqueIdentifier(
    companyConfig: CompanyConfiguration,
    locationConfig: LocationConfiguration,
  ): string {
    const id = this.id;
    const logoId = this.companyLogo?.getUniqueIdentifier() ?? '';
    const backgroundId = this.backgroundImage?.getUniqueIdentifier() ?? '';
    const themeId = this.theme;
    const sectionsId = this.sections
      ?.map(s => s.setUniqueIdentifier(this, companyConfig, locationConfig))
      ?.sort()
      ?.join(',') ?? '';
    const stylingId = this.styling?.map(s => s.getUniqueIdentifier()).sort().join(',') ?? '';
    const titleId = this.configurationTitle ?? '';
    const optionsId = this.options.getUniqueIdentifier() ?? '';
    const menuOptionsId = this.menuOptions?.getUniqueIdentifier() ?? '';
    const overflowId = this.overflowState ?? '';
    const rotationId = this.originalRotationInterval ?? Menu.defaultRotationInterval;
    const activeLocationIds = this.activeLocationIds?.sort()?.join() ?? '';
    const requiredLocationIds = this.requiredLocationIds?.sort()?.join() ?? '';
    const statusId = this.status ?? '';
    const templateSectionsId = this.templateSections?.map(s => s?.getUniqueIdentifier())?.join() ?? '';
    this.uniqueIdentifier = `
      -${id}
      -${logoId}
      -${backgroundId}
      -${themeId}
      -${sectionsId}
      -${stylingId}
      -${titleId}
      -${optionsId}
      -${menuOptionsId}
      -${overflowId}
      -${rotationId}
      -${activeLocationIds}
      -${requiredLocationIds}
      -${statusId}
      -${templateSectionsId}
    `;
    return this.uniqueIdentifier;
  }

}
