import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { RenderContextComponent } from '../../../../../../../models/base/render-context-component';
import type { Section } from '../../../../../../../models/menu/section/section';
import type { SectionRowViewModel } from '../menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { HtmlUtils } from '../../../../../../../utils/html-utils';
import type { Menu } from '../../../../../../../models/menu/menu';
import type { LocationConfiguration } from '../../../../../../../models/company/dto/location-configuration';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
})
export class MenuItemComponent extends RenderContextComponent implements OnChanges {

  @Input() calculationMode: boolean = false;
  @Input() locationConfig: LocationConfiguration;
  @Input() locationId: number;
  @Input() menu: Menu;
  @Input() section: Section;
  @Input() sectionDominantBackgroundColor: string;
  @Input() sectionRowViewModels: SectionRowViewModel[]; // Every line item in the section
  @Input() rowViewModel: SectionRowViewModel;           // This item's row view model
  @Input() odd: boolean;
  @Input() sectionIndex: number;
  @Input() itemIndex: number;
  @Input() reset: boolean;
  @Input() first: boolean;
  @Input() last: boolean;
  @ViewChild('sectionItem') sectionItem: ElementRef;

  setupViews() {
  }

  setupBindings() {
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(changes: SimpleChanges): void {
  }

  getItemHeight(): number {
    return HtmlUtils.getElementTotalHeight(this?.sectionItem?.nativeElement);
  }

}
