import { Component } from '@angular/core';
import { ProductMenuFooterComponent } from '../../product-menu-footer.component';

@Component({
  selector: 'app-double-dutch-empty-footer',
  templateUrl: './double-dutch-empty-footer.component.html',
  styleUrls: [
    '../double-dutch-footer.component.scss',
    './double-dutch-empty-footer.component.scss'
  ]
})
export class DoubleDutchEmptyFooterComponent extends ProductMenuFooterComponent {
}
