import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/menu';
import { exists } from '../../../functions/exists';

@Pipe({
  name: 'hasBackgroundAsset'
})
export class HasBackgroundAssetPipe implements PipeTransform {

  transform(menu: Menu, loaded: boolean): boolean {
    const shutOffBackgroundAsset = menu?.shutOffBackgroundAsset();
    const hasImage = exists(menu?.backgroundImage);
    const thereIsAnImageToShow = hasImage && loaded;
    const changeBackgroundEnabled = exists(menu?.hydratedTheme?.themeFeatures?.backgroundMedia);
    return !shutOffBackgroundAsset && changeBackgroundEnabled && thereIsAnImageToShow;
  }

}
