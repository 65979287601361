import { Component } from '@angular/core';
import { ProductMenuHeaderComponent } from '../product-menu-header.component';
import { of } from 'rxjs';

@Component({
  selector: 'app-menu-header-sprout',
  templateUrl: './menu-header-sprout.component.html',
  styleUrls: ['./menu-header-sprout.component.scss'],
  providers: [{ provide: ProductMenuHeaderComponent, useExisting: MenuHeaderSproutComponent }]
})
export class MenuHeaderSproutComponent extends ProductMenuHeaderComponent {

  public readonly date$ = of(this.getDate());

  private getDate() {
    const date = new Date();
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  }

}
