import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FitTextDirective } from './fit-text.directive';

@NgModule({
  declarations: [FitTextDirective],
  exports: [
    FitTextDirective
  ],
  imports: [
    CommonModule
  ]
})
export class FitTextModule {
}
