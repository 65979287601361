import { ChangeDetectionStrategy, Component, ElementRef, EnvironmentInjector, Injector, Input, OnChanges, SimpleChanges, Type } from '@angular/core';
import { InflatorComponent } from '../../../../../inflator/inflator.component';
import { PrintCardBackgroundComponent } from '../../backgrounds/print-card-background.component';
import { PrintCardInterface } from '../../../../interface/print-card.interface';
import { CardData } from '../../../../../../models/print-cards/card-data';

@Component({
  selector: 'app-print-card-background-inflator',
  templateUrl: './print-card-background-inflator.component.html',
  styleUrls: ['./print-card-background-inflator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintCardBackgroundInflatorComponent extends InflatorComponent implements PrintCardInterface, OnChanges {

  constructor(
    public elementRef: ElementRef,
    injector: Injector,
    environmentInjector: EnvironmentInjector
  ) {
    super(injector, environmentInjector);
  }

  @Input() cardData: CardData;
  @Input() preview: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cardData) this.connectToComponentType(this.getBackgroundType());
    super.ngOnChanges(changes);
  }

  protected getBackgroundType(): Type<PrintCardBackgroundComponent> | null {
    return this.cardData?.section?.getCardBackgroundComponent(this.cardData);
  }

}
