import { Pipe, PipeTransform } from '@angular/core';
import type { Section } from '../../../models/menu/section/section';

@Pipe({
  name: 'sectionBasicMethod'
})
export class SectionBasicMethodPipe implements PipeTransform {

  transform(
    section: Section,
    methodKey: string,
    argument?: any
  ): any {
    return section?.[methodKey]?.(argument);
  }

}
