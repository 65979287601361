<div class="pricing-container" [style.color]="viewModel.bodyTextColor$ | async">
  <app-column-primary-price
    [menu]="viewModel.cardStackMenu$ | async"
    [section]="viewModel.cardStack$ | async"
    [colVm]="viewModel.spoofedColViewModel$ | async"
    [rowVm]="viewModel.cardData$ | async"
    [locConfig]="viewModel.locationConfig$ | async"
    [locationId]="viewModel.locationId$ | async"
    [productStylingVm]="viewModel"
    [overridePriceStream]="viewModel.priceStreamOverride$ | async">
  </app-column-primary-price>

  <div class="pre-tax-container">
    <app-column-primary-price
      [menu]="viewModel.cardStackMenu$ | async"
      [section]="viewModel.cardStack$ | async"
      [colVm]="viewModel.spoofedColViewModel$ | async"
      [rowVm]="viewModel.cardData$ | async"
      [locConfig]="viewModel.locationConfig$ | async"
      [locationId]="viewModel.locationId$ | async"
      [productStylingVm]="viewModel"
      [overridePriceStream]="Types.LocationPriceStream.Default">
    </app-column-primary-price>

    <div
      class="pre-tax-text-container"
      [style.text-decoration]="
        viewModel
          | forcedPriceColumnTextDecorationIncludingSaleConfiguration
            : (viewModel.spoofedColViewModel$ | async)
            : (viewModel.locationConfig$ | async)
            : false
            : Types.LocationPriceStream.Default
          | async
      "
      [style.color]="
        viewModel
          | forcedPriceTextColorIncludingSaleConfiguration
            : (viewModel.spoofedColViewModel$ | async)
            : (viewModel.locationConfig$ | async)
            : false
            : Types.LocationPriceStream.Default
          | async
      "
      [class.force-bold-style]="
        viewModel
          | forceColumnBoldStylingIncludingSalePriceConfiguration
            : (viewModel.spoofedColViewModel$ | async)
            : (viewModel.locationConfig$ | async)
            : false
            : Types.LocationPriceStream.Default
          | async
      "
      [class.force-italic-style]="
        viewModel
          | forceColumnItalicStylingIncludingSalePriceConfiguration
            : (viewModel.spoofedColViewModel$ | async)
            : (viewModel.locationConfig$ | async)
            : false
            : Types.LocationPriceStream.Default
          | async
      ">
      Pre-Tax
    </div>
  </div>
</div>
