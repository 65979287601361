import { Deserializable } from '../protocols/deserializable';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import { PrintHeaderLayoutType } from '../enum/shared/print-header-layout-type.enum';
import { PrintFooterLayoutType } from '../enum/shared/print-footer-layout-type.enum';

export class MenuOptions implements Deserializable, UniquelyIdentifiable {

  // Zoom
  public productZoom: number = 1;
  public titleZoom: number = 1;
  // Hide
  public hideTitle: boolean = false;
  public hideLabel: boolean = false;
  public hidePriceDecimal: boolean = false;
  public hideLogo: boolean = false;
  public hideSale: boolean = false;
  public hideInventoryLabels: boolean = false;
  public hideCannabinoidDecimal: boolean = false;
  public hideDescription: boolean = false;
  // Show
  public showAltLogo: boolean = false;
  public showCUOMInHeader: boolean = false; // this also encapsulates the showTUOMInHeader at the moment
  public showFullDescription: boolean = false;
  // Margins
  public sectionMarginTop: number;
  public sectionMarginBottom: number;
  // Colors
  public blurredBackground: boolean = false;
  public backgroundOpacity: number = 1;
  public titleTextColor: string = '';
  public bodyTextColor: string = '';
  public footerTextColor: string = '';
  public bodyBackgroundColor: string = '';
  // Print Options
  public sectionPageBreak: boolean = false;
  public titleSectionPageBreak: boolean = false;
  public printHeaderLayout: PrintHeaderLayoutType;
  public printFooterLayout: PrintFooterLayoutType;
  // Print Card
  public singlePageMenu: boolean;
  // Marketing Media Options
  public playAudio: boolean;

  static empty(): MenuOptions {
    const empty = new MenuOptions();
    Object.keys(empty)?.forEach(key => empty[key] = undefined);
    return empty;
  }

  onDeserialize() {
  }

  getUniqueIdentifier(): string {
    return `
      -${this.productZoom}
      -${this.titleZoom}
      -${this.hideTitle}
      -${this.hideLabel}
      -${this.hidePriceDecimal}
      -${this.hideLogo}
      -${this.hideSale}
      -${this.hideInventoryLabels}
      -${this.hideCannabinoidDecimal}
      -${this.hideDescription}
      -${this.showAltLogo}
      -${this.showCUOMInHeader}
      -${this.showFullDescription}
      -${this.sectionMarginTop || ''}
      -${this.sectionMarginBottom || ''}
      -${this.blurredBackground}
      -${this.backgroundOpacity}
      -${this.titleTextColor}
      -${this.bodyTextColor}
      -${this.footerTextColor}
      -${this.bodyBackgroundColor}
      -${this.sectionPageBreak}
      -${this.titleSectionPageBreak}
      -${this.printHeaderLayout}
      -${this.printFooterLayout}
    `;
  }

}
