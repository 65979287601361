// noinspection JSUnusedLocalSymbols

import { Directive, SecurityContext } from '@angular/core';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AssetDirective } from './asset.directive';

@Directive({
  selector: '[appVideo]'
})
export class VideoDirective extends AssetDirective {

  protected setupAssetUrlBinding(): void {
    const htmlVideo = this.el.nativeElement as HTMLVideoElement;
    htmlVideo.oncanplaythrough = this.renderSucceeded.bind(this);
    htmlVideo.onerror = this.renderFailed.bind(this);
    this.subject.pipe(distinctUntilChanged(), takeUntil(this.onDestroy)).subscribe(url => {
      const video = this.el.nativeElement as HTMLVideoElement;
      video.src = !!url ? this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, url) : '';
    });
  }

}
