<div
  *ngIf="
    (columnLabel || !(labelComponentInterface | getShutOffLabelForLabelComponent)) &&
    (viewModel?.labelText$ | async) !== ''
  "
  [style.background-color]="viewModel?.labelBackgroundColor$ | async"
  [style.color]="viewModel?.labelTextColor$ | async"
  appFitText
  [parentContainer]="fitTextParentContainer"
  [percentageOfParent]="fitTextPercentageOfParent"
  [fitTextDisabled]="fitTextDisabled"
  class="product-label">
  {{ viewModel?.labelText$ | async }}
</div>
