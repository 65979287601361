import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductMenu } from '../../../../../../../../../models/menu/product-menu';
import { MenuHeaderTitleImageWithBackgroundAssetComponent } from '../../menu-header-title/menu-header-title-image/menu-header-title-image-with-background-asset/menu-header-title-image-with-background-asset.component';
import { MenuHeaderBackgroundAssetComponent } from '../../menu-header-background-asset/menu-header-background-asset.component';

@Component({
  selector: 'app-menu-header-plantlife',
  templateUrl: './menu-header-plantlife.component.html',
  providers: [{ provide: MenuHeaderTitleImageWithBackgroundAssetComponent, useExisting: MenuHeaderPlantlifeComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuHeaderPlantlifeComponent extends MenuHeaderBackgroundAssetComponent {

  @Input() menu: ProductMenu;

}
