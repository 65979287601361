import { Pipe, PipeTransform } from '@angular/core';
import type { DeprecatedMarketingMenu } from '../../../models/menu/deprecated-marketing-menu';

@Pipe({
  name: 'deprecatedMarketingMenuBasicMethod'
})
export class DeprecatedMarketingMenuBasicMethodPipe implements PipeTransform {

  transform(
    productMenu: DeprecatedMarketingMenu,
    methodKey: string,
    argument?: any
  ): any {
    return productMenu?.[methodKey]?.(argument);
  }

}
