import { Injectable } from '@angular/core';
import { PrintCardBackgroundViewModel } from '../../print-cards/backgrounds/print-card-background-view-model';
import { Observable } from 'rxjs';
import { LabelData } from '../../../../../models/print-labels/label-data';

@Injectable()
export abstract class PrintLabelBackgroundViewModel extends PrintCardBackgroundViewModel {

  public override readonly cardData$: Observable<LabelData>;

}
