import { FiveByFiveCardStack } from './five-by-five-card-stack';
import { Type } from '@angular/core';
import { PrintCardBackgroundComponent } from '../../../../../modules/print-cards/components/print-cards/backgrounds/print-card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/print-card-content.component';
import { PopsCannabis5By5CardBackgroundComponent } from '../../../../../modules/print-cards/components/print-cards/backgrounds/custom-cards-5x5/pops-cannabis-5-by5-card-background/pops-cannabis-5-by-5-card-background.component';
import { CardData } from '../../../../print-cards/card-data';
import { PopsCannabis5By5CardStackContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/custom-cards-5x5/pops-cannabis-5-by-5-card-stack-content/pops-cannabis-5-by-5-card-stack-content.component';
import { PopsCannabisCardData } from '../../../../print-cards/pops-cannabis-card-data';

export class PopsCannabis5By5CardStack extends FiveByFiveCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<PrintCardBackgroundComponent> {
    return PopsCannabis5By5CardBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> {
    return PopsCannabis5By5CardStackContentComponent;
  }

  getLeftCutLineText(cardData: PopsCannabisCardData): string | null {
    return cardData?.getLeftLargeText();
  }

}
