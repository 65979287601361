import { Pipe, PipeTransform } from '@angular/core';
import { CardStack } from '../../../models/menu/section/card-stacks/card-stack';

@Pipe({
  name: 'cardContentHeight'
})
export class CardContentHeightPipe implements PipeTransform {

  transform(cardStack: CardStack): number {
    return cardStack?.getCardHeightInPixels() || 0;
  }

}
