import { NgModule } from '@angular/core';
import { ScaleDownTextDirective } from './scale-down-text.directive';

@NgModule({
  declarations: [
    ScaleDownTextDirective
  ],
  exports: [
    ScaleDownTextDirective
  ],
})
export class ScaleDownTextModule {
}
