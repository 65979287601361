import { FireAndFlowerCardData } from './fire-and-flower-card-data';

export class FireAndFlowerSalePriceCardData extends FireAndFlowerCardData {

  public readonly showRegularPriceAtBottom: boolean = true;

  static copyFrom(cardData: FireAndFlowerCardData): FireAndFlowerSalePriceCardData {
    const copy = new FireAndFlowerSalePriceCardData();
    copy?.copyFrom(cardData);
    return copy;
  }

}
