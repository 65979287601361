import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import type { SectionColumnViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import type { ProductStylingViewModel } from '../../../models/shared/product-styling-view-model';

@Pipe({
  name: 'forcedColumnZoom'
})
export class ForcedColumnZoomPipe implements PipeTransform {

  transform(
    productStylingViewModel: ProductStylingViewModel,
    sectionColumnViewModel: SectionColumnViewModel
  ): Observable<number> {
    return productStylingViewModel?.forcedColumnZoom(sectionColumnViewModel) || of(null);
  }

}
