import { SectionRowViewModel } from './SectionRowViewModel';
import { Type } from '@angular/core';
import { MenuItemComponent } from '../../../menu-item/menu-item.component';
import { ProductSectionItemComponent } from '../../../menu-item/product-section-item/product-section-item.component';

export class SectionRowViewModelFeaturedCategory extends SectionRowViewModel {

  itemType: Type<MenuItemComponent> = ProductSectionItemComponent;

}
