import { PrintMenu } from '../print-menu';
import { Type } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { SectionColumnProductInfoType, SectionColumnType, SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { BandedRowMode } from '../../enum/shared/banded-row-mode.enum';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { BandedRowColorContrast } from '../../enum/shared/banded-row-color-contrast.enum';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { PrintHeaderLayoutType } from '../../enum/shared/print-header-layout-type.enum';

export class TrueNorthPrintMenu extends PrintMenu {

  getThemeClass(): string {
    return 'true-north-print-theme';
  }

  getFontFaceList(): string[] {
    return ['montserrat'];
  }

  getPageBottomMargin(): string {
    return '0.25in';
  }

  getPageLeftMargin(): string {
    return '0.25in';
  }

  getPageRightMargin(): string {
    return '0.25in';
  }

  getPageTopMargin(): string {
    return '0.25in';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.FirstPage;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getProductWrapperClass(odd: boolean) {
    return odd ? 'product-row-overlay' : '';
  }

  override getColumnOrdering(): Map<SectionColumnType, number> {
    return new Map<SectionColumnType, number>([
      [SectionColumnProductInfoType.ProductTitle, 0],
      [SectionColumnProductInfoType.StrainType, 1],
      [SectionColumnProductInfoType.Brand, 2],
      [SectionColumnProductInfoType.Badge, 3],
      ...SectionColumnViewModel.getDefaultCannabinoidColumnOrdering(4),
      ...SectionColumnViewModel.getDefaultTerpeneColumnOrdering(5),
      [SectionColumnProductInfoType.QuantityAndSize, 6],
      [SectionColumnProductInfoType.Quantity, 7],
      [SectionColumnProductInfoType.Size, 8],
      [SectionColumnProductInfoType.VariantPrice, 9],
      [SectionColumnProductInfoType.VariantSecondaryPrice, 10],
    ]);
  }

  bandedRowMode(): BandedRowMode {
    return BandedRowMode.Even;
  }

  bandedRowColor(rowViewModel: SectionRowViewModel, odd: boolean): string {
    if (this.currentRowIsBanded(odd)) {
      const opacity = '0.03';
      return `rgba(0, 0, 0, ${opacity})`;
    } else {
      return null;
    }
  }

  bandedRowColorContrast(): BandedRowColorContrast {
    return BandedRowColorContrast.Lighten;
  }

  getShowProductSubtitle(): boolean {
    return false;
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getTitleSectionTopMargin(): string {
    return '1rem';
  }

  getTitleSectionBottomMargin(): string {
    return '0';
  }

}
