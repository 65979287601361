import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VariantAssetService } from '../../services/variant-asset-service';
import { SortUtils } from '../../../utils/sort-utils';
import type { VariantAsset } from '../../../models/image/dto/variant-asset';
import type { SectionRowViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { StiiizyCardData } from '../../../models/print-cards/stiiizy-card-data';

@Pipe({
  name: 'getVariantAssetForCard'
})
export class GetVariantAssetForCardPipe implements PipeTransform {

  constructor(
    protected variantAssetService: VariantAssetService
  ) {
  }

  /**
   * If in line mode:
   *   - get variant asset map for variant id
   *   - sort assets by variantAssetLibrarySort
   *   - return first asset in list
   * If in grid mode:
   *   - get variant asset map for all variant ids in row
   *   - sort all assets by variantAssetLibrarySort
   *   - return first asset in list
   *
   * @param sectionRowViewModel a section row view model representing the variants in the row
   * @return an observable of the variant asset to use for the variant currently being displayed
   */
  transform(sectionRowViewModel: SectionRowViewModel): Observable<VariantAsset> {
    const variants = sectionRowViewModel?.getScopedVisibleVariants() ?? [];
    return this.variantAssetService.variantAssetMap$.pipe(
      map(variantAssetMap => variants?.map(variant => variantAssetMap?.get(variant?.id))?.flatten<VariantAsset[]>()),
      map(variantAssets => {
        const sortOrder = this.getSortOrder(sectionRowViewModel);
        const byAssetOrder = (a: VariantAsset, b: VariantAsset) => SortUtils.variantAssetLibrarySort(a, b, sortOrder);
        return variantAssets?.sort(byAssetOrder);
      }),
      map(sortedVariantAssets => sortedVariantAssets?.firstOrNull())
    );
  }

  private getSortOrder(sectionRowViewModel: SectionRowViewModel): string[] {
    if (sectionRowViewModel instanceof StiiizyCardData) {
      return sectionRowViewModel?.variantAssetTypeSortOrder();
    }
    return sectionRowViewModel?.menu?.variantAssetTypeSortOrder();
  }

}
