import { Pipe, PipeTransform } from '@angular/core';
import { ColorUtils } from '../../../utils/color-utils';

@Pipe({
  name: 'isDarkColor'
})
export class IsDarkColorPipe implements PipeTransform {

  transform(hex: string): boolean {
    return ColorUtils.isDarkColor(hex);
  }

}
