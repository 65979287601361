export enum ColumnWidth {
  Default = 10,
  ThcAndCbdDefault = 20,
  ProducerDefault = 15,
  BrandDefault = 20,
  BadgeDefault = 8,
  CannabinoidDefault = 9,
  TerpeneDefault = 9,
  SpacerDefault = 4,
  TinySpacerDefault = 1,
  VariantPriceDefault = 11,
  SizeDefault = 20,
}
