import { Menu } from '../models/menu/menu';
import { PageFragment } from '../models/shared/page-fragment';
import { MenuToDisplay } from '../models/menu/menu-to-display';
import { Asset } from '../models/image/dto/asset';
import { Variant } from '../models/product/dto/variant';
import { VariantAndAsset } from '../models/menu/marketing/FeaturedProduct/variant-and-asset';
import { Product } from '../models/product/dto/product';
import { UniquelyIdentifiable } from '../models/protocols/uniquely-identifiable';

// @dynamic
export class DistinctUtils {

  public static distinctUniquelyIdentifiable = <T extends UniquelyIdentifiable>(prev: T, curr: T) => {
    return prev?.getUniqueIdentifier() === curr?.getUniqueIdentifier();
  };
  public static distinctUniquelyIdentifiableArray = <T extends UniquelyIdentifiable>(prev: T[], curr: T[]) => {
    const prevLength = prev?.length || 0;
    const currLength = curr?.length || 0;
    if (prevLength !== currLength) return false;
    const prevId = prev?.map(m => m?.getUniqueIdentifier())?.sort()?.join(',');
    const currId = curr?.map(m => m?.getUniqueIdentifier())?.sort()?.join(',');
    return prevId === currId;
  };
  public static distinctStrings = (prev: string[]|null, curr: string[]|null) => {
    if (!prev || !curr) return prev === curr;
    if (prev?.length !== curr?.length) return false;
    return [...(prev || [])]?.sort().join(',') === [...(curr || [])]?.sort().join(',');
  };
  public static distinctAsset = <T extends Asset>(prev: T, curr: T): boolean => {
    return prev?.getUniqueIdentifier() === curr?.getUniqueIdentifier();
  };
  public static distinctVariant = (prev: Variant, curr: Variant) => prev?.id === curr?.id;
  public static distinctFeaturedVariantAndAsset = (prev: VariantAndAsset, curr: VariantAndAsset) => {
    const distinctAsset = DistinctUtils.distinctAsset(prev?.asset, curr?.asset);
    const distinctVariant = DistinctUtils.distinctVariant(prev?.variant, curr?.variant);
    return distinctAsset && distinctVariant;
  };
  public static distinctDisplayableMenu = (prev: MenuToDisplay, curr: MenuToDisplay) => {
    const distinctMenu = DistinctUtils.distinctMenu(prev.menu, curr.menu);
    const distinctTime = DistinctUtils.distinctNumber(prev.displayMeForXSeconds, curr.displayMeForXSeconds);
    return distinctMenu && distinctTime;
  };
  // Don't delete if replacing functions with general distinctUniquelyIdentifiable
  // special case inside of null and undefined
  public static distinctScheduledMenus = (prev: Menu[], curr: Menu[]) => {
    if (prev === null || prev === undefined || curr === null || curr === undefined) {
      return prev === curr;
    }
    const prevUniqueId = prev?.map(m => m.getUniqueIdentifier())?.join(',');
    const currUniqueId = curr?.map(m => m.getUniqueIdentifier())?.join(',');
    return prevUniqueId === currUniqueId;
  };
  public static distinctMenus = (prev: Menu[], curr: Menu[]) => {
    const prevUniqueId = prev?.map(m => m.getUniqueIdentifier())?.join(',');
    const currUniqueId = curr?.map(m => m.getUniqueIdentifier())?.join(',');
    return prevUniqueId === currUniqueId;
  };
  public static distinctMenu = (prev: Menu, curr: Menu) => {
    return prev?.getUniqueIdentifier() === curr?.getUniqueIdentifier();
  };
  public static distinctMenuById = (prev: Menu, curr: Menu) => {
    return prev?.id === curr?.id;
  };
  public static distinctMenuPages = (a: PageFragment[], b: PageFragment[]) => {
    return PageFragment.arePageFragmentsEqual(a, b);
  };
  public static distinctNumber = (a: number, b: number) => a === b;
  public static distinctProducts = (prev: Product[], curr: Product[]) => {
    const prevUniqueId = prev.map(p => p.getUniqueIdentifier()).join(',');
    const currUniqueId = curr.map(p => p.getUniqueIdentifier()).join(',');
    return prevUniqueId === currUniqueId;
  };

}

