import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { RenderContextComponent } from '../../../../../models/base/render-context-component';
import { ProductMenu } from '../../../../../models/menu/product-menu';
import type { SectionWithProducts } from '../../../../../models/menu/section/section-with-products';
import { SectionColumnViewModel } from '../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { SectionRowViewModel } from '../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';

@Component({
  selector: 'app-header-column-wrapper',
  templateUrl: './header-column-wrapper.component.html',
  styleUrls: ['./header-column-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class HeaderColumnWrapperComponent extends RenderContextComponent {

  constructor(
    public elementRef: ElementRef
  ) {
    super();
  }

  @Input() calculationMode: boolean;
  @Input() menu: ProductMenu;
  @Input() section: SectionWithProducts;
  @Input() colVm: SectionColumnViewModel;
  @Input() sectionRowViewModels: SectionRowViewModel[];

  setupViews() {}
  setupBindings() {}

}
