import { Pipe, PipeTransform } from '@angular/core';
import { ColorUtils } from '../../../utils/color-utils';
import type { Section } from '../../../models/menu/section/section';

@Pipe({
  name: 'textColorBasedOnSectionHeaderBackgroundColor'
})
export class TextColorBasedOnSectionHeaderBackgroundColorPipe implements PipeTransform {

  transform(section: Section, defaultBackgroundColor: string = '000000'): string {
    const backgroundColor = section?.metadata?.productsHeaderBackgroundColor || defaultBackgroundColor;
    const isDarkBackground = ColorUtils.isDarkColor(backgroundColor);
    return isDarkBackground ? '#ffffff' : '#222222';
  }

}
