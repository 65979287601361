import type { Variant } from '../../product/dto/variant';
import { CopyableProductSection } from './copyable-product-section';
import { Product } from '../../product/dto/product';
import type { Menu } from '../menu';
import type { LocationConfiguration } from '../../company/dto/location-configuration';
import type { CompanyConfiguration } from '../../company/dto/company-configuration';
import { LocationPriceStream } from '../../enum/shared/location-price-stream';

export class ProductSection extends CopyableProductSection {

  // Not returned from api, set by overflow calculator component.
  public lastProductSectionOnPage: boolean = false;
  // Not returned from api, initialized by ProductMenu onDeserialize upon entering the app.
  public sectionOverflowDuration: number;

  onDeserialize() {
    super.onDeserialize();
    if (!this.lastProductSectionOnPage) this.lastProductSectionOnPage = false;
  }

  public initOverflowDuration(duration: number) {
    this.sectionOverflowDuration = duration || 0;
  }

  public copyWithout(
    menu: Menu,
    companyConfig: CompanyConfiguration,
    locationConfig: LocationConfiguration,
    variants: Variant[]
  ): CopyableProductSection {
    return this.copyWithoutHelper(ProductSection, menu, companyConfig, locationConfig, variants);
  }

  /**
   * A spoofed section specifically for filling empty space with 'collapsed item sections'.
   * Currently, this is being used with the Plantlife print menu, so that empty spaces
   * fill with empty grid items that line up with real grid items.
   *
   * @returns [remainingSpace, [height, section]]
   */
  public getCollapsedCopyWithMaxDuplicationOfVariant(
    product: Product,
    itemHeight: number,
    spaceLeft: number,
    firstOnPage: boolean,
    pageIndex: number
  ): [number, [number, CopyableProductSection]] {
    if (!itemHeight) {
      return [0, [0, null]];
    } else {
      const copy = window?.injector?.Deserialize?.instanceOf(ProductSection, this);
      const nItems = Math.floor(spaceLeft / itemHeight);
      // Create spoofed product copy that only contains 1 variant
      const productCopy = window?.injector?.Deserialize?.instanceOf(Product, product);
      productCopy.variants = productCopy.variants.take(1);
      copy.products = Array(nItems).fill(productCopy);
      copy.productIds = copy.products?.map(p => p.id);
      copy.variants = copy.products?.flatMap(p => p?.variants);
      copy.firstOnPage = firstOnPage;
      copy.pageIndex = pageIndex;
      copy.collapseContent = true;
      const spaceUsed = nItems * itemHeight;
      return [spaceLeft - spaceUsed, [spaceUsed, copy]];
    }
  }

  resetOverflowMetaData() {
    super.resetOverflowMetaData();
    this.lastProductSectionOnPage = false;
  }

  /**
   * Used to calculate the amount of time a specific section level overflow will take.
   *
   * @returns [number of visible line items, row count, section overflow duration]
   */
  getScopedVisibleLineItemDisplayDurationCalculationData(menu: Menu): [number, number, number] {
    // price stream doesn't matter this calculation, so use default
    const priceStream = LocationPriceStream.Default;
    return [this.getScopedVisibleLineItemCount(menu, priceStream, true), this.rowCount, this.sectionOverflowDuration];
  }

}
