import { Component } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../product-menu-header.component';

@Component({
  selector: 'app-menu-header-title-subtitle',
  templateUrl: './menu-header-title-subtitle.component.html',
  providers: [{ provide: ProductMenuHeaderComponent, useExisting: MenuHeaderTitleSubtitleComponent }]
})
export class MenuHeaderTitleSubtitleComponent extends ProductMenuHeaderComponent {
}
