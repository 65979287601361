import { IndexCardStack } from './index-card-stack';
import { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { PrintCardBackgroundComponent } from '../../../../../modules/print-cards/components/print-cards/backgrounds/print-card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/print-card-content.component';
import { AgentOrangeIndexCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/print-cards/backgrounds/index-cards-3x5/agent-orange-index-card-stack-background/agent-orange-index-card-stack-background.component';
import { AgentOrangeIndexCardStackContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/index-cards-3x5/agent-orange-index-card-stack-content/agent-orange-index-card-stack-content.component';

export class AgentOrangeIndexCardStack extends IndexCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<PrintCardBackgroundComponent> | null {
    return AgentOrangeIndexCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return AgentOrangeIndexCardStackContentComponent;
  }

}
