import { PostCardStack } from './post-card-stack';
import { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { PrintCardBackgroundComponent } from '../../../../../modules/print-cards/components/print-cards/backgrounds/print-card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/print-card-content.component';
import { DougPostCardStackContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/post-cards-4x6/doug-post-card-stack-content/doug-post-card-stack-content.component';
import { DougPostCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/print-cards/backgrounds/post-cards-4x6/doug-post-card-stack-background/doug-post-card-stack-background.component';

export class DougPostCardStack extends PostCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<PrintCardBackgroundComponent> | null {
    return DougPostCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return DougPostCardStackContentComponent;
  }

  nonPerforatedBleedBorderInInches(): number {
    return 0;
  }

}
