@charset "UTF-8";

/* src/app/modules/print-cards/components/print-cards/card-content/post-cards-4x6/sessions-post-card-stack-content/sessions-price/sessions-price.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.pricing-container {
  margin-right: 2rem;
  margin-bottom: 1rem;
}
.pricing-container ::ng-deep .product-variant-price {
  font-family: nunito-sans, sans-serif;
  font-weight: 700;
  font-style: normal;
  display: flex;
  flex-direction: row-reverse;
  justify-content: right;
  align-items: center;
  gap: 1.125rem;
}
.pricing-container ::ng-deep .product-variant-price .product-variant-current-price,
.pricing-container ::ng-deep .product-variant-price .product-variant-previous-price {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.pricing-container ::ng-deep .product-variant-price .product-variant-previous-price {
  font-size: 2rem;
  text-decoration: unset;
}
.pricing-container ::ng-deep .product-variant-price .product-variant-previous-price .product-variant-price-decoration {
  font-size: 1rem;
  line-height: 1.75rem;
}
.pricing-container ::ng-deep .product-variant-price .product-variant-current-price {
  font-size: 3rem;
}
.pricing-container ::ng-deep .product-variant-price .product-variant-price-decoration {
  font-size: 1.5rem;
  line-height: 1.75rem;
}
.pricing-container .pre-tax-container {
  display: flex;
  flex-direction: row;
}
.pricing-container .pre-tax-container ::ng-deep .product-variant-price {
  font-family: nunito-sans, sans-serif;
  font-weight: 800;
  font-style: normal;
  display: flex;
  flex-direction: row-reverse;
  justify-content: right;
  align-items: center;
  gap: 1.125rem;
}
.pricing-container .pre-tax-container ::ng-deep .product-variant-price .product-variant-current-price,
.pricing-container .pre-tax-container ::ng-deep .product-variant-price .product-variant-previous-price {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.pricing-container .pre-tax-container ::ng-deep .product-variant-price .product-variant-previous-price {
  font-size: 1rem;
  text-decoration: unset;
}
.pricing-container .pre-tax-container ::ng-deep .product-variant-price .product-variant-previous-price .product-variant-price-decoration {
  font-size: 1rem;
  line-height: unset;
}
.pricing-container .pre-tax-container ::ng-deep .product-variant-price .product-variant-current-price {
  font-size: 1.125rem;
}
.pricing-container .pre-tax-container ::ng-deep .product-variant-price .product-variant-price-decoration {
  font-size: 1.125rem;
  line-height: unset;
}
.pricing-container .pre-tax-container .pre-tax-text-container {
  font-family: nunito-sans, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.125rem;
  margin-left: 0.5rem;
  width: 6rem;
}
.pricing-container .pre-tax-container .force-italic-style {
  font-family: nunito-sans, sans-serif;
  font-weight: 800;
  font-style: italic;
}
.pricing-container .pre-tax-container .force-bold-style {
  font-family: nunito-sans, sans-serif;
  font-weight: 900;
  font-style: normal;
}
.pricing-container ::ng-deep .force-italic-style * {
  font-family: nunito-sans, sans-serif;
  font-weight: 800;
  font-style: italic;
}
.pricing-container ::ng-deep .force-bold-style * {
  font-family: nunito-sans, sans-serif;
  font-weight: 900;
  font-style: normal;
}
/*# sourceMappingURL=sessions-price.component.css.map */
