<div
  #headerContainer
  [ngClass]="menu.getHeaderWrapperClass()"
  [ngStyle]="{
    color: menu?.hydratedTheme?.themeFeatures?.titleTextColor ? menu?.menuOptions?.titleTextColor : ''
  }">
  <div class="header-container">
    <div class="main-header">
      <div
        class="main-title"
        [hidden]="menu?.menuOptions?.hideTitle || !menu?.configurationTitle"
        [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
        {{ menu?.configurationTitle }}
      </div>
      <div
        class="main-subtitle"
        [hidden]="menu?.menuOptions?.hideTitle || !menu?.subTitle"
        [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
        {{ menu?.subTitle }}
      </div>
    </div>
    <img
      appImage
      *ngIf="menu?.showCompanyLogo()"
      class="company-logo"
      [style.height.rem]="6.25"
      [style.zoom]="menu?.menuOptions?.titleZoom || 1.0"
      [asset]="menu?.companyLogo"
      [reset]="reset"
      alt="company logo"
      src="" />
  </div>
</div>
