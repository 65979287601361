import type { Variant } from '../../product/dto/variant';
import { SectionWithProducts } from './section-with-products';
import { Type } from '@angular/core';
import type { CompanyConfiguration } from '../../company/dto/company-configuration';
import type { LocationConfiguration } from '../../company/dto/location-configuration';
import type { Menu } from '../menu';

export abstract class CopyableProductSection extends SectionWithProducts {

  abstract copyWithout(
    menu: Menu,
    companyConfig: CompanyConfiguration,
    locationConfig: LocationConfiguration,
    variants: Variant[]
  ): CopyableProductSection;

  /**
   * Product/variant data unmarshalled into JSON objects are large and take up a lot of memory. Therefore, do not do a
   * deep copy of the products array (unless you absolutely need to). Do a shallow copy using pointers instead.
   */
  protected copyWithoutHelper(
    SectionType: Type<CopyableProductSection>,
    menu: Menu,
    companyConfig: CompanyConfiguration,
    locationConfig: LocationConfiguration,
    variants: Variant[]
  ): CopyableProductSection {
    const storedProducts = this.products;
    const storedOriginal = this.originalVariants;
    const storedVariants = this.variants;
    this.products = null;
    this.originalVariants = null;
    this.variants = null;
    const copy = window?.injector?.Deserialize?.instanceOf(SectionType, this);
    this.products = storedProducts;
    this.originalVariants = storedOriginal;
    this.variants = storedVariants;
    copy.products = [...(storedProducts || [])];
    copy.originalVariants = [...(storedOriginal || [])];
    copy.variants = [...(storedVariants || [])];
    variants.forEach(v => copy.removeVariant(v));
    copy.setUniqueIdentifier(menu, companyConfig, locationConfig);
    return copy;
  }

  public changeVariantPool(variants: Variant[]): CopyableProductSection {
    const removed = this.originalVariants?.filter(original => !variants?.find(change => original.id === change.id));
    this.originalVariants = variants;
    this.variants = variants;
    removed.forEach(this.removeVariant.bind(this));
    return this;
  }

  protected removeVariant(v: Variant) {
    this.removeVariantFromEnabledVariantIds(v);
    this.removeVariantFromBadgeMaps(v);
    const i = this.variants?.findIndex(variant => variant.id === v?.id);
    if (i > -1) {
      this.variants?.splice(i, 1);
    }
    const j = this.originalVariants?.findIndex(variant => variant.id === v?.id);
    if (j > -1) {
      this.originalVariants?.splice(i, 1);
    }
  }

  protected removeVariantFromEnabledVariantIds(v: Variant) {
    const i = this.enabledVariantIds?.findIndex(id => id === v?.id);
    if (i > -1) {
      this.enabledVariantIds.splice(i, 1);
    }
  }

  protected removeVariantFromBadgeMaps(v: Variant) {
    this.variantBadgeIdsMap?.delete(v?.id);
    this.variantBadgeMap?.delete(v?.id);
  }

}
