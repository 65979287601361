<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="header-container"
  [ngClass]="menu | productMenuBasicMethod : 'getHeaderWrapperClass'"
  [ngStyle]="{
    color: menu?.hydratedTheme?.themeFeatures?.titleTextColor ? menu?.menuOptions?.titleTextColor : ''
  }">
  <img
    appImage
    *ngIf="menu | productMenuBasicMethod : 'showCompanyLogo'"
    fxFlex
    class="company-logo block"
    [style.object-position]="'bottom left'"
    [style.height.rem]="6.25"
    [style.zoom]="menu?.menuOptions?.titleZoom || 1.0"
    [style.margin]="menu | productMenuBasicMethod : 'getHeaderLogoMargin'"
    [asset]="menu?.companyLogo"
    [reset]="reset"
    alt="company logo"
    src="" />
  <div fxFlex [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="1rem">
      <div class="header-meta-desc">Fan Favorites</div>
      <img class="header-meta-image" src="/assets/images/true-north/Fan-Favourite-Badge.svg" alt="Fan Favourite" />
    </div>
  </div>
</div>
