import { TwoByTwoCardStack } from './two-by-two-card-stack';

export abstract class FireAndFlowerOldStyleCardStack extends TwoByTwoCardStack {

  customTopPaperMarginInInches(): number | null {
    return this.isOnPerforatedPaper() ? 0.75 : null;
  }

  getCardHeightInInches(): number {
    return 2.05;
  }

  getCardWidthInInches(): number {
    return 2.125;
  }

}
