import { Pipe, PipeTransform } from '@angular/core';
import type { SectionColumnViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import type { Section } from '../../../models/menu/section/section';
import type { ProductMenu } from '../../../models/menu/product-menu';

@Pipe({
  name: 'getShouldHideHeaderContent'
})
export class GetShouldHideHeaderContentPipe implements PipeTransform {

  transform(menu: ProductMenu, section: Section, colVm: SectionColumnViewModel): boolean {
    return menu?.getShouldHideHeaderContent(section, colVm);
  }

}
