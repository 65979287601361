<div class="size-and-price-container">
  <ng-container
    *ngFor="
      let spoofedGridColVM of viewModel.spoofedGridColViewModels$
        | async
        | fikaSpoofedGridColViewModels: (singlePrice ? 1 : null) as gridColumns
    ">
    <div class="size-and-price" [style.color]="viewModel.bodyTextColor$ | async">
      <div
        *ngIf="
          includeSize &&
          !(viewModel.isNonCannabinoidVariant$ | async) &&
          !(
            (rowVm | nVariantsAreOnSale: gridColumns : locationConfig : overridePriceStream) === 1 &&
            (viewModel.cardStackMenu$
              | async
              | isVariantPriceDiscounted
                : (viewModel.locationConfig$ | async)
                : (viewModel.locationId$ | async)
                : (viewModel.cardStack$ | async)
                : (viewModel.cardData$ | async)
                : spoofedGridColVM
                : overridePriceStream)
          )
        "
        class="size">
        {{ viewModel | getFikaGridSize: spoofedGridColVM | async | trimSafely }}
      </div>
      <div
        class="price"
        [class.multiple-variants-on-sale]="
          (rowVm | nVariantsAreOnSale: gridColumns : locationConfig : overridePriceStream) > 1
        ">
        <app-column-primary-price
          [menu]="viewModel.cardStackMenu$ | async"
          [section]="viewModel.cardStack$ | async"
          [colVm]="spoofedGridColVM"
          [rowVm]="viewModel.cardData$ | async"
          [locConfig]="viewModel.locationConfig$ | async"
          [locationId]="viewModel.locationId$ | async"
          [productStylingVm]="viewModel"
          [leadingTextOriginalPriceWithSale]="
            viewModel
              | getSalePriceWasText
                : spoofedGridColVM
                : (rowVm | nVariantsAreOnSale: gridColumns : locationConfig : overridePriceStream)
              | async
          "
          [includeLineThroughOnLeadingTextOriginalPriceWithSale]="false"
          [leadingTextSalePrice]="
            (rowVm | nVariantsAreOnSale: gridColumns : locationConfig : overridePriceStream) === 1
              ? saleText + ' '
              : null
          "
          [ignoreStylingForSaleText]="false">
        </app-column-primary-price>
      </div>
    </div>
  </ng-container>
</div>
