import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { HeaderColumnComponent } from '../header-column.component';

@Component({
  selector: 'app-header-column-variant-price',
  templateUrl: './header-column-variant-price.component.html',
  styleUrls: ['./header-column-variant-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class HeaderColumnVariantPriceComponent extends HeaderColumnComponent {
}
