import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import type { SectionColumnViewModel } from '../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import type { LocationConfiguration } from '../../../models/company/dto/location-configuration';
import type { ProductStylingViewModel } from '../../../models/shared/product-styling-view-model';

@Pipe({
  name: 'forceColumnBoldStyling'
})
export class ForceColumnBoldStylingPipe implements PipeTransform {

  transform(
    productStylingViewModel: ProductStylingViewModel,
    sectionColumnViewModel: SectionColumnViewModel,
    locationConfig: LocationConfiguration
  ): Observable<boolean> {
    return productStylingViewModel?.forceColumnBoldStyling(sectionColumnViewModel, locationConfig) || of(false);
  }

}
