import { CardStack } from '../card-stacks/card-stack';
import { Type } from '@angular/core';
import { PrintLabelBackgroundComponent } from '../../../../modules/print-cards/components/print-labels/backgrounds/print-label-background.component';
import { PrintLabelContentComponent } from '../../../../modules/print-cards/components/print-labels/label-content/print-label-content.component';
import { LabelData } from '../../../print-labels/label-data';

export abstract class LabelStack extends CardStack {

  public override templateSection?: LabelStack;

  abstract override getCardBackgroundComponent(labelData: LabelData): Type<PrintLabelBackgroundComponent> | null;
  abstract override getCardContentComponent(labelData: LabelData): Type<PrintLabelContentComponent> | null;

  /**
   * In the future, add switch statement here for each type of laser paper,
   * and return the correct row gap for each type.
   */
  getLaserLabelPaperRowGapInInches(): number | null {
    return null;
  }

  /**
   * In the future, add switch statement here for each type of laser paper,
   * and return the correct column gap for each type.
   */
  getLaserLabelPaperColumnGapInInches(): number | null {
    // 4 mm = 0.15748 inches
    return 0.15748; // for now, this is the column gap needed for letter 2x4 (HxW) laser labels
  }

}
