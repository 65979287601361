import { Pipe, PipeTransform } from '@angular/core';
import { CardStack } from '../../../models/menu/section/card-stacks/card-stack';
import { CutLineMode } from '../../../models/enum/shared/cut-line-mode.enum';

@Pipe({
  name: 'isExtendedCutLine'
})
export class IsExtendedCutLinePipe implements PipeTransform {

  transform(cardStack: CardStack|null): boolean {
    return cardStack?.cutLineMode === CutLineMode.Extended;
  }

}
