import { PrintMenu } from '../print-menu';
import { LocationPriceStream } from '../../enum/shared/location-price-stream';
import type { SectionWithProducts } from '../section/section-with-products';
import { SectionRowViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-row-view-models/SectionRowViewModel';
import { SectionColumnViewModel } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import { PriceUtils } from '../../../utils/price-utils';
import { ColumnGrouping } from '../section/column-grouping';
import { ColumnUtils } from '../../../utils/column-utils';
import { PrintHeaderLayoutType } from '../../enum/shared/print-header-layout-type.enum';
import { Fraction } from '../../enum/shared/fraction.enum';
import { Type } from '@angular/core';
import { ProductMenuFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/product-menu-footer.component';
import { PrintFooterLayoutType } from '../../enum/shared/print-footer-layout-type.enum';
import { IngramAndSonsPrintFooterComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-footer/ingram-and-sons-print-footer/ingram-and-sons-print-footer.component';

export class IngramAndSonsPrintMenu extends PrintMenu {

  public static readonly supportedDecimalsThatCanBeFractions: Fraction[] = [
    Fraction.Eighth,
    Fraction.Quarter,
    Fraction.ThreeEighths,
    Fraction.Half,
    Fraction.FiveEighths,
    Fraction.ThreeQuarters,
    Fraction.SevenEighths
  ];

  getFontFaceList(): string[] {
    return ['anton', 'Montserrat', 'Spectral'];
  }

  getPageBottomMargin(): string {
    return '0.25in';
  }

  getPageTopMargin(): string {
    return '0.25in';
  }

  getPageLeftMargin(): string {
    return '0.25in';
  }

  getPageRightMargin(): string {
    return '0.25in';
  }

  getThemeClass(): string {
    return 'ingram-and-sons-print-theme';
  }

  getDisplayPadding(): string {
    return '0rem';
  }

  getTitleSectionTopMargin(): string {
    return '1rem';
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return false;
  }

  getPrintHeaderLayoutType(): PrintHeaderLayoutType {
    return this.menuOptions?.printHeaderLayout || PrintHeaderLayoutType.FirstPage;
  }

  getPrintFooterLayoutType(): PrintFooterLayoutType {
    return this.menuOptions?.printFooterLayout || PrintFooterLayoutType.AllPagesFixed;
  }

  getFooterType(): Type<ProductMenuFooterComponent> {
    return IngramAndSonsPrintFooterComponent;
  }

  getSectionHeaderUnderlined(): boolean {
    return false;
  }

  getPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel,
  ): string {
    const decimal = super.getPriceDecimal(priceStream, locationId, s, rowVM, colVm);
    const supportedFractions = IngramAndSonsPrintMenu.supportedDecimalsThatCanBeFractions;
    return PriceUtils.getCertainDecimalsAsAsciiFractions(decimal, supportedFractions, true);
  }

  getSecondaryPriceDecimal(
    priceStream: LocationPriceStream,
    locationId: number,
    s: SectionWithProducts,
    rowVM: SectionRowViewModel,
    colVm: SectionColumnViewModel
  ): string {
    const decimal = super.getSecondaryPriceDecimal(priceStream, locationId, s, rowVM, colVm);
    const supportedFractions = IngramAndSonsPrintMenu.supportedDecimalsThatCanBeFractions;
    return PriceUtils.getCertainDecimalsAsAsciiFractions(decimal, supportedFractions, true);
  }

  getLineModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

  getGridModeColumnGroupings(sectionColumnViewModels: SectionColumnViewModel[]): ColumnGrouping[] {
    return ColumnUtils.oneBigGrouping();
  }

}
