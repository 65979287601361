import { FireAndFlowerOldStyleCardStack } from './fire-and-flower-old-style-card-stack';
import { CardData } from '../../../../print-cards/card-data';
import { Type } from '@angular/core';
import { PrintCardBackgroundComponent } from '../../../../../modules/print-cards/components/print-cards/backgrounds/print-card-background.component';
import { PrintCardContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/print-card-content.component';
import { FireAndFlowerMemberCardStackBackgroundComponent } from '../../../../../modules/print-cards/components/print-cards/backgrounds/custom-cards-2x2/fire-and-flower-member-card-stack-background/fire-and-flower-member-card-stack-background.component';
import { FireAndFlowerMemberCardStackContentComponent } from '../../../../../modules/print-cards/components/print-cards/card-content/custom-cards-2x2/fire-and-flower-member-card-stack-content/fire-and-flower-member-card-stack-content.component';

export class FireAndFlowerMemberCardStack extends FireAndFlowerOldStyleCardStack {

  getCardBackgroundComponent(cardData: CardData): Type<PrintCardBackgroundComponent> | null {
    return FireAndFlowerMemberCardStackBackgroundComponent;
  }

  getCardContentComponent(cardData: CardData): Type<PrintCardContentComponent> | null {
    return FireAndFlowerMemberCardStackContentComponent;
  }

}
