<div
  *ngIf="colVm?.columnType === Types.SectionColumnProductInfoType.StrainType"
  appScaleDownText
  [scaleEnabled]="!(menu | isPrintMenu)"
  [style.color]="
    ignoreUserSetTextColorAndAutoToLightOrDarkTextColor
      ? (section | textColorBasedOnSectionHeaderBackgroundColor)
      : (menu | productMenuBasicMethod: 'getSectionHeaderTextColor' : section)
  "
  [scaleDownLinkedKey]="section?.id + Types.TextSizeLink.TypeCannabinoid"
  class="section-header-general section-header-classification">
  {{ menu | productMenuBasicMethod: 'getTypeColumnTitle' : colVm }}
</div>
