import { Pipe, PipeTransform } from '@angular/core';
import type { ProductMenu } from '../../../models/menu/product-menu';
import type { SectionWithProducts } from '../../../models/menu/section/section-with-products';

@Pipe({
  name: 'primaryPriceColumnAlsoShowOriginalPriceIfOnSale'
})
export class PrimaryPriceColumnAlsoShowOriginalPriceIfOnSalePipe implements PipeTransform {

  transform(
    menu: ProductMenu,
    section: SectionWithProducts
  ): boolean {
    return menu?.primaryPriceColumnAlsoShowOriginalPriceIfOnSale(section) || false;
  }

}
