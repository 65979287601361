import { CardStack } from '../card-stack';
import { PaperSize } from '../../../../shared/paper-size';
import { Orientation } from '../../../../enum/dto/orientation.enum';

export abstract class AddressCardStack extends CardStack {

  customTopPaperMarginInInches(): number | null {
    return this.isOnPerforatedPaper() ? 1.8438 : null;
  }

  getCardHeightInInches(): number {
    return 0.8021;
  }

  getCardWidthInInches(): number {
    return 3;
  }

  nCardsPerPage(paperSize: PaperSize, isOnPerforatedPaper: boolean): number {
    return 18;
  }

  nColumns(): number {
    return 2;
  }

  nRows(): number {
    return 9;
  }

  cardOrientationOnPortraitPage(): Orientation {
    return Orientation.Landscape;
  }

}
