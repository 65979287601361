/* src/app/modules/print-cards/components/cut-lines/print-card-top-cut-lines/print-card-top-cut-lines.component.scss */
:host {
  flex: 1;
  display: flex;
  justify-content: center;
  z-index: 1;
}
.top-cut-lines {
  height: 100%;
  max-height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}
.top-cut-lines.extended {
  max-height: none;
}
.top-cut-lines * {
  box-sizing: border-box;
}
.top-cut-lines .left-sliver {
  border-right: 1px solid black;
}
.top-cut-lines .dividing-sliver-container {
  position: relative;
}
.top-cut-lines .dividing-sliver-container .dividing-sliver {
  position: relative;
  height: 100%;
  border-left: 1px solid black;
  border-right: 1px solid black;
}
.top-cut-lines .dividing-sliver-container .dividing-sliver-horizontal-left-line {
  position: absolute;
  align-self: center;
  border-bottom: 1px solid black;
}
.top-cut-lines .dividing-sliver-container .dividing-sliver-horizontal-middle-line {
  position: absolute;
  align-self: center;
  border-bottom: 1px solid black;
}
.top-cut-lines .dividing-sliver-container .dividing-sliver-horizontal-right-line {
  position: absolute;
  align-self: center;
  border-bottom: 1px solid black;
}
.top-cut-lines .right-sliver {
  border-left: 1px solid black;
}
/*# sourceMappingURL=print-card-top-cut-lines.component.css.map */
