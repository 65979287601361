import { CardData } from './card-data';
import { Variant } from '../product/dto/variant';

export class GanjikaBeverageCardData extends CardData {

  public override getSize(variant?: Variant): string {
    const v = variant || this.rowVariants?.firstOrNull();
    const formattedUnitSize = v?.getFormattedUnitSize();
    const packagedQuantity = v?.packagedQuantity;
    const packagedQuantityIsSet = formattedUnitSize !== '-' && formattedUnitSize !== '0';
    if (packagedQuantity > 1 && packagedQuantityIsSet) {
      return `${packagedQuantity} x ${formattedUnitSize?.toUpperCase()}`;
    } else if (packagedQuantity < 1 && packagedQuantityIsSet) {
      return `${formattedUnitSize?.toUpperCase()}`;
    } else if (!packagedQuantityIsSet) {
      return '-';
    }
    return super.getSize(variant)?.toUpperCase();
  }

}
