import { ProductSection } from '../product-section';
import { Type } from '@angular/core';
import type { MenuItemComponent } from '../../../../modules/display/components/menus/product-menu/building-blocks/menu-item/menu-item.component';
import { ReportNewProductSectionItemComponent } from '../../../../modules/display/components/menus/product-menu/building-blocks/menu-item/report-new-product-section-item/report-new-product-section-item.component';

export class ReportNewProductsSection extends ProductSection {

  onDeserialize() {
    super.onDeserialize();
    this.metadata.hideNewLabels = 'true';
    this.metadata.hideRestockedLabels = 'true';
  }

  getMenuItemType(): Type<MenuItemComponent> {
    return ReportNewProductSectionItemComponent;
  }

}
