@charset "UTF-8";

/* src/app/modules/print-cards/components/print-cards/card-content/post-cards-4x6/generic-card-stack-content/generic-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  overflow: hidden;
}
:host .main-content {
  flex: 1;
  padding: 0 calc(1rem * 1.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: calc(0.5rem * 1.4);
}
:host .main-content .product-card-image {
  display: block;
  object-fit: contain;
  max-width: 100%;
  max-height: calc(4.5rem * 1.4);
  align-self: flex-start;
}
:host .main-content .brand-name-badge-and-label-container {
  display: flex;
  flex-direction: column;
}
:host .main-content .brand-name-badge-and-label-container .brand-name-badge-and-label .brand-name-and-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  gap: calc(0.5rem * 1.4);
}
:host .main-content .brand-name-badge-and-label-container .brand-name-badge-and-label .brand-name-and-badge .brand-and-name {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
}
:host .main-content .brand-name-badge-and-label-container .brand-name-badge-and-label .brand-name-and-badge .brand-and-name .product-card-brand {
  font-family: poppins, sans-serif;
  font-weight: 500;
  font-size: calc(0.75rem * 1.4);
  line-height: 120%;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
:host .main-content .brand-name-badge-and-label-container .brand-name-badge-and-label .brand-name-and-badge .brand-and-name .product-card-name {
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-size: calc(1.5rem * 1.4);
  line-height: 130%;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
:host .main-content .brand-name-badge-and-label-container .brand-name-badge-and-label .brand-name-and-badge .product-card-badge-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.25rem;
}
:host .main-content .brand-name-badge-and-label-container .brand-name-badge-and-label .brand-name-and-badge .product-card-badge-container .product-card-badge {
  width: calc(2rem * 1.4);
}
:host .main-content .brand-name-badge-and-label-container .brand-name-badge-and-label .print-card-label {
  align-self: flex-start;
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .main-content .brand-name-badge-and-label-container .brand-name-badge-and-label .print-card-label ::ng-deep .product-label {
  font-family: poppins, sans-serif;
  font-weight: 500;
  font-size: calc(0.75rem * 1.4);
  line-height: 120%;
  border-radius: calc(0.1125rem * 1.4);
  margin: calc(0.3rem * 1.4) 0 0 0;
  width: fit-content;
}
:host .main-content .desc-type-and-price {
  display: flex;
  flex-direction: column;
  gap: calc(0.5rem * 1.4);
}
:host .main-content .desc-type-and-price ::ng-deep .description-container .description {
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-size: calc(0.75rem * 1.4);
  line-height: 122%;
  word-break: break-word;
}
:host .main-content .desc-type-and-price ::ng-deep .description-container .plain-text {
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
:host .main-content .desc-type-and-price ::ng-deep .description-container .rich-text {
  max-height: 3.843rem;
  overflow-y: hidden;
}
:host .main-content .desc-type-and-price .type-and-price {
  display: flex;
  flex-direction: row;
  gap: calc(0.34rem * 1.4);
  align-items: center;
  justify-content: flex-end;
}
:host .main-content .desc-type-and-price .type-and-price .product-card-type {
  flex: 1;
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  font-size: calc(0.75rem * 1.4);
  line-height: 83.333%;
}
:host .main-content .desc-type-and-price .type-and-price .product-card-price ::ng-deep .product-variant-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-size: calc(1rem * 1.4);
  line-height: 100%;
  overflow: visible;
}
:host .main-content .desc-type-and-price .type-and-price .product-card-price ::ng-deep .product-variant-price .product-variant-previous-price {
  opacity: 0.5;
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-size: calc(0.75rem * 1.4);
}
:host .color-strip {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 32.6%;
  box-sizing: border-box;
  padding: 0 calc(1rem * 1.4);
}
:host .color-strip .property-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: calc(0.125rem * 1.4);
  box-sizing: border-box;
  padding: calc(0.38rem * 1.4) 0;
  border-bottom: 1px solid #dadbde;
}
:host .color-strip .property-container .title {
  font-family: poppins, sans-serif;
  font-weight: 500;
  font-size: calc(0.375rem * 1.4);
  line-height: 120%;
}
:host .color-strip .property-container .value {
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-size: calc(0.75rem * 1.4);
  line-height: 120%;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
:host ::ng-deep .force-bold-style * {
  font-family: poppins, sans-serif;
  font-weight: 800;
}
:host ::ng-deep .force-italic-style * {
  font-family: poppins, sans-serif;
  font-style: italic;
  font-weight: 700;
}
/*# sourceMappingURL=generic-card-stack-content.component.css.map */
