import { Pipe, PipeTransform } from '@angular/core';
import type { ProductMenu } from '../../../../../models/menu/product-menu';
import { SectionColumnProductInfoType, type SectionColumnViewModel } from '../../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';

@Pipe({
  name: 'hideLabelColumn'
})
export class HideLabelColumnPipe implements PipeTransform {

  transform(
    colVM: SectionColumnViewModel,
    menu: ProductMenu,
    labelText: string
  ): boolean {
    return colVM.columnType === SectionColumnProductInfoType.Label && menu?.hideProductLabelColumnWhenEmpty()
      ? !labelText
      : false;
  }

}
