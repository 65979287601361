import { Size } from './size';
import { DefaultPaperSize } from '../enum/shared/default-paper-size.enum';

export class PaperSize extends Size {

  public override name: DefaultPaperSize;

  public isRegularPaper(): boolean {
    return this.name?.toLowerCase()?.includes('custom');
  }

  public isPerforated(): boolean {
    return this.name?.toLowerCase()?.includes('perforated');
  }

  public isLaserLabel(): boolean {
    return this.name?.toLowerCase()?.includes('label');
  }

}
