/* src/app/modules/print-cards/components/print-cards/backgrounds/custom-cards-2x2/fire-and-flower-member-card-stack-background/fire-and-flower-member-card-stack-background.component.scss */
:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}
:host .main-content {
  flex: 1;
}
:host .main-content .print-card-background-asset {
  height: 100%;
  width: 100%;
}
/*# sourceMappingURL=fire-and-flower-member-card-stack-background.component.css.map */
