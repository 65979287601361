import { Deserializable } from '../protocols/deserializable';
import { OptionScale } from '../enum/dto/option-scale.enum';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import { TimeDurationUnixSeconds } from './time-duration-unix-seconds';

export class DisplayOptions implements Deserializable, UniquelyIdentifiable {

  public scale: OptionScale = OptionScale.Fit;
  public rotationInterval: Map<string, number> = new Map();
  public rotationOrder: Map<string, number> = new Map();
  public defaultValue: string;
  public overrides: Map<string, TimeDurationUnixSeconds> = new Map();

  static empty(): DisplayOptions {
    const empty = new DisplayOptions();
    Object.keys(empty)?.forEach(key => empty[key] = undefined);
    return empty;
  }

  public onDeserialize() {
    const Deserialize = window?.injector?.Deserialize;
    // If initializing new instance from properly mapped object, we can ignore the map deserialization
    if (!(this.rotationInterval instanceof Map)) {
      this.rotationInterval = Deserialize?.genericMap<string, number>(this.rotationInterval);
    }
    if (!(this.rotationOrder instanceof Map)) {
      this.rotationOrder = Deserialize?.genericMap<string, number>(this.rotationOrder);
    }
    if (!(this.overrides instanceof Map)) {
      this.overrides = Deserialize?.mapOf(TimeDurationUnixSeconds, this.overrides);
    }
  }

  getUniqueIdentifier(): string {
    const scaleId = this.scale;
    const intervalIds: string[] = [];
    this.rotationInterval?.forEach((val, key) => {
      intervalIds.push(`${key}-${val}`);
    });
    const intervalId = intervalIds.sort().join(',') ?? '';
    const orderIds: string[] = [];
    this.rotationOrder?.forEach((val, key) => {
      orderIds.push(`${key}-${val}`);
    });
    const orderId = orderIds.sort().join(',') ?? '';
    const defaultId = this.defaultValue ?? '';
    const overridesIds: string[] = [];
    this.overrides?.forEach((val, key) => {
      orderIds.push(`${key}-${val.getUniqueIdentifier() ?? ''}`);
    });
    const overridesId = overridesIds.sort().join(',') ?? '';
    return `${scaleId}-${intervalId}-${orderId}-${defaultId}-${overridesId}`;
  }

}
