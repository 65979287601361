import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/menu';

@Pipe({
  name: 'backgroundAssetWrapperClass'
})
export class BackgroundAssetWrapperClassPipe implements PipeTransform {

  transform(menu: Menu): string|null {
    return menu?.getBackgroundAssetWrapperClass();
  }

}
