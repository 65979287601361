/* src/app/modules/print-cards/components/print-cards/backgrounds/index-cards-3x5/agent-orange-index-card-stack-background/agent-orange-index-card-stack-background.component.scss */
:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}
:host .main-content {
  flex: 1;
}
:host .main-content .print-card-background-asset {
  height: 100%;
  width: 100%;
}
:host .color-strip {
  width: 25%;
  background-color: #777777;
}
/*# sourceMappingURL=agent-orange-index-card-stack-background.component.css.map */
