<div class="asset-container">
  <img
    [hidden]="!(showImage$ | async)"
    #image
    class="asset"
    (load)="ratio.emit(image.naturalWidth / image.naturalHeight); renderSucceeded()"
    (error)="imageRenderFailed()"
    [style.object-fit]="scaleFit ? 'contain' : 'cover'"
    [style.border-radius]="borderRadius"
    [ngStyle]="styleOverrides"
    [ngClass]="{
      'ease-me-in-from-top': easeInFromTop,
      'sweep-from-middle-to-top': sweepFromMiddleToTop,
      'sweep-from-bottom-to-top': sweepFromBottomToTop
    }"
    [src]="imageAssetUrl$ | async"
    alt="" />
  <video
    [hidden]="!(showVideo$ | async)"
    #video
    class="asset"
    poster="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
    [controls]="showControls"
    [src]="videoAssetUrl$ | async"
    [autoplay]="autoplay"
    [loop]="loopVideo"
    [style.object-fit]="scaleFit ? 'contain' : 'cover'"
    [style.border-radius]="borderRadius"
    [ngStyle]="styleOverrides"
    (canplaythrough)="renderSucceeded()"
    (error)="videoRenderFailed()"
    (canplay)="videoCanPlay.next(true)"
    (timeupdate)="timeUpdate($event)"
    (loadedmetadata)="duration.emit(video.duration); ratio.emit(video.videoWidth / video.videoHeight || 1)"
    (loadstart)="this.videoCanPlay.next(false); this.videoStartedToLoad.emit(true)"
    (ended)="this.videoEnded.emit(true)"
    [muted]="!((showVideo$ | async) && playAudio)"></video>
  <div *ngIf="loading$ | async" #loading class="asset shimmer-loading">
    <app-shimmer-loading [width]="'100%'" [height]="'100%'" [shape]="'rect'" [borderRadius]="borderRadius">
    </app-shimmer-loading>
  </div>
</div>
