<div #headerContainer [ngClass]="menu | productMenuBasicMethod : 'getHeaderWrapperClass'">
  <div
    class="header-container"
    [ngStyle]="{
      'background-size': 'cover',
      'background-image': (image$ | async)
    }">
    <div
      class="main-header"
      [hidden]="menu?.menuOptions?.hideTitle || !menu?.configurationTitle"
      [style.zoom]="menu?.menuOptions?.titleZoom || 1.0">
      {{ menu?.configurationTitle }}
    </div>
    <img
      appImage
      *ngIf="menu | productMenuBasicMethod : 'showCompanyLogo'"
      class="company-logo"
      [style.height.rem]="6.25"
      [style.zoom]="menu?.menuOptions?.titleZoom || 1.0"
      [style.margin]="menu | productMenuBasicMethod : 'getHeaderLogoMargin'"
      [asset]="menu?.companyLogo"
      [reset]="reset"
      alt="company logo"
      src="" />
  </div>
</div>
