import { PrintCardContentViewModel } from '../../print-card-content-view-model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { PopsCannabisCardData } from '../../../../../../../models/print-cards/pops-cannabis-card-data';
import { ProductType } from '../../../../../../../models/enum/dto/product-type.enum';

@Injectable()
export class PopsCannabis5By5CardStackContentViewModel extends PrintCardContentViewModel {

  public readonly cardData$: Observable<PopsCannabisCardData>;

  public readonly priceStreamOverride$ = this.cardData$.pipe(
    map(cardData => cardData?.getOverridePriceStreamForLabelComponent())
  );

  public readonly leftSideLargeText$ = this.cardData$.pipe(
    map(cardData => cardData?.getLeftLargeText())
  );

  public readonly showLeftSideLargeText$ = combineLatest([
    this.preview$,
    this.cardData$
  ]).pipe(
    map(([previewMode, cardData]) => {
      const productType = cardData?.rowProductType();
      const hasSticker = productType === ProductType.Flower
        || productType === ProductType.Vape
        || productType === ProductType.Concentrate;
      return previewMode || !hasSticker;
    })
  );

}
