import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { HoneyHazeBusinessCardStackContentViewModel } from './honey-haze-business-card-stack-content-view-model';
import { PrintCardContentComponent } from '../../print-card-content.component';

@Component({
  selector: 'app-honey-haze-business-card-stack-content',
  templateUrl: './honey-haze-business-card-stack-content.component.html',
  styleUrls: ['./honey-haze-business-card-stack-content.component.scss'],
  providers: [HoneyHazeBusinessCardStackContentViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HoneyHazeBusinessCardStackContentComponent extends PrintCardContentComponent {

  constructor(
    public override viewModel: HoneyHazeBusinessCardStackContentViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

}
