import { MarketingLoopingContentMenu } from '../marketing-looping-content-menu';
import { MarketingMenuLabelInterface } from '../../../protocols/marketing-menu-label-interface';
import { LabelStyle } from '../../../enum/shared/label-style.enum';
import { Asset } from '../../../image/dto/asset';
import { OrderFeaturedVariants } from './order-featured-variants';
import { VariantFeature } from '../../../product/dto/variant-feature';
import { DisplayOptions } from '../../../shared/display-options';
import { MarketingAsset } from '../../../image/dto/marketing-asset';

export class MarketingFeaturedProductMenu extends MarketingLoopingContentMenu implements MarketingMenuLabelInterface {

  public calculateMarketingLoopDurationInSeconds(): number {
    // Summing the duration of each product on the menu
    let sum = 0;
    for (const [key, value] of (this?.options?.rotationInterval?.entries() || [])) {
      if (this.hydratedVariantFeature?.isFeaturedVariantInStock(key)) sum += value;
    }
    if (Number.isInteger(sum)) {
      return sum;
    } else {
      return Number(sum.toFixed(2));
    }
  }

  getMarketingLabelZoomLevel(labelStyle: LabelStyle): number {
    switch (labelStyle) {
      default:
        return 2;
    }
  }

  isAssetEnabled(asset: Asset): boolean {
    const variantId = this.getVariantIdFromAsset(asset);
    const position = this.options?.rotationOrder?.get(variantId);
    const interval = this.options?.rotationInterval?.get(variantId);
    return (position >= 0) && (interval > 0);
  }

  getAssetPosition(asset: Asset): number {
    const variantId = this.getVariantIdFromAsset(asset);
    return this.options?.rotationOrder?.get(variantId);
  }

  getOrderFeaturedVariants(
    featured: VariantFeature,
    order: DisplayOptions,
    media: MarketingAsset[]
  ): OrderFeaturedVariants[] {
    return featured?.variantIds
      ?.map(id => {
        const position = order?.rotationOrder?.get(id);
        const interval = order?.rotationInterval?.get(id);
        const variant = featured?.variants?.find(v => v?.id === id);
        const mediaName = featured.assetNameMap?.get(id);
        const asset = media?.find(m => m?.fileName === mediaName);
        if (position >= 0 && interval > 0) {
          return new OrderFeaturedVariants(position, variant, asset);
        }
      })
      ?.filterNulls();
  }

}
