import { Component, Input } from '@angular/core';
import { RenderContextComponent } from '../../../../models/base/render-context-component';
import { ProductMenu } from '../../../../models/menu/product-menu';
import type { SectionWithProducts } from '../../../../models/menu/section/section-with-products';
import { SectionColumnViewModel } from '../../../display/components/menus/product-menu/building-blocks/menu-section/product-section/section-column-view-models/SectionColumnViewModel';

@Component({ selector: 'app-header-column', template: '' })
export abstract class HeaderColumnComponent extends RenderContextComponent {

  @Input() menu: ProductMenu;
  @Input() section: SectionWithProducts;
  @Input() colVm: SectionColumnViewModel;
  @Input() ignoreUserSetTextColorAndAutoToLightOrDarkTextColor: boolean = false;

  setupViews() {}
  setupBindings() {}

}
