import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { RenderContextComponent } from '../../../../models/base/render-context-component';
import { PrintCardInterface } from '../../interface/print-card.interface';
import { ResizeObserver } from '@juggle/resize-observer';
import { exists } from '../../../../functions/exists';
import { PrintCardJigViewModel } from './print-card-jig-view-model';
import { CardData } from '../../../../models/print-cards/card-data';

@Component({
  selector: 'app-print-card-jig',
  templateUrl: './print-card-jig.component.html',
  styleUrls: ['./print-card-jig.component.scss'],
  providers: [PrintCardJigViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrintCardJigComponent extends RenderContextComponent implements PrintCardInterface {

  constructor(
    public viewModel: PrintCardJigViewModel,
    protected elementRef: ElementRef
  ) {
    super();
  }

  @Input() preview = false;
  @Input() cardData: CardData;

  private resizeObserver: ResizeObserver;

  setupViews() {
  }

  setupBindings() {
    this.observeContainer();
  }

  private observeContainer() {
    this.resizeObserver?.disconnect();
    if (exists(this.elementRef?.nativeElement)) {
      this.resizeObserver = new ResizeObserver(_ => {
        this.viewModel.connectToHeight(this.elementRef.nativeElement?.clientHeight ?? 0);
        this.viewModel.connectToWidth(this.elementRef.nativeElement?.clientWidth ?? 0);
      });
      this.resizeObserver.observe(this.elementRef.nativeElement);
    }
  }

  destroy() {
    super.destroy();
    this.resizeObserver?.disconnect();
  }

}
