import { ProductMenu } from '../product-menu';
import { Type } from '@angular/core';
import { MenuHeaderTitleImageComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-title/menu-header-title-image/menu-header-title-image.component';
import { ProductMenuHeaderComponent } from '../../../modules/display/components/menus/product-menu/building-blocks/menu-header/product-menu-header.component';
import { BandedRowMode } from '../../enum/shared/banded-row-mode.enum';

export class TightMenu extends ProductMenu {

  getThemeClass(): string {
    return 'tight-theme';
  }

  getHeaderType(): Type<ProductMenuHeaderComponent> {
    return MenuHeaderTitleImageComponent;
  }

  getShowHeader(): boolean {
    return true;
  }

  getShowFooter(): boolean {
    return false;
  }

  getShowLabelsOnTitleLineElseSubtitle(): boolean {
    return true;
  }

  getProductWrapperClass(odd: boolean) {
    return odd ? '' : 'product-row-overlay';
  }

  bandedRowMode(): BandedRowMode {
    return BandedRowMode.Even;
  }

  bandedRowsDefaultEnabled(): boolean {
    return true;
  }

  getShowProductSubtitle(): boolean {
    return true;
  }

  getFontFaceList(): string[] {
    return ['rubik', 'lato'];
  }

}
