@charset "UTF-8";

/* src/app/modules/display/components/menus/product-menu/building-blocks/menu-header/menu-header-sprout/menu-header-sprout.component.scss */
.menu-title {
  font-size: 2rem;
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;
  min-width: 0;
}
.menu-date {
  font-size: 1.25rem;
}
/*# sourceMappingURL=menu-header-sprout.component.css.map */
