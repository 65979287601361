import { ImageType, MediaType, VideoType } from '../models/enum/dto/media-type.enum';
import { DateUtils } from './date-utils';
import { AssetSize } from '../models/enum/dto/asset-size.enum';

export const ASSET_RETRY_COUNT = 10;
export const ASSET_RETRY_DELAY = 10;
export const DELETE_ASSET_DELAY = 10;

// @dynamic
export class MediaUtils {

  static readonly DefaultImageSize = AssetSize.Medium;
  static readonly DefaultCacheTimeInSeconds = (DateUtils.unixOneHour() * 24);

  static isImage(mediaType: MediaType): boolean {
    const imageTypes = Object.values(ImageType);
    return imageTypes.includes(mediaType as ImageType);
  }

  static isVideo(mediaType: MediaType): boolean {
    const videoTypes = Object.values(VideoType);
    return videoTypes.includes(mediaType as VideoType);
  }

}
