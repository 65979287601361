import { Pipe, PipeTransform } from '@angular/core';
import { Menu } from '../../../models/menu/menu';
import { exists } from '../../../functions/exists';

@Pipe({
  name: 'shouldShowDefaultVideo'
})
export class ShouldShowDefaultVideoPipe implements PipeTransform {

  transform(menu: Menu, defaultVideoUrl: string): boolean {
    const shutOffBackgroundAsset = menu?.shutOffBackgroundAsset();
    const changeBackgroundEnabled = exists(menu?.hydratedTheme?.themeFeatures?.backgroundMedia);
    const menuHasBackgroundImage = exists(menu?.backgroundImage);
    const hasDefaultVideoBackground = exists(defaultVideoUrl);
    return changeBackgroundEnabled
      ? !shutOffBackgroundAsset && !menuHasBackgroundImage && hasDefaultVideoBackground
      : !shutOffBackgroundAsset && hasDefaultVideoBackground;
  }

}
