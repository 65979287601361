import { FikaCardData } from './fika-card-data';
import { ProductType } from '../enum/dto/product-type.enum';
import { Variant } from '../product/dto/variant';

export class FikaEdiblesCardData extends FikaCardData {

  isEdiblesCard(): boolean {
    return this.section?.isChildVariantListMode()
        && this.rowVariants?.length > 1
        && this.rowVariants?.every(variant => variant?.productType === ProductType.Edible);
  }

  topOfCardVariantType(): string {
    return this.rowVariantType();
  }

  labelInterfaceFor(variant: Variant): FikaEdiblesCardData {
    const labelInterface = Object.assign(new FikaEdiblesCardData(), this);
    labelInterface.rowVariants = [variant];
    return labelInterface;
  }

}
