import { Component } from '@angular/core';
import { ProductMenuHeaderComponent } from '../../../../product-menu/building-blocks/menu-header/product-menu-header.component';

@Component({
  selector: 'app-featured-category-header',
  templateUrl: './featured-category-header.component.html',
  providers: [{ provide: ProductMenuHeaderComponent, useExisting: FeaturedCategoryHeaderComponent }]
})
export class FeaturedCategoryHeaderComponent extends ProductMenuHeaderComponent {
}
