import { ProductSectionItemViewModel } from '../product-section-item/product-section-item-view-model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { SectionColumnConfigState } from '../../../../../../../../models/menu/section/section-column-config';

@Injectable()
export class ReportNewProductSectionItemViewModel extends ProductSectionItemViewModel {

  public readonly imageColumnConfig$ = this.columnViewModels$.pipe(
    map(vms => vms?.find(columnViewModel => columnViewModel?.isAssetColumn()))
  );

  public readonly badgeColumnConfig$ = this.columnViewModels$.pipe(
    map(vms => vms?.find(columnViewModel => columnViewModel?.isBadgeColumn()))
  );

  public readonly badgeColumnEnabled$ = this.badgeColumnConfig$.pipe(
    map(badgeColumnConfig => {
      const auto = badgeColumnConfig?.state === SectionColumnConfigState.Auto;
      const on = badgeColumnConfig?.state === SectionColumnConfigState.On;
      return auto || on;
    })
  );

  public readonly newProductColumnViewModels$ = this.columnViewModels$.pipe(
    map(vms => vms?.filter(columnViewModel => !columnViewModel?.isAssetColumn() && !columnViewModel?.isBadgeColumn()))
  );

}
