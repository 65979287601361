@charset "UTF-8";

/* src/app/modules/print-cards/components/print-cards/card-content/business-cards-2x3/doug-business-card-stack-content/doug-business-card-stack-content.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  padding: 0.625rem;
  box-sizing: border-box;
}
:host .card-header {
  position: relative;
  height: 14.3%;
  width: 100%;
  display: block;
  color: #ffffff;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
}
:host .card-header .header-content.left-content {
  position: absolute;
  left: 0.625rem;
  top: 50%;
  transform: translateY(-50%);
}
:host .card-header .header-content.right-content {
  position: absolute;
  right: 0.625rem;
  top: 50%;
  transform: translateY(-50%);
}
:host .card-header .header-content .strain-container {
  font-family: poppins, sans-serif;
  font-weight: 700;
  font-size: 0.6875rem;
  vertical-align: middle;
}
:host .card-header .header-content .product-size-and-type-container {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 0.5625rem;
  vertical-align: middle;
}
:host .card-body {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: auto auto;
  box-sizing: border-box;
}
:host .card-body .card-body-left-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 0.5625rem;
  height: 7.375rem;
}
:host .card-body .card-body-left-column .product-id-info {
  padding: 0 0 0.5625rem 0.5625rem;
}
:host .card-body .card-body-left-column .product-id-info .product-card-name {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
:host .card-body .card-body-left-column .product-id-info .product-card-brand {
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-size: 0.6875rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
:host .card-body .card-body-left-column .pricing-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
:host .card-body .card-body-left-column .pricing-container ::ng-deep .product-variant-price {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-family: source-sans-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
  line-height: 100%;
  overflow: visible;
  width: unset;
}
:host .card-body .card-body-left-column .pricing-container ::ng-deep .product-variant-price .product-variant-current-price,
:host .card-body .card-body-left-column .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.1rem;
}
:host .card-body .card-body-left-column .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price {
  font-size: 0.875rem;
  color: #a3a3a3;
  text-decoration: unset;
  margin-top: 0.25rem;
}
:host .card-body .card-body-left-column .pricing-container ::ng-deep .product-variant-price .product-variant-previous-price .product-variant-price-decoration {
  font-size: 0.5rem;
  line-height: 160%;
}
:host .card-body .card-body-left-column .pricing-container ::ng-deep .product-variant-price .product-variant-current-price {
  font-size: 1.375rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
:host .card-body .card-body-left-column .pricing-container ::ng-deep .product-variant-price .product-variant-price-decoration {
  font-size: 0.875rem;
  line-height: 70%;
}
:host .card-body .card-body-right-column {
  display: grid;
  justify-content: end;
  box-sizing: border-box;
  padding: 0.4rem 0.5625rem 0.5625rem 0;
  height: 7.375rem;
}
:host .card-body .card-body-right-column .badge-and-label-container {
  display: grid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
:host .card-body .card-body-right-column .badge-and-label-container .badge-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.5rem;
  margin-bottom: 0.1rem;
  justify-content: center;
  align-items: center;
}
:host .card-body .card-body-right-column .badge-and-label-container .badge-container .badge {
  height: 1.25rem;
}
:host .card-body .card-body-right-column .badge-and-label-container .print-card-label {
  align-self: center;
  height: fit-content;
  margin: 0;
  padding: 0;
}
:host .card-body .card-body-right-column .badge-and-label-container .print-card-label ::ng-deep .product-label {
  font-family: poppins, sans-serif;
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 140%;
  border-radius: 0.25rem;
  margin: 0.1rem 0 0.2rem 0;
  width: fit-content;
}
:host .card-body .card-body-right-column .table-of-properties {
  align-self: end;
}
:host .card-body .card-body-right-column .table-of-properties .property-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0.1875rem 0 0.1875rem 0;
  gap: 0.625rem;
  text-align: center;
}
:host .card-body .card-body-right-column .table-of-properties .property-container .title {
  font-family: poppins, sans-serif;
  font-weight: 600;
  font-size: 0.625rem;
  vertical-align: middle;
  text-align: center;
}
:host .card-body .card-body-right-column .table-of-properties .property-container .value {
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-size: 0.625rem;
  overflow: clip;
  overflow-clip-margin: 0.1rem;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  word-break: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
}
:host .card-body .card-body-right-column .property-container:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
/*# sourceMappingURL=doug-business-card-stack-content.component.css.map */
