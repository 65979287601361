import { Pipe, PipeTransform } from '@angular/core';
import { CardStack } from '../../../models/menu/section/card-stacks/card-stack';

@Pipe({
  name: 'calculateExtendedCutLineLengthString'
})
export class CalculateExtendedCutLineLengthStringPipe implements PipeTransform {

  transform(cardStack: CardStack, addBleed: number = 0): string {
    const extendedLength = cardStack?.extendCutLineIntoContentAreaByNPx() ?? 0;
    return `calc(100% + ${extendedLength}px + ${addBleed}px)`;
  }

}
