<app-label-inflator #labelCalculator hidden class="product-label-container" [labelComponentInterface]="rowViewModel">
</app-label-inflator>

<div
  #sectionItem
  class="product-container new-product-container"
  id="product-container-m{{ menu.id }}-s{{ sectionIndex }}-i{{ itemIndex }}"
  [ngClass]="[
    rowViewModel | sectionRowViewModelBasicMethod: 'getReadableStrainType' | lowercase,
    menu | productMenuBasicMethod: 'getProductWrapperClass' : odd
  ]"
  [style.border-color]="viewModel.sectionBorderColor$ | async">
  <div #subContainer class="new-product-sub-container">
    <app-column-wrapper
      [menu]="menu"
      [section]="section"
      [colVm]="viewModel.imageColumnConfig$ | async"
      [rowVm]="rowViewModel"
      [locConfig]="locationConfig"
      [locationId]="locationId"
      [productStylingVm]="viewModel"
      [ignoreColConfigWidth]="true"
      [additionalClasses]="'product-column-new-badge'">
      <app-column-asset
        [menu]="menu"
        [section]="section"
        [colVm]="viewModel.imageColumnConfig$ | async"
        [rowVm]="rowViewModel"
        [locConfig]="locationConfig"
        [locationId]="locationId"
        [productStylingVm]="viewModel"
        [reset]="reset">
      </app-column-asset>
      <div
        *ngIf="viewModel.badgeColumnEnabled$ | async"
        class="product-column-general product-badge product-new-badge"
        [style.visibility]="section?.collapseContent ? 'collapse' : ''">
        <div *ngFor="let b of rowViewModel | allVariantBadges" class="badge new-product-badge">
          <img
            appImage
            [style.height]="'100%'"
            [style.width]="'100%'"
            [asset]="b.image"
            [reset]="reset"
            alt="badge"
            src="" />
        </div>
      </div>
    </app-column-wrapper>
    <div #productColumnsAndDesc class="new-product-columns-and-desc">
      <div
        class="new-product-columns"
        [class.odd]="odd"
        [class.even]="!odd"
        [class.first]="first"
        [class.last]="last"
        [class.force-bold-style]="viewModel.forcedRowBoldStyling$ | async"
        [class.force-italic-style]="viewModel.forcedRowItalicStyling$ | async"
        [class.has-background-color]="viewModel.rowBackgroundColor$ | async | getArrayElementAtIndex: 1"
        [ngStyle]="viewModel.productWrappingStyle$ | async"
        [style.background-color]="viewModel.rowBackgroundColor$ | async | getArrayElementAtIndex: 1"
        [style.color]="viewModel.forcedRowTextColorToDisplay$ | async"
        [style.text-decoration-color]="viewModel.forcedRowTextColor$ | async">
        <ng-container *ngFor="let colVm of viewModel.newProductColumnViewModels$ | async">
          <!--    Lay out product title column    -->
          <app-column-name
            *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.ProductTitle"
            [calculationMode]="calculationMode"
            [menu]="menu"
            [section]="section"
            [colVm]="colVm"
            [rowVm]="rowViewModel"
            [locConfig]="locationConfig"
            [locationId]="locationId"
            [productStylingVm]="viewModel"
            [reset]="reset"
            [additionalClasses]="'new-product-title-container'">
          </app-column-name>
          <!--    Lay out non-title columns    -->
          <app-column-wrapper
            [menu]="menu"
            [section]="section"
            [colVm]="colVm"
            [rowVm]="rowViewModel"
            [locConfig]="locationConfig"
            [locationId]="locationId"
            [productStylingVm]="viewModel"
            [colWidthFudgeFactor]="subContainer?.clientWidth / productColumnsAndDesc?.clientWidth">
            <app-column-primary-price
              *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.VariantPrice"
              [menu]="menu"
              [section]="section"
              [colVm]="colVm"
              [rowVm]="rowViewModel"
              [locConfig]="locationConfig"
              [locationId]="locationId"
              [productStylingVm]="viewModel">
            </app-column-primary-price>
            <app-column-secondary-price
              *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.VariantSecondaryPrice"
              [menu]="menu"
              [section]="section"
              [colVm]="colVm"
              [rowVm]="rowViewModel"
              [locConfig]="locationConfig"
              [locationId]="locationId"
              [productStylingVm]="viewModel">
            </app-column-secondary-price>
            <app-column-quantity-and-size
              *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.QuantityAndSize"
              [menu]="menu"
              [section]="section"
              [colVm]="colVm"
              [rowVm]="rowViewModel"
              [locConfig]="locationConfig"
              [locationId]="locationId"
              [productStylingVm]="viewModel">
            </app-column-quantity-and-size>
            <app-column-quantity
              *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.Quantity"
              [menu]="menu"
              [section]="section"
              [colVm]="colVm"
              [rowVm]="rowViewModel"
              [locConfig]="locationConfig"
              [locationId]="locationId"
              [productStylingVm]="viewModel">
            </app-column-quantity>
            <app-column-quantity-in-stock
              *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.Stock"
              [menu]="menu"
              [section]="section"
              [colVm]="colVm"
              [rowVm]="rowViewModel"
              [locConfig]="locationConfig"
              [locationId]="locationId"
              [productStylingVm]="viewModel">
            </app-column-quantity-in-stock>
            <app-column-size
              *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.Size"
              [menu]="menu"
              [section]="section"
              [colVm]="colVm"
              [rowVm]="rowViewModel"
              [locConfig]="locationConfig"
              [locationId]="locationId"
              [productStylingVm]="viewModel">
            </app-column-size>
            <app-column-strain-type
              *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.StrainType"
              [menu]="menu"
              [section]="section"
              [colVm]="colVm"
              [rowVm]="rowViewModel"
              [locConfig]="locationConfig"
              [locationId]="locationId"
              [productStylingVm]="viewModel">
            </app-column-strain-type>
            <app-column-cannabinoid
              *ngIf="colVm.columnType | isCannabinoidColumn as cannabinoidColumnType"
              [menu]="menu"
              [section]="section"
              [colVm]="colVm"
              [rowVm]="rowViewModel"
              [locConfig]="locationConfig"
              [locationId]="locationId"
              [productStylingVm]="viewModel"
              [cannabinoidColumnType]="cannabinoidColumnType">
            </app-column-cannabinoid>
            <app-column-terpene
              *ngIf="colVm.columnType | isTerpeneColumn as terpeneColumnType"
              [menu]="menu"
              [section]="section"
              [colVm]="colVm"
              [rowVm]="rowViewModel"
              [locConfig]="locationConfig"
              [locationId]="locationId"
              [productStylingVm]="viewModel"
              [terpeneColumnType]="terpeneColumnType">
            </app-column-terpene>
            <app-column-producer
              *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.Producer"
              [menu]="menu"
              [section]="section"
              [colVm]="colVm"
              [rowVm]="rowViewModel"
              [locConfig]="locationConfig"
              [locationId]="locationId"
              [productStylingVm]="viewModel">
            </app-column-producer>
            <app-column-brand
              *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.Brand"
              [menu]="menu"
              [section]="section"
              [colVm]="colVm"
              [rowVm]="rowViewModel"
              [locConfig]="locationConfig"
              [locationId]="locationId"
              [productStylingVm]="viewModel">
            </app-column-brand>
            <app-column-spacer
              *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.Spacer"
              [menu]="menu"
              [section]="section"
              [colVm]="colVm"
              [rowVm]="rowViewModel"
              [locConfig]="locationConfig"
              [locationId]="locationId"
              [productStylingVm]="viewModel">
            </app-column-spacer>
            <app-column-tiny-spacer
              *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.TinySpacer"
              [menu]="menu"
              [section]="section"
              [colVm]="colVm"
              [rowVm]="rowViewModel"
              [locConfig]="locationConfig"
              [locationId]="locationId"
              [productStylingVm]="viewModel">
            </app-column-tiny-spacer>
            <app-column-badge
              *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.Badge"
              [menu]="menu"
              [section]="section"
              [colVm]="colVm"
              [rowVm]="rowViewModel"
              [locConfig]="locationConfig"
              [locationId]="locationId"
              [productStylingVm]="viewModel"
              [reset]="reset">
            </app-column-badge>
            <app-column-label
              *ngIf="colVm.columnType === Types.SectionColumnProductInfoType.Label"
              [calculationMode]="calculationMode"
              [menu]="menu"
              [section]="section"
              [colVm]="colVm"
              [rowVm]="rowViewModel"
              [locConfig]="locationConfig"
              [locationId]="locationId"
              [productStylingVm]="viewModel">
            </app-column-label>
          </app-column-wrapper>
        </ng-container>
      </div>
      <div class="new-product-desc">
        {{ rowViewModel | sectionRowViewModelBasicMethod: 'getProductDesc' }}
      </div>
    </div>
  </div>
</div>
