import { Pipe, PipeTransform } from '@angular/core';
import { CardStack } from '../../../models/menu/section/card-stacks/card-stack';
import { CardData } from '../../../models/print-cards/card-data';

@Pipe({
  name: 'leftCutLineText'
})
export class LeftCutLineTextPipe implements PipeTransform {

  transform(cardStack: CardStack, cardsOnPage: CardData[], i: number): string | null {
    return cardStack?.getLeftCutLineText(cardsOnPage?.[i]);
  }

}
