import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RenderPrintCardPreviewViewModel } from './render-print-card-preview-view-model';
import { RenderContextComponent } from '../../../../models/base/render-context-component';
import { RenderContextService } from '../../../services/render-context.service';

@Component({
  selector: 'app-render-print-card-preview',
  templateUrl: './render-print-card-preview.component.html',
  styleUrls: ['./render-print-card-preview.component.scss'],
  providers: [RenderPrintCardPreviewViewModel, RenderContextService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenderPrintCardPreviewComponent extends RenderContextComponent {

  constructor(
    public viewModel: RenderPrintCardPreviewViewModel
  ) {
    super();
  }

  setupBindings() {
  }

  setupViews() {
  }

}
