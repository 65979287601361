/* src/app/modules/shared/loading/loading.component.scss */
.f12px {
  font-size: 0.75rem;
}
.f14px {
  font-size: 0.875rem;
}
.f16px {
  font-size: 1rem;
}
.f18px {
  font-size: 1.125rem;
}
.f20px {
  font-size: 1.25rem;
}
.f24px {
  font-size: 1.5rem;
}
.f32px {
  font-size: 2rem;
}
.f48px {
  font-size: 3rem;
}
.f64px {
  font-size: 4rem;
}
.loading-container {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.loading-text {
  font-size: 1rem;
  margin-top: 64px;
  text-align: center;
  font-family: poppins, sans-serif;
  font-weight: 600;
}
.determinate-spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
}
.determinate-spinner.fullscreen {
  position: fixed;
  z-index: 99;
}
.progress-bar-container {
  width: 80%;
  margin: auto;
  vertical-align: middle;
  display: block;
}
.determinate-spinner-loading-text {
  font-size: 1rem;
  width: 100%;
  text-align: center;
  display: block;
  margin-top: 1rem;
  font-family: poppins, sans-serif;
  font-weight: 600;
}
/*# sourceMappingURL=loading.component.css.map */
