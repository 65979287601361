import { ChangeDetectionStrategy, Component, ElementRef, Renderer2 } from '@angular/core';
import { FireAndFlowerMemberCardStackBackgroundViewModel } from './fire-and-flower-member-card-stack-background-view-model';
import { PrintCardBackgroundComponent } from '../../print-card-background.component';

@Component({
  selector: 'app-fire-and-flower-member-card-stack-background',
  templateUrl: './fire-and-flower-member-card-stack-background.component.html',
  styleUrls: ['./fire-and-flower-member-card-stack-background.component.scss'],
  providers: [FireAndFlowerMemberCardStackBackgroundViewModel],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireAndFlowerMemberCardStackBackgroundComponent extends PrintCardBackgroundComponent {

  constructor(
    public override viewModel: FireAndFlowerMemberCardStackBackgroundViewModel,
    elementRef: ElementRef,
    renderer2: Renderer2
  ) {
    super(viewModel, elementRef, renderer2);
  }

}
