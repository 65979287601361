import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TrueNorthPortraitCardData } from '../../../../../../../../models/print-cards/true-north-portrait-card-data';
import { TrueNorthCardStackContentViewModel } from '../true-north-card-stack-content-view-model';

@Injectable()
export class TrueNorthPortraitCardStackContentViewModel extends TrueNorthCardStackContentViewModel {

  public readonly cardData$: Observable<TrueNorthPortraitCardData>;

}
