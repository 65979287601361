import { MarketingLoopingContentMenu } from './marketing-looping-content-menu';
import { Asset } from '../../image/dto/asset';

export class MarketingLoopMenu extends MarketingLoopingContentMenu {

  public calculateMarketingLoopDurationInSeconds(): number {
    // Summing the duration of each product on the menu
    let sum = 0;
    for (const value of (this?.options?.rotationInterval?.values() || [])) {
      sum += value;
    }
    if (Number.isInteger(sum)) {
      return sum;
    } else {
      return Number(sum.toFixed(2));
    }
  }

  isAssetEnabled(asset: Asset): boolean {
    const position = this.options?.rotationOrder?.get(asset?.fileName);
    const interval = this.options?.rotationInterval?.get(asset?.fileName);
    return (position >= 0) && (interval > 0);
  }

  getAssetPosition(asset: Asset): number {
    return this.options?.rotationOrder?.get(asset?.fileName);
  }

}
