import { BaseViewModel } from '../../../../models/base/base-view-model';
import { Injectable } from '@angular/core';
import { PrintCardDomainModel } from '../../../../domain/print-card-domain-model';

@Injectable()
export class PrintCardGridViewModel extends BaseViewModel {

  constructor(
    protected printCardDomainModel: PrintCardDomainModel
  ) {
    super();
  }

  public readonly locationConfig$ = this.printCardDomainModel.locationConfig$;
  public readonly overrideVariantIds$ = this.printCardDomainModel.overrideVariantIds$;
  public readonly printCardMenu$ = this.printCardDomainModel.printCardMenu$;
  public readonly cardStack$ = this.printCardDomainModel.cardStack$;

}
