import { Component, Input, OnChanges, SecurityContext, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { RenderContextComponent } from '../../../../../../../models/base/render-context-component';
import { MenuType } from '../../../../../../../models/enum/dto/menu-type.enum';
import { CachePolicy } from '../../../../../../../models/enum/shared/cachable-image-policy.enum';
import { MediaUtils } from '../../../../../../../utils/media-utils';
import type { ProductMenu } from '../../../../../../../models/menu/product-menu';
import type { Section } from '../../../../../../../models/menu/section/section';
import type { SectionColumnViewModel } from '../menu-section/product-section/section-column-view-models/SectionColumnViewModel';
import type { SectionRowViewModel } from '../menu-section/product-section/section-row-view-models/SectionRowViewModel';
import type { LocationConfiguration } from '../../../../../../../models/company/dto/location-configuration';

@Component({ selector: 'app-menu-section', template: '' })
export abstract class MenuSectionHeaderComponent extends RenderContextComponent implements OnChanges {

  constructor(
    public sanitizer: DomSanitizer
  ) {
    super();
  }

  @Input() calculationMode: boolean = false;
  @Input() locationConfig: LocationConfiguration;
  @Input() menu: ProductMenu;
  @Input() section: Section;
  @Input() sectionRowViewModels: SectionRowViewModel[] = [];
  @Input() index: number;
  @Input() reset: boolean;

  public columnVMs: SectionColumnViewModel[] = [];
  public asset: ReplaySubject<string | SafeResourceUrl> = new ReplaySubject<string | SafeResourceUrl>(1);
  public MenuType: typeof MenuType = MenuType;

  // Removes ExpressionChangedAfterItHasBeenCheckedError
  private showSectionImageAsIcon = new BehaviorSubject<boolean>(false);
  private toggleHeaderSectionToFillEntireSection = new BehaviorSubject<boolean>(false);
  private headerSectionImageCoversSection$ = combineLatest([
    this.showSectionImageAsIcon,
    this.toggleHeaderSectionToFillEntireSection
  ]).pipe(debounceTime(1));
  public assetIsForSectionHeader = new BehaviorSubject<boolean>(false);

  abstract getHeaderHeight(): number;

  trackByColumnViewModelId = (index: number, colViewModel: SectionColumnViewModel): string => {
    return colViewModel?.uniqueId();
  };

  setupViews() {
    this.setColumnVMs();
  }

  setColumnVMs() {
    const vm = this.sectionRowViewModels?.firstOrNull();
    if (vm) {
      this.columnVMs = this.menu.getThemeSpecifiedColumnViewModels(this.sectionRowViewModels, vm);
    }
  }

  setupBindings() {
    this.showSectionImageAsIcon.next(this.menu.getShowSectionHeaderColorAsIcon());
    this.toggleHeaderSectionToFillEntireSection.next(this.menu.getMakeHeaderSectionImageFillEntireSection());
    this.subToAsset();
    this.subSetAssetMechanism();
  }

  subSetAssetMechanism() {
    const s = this.headerSectionImageCoversSection$.subscribe(([showImageAsIcon, showAssetOverEntireSection]) => {
      this.assetIsForSectionHeader.next(!showImageAsIcon && !showAssetOverEntireSection);
    });
    this.pushSub(s);
  }

  subToAsset() {
    this.fetchAsset();
    const imgKey = 'assetKey';
    this.destroyImageSub(imgKey);
    const observable$ = this?.section?.image?.sizePriorityUrl$;
    if (observable$) {
      const imageUrlSub = this.asset.bind(
        observable$.pipe(
          map(url => {
            if (url && url !== '' && this.section.image.isImage()) {
              const imgUrl = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, url);
              return `url(${imgUrl})`;
            } else if (url && url !== '' && this.section.image.isVideo()) {
              return url;
            } else {
              return null;
            }
          })
        )
      );
      this.pushImageSub(imgKey, imageUrlSub);
    }
  }

  private fetchAsset() {
    const asset = this.asset;
    const size = MediaUtils.DefaultImageSize;
    const cacheForNSeconds = MediaUtils.DefaultCacheTimeInSeconds;
    const validCacheTime = cacheForNSeconds > -1 && cacheForNSeconds !== undefined && cacheForNSeconds !== null;
    if (!!asset && !!size && validCacheTime) {
      const policy = CachePolicy.Service;
      this?.section?.image?.getAsset(policy, size, cacheForNSeconds);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.section || changes.reset) {
      this.subToAsset();
    }
    if (changes.sectionRowViewModels) {
      this.setColumnVMs();
    }
  }

}
