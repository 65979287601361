import { Deserializable } from '../protocols/deserializable';
import { UniquelyIdentifiable } from '../protocols/uniquely-identifiable';
import moment from 'moment-timezone';
import { exists } from '../../functions/exists';

export abstract class LocalizedDateRange implements Deserializable, UniquelyIdentifiable {

  constructor(
    startDate?: number,
    endDate?: number
  ) {
    this.startDate = startDate || undefined;
    this.endDate = endDate || undefined;
  }

  protected testTimestampAt: number; // for testing purposes only
  protected testingTimezone: string; // for testing purposes only
  public startDate: number;
  public endDate: number;

  /**
   * Think very carefully about using this method. You may need to use isWithinUnixDateTimeWindow instead.
   * This method converts unix dates to localized time before comparing, which is DIFFERENT from
   * comparing the unix timestamps directly.
   */
  public abstract isWithinDateTimeWindow(dateTime: number, timezone?: string): boolean;
  public abstract isRightNowWithinDateTimeWindow(timezone?: string): boolean;
  protected abstract getUtcTimeInLocalizedTimeZone(utcTimeStampInSeconds: number, timezone?: string): moment.Moment;
  protected abstract getCurrentLocalTimeInLocalizedTimeZone(timeStamp: number, timezone?: string): moment.Moment;

  onDeserialize() {}

  /**
   * This does not take timezones into account. It compares unix timestamps directly.
   */
  public isWithinUnixDateTimeWindow(unixDate: number): boolean {
    return unixDate >= (this.startDate ?? 0) && unixDate <= (this.endDate ?? 0);
  }

  protected convertUtcTimeWindowStringToLocalDate(timeWindowString: string, timezone?: string): moment.Moment {
    if (exists(timezone)) {
      return moment.tz(timeWindowString, timezone);
    } else {
      return moment(timeWindowString);
    }
  }

  protected convertTimeWindowStringToLocalDate(timeWindowString: string, timezone?: string): moment.Moment {
    if (exists(timezone)) {
      return moment(timeWindowString).tz(timezone);
    } else {
      return exists(this.testTimestampAt)
        ? moment.tz(timeWindowString, this.testingTimezone)
        : moment(timeWindowString);
    }
  }

  getTestTimestampAt(): number {
    return this.testTimestampAt;
  }

  getTestingTimezone(): string {
    return this.testingTimezone;
  }

  setTestTimestampAt(testTimestampAt: number): void {
    this.testTimestampAt = testTimestampAt;
  }

  setTestingTimezone(testingTimezone: string): void {
    this.testingTimezone = testingTimezone;
  }

  protected isTestDateWithinTimeWindow(dateTimeInSeconds: number, timezone?: string): boolean {
    const startDate = this.getUtcTimeInLocalizedTimeZone(this.startDate, timezone);
    const endDate = this.getUtcTimeInLocalizedTimeZone(this.endDate, timezone);
    // test date is already localized
    const comparisonDate = this.getCurrentLocalTimeInLocalizedTimeZone(dateTimeInSeconds, undefined);
    return comparisonDate.isSameOrAfter(startDate) && comparisonDate.isSameOrBefore(endDate);
  }

  getUniqueIdentifier(): string {
    return `${this.startDate}-${this.endDate}`;
  }

}
